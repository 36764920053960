<template>
  <a-modal
    :visible="true"
    width="450px"
    :body-style="bodyStyle"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img class="image__delete" src="@/app/users/assets/profiles/delete.png" alt="perfil" />
      <p class="text__alert">¿Estás seguro de eliminar el teléfono</p>
      <p class="text__alert">"{{ record.phone }}”?</p>
    </div>
    <div class="container__info">
      <p class="text">Recuerda que se tomarán las siguientes acciones:</p>
      <ul>
        <li>Las interacciones asociadas a este teléfono serán finalizadas.</li>
        <li>Se eliminarán los recordatorios pendientes asociados a este número.</li>
        <li>
          Se eliminará de las estrategias en las que se encuentre asociado y aún no haya sido
          gestionado.
        </li>
      </ul>
    </div>
    <div class="button__disabled">
      <a-button
        style="width: 100%"
        class="footer__delete"
        type="primary"
        @click="handleDeletePhone"
      >
        Eliminar teléfono
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent } from "vue";

import EventBus from "@/app/shared/utils/eventBus";
import { UserStatus } from "@/app/shared/utils/enums";
// import { deleteUserSocket } from "@/app/shared/services";
export default defineComponent({
  name: "InactiveUserModal",
  components: {},
  props: {
    record: {
      type: Object,
      default: null,
    },
    isWorkspace: {
      type: Boolean,
      required: true,
    },
    itemId: {
      type: Number,
      required: true,
    },
    clientWorkspace: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const bodyStyle = {
      loading: false,
      height: "420px",
    };
    return {
      bodyStyle,
    };
  },
  data() {
    return {
      UserStatus,
    };
  },
  computed: {
    usersInfo() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleCloseModal() {
      this.$emit("onCancel");
    },
    handleCloseModalHeader() {
      this.$emit("onCancel");
    },
    async handleDeletePhone() {
      try {
        if (!this.isWorkspace) {
          await this.$store.dispatch("deletePhoneContact", {
            id: this.record.id,
            client: this.itemId,
          });
          this.handleCloseModal();
        } else if (this.isWorkspace) {
          await this.$store.dispatch("deletePhoneClient", {
            phoneId: this.record.id,
            client: this.clientWorkspace?.id,
          });
          EventBus.emit("UPDATE_DATA_CLIENT");
          this.handleCloseModal();
          this.$message.success(`Se ha eliminado el número telefónico correctamente`);
        }
      } catch (error) {
        this.$message.error(`Se ha producido un error al eliminar el teléfono`);
      }

      this.$emit("delete");
    },
  },
});
</script>

<style lang="sass" scoped>
.image__delete
  margin-bottom: 10px
  margin-top: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin: 0

.button__disabled
  margin-top: 10px

.footer__delete
  margin-bottom: 20px

.container__inactive
  text-align: center
  color: $gray-13

.container__info
  margin-bottom: 18px
  color: $gray-8

.text
  margin-top: 20px
  margin-bottom: 12px

.text__strong
  font-weight: 600
</style>
