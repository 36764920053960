import entitiesStore from "@/app/entities/store/entities";
import groupsStore from "@/app/entities/store/groups";
import portfoliosStore from "@/app/entities/store/portfolios";
import typificationsStore from "@/app/entities/store/typifications";
import basesStore from "@/app/entities/store/bases";
import agentsStore from "@/app/entities/store/agents";
import resultsStore from "@/app/entities/store/results";

export default {
  modules: {
    entitiesStore,
    groupsStore,
    portfoliosStore,
    typificationsStore,
    basesStore,
    agentsStore,
    resultsStore,
  },
};
