export const supportUrl =
  window.location.hostname == "localhost"
    ? "http://localhost:8080/support"
    : `https://${import.meta.env.VITE_HOSTNAME}/support`;

const items = [
  {
    route: "/reports",
    name: "reports",
    text: "Informes",
    active: false,
    expanded: false,
    scope: "menu_reports",
    subitems: [
      {
        route: "/reports/dashboard",
        name: "dashboard",
        text: "Dashboard",
        scope: "tab_dashboard",
      },
      {
        route: "/reports/documents",
        name: "reports",
        text: "Reportes",
        scope: "tab_reports",
      },
    ],
  },
  {
    route: "/monitoring",
    name: "monitoring",
    text: "Supervisión",
    active: false,
    expanded: false,
    scope: "menu_monitoring",
    subitems: [
      {
        route: "/monitoring/monitoring",
        name: "supervision-monitor",
        text: "Monitoreo de usuarios",
        scope: "tab_monitoring",
      },
      {
        route: "/monitoring/viewer",
        name: "supervision-viewer",
        text: "Visor de colas",
        scope: "tab_viewer",
      },
      {
        route: "/monitoring/records",
        name: "supervision-records",
        text: "Grabaciones",
        scope: "tab_records",
      },
    ],
  },
  {
    route: "/crm",
    name: "crm",
    text: "CRM",
    active: false,
    expanded: false,
    scope: "menu_crm",
    subitems: [
      {
        route: "/crm/contacts",
        name: "contacts",
        text: "Contactos",
        scope: "tab_contacts",
      },
      {
        route: "/crm/tickets-manager",
        name: "tickets",
        text: "Gestor de Tickets",
        scope: "tab_tickets_manager",
      },
      {
        route: "/crm/imports",
        name: "imports",
        text: "Importaciones",
        scope: "tab_imports",
      },
      {
        route: "/crm/templates",
        name: "imports-templates",
        text: "Plantillas",
        scope: "tab_templates",
      },
      // {
      //   route: "/crm/emails-manager",
      //   name: "emails-manager",
      //   text: "Gestor de Correos",
      //   scope: "tab_emails_manager",
      // },
    ],
  },
  {
    route: "/operations",
    name: "operations",
    text: "Operaciones",
    active: false,
    expanded: false,
    scope: "menu_operations",
    subitems: [
      {
        route: "/operations/board",
        name: "operations-board",
        text: "Empresas",
        scope: "tab_entities",
      },
      {
        route: "/operations/campaigns",
        name: "operations-campaigns",
        text: "Campañas",
        scope: "tab_portfolios",
      },
      {
        route: "/operations/bases",
        name: "operations-bases",
        text: "Bases",
        scope: "tab_bases",
      },
      {
        route: "/operations/strategies",
        name: "operation-strategies",
        text: "Estrategias",
        scope: "tab_strategies",
      },
      {
        route: "/operations/queues",
        name: "operation-queues",
        text: "Colas",
        scope: "tab_queues",
      },
    ],
  },
  {
    route: "/flows",
    name: "flows",
    text: "Flujos",
    active: false,
    expanded: false,
    scope: "menu_flows",
    subitems: [
      {
        route: "/flows/forms",
        name: "flows-forms",
        text: "Formularios",
        scope: "tab_forms",
      },
      {
        route: "/flows/chatbots",
        name: "flows-chatbots",
        text: "Chatbots",
        scope: "tab_chatbots",
      },
      {
        route: "/flows/ivr-automatic",
        name: "flows-ivr-automatic",
        text: "IVR Operador automático",
        scope: "tab_ivr_auto_attendant",
      },
      {
        route: "/flows/ivr-survey",
        name: "flows-ivr-survey",
        text: "IVR Encuesta de satisfacción",
        scope: "tab_ivr_satisfaction_survey",
      },
      {
        route: "/flows/manage-vars",
        name: "manage-vars",
        text: "Gestor de Variables",
        scope: "tab_manage_vars",
      },
      // {
      //   route: "/flows/templates-email",
      //   name: "templates-email",
      //   text: "Plantillas de correo",
      //   scope: "tab_email_templates",
      // },
      {
        route: "/flows/sequences",
        name: "sequences",
        text: "Secuencias (BETA)",
        scope: "tab_sequences",
      },
    ],
  },
  {
    route: "/workspace",
    name: "workspace",
    text: "Workspace",
    hide: true,
    active: false,
    expanded: false,
    scope: "menu_workspace",
  },
  {
    route: "/email",
    name: "email",
    text: "Correo",
    active: false,
    expanded: false,
    scope: "menu_email",
    subitems: [
      {
        route: "/email/emails-manager",
        name: "emails-manager",
        text: "Gestor de correos",
        scope: "tab_email_manager",
      },
      {
        route: "/email/mailbox",
        name: "email-mailbox",
        text: "Buzón de correo",
        scope: "tab_email",
      },
      {
        route: "/email/templates-email",
        name: "templates-email",
        text: "Plantillas de correo",
        scope: "tab_email_templates",
      },
    ],
  },
  {
    route: "/channels",
    name: "channels",
    text: "Conexiones",
    active: false,
    expanded: false,
    scope: "menu_channels",
    subitems: [
      {
        route: "/channels/setup",
        name: "channels-setup",
        text: "Canales de comunicación",
        scope: "tab_setup",
      },
      {
        route: "/channels/board",
        name: "channels-board",
        text: "Mis canales",
        scope: "tab_channels",
      },
      {
        route: "/channels/integrations",
        name: "channels-integrations",
        text: "Integraciones",
        scope: "tab_integrations",
      },
      {
        route: "/channels/template",
        name: "channels-template",
        text: "Plantillas",
        scope: "tab_channels_templates",
      },
    ],
  },
  {
    route: "/pbx",
    name: "pbx",
    text: "PBX",
    active: false,
    expanded: false,
    scope: "menu_pbx",
    subitems: [
      {
        route: "/pbx/trunk",
        name: "pbx-trunk",
        text: "Troncales",
        scope: "tab_trunks",
      },
      {
        route: "/pbx/dialplan",
        name: "pbx-dialplan",
        text: "Plan de marcación",
        scope: "tab_dialingplan",
      },
    ],
  },
  {
    route: "/schedule",
    name: "schedule",
    text: "Agenda",
    active: false,
    expanded: false,
    scope: "menu_schedule",
  },

  /**
   * Header Menu
   */
  {
    route: "/account/profile",
    text: "Mi perfil",
    scope: "section_profile",
  },
  {
    route: "/account/security",
    text: "Seguridad y Permisos",
    scope: "section_security",
  },
  {
    route: "/users",
    text: "Equipo de trabajo",
    scope: "section_workteam",
  },
  {
    route: "/account/downloads",
    text: "Mis descargas",
    scope: "section_processes",
  },
  {
    route: "/billing",
    text: "Planes y facturación",
    scope: "section_billing",
  },
  {
    route: "/onboarding",
    text: "Guía de usuario",
    scope: "section_onboarding",
  },
  {
    route: supportUrl,
    text: "Soporte",
    scope: "section_support",
    isExternalLink: true,
  },

  /**
   * Header
   */
  {
    route: "/notifications",
    text: "Notificaciones",
    scope: "menu_notifications",
  },

  /**
   * Settings Menu must be at the end of the array
   */
  {
    route: "/settings",
    name: "settings",
    text: "Ajustes de la plataforma",
    active: false,
    expanded: false,
    scope: "menu_settings",
    // subitems: [
    //   {
    //     route: "/settings/account",
    //     name: "settings-account",
    //     text: "Mis datos",
    //     scope: "tab_account",
    //   },
    //   {
    //     route: "/settings/pricing",
    //     name: "settings-pricing",
    //     text: "Planes",
    //     scope: "tab_plans",
    //   },
    //   {
    //     route: "/settings/billing",
    //     name: "settings-billing",
    //     text: "Mi facturación",
    //     scope: "tab_billing",
    //   },
    // ],
  },
];

export const sections = items.filter((item) => item.scope.includes("section"));

export default items;
