import WebPhone from "./components/WebPhone.vue";
import WebPhoneMock from "./components/WebPhoneMock.vue";
import { app } from "../../main";

export default {
  install() {
    app.component("web-phone", WebPhone);
    app.component("web-phone-mock", WebPhoneMock);
  },
};
