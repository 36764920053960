<template>
  <a-form class="form" layout="vertical" :model="formState" ref="formRef">
    <a-form-item label="Título" name="title" required>
      <a-input
        v-model:value="formState.title"
        placeholder="Escribir titulo del recordatorio"
        :maxlength="maxObservationName"
        @input="handleFormChange"
      />

      <span class="countername"> {{ formState.title.length }}/{{ maxObservationName }} </span>
    </a-form-item>

    <a-row :gutter="16">
      <a-col :span="12">
        <a-form-item label="Seleccione fecha" name="date_schedule" required>
          <a-date-picker
            v-model:value="formState.date_schedule"
            style="width: 100%"
            :disabled-date="disabledDate"
            placeholder="-- / -- / --"
            format="DD/MM/YYYY"
            valueFormat="YYYY-MM-DD"
            :show-today="false"
            @change="handleFormChange"
          />
        </a-form-item>
      </a-col>

      <a-col :span="12">
        <a-form-item label="Seleccione hora" name="time_schedule" required>
          <a-time-picker
            v-model:value="formState.time_schedule"
            placeholder="00:00"
            format="HH:mm"
            valueFormat="HH:mm"
            style="width: 100%"
            @change="handleFormChange"
          />
        </a-form-item>
      </a-col>
    </a-row>

    <a-form-item name="description">
      <p>Descripción</p>
      <a-textarea
        v-model:value="formState.description"
        class="textarea"
        rows="5"
        placeholder="Escribir el mensaje inicial"
        :maxlength="maxDescriptionLength"
        @input="handleFormChange"
      />

      <span class="countername">
        {{ formState.description.length }}/{{ maxDescriptionLength }}
      </span>
    </a-form-item>
  </a-form>
</template>

<script setup>
import { reactive, ref, toRaw, watch } from "vue";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";

import { disabledDate } from "@/app/shared/utils/dates";

const props = defineProps({
  clientId: Number,
});

const emit = defineEmits(["enable-save"]);

const route = useRoute();
const store = useStore();

const maxObservationName = 50;
const maxDescriptionLength = 100;

const formRef = ref(null);
const formState = reactive({
  title: "",
  date_schedule: "",
  time_schedule: "",
  description: "",
});

const handleCreate = async () => {
  await formRef.value.validateFields().then(async () => {
    const formData = toRaw(formState);

    const data = {
      title: formData.title,
      date_schedule: formData.date_schedule,
      time_schedule: formData.date_schedule + " " + formData.time_schedule,
      description: formData.description || "-",
      client: props.clientId,
      ticket: Number(route.query.ticket),
      // channel_person: Number(route.params.channel),
      interaction: Number(route.query.interaction),
    };

    try {
      await store.dispatch("addReminder", data);
      message.success("Se creó el recordatorio correctamente");
    } catch (error) {
      message.error("Hubo un error al crear el recordatorio");
    }

    formRef.value?.resetFields();
  });
};

watch(
  formState,
  () => {
    const { title, date_schedule, time_schedule } = formState;

    if (title && date_schedule && time_schedule) emit("toggle-save", true);
    else emit("toggle-save", false);
  },
  { deep: false }
);

const handleSetData = (values) => {
  console.log("Reminder", values);
  Object.assign(formState, values);
};

defineExpose({ handleCreate, formState, handleSetData });
</script>

<style lang="sass" scoped>
.form
  margin-top: 16px

.form p
  margin-bottom: 6px
  color: $gray-9
.space
  margin-bottom: 8px
.textarea
  height: 56px
  margin: auto
.date
  display: flex
  justify-content: space-between
  .picker
    width: 184px
.ant-tag
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 330px
.msj-reminder
  color: $gray-8
  display: inline-flex
.msj-text
  margin-left: 7px
.countername
  bottom: 0
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6
</style>
