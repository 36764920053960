import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener recordatorios.
 *
 * @return {Promise<Array>} Lista de recordatorios.
 */
export async function fectAllShedule({ year = "", month = "" } = {}) {
  const response = await httpManager.get(`/schedule/reminders/?year=${year}&month=${month}`);
  return response?.data;
}
