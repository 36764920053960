import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener descarga realizas por el usuario
 * @return {Promise<Object>} descarga.
 */
export async function deleteDownload({
  proccessId = "",
}: {
  proccessId: string | number | undefined;
}) {
  const response = await httpManager.delete(`processes/${proccessId}/delete/`);
  return response;
}
