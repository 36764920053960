import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza nuevo Correo electronico.
 * @param {number} emailId Identificador del email
 * @param {Object} body Datos del email.
 *
 * @return {Promise<Object>} Datos del correo creado
 */
export async function updateEmailClient(emailId, body) {
  const url = `/workspace/tickets/emails/${emailId}/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
