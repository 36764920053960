<template>
  <a-modal
    :open="props.visible"
    class="modalDialplanRequest"
    :mask-closable="false"
    :destroy-on-close="true"
    :closable="false"
    title="Agregar plan de marcación"
    :body-style="{ padding: '0px 44px', height: '40em' }"
    :keyboard="false"
    width="45em"
    centered
  >
    <CloseOutlined class="iconoCerrado" @click="handleConfirmationClose" />
    <a-steps
      :current="currentStep"
      :items="STEPS_DIAL_PLAN_CREATE"
      progress-dot
      class="modal__steps"
    ></a-steps>
    <a-typography-title :level="4" class="modal__title text--center mrg-bottom-12">{{
      STEPS_DIAL_PLAN_CREATE[currentStep].title_section
    }}</a-typography-title>
    <p class="mrg-bottom-12 text--center">Completa los siguientes datos</p>
    <component
      v-if="formComponent"
      ref="formRef"
      :is="formComponent"
      @onChangeTrunk="handleChangeTrunk"
    />
    <template #footer>
      <div class="footer">
        <a-button
          class="footer__back"
          type="primary"
          ghost="ghost"
          @click="handleBack"
          :disabled="disableCancel"
        >
          {{ cancelButton }}
        </a-button>
        <a-button class="footer__next" type="primary" @click="handleOk(false)">
          {{ okButton }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref, computed, reactive, nextTick } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import FormDialPlan from "@/app/pbx/components/forms/FormDialPlan.vue";
import FormDialRule from "@/app/pbx/components/forms/FormDialRule.vue";
import { Modal, message } from "ant-design-vue";
import { STEPS_DIAL_PLAN_CREATE, DEFAULT_RULES_FOR_SEC } from "@/app/pbx/utils/dialPlanUtils";
import { FormStateDialPlan, Trunk } from "@/@types/pbx/dialPlan";
import { useStore } from "vuex";
import { ResponseError } from "@/app/shared/utils/httpManager";
import { cloneDeep } from "lodash";

const props = defineProps({
  visible: {
    type: Boolean,
    required: false,
  },
});

const emits = defineEmits(["onClose", "onUpdate"]);

const store = useStore();
const currentStep = ref(0);
const formComponent = computed(() => {
  switch (currentStep.value) {
    case 0:
      return FormDialPlan;
    case 1:
      return FormDialRule;
    default:
      return null;
  }
});
const formRef = ref();
const formState = reactive({}) as FormStateDialPlan;
const isSecuritec = ref(false);
const way_selection = ref("new_config"); // pre_config o new_config

const currentData = computed(() => {
  return STEPS_DIAL_PLAN_CREATE[currentStep.value];
});

const cancelButton = computed(() => {
  return currentData.value.buttons.cancel;
});
const okButton = computed(() => {
  return currentData.value.buttons.ok;
});

const disableCancel = computed(() => {
  if (currentData.value.is_last) return false;
  return !isSecuritec.value;
});

const handleConfirmationClose = () => {
  Modal.confirm({
    title: "¿Deseas descartar los cambios realizados?",
    content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
    onOk: () => {
      handleCancel();
      Modal.destroyAll();
    },
    centered: true,
    okText: "Sí, descartar cambios",
    cancelText: "No, seguir editando",
  });
};

/**
 * Setea los valores obtenidos del componente form a la data local
 * @param values
 */
const setValuesToLocale = (values: FormStateDialPlan) => {
  Object.assign(formState, values);
};

/**
 * Setea los valores locales al componente form
 */
const setValuesToCurrentForm = async () => {
  await nextTick();
  formRef.value.onSetValues(formState);
};

const handleOk = (fromPreconfig: boolean) => {
  formRef.value.onSubmit(async (values: FormStateDialPlan, isLast: boolean) => {
    // si viene de una seleccion de pre_config y era securitec, se reseteará las reglas
    if (!isLast && !fromPreconfig && way_selection.value === "pre_config" && isSecuritec.value) {
      formState.rules = [];
      way_selection.value = "new_config";
    }
    console.log({ values, formState, form: formRef.value.onGetValues() });
    values.rules = isSecuritec.value
      ? cloneDeep(DEFAULT_RULES_FOR_SEC)
      : formRef.value.onGetValues().rules;

    setValuesToLocale(values);
    if (!isLast) {
      currentStep.value += 1;
      setValuesToCurrentForm();
    } else {
      try {
        await store.dispatch("createNewDialplan", formState);
        message.success(`Se ha agregado un nuevo plan de marcación correctamente`);
        handleCancel();
        emits("onUpdate");
      } catch (error) {
        const errorResponse =
          error instanceof ResponseError ? error.data.errors : "Ocurrió un error";
        message.error(errorResponse);
      }
    }
  });
};
const handleBack = () => {
  // solo si es el primero ejecutará el handleOk
  if (currentData.value.is_first) {
    way_selection.value = "pre_config";
    // if (isSecuritec.value) formState.rules = DEFAULT_RULES_FOR_SEC;
    // else formState.rules = [];
    return handleOk(true);
  }
  // obtiene la data actual, antes de retroceder
  const currentValues = formRef.value.onGetValues();
  // setea los valores a la data local
  setValuesToLocale(currentValues);
  currentStep.value -= 1;
  // setea valores a la data del componente
  setValuesToCurrentForm();
};
const handleCancel = () => {
  emits("onClose");
  currentStep.value = 0;
  way_selection.value = "new_config";
};
const handleChangeTrunk = (trunk: Trunk) => {
  isSecuritec.value = trunk === Trunk.Securitec;
};
</script>

<style lang="sass" scoped>
.modal
  &__steps
    margin: 24px 0px 12px 0px
  &__title
    margin-top: 30px
.modalDialplanRequest
  :deep(.ant-modal-centered .ant-modal)
    width: 740px !important
  :deep(.ant-modal-header)
    height: 56px
  :deep(.ant-modal-content)
    height: 630px
  .iconoCerrado
    position: absolute
    top: 22px
    right: 16px
</style>
