<template>
  <div class="drawer">
    <div class="drawer__header" :style="bgStyle">
      <span v-if="record.event_management === 16">No se encuentra con la plataforma abierta </span>
      <span v-else-if="record.section">En la sección de {{ SectionsNames[record.section] }}</span>
      <span v-else>En el modulo de {{ record?.menu?.name }}</span>
      <CloseOutlined @click="$emit('onClose')" />
    </div>
    <div class="drawer__body">
      <custom-scrollbar class="drawer__content">
        <supervision-drawer-user :stats="stats" :status="record.state" :info="record" />
        <supervision-drawer-graph :labels="labels" :options="options" />
        <supervision-drawer-daily :stats="stats" @refresh="refresh" />
      </custom-scrollbar>

      <div class="drawer__footer">
        <a-button size="small" @click="handleLogout"> Cerrar su sesión </a-button>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { CloseOutlined } from "@ant-design/icons-vue";

import { StateOptions, EventManagement } from "@/app/shared/utils/enums";
import { SectionsNames } from "@/app/common/utils/enums";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import SupervisionDrawerUser from "./SupervisionDrawerUser.vue";
import SupervisionDrawerDaily from "./SupervisionDrawerDaily.vue";
import SupervisionDrawerGraph from "@/app/supervision/components/graph/SupervisionDrawerGraph.vue";

const states = {
  [StateOptions.Connected]: "#73D13D",
  [StateOptions.Refreshment]: "#FFC53D",
  [StateOptions.Dinner]: "#FFA940",
  [StateOptions.Occupied]: "#FF4D4F",
  [StateOptions.Services]: "#36CFC9",
  [StateOptions.Training]: "#EB2F96",
  [StateOptions.NotAvailable]: "#40A9FF",
  [StateOptions.Disconnected]: "#BFBFBF",
  [EventManagement.Inactive]: "#BFBFBF",
};
export default defineComponent({
  components: {
    CustomScrollbar,
    SupervisionDrawerUser,
    SupervisionDrawerDaily,
    SupervisionDrawerGraph,
    CloseOutlined,
  },
  props: {
    stats: { required: true, type: Object },
    record: { required: true, type: Object },
  },
  data() {
    return { SectionsNames };
  },
  computed: {
    dataChart() {
      return this.stats?.tickets_statistics.total_by_day;
    },
    labels() {
      return Object.values(this.dataChart).map((item) => item.day_of_week);
    },
    options() {
      return Object.values(this.dataChart).map((item) => item.value);
    },
    bgStyle() {
      if (this.record.event_management === 16) {
        return {
          backgroundColor: states[this.record.event_management],
        };
      } else {
        return {
          backgroundColor: states[this.record?.state],
        };
      }
    },
  },

  methods: {
    handleLogout() {
      this.$emit("onLogout");
    },
    refresh() {
      this.$emit("refresh");
    },
  },
});
</script>

<style lang="sass" scoped>
.drawer
  min-width: 300px
  max-width: 25%
  position: fixed
  z-index: 2
  top: 103px
  right: 0%
  height: 100%

  &__header
    position: relative
    z-index: 1
    font-size: 12px
    color: $white
    display: flex
    justify-content: space-between
    align-items: center
    padding: 4px 24px
    border-top-left-radius: 6px
    cursor: pointer
    height: 30px

    & > span
      line-height: 10px

  &__body
    background-color: $white
    box-shadow: -2px 2px 3px rgba(0, 0, 0, 0.08)
    position: relative

  &__content
    padding: 20px 24px
    height: calc(100vh - 174px)

  &__footer
    width: 100%
    padding: 8px 12px
    display: flex
    justify-content: flex-end
    border-top: $border-medium
</style>
