<template>
  <div :class="{ detailed: detailedView }">
    <div>
      <span v-if="record.event_management === 16" class="state">
        <div class="state__indicator" :class="StateValues?.[record.event_management]?.class" />
        {{ StateValues?.[record.event_management]?.text }}</span
      >
      <span class="state" v-else>
        <div class="state__indicator" :class="StateValues?.[record.state]?.class" />
        {{ StateValues?.[record.state]?.text }}
      </span>
    </div>

    <div :class="{ time__container: detailedView }" v-if="time">
      <small class="time" :class="{ detailed: detailedView }">
        {{ isTimeShowed ? counterWithTimeFormat : "00:00:00" }}
      </small>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

import {
  slashDateToDashed,
  millisecondsToSeconds,
  secondsToTimeString,
} from "@/app/shared/utils/dates";
import { StateOptions, StateTexts, EventManagement } from "@/app/shared/utils/enums";

const StateValues = {
  [StateOptions.Connected]: {
    text: StateTexts[StateOptions.Connected],
    class: "state--connected",
  },
  [StateOptions.Refreshment]: {
    text: StateTexts[StateOptions.Refreshment],
    class: "state--refreshment",
  },
  [StateOptions.Dinner]: {
    text: StateTexts[StateOptions.Dinner],
    class: "state--dinner",
  },
  [StateOptions.Occupied]: {
    text: StateTexts[StateOptions.Occupied],
    class: "state--occupied",
  },
  [StateOptions.Services]: {
    text: StateTexts[StateOptions.Services],
    class: "state--services",
  },
  [StateOptions.Training]: {
    text: StateTexts[StateOptions.Training],
    class: "state--training",
  },
  [StateOptions.Disconnected]: {
    text: StateTexts[StateOptions.Disconnected],
    class: "state--disconnected",
  },
  [StateOptions.NotAvailable]: {
    text: StateTexts[StateOptions.NotAvailable],
    class: "state--notAvailable",
  },
  [EventManagement.Inactive]: {
    text: "Inactivo",
    class: "state--inactive",
  },
};

export default defineComponent({
  props: {
    record: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    time: {
      required: false,
      default: true,
    },
    detailedView: Boolean,
  },
  setup(props) {
    const store = useStore();

    const platformHour = computed(() => store.getters["getHourPlatform"]);
    const platformDate = computed(() => slashDateToDashed(store.getters["getPlatformDate"]));

    const platformMilliseconds = computed(
      () => new Date(`${platformDate.value} ${platformHour.value}`)?.valueOf() || 0
    );

    const stateChangeMilliseconds = computed(
      () => new Date(props.record?.last_state_change_time)?.valueOf() || 0
    );

    const counterInSeconds = computed(
      () =>
        millisecondsToSeconds(platformMilliseconds.value) -
        millisecondsToSeconds(stateChangeMilliseconds.value)
    );

    const isTimeShowed = computed(
      () => counterInSeconds.value >= 0 && props.record?.last_state_change_time
    );

    const counterWithTimeFormat = computed(() => secondsToTimeString(counterInSeconds.value || 0));

    return {
      StateValues,
      isTimeShowed,
      counterWithTimeFormat,
    };
  },
});
</script>

<style lang="sass" scoped>
.state
  display: inline-flex
  align-items: center

  &__indicator
    width: 8px
    height: 8px
    border-radius: 50%
    margin-right: 10px

  &--connected
    background-color: $green-6

  &--refreshment
    background-color: $gold-6

  &--dinner
    background-color: $orange-6

  &--occupied
    background-color: $red-6

  &--services
    background-color: $blue-6

  &--training
    background-color: $magenta-6

  &--disconnected
    background-color: $gray-6

  &--notAvailable
    background-color: $cyan-6

  &--inactive
    background-color: $gray-6

.time
  margin-left: 18px

  &.detailed
    margin: 0
    font-size: 14px

  &__container
    display: flex
    align-items: center
    padding-left: 8px
    border-left: 1px solid $gray-8

.divider
  background-color: $gray-8

.detailed
  display: flex
  align-items: center
  justify-content: space-between
</style>
