<template>
  <a-popover v-if="haveCallActive" placement="bottom" trigger="hover">
    <a-button class="button" type="link" v-if="detailedView">
      <div class="call-state">
        <div class="dot" :style="getStyles" />

        <span> {{ eventText[state] }} </span>
      </div>

      <div class="call-time">
        <span>{{ counterWithTimeFormat }}</span>
      </div>
    </a-button>

    <div v-else class="card" :style="getStyles" @click="$emit('click')">
      <div class="card__icon">
        <img :src="getCallImage(icon)" alt="Icono de llamada activa" />
      </div>
      <div class="card__content">
        <span> {{ identifier }} </span>
        <div>{{ isWaiting ? "" : dialing }}</div>
      </div>
      <div class="card__content">
        <div style="margin-top: 25px">{{ counterWithTimeFormat }}</div>
      </div>
    </div>
    <template #content v-if="showPop">
      <supervision-calling-popover
        :dialing="dialing"
        :identifier="identifier"
        :data="data"
        :state="state"
        @showModal="whisperAndHear($event)"
        @captureCall="handleCall"
      />
    </template>
  </a-popover>

  <template v-else>
    <div v-if="!detailedView" class="card-inactive" @click="$emit('click')">
      <div class="card__icon">
        <img :src="hangupInactiveImage" alt="Icono sin llamada activa" />
      </div>
      <div class="card__content-inactive">
        <span> + 00 000 000 </span>
        <div>Sin llamada activa</div>
      </div>
      <div class="card__content-inactive">
        <div style="margin-top: 25px">00:00:00</div>
      </div>
    </div>

    <div v-else class="call-state">
      <div class="dot dot-inactive" />

      <span class="inactive"> Sin llamada activa </span>
    </div>
  </template>
</template>

<script>
import { EventManagement } from "@/app/shared/utils/enums";
import { defineComponent, inject, watch } from "vue";
import moment from "moment-timezone";

import SupervisionCallingPopover from "../popover/SupervisionCallingPopover.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { dateTimeToTime, timeStringToSeconds, secondsToTimeString } from "@/app/shared/utils/dates";

import hangupInactiveImage from "@/app/supervision/assets/calls/hangup-inactive.svg?url";

const eventIcons = {
  [EventManagement.Ringing]: "ringing.svg",
  [EventManagement.Talking]: "talking.svg",
  [EventManagement.Acw]: "acw.svg",
  [EventManagement.Waiting]: "waiting.svg",
};

const eventColors = {
  [EventManagement.Ringing]: "#1890FF",
  [EventManagement.Talking]: "#52C41A",
  [EventManagement.Acw]: "#FA8C16",
  [EventManagement.Waiting]: "#13C2C2",
};

const eventBackgrounds = {
  [EventManagement.Ringing]: "#E6F7FF",
  [EventManagement.Talking]: "#F6FFED",
  [EventManagement.Acw]: "#FFF7E6",
  [EventManagement.Waiting]: "#E6FFFB",
};

const eventText = {
  [EventManagement.Ringing]: "Timbrando",
  [EventManagement.Talking]: "Hablando",
  [EventManagement.Acw]: "ACW",
  [EventManagement.Waiting]: "Esperando",
};

export default defineComponent({
  components: {
    SupervisionCallingPopover,
  },
  props: {
    data: [Object, null],
    last_acw_date: { type: String, default: null },
    last_waiting_time: { type: String, default: null },
    state: { required: false, type: Number, default: null },
    showPop: { required: true, type: Boolean, default: false },
    typeStrategy: { required: true, type: String, default: null },
    currentRecord: { required: true, type: Object },
    detailedView: Boolean,
  },
  emits: ["captureCurrentCall", "onStateTime"],
  setup(props) {
    const $jssip = inject("$jssip");
    const currentSupervisedCall = inject("currentSupervisedCall");

    const whisperAndHear = (modalData) => {
      $jssip.reset();
      console.log("props.currentRecord", props.currentRecord);
      console.log("props.modalData", modalData);
      console.log("props.data", props.data);
      currentSupervisedCall.value = { ...modalData, ...props.data, ...props.currentRecord };
      console.log("whisperAndHear", currentSupervisedCall.value);
    };

    const getCallImage = (icon) => {
      return new URL(`../../assets/calls/${icon}`, import.meta.url).href;
    };

    watch(
      () => props.currentRecord?.last_acw_date,
      () => {
        const { annex, last_acw_date } = props.currentRecord;

        if (annex === currentSupervisedCall.value?.annex && last_acw_date) {
          currentSupervisedCall.value = { ...currentSupervisedCall.value, ...props.currentRecord };
          console.log("WATCHER DEL CALL CARD", currentSupervisedCall.value);
        }
      }
    );

    return { whisperAndHear, eventText, getCallImage, hangupInactiveImage };
  },
  data() {
    return {
      starSendingTime: false,
      showModal: false,
      titleModal: "Escuchar",
      isMinimized: false,
      isMuted: false,
      role: "Agente",
      name: undefined,
    };
  },
  computed: {
    timezone() {
      return this.$store.getters["getDataUser"]?.customer?.timezone;
    },
    hourPlatform() {
      return this.$store.getters["getHourPlatform"];
    },
    /**
     * Time of the current state of the call (waiting, talking, acw) calculated with the current
     * time of the platform and the time returned in the endpoint.
     *
     */
    startTime() {
      let startTime = moment(this.data?.start_date)
        ?.tz(this.timezone)
        ?.format("DD/MM/YYYY HH:mm:ss");

      if (this.isWaiting) startTime = this.last_waiting_time;
      if (this.isAcw) startTime = this.last_acw_date;

      return dateTimeToTime(startTime) || "00:00:00";
    },
    counterInSeconds() {
      return timeStringToSeconds(this.hourPlatform) - timeStringToSeconds(this.startTime);
    },
    counterWithTimeFormat() {
      return secondsToTimeString(this.counterInSeconds || 0);
    },
    haveCallActive() {
      const states = [
        EventManagement.Ringing,
        EventManagement.Talking,
        EventManagement.Acw,
        EventManagement.Waiting,
      ];
      return states.includes(this.state);
    },
    isRinging() {
      return this.state == EventManagement.Ringing;
    },
    isTalking() {
      return this.state == EventManagement.Talking;
    },
    isAcw() {
      return this.state == EventManagement.Acw;
    },
    isWaiting() {
      return this.state == EventManagement.Waiting;
    },
    identifier() {
      if (this.isWaiting) {
        return "Esperando llamada";
      }
      const codeCountry = this.data.phone_code || "";
      return this.data && this.data.phone_number
        ? `${codeCountry} ${this.data.phone_number}`
        : "No identificado";
    },
    dialing() {
      if (this.data?.from_schedule) {
        return "Agenda";
      }
      return this.data?.type_dialing;
    },
    icon() {
      return eventIcons[this.state];
    },
    getStyles() {
      const color = eventColors[this.state];
      return {
        border: `1px solid ${color}`,
        backgroundColor: this.detailedView ? color : eventBackgrounds[this.state],
      };
    },
  },
  watch: {
    time(value) {
      if (value && this.starSendingTime) {
        EventBus.$emit(WorkspaceEvent.TIME_SUPERVITION, this.time);
        EventBus.emit("DATA_USER_PHONE", this.data);
      }
    },
    timeModal(value) {
      if (value === this.resetTime) {
        this.handleCancel();
      } else {
        return this.timeModal;
      }
    },
    state(value) {
      /**
       * This must be executed just if the modal is visible, because if just the state is validated
       * this will end the current call if one of the listed users state change to ACW.
       */
      if (value === EventManagement.Acw && this.showModal) this.handleCancel();
    },
  },
  methods: {
    handleCall(event) {
      this.$emit("captureCurrentCall", event);
    },
    handleCancel() {
      this.$jssip.end();
      this.showModal = false;
      EventBus.$emit(
        WorkspaceEvent.FINISH_IDENTIFIER_TIME,
        this.currentRecord?.active_call?.phone_number
      );
      this.$jssip.reset();
    },
  },
  created() {
    EventBus.$on(WorkspaceEvent.START_IDENTIFIER_TIME, (identifier) => {
      if (identifier === this.data?.phone_number) {
        this.starSendingTime = true;
      }
    });
    this.$emit("onStateTime", this.state);
    EventBus.$on(WorkspaceEvent.FINISH_IDENTIFIER_TIME, (identifier) => {
      if (identifier === this.data?.phone_number) {
        this.starSendingTime = false;
      }
    });

    EventBus.$on(WorkspaceEvent.STATE_SUPERVISION, (state) => {
      this.stateSupervision = state;
    });
  },
});
</script>

<style lang="sass" scoped>
.card
  display: grid
  grid-template-columns: 0.5fr 1.3fr 0.6fr
  min-width: 225px
  gap: 5px
  height: 50px
  left: 957px
  top: 255px
  border-width: 2px
  box-sizing: border-box
  border-radius: 5px
  cursor: pointer

  &__icon
    padding: 4px 0
    display: flex
    justify-content: center
    & > img
      width: 30px
      margin-left: 8px

  &__content
    display: flex
    flex-direction: column
    justify-content: space-around
    font-size: 11px
    width: 100%
    text-align: initial
  &__content-inactive
    font-size: 11px
    color: #BFBFBF
    display: flex
    flex-direction: column
    justify-content: space-around

  &__subtitle
    display: flex
    justify-content: space-between

.card-inactive
  height: 50px
  min-width: 225px
  left: 957px
  top: 255px
  background: #FAFAFA
  border: 1px solid #F0F0F0
  box-sizing: border-box
  border-radius: 5px
  display: grid
  grid-template-columns: 0.5fr 1.3fr 0.6fr
  gap: 5px
  border-width: 2px

.dot
  height: 8px
  width: 8px
  border-radius: 50%

  &-inactive
    background-color: $gray-6

.button
  padding: 0
  display: flex
  align-items: center
  justify-content: space-between
  height: 22px
  width: 100%

.inactive
  color: $gray-8

.call
  &-state
    display: flex
    align-items: center
    gap: 10px

  &-time
    padding-left: 8px
    border-left: 1px solid

.actions
  &__icon
    margin: 9% 45%

.flex
  display: flex

.text__call
  margin-bottom: 0
  font-size: 11px
  margin-left: 11px

.imagen__call
  display: flex
  align-items: center

.container__call
  display: flex
  border: 1px solid rgb(82, 196, 26)
  background-color: rgb(246, 255, 237)
  border-radius: 5px

.time
  margin-left: 30px
  font-size: 11px

.image--call
  width: 36px
  height: 27px
  margin-right: 12px
  margin-left: 10px

.text__number
  text-align: initial
  margin-bottom: 4px
  font-size: 11px
  margin-left: 20px
</style>
