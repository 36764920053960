import httpManager from "@/app/shared/utils/httpManager";

/**
 * Editar datos personales del cliente.
 * @param {number} ticketId Identificador del ticket del cliente.
 * @param {Object} personaInformation Datos personales del cliente.
 * @return {Promise<Object>} Datos personales editados.
 */
export async function updatePersonalInformation(ticketId, personaInformation) {
  const body = {};

  for (const key in personaInformation) {
    body[key] = personaInformation[key] || null;
  }

  const response = await httpManager.put(
    `/workspace/tickets/${ticketId}/clients/general-values/`,
    body
  );
  return response?.data;
}
