<template>
  <CustomScrollbar height="500px" class="custom-scroll-web-chat-config">
    <div class="modal-top-section">
      <h3 class="modal-top-section__title margin-0">Adicionales</h3>
      <p class="modal-top-section__subtitle">
        Configura el mensaje de inicio y el tiempo de reinicio.
      </p>
    </div>

    <a-form ref="formRef" :model="formState" :rules="rules">
      <a-form-item name="resetTime">
        <div class="flex-center gap-4 mb-5">
          <p class="step-web-chat-form__label step-web-chat-form__label--required">
            Tiempo de reinicio
          </p>
        </div>
        <p>Configura el mensaje de inicio y el tiempo de reinicio..</p>

        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          v-model:value="formState.timeOfLifeAfterLastMessage"
        >
          <a-select-option v-for="item in resetTimeList" :key="item.value" :value="item.value">
            {{ item.label }}
          </a-select-option>
        </a-select>
      </a-form-item>

      <div class="flex-center gap-4 mb-5">
        <p class="step-web-chat-form__label">Mensaje inicial</p>
        <a-tooltip placement="top">
          <template #title>
            <span>
              Si tienes un chatbot activo para este canal, este mensaje inicial podría ser
              redundante con el saludo del chatbot.
            </span>
          </template>
          <InfoCircleOutlined />
        </a-tooltip>
        <a-form-item name="isWelcomeMessageVisible" class="switch">
          <a-switch v-model:checked="formState.isWelcomeMessageVisible" />
        </a-form-item>
      </div>
      <label>Mensaje de bienvenida a los contactos cuando inician una conversación.</label>
      <a-form-item name="welcomeMessage">
        <a-textarea
          v-model:value="formState.welcomeMessage"
          class="mt-5"
          rows="4"
          placeholder="Escribir el mensaje inicial"
          :maxlength="maxLength"
          :disabled="!formState.isWelcomeMessageVisible"
        />
        <span class="counter"> {{ maxLengthWelcomeMsgCount }} / {{ maxLength }} </span>
      </a-form-item>

      <a-form-item name="messageToFinish">
        <div>
          <p class="step-web-chat-form__label step-web-chat-form__label--required">
            Mensaje de despedida
          </p>
          <label
            >Mensaje que indica al usuario que ya no puede interactuar en la conversación.</label
          >
        </div>
        <a-textarea
          v-model:value="formState.messageToFinish"
          class="mt-5"
          rows="4"
          placeholder="Mensaje de despedida"
          :maxlength="maxLength"
        />
        <span class="counter"> {{ maxLengthGoodbyeMsgCount }} / {{ maxLength }} </span>
      </a-form-item>
    </a-form>
  </CustomScrollbar>
</template>

<script setup>
import { ref, toRefs, reactive, watch, computed, onMounted } from "vue";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

const emit = defineEmits(["webChatOptionsUpdated"]);

const props = defineProps({
  resetTime: {
    type: String,
    default: "30",
  },
  hasWelcomeMsg: {
    type: Boolean,
    default: true,
  },
  welcomeMsg: {
    type: String,
    default: "Hola buenos días, un gusto saludarte, cuéntanos, ¿En qué podemos ayudarte?",
  },
  goodbyeMsg: {
    type: String,
    default: "",
  },
});

const { resetTime, hasWelcomeMsg, welcomeMsg, goodbyeMsg } = toRefs(props);

const formRef = ref();

const formState = reactive({
  timeOfLifeAfterLastMessage: "30",
  isWelcomeMessageVisible: true,
  welcomeMessage: "",
  messageToFinish: "",
});

const submitForm = async () => {
  try {
    await formRef.value.validate();
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: true,
    });
  } catch (error) {
    console.log("error", error);
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: false,
    });
  }
};

defineExpose({
  submitForm,
});

watch(
  formState,
  (newVal) => {
    emit("webChatOptionsUpdated", newVal);
  },
  { deep: true }
);

const maxLength = 250;
const maxLengthWelcomeMsgCount = computed(() => formState.welcomeMessage.length);
const maxLengthGoodbyeMsgCount = computed(() => formState.messageToFinish.length);

onMounted(() => {
  formState.timeOfLifeAfterLastMessage = resetTime.value;
  formState.isWelcomeMessageVisible = hasWelcomeMsg.value;
  formState.welcomeMessage = welcomeMsg.value;
  formState.messageToFinish = goodbyeMsg.value;
});

const resetTimeList = [
  {
    label: "10 minutos",
    value: "10",
  },
  {
    label: "20 minutos",
    value: "20",
  },
  {
    label: "30 minutos",
    value: "30",
  },
  {
    label: "40 minutos",
    value: "40",
  },
  {
    label: "50 minutos",
    value: "50",
  },
  {
    label: "60 minutos",
    value: "60",
  },
];

const rules = {
  messageToFinish: { required: true, message: "Campo requerido", trigger: "change" },
};
</script>

<style lang="sass" scoped>
@import '../sharedStyles'

.switch
  margin: 0
.ant-switch
  margin-left: 14px

.counter
  padding: 0 4px
  background: $white
  border-radius: 50%
  position: absolute
  right: 6px
  bottom: 4px
  font-size: $font-small
  color: $gray-6
</style>
