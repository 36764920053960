import { IMenutem } from "@/@types/emails";
import httpManager from "@/app/shared/utils/httpManager";
import { portfolioService } from "@/app/entities/services/Portfolio.service";
import { ChannelType } from "@/app/shared/utils/enums";

interface Identifier {
  id: number;
  alias: string;
  title: string;
  email: string;
}

export interface FilterEmailManager {
  page: number;
  entity: number;
  portfolio: number;
  identifier: number;
  search: string;
  startDate: string;
  endDate: string;
  identifiers: string[];
  emailStates: string[];
  tags: string[];
  contact_types: string[];
  users: string[];
}

interface EmailManager {
  key: number;
  number: number;
  id: number;
  contact: string;
  statusSent: number;
  email: string;
  userFullname: string;
  channelCustomer: string;
  identifier: string;
  alias: string;
  ticket: number;
  emailType: number;
  entity: string;
  portfolio: string;
  user_assigned: string;
  tags: [];
  lastDateInteraction: string;
  createdAt: string;
}

class EmailManagerService {
  private baseUrl = "/mail/threads/manager";

  async getAllEmailsManager(params: FilterEmailManager): Promise<EmailManager> {
    const {
      entity,
      portfolio,
      startDate,
      endDate,
      identifiers,
      emailStates,
      tags,
      contact_types,
      users,
      page,
      search,
    } = params;

    let url = `${this.baseUrl}/?offset=${50 * (page - 1)}&limit=50&entity=${
      entity ?? ""
    }&portfolio=${portfolio || ""}&start_date=${startDate}&end_date=${endDate}`;

    if (search) url += `&search=${search}`;
    if (page) url += `&page=${page}`;
    if (identifiers) url += `&channel_customer=${identifiers}`;
    if (emailStates && !emailStates?.includes(""))
      url += `&email_thread_status_multiple=${emailStates.toString()}`;
    if (tags && !tags?.includes("")) url += `&tag_ids=${tags.toString()}`;
    if (contact_types && !contact_types?.includes(""))
      url += `&contact_types=${contact_types.toString()}`;
    if (users && !users?.includes("")) url += `&assigned_user_ids=${users.toString()}`;

    const response = await httpManager.get(url);
    console.log(response);
    return {
      ...response.data,
      results: response.data.results.map((r: any) => ({
        key: r.id,
        number: r.id,
        id: r.id,
        contact: r.client,
        statusSent: r.email_thread_status,
        email: r.from_email,
        userFullname: r.assigned_user?.fullname,
        channelCustomer: r.channel_customer,
        identifier: r.channel_customer?.identifier,
        alias: r.channel_customer?.alias,
        ticket: r.ticket,
        emailType: r.last_email_message?.email_type,
        entity: r.portfolio?.entity,
        portfolio: r.portfolio?.name,
        user_assigned: r.assigned_user,
        tags: r.tag,
        lastDateInteraction: r.last_date_interaction,
        createdAt: r.last_email_message?.email_created_at,
        strategy_name: r.strategy_name,
      })),
    };
  }

  async getIdentifiers(userId?: number): Promise<Identifier[]> {
    const portfolios = await portfolioService.getAssignedChannelCustomers(
      ChannelType.Email,
      userId
    );

    const identifiers: Array<Identifier> = [];
    portfolios.forEach((p) => {
      p.channel_customer.forEach((i) => {
        identifiers.push({
          id: i.id,
          alias: i.alias || "",
          title: i.alias || i.identifier || "",
          email: i.identifier || "",
        });
      });
    });

    return identifiers;
  }

  async downloadEmailsManager(params: FilterEmailManager) {
    const {
      entity,
      portfolio,
      startDate,
      endDate,
      identifiers,
      emailStates,
      tags,
      contact_types,
      users,
    } = params;

    let url = `${this.baseUrl}/download/?&entity=${entity ?? ""}&portfolio=${
      portfolio || ""
    }&start_date=${startDate}&end_date=${endDate}&page=`;

    if (identifiers) url += `&channel_customer=${identifiers}`;
    if (emailStates && !emailStates?.includes(""))
      url += `&email_thread_status_multiple=${emailStates.toString()}`;
    if (tags && !tags?.includes("")) url += `&tag_ids=${tags.toString()}`;
    if (contact_types && !contact_types?.includes(""))
      url += `&contact_types=${contact_types.toString()}`;
    if (users && !users?.includes("")) url += `&assigned_user_ids=${users.toString()}`;

    const response = await httpManager.getBlob(url);
    return response;
  }

  async fetchEmailsAdvancedFiltersData(params: any) {
    const { entity, portfolio, startDate, endDate } = params;

    const response = await httpManager.get(
      `/tickets/manager/advanced-filters/?entity=${entity ?? ""}&portfolio=${
        portfolio || ""
      }&start_date=${startDate}&end_date=${endDate}`
    );
    return response?.data;
  }

  async getTagEmailsDetails(tagId: number) {
    const response = await httpManager.get(`/tickets/tags/${tagId}/`);
    return response?.data;
  }

  async tagEmails(params: any) {
    const { ticketsId, tagId } = params;

    const body = {
      tickets: ticketsId,
      tag: tagId,
    };

    await httpManager.post(`/tickets/assign-tag/`, body);
  }

  async downloadFile(urlDownload: string) {
    const url = `/customers/file/download/`;
    const body = {
      url: urlDownload,
    }
    const response = await httpManager.post(url, body, { blob: true });
    return response;
  }
}

export const emailManagerService = new EmailManagerService();
