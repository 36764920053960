import { MutationTree } from "vuex";
import { State } from "@/@types/emailsManager";
import {
  SET_TABLE_PAGE,
  SET_BASIC_FILTERS,
  SET_EMAILS_ADVANCED_FILTERS,
  SET_EMAILS,
  SET_TAG_TO_EMAILS,
  SET_ADVANCED_FILTERS_DATA,
  SET_USERS_DATA,
} from "./mutation-types";

const mutations: MutationTree<State> = {
  [SET_EMAILS](state, data) {
    state.emailsData = data;
  },
  [SET_TAG_TO_EMAILS](state, payload) {
    const { emailsId, tagDetails } = payload;

    for (const emailId of emailsId) {
      const emailIndex = state.emailsData.results.findIndex((email: any) => email.id === emailId);

      const currentTicket = state.emailsData.results[emailIndex];

      if (!currentTicket.tags.includes(tagDetails.id)) currentTicket.tags.push(tagDetails);
    }
  },
  /*[EDIT_EMAIL_TAGS](state, payload) {
    const { emailId, emailData } = payload;

    const emailIndex = state.emailsData.results.findIndex((email) => email.id === emailId);

    state.emailsData.results[emailIndex] = emailData;
  },*/
  [SET_TABLE_PAGE](state, tableEmailsPage) {
    state.tableEmailsPage = tableEmailsPage;
  },
  [SET_BASIC_FILTERS](state, basicFilters) {
    state.emailsBasicFilters = basicFilters;
  },
  [SET_EMAILS_ADVANCED_FILTERS](state, emailsAdvancedFilters) {
    state.emailsAdvancedFilters = emailsAdvancedFilters;
  },
  [SET_ADVANCED_FILTERS_DATA](state, data) {
    state.emailsAdvancedFiltersData = data;
  },
  [SET_USERS_DATA](state, data) {
    state.usersData = data;
  },
};

export default mutations;
