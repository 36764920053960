<template>
  <a-form-item :label="label">
    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      v-model:value="selected"
      class="form__select"
    >
      <a-select-option :key="0"> Todos los usuarios </a-select-option>
      <a-select-option v-for="item in portfolios" :key="item.id">
        {{ item.fullname }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script>
import { fetchUsersByCampaign } from "@/app/users/services";
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Number,
    },
    campaign: {
      required: false,
      type: Number,
      default: 0,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
  },
  data() {
    return {
      portfolios: [],
    };
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
  watch: {
    campaign(value) {
      this.selected = 0;
      this.fetchAgent(value);
    },
  },
  created() {
    this.fetchAgent();
  },

  methods: {
    async fetchAgent() {
      this.portfolios = await fetchUsersByCampaign(this.campaign);
    },
  },
});
</script>

<style lang="sass" scoped>
.form__select
  width: 200px
</style>
