const initialState = () => ({
  didsInfo: {
    pagination: {},
    items: [],
  },
  did: {},
});

const state = initialState();

export default state;
