const REPOSITORY_KEY = "tickets";

class TicketsStorage {
  repository: Storage;

  constructor() {
    this.repository = window.localStorage;
  }

  getTickets() {
    const tickets = this.repository.getItem(REPOSITORY_KEY) || "{}";
    return JSON.parse(tickets);
  }

  getTypification(ticketId: number) {
    const tickets = this.getTickets();
    return tickets?.[ticketId];
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  saveTypification(ticketId: number, typification: Record<number, Record<number, any>>) {
    const tickets = this.getTickets();
    tickets[ticketId] = typification;
    this.repository.setItem(REPOSITORY_KEY, JSON.stringify(tickets));
  }

  clearTypification(ticketId: number) {
    const tickets = this.getTickets();
    delete tickets[ticketId];
    this.repository.setItem(REPOSITORY_KEY, JSON.stringify(tickets));
  }
}

export default new TicketsStorage();
