import { store } from "@/store";
import VueSocketIO from "vue-3-socket.io";

const local = false;

const connection = local ? "http://localhost:8081" : `${import.meta.env.VITE_SOCKET}`;

/**
 * Example how to user the vue-3-socket.io library inside the composition API
 *
 *    const socket = inject("socket");
 *
 *    socket.on("connect", () => {
 *      // Here goes the logic
 *    });
 */

export const socketIO = new VueSocketIO({
  debug: true,
  connection,
  vuex: {
    store,
    actionPrefix: "SOCKET_",
    mutationPrefix: "SOCKET_",
  },
  options: {
    reconnection: true,
  }, // Optional options
});
