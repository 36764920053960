<template>
  <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M1.48633 8.00108L8.49872 14.1369V10.5823C10.7053 10.347 12.5294 10.9746 14.0273 12.4725L15.1654 13.6106V12.0011C15.1654 7.82405 12.8645 5.55535 8.49872 5.34976V1.86523L1.48633 8.00108ZM3.51133 8.00178L7.1656 4.8043V6.66845H7.83227C11.2994 6.66845 13.1992 7.90247 13.6981 10.4795C12.0026 9.34479 9.99906 8.96479 7.72267 9.34419L7.1656 9.43703V11.1993L3.51133 8.00178Z"
      :fill="active ? '#1677FF' : '#BFBFBF'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
