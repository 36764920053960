<template>
  <div class="list">
    <template v-if="groupedInteractionsChannels?.length">
      <ChannelDropdownButton
        v-for="channelGroup in groupedInteractionsChannels"
        :key="channelGroup.icon"
        :groupedChannel="channelGroup"
      />
    </template>

    <template v-else>
      <a-skeleton-button class="skeleton" :active="active" />
    </template>
  </div>
</template>

<script>
import { inject, computed } from "vue";

import { groupBy } from "@/app/ticketsManager/utils/groupBy";

import ChannelDropdownButton from "@/app/workspace/components/buttons/ChannelDropdownButton.vue";

export default {
  components: {
    ChannelDropdownButton,
  },
  props: {
    items: {
      required: true,
      type: Array,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const ticketInteractionsChannels = inject("ticketInteractionsChannels");

    const groupedInteractionsChannels = computed(() =>
      groupBy(ticketInteractionsChannels.value, (interaction) => interaction?.channel?.icon)
    );

    return { ticketInteractionsChannels, groupedInteractionsChannels };
  },
  computed: {
    phoneStatus() {
      return this.$jssip.phone.status;
    },
    globalState() {
      return this.$store.getters["getStateUser"];
    },
    dialingType() {
      return this.$store.getters["getDialingType"];
    },
    dataUserState() {
      return this.$store.getters["getDataUserState"];
    },
  },
};
</script>

<style lang="sass" scoped>
.list
  display: flex
  position: relative
  align-items: center
  overflow-y: clip
  gap: 4px

  // &__item
  //   margin: 0 4px
  //   display: flex
  //   align-items: center
  //   position: relative
  //   cursor: pointer

  //   &--active::before
  //     content: ''
  //     height: 2px
  //     width: 100%
  //     position: absolute
  //     border-radius: 8px
  //     background-color: $blue-6
  //     bottom: -4px
  //     left: 0
  //     right: 0

/* Works on Chrome, Edge, and Safari */
.list::-webkit-scrollbar
  position: absolute
  height: 4px

.list::-webkit-scrollbar-track
  background: transparent

.list::-webkit-scrollbar-thumb
  background-color: #c1c1c1
  border-radius: 2px

/* Works on Firefox */
.list
  scrollbar-width: thin
  scrollbar-color: #c1c1c1 transparent

.skeleton
  height: 32px
  width: 47px
  border-radius: 6px
</style>
