export const statuses = {
  none: "None",
  connecting: "Conectando...",
  ringing: "Timbrando...",
  "in-call": "En llamada",
  failed: "Llamada cancelada",
  "no-anwser": "No contesta...",
  transfered: "Llamada transferida",
  finished: "Llamada finalizada",
};
