const getters = {
  getTemplateClientHeaders(state) {
    return state.clients;
  },
  getTemplateProductHeaders(state) {
    return state.products;
  },
};

export default getters;
