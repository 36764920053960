<template>
  <div class="email">
    <a-modal
      :open="open"
      :wrapClassName="displayModalClass"
      @cancel="handleClose"
      destroyOnClose
      :maskClosable="false"
      width="100%"
      :footer="null"
      :mask="displayMask"
    >
      <div class="general" v-if="!(status === 'minimized')">
        <div class="header">
          <h3>Redactar nuevo correo</h3>
          <div class="actions">
            <LineOutlined @click="minimizeModal" />
            <ExpandAltOutlined
              @click="expandModal"
              v-if="!(status === 'expanded') && !replyEmail"
            />
            <CloseOutlined @click="handleClose" />
          </div>
        </div>
        <!-- <RedactEmailForm
          ref="redactFormRef"
          :defaultEmail="props.defaultEmail"
          :emailTransmitter="props.emailTransmitter"
          @handleChangeEmailFrom="$emit('handleChangeEmailFrom')"
        /> -->
        <RedactEmailForm
          ref="redactFormRef"
          :emailTransmitter="props.emailTransmitter"
          @handleChangeEmailFrom="$emit('handleChangeEmailFrom')"
        />
        <!-- <footer> -->
        <a-button @click="handleClose">Cancelar</a-button>
        <a-button type="primary" :loading="sending" @click="handleOk">Enviar</a-button>
        <!-- </footer> -->
      </div>
      <MinimizedEmail
        v-if="status === 'minimized'"
        :status="status"
        @emitExpandEvent="handleStatus"
        @emitCloseEvent="handleVisibility"
        :open="open"
      />
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, inject, computed, Ref, nextTick } from "vue";
import { CloseOutlined, ExpandAltOutlined, LineOutlined } from "@ant-design/icons-vue";
import MinimizedEmail from "./status/MinimizedEmail.vue";
import RedactEmailForm from "@/app/emails/components/forms/RedactEmailForm.vue";
import { message } from "ant-design-vue";
import { emailService } from "@/app/emails/services/Email.service";
import { SendEmailDto } from "@/@types/emails/dtos";
import { EmailForm } from "@/@types/emails";
import { ResponseError } from "@/app/shared/utils/httpManager";

import HslToHex from "@/app/shared/utils/hslToHex";
// import InputSection from "@/app/workspace/components/chat/input/InputSection.vue";

const props = defineProps<{
  open: boolean;
  isReply: { type: boolean; default: false };
  emailTransmitter: { type: boolean; default: false };
  // defaultEmail: {
  //   type: EmailForm;
  //   default: {
  //     fromEmail: null;
  //     selectedItems: [];
  //     selectedItemsCC: [];
  //     selectedItemsCCO: [];
  //     subject: "";
  //     text: "";
  //   };
  // };
}>();

const status = ref("normal");
const replyEmail = inject("replyEmail") as Ref<boolean>;
const redactFormRef = ref<InstanceType<typeof RedactEmailForm>>();
const hslToHex = new HslToHex();

const displayModalClass = computed(() => {
  if (status.value === "normal") {
    return replyEmail.value ? "full-modal" : "email__modal";
  } else if (status.value === "minimized") {
    return "email__modal";
  } else {
    return "full-modal";
  }
});

const displayMask = computed(() => {
  if (status.value === "expanded" || displayModalClass.value === "full-modal") return true;
  return false;
});

// const onClose = (removedTag) => {
//   const newSelectedItems = selectedItems.value.filter((item) => item !== removedTag);
//   selectedItems.value = newSelectedItems;
// };

const emit = defineEmits<{
  "update:open": [open: boolean];
  updateList: [];
  handleChangeEmailFrom: [];
  // close: [defaultEmail: EmailForm];
}>();

defineExpose({ redactFormRef: redactFormRef.value });

const sending = ref(false);

const handleOk = async () => {
  try {
    console.log(redactFormRef.value?.formState?.text, 'redactFormRef.value?.formState?.text');
    if (redactFormRef.value?.formState?.text) {
      sending.value = true;
      const form = redactFormRef.value?.formState;
      const files = redactFormRef.value?.files;

      if (!form) throw new Error("No email data");
      if (!form.fromEmail) throw new Error(" No selected email");

      const convertedHtml = hslToHex.convertHslToHex(form.text);
      const body: SendEmailDto = {
        channel_customer: form.fromEmail,
        to_emails: form.selectedItems.join(","),
        // message: form.text,
        message: convertedHtml,
        subject: form.subject,
        cc_emails: form.selectedItemsCC.join(","),
        bcc_emails: form.selectedItemsCCO.join(","),
        attachments: files,
      };

      await emailService.sendEmail(body);

      emit("update:open", false);
      emit("updateList");
      status.value = "normal";
      message.success("Se ha enviado el correo con éxito");
    } else {
      message.error("El mensaje de correo es requerido");
    }
  } catch (error: any) {
    console.error(error);
    let errorMessage = "Ha ocurrido un error";
    if (error instanceof ResponseError) errorMessage = error.getErrorMessage();
    message.error(errorMessage);
  }

  sending.value = false;
  
};
const handleClose = () => {
  emit("close", redactFormRef.value?.formState);
  nextTick(() => {
    status.value = "normal";
    replyEmail.value = false;
    emit("update:open", false);
  });
};
const minimizeModal = () => {
  status.value = "minimized";
};
const expandModal = () => {
  status.value = "expanded";
};
const handleStatus = (message: string) => {
  status.value = message;
};
const handleVisibility = (message: boolean) => {
  emit("update:open", message);
  status.value = "normal";
};

watch(
  () => status.value,
  () => {
    console.log(status.value, "status.value");
  }
);
</script>
<style lang="sass" scoped>
p
  margin: 0
.email__modal
  .ck-content
    height: 300px
.full-modal
  .ant-form-item
    margin-bottom: 7px
.header
  display: flex
  justify-content: space-between
  padding-bottom: 12px
  .actions
    display: flex
    gap: 15px
footer
  display: flex
  justify-content: flex-end
  gap: 12px
  padding: 10px 10px 0
.flex-container
  display: flex
  justify-content: space-between
  align-items: center
</style>
<style>
.email__modal {
  width: 100%;
  max-height: 100%;
  position: relative;
}
.email__modal .ant-modal {
  width: 100%;
  min-height: 73vh;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
}

.email__modal .ant-modal-content {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 630px;
}
.email__modal .ck-content {
  min-height: 300px;
}
.reply .ck-content {
  min-height: 200px;
}
.full-modal .ant-modal {
  position: absolute;
  top: 52%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.full-modal .ck-content {
  min-height: 300px;
}
.email__modal .ant-modal-close,
.full-modal .ant-modal-close {
  display: none;
}

.email__modal .ant-form-item .ant-form-row .ant-form-item-label,
.full-modal .ant-form-item .ant-form-row .ant-form-item-label,
.reply .ant-form-item .ant-form-row .ant-form-item-label {
  width: 55px;
  text-align: left;
}
.email__modal .ant-select-multiple .ant-select-selection-overflow {
  max-width: 94%;
}
</style>
