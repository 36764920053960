import httpManager from "@/app/shared/utils/httpManager";

/**
 * Asignar nuevo cliente.
 * @param {Object} body Datos del cliente.
 *
 * @return {Promise<Object>} Datos del cliente asignado
 */
export async function assignClient(body) {
  const response = await httpManager.post("/tickets/assign-client/", body);
  return response?.data;
}
