import { updateLoggedBusiness } from "../../services";

import { SET_MY_ACCOUNT_VIEW_STATUS, SET_SECURITY_PERMITS, SET_BUSINESS } from "./mutation-types";

const actions = {
  async setMyAccountViewStatus({ commit }, settings) {
    commit(SET_MY_ACCOUNT_VIEW_STATUS, settings);
  },
  async setSecurityPermits({ commit }, settings) {
    commit(SET_SECURITY_PERMITS, settings);
  },
  async updateLoggedBusiness({ commit }, business) {
    console.log("AAAA", business);
    const enable_bases = await updateLoggedBusiness(business);
    commit(SET_BUSINESS, enable_bases);
  },
};

export default actions;
