<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :required="isRequired"
  >
    <p class="instruction">{{ input.instruction }}</p>

    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      :value="workspaceFormState?.[input.name]"
      placeholder="Seleccione"
      show-search
      :filter-option="filterOption"
      @update:value="handleChange"
    >
      <a-select-option
        v-for="option in input.options"
        :key="option.id"
        :value="option.value"
        :disabled="conditionedAttributes?.disabled"
      >
        {{ option.value }}
      </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";
import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";

const props = defineProps<{
  input: FormElementMap[ElementType.Dropdown];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const workspaceFormState = inject("workspaceFormState") as Record<string, string | null>;

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const handleChange = (value: string | null) => {
  if (!props.isWorkspace) return;

  workspaceFormState[props.input.name] = value;
};

const initialValue = null;

const filterOption = (input: string, option: { value: string }) => {
  const foundedOption = props.input.options.find((opt) => opt.value === option.value);

  return foundedOption
    ? foundedOption.value.toLowerCase()?.indexOf(input.toLowerCase()) >= 0
    : false;
};

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px
</style>
