<template>
  <div class="example">
    <apexcharts
      width="250"
      height="250"
      type="bar"
      :options="chartOptions"
      :series="series"
    ></apexcharts>
  </div>
</template>

<script>
import VueApexCharts from "vue3-apexcharts";

export default {
  name: "Chart",
  components: {
    apexcharts: VueApexCharts,
  },
  props: {
    labels: {
      type: Array,
      required: true,
    },
    options: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      chartOptions: {
        chart: {
          id: "basic-bar",
          toolbar: {
            show: false,
          },
        },
        colors: ["#FFE58F"],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          categories: this.labels,
        },
        tooltip: {
          enabled: true,
          style: {
            fontSize: "12px",
          },
          x: {
            formatter: function (val) {
              switch (val) {
                case "Lu":
                  return "Lunes";
                case "Ma":
                  return "Martes";
                case "Mi":
                  return "Miercoles";
                case "Ju":
                  return "Jueves";
                case "Vi":
                  return "Viernes";
                case "Sa":
                  return "Sabado";
                case "Do":
                  return "Domingo";
                default:
                  return val;
              }
            },
          },
          y: {
            formatter: function (val) {
              return `Tickets: ${val}`;
            },
          },
        },
      },
    };
  },
  computed: {
    series() {
      return [
        {
          name: "",
          data: this.options,
        },
      ];
    },
  },
};
</script>
