import axios from "axios";
import { router } from "@/router";
import { tokenManager } from "@/app/shared/utils/tokenManager";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { isPublicPath } from "@/app/shared/utils/helpers";
import { refreshToken as fetchNewAccessToken } from "@/app/auth/services/requests/fetchRefreshToken";

const baseURL = import.meta.env.VITE_HOST;

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  (config) => {
    console.log("AXIOS REQUEST", config);

    if (config.isPublic) {
      config.headers["Authorization"] = `Basic ${import.meta.env.VITE_PUBLIC_TOKEN}`;
    } else {
      const accessToken = tokenManager.getToken();

      if (accessToken) config.headers["Authorization"] = `Bearer ${accessToken}`;
    }

    return config;
  },
  async (error) => Promise.reject(error)
);

let refreshTokenRequestAlreadyInProcess = false;
let queuedRequest = [];

const queueRequestUntilRefreshToken = async () => {
  const refreshToken = tokenManager.getRefreshToken();
  const { access: newAccessToken } = await fetchNewAccessToken(refreshToken);

  tokenManager.saveToken(newAccessToken);

  refreshTokenRequestAlreadyInProcess = false;

  queuedRequest.forEach((cb) => cb());
  queuedRequest = [];
};

instance.interceptors.response.use(
  (response) => {
    EventBus.emit(WorkspaceEvent.RESET_INACTIVITY_TIME);
    return response;
  },
  async (error) => {
    const config = error.config;
    console.log("CONFIG AXIOS", config);
    console.log("RESPONSE AND VALIDATION", {
      isPublicPath: isPublicPath(),
      response: error.response,
    });

    if (!isPublicPath() && config.url !== "/token/refresh/") {
      if (error.response?.status === 401 && !config._retry) {
        config._retry = true;

        if (!refreshTokenRequestAlreadyInProcess) {
          refreshTokenRequestAlreadyInProcess = true;

          try {
            await queueRequestUntilRefreshToken();

            return instance(config);
          } catch (e) {
            console.log("AXIOS ERROR", e?.response);
            tokenManager.removeToken();
            const workspace = localStorage.getItem("workspace");
            const isEmbed = window.location.href.includes("embed=true");

            console.log(workspace, "axios.js");

            if (workspace && !isEmbed) {
              router.push("/login");
              console.log("Si redirige cuando hay workspace en localstorage");
            }

            return Promise.reject(e);
          }
        } else {
          return new Promise((resolve) => {
            queuedRequest.push(() => {
              resolve(instance(config));
            });
          });
        }
      }
    }

    return Promise.reject(error);
  }
);

export default instance;
