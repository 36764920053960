<template>
  <div class="bottom__profile">
    <img
      v-if="record.user?.picture"
      :src="record.user?.picture"
      class="image-record"
      :style="{ height: size, width: size }"
      alt="profile image"
    />
    <div
      v-else
      class="background__profile"
      :class="record.user?.role?.name === 'Agente' ? 'background__agent' : ''"
      :style="{ height: size, width: size }"
    >
      <img
        v-if="record.user?.icon"
        class="user__profile"
        :class="record.user.role.name === 'Agente' ? 'user__agente' : ''"
        :src="getProfileImage(record.user?.icon || iconDefault)"
        alt="perfil"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    record: {
      type: Object,
      required: true,
    },
    size: {
      type: String,
      default: "40px",
    },
  },
  computed: {
    iconDefault() {
      return "smile.svg";
    },
  },
  methods: {
    getProfileImage(profile) {
      return new URL(`../../../users/assets/profiles/${profile}`, import.meta.url).href;
    },
  },
});
</script>

<style lang="sass" scoped>
.user__icon
  width: 26px
  height: 26px
  margin: 6px
  border-radius: 50%

.user__profile
  // width: 65%
  height: 67%
  margin: 6px
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.user__agente
  width: 29px
  height: 26px
  margin: 6px
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.background__profile
  background-color: rgb(230, 247, 255)
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  margin-right: 3px
  height: 40px
  width: 40px
  margin: 0 auto

.background__agent
  background: #FFF6E2
  border-radius: 50%
  display: flex
  align-items: center
  justify-content: center
  height: 40px
  width: 40px
  margin: 0 auto

.image-record
  width: 40px
  height: 40px
  border-radius: 50%
</style>
