<template>
  <section>
    <input class="line__gray" />
    <div class="text__profile">
      <p class="name__business">Datos de mi empresa</p>
    </div>
    <section class="flex">
      <div class="information">
        <div class="container__imagen">
          <img :src="ellipseImage" style="width: 160px" alt="empresa" />
          <div class="bottom__profile">
            <image-picker
              :value="imageFile"
              @update:value="imageFile = $event"
              :src="imageSrc || imageDefault"
              @change="handleChangeInput"
              @onSelectedImage="handleSelectedImagen"
              :selectedImage="selectedImage"
              :isBusiness="isBusiness"
            />
          </div>
        </div>
        <div class="container__group">
          <p class="info--name">
            <img src="@/app/settings/assets/profile/briefcase.png" class="right" alt="empresa" />{{
              business?.name
            }}
          </p>
          <div class="top">
            <img
              :src="`${business.data_country.url_flag}`"
              class="right"
              alt="país"
              style="width: 20px"
            />
            <span>{{ business.data_country.name }}</span>
          </div>
          <div class="top">
            <img src="@/app/settings/assets/profile/global.png" class="right" alt="tiempo" />
            <span>Zona horaria: (GMT - {{ business?.data_time.time }})</span>
          </div>
        </div>
        <div class="line-yellow"></div>
      </div>
      <div class="form">
        <a-form layout="vertical" ref="formRef" :model="formState" :rules="rules">
          <a-row :gutter="12" type="flex">
            <a-col span="12">
              <a-form-item label="Nombre de la empresa" name="name">
                <a-input v-model:value="formState.name" @input="handleChangeInput" />
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="Teléfono de contacto" name="phone">
                <a-row :gutter="12" type="flex">
                  <a-col :span="7">
                    <selector-country
                      :code="code_phone"
                      @selected="code_phone = $event"
                      @change="handleChangeInput"
                    />
                  </a-col>
                  <a-col :span="17">
                    <a-input
                      v-model:value="formState.phone"
                      placeholder="Número de teléfono"
                      @input="handleChangeInput"
                    />
                  </a-col>
                </a-row>
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col span="6">
              <a-form-item label="Número de colaboradores" name="type_size">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  v-model:value="formState.type_size"
                  @change="handleChangeInput"
                >
                  <a-select-option :key="1">1-10</a-select-option>
                  <a-select-option :key="2">11-20</a-select-option>
                  <a-select-option :key="3">21-50</a-select-option>
                  <a-select-option :key="4">51-100</a-select-option>
                  <a-select-option :key="5">100-200</a-select-option>
                  <a-select-option :key="6">200 a más</a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="6">
              <a-form-item label="Industria" name="type_industry">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  v-model:value="formState.type_industry"
                  @change="handleChangeInput"
                >
                  <a-select-option v-for="industry of industries" :key="industry.id">
                    {{ industry.name }}
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="Web de la empresa" name="website">
                <a-input
                  v-model:value="formState.website"
                  @input="handleChangeInput"
                  placeholder="Escribir web de la empresa"
                />
              </a-form-item>
            </a-col>
          </a-row>
          <a-row :gutter="24">
            <a-col span="12">
              <a-form-item label="País de la empresa" name="country">
                <a-select
                  :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                  v-model:value="formState.country"
                  show-search
                  :filter-option="false"
                  :not-found-content="null"
                  placeholder="Seleccionar país"
                  @search="searchCountries"
                  @dropdownVisibleChange="handleChangeCountry"
                  @keypress="handleSearchCountry($event)"
                  @change="handleChangeInput"
                >
                  <a-select-option v-for="country in allCountries" :key="country.id">
                    <div>
                      <img class="flag" :src="country.url_flag" alt="país" />
                      <span> {{ country.name }}</span>
                    </div>
                  </a-select-option>
                </a-select>
              </a-form-item>
            </a-col>
            <a-col span="12">
              <a-form-item label="Dirección de la empresa" name="address">
                <a-input
                  v-model:value="formState.address"
                  placeholder="Escribir dirección"
                  @input="handleChangeInput"
                />
              </a-form-item>
            </a-col>
          </a-row>
        </a-form>
      </div>
    </section>
  </section>
</template>

<script>
import { updateLoggedBusiness } from "@/app/settings/services";
import { defineComponent, ref, reactive, toRaw, computed, watch } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { useStore } from "vuex";
import ImagePicker from "@/app/shared/components/molecules/ImagePicker.vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import briefcaseImage from "@/app/entities/assets/briefcase-blue.svg?url";
import ellipseImage from "@/app/settings/assets/profile/ellipse.svg?url";

export default defineComponent({
  components: {
    SelectorCountry,
    ImagePicker,
  },
  props: {
    business: { required: true, type: Object },
  },
  setup(props) {
    const formRef = ref();
    const imageFile = ref(undefined);
    const store = useStore();
    const countries = computed(() => store.getters["getAllCountries"]);
    const showCountries = ref(false);
    const isBusiness = ref(true);
    const allCountries = ref([]);
    const showConfirmModal = ref(false);
    const formState = reactive({
      name: props.business?.name,
      website: props.business?.website,
      type_industry: props.business?.type_industry,
      address: props.business?.address,
      type_size: props.business?.type_size,
      phone: props.business?.phone || undefined,
      country: props.business?.country || undefined,
    });
    const rules = {
      name: [{ required: true, message: "Campo requerido" }],
      country: [{ required: true, message: "Campo requerido" }],
      type_industry: [{ required: true, message: "Campo requerido" }],
      type_size: [{ required: true, message: "Campo requerido" }],
    };
    watch(countries, (newValue) => {
      allCountries.value = newValue;
    });
    return {
      rules,
      formState,
      formRef,
      countries,
      showCountries,
      allCountries,
      imageFile,
      showConfirmModal,
      isBusiness,
      briefcaseImage,
      ellipseImage,
    };
  },
  data() {
    return {
      imageSrc: "",
      code_phone: 1,
      selectedImage: false,
    };
  },
  computed: {
    industries() {
      return this.$store.getters["getAllIndustries"];
    },
    imageDefault() {
      let url = "";
      url = briefcaseImage;
      return url;
    },
  },
  created() {
    this.$store.dispatch("fetchAllCountries");
    this.setFieldsValue();
  },
  methods: {
    handleChangeInput() {
      this.$emit("onChangeInputBusiness", true);
      EventBus.$emit(WorkspaceEvent.SHOW_CONFIRM_MODAL);
    },
    handleSearchCountry(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[A-Za-z]+$/.test(char)) return true;
      else e.preventDefault();
    },
    setFieldsValue() {
      this.imageSrc = this.business?.picture;
      this.code_phone = this.business?.code_phone || 1;
    },
    // This method is used by $refs
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          const values = toRaw(this.formState);
          const formData = new FormData();
          if (this.imageFile) formData.append("picture_file", this.imageFile);
          formData.append("name", values.name);
          if (this.formState.website) formData.append("website", values.website);
          formData.append("type_industry", values.type_industry);
          if (this.formState.address) formData.append("address", values.address);
          formData.append("country", values.country);
          formData.append("imageSrc", this.imageSrc);
          formData.append("type_size", values.type_size);
          if (this.formState.phone) formData.append("phone", values.phone);
          formData.append("code_phone", this.code_phone);
          await updateLoggedBusiness(formData);
          this.$emit("update");
          this.$emit("onChangeInputBusiness", false);
        } catch (error) {
          this.$message.error("Algo salió mal");
        }
      });
    },
    searchCountries(value) {
      value = value.toLowerCase();
      this.allCountries = this.countries.filter((item) => {
        return item.name.toLowerCase().includes(value);
      });
    },
    handleChangeCountry(data) {
      this.showCountries = data;
      this.allCountries = this.countries;
    },
    handleSelectedImagen(e) {
      this.selectedImage = e;
    },
  },
});
</script>

<style lang="sass" scoped>
.form
  margin-left: 100px
  width: 60%
  margin-top: 30px
  margin-right: 30px

.flag
  width: 20px
  margin-bottom: 2px


.label
  margin-bottom: 8px

.text__profile
  font-size: 24px
  font-weight: 600
  margin-left: 20px

.flex
  display: flex

.information
  margin-top: 8px
  margin-left: 70px
  text-align: center
  margin-bottom: 30px

.info
  margin-top: 32px
  &--name
    font-size: 16px
    font-weight: 600

.line-yellow
  height: 10px
  background: $yellow-6
  width: 350px
  border-radius: 5px
  margin-top: 30px

.right
  margin-right: 5px

.top
  margin-top: 16px

.line__gray
  height: 0.5px
  margin-left: 20px
  background: $gray-6
  border: 10px
  width: 97%
  margin-right: 20px

.flag
  width: 20px
  margin-bottom: 2px
  margin-right: 4px

.container__imagen
  margin-top: 30px

.bottom__profile
  margin-top: -126px
  width: 130px
  margin-inline: auto

.container__group
  margin-top: 40px

.name__business
  margin-top: 22px
</style>
