<template>
  <section>
    <div class="information">
      <p class="text__info"><a @click="handleOpenMySecurity">Restablecer contraseña</a></p>
      <p v-if="userData?.password_change_date?.length > 1">
        Restablecido por última vez el {{ formatDate(userData?.password_change_date) }}
        <!-- {{ formatTime(userData?.password_change_date) }} -->
      </p>
    </div>

    <div class="information" v-if="userData?.role?.name === 'Owner'">
      <p class="text__strong">Eliminar ambiente de trabajo permanentemente</p>
      <div class="information__password">
        <span>
          Si consideras que no volverás a usar {{ ProductName }} de nuevo, puedes solicitar que tu
          ambiente de trabajo se elimine difinitivamente. Recuerda que después de 7 días, no podrás
          reactivar ni recuperar la información de tu empresa
        </span>
      </div>
      <a-button class="button__delete" type="danger" @click="handleDeleteWorkTeam"
        >Eliminar ambiente de trabajo</a-button
      >
    </div>
    <ModalResetPassword :visible="visibleModalSecurity" @onCloseModal="handleCloseModal" />
    <ModalDeleteWorkTeam
      :visible="visibleModalWorkTeam"
      @onCloseModal="handleCloseModal"
      :record="userData"
    />
  </section>
</template>

<script>
import {
  updateCompanyDataAdmin,
  fetchCompanyDataAdmin,
  refreshMinutesToExpire,
} from "@/app/settingsMenu/services";
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";
import ModalResetPassword from "@/app/settingsMenu/components/modal/ModalResetPassword.vue";
import ModalDeleteWorkTeam from "@/app/settingsMenu/components/modal/ModalDeleteWorkTeam.vue";
import { humanDateWithSlashFormat } from "@/app/shared/utils/constants";
import { dateToString, hourToLocalTime } from "@/app/shared/utils/dates";
import { ProductName } from "@/app/shared/utils/constants";

export default defineComponent({
  components: {
    ModalResetPassword,
    ModalDeleteWorkTeam,
  },
  setup() {
    const formRef = ref();
    const store = useStore();
    const userData = computed(() => store.getters["getDataUser"]);

    const serverInactivityTime = computed(() => store.getters["getMinutesExpire"]);
    const inactivity_time = ref(serverInactivityTime.value);

    watch(
      serverInactivityTime,
      () => {
        inactivity_time.value = serverInactivityTime.value || 30;
      },
      { flush: "post" }
    );

    const checked = ref(false);

    return {
      serverInactivityTime,
      inactivity_time,
      userData,
      formRef,
      checked,
    };
  },
  data() {
    return {
      visibleModalSecurity: false,
      visibleModalWorkTeam: false,
      counterTime: undefined,
      profile: undefined,
      dataCompany: undefined,
      disabledInactivity: false,
      ProductName,
    };
  },
  computed: {
    user() {
      return this.$store.getters["getDataUser"];
    },
    timeInactive() {
      return this.$store.getters["isMyTimeInactive"];
    },
  },
  created() {
    this.handleFetchDataCompany();
  },

  methods: {
    handleCloseModal() {
      this.visibleModalSecurity = false;
      this.visibleModalWorkTeam = false;
    },
    handleOpenMySecurity() {
      this.visibleModalSecurity = true;
    },
    handleDeleteWorkTeam() {
      this.visibleModalWorkTeam = true;
    },
    async updateCounterInactive() {
      try {
        if (this.inactivity_time) {
          const formData = new FormData();
          formData.append("inactivity_time", this.inactivity_time);
          await updateCompanyDataAdmin(formData);
          this.$store.dispatch("setMinutesExpire", this.inactivity_time);
          this.$message.success("Se guardaron los cambios correctamente");
          this.disabledInactivity = false;
        } else {
          this.inactivity_time = this.serverInactivityTime || 30;
        }
      } catch (error) {
        this.$message.error("Hubo un error al actualizar los datos");
      }

      try {
        const body = {
          customer_id: this.userData?.customer?.id,
          minutes_to_expire: this.inactivity_time,
        };

        refreshMinutesToExpire(body);
      } catch (error) {
        this.$message.error("Ocurrió un error al actualizar para los demás usuario");
        console.log(error);
      }
    },
    formatDate(date) {
      return dateToString(date, humanDateWithSlashFormat);
    },
    formatTime(time) {
      return hourToLocalTime(time);
    },

    handleCancelInactivity() {
      this.disabledInactivity = false;
      this.inactivity_time = this.dataCompany?.inactivity_time || 30;
    },
    async handleFetchDataCompany() {
      this.dataCompany = await fetchCompanyDataAdmin();
    },
    async fetchProfile() {
      this.profile = this.user;
    },
    handleChangeNumber() {
      this.disabledInactivity = true;
      if (!this.inactivity_time) this.inactivity_time = this.serverInactivityTime || 30;
    },
  },
});
</script>

<style lang="sass" scoped>
.text__profile
  font-size: 24px
  font-weight: 600
  margin-left: 20px

.text__strong
  font-weight: 600
  font-size: 16px

.inputNumber
  width: 80px
  margin-right: 8px

.disabled
  cursor: not-allowed
  color: rgba(0, 0, 0, 0.25)

.information__password
  width: 70%
  line-height: 25px

.button__delete
  background: $red-6
  color: white
  margin-top: 20px

.button__inactivity
  margin-top: 20px
  button
    margin-right: 10px
</style>
