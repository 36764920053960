<template>
  <web-phone-template>
    <div v-if="(isInACall && !showDialInCall) || hasCall" style="width: 100%">
      <!--Web phone content show all user call information like the name, number, time and acw time-->
      <web-phone-content :receiver="receiver" :isOverlayOn="overlayOptions.visible" />
    </div>
    <!--Dial Pad -->
    <web-phone-keyboard
      v-else
      :mode="mode"
      :value="number"
      :receiver="receiver"
      :call="isInACall"
      v-model:portfolio="portfolio"
      @codePhone="codePhone = $event"
      @country="country = $event"
      @update:value="number = $event"
      @enter-press="handleClickCall"
    >
      <icon-button
        v-if="!showDialInCall"
        :disabled="!portfolio"
        type="call"
        large
        @click="handleClickCall"
      />
    </web-phone-keyboard>

    <!--Webphone buttons-->
    <template v-if="(isInACall || isCallEnded) && hasCall" #footer>
      <web-phone-actions
        v-if="(isInACall && isCurrentTicket) || (floating && isInACall)"
        :floating="true"
        :show-dial="overlayOptions.action === WebPhoneActionNames.Keyboard"
        :transfer="overlayOptions.action === WebPhoneActionNames.Transfer"
        :inWorkspace="inWorkspace"
        @click:transfer="handleToggleTransfer"
        @onHold="handleClickHold"
        @click:keyboard="handleToggleKeyboard"
      />
      <web-phone-actions-ended
        v-else
        :floating="true"
        :show-dial="showDial"
        @closeCall="closeCall"
      />
    </template>

    <WebPhoneActionOverlay
      :floating="floating"
      :visible="overlayOptions.visible"
      :action="overlayOptions.action"
      :receiver="receiver"
      @minimize="minimizeOverlay"
    >
      <!--This component contains a list of users to whom we can transfer calls.-->
      <web-phone-transfer
        v-if="overlayOptions.action === WebPhoneActionNames.Transfer"
        @transferringToAgent="handleTransfer($event, 'agent')"
        @transferringToQueue="handleTransfer($event, 'queue')"
      />

      <web-phone-keyboard
        v-if="overlayOptions.action === WebPhoneActionNames.Keyboard"
        :value="number"
        :receiver="receiver"
        :call="isInACall"
        inside-overlay
        @update:value="number = $event"
      />
    </WebPhoneActionOverlay>
  </web-phone-template>
</template>

<script>
/**
 * Webphone core component, this component contains all logic of the web-phone with the webrtc integration
 *
 */
import { defineComponent, ref, inject, reactive } from "vue";

import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { DialingType } from "@/app/shared/utils/enums";
import { CallStatus, CallMode } from "@/packages/vue-jssip/jssip/phone";
import { WebPhoneActionNames } from "@/packages/webphone/utils/enums";

import IconButton from "../buttons/IconButton.vue";
import WebPhoneContent from "./WebPhoneContent.vue";
import WebPhoneActions from "./WebPhoneActions.vue";
import WebPhoneTemplate from "./WebPhoneTemplate.vue";
import WebPhoneKeyboard from "./WebPhoneKeyboard.vue";
import WebPhoneActionsEnded from "./WebPhoneActionsEnded.vue";
import WebPhoneTransfer from "./WebPhoneTransfer.vue";
import WebPhoneActionOverlay from "./WebPhoneActionOverlay.vue";

export default defineComponent({
  components: {
    IconButton,
    WebPhoneContent,
    WebPhoneActions,
    WebPhoneTemplate,
    WebPhoneKeyboard,
    WebPhoneActionsEnded,
    WebPhoneTransfer,
    WebPhoneActionOverlay,
  },
  props: {
    mode: {
      type: String,
      required: true,
      default: "",
    },
    inWorkspace: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const floating = inject("floating");
    const overlayOptions = reactive({
      visible: false,
      action: null,
    });

    const openOverlay = (overlayAction) => {
      overlayOptions.visible = true;
      overlayOptions.action = overlayAction;
      emit("open-overlay");
    };

    const minimizeOverlay = () => {
      overlayOptions.visible = false;
      overlayOptions.action = null;
      emit("minimize-overlay");
    };

    const handleToggleTransfer = () => {
      if (overlayOptions.action === WebPhoneActionNames.Transfer) minimizeOverlay();
      else openOverlay(WebPhoneActionNames.Transfer);
    };

    const handleToggleKeyboard = () => {
      if (overlayOptions.action === WebPhoneActionNames.Keyboard) minimizeOverlay();
      else openOverlay(WebPhoneActionNames.Keyboard);
    };

    // Data
    const codePhone = ref("");
    const portfolio = ref();
    const country = ref(1);

    return {
      WebPhoneActionNames,
      floating,
      overlayOptions,
      minimizeOverlay,
      handleToggleTransfer,
      handleToggleKeyboard,
      codePhone,
      portfolio,
      country,
    };
  },
  data() {
    return {
      number: "",
      showDial: false,
      transfer: false,
      show: true,
      showDialInCall: false,
      showOverlay: false,
      callAlreadyClicked: false,
    };
  },
  updated() {
    this.number = this.$jssip.phone.receiver.number ? this.$jssip.phone.receiver.number : "";
  },
  mounted() {
    this.number = this.$jssip.phone.receiver.number ? this.$jssip.phone.receiver.number : "";
    EventBus.on(WorkspaceEvent.SHOW_ACW, () => {
      this.show = false;
    });

    EventBus.on(WorkspaceEvent.TICKET_CLOSED, () => {
      if (!this.isInACall) {
        this.closeCall();
        this.show = true;
      }
    });

    EventBus.on(WorkspaceEvent.TYPIFICATION_DEFAULT, () => {
      this.closeCall();
      this.show = true;
    });
    EventBus.on(WorkspaceEvent.RECALL, () => {
      this.number = this.$jssip.phone.receiver.number ? this.$jssip.phone.receiver.number : "";
    });
  },
  computed: {
    isCurrentTicket() {
      const ticketId = this.$route.query?.ticket;
      const interactionId = this.$route.query?.interaction;
      // const { ticket, channel_person } = this.$jssip.phone?.meta;
      // return ticketId == ticket && channelId == channel_person;
      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
    callFinished() {
      return this.$store.getters["getCallEnded"];
    },
    isInAcw() {
      return this.$store.getters["getAcwState"];
    },
    isDefaultModeCall() {
      return this.$jssip.phone.mode === CallMode.Default;
    },
    isInACall() {
      const isInCallStatus =
        this.$jssip.phone.status == CallStatus.InCall ||
        this.$jssip.phone.status == CallStatus.Connecting ||
        this.$jssip.phone.status == CallStatus.Ringing;

      return this.isDefaultModeCall && isInCallStatus;
    },
    isCallEnded() {
      const statuses = ["finished", "failed"];
      return this.isDefaultModeCall && statuses.includes(this.$jssip.phone.status);
    },
    hasCall() {
      return this.isDefaultModeCall && this.$jssip.phone.status !== CallStatus.None;
    },
    receiver() {
      return this.$jssip.phone.receiver;
    },
    getUserData() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    closeCall() {
      this.callFinished = true;
      this.showDialInCall = false;
      this.number = "";
    },
    async handleClickCall(event) {
      if (!event) return;
      if (!this.portfolio) return;
      if (this.callAlreadyClicked) return;

      this.callAlreadyClicked = true;

      this.callFinished = false;
      if (!this.number) return;
      this.$store.commit("SET_DIALING_TYPE", 1);
      this.$jssip.clearData();
      await this.$jssip.call(
        this.number,
        DialingType.Manual,
        this.getUserData.id,
        undefined,
        this.codePhone,
        this.portfolio
      );
      this.$store.dispatch("setCallFinish", false);
      this.callAlreadyClicked = false;
    },
    async handleTransfer(item, type) {
      console.log("AL TRANSFERIR WEB PHONE", item);
      this.transfer = false;

      let actionName = "";
      let annex = "";
      // const tickets = [Number.parseInt(this.$route.query.ticket)];
      const tickets = [Number.parseInt(this.$jssip.phone.meta?.ticket)];
      console.log(tickets, "tickets");
      const body = { tickets };

      if (type === "agent") {
        body.user = item.user.id;
        actionName = "tranferTicketAgent";
        annex = `r${item.annex.split("@")[0]}`;
      }

      if (type === "queue") {
        body.queue = item.id;
        body.is_call = true;
        actionName = "tranferTicketQueues";
        annex = `q${item.id}`;
      }

      try {
        await this.$store.dispatch(actionName, body);
        // The "r" prefix in the annex indicates that this transfer is to another user
        this.$jssip.transfer(annex, item?.user?.id);
        this.$jssip.end();
        this.$jssip.setCallStatus(false);
        this.$jssip.reset();
        EventBus.$emit(WorkspaceEvent.TRANSFER_CALL);
      } catch (error) {
        this.$message.error("Ocurrió un error al transferir la llamada");
        console.log(error);
      }
    },
    handleBeforeOnload(e) {
      const confirmationMessage = "Hola";

      (e || window.event).returnValue = confirmationMessage; //Gecko + IE
      return confirmationMessage;
    },
    handleClickHold() {
      window.removeEventListener("beforeunload", this.handleBeforeOnload);
    },
  },
  watch: {
    isInACall(newIsInCallState) {
      if (!newIsInCallState) this.overlayOptions.visible = false;
    },
  },
});
</script>
