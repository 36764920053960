import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todas las importaciones del día.
 *
 * @return {Promise<Array>} Lista de importaciones.
 */
export async function fetchAllImports({ entity, portfolio }) {
  // eslint-disable-next-line
  entity = entity ? entity : "";
  // eslint-disable-next-line
  portfolio = portfolio ? portfolio : "";
  //const url = `/management/import/?entity=${entity}&portfolio=${portfolio}`
  const url = "/imports";
  const response = await httpManager.get(url);
  return response.data.map((item) => mapErrors(item));
}

function mapErrors(data) {
  return {
    ...data,
    errors: {
      exceptions: data.error_log ? [data.error_log] : [],
      unknowns: searchByName("unknown_fields", data.errors),
      forgottens: searchByName("forgotten_fields", data.errors),
      invalids: searchByName("invalid_fields", data.errors),
    },
  };
}

function searchByName(name, array) {
  const object = array.find((item) => !!item[name]);
  return object ? object[name] : [];
}
