<template>
  <a-radio-group :value="value" @update:value="handleChangeValue" class="user-wrapper">
    <a-card v-for="user in props.users" :key="user?.id" class="cardInformation">
      <div class="user">
        <div class="name">
          <UserAvatar :record="{ user: user.rawUser }" />
          <p>{{ user.name }}</p>
        </div>
        <div class="campaign">
          <p>{{ totalCampaign(user) }} {{ totalCampaign(user) > 1 ? "campañas" : "campaña" }}</p>
          <a-tooltip color="white">
            <template #title>
              <div class="info">
                <h3>
                  {{ totalCampaign(user) }} {{ totalCampaign(user) > 1 ? "campañas" : "campaña" }}
                </h3>
                <ul>
                  <li v-for="item in user.campaign" :key="item.id">{{ item.name }}</li>
                </ul>
              </div>
            </template>
            <InfoCircleOutlined />
          </a-tooltip>
        </div>
        <div class="status">
          <StateCell :record="user.rawUser" :detailedView="false" :time="false" />
        </div>
        <div class="input">
          <a-radio :value="user.id"></a-radio>
        </div>
      </div>
    </a-card>
  </a-radio-group>
</template>
<script lang="ts" setup>
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import StateCell from "@/app/supervision/components/tables/cells/StateCell.vue";
import { IUsers } from "@/@types/emails/index";
import UserAvatar from "@/app/shared/components/avatar/UserAvatar.vue";

const props = defineProps<{ users: IUsers[]; value: number | undefined }>();

const emit = defineEmits<{ "update:value": [value: number] }>();
// const record = {
//   state: 1,
// };

const handleChangeValue = (value: number) => {
  emit("update:value", value);
};

const totalCampaign = (user: IUsers) => {
  return user.campaign.length;
};

// const emit = defineEmits(["emitSelectedEvent"]);
// const emitSelectedEvent = () => {
//   emit("emitSelectedEvent", true);
// };
</script>
<style lang="sass" scoped>
p
  margin: 0

.user-wrapper
  max-height: 60vh
  overflow: auto

.info
  color: #262626
  ul
    padding: 0 25px
.cardInformation
  margin-bottom: 10px

.ant-radio-group
  width: 100%
.user
  display: flex
  justify-content: space-between
  align-items: center
  .name
    display: flex
    align-items: center
    gap: 10px
    width: 45%
  .campaign
    display: flex
    gap: 10px
    width: 25%
  .status
    width: 20%
    .time
      display: none

.input
  margin-left: 8px
</style>
