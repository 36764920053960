import httpManager from "@/app/shared/utils/httpManager";
import { mapQueue } from "@/app/queues/utils/helpers";

export async function changeStatusFromQueue(queue) {
  const url = `/queues/${queue.id}/`;
  const mapedQueue = mapQueue(queue);
  mapedQueue.status = queue.status === 1 ? 2 : 1;
  const response = await httpManager.put(url, mapedQueue);
  return response.data;
}
