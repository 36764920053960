import { type TableColumnType } from "ant-design-vue";

import { QuickAnswer } from "@/@types/platformSettings/quickAnswers";

export const quickActionsColumns: TableColumnType<QuickAnswer>[] = [
  {
    title: "N°",
    key: "number",
    customRender: ({ index }) => index + 1,
    width: 50,
  },
  {
    title: "Título",
    dataIndex: "title",
    key: "title",
  },
  {
    title: "Mensaje",
    dataIndex: "message",
    key: "message",
    ellipsis: true,
  },
  {
    title: "Multimedia",
    dataIndex: "file_url",
    key: "file",
  },
  {
    title: "Colas asignadas",
    dataIndex: "queues",
    key: "queues",
  },
  {
    title: "Acciones",
    key: "actions",
  },
];
