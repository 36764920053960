<template>
  <a-drawer
    :title="`Correo ID: #${emailId}`"
    placement="right"
    width="60%"
    class="email-detail-drawer"
    open="true"
    @close="handleCloseDrawer"
  >
    <a-spin :spinning="!data">
      <!-- {{ data }} -->
      <section class="drawer" v-if="data">
        <div class="drawer__left-section">
          <CustomScrollbar height="100%">
            <!-- CAMPAIGN SECTION -->
            <div v-if="data?.portfolio?.name" class="left-content">
              <p class="subtitle">Campaña asociada:</p>
              <p class="single-text">{{ data?.portfolio?.name }}</p>
            </div>

            <!-- IDENTIFIERS SECTION -->
            <div class="left-content" v-if="data?.email_messages.length > 0">
              <p class="subtitle">Identificadores interactuados:</p>
              <p class="single-text">{{ data?.email_messages[0]?.from_email }}</p>
            </div>

            <!-- SUBJECT SECTION -->
            <div v-if="data?.email_messages.length > 0" class="left-content">
              <p class="subtitle">Asunto:</p>
              <p class="single-text">{{ data?.email_messages[0]?.subject }}</p>
            </div>

            <!-- AGENT SECTION -->
            <div v-if="data?.associated_users?.length" class="left-content">
              <p class="subtitle">Usuarios que lo atendieron:</p>
              <div class="content" v-for="(item, idx) in data?.associated_users" :key="idx">
                <div
                  class="profile-picture"
                  :style="{
                    'background-image': 'url(' + item?.profile_picture + ')',
                  }"
                  v-if="item?.profile_picture"
                ></div>

                <div v-else class="avatar" :class="avatarClasses(item?.role)">
                  <img
                    class="avatar__icon"
                    :src="getProfileImage(item?.icon)"
                    alt="+profile icon"
                  />
                </div>
                <p class="content__text">{{ item?.fullname }}</p>
              </div>
            </div>

            <!-- CLIENT SECTION -->
            <div v-if="data?.client" class="left-content">
              <p class="subtitle">Contacto asignado:</p>
              <div class="content">
                <div class="client-avatar-default">
                  <p>{{ name(data?.client?.fullname ?? "") }}</p>
                </div>
                <p class="content__text">{{ data?.client?.fullname }}</p>
              </div>
            </div>

            <div v-if="associatedClients.length > 0" class="left-content">
              <p class="subtitle">Otros contactos asociados:</p>
              <div class="content" v-for="(item, idx) in associatedClients" :key="idx">
                <div class="client-avatar-default">
                  <p>{{ name(item?.alias ?? "") }}</p>
                </div>
                <p class="content__text">{{ item?.alias }}</p>
              </div>
            </div>

            <!-- COMMENT SECTION -->
            <div v-if="data.comment_resolved" class="left-content">
              <p class="subtitle">Comentario final del correo:</p>
              <p class="single-text">{{ data?.comment_resolved }}</p>
            </div>

            <!-- TAG SECTION -->
            <div v-if="data?.tag?.length" class="left-content">
              <p class="subtitle">Etiquetas</p>
              <div class="content" v-for="(item, idx) in data?.tag" :key="idx">
                <div class="email-tag" :style="{ 'background-color': `#${item.color}` }"></div>
                <p class="content__text">{{ item.name }}</p>
              </div>
            </div>
          </CustomScrollbar>
        </div>

        <div class="drawer__right-section">
          <EmailThreadTemplate
            :isAssigned="isAssigned"
            :currentEmailId="data.id"
            :isMailbox="false"
          />
          <!-- <CustomScrollbar height="100%">
            <EmailCollapse v-for="item in data?.details" :key="item.id" :data="item" />
          </CustomScrollbar> -->
        </div>

        <div class="drawer__bottom-section">
          <a-button @click="handleCloseDrawer">Ocultar</a-button>
        </div>
      </section>
    </a-spin>
  </a-drawer>
</template>

<script setup lang="ts">
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import EmailThreadTemplate from "@/app/emails/components/templates/EmailThreadTemplate.vue";
import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
// import EmailCollapse from "@/app/workspace/components/cards/EmailCollapse.vue";
import { toRefs, computed, onMounted, ref } from "vue";
import { useStore } from "vuex";

const store = useStore();

const isAdmin = ref<boolean>(true);
const isAssigned = ref<boolean>(false);

const dispatchEmailHistory = () => {
  store.dispatch("getEmailHistory", emailId.value);
};

const props = defineProps({
  emailId: { type: String, required: true },
});

const { emailId } = toRefs(props);

const emit = defineEmits(["onCloseDrawer"]);

const handleCloseDrawer = () => {
  emit("onCloseDrawer");
};

const data = computed(() => {
  return store.getters.getEmailHistoryDrawer;
});

const getProfileImage = (profile: string) => {
  return new URL(`../../../../users/assets/profiles/${profile}`, import.meta.url).href;
};

const associatedClients = computed(() => {
  const associatedClientsMap = data.value?.associated_clients
    .filter((el: any) => el.client_id != null)
    .map((el: any) => ({
      alias: el.alias,
      client_id: el.client_id,
      email: el.email,
    }));
  return [...new Set(associatedClientsMap)];
});

const emailResp = {
  id: 1,
  subject: "Apoyo con código de verificación",
  message:
    "<p>A dog is a type of domesticated animal. Known for its loyalty and faithfulness, it can be found as a welcome guest in many households across the world.</p><h2>When To Use</h2><p>A panel which slides in from the edge of the screen.</p><p>A Drawer is a panel that is typically overlaid on top of a page and slides in from the side. It contains a set of information or actions. Since the user can interact with the Drawer without leaving the current page, tasks can be achieved more efficiently within the same context.</p><ul><li>Use a Form to create or edit a set of information.</li><li>Processing subtasks. When subtasks are too heavy for a Popover and we still want to keep the subtasks in the context of the main task, Drawer comes very handy.</li><li>When the same Form is needed in multiple places.</li></ul>",
  // Identificadores interactuados: y remitente
  from_email: "julio.manchuay@securitec.pe",
  to_emails: [
    { alias: "Score ATC", email: "ventas@securitec.pe" },
    { alias: "Score Venta", email: "score@evnsadf.com" },
  ],
  cc_emails: [
    { alias: "Sofia Reyes", email: "sofhia@gmail.com" },
    { alias: "Sofia Reyes", email: "sofhia@gmail.com" },
  ],
  bcc_emails: null,
  attachments_url: [],
  ticket: 22827,
  // otros clientes
  client: [
    {
      id: 2290,
      fullname: "Ricardo Romero",
      profile_picture:
        "https://easy.drawingnow.com/wp-content/uploads/2022/07/How-to-Draw-Chibi-Spiderman.webp",
    },
    {
      id: 80663,
      fullname: "Miguel Vargas",
      profile_picture: "https://i.pinimg.com/736x/4a/4a/3f/4a4a3f5adf7f073009c9fc9b1cb53e95.jpg",
    },
    {
      id: 80664,
      fullname: "Ricardo Romero",
      profile_picture: "https://i.pinimg.com/736x/96/5f/da/965fda2c3fbec94c9cbaeafad08963c6.jpg",
    },
  ],
  person: 80664,
  parameters: null,
  status_sent: 2,
  portfolio_name: "Campaña navideña - BBVA Santiago",
  channel_customer: 276,
  queue: null,
  // Usuarios que lo atendieron:
  assigned_user: {
    id: 2290,
    fullname: "Julio Machahuay Giraldo",
    profile_picture: "https://pbs.twimg.com/media/GAafdB7WMAAvDyn?format=png&name=900x900",
    role: "Owner",
  },
  tag: [
    {
      id: 4,
      name: "Activo",
      color: "F4D234",
      status: 1,
      is_permanent: false,
    },
    {
      id: 6,
      name: "Aplica  promoción por cuarentena",
      color: "CE8934",
      status: 1,
      is_permanent: false,
    },
    {
      id: 8,
      name: "Vive muy lejos",
      color: "CE3434",
      status: 1,
      is_permanent: false,
    },
    {
      id: 9,
      name: "Urgente cliente vip",
      color: "E00E14",
      status: 1,
      is_permanent: false,
    },
    {
      id: 10,
      name: "Cliente preferencial",
      color: "7FE9D7",
      status: 1,
      is_permanent: false,
    },
    {
      id: 11,
      name: "Sin deudas",
      color: "4894CD",
      status: 1,
      is_permanent: false,
    },
    {
      id: 12,
      name: "Sin deudas",
      color: "4894CD",
      status: 1,
      is_permanent: false,
    },
    {
      id: 13,
      name: "Sin deudas",
      color: "4894CD",
      status: 1,
      is_permanent: false,
    },
    {
      id: 14,
      name: "Sin deudas",
      color: "4894CD",
      status: 1,
      is_permanent: false,
    },
    {
      id: 15,
      name: "Sin deudas",
      color: "4894CD",
      status: 1,
      is_permanent: false,
    },
  ],
  attachments_public_url: [
    "https://media.gq.com.mx/photos/5be9f0eb3ff3ea6083475ebc/master/w_1600,c_limit/tom_hiddleston_8285.jpg",
    "https://media.gq.com.mx/photos/5be9f0eb3ff3ea6083475ebc/master/w_1600,c_limit/tom_hiddleston_8285.jpg",
    "https://media.gq.com.mx/photos/5be9f0eb3ff3ea6083475ebc/master/w_1600,c_limit/tom_hiddleston_8285.jpg",
    "https://media.gq.com.mx/photos/5be9f0eb3ff3ea6083475ebc/master/w_1600,c_limit/tom_hiddleston_8285.jpg",
    "https://media.gq.com.mx/photos/5be9f0eb3ff3ea6083475ebc/master/w_1600,c_limit/tom_hiddleston_8285.jpg",
  ],
  created_at: "2024-04-03 16:59:05",
  comment_resolved:
    "Este es el comentario del ticket. Este es el comentario del ticket. Este es el comentario del ticket.",
};

// const avatarClasses = computed(() => {
//   const role = data.value?.assigned_user?.role;
//   console.log(role);

//   return role === "Agente" ? "avatar__agent" : "avatar__default";
// });

const avatarClasses = (role: string) => {
  return role === "Agente" ? "avatar__agent" : "avatar__default";
};

const name = (name: string) => {
  const words = name?.split(" ") ?? [];

  let newName = "";

  for (let i = 0; i < Math.min(words.length, 2); i++) {
    newName += words[i].charAt(0).toUpperCase();
  }

  return newName;
};

onMounted(() => {
  dispatchEmailHistory();
});
</script>

<style lang="sass">
.email-detail-drawer
  .ant-drawer-body
    padding: 0

  .ant-drawer-header
    padding: 16px 20px

  .ant-drawer-header-title
    flex-direction: row-reverse

  .ant-drawer-close
    padding: 0
    margin: 0

  .ant-spin-container,
  .ant-spin-nested-loading
    height: 100%

.email-collapse
  margin: 5px 0px
  .ant-collapse-content
    border-top: none

  .ant-collapse-content-box
    padding-top: 0 !important
</style>

<style lang="sass" scoped>
// DRAWER
.drawer
  height: 100%
  display: grid
  grid-template-columns: 35% 65%
  grid-template-rows: calc(100% - 60px) 60px

  &__left-section
    grid-column: 1

    .scroll-area
      padding: 20px 24px

  &__right-section
    grid-column: 2
    // padding: 8px
    border-left: 1px solid #0000001A

  &__bottom-section
    grid-column: span 2
    padding: 12px 16px
    display: flex
    justify-content: flex-end
    border-top: 1px solid #0000001A

// LEFT SECTION STYLES
.left-content
  margin-bottom: 17px

p
  margin: 0

.subtitle
  color: $gray-10
  font-weight: 600

.single-text
  color: $gray-8

.content
  display: grid
  grid-template-columns: 28px 1fr
  align-items: center
  gap: 8px
  margin-bottom: 8px

  &__text
    color: $gray-8

.profile-picture
  width: 28px
  height: 28px
  border-radius: 50%
  background-size: cover
  background-position: center

.client-avatar-default
  width: 28px
  height: 28px
  font-size: 12px
  font-weight: 600
  display: flex
  align-items: center
  justify-content: center
  background-color: $geekblue-6
  border-radius: 50%
  color: white

.email-tag
  width: 28px
  height: 8px
  border-radius: 10px
  background-size: cover
  background-position: center

// .avatar
//   &__icon
//     background-color: $primary !important
//   &__default
//     background-color: $blue-1
//   &__agent
//     background-color: #FFF7E6
.avatar
  height: 28px
  width: 28px
  display: flex
  align-items: center
  justify-content: center
  background-color: $blue-1
  border-radius: 50%

  &__icon
    width: 18px
    height: 18px

  &__default
    background-color: $blue-1

  &__agent
    background-color: #FFF7E6
</style>
