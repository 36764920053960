import {
  fetchAllQueues,
  changeStatusFromQueue,
  deleteQueue,
  deactivateQueue,
} from "@/app/queues/services";
import { SET_QUEUES } from "./mutation-types";

import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";
import { groupChannels } from "@/app/queues/utils/helpers";

const actions = {
  async fetchAllQueues({ commit }, params = {}) {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { search, portfolio, pagination } = params;
    const data = await fetchAllQueues(search, portfolio, pagination);
    const newData = groupChannels(data.results);
    commit(SET_QUEUES, {
      items: newData,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async changeQueueStatus(_, queue) {
    await changeStatusFromQueue(queue);
  },
  async deactivateQueue(_, queue) {
    await deactivateQueue(queue);
  },
  async deleteQueue(_, queue) {
    await deleteQueue(queue.id);
  },
};

export default actions;
