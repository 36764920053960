import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear una nueva base
 *
 * @param {Object} base Datos de la base
 *
 * @return {Promise<Object>} Datos de la base creada
 */

export default async function (portfolioId, users) {
  const response = await httpManager.post(`/portfolios/${portfolioId}/agents/`, users);
  return response.data;
}
