import axios from "@/plugins/axios";
const local = false;
/**
 * Conecta con el socket para informar de la captura de un ticket.
 * @parms {object} data
 *
 */
export async function transferTicket(data) {
  const url = local
    ? "http://localhost:8081/tickets/capture-ticket"
    : `${import.meta.env.VITE_SOCKET}tickets/capture-ticket`;
  const response = await axios.post(url, {
    customer_id: data.customer_id,
    iduser: data.user_id,
    name: data.name,
    ticket_id: data.ticket,
    channel: data.channel,
  });

  return response.data;
}
