<template>
  <div class="email">
    <a-modal
      :open="open"
      title="Aplicar filtros"
      wrapClassName="filterModal"
      @ok="handleOk"
      @cancel="handleClose"
      destroyOnClose
      :maskClosable="false"
      width="482px"
      :footer="null"
      centered
    >
      <a-form class="form" layout="vertical">
        <a-form-item>
          <h4>Correos leídos:</h4>
          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            v-model:value="filters.read"
            :options="options"
            placeholder="Seleccionar..."
          ></a-select>
        </a-form-item>
        <a-form-item>
          <h4>Etiquetas:</h4>
          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :value="filters.tagList"
            :options="tags"
            mode="multiple"
            placeholder="Seleccionar..."
            @change="handleTagChange"
          ></a-select>
        </a-form-item>
        <footer>
          <a-button @click="handleClose">Limpiar</a-button>
          <a-button type="primary" @click="handleOk">Aplicar</a-button>
        </footer>
      </a-form>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { fetchTags } from "@/app/platformSettings/services";
import { Ref, inject, onMounted, ref, computed } from "vue";
import { useRouter, useRoute } from "vue-router";
import { useStore } from "vuex";

defineProps({
  open: { type: Boolean, default: false },
});

const options = [
  {
    value: "",
    label: "Todos",
  },
  {
    value: true,
    label: "Leídos",
  },
  {
    value: false,
    label: "No leídos",
  },
];

const tags = ref<{ value: number | ""; label: string }[]>([]);

const store = useStore();

/*
  #TODO: Cambiar estructura de filtros de inbox para que use estados. 
  Usar vuex para estos valores ya que el listado del inbox depende de estos
*/
// const read = inject("filterRead") as Ref<boolean | "">;
// const selectedTags = inject("filterSelectedTags") as Ref<Array<number | "">>;
// const search = inject("filterSearch") as Ref<string | "">;
const filters = computed(() => {
  return store.getters.getFilters;
});

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
// const fetchList = inject("fetchList") as (query: {
//   channelCustomerId?: number;
//   assigned: boolean;
//   resolved?: boolean;
//   read?: boolean | "";
//   tagList?: Array<number | "">;
//   search?: string | undefined;
// }) => Promise<void>;

// const read = ref<boolean | "">("");
// const selectedTags = ref<Array<number | "">>([""]);

onMounted(async () => {
  const response = await fetchTags();

  tags.value.push({ value: "", label: "Todas" });
  tags.value.push(...response.map((t) => ({ value: t.id, label: t.name })));
});

const emit = defineEmits<{ "update:open": [open: boolean] }>();

/*
  #TODO: Mejorar acción SET_THREADS_INBOX
*/
// const route = useRoute();

// const getUrlParams = (): {
//   channelCustomerId: number;
//   assigned: boolean;
//   resolved?: boolean;
// } => {
//   const { channelCustomer, assigned, resolved } = route.query;

//   return {
//     channelCustomerId: Number(channelCustomer),
//     assigned: assigned === "true",
//     resolved: resolved === " true",
//   };
// };

const handleOk = async () => {
  // router.push({
  //   query: {
  //     ...route.query,
  //     is_open: String(read.value),
  //     tag_list: selectedTags.value.includes("") ? "" : JSON.stringify(selectedTags.value),
  //   },
  // });
  // const params = getUrlParams();
  // console.log("handle ok");
  // fetchList({
  //   ...params,
  //   read: filters.value.read,
  //   tagList: filters.value.tagList,
  //   search: filters.value.search,
  // });
  await store.dispatch("updateThreadsInbox", {
    assignedUser: store.getters["getDataUser"]?.id,
    resetPagination: true,
  });

  emit("update:open", false);
};

const handleClose = async () => {
  // const params = getUrlParams();

  // fetchList({ ...params });
  await store.dispatch("updateThreadsInbox", {
    assignedUser: store.getters["getDataUser"]?.id,
    resetPagination: true,
  });
  // router.push({
  //   query: {
  //     ...route.query,
  //     is_open: "",
  //     tag_list: "",
  //   },
  // });

  emit("update:open", false);
};

const handleTagChange = (values: Array<number | "">) => {
  if (filters.value.tagList.at(0) === "") {
    const tagValues = values.filter(Boolean);
    filters.value.tagList = tagValues;
    return;
  }

  if (values.includes("")) {
    filters.value.tagList = [""];
    return;
  }

  filters.value.tagList = values;
};
</script>
<style lang="sass" scoped>
.filterModal
  .form
    padding: 10px 0
    .ant-form-item
      margin-bottom: 20px
    footer
      display: flex
      justify-content: flex-end
      padding: 10px 0
      gap: 10px
</style>
