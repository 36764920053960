const getters = {
  getWorkspaceStrategyPhone(state) {
    return state.strategyPhone;
  },
  getWorkspacePhoneNumber(state) {
    return state.phoneNumber;
  },
  getWorkspacePhoneStatus(state) {
    return state.status;
  },
  getCallFinished(state) {
    return state.callFinished;
  },
  getCallMuted(state) {
    return state.phoneMuted;
  },
  getCallHold(state) {
    return state.phoneHold;
  },
};

export default getters;
