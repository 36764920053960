import axios from "@/plugins/axios";
const local = false;
/**
 * Conecta con el socket para lanzar el evento del progresivo.
 *
 * @return {Bolean} Lista de Empresas con sus campañas.
 */
export async function callHangUp(data) {
  const url = local
    ? "http://localhost:8081/calls/hang-up"
    : `${import.meta.env.VITE_SOCKET}calls/hang-up`;
  const response = await axios.post(url, {
    customer_id: data.customer_id,
    user_id: data.user_id,
    iduser: data.user_id,
  });

  return response.data;
}
