const crypto = window.crypto || window.msCrypto;
var array = new Uint32Array(1);
export function columnsFixedTableLeft(value) {
  const colums = {
    key: crypto.getRandomValues(array),
    width: "1px",
    fixed: "left",
  };

  value?.unshift(colums);

  return value;
}

export function columnsFixedTableLeftAndRight(value) {
  const columsleft = {
    key: crypto.getRandomValues(array),
    width: "1px",
    fixed: "left",
  };
  const columsright = {
    key: crypto.getRandomValues(array),
    width: "10px",
    fixed: "right",
  };

  value?.unshift(columsleft);
  value.push(columsright);

  return value;
}
