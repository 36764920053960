<template>
  <a-form-item :label="label">
    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      v-model:value="selected"
      class="form__select"
    >
      <a-select-option :key="0"> Todas las disposiciones </a-select-option>
      <a-select-option key="NO ANSWER"> NO ANSWER </a-select-option>
      <a-select-option key="CONGESTION"> CONGESTION </a-select-option>
      <a-select-option key="FAILED"> FAILED </a-select-option>
      <a-select-option key="BUSY"> BUSY </a-select-option>
      <a-select-option key="ANSWERED"> ANSWERED </a-select-option>
    </a-select>
  </a-form-item>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Number,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
  },

  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
});
</script>

<style lang="sass" scoped>
.form__select
  width: 190px
</style>
