const getters = {
  /**
   * Retorna los datos del usuario logueado.
   */
  getDataUser: (state) => state.data_user,
  getStateUser: (state) => state.stateUser,
  getAllowSendTemplate: (state) => state.allowSendTemplate,

  getMinutesExpire: (state) => state.minutes_expire,
  getDataUserState: (state) => state.dataUserState,
};
export default getters;
