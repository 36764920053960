<template>
  <a-modal
    :visible="props.visible"
    :title="props.title"
    :confirmLoading="loadingOk"
    @cancel="handleClose"
    @ok="handleCreateVar"
    centered
  >
    <p>Completa todos los campos requeridos</p>
    <a-form
      :model="formVarState"
      ref="formRef"
      layout="vertical"
      :rules="formRules"
      class="modal__form"
    >
      <a-form-item
        label="Nombre de la variable"
        name="name"
        extra="El nombre debe ser en minúsculas, sin espacios y sin caracteres especiales. Puede tener guiones bajos. Ejemplo: producto_123"
      >
        <a-input placeholder="Escribe aquí..." v-model:value="formVarState.name" :maxLength="30" />
      </a-form-item>
      <a-typography-text strong
        >Palabras que desea obviar de las respuestas (Opcional)</a-typography-text
      >
      <p class="form__item-description">
        Ejm: Si capturas la variable “Nombre” y el cliente responde “Mi nombre es Maria”, agrega
        aquí “mi nombre es” para que solo se guarde “Maria”.
      </p>
      <div class="form__tags">
        <a-tag
          v-for="localAvoidWord in avoidWordsState.avoidWords"
          :key="localAvoidWord"
          class="mrg-bottom-4"
          :closable="true"
          @close="() => handleRemoveAvoidWord(localAvoidWord)"
        >
          {{ localAvoidWord }}
        </a-tag>
        <a-input
          v-if="avoidWordsState.showInput"
          ref="inputAvoidWord"
          type="text"
          size="small"
          class="form__input--add"
          v-model:value="avoidWordsState.inputValue"
          @blur="handleInputConfirmAvoidWord"
          @keyup.enter="handleInputConfirmAvoidWord"
          placeholder="Escribe aquí"
          :maxLength="100"
        />
        <a-tag v-else class="form__tag--add" @click="handleShowInputAvoidWord">
          <plus-outlined /> Agregar palabras
        </a-tag>
      </div>
    </a-form>
  </a-modal>
</template>
<script setup lang="ts">
import { reactive, ref, nextTick, watch } from "vue";
import { FormVar, AvoidWordsState, AvoidWordItem, PropsModalVar } from "@/@types/manageVars";
import { message } from "ant-design-vue";
import { PlusOutlined } from "@ant-design/icons-vue";

const props = defineProps<PropsModalVar>();

// EMITS
const emits = defineEmits(["onClose"]);

// CONSTANTS
const formVarState = reactive<FormVar>({
  name: props.dataSource?.name || "",
  exclude_words: props.dataSource?.exclude_words || [],
});
const avoidWordsState: AvoidWordsState = reactive({
  inputValue: "",
  avoidWords: [],
  showInput: false,
});
const inputAvoidWord = ref();
const formRef = ref();
const formRules = reactive({
  name: [
    { required: true, message: "Por favor rellene el campo" },
    {
      whitespace: true,
      message: "No se admiten espacios en blanco.",
    },
    {
      pattern: /^[a-z0-9_ñ]+$/g,
      message:
        "El nombre debería ser en minúsculas separados por guión bajo, sin caracteres especiales",
    },
  ],
});
const loadingOk = ref(false);

watch(
  () => props.visible,
  (newValue: boolean) => {
    if (newValue && props.dataSource) {
      formVarState.name = props.dataSource?.name || "";
      avoidWordsState.avoidWords = props.dataSource?.exclude_words || [];
    }
    if (!newValue) {
      handleClose();
    }
  }
);

// FUNCTIONS
const handleClose = () => {
  formRef.value.resetFields();
  formVarState.name = "";
  formVarState.exclude_words = [];
  avoidWordsState.inputValue = "";
  avoidWordsState.avoidWords = [];
  avoidWordsState.showInput = false;
  emits("onClose");
};

const handleRemoveAvoidWord = (avoidWord: AvoidWordItem) => {
  const localAvoidWords = avoidWordsState.avoidWords;
  const avoidWords = [...localAvoidWords].filter((value) => value !== avoidWord);
  avoidWordsState.avoidWords = avoidWords;
};

/**
 * Muestra el input para agregar una palabra obviada
 */
const handleShowInputAvoidWord = () => {
  avoidWordsState.showInput = true;
  nextTick(() => {
    inputAvoidWord.value.focus();
  });
};

/**
 * Escucha otros eventos
 */
const handleInputConfirmAvoidWord = () => {
  const valuesLocalAvoidWords = avoidWordsState.avoidWords;
  const newAvoidWord = avoidWordsState.inputValue;
  const noExistInLocalAvoid = valuesLocalAvoidWords.indexOf(newAvoidWord) === -1;
  const maxLengthAvoidWords = 20;
  if (newAvoidWord && noExistInLocalAvoid) {
    if (valuesLocalAvoidWords.length < maxLengthAvoidWords)
      avoidWordsState.avoidWords.push(newAvoidWord);
    else message.error("No se pueden agregar más palabras");
  }

  avoidWordsState.showInput = false;
  avoidWordsState.inputValue = "";
};

const handleCreateVar = async () => {
  await formRef.value.validate().then(async () => {
    loadingOk.value = true;
    formVarState.exclude_words = avoidWordsState.avoidWords;
    if (props.onOk) await props.onOk({ id: props.dataSource?.id || undefined, ...formVarState });
    loadingOk.value = false;
  });
};
</script>

<style lang="sass" scoped>
.modal
  &__form
    height: 400px
.form
  &__tag--add
    border-style: dashed
  &__input--add
    width: 124px
</style>
