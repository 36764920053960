import httpManager from "../../shared/utils/httpManager";
import { cloneDeep } from "lodash";

export async function createNewUserInvite(newUserInvite) {
  const newInvite = cloneDeep(newUserInvite);

  if (!newInvite.phone) delete newInvite.phone;

  const options = { isPublic: true };
  const response = await httpManager.post("/users/register/", newInvite, options);
  return response.data;
}
