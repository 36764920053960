import { CallStatus } from "@/app/workspace/utils/enums";

export const initialState = () => ({
  phoneNumber: "",
  strategyPhone: null,
  callFinished: false,

  /**
   * Estado de la llamada.
   * Es usado también por el WebPhone.
   */
  status: CallStatus.None,

  /**
   * Datos de la persona a quien se está llamando.
   * @property {string} name
   * @property {string} image
   * @property {string} phoneNumber
   */
  person: null,
  phoneMuted: false,
  phoneHold: false,
});

const state = initialState();

export default state;
