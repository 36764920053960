import {
  fetchInitialStrategyFilters,
  generateStrategyByFilters,
  fetchStrategyCalcutations,
  fetchStrategyEmailClients,
  fetchTypificationsFilters,
} from "@/app/strategies/services";

import { SET_FILTERS, SET_PROPERTIES, CLEAR_FILTERS } from "./mutation-types";

const actions = {
  async fetchInitialStrategyFilters({ state, commit }, typeStrategy) {
    commit(CLEAR_FILTERS);
    const filters = await fetchInitialStrategyFilters(
      state.portfolio,
      state.base,
      state.coverage,
      state.type_phone,
      typeStrategy
    );
    commit(SET_FILTERS, filters);
  },
  async fetchStrategyCalcutations({ state }, filters) {
    const params = {
      base: state.base,
      portfolio: state.portfolio,
      coverage: state.coverage,
      type_phone: state.type_phone,
    };
    return await fetchStrategyCalcutations(params, filters);
  },
  async fetchStrategyEmailClients({ state }, filters) {
    const params = {
      base: state.base,
      portfolio: state.portfolio,
      coverage: state.coverage,
    };
    return await fetchStrategyEmailClients(params, filters);
  },
  async generateStrategyByFilters({ state }, strategy) {
    strategy.filters = state.filters;
    await generateStrategyByFilters(strategy);
  },
  async fetchTypificationsFilters({ state }, filters) {
    return await fetchTypificationsFilters(state.base, state.portfolio, filters);
  },
  setStrategyFiltersProperties({ commit }, properties) {
    commit(SET_PROPERTIES, properties);
  },
  setStrategyFiltersState({ commit }, filters) {
    commit(SET_FILTERS, filters);
  },
};

export default actions;
