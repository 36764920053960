<template>
  <section class="history">
    <h2 style="padding: 0 24px" v-show="!isRenderInDrawer">Historial de llamadas:</h2>

    <CustomScrollbar :style="height ? { height: `${height - 40}px` } : null">
      <div class="list">
        <div class="call" v-for="interaction in history" :key="interaction.id">
          <small class="alias-identifier-label">
            Identificador:
            {{
              interaction.channel_customer?.alias
                ? interaction.channel_customer?.alias
                : "Número aleatorio"
            }}</small
          >
          <div class="info">
            <span>{{ dateCalendar(interaction.created_at) }}</span>
            <span>
              {{
                interaction.type_interaction === InteractionType.Inbound ? "Entrante" : "Saliente"
              }}
              <ArrowUpOutlined
                class="icon"
                :class="{
                  inbound: interaction.type_interaction === InteractionType.Inbound,
                  not_answered: !interaction.contact_date,
                }"
              />
            </span>
          </div>
          <span class="state">
            {{ interaction.contact_date ? "Contestada" : "No contestada" }}
            <a-divider type="vertical" class="divider" />
            {{ formatSeconds(interaction.call_duration) }}
          </span>
        </div>

        <p v-if="!history.length">No hay llamadas a este número</p>
      </div>
    </CustomScrollbar>
  </section>
</template>

<script setup>
import { ArrowUpOutlined } from "@ant-design/icons-vue";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";

import { ticketService } from "../../services/TicketService";
import { dateCalendar, formatSeconds } from "@/app/shared/utils/dates";
import { InteractionType } from "@/app/shared/utils/enums";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

const props = defineProps({
  identifier: String,
  height: Number,
  ticketId: Number,
  isRenderInDrawer: { Boolean },
});

const route = useRoute();

const history = ref([]);

onMounted(async () => {
  history.value = await ticketService.getCallInteractionsByIdentifier(
    props.ticketId ?? route.query.ticket,
    props.identifier
  );
});
</script>

<style lang="sass" scoped>
.divider
  margin: 0 2px
  background-color: $gray-7

.alias-identifier-label
  font-size: 12px
  color: $gray-7

.history
  width: 100%
  margin-top: 25px
  flex: 1 1 100%

  h2
    font-size: 14px
    font-weight: 600
    margin-bottom: 20px

.list
  padding: 0 24px
  display: flex
  flex-direction: column
  overflow-y: auto
  gap: 16px
  padding-bottom: 40px

  &::-webkit-scrollbar
    display: none


.call
  width: 100%
  display: flex
  flex-direction: column

  .info
    display: flex
    justify-content: space-between
    gap: 15px

  .type
    min-width: 74px

  .state
    font-size: 12px
    color: $gray-7

  .icon
    color: #73D13D
    transform: rotate(45deg)
    font-weight: 600

    &.inbound
      transform: rotate(225deg)

    &.not_answered
      color: #FF4D4F
</style>
