<template>
  <svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M17.0753 6.29398L11.6512 1.09798C10.95 0.396896 9.99956 0.00211681 9.00795 8.48792e-06C8.01633 -0.00209984 7.06425 0.388635 6.36 1.08673L0.92475 6.29398C0.633053 6.57442 0.400836 6.91074 0.241955 7.28288C0.0830744 7.65502 0.000786007 8.05535 0 8.45999L0 18H18V8.45999C17.9992 8.05535 17.9169 7.65502 17.758 7.28288C17.5992 6.91074 17.3669 6.57442 17.0753 6.29398ZM7.40925 2.15848C7.83918 1.74593 8.41258 1.51657 9.00843 1.51881C9.60427 1.52105 10.1759 1.75471 10.6027 2.17048L15.6637 7.01849L10.5907 12.0915C10.162 12.5002 9.59236 12.7282 9 12.7282C8.40764 12.7282 7.83801 12.5002 7.40925 12.0915L2.33625 7.01849L7.40925 2.15848ZM16.5 16.5H1.5V8.45999C1.5 8.41199 1.50975 8.36549 1.51425 8.31749L6.34875 13.152C7.05198 13.855 8.00563 14.2499 9 14.2499C9.99437 14.2499 10.948 13.855 11.6512 13.152L16.4858 8.31749C16.4903 8.36549 16.5 8.41199 16.5 8.45999V16.5Z"
      :fill="active ? '#000000' : '#262626'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
