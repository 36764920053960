import httpManager from "@/app/shared/utils/httpManager";

/**
 *  Obtener los datos de la etiqueta.
 *
 * @param {Object} body Datos de la etiqueta.
 * @param {number[]} body.tickets
 * @param {number} body.tag
 *
 * @return {Promise<unknown>} Lista los tickets asignados con la etiqueta.
 */
export async function assignTagToTickets(body) {
  const response = await httpManager.post("/tickets/assign-tag/", body);
  return response?.data;
}
