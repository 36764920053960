<template>
  <CustomScrollbar height="500px" class="custom-scroll-web-chat-config">
    <div class="modal-top-section">
      <h3 class="modal-top-section__title margin-0">Visibilidad</h3>
      <p class="modal-top-section__subtitle">
        Configura como y donde quieres que se muestre tu webchat.
      </p>
    </div>

    <a-form ref="formRef" :model="formState" class="step-web-chat-form">
      <div class="web-chat-domain">
        <p class="step-web-chat-form__label step-web-chat-form__label--required">
          Dominio de tu página web
        </p>
        <p>Ingresa las rutas de tu web en las que deseas que aparezca el widget de webchat.</p>
        <div>
          <a-form-item
            v-for="(domain, index) in formState.domains"
            :key="domain.key"
            :name="['domains', index, 'value']"
            :rules="rules"
            class="domain-list"
          >
            <a-input v-model:value="domain.value" placeholder="https://beex.com" />
            <a-button
              class="input-delete-btn"
              type="text"
              :disabled="index === 0"
              @click="removeDomain(domain)"
            >
              <DeleteOutlined />
            </a-button>
          </a-form-item>
          <a-button v-if="maxDomains" class="input-add" type="link" @click="addDomain">
            Agregar nuevo dominio
            <PlusOutlined />
          </a-button>
        </div>
      </div>
      <div>
        <div class="flex-center gap-14 mb-5">
          <p class="step-web-chat-form__label">Móviles</p>
          <a-switch v-model:checked="formState.isResponsive" />
        </div>
        <label>Habilita esta opción si deseas mostrar tu widget en dispositivos móviles.</label>
      </div>

      <div class="web-chat-window-position">
        <p class="step-web-chat-form__label">Elige la posición en la web</p>
        <a-radio-group v-model:value="formState.position">
          <div class="set-position-container">
            <a-radio-button
              v-for="item in webChatPositions"
              :key="item.id"
              :value="item.id"
              class="item-position"
            >
              <div :class="`positioner ${item.class} ${item.pos}`"></div>
            </a-radio-button>
          </div>
        </a-radio-group>
      </div>

      <!-- <div>
        <div class="flex-center gap-14 mb-5">
          <p class="step-web-chat-form__label">Marca de agua</p>
          <a-switch v-model:checked="formState.isFreeVersion" />
        </div>
        <label>
          Deshabilita esta opción si no deseas que se visualice la marca de agua de
          {{ CompanyName }} en tu WebChat.
        </label>
      </div> -->
    </a-form>
  </CustomScrollbar>
</template>

<script setup>
import { ref, toRefs, reactive, watch, onMounted, computed } from "vue";
import { DeleteOutlined, PlusOutlined } from "@ant-design/icons-vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { CompanyName } from "@/app/shared/utils/constants";

const emit = defineEmits(["webChatOptionsUpdated"]);

const props = defineProps({
  domains: {
    type: Array,
    default: () => [{}],
  },
  showInMobile: {
    type: Boolean,
    default: false,
  },
  chatPosition: {
    type: String,
    default: "BOTTOM_RIGHT",
  },
  hasWatermark: {
    type: Boolean,
    default: true,
  },
});

const { domains, showInMobile, chatPosition, hasWatermark } = toRefs(props);

const formRef = ref();

const formState = reactive({
  domains: [{}],
  isResponsive: false, // showInMobile
  position: "BOTTOM_RIGHT",
  isFreeVersion: true, // hasWatermark
});

const maxDomains = computed(() => formState.domains.length <= 9);

// DOMAINS
const addDomain = () => {
  if (maxDomains.value) {
    formState.domains.push({
      value: "",
      key: Date.now(),
    });
  }
};

const removeDomain = (item) => {
  const index = formState.domains.indexOf(item);
  if (index !== -1) {
    formState.domains.splice(index, 1);
  }
};

const submitForm = async () => {
  try {
    await formRef.value.validate();
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: true,
    });
  } catch (error) {
    console.warn("error", error);
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: false,
    });
  }
};

defineExpose({
  submitForm,
});

const domainRegExp = new RegExp(/^(http|https):\/\/[a-zA-Z0-9\-\\.]+\.[a-zA-Z]{2,6}(\S*)?$/);

const validateDomains = async (rule, value) => {
  const duplicatedCount = formState.domains.filter((ele) => ele.value === value).length;

  if (domainRegExp.test(value) && value !== "" && duplicatedCount > 1)
    return Promise.reject("Este dominio ya está repetido.");
};

const rules = [
  { required: true, message: "Campo requerido" },
  {
    pattern: domainRegExp,
    message: "Debe ser una url válida, ejemplo: https://beex.com",
  },
  { validator: validateDomains, trigger: "change" },
];

watch(
  formState,
  (newVal) => {
    emit("webChatOptionsUpdated", newVal);
  },
  { deep: true }
);

const webChatPositions = [
  {
    id: "TOP_LEFT",
    class: "top-position",
    pos: "top-position--left",
  },
  {
    id: "TOP_RIGHT",
    class: "top-position",
    pos: "top-position--right",
  },
  {
    id: "BOTTOM_RIGHT",
    class: "bottom-position",
    pos: "bottom-position--right",
  },
  {
    id: "BOTTOM_LEFT",
    class: "bottom-position",
    pos: "bottom-position--left",
  },
];

onMounted(() => {
  formState.domains = domains.value;
  formState.isResponsive = showInMobile.value;
  formState.position = chatPosition.value;
  formState.isFreeVersion = hasWatermark.value;
});
</script>

<style lang="sass" scoped>
@import '../sharedStyles'

.domain-list
  :deep(.ant-form-item-control-input-content)
    display: flex
    align-items: center
    gap: 12px

.input-delete-btn
  padding: 4px
  display: flex
  align-items: center

  &:disabled
    .anticon
      color: $gray-6
  .anticon
    font-size: 20px
    color: $red-3
  &:hover
    color: $red-3
    transform: scale(1.02)

.input-add
  padding: 0px
  &:hover
    color: #1677ff

.set-position-container
  margin-top: 8px
  display: grid
  grid-template-columns: repeat(4, 80px)
  gap: 16px

.item-position
  width: 80px
  height: 80px
  position: relative
  cursor: pointer

  &.ant-radio-button-wrapper
    background-color: $gray-4
    border: 1px solid transparent
    border-radius: 4px
    transition: 0.2s all

    &::before
      display: none

  &.ant-radio-button-wrapper-checked
    border: 1px solid $blue-6
    span ~ span .positioner
      background-color: $blue-6

.positioner
  width: 16px
  height: 16px
  position: absolute
  border-radius: 100%
  background-color: $gray-7

.top-position
  top: 8px

  &--left
    left: 8px

  &--right
    right: 8px

.bottom-position
  bottom: 8px

  &--left
    left: 8px

  &--right
    right: 8px
</style>
