export const RESET_STATE = "RESET_STATE";
export const SET_TYPIFICATION = "SET_TYPIFICATION";
export const SET_FLAG_TREE = "SET_FLAG_TREE";
export const SET_PORTFOLIO_NAME = "SET_PORTFOLIO_NAME";
export const SET_BUSINESS_TYPE = "SET_BUSINESS_TYPE";
export const SET_TYPIFICATION_TYPE = "SET_TYPIFICATION_TYPE";
export const UPDATE_TYPIFICATION_ITEM_BY_STATUS = "UPDATE_TYPIFICATION_ITEM_BY_STATUS";
export const ADD_ITEM_TO_TYPIFICATION = "ADD_ITEM_TO_TYPIFICATION";
export const DELETE_TYPIFICATION_ITEM = "DELETE_TYPIFICATION_ITEM";
export const UPDATE_ITEM_TO_TYPIFICATION = "UPDATE_ITEM_TO_TYPIFICATION";
export const SET_SAVED = "SET_SAVED";
export const SET_TYPIFICATION_TREE_MUTATION_STATUS = "SET_TYPIFICATION_TREE_MUTATION_STATUS";
export const SET_LAST_MODIFIED = "SET_LAST_MODIFIED";
export const SET_FORMS = "SET_FORMS";
export const SET_TYPIFICATION_DISABLED = "SET_TYPIFICATION_DISABLED";
