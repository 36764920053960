<template>
  <div class="avatar" :class="getClasses">
    <img v-if="photo" :src="photo" class="avatar__photo" alt="photo" />

    <div v-else class="avatar__icon" :title="name || 'No identificado'">
      {{ text }}
    </div>

    <IconChatChannel :type="channel" class="avatar__channel" />
  </div>
</template>

<script>
import IconChatChannel from "@/app/shared/components/icons/IconChatChannel.vue";

export default {
  components: {
    IconChatChannel,
  },
  props: {
    name: { type: String, default: "" },
    photo: { type: String, default: null },
    size: {
      type: String,
      default: undefined,
      validator: (value) => ["small", "large", "xl"].includes(value),
    },
    channel: {
      required: false,
      type: Number,
      default: 0,
    },
  },
  computed: {
    text() {
      if (!this.name) return "NI";
      const words = this.name?.split(" ") ?? [];
      const firstLetter = words[0][0];
      if (firstLetter == undefined) {
        return "NI";
      }
      const secondLetter = words.length > 1 ? words[1][0] : "";
      if (secondLetter == undefined) {
        return "NI";
      }
      return `${firstLetter}${secondLetter}`.toUpperCase();
    },
    getClasses() {
      return {
        "avatar--small": this.size === "small",
        "avatar--large": this.size === "large",
        "avatar--xl": this.size === "xl",
      };
    },
  },
};
</script>

<style lang="sass" scoped>
.avatar
  width: 40px
  height: 40px
  color: $white
  user-select: none
  position: relative

  &__photo
    display: block
    width: 100%
    height: 100%
    border-radius: 50%
    position: relative

  &__icon
    height: 100%
    display: flex
    align-items: center
    justify-content: center
    background-color: white
    position: relative
    border-radius: 50%
    font-size: 16px
    font-weight: 600
    border: 1px solid $geekblue-6
    color: $geekblue-7
    background-color: $geekblue-1

  &__channel
    position: absolute
    bottom: -4px
    right: -4px

  &--small
    width: 28px
    height: 28px

  &--small &__icon
    font-size: 12px

  &--large
    width: 30px
    height: 30px
    margin-right: 8px

  &--xl
    width: 92px
    height: 92px

  &--xl &__icon
    font-size: 32px
</style>
