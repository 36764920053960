import EmailPage from "@/app/emails/pages/EmailPage.vue";

export default [
  {
    path: "/email",
    name: "email",
    // component: () => import(/* webpackChunkName: "flows-page" */ "@/app/flows/pages/FlowsPage.vue"),
    // redirect: "/flows/forms",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "emails-manager",
        component: () =>
          import(
            /* webpackChunkName: "emails-manager" */ "@/app/emailsManager/pages/EmailsManagerPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "mailbox",
        name: "email-mailbox",
        component: EmailPage,
        // meta: {
        //   requiresAuth: true,
        // },
      },
      {
        path: "templates-email",
        component: () =>
          import(
            /* webpackChunkName: "email-templates-page" */ "@/app/flows/emailTemplates/pages/EmailTemplatesPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
        children: [
          {
            path: "",
            component: () =>
              import(
                /* webpackChunkName: "email-templates-list" */ "@/app/flows/emailTemplates/templates/TemplatesListTemplate.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
          {
            path: ":id",
            component: () =>
              import(
                /* webpackChunkName: "email-template-details" */ "@/app/flows/emailTemplates/templates/TemplateDetailsTemplate.vue"
              ),
            meta: {
              requiresAuth: true,
            },
          },
        ],
      },
    ],
  },
];
