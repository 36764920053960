<template>
  <a-table
    size="middle"
    :scroll="scrollStyle"
    :loading="loading"
    :columns="blackListTableColumns(props.blackListTabSelection)"
    :dataSource="tableData?.results"
    :pagination="pagination"
    @change="handleChangePagination"
    row-key="id"
  >
    <template #bodyCell="{ column, record }">
      <template v-if="column.dataIndex === 'portfolios'">
        {{ countPortfolios(record.portfolios) }}
        <a-popover placement="bottom" v-if="record.portfolios?.length > 0">
          <template #content>
            <ul class="container__portfolio">
              <li v-for="item of record.portfolios" :key="item.id">
                {{ item.name }}
              </li>
            </ul>
          </template>
          <InfoCircleOutlined />
        </a-popover>
      </template>
      <template v-if="column.dataIndex === 'channels_customers'">
        {{ countChannelsCustomers(record.channels_customers) }}
        <a-popover placement="bottom" v-if="record.channels_customers?.length > 0">
          <template #content>
            <ul class="container__portfolio">
              <li v-for="item of record.channels_customers" :key="item.id">
                {{ item.identifier }}
              </li>
            </ul>
          </template>
          <InfoCircleOutlined />
        </a-popover>
      </template>
      <template v-if="column.dataIndex === 'country_code'">
        <img class="flag" :src="record.country?.url_flag" alt="word wide web" />
        +{{ record.country_code }}
      </template>
      <template v-if="column.dataIndex === 'origin'">
        {{ BlockedOriginName[record.origin] }}
      </template>
      <template v-if="column.dataIndex === 'blocked_type'">
        {{ record.blocked_type ? BlockedTypeName[record.blocked_type] : "-" }}
      </template>
      <template v-if="column.dataIndex === 'blocked_by'">
        {{ record.blocked_by?.fullname }}
      </template>
      <template v-if="column.dataIndex === 'actions'">
        <div class="actions" id="blacklist-actions">
          <InfoCircleOutlined @click="handleOpenDetails(record)" />
          <!-- v-if="props.blackListTabSelection === BlackListTab.Whatsapp" -->
          <!-- solo para probar quito condicion -->
          <EditOutlined @click="handleEditDetails(record)" />
          <DeleteOutlined @click="handleConfirmDelete(record)" />
        </div>
      </template>
    </template>
  </a-table>
  <BlockNumberDetailsModal v-model:open="openBlockNumberDetailsModal" :block-number="blockNumber" />
  <BlockNumberModal
    v-model:open="openEditBlockNumberModal"
    :blackListTabSelection="blackListTabSelection"
    :blockNumber="blockNumber"
    @fetch-data="fetchDataBlackList"
  />
</template>
<script setup lang="ts">
import { ref, watch, computed, createVNode, h } from "vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { blackListTableColumns } from "@/app/platformSettings/utils/columns/blackList";
import { DeleteOutlined, InfoCircleOutlined, EditOutlined } from "@ant-design/icons-vue";
import BlockNumberDetailsModal from "@/app/platformSettings/components/modal/BlockNumberDetailsModal.vue";
import { IBlackListFilters, IBlackList } from "@/@types/platformSettings/blacklist";
import {
  BlackListTabChannelId,
  BlackListTab,
  BlockedOriginName,
  BlockedTypeName,
} from "@/app/platformSettings/utils/enums";
import { blackListService } from "@/app/platformSettings/services/BlackListService";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";
import { debounce } from "lodash";
import { Modal, message } from "ant-design-vue";
import BlockNumberModal from "@/app/platformSettings/components/modal/BlockNumberModal.vue";

const props = defineProps<{
  blackListTabSelection: BlackListTab;
  filters: IBlackListFilters;
}>();

const { scrollStyle } = useScrollResize({ headerMargin: 520, width: 1000 });

const openBlockNumberDetailsModal = ref<boolean>(false);
const openEditBlockNumberModal = ref<boolean>(false);
const loading = ref<boolean>(false);
const tableData = ref<{ count: number; results: IBlackList[] }>();
const blockNumber = ref<IBlackList>();

const currentTablePage = ref(1);
const pagination = computed(() => ({
  total: tableData.value?.count,
  current: currentTablePage.value,
  pageSize: TABLE_PAGE_SIZE,
  showSizeChanger: false,
}));

const countPortfolios = (portfolios: any[]) => {
  if (portfolios.length == 0) return "Sin campañas";
  else if (portfolios.length == 1) return "1 Campaña";
  else return `${portfolios.length} campañas`;
};

const countChannelsCustomers = (channelsCustomers: any[]) => {
  if (channelsCustomers.length == 0) return "Sin identificadores";
  else if (channelsCustomers.length == 1) return "1 identificador";
  else return `${channelsCustomers.length} identificadores`;
};

const fetchDataBlackList = debounce(async () => {
  loading.value = true;

  tableData.value = await blackListService.getBlackList(
    { ...props.filters, channel: BlackListTabChannelId[props.blackListTabSelection] },
    pagination.value.current
  );

  loading.value = false;
}, 500);

const handleChangePagination = async (pagination: { current: number }) => {
  currentTablePage.value = pagination.current;
  await fetchDataBlackList();
};

const handleOpenDetails = (item: IBlackList) => {
  blockNumber.value = item;
  openBlockNumberDetailsModal.value = true;
};

const handleEditDetails = (item: IBlackList) => {
  blockNumber.value = item;
  openEditBlockNumberModal.value = true;
};

const handleConfirmDelete = (item: IBlackList) => {
  Modal.confirm({
    icon: createVNode(QuestionCircleOutlined),
    title: h("span", null, [
      "¿Estás seguro de desbloquear el número ",
      h("br"),
      "+",
      item.country_code,
      item.phone_number,
      "?",
    ]),
    content: "Recuerda que este número podrá volver a comunicarse y ser comunicado por tu empresa.",
    okText: "Desbloquear",
    cancelText: "Cancelar",
    centered: true,
    onOk: async () => {
      try {
        // conexion a api aquí
        await blackListService.delete(item.id);
        message.success("Se desbloqueo número correctamente");
        await fetchDataBlackList();
      } catch (error) {
        message.error("Ha ocurrido un error");
      }
    },
  });
};

watch(
  props.filters,
  async () => {
    await fetchDataBlackList();
  },
  { immediate: true }
);

defineExpose({ fetchDataBlackList });
</script>
<style scoped lang="sass">
.actions
  display: flex
  gap: 5px
  justify-content: center
  color: #1677FF

.container__portfolio
  color: $gray-8

.flag
  width: 20px
  margin-bottom: 2px
</style>
