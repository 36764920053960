import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener el detalle de una campaña.
 * @param {number} portfolioId Identificador de la campaña.
 * @return {Promise<Object>} Detalles de la campaña.
 */
export async function fetchPortfolioDetail(portfolioId) {
  const url = `/portfolios/${portfolioId}/`;
  const response = await httpManager.get(url);
  return response?.data;
}
