<template>
  <section>
    <p class="paragraph">
      Puedes agregar o editar email.
      <a @click="handleOpenAddEmail()"><span>Agregar email</span></a>
    </p>
    <add-email-contact-drawer
      :visible="isAddEmailVisible"
      @onCloseDrawerEmail="handleCloseDrawer"
      :itemId="itemId"
    />
    <div class="flex">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 500 }"
        :pagination="pagination"
        class="table__phone"
      >
        <template #actionScoped="{ record }">
          <div class="actions">
            <a-tooltip>
              <template #title> Editar </template>
              <update-email-contact-drawer :record="record" />
            </a-tooltip>
            <a-tooltip>
              <template #title> Eliminar </template>
              <DeleteOutlined class="icon" @click="handleDelete(record)" />
            </a-tooltip>
          </div>
        </template>
      </a-table>

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getEmails?.length > 2">
          ver más ({{ getEmails?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
</template>

<script>
// TODO-JOHN: Refactor, remover modals de la tabla
import { columns } from "@/app/contacts/components/tables/email/ContactEmailsTableColumns";
import AddEmailContactDrawer from "@/app/contacts/components/drawers/email/AddEmailContactDrawer.vue";
import UpdateEmailContactDrawer from "@/app/contacts/components/drawers/email/UpdateEmailContactDrawer.vue";
import { DeleteOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";
import { createVNode } from "vue";
import { Modal } from "ant-design-vue";

export default {
  components: {
    AddEmailContactDrawer,
    UpdateEmailContactDrawer,
    DeleteOutlined,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  data() {
    return {
      columns,
      isAddEmailVisible: false,
      position: 0,
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      openTable: false,
      dataTable: [],
    };
  },
  watch: {
    getEmails() {
      this.dataTable = this.getEmails;
    },
  },
  computed: {
    columnsTable() {
      return this.columns;
    },
    emails() {
      return this.$store.getters["getContactEmail"].items?.map((item) => {
        return {
          id: item.id,
          email: item.email,
          type_email: item.type_email,
        };
      });
    },
    getEmails() {
      return this.$store.getters["getContactEmail"].items;
    },
  },

  created() {
    this.$store.dispatch("fetchEmailContact", this.itemId);
  },
  methods: {
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getEmails.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      this.openTable = false;
    },
    handleCloseDrawer() {
      this.isAddEmailVisible = false;
    },
    handleOpenAddEmail() {
      this.isAddEmailVisible = true;
    },
    handleEdit(value) {
      this.$store.dispatch("deleteEmailClient", value.id);
    },
    handleDelete(value) {
      Modal.confirm({
        title: `¿Estás seguro de Eliminar este email?`,
        content: "Recuerda que las interacciones asociadas a este email serán finalizadas.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.$store.dispatch("deleteEmailContact", value.id),
        centered: true,
        okText: "Eliminar",
        cancelText: "Cancelar",
      });
    },
  },
};
</script>
<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7
.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px
.actions
  display: flex

.scroll
  position: absolute
  right: 0
  top: 0
  bottom: 0
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  margin-top: 42px
  border: 1px solid #E5E5E5
  background: #F9F9F9
  border-radius: 5px
  height: 86px
  width: 20px

.table__phone
  margin-right: 20px

.flex
  position: relative

:deep(.ant-pagination)
  display: none !important

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 10px
</style>
