import {
  SET_ENTITIES,
  REMOVE_ENTITY,
  SET_ENTITY,
  ADD_ENTITY,
  UPDATE_STATUS_ENTITY,
  SEND_ENTITY_TOKEN,
} from "./mutation-types";

const mutations = {
  [SET_ENTITIES](state, data) {
    state.entitiesInfo = data;
  },
  [ADD_ENTITY](state, entity) {
    state.entitiesInfo.items.unshift(entity);
  },
  [SET_ENTITY](state, entity) {
    const index = state.entitiesInfo.items.findIndex((item) => entity.id === item.id);
    state.entitiesInfo.items.splice(index, 1, entity);
  },
  [REMOVE_ENTITY](state, entityId) {
    const index = state.entitiesInfo.items.findIndex((item) => entityId === item.id);
    state.entitiesInfo.items.splice(index, 1);
  },
  [UPDATE_STATUS_ENTITY](state, entity) {
    const index = state.entitiesInfo.items.findIndex((item) => entity.id === item.id);
    state.entitiesInfo.items.splice(index, 1, entity);
  },
  [UPDATE_STATUS_ENTITY](state, payload) {
    const index = state.entitiesInfo.items.findIndex((item) => {
      return item.id == payload.id;
    });
    state.entitiesInfo.items.splice(index, 1, payload.updatedStatusEntity);
  },
  [SEND_ENTITY_TOKEN](state, token) {
    state.token = { ...token };
  },
};

export default mutations;
