import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear un nuevo troncal.
 *
 * @param {Object} userData Datos del troncal a crear.
 *
 * @return {Promise<Object>} Nuevo troncal
 */
export default async function (didData) {
  const body = {
    ...didData,
  };
  const response = await httpManager.post("/pbx/dids/", body);
  return response?.data;
}
