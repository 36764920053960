<template>
  <div class="cellphone__wrapper">
    <img :src="smsPhoneImage" alt="cellphone" class="cellphone" />

    <div class="overlap-text" :class="{ preview: !content?.length }">
      <CustomScrollbar height="290px" auto>
        <!-- {{ state ? reactiveContent : content || preview }} -->
        {{ content ? reactiveContent || content : preview }}
      </CustomScrollbar>

      <span>14:15</span>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import { cloneDeep } from "lodash";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

import smsPhoneImage from "@/app/channels/assets/sms-phone.svg?url";

const props = defineProps<{ preview?: string; state?: Record<number, string>; content: string }>();

const reactiveContent = ref(props.content);

watch(
  () => props.content,
  (newContent) => {
    reactiveContent.value = newContent;
  },
  { immediate: true }
);

watch(
  () => props.state,
  (newState) => {
    if (!newState) return;

    const initialContentArray = props.content.split(" ");
    const contentArray = cloneDeep(initialContentArray);

    Object.values(newState).forEach((val, idx) => {
      const num = idx + 1;

      const singleParamRegex = new RegExp(`{{[${num}]}}`);

      const paramIdx = initialContentArray.findIndex((word) => singleParamRegex.test(word));

      const newParamText = initialContentArray[paramIdx].replace(
        singleParamRegex,
        val || `{{${num}}}`
      );

      contentArray[paramIdx] = newParamText;

      reactiveContent.value = contentArray.join(" ");
    });
  },
  { deep: true }
);
</script>

<style scoped lang="sass">
.cellphone
  min-width: 0
  width: fit-content
  // width: 100%
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.35)
  border-radius: 15px

  &__wrapper
    position: relative

.overlap-text
  color: white
  line-height: 22px
  position: absolute
  top: 18.5%
  right: 5%
  left: 5%
  max-height: 325px
  padding: 5px 10px
  background: #373E4E
  border-radius: 20px
  box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.25)
  word-wrap: break-word

  &.preview
    color: #BFBFBF

  span
    color: #8C8C8C
    width: 100%
    display: block
    text-align: right
    font-size: 12px
    margin-top: 5px

:deep(.ps__rail-y)
  opacity: 0.6
</style>
