<template>
  <div class="dialer" :style="getStyles">
    <img class="dialer__move" draggable="false" :src="moveDotsImage" alt="move icon" />
    <span class="dialer__receiver spacer"> {{ name }} </span>
    <span v-if="elapsedTime"> {{ hours(elapsedTime) }} </span>
    <img
      class="dialer__maximize"
      :src="maximizeImage"
      alt="maximize icon"
      @click="$emit('click:maximize')"
    />
  </div>
</template>

<script>
import { themes } from "../../../../styles/themes";
import { secondsToHHMMSS } from "../../../../utils/time";
import { defineComponent } from "vue";
import moveDotsImage from "../../../../assets/move.svg?url";
import maximizeImage from "../../../../assets/maximize.svg?url";

export default defineComponent({
  inject: {
    theme: { default: "theme" },
  },
  methods: {
    hour(seconds) {
      return secondsToHHMMSS(seconds);
    },
  },
  computed: {
    receiver() {
      return this.$jssip.phone.receiver;
    },
    name() {
      return this.receiver?.name || "No identificado";
    },
    getStyles() {
      return {
        backgroundColor: themes[this.theme].backgroundColor,
      };
    },
    /**
     * Elapsed time in seconds.
     * @return {number} Elapsed seconds.
     */
    elapsedTime() {
      return this.$jssip.phone.elapsed;
    },
  },
  setup() {
    return {
      moveDotsImage,
      maximizeImage,
    }
  }
});
</script>

<style lang="sass" scoped>
@import '../../../../styles/main'

.dialer
  height: 52px
  display: flex
  align-items: center
  padding: 12px
  gap: 12px

  &__receiver
    font-size: 16px
    font-weight: 600
    overflow: hidden
    text-overflow: ellipsis
    white-space: nowrap

  &__move
    cursor: move
    padding: 12px 0

  &__maximize
    cursor: pointer

.spacer
  flex: auto
</style>
