import dayjs from "dayjs";

export abstract class Evaluator {
  public static contains(inputValue: string, comparisonValue = ""): boolean {
    const value = inputValue.toLowerCase();
    const compare = comparisonValue.toLowerCase();

    return value.includes(compare);
  }

  public static beginsWith(inputValue: string, comparisonValue = ""): boolean {
    const value = inputValue.toLowerCase();
    const compare = comparisonValue.toLowerCase();

    return value.startsWith(compare);
  }

  public static endsWith(inputValue: string, comparisonValue = ""): boolean {
    const value = inputValue.toLowerCase();
    const compare = comparisonValue.toLowerCase();

    return value.endsWith(compare);
  }

  public static isEmpty(inputValue: string): boolean {
    return !inputValue;
  }

  public static isEqual(inputValue: string, comparisonValue = ""): boolean {
    const value = inputValue.toLowerCase();
    const compare = comparisonValue.toLowerCase();

    return value === compare;
  }

  private static getParsedInputValue(inputValue: string | string[]) {
    // const getFormatToUse = (date: string) => {
    //   const isTime = dayjs(date, "HH:mm", true).isValid();

    //   return isTime ? "HH:mm" : "YYYY-MM-DD";
    // };

    let valueToParse;

    if (Array.isArray(inputValue)) valueToParse = inputValue[1];
    else valueToParse = inputValue;

    const isTime = dayjs(valueToParse, "HH:mm", true).isValid();
    const formatToUse = isTime ? "HH:mm" : "YYYY-MM-DD";

    return {
      value: dayjs(valueToParse, formatToUse),
      unit: isTime ? "m" : "d",
      format: formatToUse,
    } as const;
  }

  public static isBefore(inputValue: string | string[], comparisonValue = ""): boolean {
    const { value, format, unit } = this.getParsedInputValue(inputValue);
    const compare = dayjs(comparisonValue, format);

    return value.isBefore(compare, unit);
  }

  public static isAfter(inputValue: string | string[], comparisonValue = ""): boolean {
    const { value, format, unit } = this.getParsedInputValue(inputValue);
    const compare = dayjs(comparisonValue, format);

    return value.isAfter(compare, unit);
  }
}
