<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :required="isRequired"
  >
    <p class="instruction">{{ input.instruction }}</p>

    <a-checkbox-group
      :value="workspaceFormState?.[input.name]"
      class="list-group"
      :disabled="conditionedAttributes?.disabled"
      @update:value="handleChange"
    >
      <a-checkbox v-for="option in input.options" :key="option.id" :value="option.value">
        {{ option.value }}
      </a-checkbox>

      <a-checkbox :value="otherValue" v-if="input.has_other_option">
        Otro

        <a-input
          v-model:value="otherValue"
          :disabled="!workspaceFormState?.[input.name]?.includes(otherValue)"
          placeholder="Escribir otra opción aquí"
        />
      </a-checkbox>
    </a-checkbox-group>
  </a-form-item>
</template>

<script setup lang="ts">
import { inject, computed, ref, watch } from "vue";

import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";

const props = defineProps<{
  input: FormElementMap[ElementType.MultipleOptions];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const workspaceFormState = inject("workspaceFormState") as Record<string, string[] | null>;

const otherValue = ref("");

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const handleChange = (value: string[]) => {
  if (!props.isWorkspace) return;

  const limit = props.input.selection_limit;

  if (limit && value?.length > limit) return;

  workspaceFormState[props.input.name] = value;
};

watch(otherValue, (newVal, prevVal) => {
  const workspaceElementState = workspaceFormState[props.input.name];

  if (!workspaceElementState) return;

  if (workspaceElementState.includes(prevVal)) {
    const index = workspaceElementState.findIndex((opt) => opt === prevVal);

    if (index === -1) return;

    workspaceElementState[index] = newVal;
  }
});

const initialValue = null;

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px

.list-group
  display: flex
  flex-direction: column
  gap: 5px

  .ant-checkbox-wrapper
    margin-left: 0
</style>
