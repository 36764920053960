export const columns = [
  {
    title: "Fecha y hora",
    dataIndex: "created_at",
    width: 160,
    slots: { customRender: "created_at" },
    // sorter: (a, b) => {
    //   console.log(a.created_at, b.created_at);
    //   return a.created_at - b.created_at;
    // },
  },
  {
    title: "Correo ID",
    dataIndex: "id",
    width: 100,
    slots: { customRender: "id" },
  },
  {
    title: "Última vez gestionado por",
    dataIndex: "user_fullname",
    width: 200,
  },
  {
    title: "Último identificador",
    dataIndex: "identifier",
    slots: { customRender: "identifier" },
    width: 210,
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 90,
    fixed: "right",
  },
];
