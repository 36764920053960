<template>
  <div style="height: calc(100% - 150px)">
    <WorkspaceChatHeader
      @update:ticket="$emit('update:ticket')"
      :isChat="false"
      :inCall="showWebphone"
    />
    <WorkspaceCallCard v-if="showWebphone" />
    <WorkspaceVoiceCard v-else />
  </div>
</template>

<script>
import { defineComponent } from "vue";

import WorkspaceChatHeader from "@/app/workspace/components/chat/WorkspaceChatHeader.vue";
import WorkspaceCallCard from "@/app/workspace/components/cards/WorkspaceCallCard.vue";
import WorkspaceVoiceCard from "@/app/workspace/components/cards/WorkspaceVoiceCard.vue";
import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import { ChannelType, EventManagement } from "@/app/shared/utils/enums";

import { registerEventManagement } from "@/app/shared/services";

export default defineComponent({
  components: {
    WorkspaceCallCard,
    WorkspaceVoiceCard,
    WorkspaceChatHeader,
  },
  props: {
    ticket: Object,
  },
  computed: {
    isCurrentTicket() {
      const ticketId = this.$route.query?.ticket;
      const interactionId = this.$route.query?.interaction;

      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
    isInAcw() {
      return this.$store.getters["getStateUser"] === EventManagement.Acw;
    },
    showWebphone() {
      return this.isInACall && this.isCurrentTicket && !this.isInAcw;
    },
    interactionId() {
      return this.$route.query?.interaction;
    },
    ticketId() {
      return this.$route.query?.ticket;
    },
    isInACall() {
      return (
        this.$jssip.phone.status == CallStatus.Connecting ||
        this.$jssip.phone.status == CallStatus.Ringing ||
        this.$jssip.phone.status == CallStatus.InCall
      );
    },
    dataUserState() {
      return this.$store.getters["getDataUserState"];
    },
    dialingType() {
      return this.$store.getters["getDialingType"];
    },
    globalState() {
      return this.$store.getters["getStateUser"];
    },
    ticketSelected() {
      return this.ticket;
    }
  },
  methods: {
    registerEventOnline() {
      registerEventManagement(EventManagement.Online, {
        call_ticket: this.$jssip.phone?.meta?.ticket,
        type_dialing: this.dialingType,
        menu: 23,
        state: this.dataUserState,
      });
    }
  },
});
</script>
