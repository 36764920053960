import axios from "@/plugins/axios";
const local = false;
/**
 * Conecta con el socket para lanzar el evento del progresivo.
 *
 * @return {Bolean} Lista de Empresas con sus campañas.
 */
export async function launchStrategy(data) {
  const url = local
    ? "http://localhost:8081/tickets/notify-campaign/"
    : `${import.meta.env.VITE_SOCKET}notify-campaign/`;
  const response = await axios.post(url, {
    customer_id: data.customer_id,
    iduser: data.user_id,
    idcampaign: data.campaign_id,
  });

  return response.data;
}
