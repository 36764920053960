<template>
  <a-table
    :data-source="datatable"
    :columns="columns"
    :pagination="false"
    :scroll="scroll"
    row-key="id"
    size="middle"
  >
    <template #client="{ record }">
      <!--Validacion para canal de Whatsapp-->
      <span v-if="record.channel && record.channel.id === ChannelType.Whatsapp">
        {{ record.client.ticket.code_country || "" }}
        {{ record.client.ticket.identifier }}
      </span>
      <span v-else-if="record.client.ticket.extra_information">
        {{ record.client.ticket.extra_information.fullname }}
      </span>
      <span v-else>No identificado</span>
    </template>
    <template #channel="{ record }">
      <div class="icon__channel">
        <a-tooltip>
          <template #title>
            {{ typeChannel(record.channel?.id) }}
          </template>
          <icon-channel :type="record.channel?.id" :width="14" />
        </a-tooltip>
      </div>
    </template>
  </a-table>
</template>

<script>
import IconChannel from "@/app/shared/components/icons/IconChannel.vue";
import { ChannelType } from "@/app/shared/utils/enums";
import { chatOnHoldTableColumns } from "@/app/supervision/utils/constants";
import { defineComponent } from "vue";

const channelsName = {
  [ChannelType.Whatsapp]: "Whatsapp",
  [ChannelType.Messenger]: "Messenger",
  [ChannelType.Inbound]: "Web Chat",
  [ChannelType.Sms]: "Sms",
};

export default defineComponent({
  components: {
    IconChannel,
  },
  props: {
    datatable: {
      required: true,
      type: Array,
    },
  },
  data() {
    return {
      ChannelType,
      windowHeight: window.innerHeight,
      columns: chatOnHoldTableColumns,
    };
  },
  computed: {
    scroll() {
      return { y: this.windowHeight / 2 - 150 };
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  methods: {
    typeChannel(channel) {
      return channelsName[channel];
    },
  },
});
</script>

<style lang="sass" scoped>
.icon__channel
  text-align: center
</style>
