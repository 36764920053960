import { SET_QUEUES, UPDATE_QUEUE, REMOVE_QUEUE } from "./mutation-types";

const mutations = {
  [SET_QUEUES](state, data) {
    state.queuesInfo = data;
  },
  [UPDATE_QUEUE](state, queue) {
    const index = state.queuesInfo.items.findIndex((item) => item.id === queue.id);
    state.queuesInfo.items.splice(index, 1, queue);
  },
  [REMOVE_QUEUE](state, queue) {
    const index = state.queuesInfo.items.findIndex((item) => item.id === queue.id);
    state.queuesInfo.items.splice(index, 1);
  },
};

export default mutations;
