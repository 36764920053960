<template>
  <a-drawer
    :visible="visible"
    title="Agregar dirección"
    destroy-on-close
    :mask-closable="false"
    placement="right"
    width="480"
    keyboard
    :body-style="{ padding: 0 }"
    @close="handleCloseDrawer"
  >
    <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
      <a-form
        class="form"
        layout="vertical"
        ref="formRef"
        :model="formState"
        :rules="rules"
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="Dirección" name="address">
          <a-input
            v-model:value="formState.address"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>
        <a-form-item label="Tipo" name="type_address">
          <a-input
            v-model:value="formState.type_address"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>

        <a-form-item label="Departamento" name="department">
          <a-input
            v-model:value="formState.department"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>

        <a-form-item label="Provincia" name="province">
          <a-input
            v-model:value="formState.province"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>

        <a-form-item label="Distrito" name="district">
          <a-input
            v-model:value="formState.district"
            placeholder="Escribe aquí"
            @input="handleChangeInput"
          />
        </a-form-item>
      </a-form>
    </custom-scrollbar>

    <div class="footer">
      <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="!changeInput">
        Agregar dirección
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";

export default defineComponent({
  components: {
    CustomScrollbar,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      address: "",
      district: "",
      province: "",
      type_address: "",
      department: "",
    });

    const rules = {
      address: [{ required: true, message: "Campo requerido" }],
    };

    return {
      rules,
      formState,
      formRef,
    };
  },
  data() {
    return {
      changeInput: false,
    };
  },
  methods: {
    handleChangeInput() {
      this.changeInput = true;
    },
    handleCloseDrawer() {
      this.$emit("onCloseDrawerEdit");
      this.changeInput = false;
      this.resetFields();
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("createAddressContact", {
            addressId: this.itemId,
            body: toRaw(this.formState),
          });
          this.handleCloseDrawer();
          this.$message.success("Se ha agregado información correctamente");
          this.resetFields();
        } catch (error) {
          this.$message.error("Se ha producido un error al agregar la información");
        }
      });
    },
    resetFields() {
      this.formState.address = "";
      this.formState.district = "";
      this.formState.province = "";
      this.formState.type_address = "";
      this.formState.department = "";
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

:deep(.ant-calendar-picker)
  width: 100%
</style>
