import {
  createNewCustomer,
  createTemporalUser,
  validateToken,
  validateTokenNewUser,
  createNewUserInvite,
} from "../../services/index";
import fetchAllZoneTime from "../../services/companyTimeZone";

import { fetchAccessToken } from "../../../auth/services/index";
import { tokenManager } from "@/app/shared/utils/tokenManager";
// import { registerEventManagement } from "@/app/shared/services";
// import { EventManagement, StateOptions } from "@/app/shared/utils/enums";
import {
  ADD_PROPERTIES_TO_CUSTOMER,
  ADD_TOKEN,
  REMOVE_TEMPORAL_USER,
  SET_TEMPORAL_USER,
  SET_PROGRESS,
  SET_NEW_USER_INVITE,
  ADD_PROPERTIES_TO_NEW_USER,
  SET_ZONE_TIME,
  SET_TEMPORAL_FORM,
  SET_REGISTER_STEP,
  SET_REGISTER_WORKSPACE_STEP,
} from "./mutation-types";

const actions = {
  async createTemporalUser({ commit }, temporalUser) {
    const user = await createTemporalUser(temporalUser);
    commit(SET_TEMPORAL_USER, user);
    commit(SET_TEMPORAL_FORM, temporalUser);
  },
  async setRegistrationProgress({ commit }, step) {
    commit(SET_PROGRESS, step);
  },
  async createNewCustomer({ state }, workspace) {
    const customer = { ...state.customer, workspace };
    await createNewCustomer(customer);
  },
  async loginInRegister({ state }) {
    const accessToken = await fetchAccessToken({
      username: state.temporalUser.email,
      password: state.customer.password,
    });
    console.log(accessToken);
    tokenManager.saveToken(accessToken);
    localStorage.removeItem("time");
    // await registerEventManagement(EventManagement.Login);
    // await registerEventManagement(EventManagement.Online, { state: StateOptions.Connected });
    return accessToken;
  },
  async createNewUserInvite({ state }, newUserInvite) {
    const customer = { ...state.newUser, ...newUserInvite };
    await createNewUserInvite(customer);
  },
  async validateRegisterTemporalToken({ commit }, token) {
    const data = await validateToken(token);
    commit(SET_TEMPORAL_USER, { email: data.email, name: data.name });
    commit(ADD_TOKEN, token);
  },
  removeTemporalUserFromStore({ commit }) {
    commit(REMOVE_TEMPORAL_USER);
  },
  //CREAR EMPRESA
  addPropertiesToCustomer({ commit }, properties) {
    commit(ADD_PROPERTIES_TO_CUSTOMER, properties);
  },
  // CREAR USUARIO
  addPropertiesToNewUser({ commit }, newProperties) {
    commit(ADD_PROPERTIES_TO_NEW_USER, newProperties);
  },
  async validateRegisterNewUserToken({ commit }, token) {
    const response = await validateTokenNewUser(token);
    commit(SET_NEW_USER_INVITE, response.data);
  },
  async fetchAllZoneTime({ commit }) {
    const zoneTime = await fetchAllZoneTime();
    commit(SET_ZONE_TIME, zoneTime);
  },
  setRegisterStep({ commit }, step) {
    commit(SET_REGISTER_STEP, step);
  },
  setRegisterWorkspaceStep({ commit }, step) {
    commit(SET_REGISTER_WORKSPACE_STEP, step);
  },
};

export default actions;
