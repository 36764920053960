const getters = {
  getInteractionsChannel: (state) => state.interactionsChannel,
  getEmailHistoryDrawer: (state) => state.emailHistory,
  getWorkspaceClient: (state) => state.client,
  getTicketsFilters: (state) => state.ticketsFilters,
  getWorkspaceTickets: (state) => state.tickets,
  getFilteredTickets: (state) => state.filteredTickets,
  getTicketsListPage: (state) => state.ticketListPage,
  getHasNextTicketPage: (state) => state.hasNextTicketPage,
  getWorkspaceMessageHistory: (state) => state.history || [],
  getWorkspaceClientChannels: (state) => state.channels,
  getSelectedTickets: (state) => state.selectedTickets,
  getSelectedTicketsWithTagLimit: (state) =>
    state.selectedTickets.filter((item) => item.tags.length >= 10),
  getSelectedTicketsWithValidTagLimit: (state) =>
    state.selectedTickets.filter((item) => item.tags.length < 10),
  getSelectedTicketsId: (state) => state.selectedTickets.map((item) => item.id),
  getClientsWithSameNumberFound(state) {
    const items = state.clientsWithSameNumberFound.map((item) => {
      return {
        ...item,
        portfolio: item?.portfolio?.name || "",
      };
    });
    return items;
  },
  getClientsFound(state) {
    const items = state.clientsFound.map((item) => {
      return {
        ...item,
        portfolio: item?.portfolio?.name || "",
      };
    });
    return items;
  },
  getCallEnded: (state) => state.callEnded,
  getTicketDetail: (state) => state.ticketDetail,
  getDialingType: (state) => state.dialingType,
  getChannelPersonTicket: (state) => state.channel_person,
  getSpeechConfig: (state) => state.speechConfig,
  getPortfolioDetail: (state) => state.portfolioDetail,
  getTypificationsTableData: (state) => state.typificationsTableData,
  getCapturedState: (state) => state.isCaptureded,
  getScheduleState: (state) => state.isSchedule,
  getLastTicketOpened: (state) => state.lastTicketOpened,
  getEventMessaging: (state) => state.eventMessaging,
};

export default getters;
