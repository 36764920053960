import httpManager from "@/app/shared/utils/httpManager";
import { toFormData } from "@/app/shared/utils/helpers";

/**
 * Obtener Plantillas de WhatsApp.
 *
 * @return {Promise<Array>} Lista de colas.
 */
export async function sendTemplate(data) {
  if (data.code_country) delete data.country;

  const url = `/workspace/send-whatsapp-template/`;
  const response = await httpManager.post(url, toFormData(data));

  return response.data;
}
