import httpManager from "@/app/shared/utils/httpManager";

/**
 * Assign asterisk id to a ticket
 * @param {number} ticketId Identificador del ticket.
 * @param {string} asteriskId Identificador de asterisk.
 */
export async function updateOutboundCall(ticketId, asteriskId) {
  const url = `/workspace/outbound-call/update/`;

  await httpManager.post(url, { ticket: ticketId, asterisk_id: asteriskId });
}
