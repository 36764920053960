import httpManager from "../../shared/utils/httpManager";
/**
 * Obtener todas  las zonas de horario para la empresa.
 *
 * @return {Promise<Array>} Lista de todas las zonas de horario para la empresa.
 */

export default async function fetchAllZoneTime() {
  const response = await httpManager.get("/parameters/timezones/");
  return response.data;
}
