import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear una nueva base
 *
 * @param {Object} base Datos de la base
 *
 * @return {Promise<Object>} Datos de la base creada
 */
export async function createNewBase(base) {
  const body = base;
  const response = await httpManager.post("/entities/portfolios/bases/", body);
  return response.data;
}
