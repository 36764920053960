<template>
  <div style="padding: 12px 20px; height: 100%">
    <a-tabs destroyInactiveTabPane class="tabs-container" v-model:activeKey="activeKey" type="card">
      <a-tab-pane v-for="tab in tabs" :key="tab.key" :tab="tab.label">
        <MainContainer tabs with-padding>
          <slot :name="tab.key" />
        </MainContainer>
      </a-tab-pane>
    </a-tabs>
  </div>
</template>

<script setup lang="ts" generic="T">
import { ref } from "vue";

import { TabsData } from "@/@types/shared/pickers";

import MainContainer from "./MainContainer.vue";

const props = defineProps<{ tabs: TabsData<T>[] }>();

const activeKey = ref(props.tabs.at(0)?.key);
</script>

<style scoped lang="sass">
.tabs-container
  height: 100%

:deep(.tabs-container > .ant-tabs-nav)
  margin: 0

:deep(.tabs-container > .ant-tabs-nav::before)
  border-color: transparent

:deep(.tabs-container .ant-tabs-content:first-child)
  height: 100%

:deep(.tabs-container .ant-tabs-nav-list:first-child)
  gap: 6px

:deep(.tabs-container .ant-tabs-nav-list:first-child > .ant-tabs-tab)
  border-bottom: 0
  border-color: #d9d9d9

:deep(.tabs-container .ant-tabs-content:first-child > .ant-tabs-tabpane)
  height: 100%
</style>
