<template>
  <section class="quick-answers-container">
    <p>
      Crea plantillas de textos para usarlas en las conversaciones con tus contactos.
      <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para conocer
      más.
    </p>

    <div class="controlls">
      <a-input v-model:value="search" class="search-input" placeholder="Buscar por título">
        <template #prefix>
          <SearchOutlined class="search-input__icon" />
        </template>
      </a-input>

      <a-button type="primary" @click="handleOpenModal('create')">Crear respuesta rápida</a-button>
    </div>

    <QuickAnswersTable
      ref="tableRef"
      :search="search"
      @edit="(record) => handleOpenModal('edit', record)"
    />
  </section>

  <QuickAnswerModal
    v-if="modalConfig.open"
    v-model:open="modalConfig.open"
    :type="modalConfig.type"
    :record="modalConfig.record"
    @update="handleUpdate"
  />
</template>

<script setup lang="ts">
import { SearchOutlined } from "@ant-design/icons-vue";
import { reactive, ref } from "vue";

import { QuickAnswer, ModalActionType } from "@/@types/platformSettings/quickAnswers";

import QuickAnswersTable from "../table/QuickAnswersTable.vue";
import QuickAnswerModal from "../modal/QuickAnswerModal.vue";

const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/ajustes-en-la-plataforma/workspace/`;

const search = ref("");
const modalConfig = reactive<{ open: boolean; type: ModalActionType; record?: QuickAnswer }>({
  open: false,
  type: "create",
  record: undefined,
});

const tableRef = ref<InstanceType<typeof QuickAnswersTable>>();

const handleOpenModal = (type: ModalActionType, record?: QuickAnswer) => {
  modalConfig.open = true;
  modalConfig.type = type;
  modalConfig.record = record;
};

const handleUpdate = () => {
  tableRef.value?.fetchData();
};
</script>

<style scoped lang="sass">
p
  color: $gray-8
  margin-bottom: 16px

.search-input
  width: 265px

  &__icon
    color: $gray-6

.controlls
  display: flex
  justify-content: space-between
  margin-bottom: 16px
</style>
