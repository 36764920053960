import httpManager from "@/app/shared/utils/httpManager";

/**
 * Editar contacto.
 * @param {number} ticketId Identificador del ticket.
 * @param {Object} contact Datos de direccion.
 *
 * @return {Promise<Object>} Datos de la direccion creada
 */
export async function updateContactClient(ticketId, contact) {
  const url = `/workspace/tickets/${ticketId}/contacts/${contact.id}/`;
  const response = await httpManager.put(url, contact);
  return response.data;
}
