<template>
  <div :class="{ detailed: detailedView, disabled: disabled }">
    <div>
      <span class="event">
        <div
          class="event__indicator"
          :style="{
            backgroundColor: EventData?.[record?.event_management_id]?.color,
          }"
        />
        {{ !disabled ? EventData?.[record?.event_management_id]?.name : "Sin evento activo" }}
      </span>
    </div>

    <div :class="{ time__container: detailedView }" v-if="time">
      <small class="time" :class="{ detailed: detailedView }">
        {{ isTimeShowed ? counterWithTimeFormat : "00:00:00" }}
      </small>
    </div>
  </div>
</template>

<script>
import { computed, defineComponent } from "vue";
import { useStore } from "vuex";

import {
  slashDateToDashed,
  millisecondsToSeconds,
  secondsToTimeString,
} from "@/app/shared/utils/dates";
import { EventData } from "@/app/shared/utils/globalVariables";

export default defineComponent({
  props: {
    record: {
      required: true,
      type: Object,
      default: () => ({}),
    },
    time: {
      required: false,
      default: true,
    },
    detailedView: Boolean,
  },
  setup(props) {
    const store = useStore();

    const platformHour = computed(() => store.getters["getHourPlatform"]);
    const platformDate = computed(() => slashDateToDashed(store.getters["getPlatformDate"]));
    const disabled = computed(() => props.record?.event_management_id == null);

    const platformMilliseconds = computed(
      () => new Date(`${platformDate.value} ${platformHour.value}`)?.valueOf() || 0
    );

    const stateChangeMilliseconds = computed(
      () => new Date(props.record?.last_event_change_time)?.valueOf() || 0
    );

    const counterInSeconds = computed(
      () =>
        millisecondsToSeconds(platformMilliseconds.value) -
        millisecondsToSeconds(stateChangeMilliseconds.value)
    );

    const isTimeShowed = computed(
      () =>
        counterInSeconds.value >= 0 &&
        props.record?.last_event_change_time &&
        props.record?.event_management_id
    );

    const counterWithTimeFormat = computed(() => secondsToTimeString(counterInSeconds.value || 0));

    return {
      EventData,
      isTimeShowed,
      counterWithTimeFormat,
      disabled,
    };
  },
});
</script>

<style lang="sass" scoped>
.event
  display: inline-flex
  align-items: center

  &__indicator
    width: 8px
    height: 8px
    border-radius: 50%
    margin-right: 10px

.time
  margin-left: 18px

  &.detailed
    margin: 0
    font-size: 14px

  &__container
    display: flex
    align-items: center
    padding-left: 8px
    border-left: 1px solid $gray-8

.divider
  background-color: $gray-8

.detailed
  display: flex
  align-items: center
  justify-content: space-between

.disabled
  .event, .time
    color: #00000026
    &__indicator
      background-color: #00000026
</style>
