import httpManager from "@/app/shared/utils/httpManager";

const emptyFilters = {
  clients: [],
  custom_clients: [],
  custom_products: [],
};

export async function fetchStrategyCalcutations(
  { portfolio, base, coverage, type_phone },
  filters = emptyFilters
) {
  const body = { portfolio, base, coverage, type_phone, filters };
  const response = await httpManager.post("/strategies/filters/number-clients/", body);
  return response.data;
}
