<template>
  <template v-if="detailedView">
    <span v-if="data?.channel">
      {{ chatName?.[data?.channel?.id] }}
    </span>

    <span v-else class="inactive">Sin Chat</span>
  </template>

  <template v-else>
    <a-popover v-if="chatChannel" placement="bottom" trigger="hover">
      <div class="card" :style="getStyles">
        <div class="card__icon">
          <img :src="getChannelImage(icon)" alt="icono chat" />
        </div>
        <div class="card__content">
          <span> {{ name }} </span>
          <div>{{ identifier }}</div>
        </div>
      </div>
      <template #content>
        <supervision-messaging-popover
          :data="data"
          :identifier="identifier"
          :associate="associate"
          :state="state"
          @capture="handleCapture"
        />
      </template>
    </a-popover>

    <div v-else class="card-inactive">
      <div class="card__icon">
        <img :src="chatImage" alt="Icono sin chat activo" />
      </div>
      <div class="card__content-inactive">
        <span> + 00 000 000 </span>
        <div>Sin chat abierto</div>
      </div>
    </div>
  </template>
</template>

<script>
import { defineComponent } from "vue";
import { ChannelType } from "@/app/shared/utils/enums";
import SupervisionMessagingPopover from "../popover/SupervisionMessagingPopover.vue";
import chatImage from "@/app/shared/assets/channels/chat.svg?url";

const chatIcons = {
  [0]: "chat.svg",
  [ChannelType.Whatsapp]: "whatsapp.svg",
  [ChannelType.Messenger]: "messenger.svg",
  [ChannelType.Inbound]: "inbound.svg",
  [ChannelType.Instagram]: "instagram.svg",
  [ChannelType.Sms]: "sms.svg",
};

const chatName = {
  [ChannelType.Whatsapp]: "WhatsApp",
  [ChannelType.Messenger]: "Facebook Messenger",
  [ChannelType.Inbound]: "Webchat",
  [ChannelType.Instagram]: "Instagram",
  [ChannelType.Sms]: "Sms",
};

const chatColors = {
  [0]: "border: 1px solid #F0F0F0",
  [ChannelType.Whatsapp]: "border: 1px solid #25D366",
  [ChannelType.Messenger]:
    "border: 1px solid; border-image-source: linear-gradient(84.65deg, #8147FF -4.91%, #C53FCE 47.52%, #FF6968 105.71%) !important; border-image-slice: 1;",
  [ChannelType.Inbound]: "border: 1px solid #FF5F1F",
  [ChannelType.Instagram]: "border: 1px solid #E1306C",
  [ChannelType.Sms]: "border: 1px solid rgb(0, 49, 93)",
};

export default defineComponent({
  components: {
    SupervisionMessagingPopover,
  },
  data() {
    return { chatName, chatImage };
  },
  props: {
    callActive: { type: [Boolean, null] },
    data: { required: true, type: [Object, null] },
    state: { required: false, type: Number, default: null },
    detailedView: Boolean,
  },
  computed: {
    chatChannel() {
      return this.data?.channel?.id > 1;
    },
    name() {
      if (this.data?.client) return this.data.client.fullname;
      return "Sin identificar";
    },
    identifier() {
      if (
        this.data?.channel?.id == ChannelType.Inbound ||
        this.data?.channel?.id == ChannelType.Messenger
      )
        return this.data?.channel_customer.associate;
      else if (this.data?.identifier) {
        const codeCountry = this.data?.last_message_interaction?.code_country || "";
        return `${codeCountry} ${this.data.identifier}`;
      }
      return "Sin identificar";
    },
    associate() {
      const channel_customer = this.data?.channel_customer;

      if (channel_customer)
        return this.data?.channel?.id === ChannelType.Whatsapp
          ? `${channel_customer?.alias} (${channel_customer.associate})`
          : channel_customer.associate;
      return "Sin identificar";
    },
    icon() {
      if (this.data?.channel) return chatIcons[this.data.channel.id];
      return chatIcons[0];
    },

    getStyles() {
      if (this.data?.channel) return chatColors[this.data.channel.id];
      return chatColors[0];
    },
  },
  methods: {
    getChannelImage(channel) {
      return new URL(`../../../shared/assets/channels/${channel}`, import.meta.url).href;
    },
    handleCapture() {
      this.$emit("capture");
    },
  },
});
</script>

<style lang="sass" scoped>
.card
  color: $gray-9
  min-width: 225px
  font-size: 11px
  height: 50px
  padding: 8px
  display: flex
  gap: 8px
  border-radius: 6px
  border-width: 2px

  &__icon
    padding: 4px 0
    display: flex
    justify-content: center
    & > img
      width: 30px

  &__content
    display: flex
    flex-direction: column
    width: 100%
  &__content-inactive
    font-size: 11px
    color: #BFBFBF
    display: flex
    flex-direction: column
    justify-content: space-around

  &__subtitle
    display: flex
    justify-content: space-between

  &__content > span
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden
    width: 180px

.inactive
  color: $gray-8

.card-inactive
  min-width: 225px
  height: 50px
  left: 957px
  top: 255px
  background: #FAFAFA
  border: 1px solid #F0F0F0
  box-sizing: border-box
  border-radius: 5px
  display: grid
  grid-template-columns: 0.5fr 1.2fr 0.5fr
  gap: 5px
  border-width: 2px
</style>
