<template>
  <div v-if="lastMessage" class="flex-center gap-4px">
    <div class="status-msg-icon" v-if="lastMessage.origin == 'agent'">
      <component :is="statusIcon" :class="statusClass" />
    </div>
    <p :class="statusClass" v-if="lastMessage.type_message != MessageType.Contacts">
      {{ description }}
    </p>
    <p :class="statusClass" v-else>
      <UserFilledIcon class="fixed-tag-identifier__user-icon" /> 
      <span class="truncate-text">{{ lastMessage?.contact?.name?.formatted_name }}</span>
    </p>
  </div>
</template>

<script setup lang="ts">
import { toRefs, computed } from "vue";
import IconOneCheckOutlined from "@/app/common/components/atoms/IconOneCheckOutlined.vue";
import IconTwoChecksOutlinedGray from "@/app/common/components/atoms/IconTwoChecksOutlinedGray.vue";
import IconTwoChecksOutlinedBlue from "@/app/common/components/atoms/IconTwoChecksOutlinedBlue.vue";
import IconStickerFilled from "@/app/common/components/atoms/IconStickerFilled.vue";
import { WarningOutlined } from "@ant-design/icons-vue";
import UserFilledIcon from "@/app/shared/components/icons/UserFilledIcon.vue";
import { MessageType } from "@/app/shared/utils/enums";

interface LastMessage {
  channel_id: number;
  type_message: number;
  datetime: string;
  time_elapsed: number;
  message: string;
  status_sent: number;
  is_read: boolean;
  blocked: boolean;
  origin: string;
}

interface Props {
  lastMessage: LastMessage;
  lastStatusVoiceInteraction: string;
}

const props = defineProps<Props>();

const { lastMessage } = toRefs(props);

const statusIcon = computed(() => {
  if (lastMessage.value?.type_message === MessageType.Sticker) {
    return IconStickerFilled;
  } else {
    switch (lastMessage.value?.status_sent) {
      // Enviado
      case 2:
        return IconOneCheckOutlined;

      // Entregado
      case 3:
        return IconTwoChecksOutlinedGray;

      // Leído
      case 4:
        return IconTwoChecksOutlinedBlue;

      // Error al enviar
      case 5:
        return WarningOutlined;

      default:
        return null;
    }
  }
});

const description = computed(() => {
  const { value: lastMsg } = lastMessage;
  console.log(lastMessage, 'lastMsg');
  if (!lastMsg) return "No hay mensajes";

  if (lastMessage.value.status_sent === 5) return "Error al enviar";

  const { type_message, message } = lastMsg;

  const isSticker = type_message === MessageType.Sticker;
  const isImage = type_message === MessageType.Image || isSticker;
  const isAudio = type_message === MessageType.Audio;
  const isLocation = type_message === MessageType.Location;

  const fileText = isImage
    ? `${isSticker ? "Sticker" : "📷 Envió una imagen"}`
    : isAudio
    ? "🎧 Envió un audio"
    : isLocation
    ? "📍 Envió una ubicación"
    : "🗂 Envió un archivo";

  return message || fileText;
});

const statusClass = computed(() => ({
  "has-error": lastMessage.value.status_sent === 5,
  message: true,
}));
</script>

<style lang="sass">
.flex-center
  display: flex
  align-items: center

.gap-4px
  gap: 4px

.message
  margin-bottom: 0
  font-size: $font-small
  color: $gray-7
  overflow: hidden
  text-overflow: ellipsis
  white-space: nowrap
  line-height: 20px

.has-error
  color: $red-4

.status-msg-icon
  display: flex
  align-items: center
  width: 16px
  height: 20px

.truncate-text
  display: inline-bloc
  max-width: 200px
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
</style>
