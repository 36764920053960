import httpManager from "@/app/shared/utils/httpManager";
/**
 * Actualizar datos de la plantilla por campaña y tipo de plantilla.
 *
 * @param {string} type Tipo de plantilla [client, product].
 * @param {number} portfolioId Identificador de la campaña.
 * @param {Array} template Lista de campos que forman el template.
 *
 * @return {Promise<Object>} Datos de la campaña con sus plantillas actualizadas.
 */

export async function saveTemplateByType(type, portfolioId, template) {
  let body = { account_template: template };
  if (type == "client") body = { client_template: template };

  const response = await httpManager.put(`/portfolios/${portfolioId}/template/`, body);
  return response.data;
}
