import {
  SET_PORTFOLIOS,
  ADD_PORTOLIO,
  REMOVE_PORTFOLIO,
  SET_PORTFOLIO,
  SET_SELECTED_ENTITY,
  SET_SELECTED_PORTFOLIO,
  SET_ALL_PORTFOLIOS,
} from "./mutation-types";

const mutations = {
  [SET_PORTFOLIOS](state, data) {
    state.portfoliosInfo = data;
  },
  [SET_PORTFOLIO](state, portfolio) {
    const index = state.portfoliosInfo.items.findIndex((item) => portfolio.id == item.id);
    state.portfoliosInfo.items.splice(index, 1, portfolio);
  },
  [SET_SELECTED_ENTITY](state, entityId) {
    state.selectedEntityId = entityId;
  },
  [SET_SELECTED_PORTFOLIO](state, portfolioId) {
    state.selectedPortfolioId = portfolioId;
  },
  [ADD_PORTOLIO](state, newPortfolio) {
    state.portfoliosInfo.items.unshift(newPortfolio);
  },
  [REMOVE_PORTFOLIO](state, portfolioId) {
    const index = state.portfoliosInfo.items.findIndex((item) => portfolioId == item.id);
    state.portfoliosInfo.items.splice(index, 1);
  },
  [SET_ALL_PORTFOLIOS](state, data) {
    state.portfolios = data;
  },
};

export default mutations;
