const initialState = () => ({
  basesInfo: {
    pagination: {},
    items: [],
  },
  bases: [],
});

const state = initialState();

export default state;
