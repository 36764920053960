<template>
  <div>
    <a-button class="button" type="link" @click="handleOpen"> Ver detalle </a-button>

    <a-drawer
      v-model:visible="visible"
      width="722"
      :body-style="{ padding: '8px' }"
      @close="handleClose"
    >
      <template #title>
        <div class="title">
          {{ _title }}

          <UpdateCounterButton :secondsToUpdate="secondsToUpdate" @click="handleUpdate" />
        </div>
      </template>

      <div class="block">
        <p class="block__subtitle">
          {{ slotText }}
        </p>
        <div class="wrapper">
          <slot name="waiting" />
        </div>
      </div>

      <div class="block">
        <p class="block__subtitle block__subtitle--spacing">Usuarios en esta cola</p>
        <AvailableAgentsTable
          :queue="queue"
          :portfolio="portfolio"
          :agents="agents"
          @removeAgent="handleAgentRemoved"
          @refresh="fetchAgents"
        />
      </div>
    </a-drawer>
  </div>
</template>

<script>
import { defineComponent, inject } from "vue";

import { fetchAgentsByQueue } from "@/app/supervision/services";

import AvailableAgentsTable from "../tables/tablesDrawer/AvailableAgentsTable.vue";
import UpdateCounterButton from "@/app/supervision/components/buttons/UpdateCounterButton.vue";

export default defineComponent({
  components: {
    AvailableAgentsTable,
    UpdateCounterButton,
  },
  props: {
    queue: { required: true, type: Number },
    portfolio: { required: true, type: Number },
    title: { type: String, default: "" },
    slotText: { type: String, default: "" },
  },
  setup() {
    const TIME_TO_REFRESH = inject("TIME_TO_REFRESH");
    const secondsToUpdate = inject("secondsToUpdate");

    return { TIME_TO_REFRESH, secondsToUpdate };
  },
  data() {
    return {
      visible: false,
      agents: [],
    };
  },
  watch: {
    async secondsToUpdate(secondsLeft) {
      if (secondsLeft === 0 && this.visible) this.handleUpdate();
    },
  },
  computed: {
    _title() {
      return this.title || `Cola ${this.queue}`;
    },
  },
  methods: {
    async fetchAgents() {
      this.agents = await fetchAgentsByQueue(this.queue);
    },
    handleAgentRemoved(agentId) {
      this.agents = this.agents.filter((agent) => agent.id != agentId);
    },
    handleOpen() {
      this.$emit("open");
      this.fetchAgents();
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
    },
    async handleUpdate() {
      this.secondsToUpdate = this.TIME_TO_REFRESH;
      this.$emit("update");
      await this.fetchAgents();
    },
  },
});
</script>

<style lang="sass" scoped>
.title
  display: flex
  justify-content: space-between
  align-items: center

.block
  &__subtitle
    padding: 0 16px
    font-weight: 600
    color: $gray-8

    &--spacing
      margin-top: 20px

.button
  padding: 0

:deep(.wrapper)
  padding: 0 12px
  min-height: calc(50vh - 112px)
</style>
