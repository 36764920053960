import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un correo.
 *
 * @param {number} emailId Identificador del correo.
 */
export async function deleteEmailClient(emailId) {
  await httpManager.delete(`/workspace/tickets/emails/${emailId}/`);
}
