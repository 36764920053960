<template>
  <div class="popover">
    <div class="popover__body">
      <div class="popover__userdata">
        <h4>Nombre: {{ name }}</h4>
        <div>Codigo de contacto: {{ code || "-" }}</div>
        <div>Teléfono: {{ identifier || data.phone_number }}</div>
        <a-divider />
      </div>

      <div class="popover__strategy">
        <h4>ID Ticket: {{ data.last_call_interaction?.ticket_id }}</h4>
        <span>Estrategia: {{ dialing }}</span>
        <!-- TODO: Cuando hayan llamadas entrantes, evaluar el tipo de interacción -->
        <div v-if="typeCall">
          Tipo de interacción:
          <span v-if="typeCall === InteractionType.Inbound"> Inbound</span>
          <span v-else-if="typeCall === InteractionType.Outbound"> Outbound</span>
        </div>
        <div>Canal: Voz</div>
        <a-tag v-if="state == EventManagement.Talking" color="green">Hablando</a-tag>
        <a-tag v-else-if="state == EventManagement.Ringing" color="blue">Timbrando</a-tag>
        <a-tag v-else-if="state == EventManagement.Acw" color="orange">ACW</a-tag>
        <a-tag v-else-if="state == EventManagement.Waiting" color="cyan">Esperando</a-tag>
        <a-divider />
      </div>

      <div class="popover__footer">
        <a-button
          type="link"
          :disabled="state != EventManagement.Talking"
          @click="whisperAndHear(identifier, 'whisper')"
        >
          Susurrar</a-button
        >
        <a-button
          type="link"
          :disabled="state != EventManagement.Talking"
          @click="whisperAndHear(identifier, 'hear')"
        >
          Escuchar</a-button
        >
        <a-tooltip>
          <template #title v-if="!hasWorkspace">
            Para capturar, tu perfil debe tener habilitado el módulo de Workspace
          </template>
          <a-button
            type="link"
            :disabled="state != EventManagement.Talking || !hasWorkspace"
            @click="captureCall(identifier)"
          >
            Capturar
          </a-button>
        </a-tooltip>
        <!-- <a-button
          v-else
          :disabled="state != EventManagement.Talking"
          type="link"
          @click="captureCall(identifier)"
        >
          Capturar</a-button
        > -->
      </div>
    </div>
  </div>
</template>

<script>
import { EventManagement } from "@/app/shared/utils/enums";
import { defineComponent } from "vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { InteractionType } from "@/app/shared/utils/enums";

export default defineComponent({
  components: {},
  props: {
    dialing: { required: true, type: String },
    identifier: { required: false, type: String },
    data: { required: true, type: Object },
    state: { required: false, type: Number, default: null },
  },
  data() {
    return {
      EventManagement,
      InteractionType,
    };
  },
  computed: {
    userData() {
      return this.$store.getters["getDataUser"];
    },
    hasWorkspace() {
      return !!this.userData?.options_menu.find((item) => item.scope === "menu_workspace");
    },
    name() {
      if (this.data?.client) return this.data.client?.fullname;
      return "Sin identificar";
    },
    code() {
      if (this.data?.client) return this.data.client?.code;
      return "Sin identificar";
    },
    photo() {
      return this.data.client?.profile_picture;
    },
    typeCall() {
      return this.data?.last_call_interaction?.type_interaction;
    },
  },
  watch: {
    state(value) {
      if (value) {
        EventBus.$emit(WorkspaceEvent.STATE_SUPERVISION, this.state);
      }
    },
  },
  methods: {
    whisperAndHear(annex, type) {
      this.$emit("showModal", { annex, type });
      EventBus.$emit(WorkspaceEvent.START_IDENTIFIER_TIME, this.identifier);
    },
    captureCall(annex) {
      this.$emit("captureCall", { annex });
    },
  },
});
</script>

<style lang="sass" scoped>
.popover
  font-size: 12px
  color: $gray-9
  padding: 3px
  width: 280px
  &__body
    min-width: 120px
    display: grid
    grid-template-rows: 80px 100px 20px
    gap: 5px

  &__content
    margin-bottom: 12px

    & p
      margin-bottom: 2px

  &__footer
    display: flex
    justify-content: space-between
    font-size: 13px

.ant-divider-horizontal
  margin: 12px 0
:deep(.ant-btn)
  font-size: 12px
</style>
