<template>
  <a-modal
    :visible="props.visible"
    :footer="null"
    @cancel="handleClose"
    width="34em"
    :bodyStyle="bodyStyle"
    centered
  >
    <info-circle-outlined class="modal__icon" />
    <a-typography-title class="modal__title" :level="5">{{ title }}</a-typography-title>
    <p class="regular-14 modal__description">
      Esta varibale ya no podrá ser utilizada en los nuevos flujos de tus chatbots, pero seguirán
      activos en donde ya han sido empleados. Esta variable se encuentra activa en los siguientes
      chatbots:
    </p>
    <a-list
      size="small"
      class="modal__list"
      :data-source="props.varChatbots"
      :pagination="pagination"
      :locale="{ emptyText: 'No hay chatbots que usen esta variable' }"
    >
      <template #renderItem="{ item }">
        <a-list-item>
          {{ item.name }}
        </a-list-item>
      </template>
      <template #header>
        <div>
          <a-typography-text class="mrg-bottom-0" strong
            >Chatbots vinculados a esta variable</a-typography-text
          >
        </div>
      </template>
    </a-list>
    <a-button
      class="modal__button"
      type="primary"
      @click="handleDeleteVar"
      :disabled="buttonOk.disabled"
      :loading="buttonOk.loading"
      >Eliminar</a-button
    >
  </a-modal>
</template>

<script setup lang="ts">
import { reactive, computed, inject } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { deleteVar } from "@/app/manageVars/services";
import { PropsModalDeleteVar, FunctionsManageVar } from "@/@types/manageVars";
import { message } from "ant-design-vue";

const props = defineProps<PropsModalDeleteVar>();

const emits = defineEmits(["onClose"]);
const functionsManageVar = inject("functionManageVar") as FunctionsManageVar;

const bodyStyle = {
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  padding: "32px 36px",
};
const buttonOk = reactive({
  loading: false,
  disabled: false,
});

/**
 * @return {String}
 */
const title = computed(() => {
  // return `¿Estás  ${this.transformWordGender(
  //   'seguro',
  //   this.profile.gender
  // )} de eliminar la variable "${this.varName}"?`
  return "¿Estás seguro de eliminar la variable?";
});
/**
 * @returns {Object} - paginación para la tabla
 */
const pagination = computed(() => {
  return {
    hideOnSinglePage: true,
    defaultPageSize: 4,
    // total: listDataSource.pagination.total || 0,
    // current: listDataSource.pagination.page,
    // onChange: (page) => handleChangeList(page),
  };
});

/**
 * Resetea el modal
 */
const handleReset = () => {
  buttonOk.loading = false;
  buttonOk.disabled = false;
};

const handleDeleteVar = async () => {
  if (!props.varId) return;
  console.log("delete", props.varId);
  await deleteVar(props.varId);
  functionsManageVar.onRemove(props.varId);
  message.success("Variable eliminada");
  handleClose();
};

const handleClose = () => {
  emits("onClose");
  handleReset();
};
</script>

<style lang="sass" scoped>
.modal
  &__icon
    font-size: 64px
    color: $orange-6
  &__title
    margin: 16px auto
    text-align: center
  &__description
    text-align: left
    width: 100%
    margin: 0px 0px 8px
  &__button
    width: 100%
  &__list
    width: 100%
    min-height: 200px
</style>
