<template>
  <div class="wrapper">
    <div class="controls">
      <a-input v-model:value="search" placeholder="Buscar..." class="search" @input="handleSearch">
        <template #prefix>
          <SearchOutlined />
        </template>
      </a-input>

      <entity-picker
        v-if="user?.customer?.type_business == 1"
        :value="entity"
        @update:value="entity = $event"
      />

      <campaign-picker
        :showAll="true"
        :value="campaign"
        @update:value="campaign = $event"
        :entity="entity"
      />

      <a-select
        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
        v-model:value="selected"
        class="form__select"
        placeholder="Seleccionar estado"
      >
        <a-select-option :key="''"> Todos los estados </a-select-option>
        <a-select-option :key="0"> Todos los estados activos </a-select-option>
        <a-select-option v-for="state in status" :key="state.id">{{ state.name }}</a-select-option>
        <a-select-option :key="-1"> Inactivo </a-select-option>
      </a-select>

      <ProfilePicker v-model:value="profile" show-all-picker />
    </div>

    <div class="info">
      <p>
        Total de usuarios: <span class="users_number"> {{ usersCount }} </span>
      </p>

      <a-divider type="vertical" class="divider" />

      <div class="switch">
        Vista compacta
        <a-switch v-model:checked="detailedView" />
      </div>
    </div>
  </div>
</template>

<script>
import { SearchOutlined } from "@ant-design/icons-vue";
import { defineComponent, ref, computed, watch, inject } from "vue";
import { useStore } from "vuex";

import EntityPicker from "@/app/shared/components/pickers/EntityPicker.vue";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import ProfilePicker from "@/app/shared/components/pickers/ProfilePicker.vue";

export default defineComponent({
  components: {
    EntityPicker,
    CampaignPicker,
    SearchOutlined,
    ProfilePicker,
  },
  setup() {
    const store = useStore();

    const user = computed(() => store.getters["getDataUser"]);
    const entity = ref(0);

    const detailedView = inject("detailedView");

    const usersCount = computed(() => store.getters["getMonitorUserList"]?.length ?? 0);

    if (user.value) {
      if (user.value.customer.type_business == 2) {
        entity.value = 1;
      }
    }

    watch(user, (newValue) => {
      if (newValue.customer.type_business == 2) {
        entity.value = 1;
      }
    });

    return {
      entity,
      user,
      detailedView,
      usersCount,
    };
  },
  data() {
    return {
      search: "",
      campaign: 0,
      interval: null,
      status: [],
      selected: 0,
      profile: "",
    };
  },
  sockets: {
    refreshTickets() {
      this.handleSearch();
    },
  },
  watch: {
    entity() {
      this.handleSearch();
    },
    campaign() {
      this.handleSearch();
    },
    selected() {
      this.handleSearch();
    },
    profile() {
      this.handleSearch();
    },
  },
  mounted() {
    this.getStatusMonitoring();
    this.interval = setInterval(() => this.handleSearch(), 1000);
  },
  unmounted() {
    clearInterval(this.interval);
  },
  methods: {
    handleSearch() {
      const filters = {
        search: this.search,
        entity: this.entity,
        portfolio: this.campaign,
        state: this.selected,
        profile: this.profile,
      };

      this.$store.dispatch("fetchMonitorUsers", filters);
    },
    async getStatusMonitoring() {
      try {
        const response = await this.$store.dispatch("fetchStatusMonitoring");
        this.status = response?.filter((status) => status.id !== 6);
        // Status id 6 is Disconnected
        console.log(this.status);
      } catch (error) {
        console.log(error);
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.wrapper
  display: flex
  margin-bottom: 12px
  justify-content: space-between
  align-items: center
  gap: 8px

.controls
  // display: grid
  // grid-template-columns: 2fr 1fr 1fr 1fr 1fr 1fr
  display: flex
  gap: 8px

.info
  display: flex
  gap: 12px
  font-size: 16px
  color: $gray-8

.divider
  background-color: $gray-5
  height: auto

p
  margin-bottom: 0

.switch
  display: flex
  align-items: center
  gap: 8px

.users_number
  font-size: 20px
  font-weight: 600
  color: $gray-9

.search
  width: 100%
  height: 32px

.form__select
  height: 32px

// .form__select
//   width: 100%
//   & > :deep(.ant-select-arrow)
//     top: 30%

.ant-form-item
  margin-bottom: 0
</style>
