import httpManager from "@/app/shared/utils/httpManager";

class FileDownloadService {
  async downloadFile(urlDownload: string) {
    const url = `/customers/file/download/?url=${urlDownload}`;
    const response = await httpManager.getBlob(url);
    return response;
  }
}

export const fileDownloadService = new FileDownloadService();
