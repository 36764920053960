import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un contacto.
 *
 * @param {number} contactId Identificador del contacto.
 */
export async function changeStatusContact(contactId, status) {
  return await httpManager.put(`/contacts/clients/${contactId}/`, { status: status});
  // await httpManager.delete(`/contacts/clients/${contactId}/`);
}
