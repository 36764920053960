<template>
  <p>
    Utiliza el callback para recibir actualizaciones en tiempo real.
    <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para conocer más.
  </p>

  <div class="key__wrapper">
    <div class="input__wrapper">
      <div class="input_container">
        <label>Callback</label>
        <a-input
          v-model:value="callback"
          class="input"
          :disabled="!isEditing"
          placeholder="Ingresa tu callback aquí..."
        />
      </div>

      <div class="input_container">
        <label>
          Token
          <span class="info">
            <a-tooltip
              title="Ingresa el token proporcionado por tu empresa para validar el callback, solo si es necesario según las políticas de tu empresa."
            >
              <InfoCircleOutlined />
            </a-tooltip>
            (Opcional)
          </span>
        </label>
        <a-input-password
          v-model:value="token"
          class="input"
          :disabled="!isEditing"
          placeholder="Escribe aquí..."
        />
      </div>

      <div class="action">
        <a-button v-if="isEditing" class="button" :loading="isSaving" @click="handleSave">
          Guardar cambios
        </a-button>

        <template v-else>
          <a-button class="button" :disabled="disabled" @click="handleEdit"> Editar </a-button>
          <a-button class="button" :disabled="disabled" @click="handleClear"> Limpiar </a-button>
        </template>
      </div>
    </div>
  </div>

  <div class="events">
    <header>
      <h2>
        Configuración de eventos

        <a-tooltip
          title="Activa los eventos que desees actualizar la información en tiempo real en Score."
        >
          <InfoCircleOutlined style="margin-left: 8px" />
        </a-tooltip>
      </h2>
    </header>

    <div class="list">
      <div
        class="event"
        :class="{ odd: index % 2 !== 0 }"
        v-for="(event, index) in events"
        :key="event.id"
      >
        <div class="control">
          <a-switch
            size="small"
            :checked="event.status === CallbackEventStatus.Active"
            :disabled="disabled"
            @update:checked="handleUpdateEvent($event, event.id)"
          />
        </div>

        <span class="name">{{ event.name }}</span>

        <span class="description">{{ event.description }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";
import { message } from "ant-design-vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

import { apiService } from "@/app/platformSettings/services/ApiService";
import { ResponseError } from "@/app/shared/utils/httpManager";
import { CallbackEvent, CallbackEventStatus } from "@/@types/platformSettings/callbacks";

const props = defineProps<{
  callbackUrl: string;
  token: string;
  disabled: boolean;
}>();

const DOCS_URL = `${
  import.meta.env.VITE_WEB_DOC
}/docs/score/ajustes-en-la-plataforma/api-key-callback/`;

const callback = ref(props.callbackUrl);
const token = ref(props.token);
const isEditing = ref(false);

const events = ref<CallbackEvent[]>([]);

const fetchEvents = async () => {
  events.value = await apiService.getCallbackEvents();
};

onMounted(async () => {
  isEditing.value = !callback.value;

  fetchEvents();
});

const handleUpdateEvent = async (newActive: boolean, eventId: number) => {
  const newStatus = newActive ? CallbackEventStatus.Active : CallbackEventStatus.Inactive;

  await apiService.updateCallbackEvent(eventId, newStatus);
  fetchEvents();
};

const isSaving = ref(false);

const handleSave = async () => {
  isSaving.value = true;

  try {
    await apiService.updateUrlCallback(callback.value, token.value);
    isEditing.value = false;
  } catch (error) {
    const errorMessage =
      error instanceof ResponseError ? error.getErrorMessage() : "Ha ocurrido un error";

    message.error(errorMessage);
  }

  isSaving.value = false;
};

const handleEdit = () => {
  isEditing.value = true;
};

const handleClear = () => {
  callback.value = "";
  isEditing.value = true;
};
</script>

<style scoped lang="sass">
p
  color: $gray-8
  margin-bottom: 16px

.input
  display: flex !important

  &__wrapper
    display: flex
    align-items: flex-end
    gap: 12px

  &_container
    max-width: min(450px, 100%)
    flex: 1 1 auto

.action
  width: 170px
  display: flex
  gap: 8px

  .button
    width: 100%

.events
  margin-top: 16px

  h2
    font-weight: 600
    font-size: 14px
    margin-bottom: 8px

  .event
    display: grid
    gap: 16px
    grid-template-columns: auto 200px 1fr
    padding: 12px 0
    border-top: 1px solid #00000026
    color: $text-primary

    .control
      min-width: 0

    .name
      text-wrap: pretty

    .description
      text-wrap: pretty

    &.odd
      background-color: #F9F9FC


.info
  color: $gray-7
</style>
