import { fetchAllCountries } from "@/app/shared/services";
import { SET_COUNTRIES } from "./mutation-types";

const actions = {
  async fetchAllCountries({ commit }) {
    const countries = await fetchAllCountries();
    commit(SET_COUNTRIES, countries);
  },
};

export default actions;
