import { SmsTemplate, SmsTemplateStatus } from "@/@types/channels/sms";
import { CreateTemplateBodyDto, CreateTemplateDto, FiltersDto } from "@/@types/channels/sms/dto";
import { getPaginationQuery } from "@/app/shared/utils/helpers";
import httpManager from "@/app/shared/utils/httpManager";
import { UpdateTemplateDto } from "../../../@types/channels/sms/dto";
import { SendSmsTemplateDTO } from "@/@types/workspace/smsModal";

class SmsTemplatesService {
  private url = "/channels/sms-templates";

  async getList(params: { filters: FiltersDto; page: number }) {
    const paginationQuery = getPaginationQuery(params.page);
    const { search, status, portfolio } = params.filters;
    const url = `${this.url}/?${paginationQuery}&search=${search}&status=${status}&portfolio=${portfolio}`;

    const response = await httpManager.get(url);

    return response.data;
  }

  async getByQueue(queueId: number, withParameters: boolean = true) {
    let url = `${this.url}/queues/${queueId}`;
    url += !withParameters ? `?with_parameters=False` : '';

    const response = await httpManager.get(url);

    return response.data;
  }

  async create(createDto: CreateTemplateDto, channelCustomerId: number) {
    const { name, queues, content, parameters } = createDto;

    const createBody: CreateTemplateBodyDto = {
      name,
      queues,
      template: content,
      parameters: undefined,
      channel_customer: channelCustomerId,
    };

    if (parameters) {
      const formattedParams = Object.values(parameters).map((name) => ({ name }));

      createBody.parameters = formattedParams;
    }

    await httpManager.post(`${this.url}/`, createBody);
  }

  async update(templateId: number, createDto: Partial<CreateTemplateDto>) {
    const { name, queues, content, parameters } = createDto;

    const updateBody: Partial<UpdateTemplateDto> = {
      name,
      queues,
      template: content,
      parameters: undefined,
    };

    if (parameters) {
      const formattedParams = Object.values(parameters).map((name) => ({ name }));

      updateBody.parameters = formattedParams;
    }

    await httpManager.put(`${this.url}/${templateId}/`, updateBody);
  }

  async toggleStatus(smsTemplate: SmsTemplate) {
    const status = {
      status:
        smsTemplate.status === SmsTemplateStatus.Active
          ? SmsTemplateStatus.Inactive
          : SmsTemplateStatus.Active,
    };

    await httpManager.put(`${this.url}/${smsTemplate.id}/`, status);
  }

  async delete(templateId: number) {
    await httpManager.delete(`${this.url}/${templateId}/`);
  }

  async downloadExcelTemplate(body: { template: number }) {
    const url = `/strategies/sms/download-template/`;

    const response = await httpManager.post(url, body, { blob: true });

    return response;
  }

  async sendTemplate(sendSmsTemplateDTO: SendSmsTemplateDTO) {
    const url = `/workspace/send-sms-template/`;

    await httpManager.post(url, sendSmsTemplateDTO);
  }
}

export const smsTemplatesService = new SmsTemplatesService();
