export const columns = (detailedView) => [
  {
    title: "Anexo",
    dataIndex: "annex",
    slots: { customRender: "annex" },
    width: 70,
    fixed: "left",
  },
  {
    dataIndex: "user.fullname",
    width: 240,
    sorter: (a, b) => {
      if (a.user.fullname.toLowerCase() < b.user.fullname.toLowerCase()) return -1;
      if (a.user.fullname.toLowerCase() > b.user.fullname.toLowerCase()) return 1;
      return 0;
    },
    slots: { customRender: "fullname", title: "nameTitle" },
    fixed: "left",
  },
  {
    title: "Estado",
    dataIndex: "state",
    slots: { customRender: "state" },
    width: detailedView ? 180 : 160,
  },
  {
    title: "Eventos de llamadas",
    dataIndex: "event_management",
    slots: { customRender: "event_management" },
    width: detailedView ? 180 : 160,
  },
  {
    title: "Eventos de mensajería",
    dataIndex: "event_management_messaging",
    slots: { customRender: "event_management_messaging" },
    width: detailedView ? 180 : 160,
  },
  {
    dataIndex: "webphone_status",
    slots: { title: "webphoneTitle", customRender: "webphoneBody" },
    width: detailedView ? 120 : 60,
    align: detailedView ? "left" : "center",
    className: detailedView ? "" : "webphone_status",
  },
  {
    title: "Módulo",
    dataIndex: "menu",
    width: 150,
    slots: { customRender: "menu" },
    ellipsis: true,
  },
  {
    title: "Tickets activos",
    dataIndex: "tickets",
    width: 130,
    slots: { customRender: "tickets" },
  },
  {
    title: "Estrategias activas",
    slots: { customRender: "strategy" },
    width: 150,
  },
  {
    title: "Campañas",
    dataIndex: "portfolios",
    slots: { customRender: "portfolio" },
    width: 150,
  },
  {
    title: "Llamadas activas",
    dataIndex: "active_call",
    width: detailedView ? 180 : 250,
    slots: { customRender: "active_call" },
    fixed: "right",
  },
  {
    title: "Chats abiertos",
    dataIndex: "chat",
    slots: { customRender: "chat" },
    width: detailedView ? 180 : 250,
    fixed: "right",
  },
];
