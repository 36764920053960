import { SET_TAGS, ADD_TAG, UPDATE_TAG, DELETE_TAG } from "./mutation-types";

const mutations = {
  [SET_TAGS](state, tags) {
    state.tags = tags;
  },
  [ADD_TAG](state, tag) {
    state.tags = [tag, ...state.tags];
  },
  [UPDATE_TAG](state, newTag) {
    const index = state.tags.findIndex((item) => newTag.id == item.id);
    state.tags.splice(index, 1, newTag);
  },
  [DELETE_TAG](state, tagId) {
    const index = state.tags.findIndex((item) => tagId == item.id);
    state.tags.splice(index, 1);
  },
};

export default mutations;
