<template>
  <section class="unsubscriber-container">
    <p>Administra todos los correos desuscritos de tus estrategias masivas.</p>

    <div class="controls">
      <a-form class="form" layout="vertical" :model="formState">
        <a-form-item>
          <a-input
            v-model:value="formState.email"
            placeholder="Buscar por correo"
            style="width: 220px"
          >
            <template #suffix>
              <SearchOutlined />
            </template>
          </a-input>
        </a-form-item>
        <!-- <a-form-item>
          <a-select
:getPopupContainer="triggerNode => triggerNode.parentNode"            v-model:value="formState.identifier"
            class="input"
            :options="[...identifierOptions]"
            placeholder="Todos los identificadores"
          />
        </a-form-item> -->
      </a-form>
      <div class="btn">
        <a-button type="primary" @click="openUnSuscriberModal = true">
          Desuscribir nuevo correo
          <PlusOutlined />
        </a-button>
        <a-button @click="handleDownload" :loading="downloading" :disabled="disabledDownload">
          Descargar
          <DownloadOutlined />
        </a-button>
      </div>
    </div>
    <div>
      <UnsuscriberTable :controls="formState" />
    </div>
  </section>
  <UnSubscriberModal v-model:open="openUnSuscriberModal" />
</template>

<script setup lang="ts">
import { reactive, ref } from "vue";
import { SearchOutlined, PlusOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import UnsuscriberTable from "@/app/platformSettings/components/table/UnsuscriberTable.vue";
import UnSubscriberModal from "@/app/platformSettings/components/modal/UnSubscriberModal.vue";
import { emailService } from "@/app/emails/services/Email.service";
import { downloadFile } from "@/app/shared/utils/file";

const formState = reactive({
  email: "",
  identifier: "",
});

const downloading = ref(false);
const disabledDownload = ref(false);
const openUnSuscriberModal = ref(false);

// const identifierOptions = [
//   {
//     value: "",
//     label: "Todos los identificadores",
//   },
//   {
//     value: 1,
//     label: "Activo",
//   },
//   {
//     value: 2,
//     label: "Inactivo",
//   },
//   {
//     value: 4,
//     label: "Pendiente",
//   },
// ];

const handleDownload = async () => {
  downloading.value = true;

  const response = await emailService.downloadUnsubscribeReport({ email: formState.email });

  downloadFile(response, "unsubscribe-emails.xlsx", { useResponseFileName: true });

  downloading.value = false;
};
</script>

<style scoped lang="sass">
.form
  display: flex
  gap: 12px
  margin-bottom: 12px
  .input
    min-width: 160px
.controls
  display: flex
  justify-content: space-between
  .btn
    display: flex
    gap: 10px
</style>
