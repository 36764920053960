import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener listado de agentes de la campaña.
 *
 * @param {number} campaignId Identificador de campaña.
 *
 * @return {Promise<Array>} Listado de agentes.
 */
export async function fetchAgentsByCampaign(id) {
  const response = await httpManager.get(`/strategies/${id}/agents/active/`);
  return response.data;
}
