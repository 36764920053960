<template>
  <img :src="src || getChannelImage()" alt="Icono" :width="width" />
</template>

<script>
import { ChannelType } from "@/app/shared/utils/enums";

const channelIcons = {
  [ChannelType.Whatsapp]: "whatsapp.svg",
  [ChannelType.Messenger]: "messenger.svg",
  [ChannelType.Inbound]: "inbound.svg",
  [ChannelType.Voice]: "calls.svg",
  [ChannelType.Sms]: "sms.png",
  [ChannelType.VoiceInbound]: "calls.svg",
  [ChannelType.Instagram]: "instagram.svg",
};

export default {
  props: {
    type: { required: true, type: Number },
    width: { required: false, type: Number },
    src: String,
  },
  computed: {
    icon() {
      return channelIcons[this.type] || "calls.svg";
    },
  },
  methods: {
    getChannelImage() {
      return new URL(`../../assets/channels/${this.icon}`, import.meta.url).href;
    },
  },
};
</script>
