const initialState = () => ({
  usersInfo: {
    pagination: {},
    items: [],
  },
  user: {},
});
const state = initialState();

export default state;
