import axios from "@/plugins/axios";
/**
 * Obtener un usuario mediante su id.
 *
 * @param {Number} id Datos del usuario a crear.
 *
 * @return {Promise<Object>} Usuario por id
 */

export default async function (id, body) {
  const response = axios.delete(`/profiles/${id}/`, { data: body });
  return response;
}
