<template>
  <EmailCounter
    :counter="selectedThreadsInbox.countSelectedRow"
    :isAllSelected="selectedThreadsInbox.countSelectedRow == data.length"
  />
  <!-- {{ props.channelCustomerId }} -->
  <ActionsCard
    v-if="selectedThreadsInbox.countSelectedRow > 0"
    :role="role"
    :isAssigned="isAssigned"
    :showFullActions="true"
    :selectedRows="
      data.filter((d) => selectedThreadsInbox?.selectedRowKeys?.includes(Number(d.key)))
    "
    @emitAssignEvent="handleAssignEmails"
    @massiveTag="handleMassiveTag"
    @update="updateTable"
    @refreshTable="refreshTable"
    @resetSelected="resetSelectedThreads"
    @resolved="toResolved"
  />
  <div class="blank" v-if="!(data.length >= 1) && !fetchingList">
    <img src="@/app/emails/assets/email.png" alt="Logo de Email" />
    <p>2. No tienes correos en tu bandeja por el momento</p>
  </div>
  <!-- <div class="select" v-if="isAssigned">
    <label>
      <input type="checkbox" v-model="selectAll" @change="handleSelectAll" />
      Seleccionar todo
    </label>
  </div> -->
  <!-- {{ selectedThreadsInbox }}
  {{ rowSelection }} -->
  <a-table
    :key="tableKey"
    style="border-collapse: collapse"
    v-if="data.length >= 1 || fetchingList"
    :row-selection="rowSelection"
    :columns="inboxTableColumnsFilter"
    :data-source="data"
    :loading="fetchingList"
    :row-key="(record: any) => record.key"
    :scroll="scrollStyle"
    :pagination="pagination"
    :customRow="
      (record: any, index: number) => {
        return {
          onClick: () => handleRowClick(record),
          onMouseenter: () => handleMouseenter(index),
          onMouseleave: () => handleMouseleave(),
        };
      }
    "
    @change="handleTableChange"
    :rowClassName="
      (record: any, index: number) => {
        if (!record.read) {
          return 'row-unread' + (index == handleHoverTable ? ' row-hover' : '');
        } else {
          return 'row-read' + (index == handleHoverTable ? ' row-hover' : '');
        }
      }
    "
    class="inbox__table"
  >
    <template #headerCell="{ column }">
      <template v-if="column.key === 'subject'">
        <span>Asunto - </span><span class="text-gray-7">vista previa del correo</span>
      </template>
    </template>
    <!-- index -->
    <template #bodyCell="{ column, record, index }">
      <template v-if="column.dataIndex === 'number'">
        <span>#{{ record.number }}</span>
      </template>
      <template v-if="column.dataIndex === 'statusInbox'">
        <email-state-inbox-tag :state="record.statusInbox" />
      </template>
      <template v-if="column.dataIndex === 'email'">
        <span>{{ record.email }}</span>
      </template>
      <template v-else-if="column.dataIndex === 'time'">
        <div style="text-align: right">
          <span>{{ record.time }}</span>
        </div>
      </template>
      <template v-else-if="column.key === 'subject'">
        <div style="display: flex; justify-content: space-between">
          <div>
            <span>{{
              record.subject
                ? record.subject == "RE:" || record.subject == "Re:"
                  ? "RE: (Sin Asunto)"
                  : record.subject
                : "(Sin Asunto)"
            }}</span>
            <span>{{ record.message_preview || "" }}</span>
            <TicketTagsList :tags="record?.tags" />
          </div>
          <!-- solo existe el componente en el dom cuando es la fila hover o la ultima fila hover (evita problemas de rendimiento)-->
          <!-- <div v-if="index == handleHoverTable || index == lastHandleHoverTable"> -->
          <div>
            <div v-show="index == handleHoverTable">
              <ActionsCard
                :role="role"
                :isAssigned="isAssigned"
                :showOnlyIconsTable="true"
                :selectedRows="[record]"
                :tags="tags"
                @emitAssignEvent="handleAssignEmails"
                @massiveTag="handleMassiveTag"
                @update="updateTable"
                @refreshTable="refreshTable"
                @resetSelected="resetSelectedThreads"
                @resolved="toResolved"
              />
            </div>
          </div>
        </div>
      </template>
    </template>
  </a-table>
</template>
<script lang="ts" setup>
import { ref, watch, computed, onMounted, unref } from "vue";
import type { TableProps } from "ant-design-vue";
import { useStore } from "vuex";
// import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";
import { inboxTableColumns } from "@/app/emails/utils/columns";
import ActionsCard from "../cards/ActionsCard.vue";
import EmailCounter from "../cards/EmailCounter.vue";
import { DataType } from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";
import { Tag, UserData } from "@/@types/global";
import TicketTagsList from "@/app/shared/components/lists/TicketTagsList.vue";
import { useScrollResize } from "@/app/shared/composables/useScrollResize";
import { Key } from "ant-design-vue/es/_util/type";
import { fetchTags } from "@/app/platformSettings/services";
import EmailStateInboxTag from "@/app/emails/components/tags/EmailStateInboxTag.vue";

// const selectedEmails = ref<number>(0);
const selectAll = ref<boolean>(false);

// const fetchingList = inject("fetchingList") as Ref<boolean>;
const fetchingList = computed(() => store.getters["getFetchingThreadsInbox"]);

// const menuItems = ref<IMenutem[]>([]);

const props = defineProps<{
  isVisibleEmail: boolean;
  role: boolean;
  isAssigned: boolean;
  data: DataType[];
  // channelCustomerId: number;
}>();

// const selectedRowKeys = ref<DataType["key"][]>([]);

const store = useStore();

const user = computed<UserData>(() => store.getters["getDataUser"]);
const page = computed(() => store.getters["getTablePage"]);

const tags = ref<Tag[]>([]);

// const campaignActual = ref(null);

watch(
  () => store.getters.getSelectedThreadsInbox,
  (newList) => {
    console.log("watch store.getters.getSelectedThreadsInbox");
    if (newList?.length) setNewMargin(300);
    else setNewMargin(240);
  }
);

const pagination = computed(() => ({
  total: store.getters["getTotalEmails"],
  current: page.value,
  pageSize: 50,
  showSizeChanger: false,
}));

const { scrollStyle, setNewMargin } = useScrollResize({ headerMargin: 240, width: 900 });

const handleHoverTable = ref<number | null>(null);
const lastHandleHoverTable = ref<number | null>(null);

const handleMouseenter = (index: number) => {
  handleHoverTable.value = index;
  lastHandleHoverTable.value = index;
};

const handleMouseleave = () => {
  handleHoverTable.value = null;
};

const inboxTableColumnsFilter = computed(() => {
  const inboxTableColumnsAux = inboxTableColumns;
  inboxTableColumnsAux[inboxTableColumnsAux.length - 2] = {
    ...inboxTableColumnsAux[inboxTableColumnsAux.length - 2],
    customCell: (_, index) => ({
      colSpan: index == handleHoverTable.value ? 2 : 1,
    }),
  };
  inboxTableColumnsAux[inboxTableColumnsAux.length - 1] = {
    ...inboxTableColumnsAux[inboxTableColumnsAux.length - 1],
    customCell: (_, index) => ({
      colSpan: index == handleHoverTable.value ? 0 : 1,
    }),
  };
  return inboxTableColumnsAux;
});
/*
  #TODO: Cambiar estructura de filtros de inbox para que use estados. 
  Usar vuex para estos valores ya que el listado del inbox depende de estos
*/
// const read = inject("filterRead") as Ref<boolean | "">;
// const selectedTags = inject("filterSelectedTags") as Ref<Array<number | "">>;
// const search = inject("filterSearch") as Ref<string | "">;

// const getUrlParams = (): {
//   channelCustomerId: number;
//   assigned: boolean;
//   resolved?: boolean;
// } => {
//   const { channelCustomer, assigned, resolved } = route.query;

//   return {
//     channelCustomerId: Number(channelCustomer),
//     assigned: assigned === "true",
//     resolved: resolved === " true",
//   };
// };

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
// const fetchList = inject("fetchList");

// const refreshInbox = () => {
// const params = getUrlParams();
// if (fetchList) {
//   fetchList({
//     ...params,
//     read: read.value,
//     tagList: selectedTags.value,
//     search: search.value,
//     page: page,
//     resetPagination: false,
//   });
// }
// };

const refreshTable = async () => {
  await store.dispatch("updateThreadsInbox", {
    assignedUser: store.getters["getDataUser"]?.id,
    resetPagination: false,
  });
};

const handleTableChange = async (page: { current: number }) => {
  store.dispatch("updateTablePage", page.current);
  await refreshTable();
  // refreshInbox(page.current);
};
// const fetchMenuList = async () => {
//   const userId = user.value?.id;

//   menuItems.value = await emailService.getEmailsMenuList(userId);
// };

const onSelectChange = async (changableRowKeys: Key[], selectedRows: DataType[]) => {
  console.log(changableRowKeys, "changableRowKeys");
  console.log(selectedRows, "selectedRows");
  await store.dispatch("updateSelectedThreadsInbox", {
    selectedRowKeys: changableRowKeys,
    countSelectedRow: selectedRows.length,
  });
  // selectedRowKeys.value = changableRowKeys;
  // selectedEmails.value = selectedRows.length;
};

const selectedThreadsInbox = computed(() => store.getters.getSelectedThreadsInbox);

const rowSelection: TableProps["rowSelection"] = {
  // selectedRowKeys: computed(() => selectedRowKeys.value),
  selectedRowKeys: computed(() => store.getters.getSelectedThreadsInbox.selectedRowKeys),
  onChange: onSelectChange,
  getCheckboxProps: (record: DataType) => ({
    name: record?.email,
  }),
  // onSelect: (nativeEvent: any, selected: any) => {
  //   console.log(`selectedRows lll: ${nativeEvent}`, "selected: ", selected);
  // },
};

const tableKey = ref(1);

const handleMassiveTag = async (tags: { tagsToAdd: number[]; tagsToRemove: number[] }) => {
  // await emailService.massiveTag(selectedThreadsInbox.value.selectedRowKeys, tags);
  // await resetSelectedThreads();
  // await store.dispatch("updateThreadsInbox", {
  //   assignedUser: user.value?.id,
  //   resetPagination: false,
  // });
  // updateTable();
};

const resetSelectedThreads = async () => {
  await store.dispatch("updateSelectedThreadsInbox", {
    selectedRowKeys: [],
    countSelectedRow: 0,
  });
};

const updateTable = async () => {
  await resetSelectedThreads();
  // selectedEmails.value = 0;
  // selectedRowKeys.value = [];
  tableKey.value += 1;
  emit("update");
};

// const resetSelected = async () => {
//  selectedEmails.value = 0;
//  selectedRowKeys.value = [];
// };

const handleAssignEmails = async () => {
  // await emailService.transferThreadUser(user.value.id, {
  //   email_threads: selectedRowKeys.value
  // });
  await emailService.assignEmailsToUser(selectedThreadsInbox.value.selectedRowKeys, user.value.id);
  await resetSelectedThreads();
  // selectedEmails.value = 0;
  // selectedRowKeys.value = [];

  emit("update");
};

const emit = defineEmits(["emitEmailEvent", "update", "toResolved"]);

const emitEmailEvent = (record: DataType) => {
  emit("emitEmailEvent", !props.isVisibleEmail, record);
};

const handleSelectAll = async () => {
  if (selectAll.value) {
    // selectedRowKeys.value = props.data.map((row) => {
    //   return Number(row?.key);
    // });
    // selectedEmails.value = selectedRowKeys.value.length;
    const selectedRowKeys = props.data.map((row) => {
      return Number(row?.key);
    });
    await store.dispatch("updateSelectedThreadsInbox", {
      selectedRowKeys: selectedRowKeys,
      countSelectedRow: selectedRowKeys.length,
    });
    return selectedRowKeys;
  }
  await resetSelectedThreads();
  return [];
  // selectedEmails.value = 0;
  // return (selectedRowKeys.value = []);
};

// const handleCellClick = (record: DataType, dataIndex: string) => {
//   console.log(`Clicked cell in column ${dataIndex} with value:`, record);
//   // console.log(`RECORD cell ${record.messages[0]}`);
//   emitEmailEvent(record);
// };

const handleRowClick = (record: DataType) => {
  if (!window?.getSelection()?.toString()) emitEmailEvent(record);
};

const toResolved = async () => {
  await resetSelectedThreads();
  // selectedEmails.value = 0;
  // selectedRowKeys.value = [];
  emit("toResolved");
  // emit("update");
};

watch(selectAll, () => {
  handleSelectAll();
});

// watch(
//   () => props.channelCustomerId,
//   (value) => {
//     if (menuItems.value) {
//       console.log(menuItems.value, "menu items");
//       menuItems.value?.forEach((campaign) => {
//         campaign.subItems?.forEach((i) => {
//           console.log(i.id, "-", value);
//           if (i.id == value) campaignActual.value = campaign.id;
//         });
//       });
//     }
//   },
//   { immediate: true }
// );

onMounted(async () => {
  // fetchMenuList();
  tags.value = await fetchTags();
});
</script>
<style lang="sass" scoped>
.blank
  display: flex
  flex-direction: column
  align-items: center
  justify-content: center
  min-height: 80vh
  color: $gray-8
.select
  padding: 15px 25px
  width: 200px
  label
    display: flex
    gap: 10px
    align-items: center
    input[type="checkbox"]
      width: 16px
      height: 16px
      border: $gray-6
      opacity: 0.3
      &:checked
        opacity: 1
.inbox
  &__table
    cursor: pointer
    padding-top: 0px
    padding-left: 3px

.text-gray-7
  color: $gray-7

:deep(.ant-table-thead > tr > th)
  background-color: #0000000F
  // box-shadow: inset 0 4px 2px -2px #C5C5C5 !important
  border-bottom: 2px solid $gray-7
  z-index: 3
:deep(.ant-table-thead > tr > th.ant-table-cell-scrollbar)
  background-color: white
:deep(td.ant-table-cell.ant-table-selection-column)
  border-right: 1px solid $gray-5
:deep(.ant-table-row.row-unread > td)
  font-weight: 600
  background-color: $gray-3
:deep(.ant-table-row.row-unread > td.ant-table-cell.ant-table-selection-column)
  border-left: 4px solid #314bc8
:deep(.ant-table-row.row-read > td)
  background-color: $white
:deep(.ant-table-wrapper.inbox__table table)
  border-collapse: collapse !important
:deep(.ant-table-row.row-unread.row-hover:hover > td)
  background-color: $gray-3
:deep(.ant-table-row.row-read.row-hover:hover > td)
  background-color: $white
:deep(.ant-table-row.row-hover)
  box-shadow: 0 10px 10px -7px #C5C5C5 !important
  position: relative
  z-index: 20 !important
</style>
