<template>
  <input
    class="icp__input"
    :style="styleObject"
    type="color"
    :value="value"
    @input="$_icp_handleInput"
    @change="$_icp_handleChange"
  />
</template>
<script>
export default {
  props: ["value"],
  data() {
    return {
      styleObject: {
        backgroundColor: this.value,
      },
    };
  },
  methods: {
    $_icp_handleInput(e) {
      const inputValue = e.target.value;

      this.$emit("input", inputValue);

      if (typeof inputValue === "string") {
        this.$emit("update:value", inputValue);
      }
    },
    $_icp_handleChange(e) {
      this.$emit("change", e.target.value);
    },
  },
};
</script>
<style lang="css" scoped>
.icp__input {
  height: 10px;
  width: 10px;
  opacity: 0;
  position: absolute;
  transform: translate(-70px, 12px);
  border-radius: 50%;
  border: none;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  padding: 0;
}
.icp__input::-webkit-color-swatch-wrapper {
  padding: 0;
}
.icp__input::-webkit-color-swatch {
  border: none;
  border-radius: 15px;
}
.icp__input::-moz-color-swatch {
  border: none;
  border-radius: 15px;
}
</style>
