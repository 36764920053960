import httpManager from "@/app/shared/utils/httpManager";
import { toFormData } from "axios";

export async function uploadAudioFileQueue(queueId, state, create) {
  const { waiting_file_audio } = state;

  const url = `/queues/${queueId}/`;

  const body = { waiting_file_audio };

  if (!create) body.waiting_file_audio_delete = !waiting_file_audio;

  const fileAudio = toFormData(body);
  const response = await httpManager.put(url, fileAudio);

  return response?.data;
}
