import httpManager from "@/app/shared/utils/httpManager";
import { TABLE_PAGE_SIZE_XL } from "../../../shared/utils/constants";
export default async function (params, pagination = {}) {
  let query = "";
  query += params.startValue ? `start_date=${params.startValue}&` : "";
  query += params.endValue ? `end_date=${params.endValue}&` : "";

  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE_XL;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE_XL;

  const url = `/reports/4/?offset=${offset}&limit=${pageSize}&${query}`;

  const response = await httpManager.get(url);
  return response?.data;
}
