import httpManager from "@/app/shared/utils/httpManager";

/**
 *
 * @param {Object} nodeId
 * @returns {Promise<Object>} Node details
 */

export async function fetchNodeDetails(nodeId) {
  const url = `/chatbots/nodes/${nodeId}/`;
  const response = await httpManager.get(url);
  return response.data;
}
