<template>
  <div
    class="image-container"
    @mousedown="startDragging"
    @mousemove="onDragging"
    @mouseup="stopDragging"
    @mouseleave="stopDragging"
    @wheel="handleZoom"
  >
    <img :src="props.media_url" alt="Imagen" :style="imageStyles" />
    <div class="buttons">
      <a-tooltip title="Descargar">
        <a-button type="text">
          <DownloadOutlined @click="downloadImage" class="icon" />
        </a-button>
      </a-tooltip>
      <a-tooltip>
        <template #title>
          <div>Acercar - {{ zoomPercent }} %</div>
        </template>
        <a-button type="text">
          <PlusOutlined @click="zoomIn" class="icon" />
        </a-button>
      </a-tooltip>
      <a-tooltip>
        <template #title>
          <div>Alejar - {{ zoomPercent }} %</div>
        </template>
        <a-button type="text">
          <MinusOutlined @click="zoomOut" class="icon" />
        </a-button>
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { ref, reactive, computed } from "vue";
import { fileDownloadService } from "@/app/shared/services/FileDownload.service";
import { downloadFile } from "@/app/shared/utils/file";
import { DownloadOutlined, PlusOutlined, MinusOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  media_url: {
    type: String,
    default: "",
  },
});

const position = reactive({ x: 0, y: 0 });
const isDragging = ref(false);
const startPos = reactive({ x: 0, y: 0 });

const zoomLevel = ref(1);
const zoomAmount = 0.2;
const zoomMax = 5;
const zoomMin = 0.2;

const zoomPercent = computed(() => {
  let percent = Math.round(zoomLevel.value * 100);
  if (zoomLevel.value == zoomMax) percent = 500;
  if (zoomLevel.value == zoomMin) percent = 0;
  return percent;
});

const zoomIn = () => {
  if (zoomLevel.value <= zoomMax) {
    zoomLevel.value += zoomAmount;
  }
};

const zoomOut = () => {
  if (zoomLevel.value > zoomAmount && zoomLevel.value > zoomMin) {
    zoomLevel.value -= zoomAmount;
  }
  resetPosition();
};

const downloadImage = async () => {
  const response = await fileDownloadService.downloadFile(props.media_url);
  downloadFile(response, props.media_url.split("/").pop(), { useResponseFileName: true });
};

const startDragging = (event) => {
  event.preventDefault();
  isDragging.value = true;
  startPos.x = event.clientX - position.x;
  startPos.y = event.clientY - position.y;
};

// Mientras se arrastra
const onDragging = (event) => {
  if (isDragging.value && zoomLevel.value > zoomMin) {
    event.preventDefault();
    position.x = event.clientX - startPos.x;
    position.y = event.clientY - startPos.y;
  }
};

// Detener el arrastre
const stopDragging = () => {
  isDragging.value = false;
};

const handleZoom = (event) => {
  if (event.deltaY < 0) {
    zoomLevel.value = Math.min(zoomLevel.value + zoomAmount, zoomMax); // acercar
  } else {
    zoomLevel.value = Math.max(zoomLevel.value - zoomAmount, zoomMin); // alejar
    resetPosition();
  }
};

const resetPosition = () => {
  position.x = 0;
  position.y = 0;
  startPos.x = 0;
  startPos.y = 0;
};

const imageStyles = computed(() => ({
  transform: `translate(${position.x}px, ${position.y}px) scale(${zoomLevel.value})`,
  cursor: isDragging.value ? "grabbing" : "grab",
  transition: isDragging.value ? "none" : "transform 0.3s ease",
}));
</script>

<style scoped lang="sass">
.image-container
  position: relative
  display: flex
  justify-content: center
  align-items: center
  max-height: 500px
  overflow: hidden
  background-color: #f0f0f0
  cursor: grab

img
  max-width: 100%
  max-height: 500px
  transition: transform 0.3s ease
  user-select: none

img:active
  cursor: grabbing

.buttons
  position: absolute
  bottom: 10px
  right: 10px
  padding: 0 8px
  background-color: rgba(21, 30, 40, 0.8)
  border-radius: 5px
  color: white
  display: flex
  align-items: center
  .ant-btn
    padding: 0
    color: white

.icon
  margin: 0 6px
</style>
