export default [
  {
    path: "/flows/ivr-automatic",
    component: () => import(/* webpackChunkName: "ivr-page" */ "@/app/ivr/pages/IvrBotPage.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "ivr-list" */ "@/app/ivr/bot/components/templates/ListTemplate.vue"
          ),
      },
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "ivr-bot-detail" */ "@/app/ivr/bot/components/templates/DetailTemplate.vue"
          ),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/flows/ivr-survey",
    component: () => import(/* webpackChunkName: "ivr-page" */ "@/app/ivr/pages/IvrBotPage.vue"),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "ivr-list" */ "@/app/ivr/bot/components/templates/ListTemplate.vue"
          ),
      },
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "ivr-bot-detail" */ "@/app/ivr/bot/components/templates/DetailTemplate.vue"
          ),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];
