<template>
  <article class="page">
    <section class="page__presentation">
      <section class="page__title">
        <main-logo dark="dark" large="large"></main-logo>
        <div class="text__join">
          <span>¡Únete a tu equipo de trabajo en segundos!</span>
        </div>
      </section>

      <img
        class="page__image page__image--top"
        src="@/app/auth/assets/welcome_group_1.png"
        alt="bienvenido"
      />

      <img
        class="page__image page__image--bottom"
        src="@/app/auth/assets/welcome_group_2.png"
        alt="bienvenido"
      />

      <div class="page__background"></div>
    </section>

    <section class="page__body">
      <div class="buttom__close">
        <CloseOutlined @click="handleHideModal" />
      </div>
      <a-form class="form" layout="inline">
        <div class="separator"></div>
        <div class="search__workspace">
          <div class="container">
            <a-form-item class="form__input">
              <a-auto-complete
                v-model:value="workspace"
                placeholder="Buscar equipo"
                :options="workspaces.map((w) => ({ value: w.workspace, label: w.workspaces }))"
                @search="handleSearchName"
                @select="handleSelect"
              />
            </a-form-item>
          </div>
        </div>
      </a-form>
      <p v-if="hasError" class="form__error">No se han encontrado coincidencias</p>
      <div class="register">
        <p>¿Aún no tienes un equipo de trabajo?</p>
        <a type="link" @click="routerRegister"> Registrate aquí </a>
      </div>
    </section>
  </article>
</template>

<script>
import MainLogo from "@/app/shared/components/molecules/MainLogo.vue";
import httpManager from "@/app/shared/utils/httpManager";
import { CloseOutlined } from "@ant-design/icons-vue";

const MIN_LETTERS_TO_SEARCH = 0;

export default {
  components: {
    MainLogo,
    CloseOutlined,
  },
  data() {
    return {
      dataSource: [],
      changeSearch: 0,
      workspace: "",
      hasError: false,
      loading: false,
    };
  },
  computed: {
    workspaces() {
      return this.$store.getters["getAllWorkspaces"];
    },
  },
  methods: {
    handleHideModal() {
      this.$emit("onHide");
      this.$router.push("/home");
    },
    routerRegister() {
      this.$router.push("/register");
    },
    async validateWorkspace(workspace) {
      try {
        this.loading = true;
        httpManager.updateBaseURL(`https://backend.${import.meta.env.VITE_HOSTNAME}`);
        await this.$store.dispatch("validateWorkspace", workspace);
        if (window.location.hostname == "localhost") {
          localStorage.setItem("workspace", workspace);
          this.$router.push(`/login`);
        } else {
          window.location.href = `https://${workspace}.${import.meta.env.VITE_HOSTNAME}/login`;
          localStorage.setItem("workspace", workspace);
        }
      } catch {
        this.hasError = true;
      } finally {
        this.loading = false;
      }
    },
    handleSearchName(value) {
      this.hasError = false;

      if (value.length > MIN_LETTERS_TO_SEARCH) {
        this.handleFetchWokspaces(value);
      } else {
        this.$store.dispatch("clearWorkspaces");
      }
    },
    async handleFetchWokspaces(value) {
      try {
        await this.$store.dispatch("fetchAllWorkspace", value.replace(/ /g, "-"));
      } catch (error) {
        this.hasError = true;
      }
    },
    handleSelect(workpace) {
      this.validateWorkspace(workpace);
    },
  },
};
</script>

<style lang="sass" scoped>
.page
  display: flex
  align-items: center
  min-height: 100vh
  position: absolute
  left: 0
  top: 0
  right: 0
  bottom: 0
  background: $gray-12

  &__presentation
    width: 400px
    min-width: 400px
    min-height: 100vh
    color: $white
    font-size: 20px
    text-align: left
    padding: 40px
    background: linear-gradient(to bottom, $background-left-welcome-afer, $gray-9)
    position: relative
    display: flex
    align-items: center
    justify-content: center

  &__background
    position: absolute
    min-width: 400px
    min-height: 100vh
    background-image: url('../../assets/welcome_arrows_background.png')
    background-position: center
    background-size: cover

  &__image
    display: block
    position: absolute
    width: auto

    &--top
      top: 36px
      left: 40px

    &--bottom
      bottom: 36px
      right: 40px

  &__body
    left: -364px
    top: 10px
    position: relative

.form

  &__input
    margin-top: 13px
    width: 335px

  &__label
    font-size: 20px
    font-weight: 600
    margin-right: 4px

  &__error
    position: absolute
    left: 60px
    font-size: 12px
    color: $white
    margin-top: 14px

.search__workspace
  border-radius: 10px
  align-items: center
  margin-top: 40px

.register
  text-align: center
  margin-top: 50px
  margin-left: -20px

  & p
    color: $white

.separator
  margin-bottom: 10px

.text__workpace
  font-size: 16px
  font-weight: 600
  padding-right: 60px
  color: $gray-1

.container
  display: flex
  align-items: center
  margin-top: -8px

.cancel
  margin-top: 38px

.page__title
  margin-top: -220px

.text__join
  margin-top: 10px

.buttom__close
  top: -120px
  position: absolute
  display: flex
  flex-direction: row-reverse
  right: 3px
  color: $white
  font-size: 18px
</style>
<style>
.ant-select-item.ant-select-item-option.auto__complete.ant-select-item-option-active {
  font-weight: 700;
  background-color: #feffe6 !important;
  color: #595959;
  border: 1px solid #f0f0f0;
}
.ant-select-item.ant-select-item-option.auto__complete {
  font-weight: 300;
}
</style>
