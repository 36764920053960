import { fetchAgentsPortfolio } from "@/app/entities/services";
import { SET_AGENTSPORTFOLIO } from "./mutation-types";

const actions = {
  // Agents
  async fetchAgentPortfolio({ commit }, idPortfolio) {
    const agentsPortfolio = await fetchAgentsPortfolio(idPortfolio);
    commit(SET_AGENTSPORTFOLIO, agentsPortfolio);
  },
};

export default actions;
