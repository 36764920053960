import usersStore from "@/app/users/store/users";
import profilesStore from "@/app/users/store/profiles";
import channelsStore from "@/app/users/store/channels";
import menusStore from "@/app/users/store/menus";

export default {
  modules: {
    usersStore,
    channelsStore,
    profilesStore,
    menusStore,
  },
};
