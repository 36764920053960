import httpManager from "@/app/shared/utils/httpManager";
/**
 * Resolver un solo ticket.
 *
 * @param {number} ticketId Identificador del ticket
 * @param {Array<number>} tagsId Arreglo con el id de las etiquetas
 *
 * @returns {Promise} Datos del ticket actualizados
 *
 */
export async function editTicketTags(ticketId, tagsId) {
  const body = {
    new_tags: tagsId,
  };

  const results = await httpManager.put(`/tickets/manager/${ticketId}/`, body);
  return results?.data;
}
