<template>
  <a-select
    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
    :value="value"
    class="select"
    @change="handleChange"
    placeholder="Seleccione un perfil"
  >
    <a-select-option :value="''" v-if="showAllPicker"> Todos los perfiles </a-select-option>
    <a-select-option v-for="profile in profileData" :key="profile.id" :title="profile.name">
      {{ profile.name }}
    </a-select-option>
  </a-select>
</template>

<script setup lang="ts">
import { onMounted, ref } from "vue";

import type { Profile } from "@/@types/global";
import { fetchAllProfiles } from "@/app/users/services";

defineProps<{ value: number | undefined; showAllPicker?: boolean }>();
const emit = defineEmits<{ (e: "update:value", value: number): void }>();

const profileData = ref<Profile[]>([]);

const handleChange = (value: number) => emit("update:value", value);

onMounted(async () => {
  const response = await fetchAllProfiles();
  profileData.value = response;
});
</script>

<style scoped lang="sass">
.select
  min-width: 200px
</style>
