import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una entidad.
 *
 * @param {number} trunkId Iedntificador de la entidad.
 * @param {FormData} entity Datos modificados de la entidad.
 *
 * @return {Promise<Object>} Entidad editada.
 */
export default async function (didId, did) {
  const response = await httpManager.put(`/pbx/dids/${didId}/`, did);
  return response?.data;
}
