import httpManager from "../../../shared/utils/httpManager";

/**
 * Obtener filtros avanzados.
 *
 * @param {Number} id
 * @return {Promise<Object>} Filtros avanzados
 */
export async function fetchAdvancedFilters(filters) {
  const entity = filters.entity || "";
  const campaign = filters.campaign || "";
  const base = filters.base || "";
  const typification_type = filters?.typification_type || "";
  const coverage = filters.coverage || "";
  const url = `/contacts/clients/advanced-filters/?entity=${entity}&portfolio=${campaign}&base=${base}&typification_type=${typification_type}&coverage=${coverage}`;
  const response = await httpManager.get(url);
  return response.data;
}
