<template>
  <a-modal
    :visible="visible"
    :destroy-on-close="true"
    title="Editar etiqueta"
    @cancel="handleCloseModal"
  >
    <a-form layout="vertical" ref="formRef" :model="formState">
      <a-form-item label="Nombre de la etiqueta" required>
        <a-input v-model:value="formState.name" placeholder="Escribir nombre de la etiqueta" />
      </a-form-item>

      <a-form-item class="radio__form__item" label="Color de la etiqueta" required>
        <a-radio-group class="radio__wrapper" v-model:value="formState.color">
          <a-radio
            class="radio__color"
            v-for="defaultColor in defaultColors"
            :style="{ backgroundColor: `#${defaultColor}` }"
            :value="defaultColor"
            :key="defaultColor"
          />

          <div class="custom-color__selector">
            <p>Personaliza el color:</p>

            <div class="radio-color__container">
              <img src="../../assets/brush.svg" alt="brush" />

              <a-radio
                class="radio__color"
                :style="{ backgroundColor: `#${customColor}` }"
                :value="customColor"
                :class="{ 'not-selected': !isSelected || defaultColors.includes(tagData?.color) }"
                @click="openColorPicker"
              />

              <a-input v-model:value="customColor" placeholder="109FEE" style="margin-left: 14px">
                <template #prefix>
                  <span>#</span>
                </template>
              </a-input>

              <ColorInput ref="colorInputRef" @input="handleColorInput" />
            </div>
          </div>
        </a-radio-group>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button key="back" @click="handleCloseModal">Cancelar</a-button>
      <a-button key="submit" type="primary" @click="handleOk">Guardar cambios</a-button>
    </template>
  </a-modal>
</template>

<script>
import { ref, toRaw, watch } from "vue";
import { useStore } from "vuex";

import { message } from "ant-design-vue";

import ColorInput from "../input/ColorInput.vue";
import { defaultColors } from "../../utils/defaultColors";

export default {
  components: {
    ColorInput,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    tagData: {
      type: Object,
      required: true,
    },
  },
  setup(props, { emit }) {
    const store = useStore();

    const formRef = ref(null);
    const colorInputRef = ref(null);

    const formState = ref({
      name: props.tagData?.name,
      color: props.tagData?.color,
    });

    const isSelected = ref(true);
    const customColor = ref(props.tagData?.color);

    watch(
      () => formState.value.color,
      () => {
        customColor.value = formState.value.color;
        if (defaultColors.includes(customColor.value)) {
          isSelected.value = false;
        }
      }
    );

    watch(customColor, () => {
      formState.value.color = customColor.value;
      isSelected.value = true;

      if (defaultColors.includes(customColor.value)) {
        isSelected.value = false;
      }
    });

    const handleColorInput = (inputColor) => {
      if (typeof inputColor === "string") {
        const hexCode = inputColor.substring(1);
        formState.value.color = hexCode;
        customColor.value = hexCode;
      }
    };

    const openColorPicker = () => {
      colorInputRef.value.$el.click();
      isSelected.value = true;
    };

    const handleOk = () => {
      formRef.value.validateFields().then(async () => {
        const data = toRaw(formState.value);

        await store.dispatch("updateTag", { tagId: props.tagData?.id, tagData: data });
        message.success("La etiqueta se guardó correctamente");
        emit("update");
        handleCloseModal();
      });
    };

    const handleCloseModal = () => {
      emit("on-close");
    };

    return {
      formRef,
      colorInputRef,
      formState,
      customColor,
      openColorPicker,
      handleColorInput,
      handleOk,
      handleCloseModal,
      defaultColors,
      isSelected,
    };
  },
};
</script>

<style lang="sass" scoped>
p
  color: $gray-8
  margin-bottom: 4px

.radio__color
  width: 24px
  height: 24px
  margin: 0
  border-radius: 50%
  transition: 0.2s all

.custom-color__selector
  grid-row-start: 4
  grid-column: 1/-1
  justify-self: start
  margin-left: 4px

.radio-color__container
  height: 32px
  display: grid
  grid-template-columns: 24px 32px 1fr
  justify-items: center
  align-items: center
  gap: 4px

.ant-radio-wrapper-checked
  width: 32px
  height: 32px
  transition: 0.2s all

.ant-radio-wrapper-checked::after
  content: "✓"
  position: absolute
  margin: auto
  color: $gray-9
  font-size: 18px
  top: 50%
  left: 50%
  transform: translate(-50%, -50%)
</style>

<style>
.radio__form__item .ant-form-item-control {
  max-height: 176px !important;
}

.radio__color .ant-radio {
  display: none !important;
}

.radio__wrapper.ant-radio-group {
  display: grid !important;
  grid-template: repeat(4, 32px) / repeat(auto-fit, 32px) !important;
  gap: 16px 12px !important;
  justify-items: center !important;
  align-items: center !important;
}

.not-selected {
  width: 24px !important;
  height: 24px !important;
}

.not-selected::after {
  content: "" !important;
}
</style>
