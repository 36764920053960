import axios from "@/plugins/axios";
import { IResponseOk, Response } from "./interfaces/get.interface";

const url = import.meta.env.VITE_WEBCHAT_WIDGET_API;

export default async function (token: string): Promise<Response> {
  const base = `${url}/${token}`;
  const { data } = await axios.get<IResponseOk>(base);
  return data.response;
}
