import httpManager from "@/app/shared/utils/httpManager";

export default async function ({ type, payload }) {
  let url;
  const id = payload.id;
  switch (type) {
    case "group":
      url = `/entities/group/${id}/`;
      break;
    case "result":
      url = `/entities/results/${id}/`;
      break;
    case "motive":
      url = `/entities/motives/${id}/`;
      break;
    case "submotive":
      url = `/entities/submotives/${id}/`;
      break;
    default:
      break;
  }
  const response = await httpManager.put(url, payload);
  return response.data;
}
