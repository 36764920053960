<template>
  <img v-if="icon" :src="getChannelImage()" alt="Icono" />
</template>

<script>
import { ChannelType } from "@/app/shared/utils/enums";

const channelIcons = {
  [ChannelType.Whatsapp]: "whatsappChat.png",
  [ChannelType.Messenger]: "messengerChat.png",
  [ChannelType.Inbound]: "inboundChat.png",
  [ChannelType.Voice]: "voiceChat.png",
  [ChannelType.Sms]: "smsChat.png",
  [ChannelType.VoiceInbound]: "voiceChat.png",
  [ChannelType.Instagram]: "instagramChat.png",
};

export default {
  props: {
    type: { required: true, type: Number },
  },
  computed: {
    icon() {
      return channelIcons[this.type];
    },
  },
  methods: {
    getChannelImage() {
      return new URL(`../../../shared/assets/channels/${this.icon}`, import.meta.url).href;
    },
  },
};
</script>
