import httpManager from "@/app/shared/utils/httpManager";

/**
 * Resolver ticket
 * @param {number} ticketId Identificador del ticket.
 */
export async function resolveTicketById(ticketId, body) {
  const url = `/tickets/${ticketId}/resolve/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
