import httpManager from "@/app/shared/utils/httpManager";
/**
 * Eliminar un plan de marcación mediante su id.
 *
 * @param {Number} id Identificador del plan de marcación.
 *
 * @return {Promise<Object>} Usuario por id
 */
export default async function (id) {
  const response = await httpManager.delete(`/pbx/dialing-plans/${id}/`);
  return response?.data;
}
