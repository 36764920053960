<template>
  <recovery-template />
</template>

<script>
import RecoveryTemplate from "@/app/auth/components/templates/RecoveryTemplate.vue";

export default {
  components: {
    RecoveryTemplate,
  },
};
</script>
