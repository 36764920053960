import { Client, Tag, UserData } from "@/@types/global";
import { Key } from "ant-design-vue/es/_util/type";

export interface IUsers {
  id: string;
  name: string;
  avatar: string;
  campaign: ICampaign[];
  state: number;
  rawUser: UserData;
}

export interface ICampaign {
  id: number;
  name: string;
}

export interface IData {
  key: string;
  number: string;
  email: string;
  subject: string;
  time: string;
}

export interface ICampaignEmail {
  channelCustomerId: number;
  id: number;
  incomingEmail: string;
  outgoingEmail: string;
  title: string;
  unread: boolean;
  totalEmail: number;
}

export interface State {
  selectedMenu: string;
  menuItems: Array<IMenutem>;
  openKeysMenuItems: string[];
  selectedKeysMenuItems: IMenuSubItem[];
  // selectedCampaign: string;
  selectedCampaign: ICampaignEmail | null;
  // selectedEmail: string;
  totalEmails: number;
  tablePage: number;
  filters: {
    search: string;
    read: boolean | undefined;
    tagList: Array<number | string>;
  };
  threadsInbox: DataType[];
  fetchingThreadsInbox: boolean;
  selectedThreadsInbox: {
    selectedRowKeys: Key[];
    countSelectedRow: number;
  };
  threadActual: Thread;
}

export interface IMenutem {
  id: number;
  title: string;
  subMenu: string;
  subItems: IMenuSubItem[];
}

export interface IMenuSubItem {
  id: number;
  title: string;
  buttonLabel: string;
  isActive?: boolean | false;
  email: string;
  emailIncoming?: string | null;
  unread?: boolean;
}

export interface IEmailMessage {
  id: number;
  show: boolean;
  hasActionMessage: boolean;
  hasNote: boolean;
}

export enum IdentifierType {
  Score = 1,
  Securitec = 2,
  Ticker = 3,
}

export interface EmailIdentifiers {
  id: number;
  associate: string;
  alias: string;
}

export interface EmailDashboard {
  strategy_id: number;
  agents?: any;
  management_statistics?: any;
  click_through_rate: number;
  states: DashboardState[];
  strategy_type_name: string;
  total_cancel_subscription: number;
  total_click_through_rate: number;
  percentage_cancel_subscription: number;
  total_clicks: number;
  percentage_open: number;
  percentage_click: number;
  percentage_click_through_rate: number;
  total_opens: number;
  total: number;
  porcentage_total_spam?: number;
  total_spam?: number;
}

export interface DashboardState {
  name: string;
  value: number;
  percentage: number;
}

export interface DataType {
  key: number;
  number: string;
  id?: number;
  email: string;
  subject: string;
  messagePreview: string | null;
  time: string;
  tags: Tag[];
  read: boolean;
  // messages: string[];
}

export interface NoteEmail {
  key: number;
  id: number;
  note: string;
  userData: string;
  date: string;
  time: string;
}

export interface EmailDetails extends IEmailMessage {
  id: number;
  avatar: string;
  sender: string;
  receivers: { alias: null | string; email: null | string }[];
  code: number;
  content: string;
  channelCustomer?: number | null;
  date: string;
  time: string;
  hasActionMessage: boolean;
  hasNote: boolean;
  notes: NoteEmail[] | [];
  subject: string;
  tags: Tag[];
  client: { id: number; fullname: string; profile_picture: string }[];
  ccEmails: { alias: null | string; email: null | string }[];
  bccEmails: { alias: null | string; email: null | string }[];
  resolved: boolean;
  parent: any | null;
  referenceId?: string | null;
  createdAt: string;
  attachmentsPublicUrl?: [];
  attachmentsUrl?: [];
}

export interface EmailLogDetails {
  userId: number;
  message: string;
  createdAt: string;
}

export enum ThreadDetailType {
  Email = "Email",
  Log = "Log",
}

export interface ThreadDetails {
  createdAt: string;
  type: ThreadDetailType.Email | ThreadDetailType.Log;
  elementEmail: EmailDetails | null;
  elementLog: EmailLogDetails | null;
}

export interface Thread {
  key: number | null;
  id: number | null;
  tags: Tag[];
  client: any;
  ticket: number | null;
  associatedClients: [];
  details: ThreadDetails[];
  assignedUser?: number | null;
}

export interface EmailRecordInfo {
  id: number;
  email: string;
  description: string;
  created_at: string;
}

export interface EmailForm {
  fromEmail: string;
  selectedItems: string[];
  selectedItemsCC: string[];
  selectedItemsCCO: string[];
  subject: string;
  text: string;
}

export enum ActionsEmail {
  Responder = "Responder",
  ResponderTodos = "Responder a todos",
  Reenviar = "Reenviar",
  NotaInterna = "Añadir nota interna",
  Bloquear = "Bloquear contacto",
}

export enum TypeInbox {
  MisAsignados = "Asignados a mí",
  BandejaEntrada = "Bandeja de entrada",
  Resueltos = "Resueltos",
}
