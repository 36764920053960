<template>
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_404_13047)">
      <path
        d="M5.15382 19.1666C4.89421 19.1666 4.63655 19.0941 4.41037 18.9515C4.01899 18.7043 3.77644 18.2806 3.76161 17.8177L3.71643 16.3356C1.85772 14.6689 0.833252 12.3289 0.833252 9.74702C0.833252 7.28278 1.77785 5.0166 3.49276 3.36589C5.18977 1.73267 7.50085 0.833252 10.0003 0.833252C12.4998 0.833252 14.8109 1.73267 16.5079 3.36589C18.2228 5.0166 19.1674 7.28278 19.1674 9.74702C19.1674 12.2111 18.2228 14.4773 16.5079 16.128C14.8109 17.7612 12.4998 18.6608 10.0003 18.6608C9.09924 18.6608 8.21744 18.5443 7.37929 18.3146L5.71544 19.0487C5.53513 19.1277 5.34406 19.1666 5.15382 19.1666ZM10.0003 2.26561C5.5908 2.26561 2.26561 5.48184 2.26561 9.74702C2.26561 11.9195 3.12348 13.8834 4.68117 15.2768C4.96848 15.5356 5.1334 15.8862 5.14725 16.2655L5.14739 16.27L5.19159 17.7142L6.81376 16.9985C7.10583 16.8699 7.43524 16.8452 7.742 16.9288C8.46293 17.1277 9.22205 17.2283 10.0003 17.2283C14.4099 17.2283 17.7351 14.0121 17.7351 9.74702C17.7351 5.48184 14.4099 2.26561 10.0003 2.26561ZM5.49331 12.6727L8.16989 10.6091C8.35075 10.4734 8.59973 10.4716 8.78227 10.6074L10.7628 12.1469C11.3565 12.5899 12.2061 12.4337 12.6018 11.8091L15.0912 7.86145C15.3317 7.48462 14.8643 7.05869 14.5078 7.32852L11.8325 9.35578C11.6516 9.4916 11.4026 9.49328 11.2201 9.35746L9.23954 7.8539C8.64589 7.4109 7.79627 7.56701 7.40055 8.19157L4.90987 12.1396C4.66942 12.5165 5.13676 12.9425 5.49331 12.6727Z"
        fill="black"
        fill-opacity="0.65"
      />
    </g>
    <defs>
      <clipPath id="clip0_404_13047">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
</template>
