import httpManager from "@/app/shared/utils/httpManager";
/**
 * Actualizar nueva etiqueta.
 * @param {number} tagId Identificador de la etiqueta.
 * @param {Object} tagData Datos de la etiqueta.
 *
 * @return {Promise<Object>} Nueva etiqueta.
 */
export async function updateTag(tagId, tagData) {
  const response = await httpManager.put(`/tickets/tags/${tagId}/`, tagData);
  return response?.data;
}
