import {
  fetchAllBases,
  createNewBase,
  updateBase,
  deleteBase,
  changeBaseStatus,
  fetchDashboardByBase,
  fetchListBases,
} from "@/app/entities/services";
import {
  ADD_BASE,
  REMOVE_BASE,
  SET_BASE,
  SET_BASES,
  SET_BASE_STATUS,
  SET_ALL_BASES,
} from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchDashboardByBase(idBase) {
    return await fetchDashboardByBase(idBase);
  },
  async fetchAllBases({ commit }, { filters, pagination }) {
    const data = await fetchAllBases(
      filters?.search,
      filters?.entity,
      filters?.portfolio,
      pagination
    );
    commit(SET_BASES, data);
    commit(SET_BASES, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async createNewBase({ commit }, base) {
    const createdBase = await createNewBase(base);
    commit(ADD_BASE, createdBase);
  },
  async updateBase({ commit }, { baseId, newBase }) {
    const updatedBase = await updateBase(baseId, newBase);
    commit(SET_BASE, updatedBase);
  },
  async deleteBase({ commit }, baseId) {
    await deleteBase(baseId);
    commit(REMOVE_BASE, baseId);
  },
  async changeBaseStatus({ commit }, base) {
    const changedStatus = base.status == 1 ? 2 : 1;
    const updatedBase = await changeBaseStatus(base.id, changedStatus);
    commit(SET_BASE_STATUS, updatedBase);
  },
  async fetchListBases({ commit }, search) {
    const data = await fetchListBases(search);

    if (data.length === 0) {
      commit(SET_ALL_BASES, []);
    }
    commit(SET_ALL_BASES, data);
  },
  async clearInputBase({ commit }) {
    commit(SET_ALL_BASES, []);
  },
};

export default actions;
