<template>
  <p>
    Crea variables y haz que tu chatbot capture los datos de tus contactos
    <a :href="DOCS_URL" target="_blank" rel="noopener noreferrer"> Click aquí </a> para que sepas
    cómo hacerlo.
  </p>
  <a-row type="flex" justify="space-between" class="mrg-bottom-12">
    <div>
      <a-input-search
        v-model:value="filters.search"
        placeholder="Buscar por nombre"
        class="section__input--search mrg-right-8"
        @search="handleSearch"
      />
    </div>
    <a-button type="primary" @click="handleShowCreateModal">Crear nueva variable</a-button>
  </a-row>
  <table-manage-vars
    :loading="loading"
    :data-source="dataSource?.results"
    :total="dataSource ? dataSource.count : 0"
    @onUpdate="handleShowUpdateModal"
    @onDelete="handleShowDeleteModal"
    @onChangePagination="handleFilterVars"
  />
  <modal-manage-var v-bind="modalVar" @onClose="handleCloseModal" />
  <modal-delete-manage-var v-bind="modalDeleteVar" @onClose="handleCloseDeleteModal" />
</template>

<script setup lang="ts">
import { reactive, ref, onMounted, provide } from "vue";
import Anchor from "@/app/shared/components/molecules/Anchor.vue";
import TableManageVars from "@/app/manageVars/components/tables/TableManageVars.vue";
import ModalManageVar from "@/app/manageVars/components/modals/ModalManageVar.vue";
import ModalDeleteManageVar from "@/app/manageVars/components/modals/ModalDeleteManageVar.vue";
import { fetchVars, createVar, updateVar } from "@/app/manageVars/services";
import {
  ResponseManageVars,
  FormVar,
  VarItem,
  PropsModalVar,
  PropsModalDeleteVar,
} from "@/@types/manageVars";
import { message } from "ant-design-vue";
import { Pagination } from "@/@types/global";

const DOCS_URL = `${import.meta.env.VITE_WEB_DOC}/docs/score/flujos/gestor-de-variables/`;

const filters = reactive({
  search: "",
});
const loading = ref(false);
const dataSource = ref<ResponseManageVars>();

const modalVar: PropsModalVar = reactive({
  visible: false,
  title: "",
  okText: "",
  dataSource: undefined,
  onOk: undefined,
});

const modalDeleteVar: PropsModalDeleteVar = reactive({
  varId: undefined,
  varName: "",
  visible: false,
  varChatbots: [],
});

onMounted(() => {
  loadDataInitial();
});

/**
 * Carga la data inicial
 */
const loadDataInitial = async () => {
  handleFilterVars({ current: 1 });
};

const handleFilterVars = async (pagination: Pagination) => {
  loading.value = true;
  const response = await fetchVars({ search: filters.search, pagination });
  if (response.success) {
    dataSource.value = response.data;
  }

  loading.value = false;
};

const handleSearch = () => {
  handleFilterVars({ current: 1 });
};

const handleShowCreateModal = () => {
  modalVar.title = "Crear nueva variable";
  modalVar.okText = "Crear variable";
  modalVar.onOk = addVar;
  modalVar.visible = true;
};

const handleShowUpdateModal = (record: VarItem) => {
  modalVar.title = "Editar variable";
  modalVar.okText = "Editar variable";
  modalVar.dataSource = record;
  modalVar.onOk = updateVarDetails;
  modalVar.visible = true;
};

const handleCloseModal = () => {
  modalVar.visible = false;
  modalVar.title = "";
  modalVar.dataSource = undefined;
};

const handleShowDeleteModal = (record: VarItem) => {
  modalDeleteVar.varId = record.id;
  modalDeleteVar.varName = record.name;
  modalDeleteVar.varChatbots = record.chatbots;
  modalDeleteVar.visible = true;
};

const handleCloseDeleteModal = () => {
  modalDeleteVar.visible = false;
  modalDeleteVar.varId = undefined;
};

const addVar = async (newVar: FormVar) => {
  const response = await createVar(newVar);
  if (response.success && dataSource.value) {
    dataSource.value.results.unshift(response.data);
    handleCloseModal();
    message.success("Se agregó una nueva variable");
    setLoading();
  }
};
const updateVarDetails = async (newVar: FormVar) => {
  const response = await updateVar(newVar);
  if (response.success && dataSource.value) {
    const varFound = dataSource.value.results.find((item) => item.id === newVar.id);
    if (!varFound) return;
    varFound.name = response.data.name;
    varFound.exclude_words = response.data.exclude_words;

    handleCloseModal();
    message.success("Se agregó una nueva variable");
    setLoading();
  }
};
const setLoading = () => {
  loading.value = true;
  setTimeout(() => (loading.value = false), 1000);
};
const removeVar = (id: number) => {
  if (!dataSource.value) return;
  dataSource.value.results = dataSource.value.results.filter((result) => result.id !== id);
  setLoading();
};
provide("functionManageVar", { onRemove: removeVar });
</script>
