import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo telefono.
 * @param {client} contactId Identificador del contacto.
 *
 */
export async function confirmIsCallUser(client) {
  const url = `/contacts/clients/validate-phone-in-call/`;
  const response = await httpManager.post(url, client);
  return response?.data;
}
