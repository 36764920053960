<template>
  <div class="logo" :class="{ 'logo--dark': dark }" v-if="workspace != 'sandbox'">
    <svg
      id="GRID"
      xmlns="http://www.w3.org/2000/svg"
      :height="xl ? 51 : large ? 41 : 41"
      viewBox="0 0 671.14 242.68"
    >
      <path
        class="cls-2"
        d="M234.18,98.11l-32.32-55.98c-8.27-14.33-23.69-23.23-40.24-23.23h-64.64c-16.55,0-31.97,8.9-40.24,23.23l-32.32,55.98c-8.27,14.33-8.27,32.14,0,46.47l45.73,79.21h91.46c16.55,0,31.97-8.9,40.24-23.23l32.32-55.98c8.27-14.33,8.27-32.14,0-46.47ZM96.98,46.24h64.64c6.81,0,13.16,3.67,16.57,9.57l32.32,55.98c3.41,5.9,3.41,13.23,0,19.13l-6.14,10.63c-.77.08-1.54.13-2.32.13h-84.52l-42.26-73.19c-.38-.65-.71-1.33-1.02-2.01l6.16-10.67c3.41-5.9,9.76-9.57,16.57-9.57ZM161.62,196.46h-75.68l-37.84-65.54c-3.41-5.9-3.41-13.23,0-19.13l10.3-17.85,43.35,75.08h86.76l-10.32,17.88c-3.41,5.9-9.76,9.57-16.57,9.57Z"
      />
      <g>
        <g>
          <path
            class="cls-1"
            d="M371.85,110.85c-1.81-1.05-3.88-1.85-6.17-2.43.94-.38,1.84-.79,2.69-1.25,4.64-2.53,8.03-5.84,10.17-9.93,2.14-4.09,3.21-8.48,3.21-13.18,0-10.76-3.83-18.73-11.48-23.9-7.65-5.17-18.26-7.76-31.82-7.76h-40.76v115.24h44.56c13.51,0,23.88-2.86,31.11-8.59,7.23-5.72,10.84-14.21,10.84-25.45,0-4.54-.98-8.84-2.93-12.9-1.95-4.06-5.09-7.35-9.42-9.85ZM338.45,72.5c6.38,0,11.05,1.11,14.01,3.32,2.95,2.22,4.43,5.86,4.43,10.92,0,4.43-1.5,7.82-4.51,10.17-3.01,2.35-7.65,3.52-13.93,3.52h-15.83v-27.94h15.83ZM355.31,143.54c-2.74,2.72-7.1,4.08-13.06,4.08h-19.63v-29.84h20.66c3.85,0,6.95.63,9.3,1.9,2.35,1.27,4.08,3.06,5.18,5.38,1.11,2.32,1.66,5.09,1.66,8.31,0,4.06-1.37,7.45-4.12,10.17Z"
          />
          <path
            class="cls-1"
            d="M456.7,84.06c-5.65-3.48-12.61-5.22-20.9-5.22s-15.51,1.9-21.53,5.7c-6.02,3.8-10.65,9.04-13.89,15.71-3.25,6.68-4.87,14.29-4.87,22.84v3.09c0,7.6,1.7,14.54,5.1,20.82,3.4,6.28,8.31,11.29,14.72,15.04,6.41,3.75,14.13,5.62,23.15,5.62,8.39,0,15.39-1.61,21.01-4.83,5.62-3.22,9.78-6.75,12.47-10.61l-11.56-13.22c-2.48,3.22-5.49,5.58-9.02,7.08-3.54,1.5-7.34,2.26-11.4,2.26-4.33,0-8.02-.94-11.08-2.81-3.06-1.87-5.41-4.47-7.05-7.8-.99-2.02-1.68-4.24-2.07-6.65h54.07v-10.13c0-8.5-1.45-15.9-4.35-22.2-2.9-6.3-7.18-11.2-12.82-14.68ZM450.36,115h-30.35c.26-1.63.6-3.19,1.03-4.67,1.08-3.75,2.81-6.73,5.18-8.94,2.37-2.22,5.51-3.32,9.42-3.32,3.43,0,6.21.63,8.35,1.9,2.14,1.27,3.73,3.03,4.79,5.3,1.05,2.27,1.58,4.88,1.58,7.84v1.9Z"
          />
          <path
            class="cls-1"
            d="M543.74,84.06c-5.65-3.48-12.61-5.22-20.9-5.22s-15.51,1.9-21.53,5.7c-6.02,3.8-10.65,9.04-13.89,15.71-3.25,6.68-4.87,14.29-4.87,22.84v3.09c0,7.6,1.7,14.54,5.1,20.82,3.4,6.28,8.31,11.29,14.72,15.04,6.41,3.75,14.13,5.62,23.15,5.62,8.39,0,15.39-1.61,21.01-4.83,5.62-3.22,9.78-6.75,12.47-10.61l-11.56-13.22c-2.48,3.22-5.49,5.58-9.02,7.08-3.54,1.5-7.34,2.26-11.4,2.26-4.33,0-8.02-.94-11.08-2.81-3.06-1.87-5.41-4.47-7.05-7.8-.99-2.02-1.68-4.24-2.07-6.65h54.07v-10.13c0-8.5-1.45-15.9-4.35-22.2-2.9-6.3-7.18-11.2-12.82-14.68ZM537.41,115h-30.35c.26-1.63.6-3.19,1.03-4.67,1.08-3.75,2.81-6.73,5.18-8.94,2.37-2.22,5.51-3.32,9.42-3.32,3.43,0,6.21.63,8.35,1.9,2.14,1.27,3.73,3.03,4.79,5.3,1.05,2.27,1.58,4.88,1.58,7.84v1.9Z"
          />
          <polygon
            class="cls-1"
            points="620.2 123.87 644.1 82 618.7 82 604.92 106.77 591.47 82 565.9 82 589.81 123.87 564.79 167.64 590.44 167.64 604.92 141.36 619.65 167.64 645.13 167.64 620.2 123.87"
          />
        </g>
        <g>
          <path
            class="cls-1"
            d="M326.51,211.41c-.26,2.35-.93,4.46-2.03,6.33-1.1,1.88-2.65,3.35-4.65,4.43s-4.51,1.62-7.53,1.62-5.49-.72-7.68-2.15c-2.19-1.43-3.9-3.45-5.11-6.05-1.22-2.6-1.82-5.64-1.82-9.12v-3.78c0-3.49.61-6.54,1.84-9.13,1.22-2.6,2.97-4.61,5.23-6.05s4.93-2.16,8.02-2.16c2.83,0,5.22.53,7.17,1.58,1.95,1.05,3.47,2.52,4.56,4.41,1.09,1.88,1.76,4.06,2.02,6.53h-4.98c-.38-2.62-1.21-4.69-2.51-6.21-1.29-1.52-3.38-2.27-6.26-2.27-2.21,0-4.06.55-5.55,1.66s-2.62,2.65-3.38,4.64c-.76,1.99-1.14,4.31-1.14,6.96v3.83c0,2.45.34,4.68,1.03,6.69.68,2.01,1.73,3.62,3.15,4.82s3.22,1.8,5.43,1.8,3.78-.33,5.02-1c1.24-.67,2.18-1.63,2.83-2.88s1.1-2.75,1.37-4.5h4.98Z"
          />
          <path
            class="cls-1"
            d="M331.24,209.09c0-2.69.51-5.1,1.54-7.22s2.48-3.81,4.37-5.05c1.88-1.24,4.12-1.86,6.71-1.86s4.88.62,6.78,1.86,3.36,2.92,4.38,5.05,1.53,4.53,1.53,7.22v.59c0,2.67-.51,5.07-1.53,7.21s-2.48,3.82-4.37,5.05-4.14,1.85-6.74,1.85-4.85-.62-6.75-1.85c-1.89-1.23-3.35-2.92-4.38-5.05s-1.54-4.54-1.54-7.21v-.59ZM336.05,209.68c0,1.83.29,3.53.87,5.09.58,1.56,1.45,2.81,2.61,3.75s2.62,1.41,4.38,1.41,3.2-.47,4.35-1.41,2.03-2.19,2.61-3.75.87-3.25.87-5.09v-.59c0-1.83-.29-3.53-.87-5.09s-1.46-2.81-2.62-3.76-2.63-1.43-4.39-1.43-3.18.48-4.34,1.43c-1.17.95-2.03,2.21-2.61,3.76-.57,1.56-.86,3.25-.86,5.09v.59Z"
          />
          <path
            class="cls-1"
            d="M367.32,223.28h-4.8v-27.8h4.55l.26,5.94v21.87ZM364.18,208.22c.03-2.54.52-4.8,1.46-6.8s2.26-3.57,3.94-4.73c1.69-1.16,3.65-1.73,5.9-1.73,1.83,0,3.41.34,4.74,1.01s2.36,1.75,3.08,3.22,1.09,3.43,1.09,5.86v18.22h-4.8v-18.27c0-1.46-.21-2.63-.63-3.52s-1.04-1.54-1.86-1.94-1.83-.6-3.03-.6-2.25.25-3.21.75-1.79,1.18-2.49,2.06-1.24,1.87-1.62,2.99-.57,2.31-.57,3.56l-2-.08Z"
          />
          <path
            class="cls-1"
            d="M403.27,199.1h-15.06v-3.62h15.06v3.62ZM398.07,188.72v27.67c0,.96.15,1.68.44,2.16s.67.8,1.13.95.96.23,1.49.23c.39,0,.81-.03,1.25-.1s.76-.13.96-.18l.03,3.83c-.38.12-.87.24-1.48.35s-1.34.17-2.2.17c-1.16,0-2.24-.23-3.21-.69s-1.76-1.24-2.34-2.33-.87-2.56-.87-4.41v-27.65h4.8Z"
          />
          <path
            class="cls-1"
            d="M426.06,209.76h-4.78c-2.71,0-4.8.43-6.27,1.28s-2.21,2.14-2.21,3.85c0,1.34.45,2.47,1.35,3.39s2.21,1.39,3.94,1.39c1.46,0,2.74-.31,3.85-.93s2-1.38,2.65-2.29,1-1.78,1.05-2.62l2.06,2.29c-.12.72-.45,1.52-.98,2.39s-1.24,1.71-2.12,2.51-1.93,1.46-3.13,1.98-2.57.78-4.07.78c-1.88,0-3.53-.36-4.95-1.09s-2.51-1.72-3.29-2.97-1.17-2.64-1.17-4.16c0-3,1.13-5.27,3.39-6.82s5.3-2.33,9.12-2.33h5.5l.05,3.34ZM425.18,204.21c0-1.66-.49-2.98-1.48-3.97s-2.44-1.48-4.36-1.48c-1.18,0-2.22.21-3.12.62s-1.6.95-2.09,1.62-.75,1.4-.75,2.18h-4.8c0-1.34.45-2.63,1.36-3.89s2.19-2.3,3.84-3.11c1.65-.81,3.6-1.22,5.85-1.22,2.02,0,3.81.34,5.37,1.03s2.78,1.72,3.66,3.1,1.32,3.1,1.32,5.18v12.95c0,.93.08,1.91.24,2.95s.41,1.94.73,2.7v.41h-5.01c-.24-.55-.43-1.28-.57-2.2s-.21-1.77-.21-2.56v-14.31Z"
          />
          <path
            class="cls-1"
            d="M448.59,219.94c1.13,0,2.18-.24,3.13-.71s1.75-1.12,2.36-1.94.97-1.76,1.05-2.8h4.55c-.09,1.64-.64,3.17-1.67,4.59s-2.36,2.55-4.01,3.42-3.45,1.3-5.42,1.3c-2.79,0-5.1-.64-6.94-1.91-1.83-1.28-3.2-2.97-4.1-5.07-.9-2.11-1.35-4.4-1.35-6.89v-1.08c0-2.5.45-4.8,1.36-6.9s2.28-3.79,4.11-5.06,4.14-1.91,6.91-1.91c2.18,0,4.08.44,5.72,1.32s2.92,2.08,3.85,3.6,1.44,3.23,1.53,5.15h-4.55c-.09-1.15-.41-2.19-.96-3.13s-1.31-1.69-2.26-2.25-2.06-.84-3.33-.84c-1.94,0-3.46.5-4.56,1.49s-1.88,2.26-2.34,3.79-.68,3.11-.68,4.74v1.08c0,1.63.22,3.21.67,4.75s1.22,2.81,2.33,3.79,2.63,1.48,4.59,1.48Z"
          />
          <path
            class="cls-1"
            d="M476.7,199.1h-15.06v-3.62h15.06v3.62ZM471.51,188.72v27.67c0,.96.15,1.68.44,2.16s.67.8,1.13.95.96.23,1.49.23c.39,0,.81-.03,1.25-.1s.76-.13.96-.18l.03,3.83c-.38.12-.87.24-1.48.35s-1.34.17-2.2.17c-1.16,0-2.24-.23-3.21-.69s-1.76-1.24-2.34-2.33-.87-2.56-.87-4.41v-27.65h4.8Z"
          />
          <path
            class="cls-1"
            d="M523.57,211.41c-.26,2.35-.93,4.46-2.03,6.33s-2.65,3.35-4.65,4.43-4.51,1.62-7.53,1.62-5.49-.72-7.68-2.15c-2.19-1.43-3.9-3.45-5.11-6.05s-1.82-5.64-1.82-9.12v-3.78c0-3.49.61-6.54,1.84-9.13s2.97-4.61,5.23-6.05,4.93-2.16,8.02-2.16c2.83,0,5.22.53,7.17,1.58s3.47,2.52,4.56,4.41,1.76,4.06,2.02,6.53h-4.98c-.38-2.62-1.21-4.69-2.51-6.21-1.29-1.52-3.38-2.27-6.26-2.27-2.21,0-4.06.55-5.55,1.66s-2.62,2.65-3.38,4.64-1.14,4.31-1.14,6.96v3.83c0,2.45.34,4.68,1.03,6.69s1.73,3.62,3.15,4.82,3.22,1.8,5.43,1.8,3.78-.33,5.02-1,2.18-1.63,2.83-2.88,1.1-2.75,1.37-4.5h4.98Z"
          />
          <path
            class="cls-1"
            d="M541.09,223.79c-2.59,0-4.83-.58-6.74-1.73s-3.39-2.75-4.45-4.77-1.58-4.33-1.58-6.94v-1.1c0-3.01.58-5.59,1.73-7.72,1.16-2.13,2.66-3.76,4.52-4.88s3.82-1.68,5.9-1.68c2.67,0,4.85.6,6.54,1.79,1.69,1.19,2.93,2.81,3.73,4.86s1.19,4.36,1.19,6.95v2.13h-20.76v-3.85h15.96v-.36c-.07-1.23-.32-2.44-.75-3.61s-1.11-2.14-2.06-2.9-2.23-1.14-3.85-1.14c-1.44,0-2.71.41-3.82,1.22s-1.97,2-2.6,3.55-.94,3.44-.94,5.67v1.1c0,1.76.33,3.37.98,4.82s1.59,2.6,2.83,3.47,2.71,1.3,4.42,1.3,3.1-.34,4.27-1.03,2.18-1.6,3.06-2.75l2.9,2.26c-.89,1.37-2.17,2.61-3.84,3.71-1.67,1.1-3.88,1.66-6.64,1.66Z"
          />
          <path
            class="cls-1"
            d="M562.27,223.28h-4.8v-27.8h4.55l.26,5.94v21.87ZM559.13,208.22c.03-2.54.52-4.8,1.46-6.8s2.26-3.57,3.94-4.73,3.65-1.73,5.9-1.73c1.83,0,3.41.34,4.74,1.01s2.36,1.75,3.08,3.22,1.09,3.43,1.09,5.86v18.22h-4.8v-18.27c0-1.46-.21-2.63-.63-3.52s-1.04-1.54-1.86-1.94-1.83-.6-3.03-.6-2.25.25-3.21.75-1.79,1.18-2.49,2.06-1.24,1.87-1.62,2.99-.57,2.31-.57,3.56l-2-.08Z"
          />
          <path
            class="cls-1"
            d="M598.21,199.1h-15.06v-3.62h15.06v3.62ZM593.02,188.72v27.67c0,.96.15,1.68.44,2.16s.67.8,1.13.95.96.23,1.49.23c.39,0,.81-.03,1.25-.1s.76-.13.96-.18l.03,3.83c-.38.12-.87.24-1.48.35s-1.34.17-2.2.17c-1.16,0-2.24-.23-3.21-.69s-1.76-1.24-2.34-2.33-.87-2.56-.87-4.41v-27.65h4.8Z"
          />
          <path
            class="cls-1"
            d="M615.3,223.79c-2.59,0-4.83-.58-6.74-1.73s-3.39-2.75-4.45-4.77-1.58-4.33-1.58-6.94v-1.1c0-3.01.58-5.59,1.73-7.72s2.66-3.76,4.52-4.88,3.82-1.68,5.9-1.68c2.67,0,4.85.6,6.54,1.79s2.93,2.81,3.73,4.86c.8,2.05,1.19,4.36,1.19,6.95v2.13h-20.76v-3.85h15.96v-.36c-.07-1.23-.32-2.44-.75-3.61s-1.11-2.14-2.06-2.9-2.23-1.14-3.85-1.14c-1.44,0-2.71.41-3.82,1.22s-1.97,2-2.6,3.55-.94,3.44-.94,5.67v1.1c0,1.76.33,3.37.98,4.82s1.59,2.6,2.83,3.47,2.71,1.3,4.42,1.3,3.1-.34,4.27-1.03,2.18-1.6,3.06-2.75l2.9,2.26c-.89,1.37-2.17,2.61-3.84,3.71-1.67,1.1-3.88,1.66-6.64,1.66Z"
          />
          <path
            class="cls-1"
            d="M636.47,223.28h-4.8v-27.8h4.68l.13,4.37v23.43ZM645.13,199.71c-.41-.09-.79-.14-1.14-.17s-.75-.04-1.19-.04c-1.46,0-2.69.3-3.69.9s-1.78,1.43-2.33,2.48-.89,2.26-1.03,3.61l-1.36.77c0-2.24.3-4.3.9-6.15s1.54-3.35,2.81-4.47,2.92-1.68,4.92-1.68c.33,0,.72.04,1.17.12s.77.16.94.24v4.39Z"
          />
        </g>
      </g>
    </svg>
  </div>
  <div class="logo logo_previous" :class="{ 'logo--dark': dark }" v-else>
    <svg
      :width="xl ? 50 : large ? 40 : 35"
      :height="xl ? 51 : large ? 41 : 36"
      viewBox="0 0 37 36"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="logo__vector"
    >
      <path
        d="M12.6103 4.23409C12.5658 3.346 12.1851 2.5287 11.538 1.93342C10.8876 1.33814 10.0501 1.03246 9.17444 1.07751C8.60656 1.10647 8.06724 1.28022 7.59771 1.57947C7.34391 1.74036 7.11232 1.93664 6.9061 2.16831C6.31919 2.82794 6.0178 3.67742 6.06222 4.56551C6.15105 6.39639 7.69288 7.81219 9.49804 7.72209C10.3736 7.67704 11.1795 7.29092 11.7664 6.6345C12.3533 5.97165 12.6547 5.12218 12.6103 4.23409Z"
        fill="#F3D113"
      />
      <path
        d="M10.5289 35.5459C12.3341 35.4558 13.73 33.892 13.6412 32.0611C13.5523 30.2303 12.0105 28.8145 10.2053 28.9046C9.62477 28.9335 9.08862 29.1137 8.62861 29.4065C7.65465 30.0243 7.02967 31.1473 7.08994 32.3893C7.18195 34.2202 8.72378 35.636 10.5289 35.5459Z"
        fill="#F3D113"
      />
      <path
        d="M7.87047 27.9198C7.16618 27.1958 6.52533 26.3882 5.97014 25.4937C2.67391 20.1651 3.33697 13.4401 7.14714 8.87739C5.95428 8.27567 5.03108 7.19452 4.62818 5.87204C-0.603285 11.6929 -1.61849 20.5706 2.70881 27.5659C3.53049 28.8948 4.49493 30.0725 5.57041 31.1022C5.87497 29.7571 6.71886 28.6084 7.87047 27.9198Z"
        fill="#F3D113"
      />
      <path
        d="M33.4249 14.132C32.857 14.161 32.3177 14.3347 31.8481 14.634C31.5943 14.7949 31.3627 14.9911 31.1565 15.2228C30.5696 15.8824 30.2682 16.7319 30.3126 17.62C30.4015 19.4509 31.9433 20.8667 33.7485 20.7766C35.5536 20.6865 36.9495 19.1227 36.8607 17.2918C36.7719 15.4577 35.23 14.0419 33.4249 14.132Z"
        fill="#F3D113"
      />
      <path
        d="M29.526 10.5058C30.0812 11.4036 30.519 12.3399 30.8521 13.2988C31.585 12.8 32.4384 12.504 33.3457 12.459C33.8057 12.4364 34.253 12.4815 34.6813 12.5812C34.2372 11.1526 33.609 9.75608 32.7873 8.42717C28.4632 1.43507 20.1417 -1.50271 12.6927 0.740042C13.6381 1.63135 14.1965 2.84121 14.2599 4.15404C14.2631 4.21839 14.2599 4.28597 14.2599 4.35032C19.9704 2.85408 26.2298 5.17727 29.526 10.5058Z"
        fill="#F3D113"
      />
      <path
        d="M33.8278 22.4471C32.8475 22.4954 31.9243 22.2476 31.1312 21.7842C30.2333 25.0534 28.1839 28.0041 25.132 29.9444C22.0832 31.8847 18.568 32.4735 15.2782 31.8686C15.2813 31.9072 15.2877 31.9426 15.2877 31.9812C15.3543 33.3616 14.8562 34.6422 14.0028 35.5915C18.3745 36.5471 23.0984 35.8489 27.1782 33.2554C31.2549 30.6619 33.9325 26.6494 34.9858 22.2444C34.6177 22.3538 34.2307 22.4278 33.8278 22.4471Z"
        fill="#F3D113"
      />
    </svg>
    <span class="logo__title" :class="{ xl: xl }"> Score Cloud </span>
  </div>

</template>

<script>
import { ProductName } from "@/app/shared/utils/constants";

export default {
  props: {
    collapse: {
      required: false,
      type: Boolean,
      default: false,
    },
    dark: {
      required: false,
      type: Boolean,
      default: false,
    },
    large: {
      required: false,
      type: Boolean,
      default: false,
    },
    xl: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const workspace = localStorage.getItem('workspace');
    return { ProductName, workspace };
  },
};
</script>

<style lang="sass" scoped>
.logo
  display: flex
  align-items: center
  margin-top: 16px
  margin-bottom: 16px
  margin-left: 10px
  overflow: hidden

  &__vector
    line-height: 0px
    flex: 0 0 auto

  &__title
    color: $gray-9
    font-size: 28px
    font-weight: bold
    line-height: 38px
    margin-left: 10px
    text-overflow: clip
    overflow: hidden
    white-space: nowrap

    &.xl
      font-size: 40px

  &--dark
    margin: 0

  &--dark &__title
    color: $white

  &_previous
    margin-left: 14px

.cls-1
  fill: #000

.cls-1,
.cls-2
  stroke-width: 0px

.cls-2
  fill: #fcb62b
</style>
