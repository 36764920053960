import NotificationsPage from "./pages/NotificationsPage.vue";

export default [
  {
    path: "/notifications",
    name: "notifications",
    component: NotificationsPage,
    meta: {
      requiresAuth: true,
      hasScope: false,
    },
  },
];
