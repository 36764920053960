<template>
  <div class="ticket__tags">
    <template v-if="tags?.length !== 0">
      <a-tooltip v-for="item in tags" :key="item?.id" :title="item?.name">
        <div class="color-tag" :style="getTagColor(item?.color)" />
      </a-tooltip>
    </template>

    <span v-else-if="withLabel" class="ticket__message">Ticket sin etiquetas asignadas</span>
  </div>
</template>

<script setup>
defineProps({
  tags: { type: Array, default: () => [] },
  withLabel: { type: Boolean, default: false },
});

const getTagColor = (color) => {
  return `background-color: #${color}`;
};
</script>

<style lang="sass" scoped>
.color-tag
  width: 20px
  height: 8px
  border-radius: 2px
  border: $white

.ticket
  &__tags
    padding-right: 8px
    display: flex
    align-items: center
    flex-wrap: wrap
    gap: 4px
    max-height: 40px

  &__message
    color: $gray-6
    font-weight: 500
    font-size: 12px
</style>
