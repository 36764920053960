<template>
  <svg width="20" height="21" viewBox="0 0 20 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M10.5661 1.809C10.1906 1.75475 9.80931 1.75475 9.43386 1.809C9.00972 1.87029 8.60372 2.02261 7.97387 2.25892L7.90787 2.28368L6.57454 2.78367L6.45151 2.82978C5.32246 3.25282 4.59661 3.52479 4.0495 4.00695C3.56723 4.43197 3.19572 4.96805 2.96709 5.56886C2.70774 6.25042 2.70795 7.02556 2.70827 8.23126L2.7083 8.36264L2.7083 9.63944L2.7083 9.71235C2.70825 10.9379 2.70822 11.6383 2.82935 12.2948C3.11734 13.8555 3.90667 15.2797 5.07752 16.351C5.56998 16.8017 6.16398 17.1729 7.20326 17.8224L7.26509 17.861L7.32532 17.8987C7.88265 18.2471 8.24043 18.4708 8.61592 18.6109C9.50855 18.9441 10.4914 18.9441 11.384 18.611C11.7595 18.4708 12.1173 18.2471 12.6746 17.8987L12.7348 17.861L12.7967 17.8224C13.8359 17.1729 14.4299 16.8017 14.9224 16.351C16.0933 15.2797 16.8826 13.8555 17.1706 12.2948C17.2917 11.6383 17.2917 10.9379 17.2916 9.71234L17.2916 9.63944L17.2916 8.36264L17.2917 8.23126C17.292 7.02556 17.2922 6.25042 17.0328 5.56886C16.8042 4.96805 16.4327 4.43197 15.9504 4.00695C15.4033 3.52479 14.6775 3.25282 13.5484 2.82978L13.4254 2.78367L12.0921 2.28368L12.0261 2.25892C11.3962 2.02261 10.9902 1.87029 10.5661 1.809ZM13.3586 7.97469C13.6026 8.21877 13.6026 8.61449 13.3586 8.85857L9.60857 12.6086C9.36449 12.8526 8.96877 12.8526 8.72469 12.6086L7.05802 10.9419C6.81394 10.6978 6.81394 10.3021 7.05802 10.058C7.3021 9.81394 7.69783 9.81394 7.9419 10.058L9.16663 11.2827L12.4747 7.97469C12.7188 7.73061 13.1145 7.73061 13.3586 7.97469Z"
      :fill="active ? '#389E0D' : '#000000'"
    />
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
  },
};
</script>
