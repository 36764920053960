/* eslint-disable @typescript-eslint/no-explicit-any */
const REPOSITORY_KEY = "jssip";
const TIME_KEY = "times";

interface JssipStorage {
  repository: Storage;
}

class JssipStorage {
  constructor() {
    this.repository = localStorage;
  }

  get() {
    const storage = this.repository.getItem(REPOSITORY_KEY);
    return JSON.parse(storage as string) || null;
  }

  /**
   *
   * @param {object} data Webphone data needed for calls.
   * @param {string} data.name Receiver name.
   * @param {string} data.photo Receiver optional photo.
   * @param {string} data.number Receiver cellphone number.
   * @param {number} data.ticket Call ticket identifier.
   * @param {number} data.channel Call channel identifier.
   * @param {number} data.time_acw Call acw time.
   */
  save(data: any) {
    const currentData = this.get();
    const storage = JSON.stringify({ ...currentData, ...data });
    this.repository.setItem(REPOSITORY_KEY, storage);
  }

  clear() {
    this.repository.removeItem(REPOSITORY_KEY);
  }

  getAcwTimes() {
    const data = this.repository.getItem(TIME_KEY);
    if (!data) return [];
    return JSON.parse(data);
  }

  addAcwTime() {
    const now = Math.floor(new Date().getTime() / 1000);
    const times = this.getAcwTimes();
    times.push(now);
    this.repository.setItem(TIME_KEY, JSON.stringify(times));
  }

  clearAcwTime() {
    this.repository.removeItem(TIME_KEY);
  }
}

export default new JssipStorage();
