export default [
  "add",
  "calling",
  "decline",
  "hash",
  "incoming",
  "missed",
  "muted",
  "none",
  "numbers",
  "outcomming",
  "phone",
  "recieved",
  "voicemail",
  "mic",
  "micno",
  "pause",
  "forwarded",
  "video",
  "pause_active",
];
