export default [
  {
    path: "sequences",
    component: () =>
      import(
        /* webpackChunkName: "sequences-page" */ "@/app/flows/sequences/pages/SequencesPage.vue"
      ),
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "sequences-list" */ "@/app/flows/sequences/templates/SequencesListTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: ":id",
        component: () =>
          import(
            /* webpackChunkName: "sequences-details" */ "@/app/flows/sequences/templates/SequenceDetailTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
