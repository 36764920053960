<template>
  <div class="success">
    <img src="@/app/channels/assets/bomb.png" alt="Imagen de Error" class="success__image" />
    <h2>¡Oh no!</h2>

    <div class="success__body">
      <p class="success__title">Algo salio mal con la solicitud</p>
    </div>

    <p class="success__description">Por favor vulve a intentar el proceso</p>

    <a-button @click="handleBack"> Volver a canales </a-button>
  </div>
</template>

<script>
export default {
  props: {
    textComplete: {
      type: Object,
      required: true,
    },
  },
  methods: {
    handleBack() {
      this.$emit("remove-listener");
      this.$router.push("/channels/board");
    },
  },
};
</script>

<style lang="sass" scoped>

.success
  border: $border-medium
  border-radius: 4px
  box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15)
  padding: 64px
  max-width: 500px
  margin: 80px auto
  display: flex
  flex-direction: column
  align-items: center

  &__image
    display: block

  &__body
    display: flex
    align-items: center

  &__title
    font-size: 20px
    font-weight: 600

  &__description
    margin-bottom: 24px
</style>
