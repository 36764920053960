import httpManager from "@/app/shared/utils/httpManager";

/**
 * Editar un telefono
 *
 * @param {Object} phone Datos del telefono a editar
 *
 * @return {Promise<Object>} Datos del telefono editado
 */
export async function updatePhonesClient(id, phone) {
  const response = await httpManager.put(`/workspace/tickets/phones/${id}/`, phone);
  return response?.data;
}
