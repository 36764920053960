export const SET_DATA_USER = "SET_DATA_USER";
export const REMOVE_DATA_USER = "REMOVE_DATA_USER";
export const SET_DATA_MINUTES_EXPIRE = "SET_DATA_MINUTES_EXPIRE";
export const SET_DATA_USER_STATE = "SET_DATA_USER_STATE";
export const SET_GLOBAL_STATE = "SET_GLOBAL_STATE";
export const SET_ALLOW_SEND_TEMPLATE = "SET_ALLOW_SEND_TEMPLATE";

// Notifications
export const INCREMENT_UNREAD_NOTIFICATIONS_COUNT = "INCREMENT_UNREAD_NOTIFICATIONS_COUNT";
export const DECREMENT_UNREAD_NOTIFICATIONS_COUNT = "DECREMENT_UNREAD_NOTIFICATIONS_COUNT";
export const READ_ALL_NOTIFICATIONS = "READ_ALL_NOTIFICATIONS";
