<template>
  <div class="email">
    <a-modal
      :open="open"
      title="Resolver correo"
      wrapClassName="resolve"
      @ok="handleOk"
      @cancel="handleClose"
      destroyOnClose
      :maskClosable="false"
      width="434px"
      :footer="null"
    >
      <h4>Comentario</h4>
      <a-textarea v-model:value="content" placeholder="Escribir aquí" />
      <footer>
        <a-button @click="handleClose">Cancelar</a-button>
        <a-button type="primary" @click="handleOk" :disabled="isEnabledButton()">Resolver</a-button>
      </footer>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { message } from "ant-design-vue";
import { emailService } from "@/app/emails/services/Email.service";
import { useRoute } from "vue-router";

const props = defineProps<{ open: boolean; multiple: boolean; emailsIds?: number[] }>();

const content = ref<string>("");
const emit = defineEmits<{ "update:open": [open: boolean]; update: [resolved: boolean] }>();

const isEnabledButton = () => {
  if (content.value.length > 1) return false;
  return true;
};

const route = useRoute();

const handleOk = async () => {
  const emailId = Number(route.query.emailId);

  if (props.multiple) {
    if (!props.emailsIds) return;

    await emailService.massiveResolve(props.emailsIds, content.value);
  } else {
    await emailService.editThreadEmail(emailId, {
      email_thread_status: 3,
      comment_resolved: content.value,
    });
  }

  emit("update:open", false);
  emit("update", true);
  message.success("Se ha resuelto el correo con éxito");
};
const handleClose = () => {
  emit("update:open", false);
};
</script>
<style lang="sass" scoped>
.resolve
  h4
    padding-top: 10px
  textarea
    height: 268px
  footer
    padding: 20px 0 5px 0
    display: flex
    justify-content: flex-end
    gap: 10px
</style>
