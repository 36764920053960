<template>
  <div class="section">
    <a-form class="form" layout="vertical" ref="formRef" :model="formState" :rules="rules">
      <a-form-item class="form__item" label="Nombre del plan de marcación" name="name">
        <a-input v-model:value="formState.name" placeholder="Escribir nombre del plan" />
      </a-form-item>
      <a-form-item class="form__item" label="Troncal" name="trunk">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          v-model:value="formState.trunk"
          placeholder="Seleccione"
          @change="handleSelecTrunk"
          :disabled="disabledTrunk"
        >
          <a-select-option
            v-for="trunk in trunks"
            :key="trunk.id"
            :disabled="trunk.dialing_plans?.length"
          >
            {{ trunk.name }}
            {{ trunk.dialing_plans?.length ? " (Troncal utilizada)" : "" }}
          </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item class="form__item" label="País" name="country">
        <selector-country v-model:value="formState.country" :disabled="disabledCountry" />
      </a-form-item>

      <a-form-item label="Tiempo de timbrado" name="ringing_time">
        <a-space :size="8">
          <a-input-number v-model:value="formState.ringing_time" :maxlength="2" placeholder="00" />
          <div class="stamped__time">
            <span class="ringingtext"> seg</span>
          </div>
        </a-space>
      </a-form-item>
    </a-form>
  </div>
</template>

<script>
import { defineComponent, ref, reactive } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";

export default defineComponent({
  components: {
    SelectorCountry,
  },
  props: {
    update: {
      type: Boolean,
      default: false,
    },
    record: {
      type: Object,
      required: false,
    },
    disabledTrunk: {
      type: Boolean,
      default: false,
    },
    disabledCountry: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { expose }) {
    const formRef = ref();
    const formState = reactive({
      name: "",
      trunk: undefined,
      country: 1,
      ringing_time: 0,
    });

    const rules = {
      name: [{ required: true, message: "Campo requerido" }],
      trunk: [{ required: true, message: "Campo requerido" }],
      country: [{ required: true, message: "Campo requerido" }],
      ringing_time: [{ required: true, message: "Campo requerido" }],
    };
    const selectTrunk = ref(props.update ? props.record.trunk : "");

    const onSubmit = (callback) => {
      formRef.value
        .validate()
        .then((values) => {
          callback({ ...formState, ...values });
        })
        .catch((error) => {
          console.error("[submit]", error);
        });
    };

    const onSetValues = (values) => {
      Object.assign(formState, values);
    };

    const onGetValues = () => {
      return formState;
    };

    const onResetValues = () => {
      formRef.value.resetFields();
    };

    expose({ onSubmit, onSetValues, onGetValues, onResetValues });

    return {
      rules,
      formRef,
      formState,
      selectTrunk,
    };
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {
    trunks() {
      return this.$store.getters["getTrunkList"];
    },
  },
  watch: {
    selectTrunk: {
      immediate: true,
      handler(value) {
        if (value) {
          this.$store.dispatch("addPricesTrunk", value);
        }
      },
    },
  },
  mounted() {
    this.$store.dispatch("fetchAllTrunks");
  },

  methods: {
    handleCancel() {
      this.$emit("handleCancel");
    },
    handleSelecTrunk(value) {
      this.selectTrunk = value;
      this.$emit("onChangeTrunk", value);
    },
    handleChangeName(event) {
      this.name = event.target.value;
    },
  },
});
</script>

<style lang="sass" scoped>
.section
  width: 100%
  padding: 12px 24px
  .form
    margin: 0 auto
    display: flex
    flex-wrap: wrap
    align-content: flex-start
    justify-content: space-between
    &__item
      width: 100%
  .ringingtext
    font-size: $font-normal
    line-height: 22px
    color: $gray-9
  .footer
    position: absolute
    left: 0
    bottom: 0
    width: 100%
    height: 56px
    display: flex
    align-items: center
    justify-content: flex-end
    &__next
      margin-right: 1rem
    &__back
      margin-right: 1rem
.countername
  bottom: 8px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6

.stamped__time
  padding-top: 2px
</style>
