<template>
  <CustomScrollbar height="100%">
    <a-calendar @select="handleOpenModal" :disabledDate="disabledDate">
      <template #headerRender="{ value, onChange }">
        <div class="calendar__options">
          <a-row type="flex" justify="space-between" align="bottom">
            <a-col>
              <a-row :gutter="24" type="flex" justify="space-between">
                <a-col>
                  <p class="title__calendar">Seleccione fechas</p>
                  <a-row type="flex" class="options-date">
                    <a-col>
                      <a-select
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        :dropdown-match-select-width="false"
                        class="my-year-select"
                        :value="String(value.year())"
                        @change="
                          (newYear) => {
                            updateYear(newYear);
                            onChange(value.clone().year(newYear));
                          }
                        "
                      >
                        <a-select-option
                          v-for="val in getYears(value)"
                          :key="String(val)"
                          class="year-item"
                        >
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                    <a-col>
                      <a-select
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                        :dropdown-match-select-width="false"
                        :value="String(value.month())"
                        @change="
                          (selectedMonth) => {
                            updateMonth(selectedMonth);
                            onChange(value.clone().month(parseInt(selectedMonth, 10)));
                          }
                        "
                      >
                        <a-select-option
                          v-for="(val, index) in getMonths(value)"
                          :key="String(index)"
                          class="month-item"
                        >
                          {{ val }}
                        </a-select-option>
                      </a-select>
                    </a-col>
                  </a-row>
                </a-col>
                <a-col>
                  <p class="title__calendar">Estados de los recordatorios</p>
                  <div>
                    <div class="container__legend">
                      <div class="legend">
                        <div class="agenda__status block--yellow"></div>
                        <span>Pendientes</span>
                      </div>
                      <div class="legend">
                        <div class="agenda__status block--green"></div>
                        <span>Completados</span>
                      </div>
                      <div class="legend">
                        <div class="agenda__status block--red"></div>
                        <span>Vencidos</span>
                      </div>
                    </div>
                  </div>
                </a-col>
              </a-row>
            </a-col>
            <a-col>
              <a-button type="primary" @click="handleOpenModal">
                <PlusOutlined />
                Crear nuevo recordatorio
              </a-button>
            </a-col>
          </a-row>
        </div>
      </template>
      <template #dateCellRender="{ current: value }">
        <ul class="events">
          <li class="event__item" v-for="item in getListData(value)" :key="item.content">
            <PopoverShedule :item="item" @update="fetchReminders" />
          </li>
        </ul>
      </template>
    </a-calendar>
  </CustomScrollbar>
  <AddNewReminder
    ref="editDiaplan"
    :visible="visible"
    :selected-date="selectedDate"
    @handleCloseModal="handleCloseModal"
  />
</template>
<script>
import { defineComponent, ref, computed, onMounted, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import dayjs from "dayjs";
import { PlusOutlined } from "@ant-design/icons-vue";
import PopoverShedule from "@/app/schedule/components/modal/PopoverShedule.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { dateToTenantTime } from "@/app/shared/utils/dates";
import { dateIsLessThan } from "@/app/schedule/utils/dates";
import AddNewReminder from "@/app/schedule/components/modal/AddNewReminder.vue";

import { disabledDate } from "@/app/shared/utils/dates";

export default defineComponent({
  setup() {
    const store = useStore();
    const selectedDate = ref();
    const visible = ref(false);

    const userData = computed(() => store.getters["getDataUser"]);

    const tenantTime = computed(() => {
      const date = new Date();
      const UTC = userData.value?.customer?.data_time?.utc;

      date.setHours(date.getUTCHours() + Number(UTC?.replaceAll("0", "")));
      return date;
    });

    const getMonths = (value) => {
      // const current = value.clone();
      // const localeData = value.localeData();
      // const months = [];

      // for (let i = 0; i < 12; i++) {
      //   current.month(i);
      //   months.push(localeData.monthsShort(current));
      // }
      // return months;
      const localeData = value.localeData();
      const months = [];
      for (let i = 0; i < 12; i++) {
        months.push(localeData.monthsShort(value.month(i)));
      }
      return months;
    };

    const reminders = computed(() => store.getters["getReminders"]);

    const getYears = (value) => {
      // const year = value.year();
      // const years = [];

      // for (let i = year - 10; i < year + 10; i += 1) {
      //   years.push(i);
      // }
      // return years;
      const year = value.year();
      const years = [];
      for (let i = year - 10; i < year + 10; i += 1) {
        years.push(i);
      }
      return years;
    };

    const handleOpenModal = (value, info) => {
      console.log(info);
      if (!info) {
        visible.value = true;
        return;
      }

      if (info.source !== "date") return;

      if (value.date) {
        selectedDate.value = dateToTenantTime(value, userData.value?.customer?.timezone);
      } else {
        selectedDate.value = undefined;
      }
      visible.value = true;
    };

    const handleCloseModal = () => {
      visible.value = false;
    };

    const getItemColor = (status, date, time) => {
      let item = "";

      if (status == 4) {
        // Finalizado
        item = "#52C41A";
      } else if (dateIsLessThan(date, time, tenantTime.value)) {
        // Vencido
        item = "#FF4D4F";
      } else {
        // Pendiente
        item = "#FFC53D";
      }

      return item;
    };

    const getListData = (value) => {
      const valueMonth = value.month() + 1; // Mes
      const month = `${valueMonth}`.length == 2 ? `${valueMonth}` : `0${valueMonth}`; // Agregar 0 delante al mes
      const valueDay = value.date(); // Dia
      const day = `${valueDay}`.length == 2 ? `${valueDay}` : `0${valueDay}`; // Agregar 0 delante al dia
      const valueDate = value.year() + "-" + month + "-" + day;
      let schedules = [];

      if (valueDate) {
        /** Filtra los eventos del día, mes y año correspondiente */
        const scheduleFiltered = reminders.value.filter((sch) => sch.date_schedule == valueDate);
        schedules = scheduleFiltered.map((scheduleItem) => {
          const hour_schedule = scheduleItem.time_schedule.split(" ")[1];

          return {
            ...scheduleItem,
            color: getItemColor(scheduleItem.status, scheduleItem.date_schedule, hour_schedule),
            dateTime: scheduleItem.date_schedule + " " + hour_schedule,
          };
        });
      }
      return schedules.sort((a, b) =>
        a.dateTime > b.dateTime ? 1 : a.dateTime < b.dateTime ? -1 : 0
      );
    };

    const currentDate = computed(() => {
      const date = dayjs(tenantTime.value);

      return {
        year: date.year(),
        month: date.month() + 1,
      };
    });

    const scheduleFilters = ref({
      year: undefined,
      month: undefined,
    });

    const updateYear = (newYear) => {
      scheduleFilters.value.year = Number(newYear);
    };

    const updateMonth = (selectedMonth) => {
      scheduleFilters.value.month = Number(selectedMonth) + 1;
    };

    const fetchReminders = async () => {
      await store.dispatch("fetchAllReminder", scheduleFilters.value);
    };

    watch(
      currentDate,
      () => {
        scheduleFilters.value.year = currentDate.value.year;
        scheduleFilters.value.month = currentDate.value.month;
      },
      { immediate: true }
    );

    watchEffect(() => {
      if (!scheduleFilters.value.month) return;

      store.dispatch("fetchAllReminder", scheduleFilters.value);
    });

    // watch(scheduleFilters, () => fetchReminders());

    // onMounted(() => {
    //   fetchReminders();
    // });

    return {
      getMonths,
      getYears,
      visible,
      selectedDate,
      handleOpenModal,
      handleCloseModal,
      getListData,
      disabledDate,
      scheduleFilters,
      tenantTime,
      updateYear,
      updateMonth,
      fetchReminders,
    };
  },
  components: {
    CustomScrollbar,
    PopoverShedule,
    AddNewReminder,
    PlusOutlined,
  },
});
</script>

<style lang="sass" scoped>
.success__alert
  position: absolute
  z-index: 2
  left: 50%
  transform: translate(-50%, -50%)
  top: 10px

.calendar__options
  position: sticky
  padding: 10px
  top: 0
  background-color: $white
  z-index: 5
  height: 86px

.events
  list-style: none
  margin: 0
  padding: 0
  cursor: pointer

.ant-btn
  padding: 0 46px

:deep(.ant-fullcalendar-fullscreen .ant-fullcalendar-content)
  overflow-x: hidden

:deep(.ant-picker-cell-disabled)
  cursor: not-allowed
  pointer-events: all
</style>
<style>
.legend {
  display: flex !important;
  flex-direction: row !important;
  margin: 0 0 8px 0 !important;
  align-items: center !important;
  margin-right: 20px !important;
  margin-top: 4px !important;
}

.event__item {
  height: 20px !important;
  margin-bottom: 4px !important;
}

.agenda__status {
  width: 12px;
  height: 12px;
  margin-right: 8px;
  border-radius: 100%;
}
.block--yellow {
  background-color: #fadb14;
}
.block--red {
  background-color: #f5222d;
}
.block--green {
  background-color: #52c41a;
}
.options-date {
  gap: 16px;
}
.container__legend {
  display: flex;
}
.title__calendar {
  font-weight: 700;
  margin-bottom: 10px;
}
</style>
