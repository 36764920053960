import { TabKeyDialPlan, DialRule } from "@/@types/pbx/dialPlan";

const STEPS_DIAL_PLAN_CREATE = [
  {
    title: "Datos generales",
    title_section: "Datos generales del plan de marcación",
    buttons: {
      cancel: "Visualizar con preconfiguración",
      ok: "Configurar regla de marcado",
    },
    is_first: true,
  },
  {
    title: "Regla de marcado",
    title_section: "Configura la regla de marcado",
    buttons: {
      cancel: "Volver",
      ok: "Agregar",
    },
    is_last: true,
  },
];

const TABS_DIAL_PLAN_EDIT = {
  [TabKeyDialPlan.General]: {
    title: "Datos generales",
    title_section: "Datos generales del plan de marcación",
    key: TabKeyDialPlan.General,
    buttons: {
      cancel: "Cancelar",
      ok: "Guardar configuración general",
    },
  },
  [TabKeyDialPlan.Rules]: {
    title: "Regla de marcado",
    title_section: "Configura la regla de marcado",
    key: TabKeyDialPlan.Rules,
    buttons: {
      cancel: "Cancelar",
      ok: "Guardar reglas de marcado",
    },
    is_last: true,
  },
};

const DEFAULT_RULES_FOR_SEC: DialRule[] = [
  {
    name: "Peru Movil",
    rule: "9XXXXXXXX",
    prefix_dial: "51",
    prefix_provider: "",
  },
  {
    name: "Peru Fijo 1",
    rule: "[2-7]XXXXXX",
    prefix_dial: "51",
    prefix_provider: "",
  },
  {
    name: "Peru Fijo 2",
    rule: "[2-7]XXXXXX",
    prefix_dial: "511",
    prefix_provider: "",
  },
  {
    name: "Peru Fijo 3",
    rule: "[2-7]XXXXXX",
    prefix_dial: "5101",
    prefix_provider: "",
  },
  {
    name: "Peru Provincia 1",
    rule: "NZXXXXXX",
    prefix_dial: "51",
    prefix_provider: "",
  },
  {
    name: "Peru Provincia 2",
    rule: "NZXXXXXX",
    prefix_dial: "510",
    prefix_provider: "",
  },
];

export { STEPS_DIAL_PLAN_CREATE, TABS_DIAL_PLAN_EDIT, DEFAULT_RULES_FOR_SEC };
