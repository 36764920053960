<template>
  <div class="preview-container">
    <div v-if="file.inputType === 'file'" class="file__container">
      <div class="file__data">
        <component class="icon" :is="getIconByType(file)"></component>

        <a-tooltip :title="formatedFile.name">
          <span class="file__name">
            {{ formatedFile.name }}
          </span>
        </a-tooltip>

        <a-tooltip title="Descargar" v-if="canDownload">
          <a-button class="button" type="link" @click="emit('download', formatedFile.src)">
            <DownloadOutlined class="icon" />
          </a-button>
        </a-tooltip>
      </div>
      <a-tooltip title="Borrar" v-if="canDelete">
        <a-button class="button" type="text" size="small" @click="emit('remove', position)">
          <CloseOutlined />
        </a-button>
      </a-tooltip>
    </div>

    <div v-else class="media">
      <img v-if="file.inputType === 'image'" :src="formatedFile.src" :alt="formatedFile.name" />

      <video v-if="file.inputType === 'video'">
        <source :src="formatedFile.src" type="video/mp4" />
      </video>
    </div>

    <a-tooltip title="Borrar" v-if="canDelete && file.inputType !== 'file'">
      <a-button class="button" type="text" @click="emit('remove', position)">
        <DeleteOutlined class="icon" />
      </a-button>
    </a-tooltip>
  </div>
</template>

<script setup lang="ts">
import {
  DeleteOutlined,
  DownloadOutlined,
  FileOutlined,
  FilePdfOutlined,
  FileWordOutlined,
  FileExcelOutlined,
  FilePptOutlined,
  FileTextOutlined,
  CloseOutlined,
} from "@ant-design/icons-vue";

import type { FileMetadata } from "@/@types/workspace";
import { computed } from "vue";

const props = defineProps<{
  file: FileMetadata;
  canDelete?: boolean;
  canDownload?: boolean;
  workspace?: boolean;
  position?: number;
}>();

const emit = defineEmits<{
  remove: [postion?: number];
  download: [fileSrc: string];
}>();

const getIconByType = (file: FileMetadata) => {
  const hasExtension = (extensions: string[]) => {
    const stringToEval = typeof file.file === "string" ? file.file : file.file?.type;

    return extensions.some((ext) => stringToEval?.includes(ext));
  };

  if (hasExtension(["pdf"])) return FilePdfOutlined;
  if (hasExtension(["word", "doc"])) return FileWordOutlined;
  if (hasExtension(["excel", "sheet", "csv", "xls"])) return FileExcelOutlined;
  if (hasExtension(["powerpoint", "presentation", "ppt"])) return FilePptOutlined;
  if (hasExtension(["text"])) return FileTextOutlined;

  return FileOutlined;
};

const formatedFile = computed(() => {
  const file = props.file.file;
  const fileType = props.file.inputType;

  console.log(props.file.file, 'props.file.file');
  const getNameFromUrl = (fileUrl: string) => {
    const filePath = fileUrl.split("/").at(-1);

    if (!filePath) return "Documento";

    if (!props.workspace) return filePath;

    const [hash, ...encodedFileName] = filePath.split("-");

    return encodedFileName ? decodeURI(encodedFileName.join("-")) : filePath;
  };

  const fileName = typeof file === "string" ? getNameFromUrl(file) : file?.name;

  const fileData = { src: typeof file === "string" ? file : "", name: fileName };

  if (fileType !== "file" && !fileData.src && file) {
    if (typeof file !== "string") fileData.src = URL.createObjectURL(file);
  }

  return fileData;
});
</script>

<style lang="sass" scoped>
.file
  &__container
    max-width: 100%
    min-width: 0
    height: 24px
    display: flex
    align-items: center
    justify-content: space-between
    color: $gray-9
    padding: 0 12px
    border-radius: 10px
    background-color: #F3FBFF
    min-height: 40px

  &__data
    display: flex
    align-items: center
    justify-content: center
    gap: 8px
    min-width: 0

  &__name
    overflow: hidden
    white-space: nowrap
    text-overflow: ellipsis
    max-width: 310px

.preview-container
  width: fit-content
  max-width: 100%
  min-width: 0
  // width: 100%
  display: flex
  align-items: center
  gap: 10px

.media
  height: 95px
  width: fit-content
  overflow: hidden
  border-radius: 8px
  border: 1px solid $gray-5

  img, video
    height: 100%

.button
  padding: 0
  border-radius: 4px
  width: 22px
  height: 22px

.icon
  font-size: 18px

.file-preview-chat-container
  .preview-container
    min-width: 100%

  .file__container
    min-width: 100%
</style>
