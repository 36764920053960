import httpManager from "@/app/shared/utils/httpManager";
import { ResponseFetchManageVars } from "@/@types/manageVars";
import { Pagination } from "@/@types/global";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

/**
 * Obtener lista de variables
 * @return {Promise<ResponseFetchManageVars>}
 */
export async function fetchVars({
  search = "",
  pagination = { current: 1, pageSize: TABLE_PAGE_SIZE },
}: {
  search: string;
  pagination?: Pagination;
}): Promise<ResponseFetchManageVars> {
  try {
    const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
    const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;
    const response = await httpManager.get(
      `variables?offset=${offset}&limit=${pageSize}&search=${search}`
    );
    return { success: true, data: response?.data };
  } catch (error) {
    return { success: false, message: "Ocurrió un error" };
  }
}
