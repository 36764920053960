<template>
  <div class="workspace__panel" id="workspace-third-panel" v-if="!hasTicket">
    <div class="information__ticket">
      <img src="@/app/workspace/assets/workspace3.png" alt="workspace" />
      <p>3. Selecciona un ticket para mostrar la información</p>
    </div>
  </div>

  <div class="workspace__panel no-contact" v-else-if="!hasClient">
    <NoClientSection hasIvrVarSection />
  </div>

  <section class="workspace__panel group" v-else>
    <ClientCard
      :itemId="clientId"
      :campaignName="campaign"
      :portfolio="clientPortfolio"
      :isWorkspace="true"
    />
  </section>

  <!-- <section class="workspace__panel group" v-else>
    <header class="header">
      <contact-info-card />
    </header>

    <a-tabs v-model:activeKey="currentTab" class="client-tabs" type="card" centered>
      <a-tab-pane :key="ContactDetailsTabs.Personal">
        <template #tab>
          <div class="tab-content">
            <IconCrm in-tab :active="currentTab === ContactDetailsTabs.Personal" />
            Personal
          </div>
        </template>

        <WorkspaceClientCard />
      </a-tab-pane>

      <a-tab-pane :key="ContactDetailsTabs.Product">
        <template #tab>
          <div class="tab-content">
            <IconCredicard in-tab :active="currentTab === ContactDetailsTabs.Product" />
            Producto
          </div>
        </template>

        <WorkspaceProductsCard v-if="isCollection" />
        <ProductDataTable v-else class="client-prod-table" />
      </a-tab-pane>

      <a-tab-pane :key="ContactDetailsTabs.Ticket">
        <template #tab>
          <div class="tab-content">
            <IconTickets in-tab :active="currentTab === ContactDetailsTabs.Ticket" />
            Ticket
          </div>
        </template>

        <WorkspaceTypificationsCard />
      </a-tab-pane>

      <a-tab-pane :key="ContactDetailsTabs.Email">
        <template #tab>
          <div class="tab-content">
            <IconEmail in-tab :active="currentTab === ContactDetailsTabs.Email" />
            Correo
          </div>
        </template>
        <EmailCard :ticketId="idTkt" />
      </a-tab-pane>
    </a-tabs>
  </section> -->
</template>

<script>
import { inject, ref, computed, watch } from "vue";
import { useStore } from "vuex";

import { ContactDetailsTabs } from "@/app/workspace/utils/enums";
import { OperationType } from "@/@types/entities/portfolios";

// import WorkspaceClientCard from "@/app/workspace/components/cards/WorkspaceClientCard.vue";
// import WorkspaceProductsCard from "@/app/workspace/components/cards/WorkspaceProductsCard.vue";
// import WorkspaceTypificationsCard from "@/app/workspace/components/cards/WorkspaceTypificationsCard.vue";
// import ContactInfoCard from "@/app/workspace/components/cards/ContactInfoCard.vue";
// import EmailCard from "@/app/workspace/components/cards/EmailCard.vue";

import NoClientSection from "@/app/workspace/components/sections/NoClientSection.vue";
// import IconCredicard from "@/app/common/components/atoms/IconCredicard.vue";
// import IconCrm from "@/app/common/components/atoms/IconCrm.vue";
// import IconTickets from "@/app/common/components/atoms/IconTickets.vue";
// import IconEmail from "@/app/common/components/atoms/IconEmailTab.vue";

import ClientCard from "@/app/contacts/components/drawers/ClientCard.vue";

export default {
  components: {
    // WorkspaceClientCard,
    // WorkspaceProductsCard,
    // WorkspaceTypificationsCard,
    // ContactInfoCard,
    // EmailCard,
    NoClientSection,
    // IconCredicard,
    // IconCrm,
    // IconTickets,
    // IconEmail,
    ClientCard,
  },
  setup() {
    const store = useStore();

    const showBanner = inject("showBanner");
    const currentTab = ref(null);

    // workspaceClient() {
    //   return this.$store.getters["getWorkspaceClient"];
    // },

    const showProductTab = computed(() => {
      const workspaceClient = store.getters["getWorkspaceClient"];
      const hasProductsData = workspaceClient?.products?.length;
      const hasPaymentsData = workspaceClient?.payments?.length;
      const hasPromisesData = workspaceClient?.promises?.length;

      return hasProductsData || hasPaymentsData || hasPromisesData;
    });

    watch(showProductTab, (show) => {
      currentTab.value = show ? ContactDetailsTabs.Product : ContactDetailsTabs.Personal;
    });

    return { ContactDetailsTabs, showBanner, currentTab };
  },
  computed: {
    hasClient() {
      return !!this.$route.query.client;
    },
    hasSpeech() {
      return this.portfolioDetail?.text_negotiation || this.portfolioDetail?.observations;
    },
    hasTicket() {
      return this.$route.query.ticket;
    },
    client() {
      return this.$store.getters["getWorkspaceClient"];
    },
    campaign() {
      return this.client?.data_client[0]?.portfolio?.name;
    },
    clientPortfolio() {
      return this.client?.data_client.at(0)?.portfolio;
    },
  },
  data() {
    return {
      idTkt: this.$route.query.ticket,
      clientId: this.$route.query.client,
    };
  },
  mounted() {
    if (!this.hasClient) return;
    const ticketId = this.$route.query.ticket;
    this.$store.dispatch("fetchWorkspaceClientByTicket", {
      ticketId,
    });
  },
};
</script>

<style lang="sass" scoped>
.header
  padding: 20px 18px
  position: sticky
  top: 0
  background-color: $white
  z-index: 4

.workspace__panel
  background-color: white
  padding: 40px 28px

  .information__ticket
    width: 100%
    height: 100%
    display: flex
    flex-direction: column
    justify-content: center
    align-items: center
    gap: 10px

.no-contact
  padding: 0
  
.group
  padding: 40px 0

.client-tabs
  :deep(.ant-tabs-nav)
    margin: 0

  :deep(.ant-tabs-nav-wrap)
    display: block !important

  :deep(.ant-tabs-nav-list)
    justify-content: space-evenly

  :deep(.ant-tabs-tab)
    min-width: 23%
    padding-top: 14px !important
    padding-bottom: 14px !important
    justify-content: center

    .tab-content
      display: flex
      justify-content: center
      align-items: center
      gap: 10px
      color: $gray-8

    &.ant-tabs-tab-active
      .tab-content
        color: $blue-6

.client-prod-table
  padding: 12px 16px
  border-top: 1px solid #d9d9d9
</style>
