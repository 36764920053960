export default [
  {
    path: "/integrations",
    component: () =>
      import(
        /* webpackChunkName: "integrations-page" */ "@/app/integrations/pages/IntegrationsPage.vue"
      ),
    children: [
      {
        path: "",
        component: () =>
          import(
            /* webpackChunkName: "integrations-list" */ "@/app/integrations/components/templates/IntegrationsListTemplate.vue"
          ),
      },
    ],
    meta: { requiresAuth: true },
  },
];
