import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear un nuevo canal
 *
 * @param {Object} channel Datos del canal
 *
 * @return {Promise<Object>} Datos del canal a crear
 */

export default async function (channel) {
  const response = await httpManager.post("/channels/channel-customer/", channel);
  return response;
}
