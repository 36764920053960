import trunkStore from "@/app/pbx/store/trunks";
import dialplanStore from "@/app/pbx/store/dialplans";
import didStore from "@/app/pbx/store/dids";
import codecStore from "@/app/pbx/store/codecs";

export default {
  modules: {
    trunkStore,
    dialplanStore,
    didStore,
    codecStore,
  },
};
