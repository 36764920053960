import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear una nueva capaña
 *
 * @param {Object} portfolio Datos de la campaña
 *
 * @return {Promise<Object>} Datos de la campaña creada
 */
export async function createNewPortfolio(portfolio) {
  const body = portfolio;
  if (!body.is_enable_time_acw) delete body.time_acw;
  if (!body.is_enable_call_time) delete body.call_time;
  if (!body.description) delete body.description;
  body.text_negotiation = body.text_negotiation || "Sin speech";
  const response = await httpManager.post("/portfolios/", body);
  return response.data;
}
