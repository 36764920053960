<template>
  <section class="section">
    <tickets-filters @update:ticket="handleUpdateTicket" />
    <workspace-ticket-list key="workspace-ticket-list" />
    <SendWhatsappTemplateModalVue v-if="showButton" floating />
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, inject } from "vue";
import { useStore } from "vuex";

import type { Ticket } from "@/@types/global";

import WorkspaceTicketList from "@/app/workspace/components/lists/WorkspaceTicketList.vue";
import TicketsFilters from "@/app/workspace/components/filters/TicketsFilters.vue";
import SendWhatsappTemplateModalVue from "@/app/workspace/components/modal/SendWhatsappTemplateModal.vue";

export default defineComponent({
  components: {
    WorkspaceTicketList,
    TicketsFilters,
    SendWhatsappTemplateModalVue,
  },
  setup(props, { emit }) {
    const store = useStore();

    const ticket = inject<Ticket>("selectedTicket");

    const showButton = computed<boolean>(() => {
      const isAllowed: boolean = store.getters["getAllowSendTemplate"];

      return isAllowed;
    });

    const handleUpdateTicket = ({ preventLoading = false } = {}) =>
      emit("update:ticket", { preventLoading });

    return { showButton, handleUpdateTicket, ticket };
  },
  sockets: {
    refreshTickets() {
      this.$store.dispatch("fetchActiveWorkspaceTickets");
      if (!this.ticket?.unread_messages) this.handleUpdateTicket({ preventLoading: true });
    },
    captureTickets() {
      this.$store.dispatch("fetchActiveWorkspaceTickets");
    },
  },
});
</script>

<style lang="sass" scoped>
.section
  background-color: white
  box-shadow: 1px 1px 4px 0px #C5C5C5
  position: relative
  z-index: 1
</style>
