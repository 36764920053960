export const initialState = () => ({
  portfolioName: "",
  typification: [],
  typificationSaved: [],
  isExistingTree: false,
  businessType: undefined,
  typeDefaultManagement: null,
  isTypificationTreeMutating: false,
  lastModification: "",
  forms: [],
  typificationDisabled: false,
});

const state = initialState();

export default state;
