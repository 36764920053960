/* eslint-disable @typescript-eslint/no-unused-vars */
import {
  fetchTypificationByPortfolio,
  pushTypificationToPortfolio,
  updateTypificationToPortfolio,
  deleteTypificationItemByType,
  createTypificationItemByType,
  updateTypificationItemByType,
  updateTypificationOrder,
  fetchFormsTypifications,
  removeForm,
} from "@/app/entities/services";
import { mapToTypifications, mapTypifications, getLastModification } from "./helpers";
import { dateFormat } from "@/app/shared/utils/constants";
import { dateToString } from "@/app/shared/utils/dates";
import {
  RESET_STATE,
  SET_FLAG_TREE,
  SET_PORTFOLIO_NAME,
  SET_TYPIFICATION,
  SET_BUSINESS_TYPE,
  SET_TYPIFICATION_TYPE,
  SET_SAVED,
  UPDATE_ITEM_TO_TYPIFICATION,
  UPDATE_TYPIFICATION_ITEM_BY_STATUS,
  DELETE_TYPIFICATION_ITEM,
  SET_TYPIFICATION_TREE_MUTATION_STATUS,
  SET_LAST_MODIFIED,
  SET_FORMS,
} from "./mutation-types";

const actions = {
  async fetchTypificationByPortfolio({ commit }, portfolioId) {
    const { groups, name, business_type, type_default_management } =
      await fetchTypificationByPortfolio(portfolioId);
    const lastModified = getLastModification(groups);
    const tree = mapTypifications(groups);
    commit(SET_TYPIFICATION, tree);
    commit(SET_SAVED, tree);
    commit(SET_FLAG_TREE, tree);
    commit(SET_PORTFOLIO_NAME, name);
    commit(SET_BUSINESS_TYPE, business_type);
    commit(SET_TYPIFICATION_TYPE, type_default_management);
    commit(SET_LAST_MODIFIED, dateToString(lastModified, dateFormat));
  },
  setTypificationTree({ commit }, typification) {
    commit(SET_TYPIFICATION, typification);
  },
  async savePortfolioTypification({ state }, portfolioId) {
    const tree = mapToTypifications(state.typification);

    if (state.isExistingTree) {
      await updateTypificationToPortfolio(portfolioId, tree);
    } else {
      state.isExistingTree = true;
      await pushTypificationToPortfolio(portfolioId, tree);
    }
  },
  async addItemToTypificationTree({ commit }, item) {
    let payload = { ...item };

    if (payload.type === "group") {
      payload.type = item.group_type;
    }

    // try {
    await createTypificationItemByType({
      payload: payload,
      type: item.type,
    });
    commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
    // commit(ADD_ITEM_TO_TYPIFICATION, { ...item, id: createdItem.id });
    // } catch ({ status, message, errors }) {
    //   if (status === 400) {
    //     commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
    //   }

    //   return { success: false, status };
    // }
    // return { success: true };
  },

  async updateItemToTypificationTree({ commit }, item) {
    let payload = { ...item };

    if (payload.type === "group") {
      payload.type = item.group_type;
    }
    // try {
    return await updateTypificationItemByType({
      payload: payload,
      type: item.type,
    });
    // } catch ({ status }) {
    //   if (status === 400) {
    //     commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
    //     return false;
    //   }
    //   commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
    // }
  },

  async deleteTypificationItemByType(
    { commit },
    { groupId, resultId, motiveId, submotiveId, type, id }
  ) {
    commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, true);
    await deleteTypificationItemByType({ id, type });
    commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
    commit(DELETE_TYPIFICATION_ITEM, { groupId, resultId, motiveId, submotiveId });
  },
  async updateTypificationItemByStatus(
    { commit },
    { groupId, resultId, motiveId, submotiveId, status }
  ) {
    commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, true);
    commit(UPDATE_TYPIFICATION_ITEM_BY_STATUS, {
      groupId,
      resultId,
      motiveId,
      submotiveId,
      status,
    });
    commit(SET_TYPIFICATION_TREE_MUTATION_STATUS, false);
  },
  async updateTypificationOrder({ commit }, { portfolioId, payload }) {
    await updateTypificationOrder(portfolioId, payload);
    commit(RESET_STATE);
    const { groups, name, business_type, type_default_management } =
      await fetchTypificationByPortfolio(portfolioId);
    const tree = mapTypifications(groups);
    commit(SET_TYPIFICATION, tree);
    commit(SET_SAVED, tree);
    commit(SET_FLAG_TREE, tree);
    commit(SET_PORTFOLIO_NAME, name);
    commit(SET_BUSINESS_TYPE, business_type);
    commit(SET_TYPIFICATION_TYPE, type_default_management);
  },
  updateTypificationTree({ commit }, tree) {
    commit(SET_TYPIFICATION, tree);
  },
  async getFormsTypifications({ commit }) {
    let response = await fetchFormsTypifications();
    commit(SET_FORMS, response.data.results);
  },
  async removeForm({ commit }, { type, id }) {
    await removeForm({ id, type });
  },
};

export default actions;
