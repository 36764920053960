const initialState = () => ({
  reportTypifications: null,
  reportManagement: null,
  reportTimePlatform: null,
  commonReports: null,
  detailReportTime: null,
});

const state = initialState();

export default state;
