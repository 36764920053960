import httpManager from "../../../utils/httpManager";

export async function fetchCustomerChannels({ channel = "", toSequence = false, portfolioId = null } = {}) {
  const baseUrl = "/channels/channel-customer/";

  let url = `${baseUrl}?channel=${channel}&to_sequence=${toSequence}`;
  if (portfolioId)  url += `&portfolio=${portfolioId}`;

  const response = await httpManager.get(url);
  return response.data || [];
}
