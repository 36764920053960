export const WebPhoneState = {
  closed: "closed",
  minimized: "minimized",
  expanded: "expanded",
};

export const WebPhoneActionNames = {
  Keyboard: "Teclado",
  Transfer: "Transferir",
};
