<template>
  <div style="margin: 0 auto">
    <section class="notifications container">
      <custom-scrollbar height="calc(100vh - 110px)">
        <div style="padding: 16px 0">
          <template v-if="todayNotifications.length !== 0">
            <h2>Hoy</h2>

            <notification-card
              v-for="notification in todayNotifications"
              :key="notification.id"
              :notificationData="notification"
            />
          </template>

          <template v-if="pastNotifications.length !== 0">
            <h2 style="margin-top: 16px">Pasados</h2>

            <notification-card
              v-for="notification in pastNotifications"
              :key="notification.id"
              :notificationData="notification"
            />
          </template>
        </div>

        <footer class="footer" v-if="endpointData.pageCount === 50 && endpointData.totalCount > 50">
          <a-button class="footer__button" type="link" @click="fetchNotificationsData">
            Ver más
          </a-button>
        </footer>
      </custom-scrollbar>
    </section>
  </div>
</template>

<script>
import { ref, computed, onMounted } from "vue";
import { useStore } from "vuex";

import { dateToString } from "@/app/shared/utils/dates";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import NotificationCard from "../card/NotificationCard.vue";

export default {
  components: {
    CustomScrollbar,
    NotificationCard,
  },
  setup() {
    const store = useStore();
    const page = ref(1);

    onMounted(async () => await fetchNotificationsData());

    const allNotifications = computed(() => store.getters["getAllNotifications"] || []);
    const endpointData = computed(() => store.getters["getEndpointData"]);

    const currentDay = dateToString(new Date()).substring(0, 10);

    const todayNotifications = computed(() =>
      allNotifications.value.filter((notification) =>
        notification.notification_time.includes(currentDay)
      )
    );

    const pastNotifications = computed(() =>
      allNotifications.value.filter(
        (notification) => !notification.notification_time.includes(currentDay)
      )
    );

    const fetchNotificationsData = async () => {
      await store.dispatch("fetchNotifications", page.value);
      console.log('notification template');
      page.value++;
    };

    return {
      allNotifications,
      endpointData,
      todayNotifications,
      pastNotifications,
      page,
      fetchNotificationsData,
    };
  },
  sockets: {
    refreshNotifications() {
      this.$store.commit("DELETE_NOTIFICATIONS");
      this.page = 1;
      this.fetchNotificationsData();
    },
    refreshNotification() {
      this.$store.commit("DELETE_NOTIFICATIONS");
      this.page = 1;
      this.fetchNotificationsData();
    },
  },
};
</script>

<style lang="sass" scoped>
.notifications
  width: 780px

.action
  text-align: right
  margin-bottom: 16px

.container
  border: 1px solid $gray-5
  border-radius: 4px
  // overflow-y: scroll

h2
  font-size: 16px
  font-weight: 600
  margin-bottom: 0
  margin-left: 20px
  color: $gray-9

.footer
  border-top: 1px solid $gray-5
  padding: 4px 20px

  &__button
    padding: 0
</style>
