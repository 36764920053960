import {
  ADD_PROPERTIES_TO_CUSTOMER,
  ADD_TOKEN,
  REMOVE_TEMPORAL_USER,
  SET_TEMPORAL_USER,
  SET_PROGRESS,
  SET_NEW_USER_INVITE,
  ADD_PROPERTIES_TO_NEW_USER,
  ADD_TOKEN_NEW_USER,
  SET_ZONE_TIME,
  SET_TEMPORAL_FORM,
  SET_REGISTER_STEP,
  SET_REGISTER_WORKSPACE_STEP,
} from "./mutation-types";

const mutations = {
  [SET_TEMPORAL_USER](state, temporalUser) {
    state.temporalUser = temporalUser;
  },
  [SET_PROGRESS](state, step) {
    state.step = step;
  },
  [REMOVE_TEMPORAL_USER](state) {
    state.temporalUser = null;
  },
  [ADD_TOKEN](state, token) {
    state.customer = { ...state.customer, token };
  },
  [ADD_PROPERTIES_TO_CUSTOMER](state, properties) {
    state.customer = { ...state.customer, ...properties };
  },
  [SET_NEW_USER_INVITE](state, newUserInvite) {
    state.newUserInvite = newUserInvite;
  },
  [ADD_PROPERTIES_TO_NEW_USER](state, newProperties) {
    state.newUser = { ...state.newUser, ...newProperties };
  },
  [ADD_TOKEN_NEW_USER](state, token) {
    state.newUser = { ...state.newUser, token };
  },
  [SET_ZONE_TIME](state, zoneTime) {
    state.zoneTime = zoneTime;
  },
  [SET_TEMPORAL_FORM](state, form) {
    state.form = form;
  },
  [SET_REGISTER_STEP](state, step) {
    state.registerStep = step;
  },
  [SET_REGISTER_WORKSPACE_STEP](state, step) {
    state.registerWorkspaceStep = step;
  },
};

export default mutations;
