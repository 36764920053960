import httpManager from "@/app/shared/utils/httpManager";
/**
 * Actualiza el anexo.
 *
 * @return {Promise<string>} Numero de anexo.
 */
export async function activateAnnex(username) {
  const response = await httpManager.post(`/users/annex/enable/`, { username });
  return response?.data?.access;
}
