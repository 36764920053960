<template>
  <navigation-tabs :tabs="tabs">
    <router-view />
  </navigation-tabs>
</template>

<script>
import NavigationTabs from "@/app/shared/components/organisms/NavigationTabs.vue";
import { defineComponent } from "vue";
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

export default defineComponent({
  components: {
    NavigationTabs,
  },
  data() {
    return {
      tabs: [
        { key: "/imports/board", text: "Importaciones" },
        { key: "/imports/templates", text: "Plantillas" },
      ],
    };
  },
  methods: {
    cleanStorage() {
      this.$store.dispatch("setSelectedEntity", 0);
      this.$store.dispatch("setSelectedPortfolio", 0);
      this.$store.commit("SET_PORTFOLIOS", {
        pagination: {},
        items: [],
      });
    },
  },
  created() {
    this.$store.dispatch("clearTemplatePortfolio");
    this.cleanStorage();
    EventBus.$on(WorkspaceEvent.DRAWER_TEMPLATE_OPEN, () =>
      this.$router.push("/imports/templates")
    );
  },
  unmounted() {
    this.cleanStorage();
  },
});
</script>
