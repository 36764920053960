<template>
  <a-modal
    :visible="visible"
    centered
    :closable="false"
    :footer="null"
    :mask-closable="false"
    :keyboard="false"
    :bodyStyle="{ height: '600px' }"
  >
    <CloseOutlined class="iconoCerrado" @click="handleCloseConfirmationModal" />

    <template #title>
      Integración de troncales
      <a-tooltip>
        <template #title>
          Los siguientes datos son brindados por su proveedor de troncal.
        </template>
        <InfoCircleOutlined />
      </a-tooltip>
    </template>
    <div class="formtrunk">
      <!-- <custom-stepper v-model:value="currentStep" :items="steps" /> -->
      <!-- <div v-show="currentStep == 0"> -->
      <trunk-one
        ref="trunkOne"
        @handleCancel="handleCloseConfirmationModal"
        @handleOk="handleSave"
        @edit="handleShowConfirmationModal"
      />
      <!-- </div> -->
      <!-- <div v-show="currentStep == 1"> -->
      <!-- <trunk-two
        ref="trunkTwo"
        @handleCancel="handleGoToPreviousStep"
        @handleOk="handleSave"
        @edit="handleShowConfirmationModal"
      ></trunk-two> -->
      <!-- </div> -->
    </div>
  </a-modal>
</template>
<script>
// import CustomStepper from "@/app/shared/components/organisms/CustomStepper";
import TrunkOne from "@/app/pbx/components/forms/TrunkOne.vue";
// import TrunkTwo from "@/app/pbx/components/forms/TrunkTwo";
import { CloseOutlined, QuestionCircleOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import { defineComponent, createVNode } from "vue";

export default defineComponent({
  components: {
    // CustomStepper,
    CloseOutlined,
    TrunkOne,
    InfoCircleOutlined,
    // TrunkTwo,
  },
  props: {
    visible: {
      type: Boolean,
      required: true,
    },
    record: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      currentStep: 0,
      steps: ["Datos de proveedor", "Datos de contacto"],
      showConfirmModal: false,
    };
  },
  methods: {
    handleCloseConfirmationModal() {
      if (this.showConfirmModal) {
        this.$confirm({
          title: "¿Deseas descartar los cambios realizados?",
          content: "Recuerda que si no guardas los cambios registrados, estos se perderán",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleCancel(),
          centered: true,
          okText: "Sí, descartar cambios",
          cancelText: "No, seguir editando",
        });
      } else {
        this.handleCancel();
      }
    },
    handleShowConfirmationModal() {
      this.showConfirmModal = true;
    },
    handleGoToNextStep() {
      this.currentStep++;
    },
    handleGoToPreviousStep() {
      this.currentStep--;
    },
    async handleSave() {
      try {
        this.currentStep = 0;
        this.$emit("emitVisible", true);
        const trunk = this.$store.getters["getTrunk"];
        await this.$store.dispatch("createNewTrunk", trunk);
        this.$emit("update");
        this.$store.dispatch("clearTrunk");
        this.$refs.trunkOne?.resetForm();
        this.$refs.trunkTwo?.resetForm();
        this.$message.success(`Se ha integrado la nueva troncal correctamente`);
      } catch (error) {
        this.$message.error("Error al solicitar un troncal, complete correctamente los datos");
      }
    },
    handleCancel() {
      this.showConfirmModal = false;
      this.$emit("emitVisible");
      this.$store.dispatch("clearTrunk");
      this.$refs.trunkOne?.resetForm();
      // this.$refs.trunkTwo.resetForm();
    },
  },
});
</script>

<style lang="sass" scoped>
.iconoCerrado
  position: absolute
  top: 22px
  right: 16px

.formtrunk
  display: block
  margin: 0 auto

:deep(.ant-modal-body)
  padding: 24px 0
</style>
