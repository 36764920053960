<template>
  <h2 v-if="inContactsTable">Datos personales</h2>

  <div class="form__wrapper">
    <a-form
      class="aligned-center"
      :class="{ grid: inContactsTable, 'aligned-center-xl': inContactsTable }"
      layout="vertical"
      ref="formRef"
      :model="formState"
      :rules="rules"
      @submit.prevent="handleContinue"
    >
      <a-form-item label="Nombres" name="first_name" class="input__name">
        <a-input v-model:value="formState.first_name" placeholder="Escribir nombres" />
      </a-form-item>
      <a-form-item label="Apellido paterno" name="paternal_surname">
        <a-input
          v-model:value="formState.paternal_surname"
          placeholder="Escribir apellido paterno"
        />
      </a-form-item>
      <a-form-item label="Apellido materno" name="maternal_surname">
        <a-input
          v-model:value="formState.maternal_surname"
          placeholder="Escribir apellido materno"
        />
      </a-form-item>
      <a-form-item label="Tipo de documento" name="type_document">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          v-model:value="formState.type_document"
          placeholder="Seleccionar aquí"
          @change="handleChangeTypeDocument"
        >
          <a-select-option value="1"> Documento de Identidad (DNI) </a-select-option>
          <a-select-option value="2"> Carnet de Extranjeria </a-select-option>
          <a-select-option value="3"> Pasaporte </a-select-option>
          <a-select-option value="4"> RUC </a-select-option>
        </a-select>
      </a-form-item>
      <a-form-item name="document_number">
        <template #label>
          <span>N° de documento</span>
          <a-tooltip>
            <template #title>
              Este número será utilizado como el código de contacto. En caso de no agregarlo, se
              asignará un código aleatorio.
            </template>
            <ExclamationCircleOutlined style="margin-left: 5px" />
          </a-tooltip>
        </template>
        <a-input
          v-model:value="formState.document_number"
          placeholder="Escribir número de documento"
        />
      </a-form-item>

      <template v-if="inContactsTable">
        <a-form-item label="Dirección" name="address">
          <a-input v-model:value="formState.address" placeholder="Escribir dirección" />
        </a-form-item>

        <a-form-item label="Correo electrónico" name="email">
          <a-input v-model:value="formState.email" placeholder="Escribir correo electrónico" />
        </a-form-item>

        <a-form-item label="Número telefónico" name="phone" class="input__name">
          <a-input v-model:value="formState.phone" placeholder="Ingresa aquí...">
            <template #addonBefore>
              <SelectorCountry v-model:value="formState.country" class="country" />
            </template>
          </a-input>
          <!-- <div class="phone__wrapper">
            <SelectorCountry v-model:value="formState.country" class="country" />

            <a-input v-model:value="formState.phone" placeholder="Ingresar aquí" />
          </div> -->
        </a-form-item>
      </template>
    </a-form>
    <div class="information">
      <ExclamationCircleOutlined style="margin-left: 5px" />
      <span>Podrás agregar más de cada uno de estos datos después de crear el contacto</span>
    </div>
  </div>

  <!-- <a-divider type="horizontal" class="divider" /> -->

  <footer class="footer">
    <a-button v-if="inContactsTable" @click="handleCancel" class="footer__button cancel">
      Cancelar
    </a-button>
    <a-button type="primary" @click="handleContinue" class="footer__button" :disabled="disabledContinue"> Continuar </a-button>
  </footer>
</template>

<script>
// import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { TypeDocument } from "@/app/workspace/utils/enums";
import { defineComponent, ref, reactive, computed, watch } from "vue";
import { message } from "ant-design-vue";

import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    // CustomScrollbar,
    SelectorCountry,
    ExclamationCircleOutlined,
  },
  props: {
    user: {
      type: Object,
      required: true,
    },
    visible: {
      required: true,
      type: Boolean,
    },
    item: {
      required: false,
      type: Object,
    },
    inContactsTable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const formRef = ref();
    const text = ref("");
    const record = computed(() => props.item);
    const disabledContinue = ref(true);
    const formState = reactive({
      first_name: "",
      paternal_surname: "",
      maternal_surname: "",
      type_document: "1",
      document_number: "",
      country: 1,
    });

    watch(record, (newValue) => {
      if (props.user?.customer?.enable_bases && props.user?.customer?.type_business == 2) {
        text.value = `Se creará el cliente en la base ${newValue.name}, de la campaña ${newValue.portfolio}`;
      }
      if (props.user?.customer?.enable_bases && props.user?.customer?.type_business == 1) {
        text.value = `Se creará el cliente en la base ${newValue.name}, de la campaña ${newValue.portfolio} y de la empresa ${newValue.entity}`;
      }
      if (!props.user?.customer?.enable_bases && props.user?.customer?.type_business == 1) {
        text.value = `Se creará el cliente en la campaña ${newValue.portfolio} de la empresa ${newValue.entity}`;
      }
      if (!props.user?.customer?.enable_bases && props.user?.customer?.type_business == 2) {
        text.value = `Se creará el cliente en la campaña ${newValue.portfolio}`;
      }
    });

    watch(formState, (newValue) => {
      if (newValue.first_name?.length > 0) {
        disabledContinue.value = false;
      } else {
        disabledContinue.value = true;
      }
    });

    const handleValidateDocument = async (rule, value) => {
      const documentType = formState.type_document;
      const numericDocumentRegexp = new RegExp("^[0-9]*$");
      // const hasLetters = new RegExp("^[a-zA-Z0-9]*$");
      const hasNumbers = new RegExp("\\d");

      if (value.length === 0) return Promise.resolve();

      if (TypeDocument.Pasaporte === documentType && !numericDocumentRegexp.test(value)) {
        return Promise.reject("El Pasaporte debe contener solo números");
      } else if (TypeDocument.ForeignCard === documentType && !hasNumbers.test(value)) {
        return Promise.reject("El Carnet de extranjería debe contener números");
      } else if (TypeDocument.Dni === documentType && !numericDocumentRegexp.test(value)) {
        return Promise.reject("El DNI debe contener solo números");
      }

      if (TypeDocument.Dni === documentType && value.length !== 8) {
        return Promise.reject("El DNI debe ser de 8 dígitos");
      } else if (TypeDocument.ForeignCard === documentType && value.length !== 12) {
        return Promise.reject("El Carnet de extranjería debe ser de 12 dígitos");
      } else if (TypeDocument.Pasaporte === documentType && value.length !== 12) {
        return Promise.reject("El Pasaporte debe ser de 12 dígitos");
      } else if (documentType === TypeDocument.Ruc && value.length < 5) {
        return Promise.reject("El RUC debe tener más de 4 dígitos");
      }
    };

    const handleValidatePhone = async (rule, value) => {
      const justNumbers = new RegExp("^[0-9]*$");

      if (!value?.length) return Promise.resolve();

      if (!justNumbers.test(value))
        return Promise.reject("El teléfono solo pueden contener números");
    };

    
    const handleValidateEmail = async (rule, value) => {
      const emailRegex = new RegExp(
        "^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9-]+(\\.[a-zA-Z0-9-]+)*\\.[a-zA-Z]{1,}$"
      );

      if (!value?.length) return Promise.resolve();

      if (!emailRegex.test(value)) {
        return Promise.reject("El correo electrónico no es válido");
      }
      return Promise.resolve();
    };

    const handleContinue = () => {
      formRef.value
        .validate()
        .then(() => {
          const body = {
            type_document: Number(formState.type_document),
            // document_number: formState.document_number,
            first_name: formState.first_name,
            // paternal_surname: formState.paternal_surname,
            // maternal_surname: formState.maternal_surname,
            // code: formState.document_number,
          };

          if (formState.document_number) {
            body.document_number = formState.document_number;
            body.code = formState.document_number;
          }

          if (formState.paternal_surname) body.paternal_surname = formState.paternal_surname;
          if (formState.maternal_surname) body.maternal_surname = formState.maternal_surname;
          if (formState.address) body.address = formState.address;
          if (formState.email) body.email = formState.email;
          if (formState.phone) body.country = formState.country;
          if (formState.phone) body.phone = formState.phone;

          emit("client-submit", body);
        })
        .catch((error) => {
          console.log(error);
          const errorMessage = error?.errorFields?.[0]?.errors?.[0] || "Ha ocurrido un error";
          message.error(errorMessage);
        });
    };

    const handleCancel = () => emit("cancel");

    const rules = {
      first_name: [{ required: true, message: "Campo requerido" }],
      // paternal_surname: [{ required: true, message: "Campo requerido" }],
      // maternal_surname: [{ required: true, message: "Campo requerido" }],
      // type_document: [{ required: true, message: "Campo requerido" }],
      // document_number: [
      //   {
      //     validator: handleValidateDocument,
      //   },
      // ],
      email: [{ validator: handleValidateEmail }],
      phone: [{ validator: handleValidatePhone }],
    };

    const handleChangeTypeDocument = () => {
      formState.document_number = "";
    };

    return {
      rules,
      formState,
      formRef,
      handleChangeTypeDocument,
      handleValidateDocument,
      handleCancel,
      text,
      record,
      handleContinue,
      disabledContinue,
    };
  },
});
</script>

<style lang="sass" scoped>
h2
  text-align: center
  font-weight: 600
  font-size: 20px

.divider
  background-color: $gray-4
  width: calc(100% + 32px)
  position: relative
  left: -16px

.form__wrapper
  min-height: 430px
  margin-bottom: 50px

.grid
  display: grid
  grid-template-columns: 1fr 1fr
  gap: 0 24px

.information
  max-width: 85%
  margin: 0 auto
  display: flex
  justify-content: start
  .anticon
    margin-top: 4px

.anticon
  align-items: start
  margin-right: 10px

.aligned-center
  max-width: 75%
  margin: 0 auto

  &-xl
    max-width: 85%

.input
  &__name
    grid-column: 1 / 3

footer
  text-align: center

.button
  padding-left: 30px
  padding-right: 30px

  &.cancel
    margin-right: 8px

.modal
  &__body
    padding: 12px 24px

.phone__wrapper
  display: flex
  align-items: center
  gap: 8px

  .country
    width: 160px

.footer
  display: flex
  align-items: center
  justify-content: center
  padding: 12px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px
    width: 200px

.drawer__body--subtitle
  color: $gray-8
  font-weight: 400
  margin-bottom: 20px
</style>
