import {
  RESET_STATE,
  SET_STATUS,
  SET_PHONE_NUMBER,
  SET_STRATEGY_PHONE,
  SET_CALL_FINISH,
  SET_CALL_HOLD,
  SET_CALL_MUTED,
} from "./mutation-types";
import { initialState } from "./state";

const mutations = {
  [SET_STATUS](state, status) {
    state.status = status;
  },
  [SET_PHONE_NUMBER](state, phoneNumber) {
    state.phoneNumber = phoneNumber;
  },
  [SET_STRATEGY_PHONE](state, strategyPhone) {
    state.strategyPhone = strategyPhone;
  },
  [SET_CALL_FINISH](state, status) {
    state.callFinished = status;
  },
  [RESET_STATE](state) {
    const initial = initialState();
    for (const key in initial) {
      state[key] = initial[key];
    }
  },
  [SET_CALL_MUTED](state, status) {
    state.phoneMuted = status;
  },
  [SET_CALL_HOLD](state, status) {
    state.phoneHold = status;
  },
};

export default mutations;
