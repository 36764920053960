<template>
  <div>
    <EditOutlined class="icon-options" @click="handleShowModal" />
    <a-modal
      v-model:visible="visible"
      width="400px"
      centered
      :body-style="{ padding: 20 }"
      title="Editar recordatorio"
      :mask-closable="false"
      :keyboard="false"
      @cancel="handleConfirmationClose"
    >
      <contact-data-section :contactData="contactData" v-if="record.client" />

      <a-form class="form" layout="vertical" :model="formState" :rules="rules" ref="formRef">
        <a-form-item label="Título" required>
          <a-input
            v-model:value="formState.title"
            placeholder="Escribir titulo del recordatorio"
            :maxlength="maxObservationName"
            @input="handleChangeName"
          ></a-input>
          <span class="countername"> {{ name ? name.length : 0 }}/{{ maxObservationName }} </span>
        </a-form-item>

        <a-row :gutter="16">
          <a-col :span="12">
            <a-form-item label="Seleccione fecha" required>
              <a-date-picker
                v-model:value="formState.date_schedule"
                class="picker"
                placeholder="-- / -- / --"
                format="DD/MM/YYYY"
                valueFormat="YYYY-MM-DD"
                style="width: 100%"
                :show-today="false"
                :disabled-date="disabledDate"
                @change="handleShowModalExit"
              />
            </a-form-item>
          </a-col>

          <a-col :span="12">
            <a-form-item label="Seleccione hora" required>
              <a-time-picker
                v-model:value="formState.time_schedule"
                v-model:open="open"
                placeholder="00:00"
                format="HH:mm"
                valueFormat="HH:mm:ss"
                class="picker"
                style="width: 100%"
                @change="handleShowModalExit"
              >
                <template #addon>
                  <a-button size="small" type="primary" @click="handleClose"> Ok </a-button>
                </template>
              </a-time-picker>
            </a-form-item>
          </a-col>
        </a-row>

        <a-form-item>
          <p>Descripción</p>
          <a-textarea
            v-model:value="formState.description"
            class="textarea"
            rows="5"
            placeholder="Escribir el mensaje inicial"
            :maxlength="maxDescriptionLength"
            @input="handleShowModalExit"
          />
          <span class="countername">
            {{ description ? description.length : 0 }}/{{ maxDescriptionLength }}
          </span>
        </a-form-item>
      </a-form>
      <!-- Fotter Modal -->
      <template #footer>
        <a-button key="back" @click="handleCancel">Cancelar</a-button>
        <a-button key="submit" type="primary" @click="handleOk" :loading="saving">
          Guardar recordatorio
        </a-button>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { TypeReminder } from "@/app/schedule/utils/enum";
import { dateMomentReminder, timeMomentReminder, disabledDate } from "@/app/shared/utils/dates";
import { EditOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import ContactDataSection from "../section/ContactDataSection.vue";
import { Modal } from "ant-design-vue";
import { defineComponent, ref, reactive, toRaw, computed, createVNode } from "vue";

export default defineComponent({
  components: {
    EditOutlined,
    ContactDataSection,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const contactData = computed(() => ({
      clientName: props.record.client_name,
      clientId: props.record.client,
      clientPhoto: props.photo,
      campaign: props.record.portfolio?.name,
      queue: props.record.queue?.name,
      ticket: props.record.ticket,
      ticketState: props.record.ticket_state,
    }));

    const formRef = ref();
    const formState = reactive({
      title: "",
      date_schedule: "",
      time_schedule: "",
      description: "",
    });
    const rules = {
      title: [{ required: true, message: "Campo requerido" }],
      date_schedule: [{ required: true, message: "Campo requerido" }],
      time_schedule: [{ required: true, message: "Campo requerido" }],
      // description: [{ required: true, message: "Campo requerido" }],
    };
    return {
      contactData,
      rules,
      formState,
      formRef,
    };
  },
  data() {
    return {
      visible: false,
      config: {
        rules: [{ type: "object", required: true, message: "Campo requerido" }],
      },
      size: {
        width: 40,
        height: 40,
      },
      showConfirmModal: false,
      open: false,
      TypeReminder,
      name: "",
      description: "",
      maxObservationName: 50, // Máxima cantidad de caracteres en el campo de "Titulo Recordatorio"
      maxDescriptionLength: 100, // Máxima cantidad de caracteres en el campo de "Descripcion"
      saving: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleShowModal() {
      this.visible = true;
      this.$emit("hide");
      this.formState.title = this.record.title;
      this.formState.description = this.record.description;
      this.formState.date_schedule = dateMomentReminder(
        this.record.date_schedule,
        this.user.customer.timezone
      );
      this.formState.time_schedule = timeMomentReminder(
        this.record.time_schedule,
        this.user.customer.timezone
      );
    },
    handleOk() {
      this.saving = true;

      this.formRef.validateFields().then(async () => {
        const date = toRaw(this.formState).date_schedule;
        const time =
          toRaw(this.formState).date_schedule + " " + toRaw(this.formState).time_schedule;

        const objReminder = {
          title: toRaw(this.formState).title,
          date_schedule: date,
          time_schedule: time,
          description: toRaw(this.formState).description,
        };

        const objEvent = {
          title: toRaw(this.formState).title,
          date_schedule: date,
          time_schedule: time,
          description: toRaw(this.formState).description,
        };

        try {
          await this.$store.dispatch("updateReminder", {
            reminderId: this.record.id,
            reminder: this.record.type == TypeReminder.reminder ? objEvent : objReminder,
          });
          // await this.$store.dispatch("fetchAllReminder");
          this.$emit("update");
          this.$message.success("Se han guardado los cambios correctamente");
          this.formRef.resetFields();
        } catch (error) {
          console.error(error);
          this.$message.error(error?.getErrorMessage?.() || "Ha ocurrido un error");
        }

        this.visible = false;
        this.saving = false;
      });
    },
    handleCancel() {
      this.visible = false;
      this.formRef.resetFields();
      this.showConfirmModal = false;
    },
    handleConfirmationClose() {
      if (this.showConfirmModal) {
        Modal.confirm({
          title: "¿Estás seguro de cerrar el proceso?",
          content: "Al cerrar no se guardarán los datos previamente ingresados",
          icon: createVNode(QuestionCircleOutlined),
          onOk: () => this.handleCancel(),
          centered: true,
          okText: "Cerrar",
          cancelText: "Cancelar",
        });
      } else {
        this.handleCancel();
      }
    },
    handleClose() {
      this.open = false;
    },
    disabledDate(current) {
      return disabledDate(current);
    },
    handleChangeName(event) {
      this.name = event.target.value;
      this.showConfirmModal = true;
    },
    handleShowModalExit(event) {
      this.description = event?.target?.value;
      this.showConfirmModal = true;
    },
  },
});
</script>

<style lang="sass" scoped>
.form p
    margin-bottom: 6px
    color: $gray-9
.space
  margin-bottom: 8px
.icon-options
  margin-left: $margin-small
  font-size: $font-medium
.textarea
  height: 56px
  margin: auto
.date
  display: flex
  justify-content: space-between
  .picker
    width: 170px
.avatar
  display: flex
  align-items: center
  gap: 10px
.ant-tag
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 230px
.countername
  bottom: 0px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6
</style>
