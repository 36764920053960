import { inject, getCurrentInstance } from "vue";
import { Socket } from "socket.io-client";

import { SocketEvents } from "@/@types/shared/socketEvents";

type EventsName = keyof SocketEvents;

export function useSocket({ logName = "" } = {}) {
  /**
   * Get the instance of the component where the composable is used (this)
   * to access "vue-3-socket.io" package instance and add the typed listeners
   * to be used with the CompositionAPI.
   *
   * This was done because the "vue-3-socket.io" package only works for the
   * OptionsAPI
   */
  const currentComponentInstance = getCurrentInstance();

  const $vueSocket = currentComponentInstance?.appContext?.config?.globalProperties?.$vueSocketIo;
  function subscribe<T extends EventsName>(event: T, callback: SocketEvents[T]) {
    if (!$vueSocket) console.error("Verify the 'vue-3-socket.io' package is installed");

    $vueSocket.emitter.addListener(event, callback, { $options: { name: logName || "useSocket" } });
  }

  const socketIo = inject("socket") as Socket;

  return { socketIo, vueSocket: { subscribe } };
}
