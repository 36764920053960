<template>
  <a-modal
    :visible="true"
    :mask-closable="false"
    class="modalDialplanEdit"
    :closable="false"
    title="Editar plan de marcación"
    centered="centered"
    :body-style="{ padding: 0, height: '40em' }"
    :keyboard="false"
    width="48em"
  >
    <CloseOutlined class="iconoCerrado" @click="handleConfirmationClose" />

    <a-tabs v-model:activeKey="currentTab" @change="setValuesToCurrentForm">
      <a-tab-pane
        v-for="tab in TABS_DIAL_PLAN_EDIT"
        :key="tab.key"
        :tab="tab.title"
        class="modal__tab-container"
      >
        <a-typography-title :level="4" class="modal__title text--center mrg-bottom-12">{{
          currentData.title_section
        }}</a-typography-title>
        <component
          v-if="formComponent"
          ref="formRef"
          :is="formComponent"
          :disabled-trunk="true"
          :disabled-country="true"
        />
      </a-tab-pane>
    </a-tabs>
    <template #footer>
      <div class="footer">
        <a-button
          class="footer__back"
          type="primary"
          ghost="ghost"
          @click="handleConfirmationClose"
        >
          {{ cancelButton }}
        </a-button>
        <a-button class="footer__next" type="primary" @click="handleOk">
          {{ okButton }}
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { ref, computed, nextTick, onMounted } from "vue";
import { isEqual, cloneDeep } from "lodash";
import FormDialPlan from "@/app/pbx/components/forms/FormDialPlan.vue";
import FormDialRule from "@/app/pbx/components/forms/FormDialRule.vue";
import { CloseOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import { TABS_DIAL_PLAN_EDIT } from "@/app/pbx/utils/dialPlanUtils";
import { TabKeyDialPlan, FormStateDialPlan } from "@/@types/pbx/dialPlan";
import { useStore } from "vuex";
import { ResponseError } from "@/app/shared/utils/httpManager";

const props = defineProps({
  record: {
    type: Object,
    required: true,
  },
});

const emits = defineEmits(["onClose", "onUpdate"]);

const store = useStore();
const currentTab = ref(TabKeyDialPlan.General);
const formRef = ref();

onMounted(() => setValuesToCurrentForm());

const formComponent = computed(() => {
  switch (currentTab.value) {
    case TabKeyDialPlan.General:
      return FormDialPlan;
    case TabKeyDialPlan.Rules:
      return FormDialRule;
    default:
      return null;
  }
});

const currentData = computed(() => {
  return TABS_DIAL_PLAN_EDIT[currentTab.value];
});
const cancelButton = computed(() => {
  return currentData.value.buttons.cancel;
});
const okButton = computed(() => {
  return currentData.value.buttons.ok;
});

const handleConfirmationClose = () => {
  if (isEqual(comparisonRef.value, getFormState())) {
    handleCancel();
    return;
  }

  Modal.confirm({
    title: "¿Deseas descartar los cambios realizados?",
    content: "Recuerda que si no guardas los cambios registrados, estos se perderán",
    onOk: () => {
      handleCancel();
      Modal.destroyAll();
    },
    centered: true,
    okText: "Sí, descartar cambios",
    cancelText: "No, seguir editando",
  });
};

const getFormState = () => {
  switch (currentTab.value) {
    case TabKeyDialPlan.General:
      return formRef.value[0].onGetValues();
    case TabKeyDialPlan.Rules:
      return formRef.value[1].onGetValues();
    default:
      return null;
  }
};

const handleCancel = () => {
  emits("onClose");
  formRef.value?.[0].onResetValues?.();
  currentTab.value = TabKeyDialPlan.General;
};

const comparisonRef = ref();
/**
 * Setea los valores locales al componente form
 */
const setValuesToCurrentForm = async () => {
  if (!props.record) return;
  await nextTick();
  const record = { ...props.record };
  record.rules = record.dialing_rules;
  record.trunk = record.trunk.id;
  record.country = record.country?.id;

  comparisonRef.value = cloneDeep(record);

  switch (currentTab.value) {
    case TabKeyDialPlan.General:
      return formRef.value[0].onSetValues(record);
    case TabKeyDialPlan.Rules:
      return formRef.value[1].onSetValues(record);
    default:
      return null;
  }
};

const handleOk = () => {
  switch (currentTab.value) {
    case TabKeyDialPlan.General:
      return formRef.value[0].onSubmit(saveData);
    case TabKeyDialPlan.Rules:
      return formRef.value[1].onSubmit(saveData);
    default:
      return null;
  }
};

const saveData = async (values: FormStateDialPlan) => {
  try {
    delete values.status;
    await store.dispatch("updateDialplan", {
      id: props.record.id,
      params: values,
    });
    emits("onClose");
    emits("onUpdate");
    message.success("Se editó correctamente");
  } catch (error) {
    const errorResponse = error instanceof ResponseError ? error.data : "Ocurrió un error";
    const errorMessage = JSON.stringify(errorResponse);
    message.error(errorMessage);
  }
};
</script>

<style lang="sass" scoped>
:deep(.ant-modal-centered .ant-modal)
  width: 740px !important
:deep(.ant-modal-header)
  height: 56px
:deep(.ant-modal-content)
  height: 662px
.iconoCerrado
  position: absolute
  top: 22px
  right: 16px

.modal
  &__steps
    margin: 24px 0px 12px 0px
  &__title
    margin-top: 30px
  &__tab-container
    padding: 0px 44px
</style>
