<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke="black"
      :stroke-opacity="border ? '0.06' : '0'"
    />
    <g clip-path="url(#clip0_18630_50844)">
      <path
        d="M24 38C16.2808 38 10 31.7192 10 24C10 16.2808 16.2808 10 24 10C31.7192 10 38 16.2808 38 24C38 31.7192 31.7192 38 24 38ZM24 11.75C17.245 11.75 11.75 17.245 11.75 24C11.75 30.755 17.245 36.25 24 36.25C30.755 36.25 36.25 30.755 36.25 24C36.25 17.245 30.755 11.75 24 11.75ZM24 31C18.1856 31 17.0892 27.9086 17.0446 27.7765C16.8915 27.318 17.1391 26.8219 17.5976 26.6696C18.0526 26.5174 18.54 26.7598 18.6984 27.2051C18.7448 27.3206 19.5839 29.25 24 29.25C28.4546 29.25 29.2684 27.2874 29.3025 27.2034C29.474 26.7615 29.971 26.527 30.4181 26.6871C30.8652 26.849 31.105 27.3259 30.9545 27.7765C30.9107 27.9086 29.8144 31 24 31ZM28.375 25.75C27.8911 25.75 27.5 25.3589 27.5 24.875V20.5C27.5 20.017 27.8911 19.625 28.375 19.625C28.8589 19.625 29.25 20.017 29.25 20.5V24.875C29.25 25.3589 28.8589 25.75 28.375 25.75ZM19.625 25.75C19.142 25.75 18.75 25.3589 18.75 24.875V20.5C18.75 20.017 19.142 19.625 19.625 19.625C20.108 19.625 20.5 20.017 20.5 20.5V24.875C20.5 25.3589 20.108 25.75 19.625 25.75Z"
        fill-opacity="0.45"
        :fill="active ? 'green' : '#8C8C8C'"
      />
    </g>
    <defs>
      <clipPath id="clip0_18630_50844">
        <rect width="28" height="28" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
    border: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>
