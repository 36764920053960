<template>
  <div class="email__body" :class="{ collapse: !showContent }">
    <div class="primary thread card-button">
      <div class="user" @click="emitShowEmail">
        <a-tooltip>
          <!-- <template #title>El usuario Daniela Sánchez a enviado este correo.</template> -->
          <img
            class="img-profile"
            src="https://atg-prod-scalar.s3.amazonaws.com/studentpower/media/user%20avatar.png"
            width="40"
            height="40"
            alt="Avatar de contacto"
          />
        </a-tooltip>
        <div class="information">
          <div class="name container-beetween">
            <h3>{{ data?.sender }}</h3>
            <div class="actions">
              <div class="top">
                <div class="date">
                  <span>{{ data?.date }}</span>
                  <span>{{ data?.time }}</span>
                </div>
                <AnswerActionsButton
                  class="icon"
                  v-if="isAssigned && isMailbox"
                  @handleNoteEvent="handleNoteEvent"
                  @handleReplyEvent="handleReplyEvent"
                  :showReply="showReply"
                />
                <a-button
                  type="link"
                  :disabled="!isAssigned"
                  v-if="isMailbox"
                  @click.stop="handleReplyEvent(ActionsEmail.Responder)"
                >
                  <a-tooltip
                    :title="
                      isAssigned
                        ? 'Responder mensaje'
                        : 'Asígnate para poder responder este correo.'
                    "
                    placement="leftBottom"
                  >
                    <IconArrowCorner class="icon" :disabled="!isAssigned" />
                  </a-tooltip>
                </a-button>
              </div>
            </div>
          </div>
          <div class="addressee">
            <p>
              para:
              <span v-for="(mail, index) in data?.receivers" :key="mail.email || ''">
                {{ index > 0 ? ", " : "" }}{{ mail.alias ? `${mail.alias} ` : ""
                }}{{ `<${mail.email}>` }}
              </span>
            </p>
            <p v-if="data?.ccEmails?.at?.(0)?.email">
              cc:
              <span v-for="(mail, index) in data?.ccEmails" :key="mail.email || ''">
                {{ index > 0 ? ", " : "" }}{{ mail.alias ? `${mail.alias} ` : ""
                }}{{ `<${mail.email}>` }}
              </span>
            </p>
            <p v-if="data?.bccEmails?.at?.(0)?.email">
              cco:
              <span v-for="(mail, index) in data?.bccEmails" :key="mail.email || ''">
                {{ index > 0 ? ", " : "" }}{{ mail.alias ? `${mail.alias} ` : ""
                }}{{ `<${mail.email}>` }}
              </span>
            </p>
            <div class="content">
              <div v-show="showContent">
                <!-- <p v-if="!isMailbox" style="margin-bottom: 10px" v-html="data?.content" /> -->
                <iframe
                  title="email-message"
                  :id="`template-item-html-frame-${pos}`"
                  :srcdoc="formatContentMessage(data?.content)"
                  :style="{ maxWidth: '100%', minHeight: '60px' }"
                  frameborder="0"
                  width="100%"
                  scrolling="no"
                  @load="stylesIframe"
                />
                <div
                  class="file-download"
                  v-for="(file, index) in data?.attachmentsPublicUrl"
                  :key="index"
                >
                  <source :src="file" type="audio/mpeg" />
                  <PaperClipOutlined
                    :style="{ fontSize: '20px', color: '#00000073', paddingRight: '10px' }"
                  />
                  <div class="file-download__name">{{ nameFile(index) }}</div>
                  <DownloadOutlined
                    @click.stop="handleDownload(file, nameFile(index))"
                    :style="{ fontSize: '16px', color: '#1677FF' }"
                  />
                </div>
                <!-- <iframe ref="iframe" :srcdoc="data?.content"></iframe> -->
                <span class="more-info" v-if="pos > 0" @click.stop="moreInfo"
                  ><EllipsisOutlined
                /></span>
                <EmailParentDetail v-if="showEmailsParents" :element="emailsParents" />
              </div>
              <div v-show="!showContent" v-html="previewContent"></div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <NoteMessageCard
      v-for="note of props.data?.notes"
      :key="note.id"
      :note="note"
      :email="props.data"
      @emitRefreshThread="emit('refreshThread')"
    />

    <div :id="'cardRedactEmail' + pos" v-if="showReply">
      <ReplyEmailCard
        @emitSaveReplyEvent="handleSaveReplyEvent"
        @emitCloseReplyEvent="handleCloseReplyEvent"
        :idEmail="props.data?.id"
        :emailFrom="props.data"
        :action="actionReply"
      />
    </div>

    <div :id="'cardRedactNote' + pos" v-if="showNote">
      <NoteCard
        :show="showNote"
        @emitSaveNoteEvent="handleSaveNoteEvent"
        @emitCloseNoteEvent="handleCloseNoteEvent"
        :isAssigned="isAssigned"
        :email="props.data"
      />
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref, computed, nextTick, onMounted, inject, watch } from "vue";
import cheerio from "cheerio";
import { useStore } from "vuex";

import IconArrowCorner from "@/app/common/components/atoms/IconArrowCorner.vue";
import AnswerActionsButton from "../buttons/AnswerActionsButton.vue";
import ReplyEmailCard from "@/app/emails/components/cards/ReplyEmailCard.vue";
import NoteCard from "@/app/emails/components/cards/NoteCard.vue";
import EmailParentDetail from "@/app/emails/components/cards/EmailParentDetail.vue";
import NoteMessageCard from "@/app/emails/components/cards/NoteMessageCard.vue";
import { emailService } from "@/app/emails/services/Email.service";
import { EmailDetails, ActionsEmail } from "@/@types/emails";
import { EllipsisOutlined, PaperClipOutlined, DownloadOutlined } from "@ant-design/icons-vue";
import { IMenutem } from "@/@types/emails";
import { message } from "ant-design-vue";
import { UserData } from "@/@types/global";
import { emailManagerService } from "@/app/emailsManager/services/EmailManager.service";

import { downloadFile } from "@/app/shared/utils/file";

const props = withDefaults(
  defineProps<{
    show: boolean;
    isAssigned: boolean;
    data: EmailDetails | null;
    showContent: boolean;
    // collapse: boolean;
    pos: number;
    isMailbox?: boolean | true;
  }>(),
  {
    showContent: true,
  }
);
// const items = ref<IMenutem[]>([]);
// provide("menuItems", items);

const iframeHeight = ref(null);

const store = useStore();
const user = computed<UserData>(() => store.getters["getDataUser"]);

const iframe = ref(null);
// const isCollapsed = ref(props.collapse);
const showReply = ref(false);
const emailsParents = ref<any>(null);
const showEmailsParents = ref(false);
const actionReply = ref<string>(null);
const previewContent = computed(() => {
  const content = props.data?.content ?? "";
  const $ = cheerio.load(content);
  let contentReduce = "";
  $("*")
    .contents()
    .each((index, element) => {
      if (
        element.type === "text" &&
        element.parent?.name != "title" &&
        element.parent?.name != "style" &&
        element.parent?.name != "head"
      ) {
        const text = $(element).text() + " ";
        contentReduce += text;
      }
    });
  return truncateText(contentReduce.trim(), 150);
});

const showNote = ref<boolean>(false);
const emit = defineEmits([
  "emitNoteEvent",
  "handleNoteEvent",
  "emitShowEmailEvent",
  "refreshThread",
]);

const emitShowEmail = () => {
  if (!window?.getSelection()?.toString()) emit("emitShowEmailEvent", props?.pos || 0);
};

const closeReplyModal = (data: boolean) => {
  showReply.value = data;
  emit("refreshThread");
};

const truncateText = (text: string, maxLength: number) => {
  return text.length > maxLength ? text.substring(0, maxLength) + "..." : text;
};

const formatContentMessage = (text: string) => {
  if (props.data?.sender == "forwarding-noreply@google.com") {
    // regex para url
    const urlPattern =
      /(\b(https?|ftp|file):\/\/[-A-Z0-9+&@#\\/%?=~_|!:,.;]*[-A-Z0-9+&@#\\/%=~_|])/gi;

    let urlConfirm = "";
    const textFormatted = text.replace(urlPattern, (url) => {
      if (urlConfirm == "") {
        urlConfirm = url;
        return `<a href="${url}" target="_blank" rel="noopener noreferrer">${url}</a>`;
      } else return url;
    });

    const button = `<br/><br/><a href="${urlConfirm}" target="_blank" rel="noopener noreferrer"><button>Verificar Integración</button></a>`;

    return textFormatted + button;
  }
  return text;
};

const toScroll = (elementId: string, addScroll: number) => {
  const element = document.getElementById(elementId);
  const container = document.getElementById("container-email-thread");
  if (element && container) {
    const elementRect = element.getBoundingClientRect();
    const containerRect = container.getBoundingClientRect();
    const scrollAmount = elementRect.bottom - containerRect.bottom;
    container.scrollBy({
      top: scrollAmount + addScroll,
      behavior: "smooth",
    });
  }
};

function addNivelTree(tree: any, nivel = 0) {
  if (typeof tree === "object" && tree !== null) {
    const newTree = { ...tree };
    newTree.nivel = nivel;
    if (newTree.parent !== null) {
      newTree.parent = addNivelTree(newTree.parent, nivel + 1);
    }
    return newTree;
  } else {
    return tree;
  }
}

const moreInfo = async () => {
  if (showEmailsParents.value) {
    emailsParents.value = null;
    showEmailsParents.value = false;
  } else {
    const messageDetail = await emailService.getEmailDetails(props.data.id);
    emailsParents.value = addNivelTree(messageDetail.parent);
    showEmailsParents.value = true;
  }
};

const handleNoteEvent = () => {
  showNote.value = true;
  nextTick(() => {
    toScroll("cardRedactNote" + props.pos, 0);
  });
};

const handleSaveNoteEvent = async (data: { email: number; user: number; note: string }) => {
  await emailService.saveNote(data);
  message.success("Se ha guardado la nota con éxito");
  showNote.value = false;
  emit("refreshThread");
};

const handleCloseNoteEvent = () => {
  showNote.value = false;
};

const handleReplyEvent = (action: string) => {
  showReply.value = true;
  actionReply.value = action;
  nextTick(() => {
    toScroll("cardRedactEmail" + props.pos, 250);
  });
};

const handleSaveReplyEvent = () => {
  showReply.value = false;
  console.log("refresh thread");
  emit("refreshThread");
};

const handleCloseReplyEvent = () => {
  showReply.value = false;
};

// const fetchMenuList = async () => {
//   console.log("fetch menu in email message card");
//   items.value = await emailService.getEmailsMenuList(user.value.id);
// };

const stylesIframe = () => {
  if (props.showContent) {
    const iframe = document.getElementById(`template-item-html-frame-${props.pos}`);
    const iframeDocument = iframe.contentDocument || iframe.contentWindow.document;
    const height = iframeDocument.documentElement.scrollHeight;
    iframe.style.height = `${height}px`;
    iframe.style.margin = `0`;
  }
};

const nameFile = (index: number) => {
  console.log(
    props.data?.attachmentsPublicUrl?.[index],
    "props.data?.attachmentsPublicUrl?.[index]"
  );
  if (props.data?.attachmentsPublicUrl?.[index]) {
    const urlFile = props.data.attachmentsPublicUrl[index] as string;
    const arrayUrl = urlFile.split("/");
    const arrayUrlName = arrayUrl[arrayUrl.length - 1].split(".");
    const name = arrayUrlName[0];
    const typeFile = arrayUrlName[1];
    const maxLength = 40;
    if (name.length > maxLength) {
      return name.slice(0, maxLength) + "..." + typeFile;
    }
    return decodeURIComponent(name) + "." + typeFile;
  }
  return "";
};

const handleDownload = async (url: string, nameFile: string) => {
  const response = await emailManagerService.downloadFile(url);
  downloadFile(response, nameFile, { useResponseFileName: true });
};

watch(
  () => props.showContent,
  async () => {
    await nextTick();
    stylesIframe();
  }
);

onMounted(async () => {
  await nextTick();
  stylesIframe();
  // await fetchMenuList();
});
</script>

<style lang="sass" scoped>
.scroll-width
  width: calc(100vw - 465px)
  overflow-x: auto
.more-info
  font-size: 18px
  background-color: $gray-4
  padding-inline-start: 6px
  padding-inline-end: 6px
  border-radius: 24px
  margin-top: 10px
.email
  &__body.collapse
    .primary
      border: 1px solid $gray-5
      box-shadow: none
      /*max-height: 120px */
  &__body
    display: block
    margin: 0 auto
    height: calc(100vh - 300px)y
    overflow-y: auto

    .thread
      border: 1px solid $gray-5
      margin: 5px 8px 0px 8px
      border-radius: 4px
      padding: 10px
      background-color: $white

    .message
      border: 1px solid $primary
      box-shadow: 0 0 5px #E6F4FF

    .primary
      .user
        display: flex
        gap: 10px
        width: 100%
        .information
          width: 100%
          .name
            display: flex
            align-items: center
            gap: 10px
            h3
              margin: 0
          .addressee
            p
              margin: 0
            .content
              padding: 15px 0
              width: 100%
              overflow-x: hidden
      .container-beetween
        display: flex
        justify-content: space-between
        width: 100%
        .actions
          .top
            display: flex
            align-items: center
            gap: 7px
            .date
              color: $gray-7
              padding-right: 5px
              display: flex
              gap: 5px
            button
              padding: 0
              outline: none
              .icon
                color: #262626
                font-size: 17px
              svg
                outline: none
.card-button
  cursor: pointer
.parent-email
  color: #500050 !important
.img-profile
  height: fit-content !important
.file-download
  display: flex
  width: 400px
  padding: 2px 10px 3px 2px
  margin-bottom: 10px
  border-radius: 4px
  background: #f0f5ff
  &__name
    width: 350px
    color: #1677FF
    font-weight: 400
</style>
<style>
.actions .top .ant-btn {
  padding: 0;
}
</style>
