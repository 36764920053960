<template>
  <a-drawer
    :visible="visible"
    title="Agregar Referido"
    :mask-closable="false"
    placement="right"
    width="480"
    keyboard
    :body-style="{ padding: 0 }"
    @close="handleCloseDrawer"
  >
    <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
      <a-form
        class="form"
        layout="vertical"
        ref="formRef"
        :model="formState"
        :rules="rules"
        hide-required-mark
        @submit.prevent="handleSubmit"
      >
        <a-form-item label="Nombre" name="name">
          <a-input v-model:value="formState.name" placeholder="Escribe aquí" />
        </a-form-item>
        <a-form-item label="Tipo de documento" name="type_document">
          <a-input v-model:value="formState.type_document" placeholder="Escribe aquí" />
        </a-form-item>

        <a-form-item label="Documento" name="number_document">
          <a-input v-model:value="formState.number_document" placeholder="Escribe aquí" />
        </a-form-item>

        <a-form-item label="Teléfono" name="phone">
          <a-row :gutter="24">
            <a-col span="7">
              <selector-country v-model:value="formState.country" @selected="code_phone = $event" />
            </a-col>
            <a-col span="17">
              <a-input
                v-model:value="formState.phone"
                placeholder="Ingresar número de teléfono"
                @keypress="handleNumberPhone($event)"
              />
            </a-col>
          </a-row>
        </a-form-item>

        <a-form-item label="Email" name="email">
          <a-input v-model:value="formState.email" placeholder="Escribe aquí" />
        </a-form-item>

        <a-form-item label="Dirección" name="address">
          <a-input v-model:value="formState.address" placeholder="Escribe aquí" />
        </a-form-item>

        <a-form-item label="Observación" name="observation">
          <a-textarea
            v-model:value="formState.observation"
            placeholder="Escribe aquí"
            :maxlength="commentLimit"
            class="textarea"
          />
          <span class="counter"> {{ formState.observation?.length }}/{{ commentLimit }} </span>
        </a-form-item>
      </a-form>
    </custom-scrollbar>

    <div class="footer">
      <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
      <a-button type="primary" @click="handleSubmit" :disabled="!changeInput">
        Agregar Referido
      </a-button>
    </div>
  </a-drawer>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    SelectorCountry,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: "",
      type_document: "",
      number_document: "",
      phone: "",
      email: "",
      address: "",
      observation: "",
      country: 1,
    });
    return {
      formState,
      formRef,
    };
  },
  data() {
    return {
      // changeInput: false,
      commentLimit: 200,
    };
  },
  computed: {
    changeInput() {
      if (
        this.formState.name != "" ||
        this.formState.type_document != "" ||
        this.formState.number_document != "" ||
        this.formState.phone != "" ||
        this.formState.email != "" ||
        this.formState.address != "" ||
        this.formState.observation != ""
      )
        return true;

      return false;
    },
  },
  methods: {
    handleNumberPhone(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    // handleChangeInput() {
    // this.changeInput = true;
    // },
    handleCloseDrawer() {
      this.$emit("onCloseDrawerEdit");
      this.resetFields();
      // this.changeInput = false;
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("createReferralsContact", {
            referralsId: this.itemId,
            body: toRaw(this.formState),
          });
          this.handleCloseDrawer();
          this.$message.success("Se ha agregado información correctamente");
          this.resetFields();
          this.changeInput = false;
        } catch (error) {
          this.$message.error("Se ha producido un error al agregar la información");
        }
      });
    },
    resetFields() {
      this.formState.name = "";
      this.formState.type_document = "";
      this.formState.number_document = "";
      this.formState.phone = "";
      this.formState.email = "";
      this.formState.address = "";
      this.formState.observation = "";
      this.formState.country = 1;
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

.ant-form-item
  .textarea
    height: 85px

  .counter
    position: absolute
    right: 12px
    bottom: 2px
    color: $gray-6
    font-size: $font-small

:deep(.ant-calendar-picker)
  width: 100%
</style>
