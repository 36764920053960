<template>
  <div class="action__information">
    <img src="../../assets/avatar-score.png" width="40" height="40" alt="Avatar de contacto" />
    <h3>Equipo {{ ProductName }}</h3>
    <p>{{ props.data.message }}</p>
  </div>
</template>
<script lang="ts" setup>
import { EmailLogDetails } from "@/@types/emails";
import { ProductName } from "@/app/shared/utils/constants";

const props = defineProps<{
  data: EmailLogDetails | null;
}>();
</script>
<style lang="sass" scoped>
p,
h3
  margin: 0
.action
  &__information
    border: 1px solid $gray-5
    border-radius: 4px
    margin: 5px 8px 0px 8px
    display: flex
    align-items: center
    padding: 10px 20px
    gap: 10px
    background: #F0F5FF
</style>
