<template>
  <a-modal
    :open="open"
    :title="`Ver bloqueo de ${getChannel(blockNumber?.channel.id)} al: +${
      blockNumber?.country_code
    }${blockNumber?.phone_number}`"
    @cancel="handleClose"
    centered
    width="550px"
    class="settings__edit-block-modal"
  >
    <div class="information" v-if="blockNumber">
      <h4>Número telefónico:</h4>
      <p>+{{ blockNumber.country_code }}{{ blockNumber.phone_number }}</p>
      <h4>Fecha y hora de bloqueo:</h4>
      <p>{{ dateToString(blockNumber.created_at, "DD/MM/YYYY [a las] hh:mmA") }}</p>
      <h4>Bloqueado por:</h4>
      <p>{{ blockNumber.blocked_by?.fullname }}</p>
      <h4>Tipo del bloqueo:</h4>
      <p>
        {{ blockNumber.blocked_type ? BlockedTypeName[blockNumber.blocked_type] : "-" }}
      </p>
      <div v-if="blockNumber.channel.id == ChannelType.Whatsapp">
        <h4>Identificadores restringidos:</h4>
        <ul>
          <li v-for="item of blockNumber.channels_customers" :key="item.id">
            {{
              `${item?.alias ?? "Sin alias"} ${item.identifier ? " (" + item.identifier + ")" : ""}`
            }}
          </li>
        </ul>
      </div>
      <div v-if="blockNumber.channel.id != ChannelType.Whatsapp">
        <h4>Campañas restringidas:</h4>
        <ul>
          <li v-for="item of blockNumber.portfolios" :key="item.id">{{ item.name }}</li>
        </ul>
      </div>

      <h4>Motivo del bloqueo:</h4>
      <p>
        {{ blockNumber.observations ?? "Sin motivo" }}
      </p>
      <h4>Origen del bloqueo:</h4>
      <p>{{ BlockedOriginName[blockNumber.origin] }}</p>
    </div>
    <template #footer>
      <div class="footer">
        <a-button @click="handleClose">Cerrar Detalles</a-button>
      </div>
    </template>
  </a-modal>
</template>

<script lang="ts" setup>
import { ChannelType } from "@/app/shared/utils/enums";
import { BlockedOriginName, BlockedTypeName } from "@/app/platformSettings/utils/enums";
import { dateToString } from "@/app/shared/utils/dates.js";

defineProps<{ open: boolean; blockNumber: any }>();

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const handleClose = (e: MouseEvent) => {
  console.log(e);
  emit("update:open", false);
};

const getChannel = (channel: number) => {
  if (channel === ChannelType.Sms) return "SMS";
  else if (channel === ChannelType.Whatsapp) return "Whatsapp";
  else return "Voz";
};
</script>
<style lang="sass" scoped>
.settings__edit-block-modal
  .information
    padding: 20px
  .footer
    display: flex
    justify-content: center
</style>
