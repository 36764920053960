import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todas las plantillas de whatsapp por campañas.
 *
 * @param {string} idPortafolio Id de Campaña
 *
 * @return {Promise<Array>} Lista de plantillas whatsapp.
 */
export async function fetchTemplatesWhatsapp(idPortafolio) {
  const response = await httpManager.get(`/strategies/whatsapp/templates/${idPortafolio}`);
  return response?.data;
}
