const getters = {
  getReportTypifications(state) {
    return state.reportTypifications;
  },
  getReportManagement(state) {
    return state.reportManagement;
  },
  getReportTimePlatformat(state) {
    return state.reportTimePlatform;
  },
  getCommonReports(state) {
    return state.commonReports;
  },
  getDetailReportTime(state) {
    return state.detailReportTime;
  },
};

export default getters;
