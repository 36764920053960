import httpManager from "@/app/shared/utils/httpManager";
/**
 *
 * @return {Promise<Ticket>} Ticket details.
 */
export async function fetchTicketDetails({ tickedId } = {}) {
  if (!tickedId) throw Error("No ticket id specified");

  const url = `/tickets/${tickedId}`;
  const response = await httpManager.get(url);
  return response?.data;
}
