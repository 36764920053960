// import axios from "@/plugins/axios";
import { IBody } from "./interfaces/update.interface";

const url = import.meta.env.VITE_WEBCHAT_WIDGET_API as string;

const headers = {
  "Content-Type": "application/json",
  Authorization: import.meta.env.VITE_WEBCHAT_WIDGET_API_CLIENT_SECRET as string,
};

export default async function (body: IBody) {
  const payload = {
    ...body,
    callbackUrl: `https://backend.${import.meta.env.VITE_HOSTNAME}/webhooks/inbound/message/`,
    callbackUrlToStart: `https://backend.${
      import.meta.env.VITE_HOSTNAME
    }/webhooks/inbound/start-chat/`,
    callbackUrlToFinish: `https://backend.${
      import.meta.env.VITE_HOSTNAME
    }/webhooks/inbound/finish-chat/`,
    appName: "scoreCloud",
    isRegistrationRequired: false,
  };

  // await axios.put<IResponseOk>(url!, payload, { headers });

  await fetch(url, {
    method: "PUT",
    headers,
    body: JSON.stringify(payload),
  });
}
