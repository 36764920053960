import mitt from "mitt";

const emitter = mitt();
emitter.$on = emitter.on;
emitter.$emit = emitter.emit;
emitter.$off = emitter.off;

emitter.clearAll = () => {
  // emitter.all = new Map();
  const listeners = emitter.all.keys();

  for (const key of listeners) {
    emitter.off(key);
  }

  console.log(listeners);
};

export default emitter;
