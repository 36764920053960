import {
  SET_REPORT_TYPIFICATIONS,
  SET_REPORT_MANAGEMENT,
  SET_REPORT_TIME_PLATFORM,
  SET_COMMON_REPORTS,
  SET_REPORT_DETAIL_TIME_PLATFORM,
} from "./mutations-type";

const mutations = {
  [SET_REPORT_TYPIFICATIONS](state, report) {
    state.reportTypifications = report;
  },
  [SET_REPORT_MANAGEMENT](state, report) {
    state.reportManagement = report;
  },
  [SET_REPORT_TIME_PLATFORM](state, report) {
    state.reportTimePlatform = report;
  },
  [SET_COMMON_REPORTS](state, report) {
    state.commonReports = report;
  },
  [SET_REPORT_DETAIL_TIME_PLATFORM](state, report) {
    state.detailReportTime = report;
  },
};

export default mutations;
