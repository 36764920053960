<template>
  <a-table
    :scroll="{ y: 240 }"
    :loading="loading"
    size="middle"
    :pagination="false"
    :columns="columns"
    :data-source="data"
  >
    <template #bodyCell="{ text: color, column, record }">
      <template v-if="column.key === 'color'">
        <div class="tag__color" :style="{ backgroundColor: `#${color}` }"></div>
      </template>

      <template v-if="column.key === 'actions'">
        <a-space :size="14">
          <a-button type="link" class="action__button">
            <EditOutlined @click="handleOpenEditModal(record)" />
          </a-button>

          <a-button type="link" class="action__button">
            <DeleteOutlined @click="handleDeleteTag(record)" />
          </a-button>
        </a-space>
      </template>
    </template>
  </a-table>
</template>

<script>
import { createVNode } from "vue";
import { useStore } from "vuex";

import { EditOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { Modal, message } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

import { tagsTableColumns } from "../../utils/constants";

export default {
  props: {
    data: {
      type: Array,
      required: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    EditOutlined,
    DeleteOutlined,
  },
  setup(props, { emit }) {
    const store = useStore();

    const handleDeleteTag = ({ id: tagId, name: tagName }) => {
      Modal.confirm({
        title: `¿Estás seguro de eliminar la etiqueta "${tagName}"?`,
        content: "Recuerda que esta etiqueta se mantendrá en los tickets asignados.",
        icon: createVNode(QuestionCircleOutlined),
        centered: true,
        onOk: async () => {
          await store.dispatch("deleteTag", tagId);
          message.success("La etiqueta se eliminó correctamente");
          emit("update");
        },
        okText: "Eliminar",
        cancelText: "Cancelar",
      });
    };

    const handleOpenEditModal = (tagData) => {
      emit("on-tag-edit", tagData);
    };

    return {
      columns: tagsTableColumns,
      handleDeleteTag,
      handleOpenEditModal,
    };
  },
};
</script>

<style lang="sass" scoped>
.tag__color
  width: 38px
  height: 8px
  border-radius: 3.5px

.action__button
  padding: 0
</style>
