import httpManager from "@/app/shared/utils/httpManager";

export async function refreshToken(refreshToken) {
  const body = {
    refresh: refreshToken,
  };

  const response = await httpManager.post(`/token/refresh/`, body);

  return response?.data;
}
