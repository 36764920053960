import httpManager from "../../../shared/utils/httpManager";

/**
 * Obtener datos del cliente por su espacio de trabajo.
 *
 * @param {string} workspace Nombre del equipo de trabajo.
 *
 * @return {Object} Datos del cliente.
 */
export async function fetchCustomerByWorkspace(workspace) {
  const response = await httpManager.post(
    "/customers/validate_workspace/",
    { workspace },
    { isPublic: true }
  );
  return response.data;
}
