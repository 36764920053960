<template>
  <section>
    <EditOutlined class="icon" @click="handleShowModal" />
    <a-drawer
      v-model:visible="visible"
      title="Editar Referido"
      destroy-on-close
      :mask-closable="false"
      placement="right"
      width="480"
      keyboard
      :body-style="{ padding: 0 }"
      @close="handleCloseDrawer"
    >
      <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
        <a-form
          class="form"
          layout="vertical"
          hide-required-mark
          ref="formRef"
          :model="formState"
          :rules="rules"
          @submit.prevent="handleSubmit"
        >
          <a-form-item label="Nombre" name="name">
            <a-input v-model:value="formState.name" placeholder="Escribe aquí" />
          </a-form-item>
          <a-form-item label="Tipo de documento" name="type_document">
            <a-input v-model:value="formState.type_document" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Documento" name="number_document">
            <a-input v-model:value="formState.number_document" placeholder="Escribe aquí" />
          </a-form-item>
          <a-form-item label="Teléfono" name="phone">
            <a-row :gutter="24">
              <a-col span="7">
                <selector-country
                  v-model:value="formState.country"
                  @selected="code_phone = $event"
                />
              </a-col>
              <a-col span="17">
                <a-input
                  v-model:value="formState.phone"
                  placeholder="Ingresar número de teléfono"
                  @keypress="handleNumberPhone($event)"
                />
              </a-col>
            </a-row>
          </a-form-item>

          <a-form-item label="Email" name="email">
            <a-input v-model:value="formState.email" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Dirección" name="address">
            <a-input v-model:value="formState.address" placeholder="Escribe aquí" />
          </a-form-item>

          <a-form-item label="Observación" name="observation">
            <a-textarea
              v-model:value="formState.observation"
              placeholder="Escribe aquí"
              :maxlength="commentLimit"
              class="textarea"
            />
            <span class="counter"> {{ formState.observation.length }}/{{ commentLimit }} </span>
          </a-form-item>
        </a-form>
      </custom-scrollbar>

      <div class="footer">
        <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
        <a-button type="primary" @click="handleSubmit"> Guardar cambios</a-button>
      </div>
    </a-drawer>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import { EditOutlined } from "@ant-design/icons-vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    EditOutlined,
    SelectorCountry,
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      name: "",
      type_document: "",
      number_document: "",
      phone: "",
      email: "",
      address: "",
      observation: "",
      country: 1,
    });

    return {
      formState,
      formRef,
    };
  },
  data() {
    return {
      visible: false,
      commentLimit: 200,
    };
  },
  methods: {
    handleNumberPhone(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    handleShowModal() {
      this.visible = true;
      this.formState.name = this.record.name;
      this.formState.type_document = this.record.type_document;
      this.formState.number_document = this.record.number_document;
      this.formState.phone = this.record.phone;
      this.formState.email = this.record.email;
      this.formState.address = this.record.address;
      this.formState.observation = this.record.observation;
      this.formState.country = this.record.country;
    },
    handleCloseDrawer() {
      this.visible = false;
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("updateReferralsContact", {
            referralsId: this.record.id,
            body: toRaw(this.formState),
          });
          this.handleCloseDrawer();
          this.$message.success("Se ha editado información correctamente");
        } catch (error) {
          this.$message.error("Se ha producido un error al editar la información");
        }
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

.ant-form-item
  .textarea
    height: 85px

  .counter
    position: absolute
    right: 12px
    bottom: 2px
    color: $gray-6
    font-size: $font-small

:deep(.ant-calendar-picker)
  width: 100%
.icon
  color: $blue-6
  margin-right: $margin-tiny
</style>
