<template>
  <div
    class="notification-container"
    v-on="!notificationData.is_read ? { click: handleMarkAsRead } : {}"
    :style="{ cursor: notificationData.is_read ? 'default' : 'pointer' }"
  >
    <div class="notification-icons">
      <div class="unread-notification__wrapper">
        <div class="active" v-show="!notificationData.is_read" />
      </div>

      <component :is="mapIcon(notificationData.origin)" />
    </div>

    <div>
      <div class="notification-info">
        <h3>{{ notificationData.title }}</h3>
        <span class="notification-time"> {{ notifiedTime }} </span>
      </div>

      <p>
        {{
          notificationData.schedule
            ? `Tienes un recordatorio programado para el ${notificationDate} a las ${notificationTime} hrs.`
            : notificationData.description
        }}
      </p>
      <a-button
        v-if="notificationData.schedule"
        style="padding: 0; height: 22px"
        type="link"
        @click="handleWatchReminder"
      >
        Ver recordatorio
      </a-button>
    </div>
  </div>

  <a-divider class="divider" />
</template>

<script>
import { computed } from "vue";
import { useStore } from "vuex";
import { useRouter } from "vue-router";

import { mapIcon } from "../../utils/icons";
import { dateToDurationString } from "@/app/shared/utils/dates";

export default {
  props: {
    notificationData: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const store = useStore();
    const router = useRouter();

    const userData = computed(() => store.getters["getDataUser"]);

    const tenantTime = computed(() => {
      const date = new Date();
      const UTC = userData.value?.customer?.data_time?.utc;

      date.setHours(date.getUTCHours() + Number(UTC?.replaceAll("0", "")));
      return date;
    });

    const notifiedTime = computed(() =>
      dateToDurationString(new Date(props?.notificationData?.notification_time), tenantTime.value)
    );

    const handleMarkAsRead = () => {
      store.dispatch("markAsReadNotification", props.notificationData?.id);
      store.commit("DECREMENT_UNREAD_NOTIFICATIONS_COUNT", 1);
    };

    const handleWatchReminder = () => {
      router.push("/schedule");
    };

    const formattedTime = computed(() =>
      props?.notificationData?.notification_time?.replaceAll("-", "/")?.split(" ")
    );

    return {
      notifiedTime,
      mapIcon,
      handleMarkAsRead,
      handleWatchReminder,
      notificationDate: formattedTime.value[0].split("/").reverse().join("/"),
      notificationTime: formattedTime.value[1].substring(0, 5),
    };
  },
};
</script>

<style lang="sass" scoped>
.notification
  &-container
    line-height: 1rem
    padding: 14px 20px
    display: grid
    grid-template: 1fr / 40px 1fr
    gap: 8px

    &:hover
      background-color: $yellow-1

  &-icons
    display: flex
    justify-content: space-between
    align-items: center
    align-self: start

    .unread-notification__wrapper
      width: 6px
      height: 6px

  &-info
    display: flex
    justify-content: space-between
    align-items: center

  &-time
    color: $gray-6
    font-size: 12px

.divider
  margin: 0
  padding: 0 16px
  min-width: calc(100% - 32px)
  max-width: calc(100% - 32px)
  margin: 0 auto

.active
  width: 6px
  height: 6px
  border-radius: 50%
  background-color: $blue-6

h3
  color: $gray-9
  font-size: 14px
  font-weight: 600
  margin-bottom: 4px

p
  margin-bottom: 4px
  color: #595959
</style>
