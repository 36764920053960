export const getNewClientTableColumn = (enableBases, typeBusiness) => [
  ...(typeBusiness == 1
    ? [
        {
          title: "Empresa",
          dataIndex: "entity",
          key: "entity",
          width: "150px",
        },
      ]
    : []),
  {
    title: "Campaña",
    dataIndex: "portfolio",
    key: "portfolio",
    width: "200px",
  },
  ...(enableBases
    ? [
        {
          title: "Base",
          dataIndex: "base",
          key: "base",
          width: "200px",
        },
      ]
    : []),
  {
    title: "Acciones",
    key: "operation",
    fixed: "right",
    width: "120px",
    slots: { customRender: "manage" },
  },
];
