import httpManager from "@/app/shared/utils/httpManager";

const emptyFilters = {
  clients: [],
  custom_clients: [],
  custom_products: [],
};

export async function fetchStrategyEmailClients(
  { portfolio, base, coverage },
  filters = emptyFilters
) {
  const body = { portfolio, base, coverage, filters };
  const response = await httpManager.post("/strategies/filters/email-clients/", body);
  return response.data;
}
