export const columns = [
  {
    title: "Fecha",
    dataIndex: "call_start_date",
    key: "date",
    width: 120,
    // slots: { customRender: "date" },
  },
  {
    title: "Hora",
    dataIndex: "call_start_date",
    key: "hour",
    width: 110,
    // slots: { customRender: "hour" },
  },

  {
    title: "Origen",
    dataIndex: "source",
    key: "source",
    width: 160,
  },
  {
    title: "Usuario",
    dataIndex: "user",
    key: "user",
    width: 200,
  },
  {
    title: "Destino",
    dataIndex: "destination",
    key: "destination",
    width: 120,
  },
  {
    title: "Disposición",
    dataIndex: "disposition",
    key: "disposition",
    width: 140,
  },
  {
    title: "Tipo de marcación",
    dataIndex: "type_dialing",
    key: "type_dialing",
    width: 160,
  },
  {
    title: "Empresa",
    dataIndex: "entity",
    key: "entity",
    width: 200,
  },
  {
    title: "Campaña",
    dataIndex: "portfolio",
    key: "portfolio",
    width: 200,
  },
  {
    title: "Duracion",
    dataIndex: "billsec",
    key: "billsec",
    width: 120,
  },
  {
    title: "Audio",
    key: "action",
    // slots: { customRender: "actionScoped" },
    width: 120,
    fixed: "right",
  },
];
