<template>
  <a-form-item :label="label">
    <a-select
      :getPopupContainer="(triggerNode) => triggerNode.parentNode"
      v-model:value="selected"
      class="form__select"
    >
      <a-select-option :key="0"> Todos los tipos de marcación </a-select-option>
      <a-select-option :key="1"> Manual </a-select-option>
      <a-select-option :key="2"> Predictivo </a-select-option>
      <a-select-option :key="3"> Progresivo </a-select-option>
      <!-- Hidden IVR for PoC -->
      <!--  <a-select-option :key="4"> IVR</a-select-option> -->
      <!-- <a-select-option :key="5"> Entrante </a-select-option> -->
    </a-select>
  </a-form-item>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    value: {
      required: true,
      type: Number,
    },
    label: {
      required: false,
      type: String,
      default: "",
    },
  },
  computed: {
    selected: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
  },
});
</script>

<style lang="sass" scoped>
.form__select
  width: 220px
</style>
