<template>
  <div class="email">
    <a-modal
      :open="open"
      title="Correo a asignar"
      wrapClassName="filterModal"
      @ok="handleOk"
      @cancel="handleClose"
      destroyOnClose
      :maskClosable="false"
      width="482px"
      :footer="null"
      centered
    >
      <a-form class="form" layout="vertical" :model="formState">
        <p>
          Este correo ID #{{ currentMessage?.id }} tiene asociado
          {{ emailOptions.length }} direcciones de correo electrónicos. Elige el principal, a cuál
          se le asignará el contacto en el siguiente paso.
        </p>
        <a-form-item>
          <p>
            Selecciona correo a asignar:
            <a-tooltip placement="bottom">
              <template #title> Seleccionar uno de los correos relacionados al email </template>
              <InfoCircleOutlined />
            </a-tooltip>
          </p>
          <a-select
            :getPopupContainer="(triggerNode) => triggerNode.parentNode"
            :options="emailOptions"
            v-model:value="formState.email"
            placeholder="Seleccionar..."
          ></a-select>
        </a-form-item>
        <footer>
          <a-button
            type="primary"
            class="button button__info"
            @click="contactInfoVisible = true"
            :disabled="!formState.email"
          >
            Elegir
          </a-button>
        </footer>
      </a-form>
    </a-modal>
    <ContactInfo
      :isDisabled="!formState.email"
      :visible="contactInfoVisible"
      :email="formState.email"
      :currentMessage="currentMessage"
      @close="contactInfoVisible = false"
      @closeAssign="handleClose"
    />
  </div>
</template>
<script lang="ts" setup>
import { reactive } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import ContactInfo from "@/app/emails/components/drawers/ContactInfo.vue";
import { EmailDetails } from "@/@types/emails";
import { computed, ref } from "vue";

const props = defineProps<{ open: boolean; currentMessage?: EmailDetails | null }>();

const formState = reactive({
  email: undefined,
});

const contactInfoVisible = ref(false);
const emailOptions = computed(() => {
  if (!props.currentMessage) return [];

  const receivers = props.currentMessage.receivers.map((r) => ({
    value: r?.email,
    label: r?.email,
  }));

  return [...receivers];
});

const initialFormState = { ...formState };

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const resetForm = () => {
  Object.assign(formState, initialFormState);
};

const handleOk = () => {
  emit("update:open", false);
  resetForm();
};
const handleClose = () => {
  emit("update:open", false);
  resetForm();
};
</script>
<style lang="sass" scoped>
.button
  width: 100%
.filterModal
  .form
    padding: 10px 0
    .ant-form-item
      margin-bottom: 20px
    footer
      display: flex
      justify-content: flex-end
      padding: 10px 0
      gap: 10px
</style>
