const defaultTheme = {
  backgroundColor: "#F9FAFF",
  textColor: "#262626",
  blur: "radial-gradient(60.16% 60.16% at 50% 50%, rgba(29, 24, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
};

const light = {
  ...defaultTheme,
};

const sky = {
  ...defaultTheme,
  backgroundColor: "#F0F5FF",
  blur: "radial-gradient(60.16% 60.16% at 50% 50%, rgba(29, 24, 255, 0.4) 0%, rgba(255, 255, 255, 0) 100%)",
};

export const themes = {
  light,
  sky,
};
