<template>
  <section style="contact">
    <div class="contact__container">
      <h2 class="contact__title">Contacto vinculado</h2>

      <div class="contact__info">
        <profile-image :photo="contactData.photo" :name="contactData.clientName" size="small" />

        <p style="margin-bottom: 0; margin-left: 12px">{{ contactData.clientName }}</p>
      </div>

      <div class="contact__tags" v-if="contactData.ticketState !== TicketState.finished">
        <a-tag class="tag-default" v-if="contactData.queue">{{ contactData.queue }}</a-tag>
        <a-tag class="tag-default">ID: #{{ contactData.clientId }}</a-tag>
      </div>

      <a-tag class="tag-custom" v-if="contactData.campaign">{{ contactData.campaign }}</a-tag>

      <slot />
    </div>

    <a-divider />
  </section>
</template>

<script>
import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
import { TicketState } from "@/app/shared/utils/enums";

export default {
  components: {
    ProfileImage,
  },
  props: {
    contactData: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return {
      TicketState,
    };
  },
};
</script>

<style lang="sass" scoped>
.contact
  &__container
    display: grid
    gap: 12px

  &__title
    color: $gray-9
    font-size: $font-normal
    font-weight: 600
    margin-bottom: 0

  &__info
    display: flex
    align-items: center

  &__tags
    display: inline-flex
    gap: 8px

.tag-default
  color: $gray-8

.tag-custom
  background-color: #F0F5FF
  color: $geekblue-7
  border-color: #ADC6FF
  width: fit-content
</style>
