import AccountPage from "@/app/settingsMenu/pages/AccountPage.vue";
import SecurityPage from "@/app/settingsMenu/pages/SecurityPage.vue";
import RecoverAccount from "@/app/settingsMenu/components/myAccount/RecoverAccount.vue";
import DeletedAccount from "@/app/settingsMenu/components/myAccount/DeletedAccount.vue";
import DownloadsPage from "@/app/settingsMenu/pages/DownloadsPage.vue";

export default [
  {
    path: "/account/profile",
    name: "profile",
    component: AccountPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/security",
    name: "security",
    component: SecurityPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/account/downloads",
    name: "my-downloads",
    component: DownloadsPage,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/recover/account/:token",
    name: "recoverAccount",
    component: RecoverAccount,
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  {
    path: "/deleted/account",
    name: "DeletedAccount",
    component: DeletedAccount,
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
];
