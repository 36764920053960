<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M9.46951 1.46967C9.7624 1.17678 10.2373 1.17678 10.5302 1.46967L13.8635 4.803C14.1564 5.0959 14.1564 5.57077 13.8635 5.86366L10.5302 9.197C10.2373 9.48989 9.7624 9.48989 9.46951 9.197C9.17661 8.9041 9.17661 8.42923 9.46951 8.13634L11.5225 6.08333H9.06651C7.93402 6.08333 7.12977 6.08392 6.50039 6.13534C5.87976 6.18605 5.49551 6.28243 5.19103 6.43756C4.57951 6.74915 4.08232 7.24634 3.77073 7.85786C3.6156 8.16234 3.51922 8.54659 3.46851 9.16722C3.41709 9.7966 3.4165 10.6009 3.4165 11.7333V12.6667C3.4165 13.0809 3.08072 13.4167 2.6665 13.4167C2.25229 13.4167 1.9165 13.0809 1.9165 12.6667V11.7333L1.9165 11.7003C1.9165 10.6082 1.91649 9.74269 1.97349 9.04507C2.03178 8.33168 2.15339 7.72805 2.43422 7.17688L3.0929 7.51249L2.43422 7.17688C2.88962 6.28311 3.61628 5.55645 4.51005 5.10105C5.06122 4.82021 5.66485 4.69861 6.37824 4.64032C7.07586 4.58332 7.94136 4.58333 9.03351 4.58333H9.06651H11.5225L9.46951 2.53033C9.17661 2.23744 9.17661 1.76256 9.46951 1.46967Z"
      fill="#262626"
      fill-opacity="0.88"
    />
  </svg>
</template>
