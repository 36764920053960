<template>
  <div class="webchat-preview">
    <div class="webchat-preview__header-section" :style="{ background: color }">
      <div class="header-avatar" :style="`background-image: url('${avatar}');`">
        <UserOutlined v-if="!avatar" />
        <a-badge class="header-badge" status="success" />
      </div>
      <p>{{ alias ? alias : `${ProductName} support` }}</p>
    </div>
    <div class="webchat-content">
      <div class="webchat-preview__msg-section">
        <div v-if="hasWelcomeMsg" class="msg-section-msg-bubble">
          <div class="msg-bubble msg-bubble--sent">
            <p>{{ welcomeMsg }}</p>
          </div>
          <small>{{ sentHour }}</small>
        </div>

        <div class="msg-section-msg-bubble msg-section-msg-bubble--right">
          <div class="msg-bubble msg-bubble--received">
            <p>Hola que tal! Cuánto es el costo del plan Enterprise?</p>
          </div>
          <small>{{ receivedHour }}</small>
        </div>
      </div>

      <div class="webchat-preview__input-section input-section">
        <div class="input-section-grid">
          <PaperClipOutlined class="input-section-icon paper-clip" />
          <div class="input-section-field">
            <p>Escribe aquí...</p>
          </div>

          <SendOutlined class="input-section-icon" />
        </div>
        <small v-if="hasWatermark" class="input-section-watermark">
          Powered by {{ CompanyName }}
        </small>
      </div>
    </div>
  </div>
</template>

<script setup>
import { computed } from "vue";
import { UserOutlined, PaperClipOutlined, SendOutlined } from "@ant-design/icons-vue";
import { CompanyName, ProductName } from "@/app/shared/utils/constants";

const props = defineProps({
  color: {
    type: String,
    default: "#262A31",
  },
  avatar: {
    type: String,
    default: "",
  },
  alias: {
    type: String,
    default: "",
  },
  hasWelcomeMsg: {
    type: Boolean,
    default: true,
  },
  welcomeMsg: {
    type: String,
    default: "Hola buenos días, un gusto saludarte, cuéntanos, ¿En qué podemos ayudarte?",
  },
  hasWatermark: {
    type: Boolean,
    default: true,
  },
});

const msgSentHour = new Date();
msgSentHour.setMinutes(new Date().getMinutes() - 10);

const sentHour = computed(() =>
  msgSentHour.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
);

const msgReceivedHour = new Date();
msgReceivedHour.setMinutes(new Date().getMinutes() - 9);

const receivedHour = computed(() =>
  msgReceivedHour.toLocaleString("en-US", { hour: "numeric", minute: "numeric", hour12: true })
);
</script>

<style lang="sass" scoped>
.webchat-preview
  width: 316px
  height: 100%
  max-height: 470px
  box-shadow: 0px 4px 12px 0px rgba(0, 0, 0, 0.15)
  border-radius: 0px 0px 10px 10px
  overflow: hidden

  &__header-section
    padding: 11px 20px 20px
    display: flex
    align-items: center
    gap: 6px
    border-radius: 10px 10px 0px 0px

    p
      margin: 0
      font-size: $font-medium
      font-weight: 600
      color: $white

  &__msg-section
    padding: 16px 20px
    width: 316px

  &__input-section
    padding: 12px
    box-shadow: 1px -4px 7px 1px rgba(139, 139, 139, 0.15)

.header-avatar
  width: 24px
  height: 24px
  min-width: 24px
  min-height: 24px
  flex-shrink: 0
  display: flex
  justify-content: center

  border-radius: 100%
  background-color: $gray-6
  position: relative

  background-size: cover
  background-position: center

  .anticon-user
    color: $white

.header-badge
  height: 8px
  width: 8px
  position: absolute
  top: 0
  right: 0

  :deep(.ant-badge-status-dot)
    height: 8px
    width: 8px
    border: 1px solid $white
    position: absolute

.webchat-content
  height: calc(100% - 46px)
  margin-top: -10px
  display: grid
  grid-template-rows: 1fr 80px
  background-color: $white
  border-radius: 10px 10px 0px 0px

.msg-section-msg-bubble
  margin-bottom: 8px
  display: flex
  flex-direction: column
  gap: 8px

  &--right
    align-items: end

  small
    font-size: $font-x-small
    color: $gray-7

  p
    margin: 0

.msg-bubble
  width: calc(100% - 60px)
  padding: 6px 12px
  font-size: $font-small
  background-color: #F3F3F9

  &--sent
    border-radius: 8px 8px 8px 0px

  &--received
    border-radius: 8px 8px 0px 8px

.input-section-grid
    display: grid
    grid-template-columns: 20px 1fr 20px
    align-items: center
    gap: 8px

.input-section-icon
    font-size: 20px
    color: #8C8C8C

.input-section-field
    padding: 5px 12px
    border-radius: 2px
    border: 1px solid $gray-5

    p
      margin: 0
      font-size: $font-normal
      color: $gray-6

.input-section-watermark
    margin-top: 4px
    display: block
    text-align: center
    color: $gray-5

.paper-clip
  transform: rotate(180deg)

.anticon-send
  font-size: 18px
</style>
