const getters = {
  getStrategyFilters(state) {
    console.log(state, "STATE");
    return state.filters;
  },
  getStrategyClientFiltersCount(state) {
    if (!state.filters) return 0;
    return countSelectedFilters(state.filters.clients);
  },
  getStrategyCustomFiltersCount(state) {
    if (!state.filters) return 0;
    const clients = countSelectedFilters(state.filters.custom_clients);
    const products = countSelectedFilters(state.filters.custom_products);
    return clients + products;
  },
};

function countSelectedFilters(filters) {
  const values = filters.map((item) => item.values);
  const selecteds = values.filter((value) => isSelected(value));
  return selecteds.length;
}

function isSelected(value) {
  if (!value || value.length == 0) return false;
  const isEmptyRange = value.length == 2 && !value[0] && !value[1];
  if (isEmptyRange) return false;
  return true;
}

export default getters;
