<template>
  <a-drawer
    :visible="visible"
    title="Editar datos personales"
    destroy-on-close
    :mask-closable="false"
    placement="right"
    width="480"
    keyboard
    :body-style="{ padding: 0 }"
    @close="handleCloseDrawer"
  >
    <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
      <a-form
        v-if="dataClient"
        class="form"
        layout="vertical"
        ref="formRef"
        :model="formState"
        @submit.prevent="handleSubmit"
      >
        <div v-for="item in columns" :key="item.index">
          <a-form-item v-if="item.title == 'code'" label="Código" name="code">
            <a-input v-model:value="formState.code" disabled />
          </a-form-item>
          <a-form-item v-else :label="item.title" :name="item.title">
            <a-input
              v-model:value="formState[item.dataIndex]"
              :defaultValue="dataClient[item.dataIndex]"
            />
          </a-form-item>
        </div>
      </a-form>
    </custom-scrollbar>

    <div class="footer">
      <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
      <a-button type="primary" @click="handleSubmit"> Guardar cambios </a-button>
    </div>
  </a-drawer>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw, inject } from "vue";
import { ResponseError } from "@/app/shared/utils/httpManager";
import EventBus from "@/app/shared/utils/eventBus";
export default defineComponent({
  components: {
    CustomScrollbar,
  },
  props: {
    visible: {
      required: true,
      type: Boolean,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({});

    const loadingTableData = inject("loadingTableData");

    return {
      formState,
      formRef,
      loadingTableData,
    };
  },
  data() {
    return {
      customFields: {},
    };
  },
  computed: {
    dataClient() {
      return this.$store.getters["getCustomerPersonalData"];
    },
    getColumns() {
      return this.$store.getters["getPersonalInformation"];
    },
    columns() {
      let cols = this.getColumns;
      cols = cols.filter((col) => col.title != "id" && col.title != "Acciones");
      return cols;
    },
  },
  watch: {
    visible(value) {
      if (value) {
        const data = this.dataClient;
        const columns = this.columns;
        columns.forEach((col) => {
          this.formState[col.title] = data[col.title];
        });
      }
    },
  },
  methods: {
    handleCloseDrawer() {
      this.$emit("onCloseDrawerEdit");
    },
    handleSubmit() {
      this.loadingTableData = true;
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("updateInformationPersonal", {
            contactId: this.itemId,
            body: toRaw(this.formState),
          });
          await this.$store.dispatch("fetchCustomerPersonalData", this.itemId);
          this.handleCloseDrawer();
          this.$message.success("Se ha editado información correctamente");
          EventBus.emit("UPDATE_DATA_CONTACT");
        } catch (error) {
          let errorMessage = "Ha ocurrido un error";

          if (error instanceof ResponseError) errorMessage = error.getErrorMessage();

          this.$message.error(errorMessage);
          // this.$message.error("Se ha producido un error al editar la información");
        }
      });
      this.loadingTableData = false;
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

.flex-column
  display: flex
  flex-direction: column

:deep(.ant-calendar-picker)
  width: 100%
</style>
