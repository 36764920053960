<template>
  <a-modal
    v-model:visible="visibleModal"
    title="Restablecer contraseña"
    :destroy-on-close="true"
    :footer="false"
    :mask-closable="false"
    centered
    width="750px"
    :body-style="bodyStyle"
    @cancel="handleCloseModalHeader"
  >
    <div>
      <p class="text__info">Recuerda que las contarseñas deben ser seguras y de uso personas</p>
      <div>
        <PasswordResetCustom @setPassword="setPassword" @setOldPassword="setOldPassword" />
      </div>
    </div>
    <div class="footer">
      <div>
        <a-button class="footer__back" @click="handleCloseModalHeader"> Cancelar </a-button>
        <a-button
          class="footer__next"
          type="primary"
          @click="handleChangePassword"
          :loading="loading"
          :disabled="isDisabled"
        >
          Guardar
        </a-button>
      </div>
    </div>
  </a-modal>
</template>

<script>
import { useStore } from "vuex";
import PasswordResetCustom from "@/app/settingsMenu/components/securityPermits/form/PasswordResetCustom.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    PasswordResetCustom,
  },
  props: {
    counterInSeconds: {
      type: Number,
      default: 0,
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const store = useStore();
    const bodyStyle = {
      height: "455px",
      loading: false,
    };

    return {
      store,
      bodyStyle,
    };
  },
  data() {
    return {
      password: "",
      old_password: "",
    };
  },
  computed: {
    visibleModal() {
      return this.visible;
    },
    getPassword() {
      return this.$store.getters["getPassword"];
    },
    isDisabled() {
      return this.password === "" || this.old_password === "";
    },
  },
  methods: {
    async handleCloseModalHeader() {
      this.$emit("onCloseModal");
    },
    setPassword($event) {
      this.password = $event;
    },
    setOldPassword($event) {
      this.old_password = $event;
    },
    async handleChangePassword() {
      try {
        const data = {
          old_password: this.old_password,
          new_password: this.password,
        };
        await this.$store.dispatch("updatePasswordWork", data);
        this.$emit("onCloseModal");
        this.$message.success("Su contraseña ha sido actualizada correctamente");
      } catch (error) {
        this.$message.error("Su contraseña actual no coincide");
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.footer
  display: flex
  justify-content: flex-end
  position: absolute
  left: 0
  width: 100%
  height: 56px
  bottom: 0
  background: $white
  align-items: center
  border-top: 1px solid $gray-5
  border-radius: 0px 0px 4px 4px
  &__next
    margin-right: 1rem
  &__back
    margin-right: 1rem

.text__info
  color: #999999
</style>
