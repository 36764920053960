import { TABLE_PAGE_SIZE } from "./constants";
import { ChannelType } from "./enums";

export const isPublicPath = () => {
  const paths = ["/register", "/login", "/reset", "/support", "/dummy", "/billing/expired"];

  return paths.some((p) => window.location.pathname?.includes(p));
};

export function toFormData(object, { avoidEmpty = false, avoidFalse = true } = {}) {
  const getFormatedElement = (el) => {
    const elToFormat = avoidFalse ? (el === 0 ? 0 : el || "") : el;
    return Array.isArray(elToFormat) ? JSON.stringify(elToFormat) : elToFormat;
  };

  const formData = new FormData();

  for (const key in object) {
    if (Object.hasOwnProperty.call(object, key)) {
      const element = object[key];
      // const value = avoidEmpty ? element ?? "" : element;

      if (avoidEmpty) {
        if (element === undefined || element === null) continue;
        else formData.append(key, getFormatedElement(element));
        console.log("IN", formData);
        continue;
      }

      formData.append(key, getFormatedElement(element));
    }
  }

  return formData;
}

/**
 * Filtrar array dado un texto.
 *
 * @param {string} text Texto a buscar en el array.
 * @param {Array} array Listado a filtrar.
 * @param {string} key Propiedad por la cual buscar.
 *
 * @return {Array} Array filtrado.
 */
export function filterArrayByText(text, array, key = "name", deepKey) {
  const regexp = new RegExp(`.*${text}.*`, "i");
  return array.filter((item) => {
    const isMatch = regexp.test(item[key]);
    if (!deepKey) return isMatch;
    const isDeepMatch = item[deepKey].some((item) => regexp.test(item[key]));
    return isMatch || isDeepMatch;
  });
}

export function createInlineWorker(fn, empty = false) {
  let dataArray = ["self.onmessage = ", fn.toString()];
  if (empty) dataArray = [fn.toString()];

  const blob = new Blob(dataArray, { type: "text/javascript" });
  const url = URL.createObjectURL(blob);

  return new Worker(url);
}

/**
 * Check if the numbers in the array are consecutive
 *
 * @param {Array} array Array with numbers
 *
 * @returns {Boolean}
 */
export function isContentConsecutive(array) {
  let isConsecutive = true;

  for (let i = 1; i < array.length; i++) {
    if (array[i] - array[i - 1] !== 1) {
      isConsecutive = false;
      break;
    }
  }

  return isConsecutive;
}

/**
 * @param {Object} channel_customer Object with the data of the channel customer
 *
 * @return {String} The number, name or url of the channel_customer
 */
export function getChannelCustomerIdentifier({ channel, channelCustomer } = {}) {
  console.log("[getChannelCustomerIdentifier]", channel, channelCustomer);

  const keyByChannelId = {
    [ChannelType.Whatsapp]: "phone_number",
    [ChannelType.Messenger]: "name",
    [ChannelType.Instagram]: "name",
    [ChannelType.VoiceInbound]: "url",
  };

  const key = keyByChannelId[channel.id];

  return channelCustomer?.data_parameters?.[key] || "";
}

export function createSameValueAndLabelOptions({ options = [], stringValue = false } = {}) {
  return options?.map((option) => ({
    value: stringValue ? String(option) : option,
    label: option,
  }));
}

export function getCurrentDateTime() {
  return new Intl.DateTimeFormat("az", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
    hour: "2-digit",
    minute: "2-digit",
    second: "2-digit",
    hour12: false,
  })
    .format(new Date())
    .replace(/(:|-)/g, "")
    .replace(/\s/g, "T");
}

export async function generateHash({ string } = {}) {
  const utf8 = new TextEncoder().encode(string);
  const hashBuffer = await crypto.subtle.digest("SHA-256", utf8);
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  return hashArray.map((bytes) => bytes.toString(16).padStart(2, "0")).join("");
}

export function roundHalfToEven({ number = 10.15 }) {
  const stringNumber = String(number);
  const isSecondDecimalFive = stringNumber.at(-1) === "5";

  if (isSecondDecimalFive) {
    const firstDecimalNumber = Number(stringNumber.at(-2));
    const isFirstDecimalEven = firstDecimalNumber % 2 === 0;

    return isFirstDecimalEven
      ? stringNumber.substring(0, stringNumber.length - 1)
      : (Math.round(Number(number) * 10) / 10).toFixed(1);
  }

  return Number(number).toFixed(1);
}

/**
 * @param {Array} args
 *
 * @return {Array} The intersection elements
 */
export function findIntersection(...args) {
  if (args.length === 0) return [];

  if (args.length === 1) return args.at(0);

  const intersection = [];
  const lists = [...args];

  for (let i = 0; i < lists.length; i++) {
    const currentList = lists[i];

    for (let j = 0; j < currentList.length; j++) {
      const currentValue = currentList[j];

      if (intersection.indexOf(currentValue) === -1) {
        if (lists.filter((l) => l.indexOf(currentValue) === -1).length === 0) {
          intersection.push(currentValue);
        }
      }
    }
  }

  return intersection;
}

/**
 * @param {number} length The length of the random generated string
 *
 * @return {String} The generated string
 */
export function generateRandomString(length = 6) {
  const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

  let result = "";
  const charactersLength = characters.length;

  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength));
  }

  return result;
}

export function validateObjectsArrayHasAtLeastOneKeyCompleted(objectsList) {
  let hasAtLeastOne = false;

  for (const state of objectsList) {
    const values = Object.values(state);

    if (!values.length) {
      hasAtLeastOne = true;
      break;
    }

    for (const v of values) {
      const val = v;

      /**
       * Validate if the value of the key is a plain object
       */
      if (
        typeof val === "object" &&
        val !== null &&
        !Array.isArray(val) &&
        !(val instanceof Date)
      ) {
        for (const key in val) {
          if (val[key]) {
            hasAtLeastOne = true;
            break;
          }
        }
      } else {
        if (v) {
          hasAtLeastOne = true;
          break;
        }
      }

      if (hasAtLeastOne) break;
    }
  }

  return hasAtLeastOne;
}

export function getPaginationQuery(curretPage = 1, size = TABLE_PAGE_SIZE) {
  return `limit=${size}&offset=${(curretPage - 1) * size}`;
}
