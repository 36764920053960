import type { Rule } from "ant-design-vue/es/form";

import { ValidationType } from "@/@types/entities/forms";

const onlyNumericRegexp = new RegExp("^[0-9]*$");
const digitRegexp = new RegExp(/\d/);
const emailRegexp = new RegExp(/^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/);

async function TextNumberValidator(rule: Rule, value: string) {
  if (!value) return rule.required ? Promise.reject("Campo requerido") : Promise.resolve();

  return Promise.resolve();
}

async function TextValidator(rule: Rule, value: string) {
  if (!value) return rule.required ? Promise.reject("Campo requerido") : Promise.resolve();

  if (digitRegexp.test(value)) return Promise.reject("No se permiten números");

  return Promise.resolve();
}

async function NumberValidator(rule: Rule, value: string) {
  if (!value) return rule.required ? Promise.reject("Campo requerido") : Promise.resolve();

  if (!onlyNumericRegexp.test(value)) return Promise.reject("Solo se permiten números");

  return Promise.resolve();
}

async function EmailValidator(rule: Rule, value: string) {
  if (!value) return rule.required ? Promise.reject("Campo requerido") : Promise.resolve();

  if (!emailRegexp.test(value)) return Promise.reject("Es un correo no válido");

  return Promise.resolve();
}

type Validator = (rule: Rule, value: string) => Promise<void>;

export const ValidatorByType: Record<ValidationType, Validator> = {
  [ValidationType.TextAndNumber]: TextNumberValidator,
  [ValidationType.Text]: TextValidator,
  [ValidationType.Number]: NumberValidator,
  [ValidationType.Email]: EmailValidator,
};
