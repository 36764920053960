<template>
  <a-form-item :name="input.name" :label="index ? `${index}. ${input.label}` : input.label">
    <p class="instruction">{{ input.instruction }}</p>

    <div class="form">
      <a-form-item :name="[input.name, 'title']" label="Titulo de recordatorio" :rules="rules">
        <a-input
          :value="workspaceFormState?.[input.name]?.title"
          placeholder="Escribir aquí...."
          @update:value="handleChange($event, 'title')"
        />
      </a-form-item>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item :name="[input.name, 'date_schedule']" label="Fecha" :rules="rules">
            <a-date-picker
              :value="workspaceFormState?.[input.name]?.date_schedule"
              style="width: 100%"
              placeholder="-- / -- / --"
              format="DD/MM/YYYY"
              valueFormat="YYYY-MM-DD"
              :disabled-date="disabledDate"
              :show-today="false"
              @update:value="handleChange($event, 'date_schedule')"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item :name="[input.name, 'time_schedule']" label="Hora" :rules="rules">
            <a-time-picker
              :value="workspaceFormState?.[input.name]?.time_schedule"
              style="width: 100%"
              placeholder="00:00"
              format="HH:mm"
              valueFormat="HH:mm"
              :showNow="false"
              @update:value="handleChange($event, 'time_schedule')"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item :name="[input.name, 'description']" label="Descripción" class="description">
        <a-textarea
          :value="workspaceFormState?.[input.name]?.description"
          :maxlength="120"
          placeholder="Escribir aquí...."
          @update:value="handleChange($event, 'description')"
        />
      </a-form-item>
    </div>
  </a-form-item>
</template>

<script setup lang="ts">
import { computed, inject } from "vue";

import { ConditionedAttribute, ElementType, FormElementMap } from "@/@types/entities/forms";
import { disabledDate } from "@/app/shared/utils/dates";

const props = defineProps<{
  input: FormElementMap[ElementType.Reminder];
  index: number;
  isWorkspace: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

interface FormValue {
  title: string | null;
  date_schedule: string | null;
  time_schedule: string | null;
  description: string | null;
}

const workspaceFormState = inject("workspaceFormState") as Record<string, FormValue>;

const isRequired = computed(() => {
  if (!props.isPerProduct) return true;

  return props.hasInputsFilled;
});

const rules = computed(() => [
  {
    required: isRequired.value,
    trigger: props.isPerProduct ? ["blur", "change"] : "change",
  },
]);

const handleChange = (value: string | null, key: keyof FormValue) => {
  if (!props.isWorkspace) return;

  if (!workspaceFormState[props.input.name])
    workspaceFormState[props.input.name] = {
      title: null,
      date_schedule: null,
      time_schedule: null,
      description: null,
    };

  workspaceFormState[props.input.name][key] = value;
};

const initialValue: FormValue = {
  title: null,
  date_schedule: null,
  time_schedule: null,
  description: null,
};

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.form
  margin-top: 12px
  .ant-form-item
    margin-bottom: 16px

.instruction
  color: $gray-7
  line-height: 22px

.description
  margin: 0 !important
</style>
