const mapInfoToDataColumn = (item) => ({
  ...item,
  dataIndex: item.index,
  key: item.index,
});

const getters = {
  getContactsInfoTable(state) {
    const items = state.contacts.items?.map((contact) => {
      return {
        key: contact.id,
        fullName: contact.full_name,
        portfolioName: contact.portfolio_name,
        entityName: contact.entity_name,
        baseName: contact.base_name,
        phones: contact.phones || [],
        emails: contact.emails || [],
        documentNumber: contact.document_number || "",
        lastManagementDate: contact.last_management_date || "--",
        lastInteractionDate: contact.last_interaction_date || "--",
        lastResult: contact.last_result || "--",
        lastManagementUser: contact.last_management_user || "--",
        bestManagementResultMonth: contact.best_management_result_month?.result_name || "--",
        bestManagementGroupMonth: contact.best_management_group_month || "--",
        bestManagementMotiveMonth: contact.best_management_motive_month || "--",
        bestManagementSubmotiveMonth: contact.best_management_submotive_month || "--",
        lastGroup: contact.last_group || "--",
        lastMotive: contact.last_motive || "--",
        lastSubmotive: contact.last_submotive || "--",
        ...contact,
      };
    });
    return {
      ...state.contacts,
      items,
      pagination: state.contacts.pagination,
    };
  },

  getContact(state) {
    const contact = state.contact;
    return {
      id: contact.id || "",
      code: contact.code || "",
      lastInteraction: contact.last_interaction || [],
      lastInteractionDate: contact.last_interaction_date || "--",
      operationType: contact.operation_type || "--",
      fullName: contact.full_name || "",
      phones: contact.phones || [],
      emails: contact.emails || [],
      documentNumber: contact.document_number || "",
      channels: contact.channels || [],
      gender: contact.sex || "--",
      address: contact.address || [],
      birthdate: contact.birthdate || "--",
      observations: contact.observations || "--",
      ...contact,
    };
  },
  getCurrentTablePage(state) {
    return state.currentTablePage;
  },
  getAdvancedFilters(state) {
    return state.advancedFilters;
  },
  getBasicFilters(state) {
    return state.basicFilters;
  },
  getFilterAdvanced(state) {
    return state.filterAdvanced;
  },
  getPersonalInformation(state) {
    return state.personalInformation?.map((item) => mapInfoToDataColumn(item));
  },
  getCustomerPersonalData(state) {
    return state.customerPersonalData;
  },
  getContactCustomFields(state) {
    return state.contactCustomFields?.map((item) => mapInfoToDataColumn(item));
  },
  getContactCustomData(state) {
    return state.contactCustomData;
  },
  getContactEmail(state) {
    return {
      items: state.contactEmail.items,
    };
  },
  getContactPhone(state) {
    return {
      items: state.contactPhone.items,
    };
  },
  getContactAddress(state) {
    return {
      items: state.contactAddress.items,
    };
  },

  getProductsFieldsContact(state) {
    return state.contactProductsFields?.map((item) => mapInfoToDataColumn(item));
  },

  getProductContactData(state) {
    return state.contactProductsData;
  },
  getPaymentsClient(state) {
    return state.paymentsData;
  },
  getPromisesClient(state) {
    return state.promisesData;
  },
  getTypificationsClient(state) {
    return {
      items: state.typificationData.items,
    };
  },
  getRecordsClient(state) {
    return {
      items: state.recordData.items,
    };
  },
  getReferralsData(state) {
    return {
      items: state.referralsData.items,
    };
  },
  getFiltersTypifications(state) {
    return state.filtersTypifications;
  },
  getRecordTicketClient(state) {
    return state.recordTicketClient;
  },
  getRecordMessageTicketClient(state) {
    return state.recordTicketMessageClient;
  },
  getNumberChannels(state) {
    return state.numberChannels;
  },
  getRecordDetailTicket(state) {
    return state.recordDetailTicket;
  },
  getEmailsTab(state) {
    return state.emailsTab;
  },
};

export default getters;
