import { SET_CODECS } from "./mutation-types";
import { fetchAllCodecs } from "@/app/pbx/services";

const actions = {
  async fetchAllCodecs({ commit }) {
    const codecs = await fetchAllCodecs();
    commit(SET_CODECS, codecs);
  },
};
export default actions;
