import { getRecaptchaToken } from "@/app/auth/services/getRecaptchaToken";
import httpManager from "../../shared/utils/httpManager";

export async function createNewCustomer(customer) {
  const url = "/customers/";
  const recaptchaToken = await getRecaptchaToken();

  const response = await httpManager.post(url, { ...customer, recaptcha_token: recaptchaToken });
  return response.data;
}
