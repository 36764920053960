export const columns = [
  {
    title: "Colas",
    dataIndex: "queue_name",
    ellipsis: true,
    width: 300,
    slots: { customRender: "queue_name" },
  },
  {
    title: "Total de llamadas",
    dataIndex: "total_interactions",
    slots: { customRender: "total_calls" },
    width: 180,
  },
  {
    title: "N° NS",
    dataIndex: "sum_service_level",
    // slots: { customRender: "sum_service_level" },
    width: 100,
  },
  {
    title: "NS%",
    dataIndex: "mean_service_level",
    width: 100,
    slots: { customRender: "percent" },
  },
  {
    title: "N° Atendidas",
    dataIndex: "total_answered_calls",
    // slots: { customRender: "total_answered_calls" },
    width: 140,
  },
  {
    title: "% Atendidas",
    dataIndex: "mean_answered_level",
    width: 140,
    slots: { customRender: "percent" },
  },
  {
    title: "N° Abandonadas",
    dataIndex: "total_dropped_calls",
    // slots: { customRender: "total_dropped_calls" },
    width: 160,
  },
  {
    title: "% Abandonadas",
    dataIndex: "mean_dropped_level",
    width: 160,
    slots: { customRender: "percent" },
  },
  {
    title: "TP espera",
    dataIndex: "mean_time_waiting",
    slots: { customRender: "time" },
    width: 140,
  },
  {
    title: "N° en espera",
    dataIndex: "total_calls_waiting",
    width: 140,
  },
  {
    title: "TP hablando",
    dataIndex: "mean_time_speaking",
    slots: { customRender: "time" },
    width: 140,
  },
  {
    title: "TP ACW",
    dataIndex: "mean_time_acw",
    slots: { customRender: "time" },
    width: 140,
  },
  {
    title: "Acciones",
    fixed: "right",
    width: 120,
    slots: { customRender: "actions" },
  },
];
