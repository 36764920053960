const getters = {
  isMyAccountViewActive(state) {
    return state.settings;
  },
  isSecurityPermitsViewActive(state) {
    return state.settings;
  },
  getInfoBussiness(state) {
    return state.enable_bases;
  },
};

export default getters;
