import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener historial de mensajes dado un ticket.
 * @param {number} ticketId Identificador de ticket.
 * @return {Promise<Object>} Historial de mensajes
 */
export async function fetchHistoryByTicket(ticketId, channelId) {
  const url = `/tickets/${ticketId}/history/${channelId}`;
  const { data } = await httpManager.get(url);
  return {
    ticket: data.ticket,
    history: data.history,
    channel_person: data.channel_person,
  };
}
