import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los perfiles.
 *
 * @return {Promise<Profile[]>} Lista de perfiles.
 */
export default async function (search = "") {
  const response = await httpManager.get(`/profiles/?search=${search}`);
  return response?.data?.results;
}
