export const columns = [
  {
    title: "Grupo",
    dataIndex: "group",
    width: 130,
  },
  {
    title: "Resultado",
    dataIndex: "result",
    width: 130,
  },
  {
    title: "Tipo de gestión",
    dataIndex: "type_management",
    width: 160,
  },
  {
    title: "Negociador",
    dataIndex: "negotiator",
    width: 140,
    ellipsis: true,
  },
  {
    dataIndex: "date_management",
    width: 160,
    slots: { title: "managementDateTitle", customRender: "managementDate" },
  },
  {
    dataIndex: "date_pdp",
    width: 160,
    slots: { title: "pdpDateTitle", customRender: "pdpDate" },
  },
  {
    dataIndex: "amount_pdp",
    width: 140,
    slots: { title: "pdpAmountTitle", customRender: "pdpAmount" },
  },
  {
    title: "Teléfono",
    dataIndex: "phone",
    width: 130,
  },
  {
    title: "Comentario",
    dataIndex: "observation",
    slots: { customRender: "observation" },
  },
];
