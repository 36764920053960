const COLUMNS_MANAGE_VARS = [
  {
    title: "ID",
    key: "id",
    dataIndex: "id",
    width: 80,
  },
  {
    title: "Nombre de la variable",
    dataIndex: "name",
    key: "name",
    width: 400,
  },
  {
    title: "Chatbots vinculados",
    key: "chatbots",
    dataIndex: "chatbots",
  },
  {
    title: "Acciones",
    key: "action",
    width: 200,
  },
];

const DATA_TEST = [
  {
    name: "Variable 1",
    id: 1,
    chatbots_assigned: [{ id: 11, name: "Chatbot 1" }],
    order: 1,
  },
];

const PAGINATION_TEST = {
  total: 1,
  current: 1,
};

export { COLUMNS_MANAGE_VARS, DATA_TEST, PAGINATION_TEST };
