import httpManager from "@/app/shared/utils/httpManager";
/**
 * Resolver un solo ticket.
 *
 * @param {number} ticketId Identificador del ticket
 *
 */
export async function resolveSingleTicket(ticketId) {
  const body = {
    tickets: [ticketId],
  };

  await httpManager.post(`/tickets/manager/resolve/`, body);
}
