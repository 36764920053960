<template>
  <a-button
    v-if="floating"
    type="text"
    class="button-floating"
    :class="{ 'button-floating-active': active, hover: !disabled }"
    :disabled="disabled"
  >
    <icon large :type="iconType" color="black" :disabled="disabled" />
    <span class="button__label"> {{ label }} </span>
  </a-button>

  <div v-else class="flex-center">
    <a-tooltip>
      <template #title v-if="iconType === 'outcoming'"> Volver a llamar </template>
      <a-button class="button" :class="getClasses" @click="handleClick" :disabled="disabled">
        <icon :type="iconType" :color="iconColor" :small="!large" :disabled="disabled" />
      </a-button>
    </a-tooltip>
  </div>
</template>

<script>
import Icon from "./Icon.vue";
import icons from "../../common/icons";

const types = ["default", "call", "hold"];

export default {
  components: {
    Icon,
  },
  props: {
    type: { default: "default", validator: (value) => types.includes(value) },
    icon: { default: "phone", validator: (value) => icons.includes(value) },
    large: { type: Boolean, default: false },
    active: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    floating: { type: Boolean, default: false },
    label: { type: String, default: "" },
    stopPropagationEvent: { type: Boolean, default: false },
  },
  computed: {
    getClasses() {
      return {
        "button--disabled": this.disabled,
        "button--active": this.active,
        "button--large": this.large,
        "button--call": this.type === "call",
        "button--hold": this.type === "hold",
        "button--outgoing": this.iconType === "outcoming",
        hover: !this.disabled,
      };
    },
    iconColor() {
      if (this.disabled) return "gray";
      if (this.type === "call") return "white";
      if (this.type === "hold") return this.active ? "white" : "red";
      return this.active ? "black" : "blue";
    },
    iconType() {
      if (this.type === "call") return "phone";
      if (this.type === "hold") return this.active ? "decline" : "muted";
      return this.icon;
    },
  },
  methods: {
    handleClick(event) {
      if (this.disabled) return;
      if (event) {
        if (this.stopPropagationEvent) event.stopPropagation();
        this.$emit("click");
      }
    },
  },
};
</script>

<style lang="sass" scoped>
@import '../../styles/main'

$default-size: 32px
$large-size: 48px

.button
  display: flex
  align-items: center
  justify-content: center
  width: $default-size
  height: $default-size
  border: 1px solid transparent
  background-color: $white
  border-radius: 50%
  cursor: pointer

  &--large
    width: $large-size
    height: $large-size

  &--active
    border: 1px solid #00152933


    &:hover
      border: 1px solid #00152933
      filter: none !important

    &.button--hold
      background-color: $red

      &:hover
        border-color: transparent

  &--call
    border: 1px solid $green
    background-color: $green

  &--hold
    border: 1px solid $red
    background-color: $white

  &--disabled
    border: 1px solid $gray
    background-color: $gray
    cursor: not-allowed

  &--outgoing:hover
    background-color: $gray-4

  &-floating
    height: 68px
    width: 64px
    display: flex
    flex-direction: column
    align-items: center
    justify-content: center
    border-radius: 8px
    padding: 0

    &-active
      background-color: rgba(214, 228, 255, 0.5)

  &__label
    font-size: 12px
    margin-top: 2px

.hover:hover
  filter: brightness(1.1)

.flex-center
  display: flex
  align-items: center
  justify-content: center
</style>
