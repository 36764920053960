<template>
  <div v-if="showRecorder" class="record record-wrapper">
    <a-tooltip>
      <template #title>Eliminar audio</template>

      <DeleteOutlined class="icon icon--delete" @click="handleDeleteClick" />
    </a-tooltip>

    <div class="record-audio" v-if="audioMetadata.recordingSrc">
      <audio class="record-audio" controls>
        <source :src="audioMetadata.recordingSrc" type="audio/mp3" />
        Error del navegador
      </audio>
    </div>

    <div class="record" v-else>
      <div class="record-dot"></div>

      <span>{{ recordingTime }}</span>

      <span>Grabando...</span>

      <a-tooltip>
        <template #title>Detener</template>

        <img
          :src="stopImage"
          class="icon icon-stop"
          alt="stop recording"
          @click="stopRecording({ preventAudioReady: false })"
        />
      </a-tooltip>
    </div>
  </div>
</template>

<script setup lang="ts">
import { reactive, computed } from "vue";
import { useStore } from "vuex";
import { DeleteOutlined } from "@ant-design/icons-vue";
import MicRecorder from "mic-recorder-to-mp3";

import type { RecordMetadata } from "@/@types/workspace";
import type { UserData } from "@/@types/global";
import { secondsToTimeString } from "@/app/shared/utils/dates";

import stopImage from "@/app/workspace/assets/chat/stop-recording.svg?url";

const mediaRecorder = new MicRecorder({ bitrate: 128 });
let recordingTimeInterval: number | undefined = undefined;

const store = useStore();

defineProps<{ showRecorder: boolean }>();

const emit = defineEmits<{
  (event: "audio-ready", audioMetadata: RecordMetadata): void;
  (event: "delete", changeEvent: Boolean): void;
  (event: "stop"): void;
}>();

const userData = computed<UserData>(() => store.getters["getDataUser"]);

const audioMetadata: RecordMetadata = reactive({
  isRecording: false,
  recordingSrc: "",
  recordingFile: null,
  timeInSeconds: 0,
});
const recordingTime = computed<string>(() =>
  secondsToTimeString(audioMetadata.timeInSeconds)?.slice(3)
);

const handleRecordClick = () => {
  startRecording();
  console.log("RECOOOOOORD");
  recordingTimeInterval = window.setInterval(() => audioMetadata.timeInSeconds++, 1000);
};

const handleDeleteClick = async () => {
  await stopRecording({ preventAudioReady: true });
  deleteRecord();
};

const startRecording = () => {
  audioMetadata.isRecording = true;

  mediaRecorder
    .start()
    .then(() => onSuccess())
    .catch((err: Error) => onError(err));

  const onSuccess = () => {
    console.log("Record Started", mediaRecorder.state);
  };

  const onError = (err: Error) => {
    console.log("The following error occured: " + err);
  };
};

const stopRecording = async ({ preventAudioReady = false } = {}) => {
  const { customer, id: userId } = userData.value;

  try {
    const [buffer, blob]: [Blob[], Blob] = await mediaRecorder.stop().getMp3();
    console.log("Data available after MediaRecorder.stop() called.");

    const recordDate = new Date().toISOString();
    const fileName = `${customer?.workspace}-${userId}-${recordDate}.mp3`;

    const file = new File(buffer, fileName, {
      type: blob.type,
      lastModified: Date.now(),
    });
    console.log("Record is a blob");

    audioMetadata.recordingFile = file;
    audioMetadata.recordingSrc = window.URL.createObjectURL(file);
    audioMetadata.isRecording = false;
    audioMetadata.timeInSeconds = 0;

    emit("stop");

    window.clearInterval(recordingTimeInterval);
    console.log("Record Stopped", audioMetadata, preventAudioReady, mediaRecorder.state);

    if (!preventAudioReady) emit("audio-ready", audioMetadata);
  } catch (error) {
    console.log(error);
  }
};

const deleteRecord = (changeEvent = true) => {
  audioMetadata.recordingFile = null;
  audioMetadata.recordingSrc = "";
  emit("delete", changeEvent);
};

defineExpose({ audioMetadata, handleRecordClick, handleDeleteClick, stopRecording, deleteRecord });
</script>

<style lang="sass" scoped>
.record
  display: flex
  align-items: center
  gap: 12px
  max-height: 24px

  &-wrapper
    justify-content: flex-end
    margin-right: 10px
    width: 100%
    gap: 18px

  &-dot
    width: 12px
    height: 12px
    background-color: red
    border-radius: 50%
    animation: recording-pulse 2s infinite ease

  &-audio
    width: 100%
    height: 24px

.icon
  cursor: pointer
  color: $gray-7

  &--delete
    font-size: 18px

  &-stop
    height: 24px

@keyframes recording-pulse
  0%
    background-color: red

  50%
    background-color: transparent

  100%
    background-color: red
</style>
