export const columns = [
  {
    title: "Código del país",
    dataIndex: "country",
    width: 150,
    slots: { customRender: "country" },
  },
  {
    title: "Número",
    dataIndex: "phone",
    width: 120,
    slots: { customRender: "phone" },
  },
  {
    title: "Tipo de contacto",
    dataIndex: "contact_type",
    width: 150,
    slots: { customRender: "contact_type" },
  },
  {
    dataIndex: "last_management_date",
    width: 200,
    slots: { title: "customDate", customRender: "last_management_date" },
    sorter: (a, b) => {
      if (a.last_management_date < b.last_management_date) return -1;
      if (a.last_management_date > b.last_management_date) return 1;
      return 0;
    },
  },
  {
    title: "Tipo de teléfono",
    dataIndex: "type_phone",
    width: 150,
    slots: { customRender: "type_phone" },
  },
  {
    title: "Origen",
    dataIndex: "type_origin",
    width: 120,
  },
  {
    title: "Operador telefónico",
    dataIndex: "phone_operator",
    width: 180,
  },

  {
    title: "Prioridad",
    dataIndex: "priority",
    slots: { customRender: "priority" },
    width: 150,
  },
  {
    title: "Observación",
    dataIndex: "observation",
    slots: { customRender: "observation" },
    width: 200,
  },
  {
    title: "Acciones",
    key: "action",
    slots: { customRender: "actionScoped" },
    width: 100,
    align: "center",
    fixed: "right",
  },
];
