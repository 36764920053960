import {
  SET_DIALPLANS,
  ADD_DIALPLANS_TO_LIST,
  UPDATE_DIALPLAN,
  ADD_DIALPLANRULES,
  SET_DIALPLANRULES,
  UPDATE_DIALPLANRULES,
  REMOVE_DIALPLANRULES,
  SET_PRICES_TRUNKS_DIALPLAN,
  REMOVE_DIALPLAN,
} from "./mutation-types";

const mutations = {
  [SET_DIALPLANS](state, data) {
    state.dialplansInfo = data;
  },
  [ADD_DIALPLANS_TO_LIST](state, newdialplans) {
    state.dialplansInfo.items.push(newdialplans);
  },
  [UPDATE_DIALPLAN](state, dialplan) {
    const index = state.dialplansInfo.items.findIndex((item) => dialplan.id === item.id);
    state.dialplansInfo.items.splice(index, 1, dialplan);
  },
  [SET_DIALPLANRULES](state, newrules) {
    state.rules = newrules;
  },
  [ADD_DIALPLANRULES](state, newrules) {
    state.rules.push(newrules);
  },
  [UPDATE_DIALPLANRULES](state, rule) {
    const index = state.rules.findIndex((_, index) => rule.index === index);
    state.rules.splice(index, 1, rule);
  },
  [REMOVE_DIALPLANRULES](state, ruleIndex) {
    const index = state.rules.findIndex((_, index) => ruleIndex === index);
    state.rules.splice(index, 1);
  },
  [SET_PRICES_TRUNKS_DIALPLAN](state, pricesTrunk) {
    state.pricesTrunk = pricesTrunk;
  },
  [REMOVE_DIALPLAN](state, dialPlanId) {
    const index = state.dialplansInfo.items.findIndex((item) => dialPlanId == item.id);
    state.dialplansInfo.items.splice(index, 1);
  },
};

export default mutations;
