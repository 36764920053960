<template>
  <div class="button-list">
    <div
      v-for="button in mappedButtons"
      :key="button.title"
      class="button"
      :class="{ disabled: button.title === responseText }"
    >
      {{ button.title }}
    </div>
  </div>
</template>

<script setup lang="ts">
import {
  ButtonsMessage,
  MetaButtonMessagePayload,
  WebchatButtonMessagePayload,
  WhatsappButtonMessagePayload,
} from "@/@types/workspace/chats/message";
import { ChannelType } from "@/app/shared/utils/enums";
import { computed } from "vue";

const props = defineProps<{ payload: unknown; channelId: number; responseText?: string }>();

const mappedButtons = computed<ButtonsMessage[]>(() => {
  if (props.channelId === ChannelType.Whatsapp) {
    const typedPayload = props.payload as WhatsappButtonMessagePayload;

    return typedPayload.options;
  }

  if (props.channelId === ChannelType.Messenger) {
    const typedPayload = props.payload as MetaButtonMessagePayload;

    return typedPayload.quick_replies.map((r) => ({
      title: r.title,
      type: r.content_type,
    }));
  }

  if (props.channelId === ChannelType.Inbound) {
    const typedPayload = props.payload as WebchatButtonMessagePayload;

    return typedPayload[0].content.quick_replies.map((r) => ({
      title: r.title,
      type: r.content_type,
    }));
  }

  return [];
});
</script>

<style scoped lang="sass">
.button
  border-radius: 4px
  border: 1px solid $text-tertiary
  text-align: center
  color: $text-secondary
  line-height: 22px

  &-list
    width: 100%
    display: flex
    flex-direction: column
    gap: 5px
    direction: ltr

  &.disabled
    color: $text-disabled
    border-color: $text-disabled
    cursor: not-allowed
</style>
