import { fetchAllCards, updateLoggedBusiness, fetchLoggedBusiness } from "@/app/settings/services";
import { SET_CARDS, SET_BUSINESS, SET_SLOTS } from "./mutation-types";

const actions = {
  async fetchSavedCards({ commit }) {
    const cards = await fetchAllCards();
    commit(SET_CARDS, cards);
  },
  async updateLoggedBusiness({ commit }, business) {
    const enable_bases = await updateLoggedBusiness(business);
    console.log("AAAA", enable_bases);

    commit(SET_BUSINESS, enable_bases);
  },
  async fetchLoggedBusiness({ commit }) {
    const business = await fetchLoggedBusiness();
    commit(SET_SLOTS, business);
  },
};

export default actions;
