import { CallbackEvent, CallbackEventStatus } from "@/@types/platformSettings/callbacks";
import httpManager from "@/app/shared/utils/httpManager";

class ApiService {
  private baseUrl = "/api/v1";

  async generateToken() {
    const url = `${this.baseUrl}/token/`;

    const response = await httpManager.get(url);

    return response.data?.token;
  }

  async updateUrlCallback(urlCallback: string, token: string) {
    const url = `${this.baseUrl}/url-callback/`;

    await httpManager.post(url, { url_callback: urlCallback, token_callback: token });
  }

  async updateCallbackIntegration(newStatus: CallbackEventStatus) {
    const url = `${this.baseUrl}/status-callback/`;

    await httpManager.post(url, { status: newStatus });
  }

  async getCallbackEvents(): Promise<CallbackEvent[]> {
    const url = `${this.baseUrl}/callbacks/`;

    const response = await httpManager.get(url);

    return response.data;
  }

  async updateCallbackEvent(eventId: number, newStatus: CallbackEventStatus) {
    const url = `${this.baseUrl}/callbacks/${eventId}/`;

    await httpManager.put(url, { status: newStatus });
  }

  async updateMessageSendOption(newStatus: Boolean) {
    const url = `/settings/customer/`;

    await httpManager.put(url, { enable_enter: newStatus });
  }
}

export const apiService = new ApiService();
