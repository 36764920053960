import httpManager from "@/app/shared/utils/httpManager";
/**
 * Transferir ticket a agente.
 *
 * @param {number} ticketId Identificador del ticket
 * @param {number} agentId Identificador del agente
 *
 */
export async function transferTicketToAgent(ticketIdList, agentId, origin, isAsync = false) {
  const body = {
    tickets: ticketIdList,
    user: agentId,
    origin: origin, // Origin 2 is for tickets manager | 1 calls or workspace
    is_async: isAsync,
  };

  const response = await httpManager.post(`/tickets/transfer-to-agent/`, body);

  return response.data;
}
