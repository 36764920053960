<template>
  <section>
    <div class="wrapper">
      <a-table
        :data-source="agents"
        :columns="columns"
        :pagination="false"
        :scroll="scroll"
        row-key="id"
        size="middle"
      >
        <template #fullname="{ text }">
          <span> {{ text }} </span>
        </template>

        <template #customState="{ record }">
          <a-badge :color="statusColor(record)" />
          <span> {{ statusText(record) }}</span>
        </template>

        <template #event_management="{ record }">
          <a-badge :color="eventColor(record.event_management)" />
          <span> {{ eventText(record.event_management) }}</span>
        </template>

        <template #event_management_messaging="{ record }">
          <a-badge :color="eventColor(record.event_management_messaging)" />
          <span> {{ eventText(record.event_management_messaging) }}</span>
        </template>

        <template #actions="{ record }">
          <a-tooltip v-if="isCalling(record)">
            <template #title>
              El usuario se encuentra en una llama activa en estos momentos
            </template>
            <a-button type="link" @click="handleRemove(record)" :disabled="isCalling(record)">
              Quitar de cola
            </a-button>
          </a-tooltip>
          <a-button v-else type="link" @click="handleRemove(record)" :disabled="isCalling(record)">
            Quitar de cola
          </a-button>
        </template>
      </a-table>
    </div>

    <viewer-add-agents-modal
      :queue="queue"
      :currentAgents="agents"
      :portfolio="portfolio"
      @refresh="$emit('refresh')"
    />
  </section>
</template>

<script>
import { defineComponent, createVNode } from "vue";
import { Modal } from "ant-design-vue";
import { QuestionCircleOutlined } from "@ant-design/icons-vue";

import { EventData } from "@/app/shared/utils/globalVariables";
import { EventManagement, StateOptions } from "@/app/shared/utils/enums";
import { removeAgentFromQueue } from "@/app/supervision/services";
import { agentsTableColumns } from "@/app/supervision/utils/constants";

import ViewerAddAgentsModal from "../../modal/ViewerAddAgentsModal.vue";
// import { EventManagement } from "@/app/shared/utils/enums";

// const stateColors = {
//   [StateOptions.Connected]: "green",
//   [StateOptions.Refreshment]: "gold",
//   [StateOptions.Dinner]: "orange",
//   [StateOptions.Occupied]: "red",
//   [StateOptions.Services]: "blue",
//   [StateOptions.Training]: "purple",
//   [StateOptions.NotAvailable]: "cyan",
//   [StateOptions.Disconnected]: "gray",
// };

// const stateText = {
//   [StateOptions.Connected]: "Contectado",
//   [StateOptions.Refreshment]: "Refrigerio",
//   [StateOptions.Occupied]: "Ocupado",
//   [StateOptions.Services]: "En servicios",
//   [StateOptions.Training]: "En capacitación",
//   [StateOptions.NotAvailable]: "No disponible",
//   [StateOptions.Disconnected]: "Desconectado",
// };

// const eventColors = {
//   [EventManagement.Ringing]: "#1890FF",
//   [EventManagement.Talking]: "#52C41A",
//   [EventManagement.Acw]: "#FA8C16",
//   [EventManagement.Waiting]: "#13C2C2",
// };
export default defineComponent({
  components: {
    ViewerAddAgentsModal,
  },
  props: {
    queue: { required: true, type: Number },
    portfolio: { required: true, type: Number },
    agents: { required: true, type: Array },
  },
  data() {
    return {
      columns: agentsTableColumns,
      windowHeight: window.innerHeight,
    };
  },
  computed: {
    scroll() {
      return { y: this.windowHeight / 2 - 150 };
    },
  },
  mounted() {
    window.addEventListener("resize", () => {
      this.windowHeight = window.innerHeight;
    });
  },
  methods: {
    isCalling(record) {
      return [EventManagement.Ringing, EventManagement.Talking, EventManagement.Acw].includes(
        record.event_management?.id
      );
    },
    statusColor(record) {
      const event = record.event_management?.id;
      if (record.state === StateOptions.NotAvailable) return "#13C2C2";

      return EventData[event].color;
    },
    statusText(record) {
      const event = record.event_management?.id;

      if (record.state === StateOptions.NotAvailable) return "No disponible";

      return event === EventManagement.Online ? "Disponible" : EventData[event].name;
    },
    eventColor(record) {
      return record ? EventData?.[record?.id]?.color : "#00000026";
    },
    eventText(record) {
      return record ? EventData?.[record?.id]?.name : "Sin evento activo";
    },
    handleRemove(agent) {
      Modal.confirm({
        title: `¿Estás seguro de quitar el usuario de esta cola?`,
        content: `Recuerda que este usuarios ya no recibirá tickets, y sus tickets asigandos serán transferidos a la cola para ser reasignados a otros usuarios disponibles. `,
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.removeAgent(agent.id),
        centered: true,
      });
    },
    async removeAgent(agentId) {
      await removeAgentFromQueue(this.queue, agentId);
      this.$emit("removeAgent", agentId);
    },
  },
});
</script>
<style lang="sass" scoped>
:deep(.ant-badge-status-dot)
  width: 8px
  height: 8px
</style>
