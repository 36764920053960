import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener el detalle de una tipificacion.
 * @param {number} contactId Identificador del ticket del cliente.
 * @return {Promise<Object>} Historial de gestiones.
 */
export async function fetchExternalTypifications(contactId, page) {
  const offset = (page - 1) * 10;

  const url = `/contacts/clients/${contactId}/external-managements/?limit=10&offset=${offset}`;
  const response = await httpManager.get(url);
  return response.data;
}
