<template>
  <section>
    <div class="flexbox">
      <a-table
        row-key="id"
        size="middle"
        :columns="columnsTable"
        :data-source="dataTable"
        :scroll="{ x: 600 }"
        :pagination="pagination"
        class="table__phone"
      >
        <template #ticketId="{ record }">
          <span>#{{ record.id }}</span>
        </template>
        <template #channelInteractive="{ record }">
          <div class="flex">
            <div v-for="item in uniqueChannel(record)" :key="item[0]">
              <img :src="item.icon" alt="icono" style="width: 19px; margin-left: 5px" />
            </div>
          </div>
        </template>

        <template #actionScoped="{ record }">
          <div class="actions">
            <a-tooltip>
              <template #title> Ver información</template>
              <InfoCircleOutlined class="icon" @click="handleOpenDetailTicket(record)" />
            </a-tooltip>
          </div>
        </template>

        <template #created_at="{ record }">
          <span>{{ formatDate(record.created_at) }}</span>
        </template>

        <template v-slot:customDate
          >Fecha y hora
          <a-tooltip>
            <template v-slot:title> Ordenar </template>
            <SwapOutlined :rotate="90" />
          </a-tooltip>
        </template>
      </a-table>
      <TicketDrawerContact
        :item="record"
        v-if="openDrawenTicket"
        @onCloseDrawerTicket="handleCloseDrawerTicket"
        :contactInfo="contactInfo"
      />

      <div class="showList">
        <a @click="handleShowTotalTable" v-if="!openTable && getRecords?.length > 2"
          >ver más ({{ getRecords?.length - 2 }})</a
        >
        <a @click="handleShowDecreaseTable" v-if="openTable">ver menos</a>
      </div>
    </div>
  </section>
</template>

<script>
// TODO-JOHN: Refactor, remover drawer de la tabla
import { columns } from "@/app/contacts/components/tables/record/ContactRecordTableColumns";
import { SwapOutlined, InfoCircleOutlined } from "@ant-design/icons-vue";
import TicketDrawerContact from "@/app/contacts/components/drawers/recordTickets/TicketDrawerContact.vue";

import { TABLE_PAGE_SIZE_DETAIL_CONTACT } from "@/app/shared/utils/constants";
import { humanDateTimeWithSlashFormat } from "@/app/shared/utils/constants";
import { dateToString } from "@/app/shared/utils/dates";
export default {
  components: {
    SwapOutlined,
    TicketDrawerContact,
    InfoCircleOutlined,
  },
  props: {
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
    contactInfo: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      columns,
      isAddPhonesVisible: false,
      dataTable: [],
      pagination: {
        pageSize: TABLE_PAGE_SIZE_DETAIL_CONTACT,
        showSizeChanger: false,
      },
      position: 0,
      openTable: false,
      openDrawenTicket: false,
      record: null,
    };
  },
  computed: {
    records() {
      return this.$store.getters["getRecordsClient"].items?.map((item) => {
        return {
          id: item.id,
          user_assigned: item.user_assigned,
          observations: item.observations,
          created_at: item.created_at,
          channels: item.channels,
        };
      });
    },
    getRecords() {
      return this.$store.getters["getRecordsClient"].items;
    },
    columnsTable() {
      return this.columns;
    },
  },
  watch: {
    getRecords() {
      this.dataTable = this.getRecords;
    },
  },
  created() {
    this.$store.dispatch("fetchRecordsClient", this.itemId);
  },
  methods: {
    handleInformationData($event) {
      this.dataTable = $event;
    },
    handleShowTotalTable() {
      this.pagination.pageSize = this.getRecords.length;
      this.openTable = true;
    },
    handleShowDecreaseTable() {
      this.pagination.pageSize = 2;
      // if (this.dataTable === 2) {
      //   this.dataTable = this.pagination.pageSize;
      // }
      // this.dataTable = [this.records[this.position], this.records[this.position + 1]];
      this.openTable = false;
    },
    handleCloseDrawer() {
      this.isAddPhonesVisible = false;
    },
    handleOpenAddPhones() {
      this.isAddPhonesVisible = true;
    },
    handleOpenDetailTicket(record) {
      this.record = record;
      this.openDrawenTicket = true;
    },
    handleCloseDrawerTicket() {
      this.openDrawenTicket = false;
    },
    uniqueChannel(record) {
      const uniqueGroup = [];
      record.channels.forEach((typification) => {
        const elementAdded = uniqueGroup.find((u) => u.id === typification.id);
        if (!elementAdded) {
          uniqueGroup.push(typification);
        }
      });
      return uniqueGroup.map((item) => item);
    },
    formatDate(date) {
      return dateToString(date, humanDateTimeWithSlashFormat);
    },
  },
};
</script>

<style lang="sass" scoped>
:deep(.ant-table-thead > tr > th .ant-table-column-sorter)
  display: none

.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px

.actions
  display: flex

.phone
  cursor: pointer

.channels
  width: 14px
  height: 14px

.flex
  display: flex

.rigth
  margin-right: 5px

.flexbox
  position: relative

.table__phone
  margin-right: 20px

:deep(.ant-pagination)
  display: none !important

.showList
  margin-left: 5px
  margin-top: 10px
  margin-bottom: 36px
</style>
