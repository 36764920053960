<template>
  <a-form class="form" layout="vertical" ref="formRef" :model="formState">
    <a-form-item v-if="type === 'template'" name="template" :label="selectLabel" required>
      <template #label>
        <slot name="label"></slot>
      </template>
      <div style="display: flex; width: 100%">
        <a-select
          :getPopupContainer="(triggerNode: any) => triggerNode.parentNode"
          :disabled="!queue || disabled || templates.length == 0"
          :loading="loading"
          v-model:value="formState.template"
          :placeholder="
            templates.length > 0
              ? 'Seleccionar plantilla'
              : 'No se encontraron plantillas en esta cola, crea tu primera plantilla'
          "
          :option-label-prop="'customLabel'"
        >
          <a-select-opt-group
            label="Sin parámetros"
            v-if="templatesSMSWithoutParameters.length > 0 && allParameters"
          >
            <a-select-option
              v-for="tem in templatesSMSWithoutParameters"
              :key="tem.id"
              :customLabel="`${tem.name} (sin parámetros)`"
            >
              {{ tem.name }}
            </a-select-option>
          </a-select-opt-group>
          <a-select-opt-group
            label="Con parámetros"
            v-if="templatesSMSWithParameters.length > 0 && allParameters"
          >
            <a-select-option
              v-for="tem in templatesSMSWithParameters"
              :key="tem.id"
              :customLabel="`${tem.name} (con parámetros)`"
            >
              {{ tem.name }}
            </a-select-option>
          </a-select-opt-group>

          <a-select-option
            v-if="!allParameters"
            v-for="tem in templates"
            :key="tem.id"
            :customLabel="tem.name"
          >
            {{ tem.name }}
          </a-select-option>
        </a-select>
        <slot name="preview-button"></slot>
      </div>
    </a-form-item>

    <template v-if="type === 'write'">
      <p>
        Recuerda que la longitud máxima de un mensaje de texto depende de la codificación utilizada.
        <!-- Si tienes dudas sobre el funcionamiento del envío de SMS
        <a href="http://" target="_blank" rel="noopener noreferrer">revisa nuestros docs.</a> -->
      </p>

      <a-alert
        v-if="messageMetadata.encoding === 'UCS-2'"
        class="warning"
        message="Te encuentras usando caracteres especiales (UCS-2)"
        type="warning"
      >
        <template #description>
          El uso de caracteres especiales, reduce el número de caracteres permitidos por mensaje a
          70. Para conocer más haz
          <a href="http://" target="_blank" rel="noopener noreferrer">click aquí.</a>
        </template>
      </a-alert>

      <a-form-item name="message" label="Redacta el mensaje de tu SMS" required>
        <a-textarea
          v-model:value="formState.message"
          :autosize="{ minRows: 3, maxRows: 3 }"
          showCount
          :maxlength="messageMaxLength"
          placeholder="Escribir aquí..."
        />
      </a-form-item>

      <section class="message-data">
        <h2>Calculador de caracteres de SMS</h2>

        <table class="table">
          <tr>
            <td>
              <strong>
                Codificación detectada

                <a-tooltip>
                  <template #title>
                    <strong>GSM-7:</strong> Mensaje que contiene caracteres de texto.
                    <br />
                    <strong>UCS-2:</strong> Mensaje que contiene Unicode (emojis o idiomas
                    internacionales).
                  </template>

                  <InfoCircleOutlined class="icon" />
                </a-tooltip>
              </strong>
            </td>

            <td>
              {{ messageMetadata.encoding }}
            </td>
          </tr>

          <tr>
            <td><strong>Nª de mensajes</strong></td>

            <td>{{ messageMetadata.messages }}</td>
          </tr>

          <tr>
            <td><strong>Total de caracteres usados</strong></td>

            <td>{{ messageMetadata.length }}/{{ messageMaxLength }}</td>
          </tr>

          <tr>
            <td>
              <strong>
                Caracteres restantes por mensaje

                <a-tooltip
                  title="Número de caracteres que te quedan por mensaje sobre el total de caracteres por mensaje."
                >
                  <InfoCircleOutlined class="icon" />
                </a-tooltip>
              </strong>
            </td>

            <td>{{ messageMetadata.remaining }}/{{ messageMetadata.characterPerMessage }}</td>
          </tr>
        </table>
      </section>
    </template>
  </a-form>
</template>

<script setup lang="ts">
import { computed, reactive, ref, watch } from "vue";
import { count } from "sms-length";

import { SmsTemplate } from "@/@types/channels/sms";
import { smsTemplatesService } from "@/app/channels/services/SmsTemplates.service";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { FormInstance } from "ant-design-vue";

const messageMaxLength = 560;

const props = withDefaults(
  defineProps<{
    type: "template" | "write";
    selectLabel?: string;
    queue?: number;
    template?: number;
    message?: string;
    disabled?: boolean;
    withoutParameters?: boolean;
    allParameters?: boolean;
    getPopupContainer?: boolean;
  }>(),
  {
    disabled: false,
    withoutParameters: false,
    allParameters: false,
  }
);

const emit = defineEmits<{
  valid: [isValid: boolean];
  "update:templateId": [templateId?: number];
  "update:template": [templateContent?: string];
  "update:message": [message?: string];
  "update:templateItem": [templateItem?: Object];
}>();

const templates = ref<SmsTemplate[]>([]);
const loading = ref(false);
const formRef = ref<FormInstance>();
const formState: { template?: number; message?: string } = reactive({
  template: props.template || undefined,
  message: props.message || undefined,
});

const messageMetadata = computed(() => {
  const metadata = count(formState.message || "");

  return {
    ...metadata,
    encoding: metadata.encoding === "UTF16" ? "UCS-2" : metadata.encoding,
  } as const;
});

let isTemplateInitialized = !!props.template;

const fetchTemplates = async () => {
  loading.value = true;
  if (props.type === "template" && props.queue)
    templates.value =
      (await smsTemplatesService.getByQueue(props.queue, !props.withoutParameters)) || [];
  loading.value = false;
};

const templatesSMSWithParameters = computed(() => {
  return templates.value.filter((template) => template.parameters?.length > 0);
});

const templatesSMSWithoutParameters = computed(() => {
  return templates.value.filter(
    (template) => template.parameters === null || template.parameters?.length === 0
  );
});

watch(
  () => props.queue,
  async () => {
    console.log("watch queue");
    await fetchTemplates();

    console.log(isTemplateInitialized, "isTemplateInitialized");
    console.log(formState.template, "formState.template");
    if (!isTemplateInitialized && formState.template) {
      formState.template = undefined;
      emit("update:template", "");
      emit("update:templateId", undefined);
      emit("update:templateItem", undefined);
    } else {
      const template = templates.value.find((t) => t.id === formState.template);
      emit("update:template", template?.template);
      emit("update:templateId", formState.template);
      emit("update:templateItem", template);
    }
    isTemplateInitialized = false;
  },
  { immediate: true }
);

watch(formState, () => {
  formRef.value
    ?.validate()
    .then(() => emit("valid", true))
    .catch(() => emit("valid", false));

  if (props.type === "template") {
    const template = templates.value.find((t) => t.id === formState.template);
    emit("update:template", template?.template);
    emit("update:templateId", formState.template);
    emit("update:templateItem", template);
  }

  if (props.type === "write") emit("update:message", formState.message);
});

watch(
  () => props.type,
  async () => {
    console.log("watch type");
    await fetchTemplates();

    emit("update:template", "");
    emit("update:templateId", undefined);
    emit("update:templateItem", undefined);
    emit("update:message", "");
  }
);
</script>

<style scoped lang="sass">
.form
  flex: 1 1 auto

.info
  color: $gray-8
  margin-bottom: 16px

.warning
  margin-bottom: 16px

.message-data
  h2
    font-size: 14px
    font-weight: 600
    margin-bottom: 12px

  .table
    color: $text-primary
    border-top: 1px solid $gray-4
    width: 100%

    .icon
      margin-left: 2px

    td
      padding: 8px

    tr
      border-bottom: 1px solid $gray-4

:deep(.ant-alert-warning)
  padding: 16px !important

:deep(.ant-form-item-label > label)
  width: 100%
:deep(.ant-form-item-label > label > div)
  display: flex
  justify-content: space-between
  align-items: center
  width: 100%
</style>
