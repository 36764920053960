<template>
  <div
    class="cards-channels"
    @click="openModal(channel.type)"
    :class="isDisabled === true ? 'container__disabled' : ''"
  >
    <div class="card-channel" :class="isDisabled === true ? 'container__blue' : ''">
      <!-- Título -->
      <div class="section-icon-channels">
        <div class="check-circle">
          <a-tag :color="channel.namecolor" class="tag">
            {{ channel.status }}
          </a-tag>
          <a-tooltip v-if="isActiveChannel">
            <template #title> Contiene canales activos </template>
            <img :src="checkCircleUrl" alt="check-circle" />
          </a-tooltip>
        </div>
        <div class="section-title">
          <SocialIcon :type="channel.type" />
        </div>
        <div class="description">
          {{ channel.button }}
        </div>
        <div class="title">
          {{ channel.name }}
        </div>
        <div class="description">
          {{ channel.description }}
          <router-link v-if="channel?.descriptionLink" to="/pbx/trunk">
            {{ channel.descriptionLink }}
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SocialIcon from "@/app/channels/components/cards/SocialIcon.vue";
import checkCircleUrl from "@/app/channels/assets/check-circle.svg?url";

export default {
  name: "CardChannel",
  components: { SocialIcon },
  props: {
    channel: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      sms: 4,
      voice: 5,
      facebook: 3,
      whatsApp: 1,
      webChat: 2,
      email: 6,
      checkCircleUrl,
    };
  },
  computed: {
    channelsCustomer() {
      return this.$store.getters["getChannelsCustomersList"] || [];
    },
    isDisabled() {
      return false;
      // return this.channel.type === "Messenger";
      // const workspace = localStorage.getItem("workspace");
      // return !["cocachola", "sandbox"].includes(workspace);
    },
    buttonText() {
      return this.channel.button;
    },
    isActiveChannel() {
      let active = false;
      this.channelsCustomer.forEach((channel) => {
        if (this.channel.channelName === channel.channel_name) {
          active = true;
        }
      });
      return active;
    },
  },
  methods: {
    openModal(type) {
      if (this.isDisabled) return;
      this.$emit("openModal", type);
    },
  },
};
</script>
<style lang="sass" scoped>
.disabled
  opacity: 0.5
  pointer-events: none
.cards-channels
  cursor: pointer
  // min-width: 500px
  max-width: 500px
  min-width: 340px
  flex: 0 0 30%

.card-channel
  background-color: $white
  // box-shadow: 0px 1px 8px rgba(0, 0, 0, 0.1)
  padding: 3% 12px
  border-radius: 8px
  border: 1px solid $gray-5
  box-sizing: border-box
  height: 240px
  // width: 24vw
  // max-width: 320px
  // max-height: 254px
  .section-icon-channels
    margin-bottom: $margin-tiny
    text-align: center
    height: 100%
    display: flex
    flex-direction: column
    .section-title
      display: flex
      justify-content: center
      margin-bottom: 5px
    .title
      font-size: 20px
      font-weight: 600
      margin-bottom: 5px
      .linkblue, .linkgreen
        margin-bottom: 0px
        margin-left: $margin-tiny
        font-weight: normal
      .linkblue
        color: $blue-6 !important
      .linkgreen
        color: $green-6 !important
    .tag
      margin-right: 0px
      margin-bottom: 5px
      border-radius: 2px
  .description
    font-size: 14px
    line-height: 22px
    color: $gray-7
    padding: 0 10%
  .button-link
    text-align: right
  .check-circle
    justify-content: space-between
    display: flex
    img
      width: 20px
      height: 20px

.container__disabled
  cursor: not-allowed

.container__blue
  background-color: $white
  padding: 3% 12px
  border-radius: 8px
  border: 1px solid $gray-5
  box-sizing: border-box
  height: 240px
</style>
