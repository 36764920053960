import {
  SET_LIMITED_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_NEW_NOTIFICATION,
  SET_MARK_AS_READ_NOTIFICATION,
  DELETE_NOTIFICATIONS,
} from "./mutation-types";

const mutations = {
  [SET_LIMITED_NOTIFICATIONS](state, data) {
    state.limitedNotifications = data;
  },
  [SET_NOTIFICATIONS](state, data) {
    state.notifications = data;
    state.notificationsData.push(...data?.results);
  },
  [SET_MARK_AS_READ_NOTIFICATION](state, notificationId) {
    // TODO: Refactor to avoid repeating this code.
    const limitedNotificationsIndex = state.limitedNotifications?.results.findIndex(
      (notification) => notification.id === notificationId
    );

    const currentLimitedNotificationsIndex =
      state.limitedNotifications?.results[limitedNotificationsIndex];
    currentLimitedNotificationsIndex.is_read = true;

    const notificationsDataIndex = state.notificationsData.findIndex(
      (notification) => notification.id === notificationId
    );

    const currentNotificationData = state.notificationsData[notificationsDataIndex];
    currentNotificationData.is_read = true;
  },
  [SET_NEW_NOTIFICATION](state, nweNotification) {
    state.notifications?.results.unshift(nweNotification);
  },
  [DELETE_NOTIFICATIONS](state) {
    state.notificationsData = [];
  },
};

export default mutations;
