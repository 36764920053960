import httpManager from "@/app/shared/utils/httpManager";
import { toFormData } from "@/app/shared/utils/helpers";

/**
 * @param {Object} fileData
 * @param {string} url
 * @returns {Promise<{ file: string, name: string }>} Url data
 */
export async function uploadFile(fileData, url) {
  const body = toFormData(fileData);

  const response = await httpManager.post(url, body);

  return response?.data;
}
