<template>
  <div class="actions" v-if="!floating">
    <!--Rellamar-->
    <icon-button class="actions__icon" :active="showDial" icon="outcoming" @click="handleRecall" />
    <!--Mostrar el dial pad-->
    <icon-button v-if="!show" class="actions__icon" type="hold" @click="handleCloseCall" />
  </div>

  <div v-else class="actions-floating">
    <WorkspaceAcwCard />

    <WebPhoneFooterActions
      class="action-buttons"
      button-type="outcoming"
      :inWorkspace="inWorkspace"
      :primaryDisabled="inAnotherCall || anotherTicketInACW"
      :tooltipText="tooltipText"
      :stopPropagationEvent="stopPropagationEvent"
      @click:primary="inWorkspace ? $emit('click:recall', $event) : handleRecall($event)"
      @click:history="handleHistory"
    />
  </div>
</template>

<script>
import EventBus from "@/app/shared/utils/eventBus";
import { DialingType } from "@/app/shared/utils/enums";
import { WorkspaceEvent } from "@/app/workspace/domain/events";

import IconButton from "../buttons/IconButton.vue";
import WebPhoneFooterActions from "./WebPhoneFooterActions.vue";
import WorkspaceAcwCard from "@/app/workspace/components/cards/WorkspaceAcwCard.vue";

export default {
  components: {
    IconButton,
    WebPhoneFooterActions,
    WorkspaceAcwCard,
  },
  props: {
    showDial: {
      type: Boolean,
      default: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    inWorkspace: {
      type: Boolean,
      default: false,
    },
    inAnotherCall: {
      type: Boolean,
      default: false,
    },
    anotherTicketInACW: {
      type: Boolean,
      default: false,
    },
    stopPropagationEvent: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    client() {
      return this.$store.getters["getWorkspaceClient"];
    },
    getUserData() {
      return this.$store.getters["getDataUser"];
    },
    show() {
      return this.ticket?.time_acw;
    },
    channelId() {
      return this.$route.params.channel;
    },
    tooltipText() {
      if (this.inAnotherCall) return "No puedes marcar mientras estas en una llamada activa";
      if (this.anotherTicketInACW)
        return "No puedes marcar mientras tienes un ACW corriendo, tipifica para poder llamar";

      return null;
    },
    lastOpened() {
      return this.$store.getters["getLastTicketOpened"];
    },
    ticket() {
      return this.lastOpened?.ticket;
    },
    channel() {
      return this.lastOpened?.channel_person;
    },
  },
  methods: {
    handleCloseCall(event) {
      if (event) {
        this.$jssip.reset();
        this.$store.dispatch("setCallFinish", true);
        this.$emit("closeCall");
      }
    },
    async handleRecall(event) {
      this.$store.dispatch("setCallFinish", false);
      if (event) {
        const metaData = {
          name: this.ticket?.client?.name,
          photo: this.ticket?.client?.profile_picture,
          number: this.channel ? this.channel?.identifier : this.ticket?.identifier,
          ticket: this.ticket?.id,
          channel: 1,
          // channel_person: this.channel ? this.channel?.id : this.ticket.channel_person?.id,
          client: this.$route.query.client,
          time_acw: this.ticket?.time_acw,
          type_default_management: this.ticket?.portfolio?.type_default_management,
          channel_customer: this.channel?.channel_customer?.id || this.ticket?.channel_customer,
        };
        this.$jssip.setData(metaData);
        this.$store.commit("SET_DIALING_TYPE", DialingType.Manual);
        // if (this.channel?.identifier) {
        //   this.$jssip._call(
        //     this.channel.identifier,
        //     this.ticket?.client?.name,
        //     this.ticket?.client?.profile_picture,
        //     this.ticket,
        //     DialingType.Manual,
        //     this.user?.id
        //   );
        // } else {
        this.$jssip._call(
          this.channel?.identifier ? this.channel.identifier : this.ticket?.identifier,
          this.ticket?.client?.name,
          this.ticket?.client?.profile_picture,
          this.ticket,
          DialingType.Manual,
          this.user?.id,
          "0",
          "0",
          {},
          this.channel?.code_country
        );
        // }
        this.$router.push({
          path: "/workspace",
          query: {
            ticket: this.ticket.id,
            interaction: this.$route.query.interaction,
            client: this.$route.query.client,
          },
        });
        EventBus.emit(WorkspaceEvent.RECALL);
      }
    },
  },
};
</script>

<style lang="sass" scoped>
.actions
  display: flex
  justify-content: center

  &__icon
    margin: 0 4px

  &-floating
    // margin-top: 30%

.action-buttons
  margin-top: 23px
</style>
