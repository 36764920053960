<template>
  <a-modal
    :visible="true"
    width="450px"
    :body-style="bodyStyle"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img class="image__delete" :src="questionImage" alt="perfil" />
      <div v-if="!multipleContacts">
        <p class="text__alert">
          ¿Estás seguro de {{ status == ContactStatus.Active ? "activar" : "inactivar" }} el
          contacto
        </p>
        <p class="text__alert">“{{ record.fullName }}”?</p>
      </div>
      <div v-else>
        <p class="text__alert">
          ¿Estás seguro de {{ status == ContactStatus.Active ? "activar" : "inactivar" }} los
          {{ countContacts }} contactos
        </p>
        <p class="text__alert">seleccionados?</p>
      </div>
    </div>
    <div class="container__info" v-if="status == ContactStatus.Inactive">
      <p class="text">Recuerda que se tomarán las siguientes acciones:</p>
      <ul>
        <li v-if="multipleContacts">
          Si se encuentran en una
          <span style="font-weight: 600">llamada activa, esta se cortará.</span>
        </li>
        <li>Todos sus tickets activos se finalizarán.</li>
        <li v-if="multipleContacts">
          Se actualizará el módulo de Workspace de los usuario que tengan tickets asociados a esos
          contactos.
        </li>
        <li>Se eliminarán los recordatorios pendientes asociados.</li>
        <li>
          Se eliminará de las estrategias en las que se encuentre y aún no haya sido contactado.
        </li>
        <li v-if="multipleContacts">
          Los productos asociados a estos contactos también serán inactivados
        </li>
        <li v-else>
          Los productos asociados a este contacto también serán inactivados
        </li>
      </ul>
    </div>
    <div class="container__info" v-else>
      <p class="text">Recuerda que los productos que estuvieron asociados a los contactos no se activaran.</p>
    </div>
    <div class="button__disabled">
      <a-button style="width: 48%" @click="handleCloseModalHeader">Cancelar</a-button>
      <a-button
        style="width: 48%"
        class="footer__delete"
        type="primary"
        @click="validationChangeStatus"
        v-if="status == ContactStatus.Inactive"
      >
        {{ multipleContacts ? "Inactivar contactos" : "Inactivar contacto" }}
      </a-button>
      <a-button
        style="width: 48%"
        class="footer__delete"
        type="primary"
        @click="validationChangeStatus"
        v-else-if="status == ContactStatus.Active"
      >
        {{ multipleContacts ? "Activar contactos" : "Activar contacto" }}
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, createVNode } from "vue";
import { UserStatus } from "@/app/shared/utils/enums";
// import { deleteUserSocket } from "@/app/shared/services";
import { confirmIsCallUser } from "@/app/contacts/services";
import { Modal } from "ant-design-vue";
import { ExclamationCircleOutlined } from "@ant-design/icons-vue";
import questionImage from "@/app/users/assets/profiles/question.svg?url";
import { ContactStatus } from "@/app/contacts/utils/enums";

export default defineComponent({
  name: "InactiveUserModal",
  components: {},
  props: {
    record: {
      type: Object,
      default: null,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
    multipleContacts: {
      required: false,
      type: Boolean,
      default: false,
    },
    countContacts: {
      required: false,
      type: Number,
      default: 0,
    },
    status: {
      required: true,
      type: Boolean,
    },
  },
  setup(props) {
    const bodyStyle = {
      // height: props.multipleContacts ? "400px" : "370px",
      loading: false,
    };
    const handleConfirmIsCall = () => {
      Modal.confirm({
        title: "Este contacto se encuentra actualmente en una llamada activa",
        content:
          "Para poder eliminar su número telefónico o al contacto en sí, se debe esperar que dé por finalizada su llamada.",
        icon: createVNode(ExclamationCircleOutlined),
        okText: "Aceptar",
        centered: true,
      });
    };
    return {
      bodyStyle,
      handleConfirmIsCall,
      questionImage,
      ContactStatus,
    };
  },
  data() {
    return {
      UserStatus,
    };
  },
  computed: {
    usersInfo() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleCloseModalHeader() {
      this.$emit("onCancel");
    },
    async validationChangeStatus() {
      if (!this.multipleContacts) {
        if (this.status == ContactStatus.Inactive) {
          try {
            await confirmIsCallUser({
              client: this.record.id,
            });
            this.handleChangeStatusContact();
          } catch (error) {
            this.handleConfirmIsCall();
          }
        } else {
          this.handleChangeStatusContact();
        }
      } else {
        this.$emit("changeStatus");
        this.$emit("onCancel");
      }
    },
    async handleChangeStatusContact() {
      try {
        await this.$store.dispatch("fetchChangeStatusContact", {
          contactId: this.itemId,
          status: this.status,
        });
        this.$emit("onCancel");
        this.$emit("onCloseDrawer");
        await this.$store.dispatch("fetchContactsExtern");
        await this.$store.dispatch("fetchContact", this.itemId);
        this.$message.success(
          `Se ha ${this.status == ContactStatus.Inactive ? "inactivado" : "activado"} el contacto ${
            this.record.fullName
          } correctamente`
        );
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        this.$message.error(errorMessage);
      }
    },
  },
});
</script>

<style lang="sass" scoped>
.image__delete
  margin-bottom: 10px
  margin-top: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin: 0

.button__disabled
  margin-top: 10px

.footer__delete
  // margin-bottom: 20px
  margin-top: 18px
  margin-left: 10px

.container__inactive
  text-align: center
  color: $gray-13

.container__info
  // margin-bottom: 18px
  color: $gray-8

.text
  margin-top: 20px
  margin-bottom: 12px
</style>
