<template>
  <div class="center-web-phone">
    <!-- <div class="change-state-message fixed" v-if="waitingChangeStateMessage"> -->
    <!--   Al terminar esta gestión, tu estado cambiará a “{{ waitingChangeStateMessage.text }}” -->
    <!-- </div> -->
    <InformationCard fixed />

    <web-phone-mock :name="name" :photo="photo" :number="number" />
  </div>
</template>

<script>
import { inject } from "vue";

import InformationCard from "@/packages/webphone/components/cards/InformationCard.vue";

export default {
  components: {
    InformationCard,
  },
  setup() {
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    return { ticket, channel };
  },
  computed: {
    name() {
      return this.ticket?.client?.name || "No identificado";
    },
    photo() {
      return this.ticket?.client?.profile_picture;
    },
    number() {
      return this.channel?.identifier || this.ticket?.identifier;
    },
    waitingChangeStateMessage() {
      return this.$store.getters["getWaitingChangeStateMessage"];
    },
  },
};
</script>
<style lang="sass" scoped>
.center-web-phone
  position: relative
  // display: grid
  // place-content: center
  // height: 80%
</style>
