<template>
  <WorkspaceChatHeader @update:ticket="$emit('update:ticket')" :isChat="true" />

  <div v-if="isSms" class="warning">
    El canal de SMS permite por ahora únicamente envío de plantillas sin
    <br />
    respuesta del contacto.
  </div>

  <div
    class="chat__container"
    :class="{ 'no-input': isSms }"
    :style="getBackground()"
    v-if="history"
  >
    <WorkspaceChatHistory
      :history="history"
      :expanded="true"
      :channelId="channel?.channel?.id"
      :inputHeight="inputHeight"
    />
    <InputSection
      v-if="channel?.channel?.id !== ChannelType.Sms"
      class="chat__input"
      :channelId="channel?.channel?.id"
      :disabled="isBlockedByBalance || isBlockedByTimeout"
      :tooltipText="tooltipText"
      @sended="handleMessageSent"
    />
  </div>

  <div v-else class="skeleton">
    <a-skeleton active />
    <a-skeleton active />
  </div>
</template>

<script>
import { inject } from "vue";
import { debounce } from "lodash";

import { ChannelType } from "@/app/shared/utils/enums";
import EventBus from "@/app/shared/utils/eventBus";

import WorkspaceChatHeader from "@/app/workspace/components/chat/WorkspaceChatHeader.vue";
import WorkspaceChatHistory from "@/app/workspace/components/chat/WorkspaceChatHistory.vue";
import InputSection from "@/app/workspace/components/chat/input/InputSection.vue";

import backgroundImage from "@/app/workspace/assets/bg.webp";
import { EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";

export default {
  components: {
    WorkspaceChatHeader,
    WorkspaceChatHistory,
    InputSection,
  },
  setup() {
    const ticket = inject("selectedTicket");
    // This is now the interaction
    const channel = inject("selectedChannel");

    const getBackground = () => `background-image: url(${backgroundImage})`;

    return { ticket, channel, ChannelType, getBackground };
  },
  computed: {
    isSms() {
      return this.channel?.channel?.id === ChannelType.Sms;
    },
    user() {
      return this.$store.getters["getDataUser"];
    },
    dataUserState() {
      return this.$store.getters["getDataUserState"];
    },
    history() {
      const data = this.$store.getters["getWorkspaceMessageHistory"];

      if (!data) {
        return [];
      }

      const messages = data.messages || [];
      const summaries = data.summaries || [];
      const summaryWorspace = summaries.filter((summary) => summary.origin === "workspace");

      const messageHistory = [
        ...messages.map((message) => ({ ...message, type: "message" })),
        ...summaryWorspace.map((summary) => ({ ...summary, type: "summary" })),
      ];

      const sortedMessages = messageHistory.sort(
        (a, b) => new Date(a.created_at) - new Date(b.created_at)
      );

      console.log(sortedMessages, "FINAL ARRAY MESSAGES");

      return sortedMessages;
    },
    isBlockedByBalance() {
      const lastMessage = this.history.at(-1);

      return lastMessage?.blocked;
    },
    isBlockedByTimeout() {
      return this.channel?.blocked;
    },
    tooltipText() {
      if (this.isBlockedByBalance)
        return "No cuenta con saldo suficiente para enviar un mensaje de whatsapp";

      if (this.isBlockedByTimeout) {
        const channelId = this.channel?.channel?.id;

        switch (channelId) {
          case ChannelType.Whatsapp:
            return "Han pasado más de 24h sin respuesta del contacto o has enviado una plantilla y no hay respuesta.";

          case ChannelType.Inbound:
            return "Se ha excedido el tiempo de inactividad por parte del contacto o ha reiniciado su Web Chat.";

          default:
            break;
        }
      }

      return "";
    },
  },
  async created() {
    await this.fetchHistory();

    EventBus.on("updateHistory", () => {
      console.log("updateHistory");
      this.fetchHistory();
    });

    // if (this.ticket.unread_messages) {
    //   await refreshTickets({
    //     customer_id: this.user?.customer?.id,
    //     iduser: this.user?.id,
    //   });
    // }
  },
  async mounted() {
    console.log(this.$store.getters["getEventMessaging"], 'this.$store.getters["getEventMessaging"]')
    if (this.$store.getters["getEventMessaging"] == -1) {
      this.registerEvent(EventManagement.Opened);
    }
  },
  methods: {
    async fetchHistory(prevent = false) {
      const ticketId = this.$route.query.ticket;
      const interactionId = this.$route.query.interaction;
      const payload = { ticket: ticketId, interaction: interactionId, prevent };
      await this.$store.dispatch("fetchHistoryByTicket", payload);
    },
    handleMessageSent() {
      this.fetchHistory(true);
    },
    async registerEvent(event) {
      const ticket = this.$route.query.ticket;
      await registerEventManagement(event, {
        open_ticket: ticket,
        menu: 23,
        state: this.dataUserState,
      });
      this.$store.dispatch("setEventMessaging", event);
    },
  },
  sockets: {
    refreshHistory: debounce(function ({ ticketid }) {
      if (ticketid != this.$route.query.ticket) return;
      console.log(new Date().toLocaleTimeString());
      this.fetchHistory(true);
    }, 1000),
  },
};
</script>

<style lang="sass" scoped>
.skeleton
  padding: 0 8px

.chat
  &__container
    // height: calc(100% - 118px)
    // height: calc(100% - 167px)
    // height: 100%
    display: flex
    flex-direction: column
    flex: 1 1 auto
    min-height: 0

    &.no-input
      // height: calc(100% - 118px)

  &__input
    justify-self: flex-end

.warning
  background-color: #FFE58F
  color: $text-primary
  font-size: 12px
  text-align: center
  padding: 4px 12px
</style>
