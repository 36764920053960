import PlatformSettingsPage from "./pages/PlatformSettingsPage.vue";

export default [
  {
    path: "/settings",
    name: "settings",
    component: PlatformSettingsPage,
    meta: {
      requiresAuth: true,
    },
  },
];
