import ImportsPage from "@/app/imports/pages/ImportsPage.vue";
// import ImportsTemplate from "@/app/imports/components/templates/ImportsTemplate";
// import TemplatesTemplate from "@/app/imports/components/templates/TemplatesTemplate";
// import TemplatesClientTemplate from "@/app/imports/components/templates/TemplatesClientTemplate";
// import TemplatesProductTemplate from "@/app/imports/components/templates/TemplatesProductTemplate";

const commonMeta = {
  requiresAuth: true,
};

export default [
  {
    path: "/imports",
    name: "imports",
    component: ImportsPage,
    redirect: "/imports/board",
    children: [
      {
        path: "board",
        redirect: "/crm/imports",
        // component: ImportsTemplate,
        // meta: commonMeta,
      },
      {
        path: "templates",
        redirect: "/crm/templates",
        // component: TemplatesTemplate,
        // meta: commonMeta,
      },
      {
        path: "templates/edit-contacts",
        redirect: "/crm/templates/edit-contacts",
        // component: TemplatesClientTemplate,
        // meta: commonMeta,
      },
      {
        path: "templates/edit-product",
        redirect: "/crm/templates/edit-product",
        // component: TemplatesProductTemplate,
        // meta: commonMeta,
      },
    ],
    meta: commonMeta,
  },
];
