import httpManager from "@/app/shared/utils/httpManager";

/**
 *
 * @param {Object} filters
 * @returns {Promise<Object>} chatbotsList
 */

export async function fetchChatbotsList(filters) {
  const { name, channel, status } = filters;
  const url = `/chatbots/?name=${name}&channel=${channel || ""}&status=${status || ""}`;
  const response = await httpManager.get(url);
  return response.data;
}
