<template>
  <main-container>
    <SettingAccount />
  </main-container>
</template>
<script>
import SettingAccount from "@/app/settingsMenu/components/myAccount/SettingAccount.vue";
import MainContainer from "@/app/shared/components/organisms/MainContainer.vue";

export default {
  name: "AccountTemplate",
  components: {
    SettingAccount,
    MainContainer,
  },
  computed: {
    isMyAccountViewActive() {
      return this.$store.getters["isMyAccountViewActive"];
    },
  },

  methods: {
    handleCancelTemplate() {
      this.$store.dispatch("setMyAccountViewStatus", true);
    },
  },
};
</script>
<style lang="sass" scoped>
.container__info
  position: absolute
  left: 0
  right: 0
  bottom: 0
  top: 0
</style>
