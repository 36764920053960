export default [
  {
    path: "/chatbots",
    name: "chatbots",
    redirect: "/flows/chatbots",
    // component: Chatbots,
    // meta: {
    //   requiresAuth: true,
    // },
  },
  {
    path: "/chatbots/tree/:id",
    name: "id",
    redirect: (to) => ({ path: `/flows/chatbots/tree/${to.params.id}` }),
    // component: Chatbot,
    // meta: {
    //   requiresAuth: true,
    // },
  },
];
