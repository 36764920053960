import { Condition, ConditionedAttribute } from "@/@types/entities/forms";
import {
  AttributePerAction,
  EvaluationPerConditionType,
  LogicPerType,
} from "@/app/entities/utils/forms/conditionMaps";

function getIfStatementEvaluation(
  condition: Condition,
  formState: Record<string, string | undefined | null>
): boolean {
  const evaluation = condition.form_if.map((ifItem) => {
    if (!ifItem.condition_type || !ifItem.input_name) return false;

    const evaluateFunction = EvaluationPerConditionType[ifItem.condition_type];
    const inputValue = formState[ifItem.input_name] || "";
    const comparisonValue = ifItem.comparison_value || "";

    return evaluateFunction(inputValue, comparisonValue);
  });

  const logicalOperation = LogicPerType[condition.logical_type];
  return logicalOperation(evaluation);
}

function getCondition(
  condition: Condition,
  ifSectionResult: boolean
): Record<string, ConditionedAttribute> {
  const conditionBody: Record<string, ConditionedAttribute> = {};

  condition.form_else.forEach((elseItem) => {
    if (!elseItem.input_name || !elseItem.action_type) return;

    // const ifSectionResult = getIfStatementEvaluation(condition, formState);

    const getAttribute = AttributePerAction[elseItem.action_type];

    const attribute = getAttribute(ifSectionResult);

    conditionBody[elseItem.input_name] = { ...conditionBody[elseItem.input_name], ...attribute };
  });

  return conditionBody;
}

const alreadyAppliedConditions = new Map<string, boolean>();

export function buildConditionalsBody(
  conditionals: Condition[],
  formState: Record<string, string | undefined | null>
) {
  const body: Record<string, ConditionedAttribute> = {};

  for (const c of conditionals) {
    const ifSectionResult = getIfStatementEvaluation(c, formState);
    const condition = getCondition(c, ifSectionResult);

    console.log({ c, condition, ifSectionResult });

    for (const key in condition) {
      if (alreadyAppliedConditions.get(key)) continue;

      body[key] = { ...body[key], ...condition[key] };

      alreadyAppliedConditions.set(key, ifSectionResult);
    }
  }

  alreadyAppliedConditions.clear();

  return body;
}
