export const VisibilityModalText = {
  toInactive: {
    title:
      "¿Estás seguro de desactivar la visibilidad de tu ambiente de trabajo en el buscador de inicio de sesión?",
    content:
      "Recuerda que el nombre de tu ambiente de trabajo ya no se mostrará en nuestro buscador público. Tus usuarios podrán acceder a la plataforma utilizando tu URL personalizada.",
    button: "Desactivar",
  },
  toActive: {
    title:
      "¿Estás seguro de activar la visibilidad de tu ambiente de trabajo en el buscador de inicio de sesión?",
    content:
      "Recuerda que el nombre de tu ambiente de trabajo volverá a mostrarse en nuestro buscador público. Tus usuarios podrán acceder a la plataforma por este medio o utilizando tu URL personalizada.",
    button: "Activar",
  },
} as const;
