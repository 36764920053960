import {
  ADD_NEW_USER,
  ADD_USER_TO_LIST,
  CLEAR_USER,
  REMOVE_USER,
  SET_USER,
  SET_USERS,
  UPDATE_CLIENT_EMAIL,
} from "./mutation-types";

const mutations = {
  [SET_USERS](state, data) {
    state.usersInfo = data;
  },
  [ADD_USER_TO_LIST](state, newUser) {
    state.usersInfo.items.unshift(newUser);
  },
  [SET_USER](state, user) {
    state.user = user;
  },
  [ADD_NEW_USER](state, payload) {
    const index = state.usersInfo.items.findIndex((user) => {
      return user.id == payload.id;
    });
    state.usersInfo.items.splice(index, 1, payload.dataUser);
  },
  [CLEAR_USER](state) {
    state.user = {};
  },
  [REMOVE_USER](state, userId) {
    const index = state.usersInfo.items.findIndex((item) => userId === item.id);
    state.usersInfo.items.splice(index, 1);
  },
  [UPDATE_CLIENT_EMAIL](state, user) {
    const index = state.usersInfo.items.findIndex((item) => user.id === item.id);
    state.usersInfo.items.splice(index, 1, user);
  },
};

export default mutations;
