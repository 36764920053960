import { computed } from "vue";
import { useStore } from "vuex";

import { UserData } from "@/@types/global";
import { RolTypes } from "@/app/shared/utils/enums";

type RoleId = (typeof RolTypes)[keyof typeof RolTypes];

export function useUserNeedPermissions(neededRoles: RoleId[]) {
  const store = useStore();

  const userData = computed<UserData>(() => store.getters["getDataUser"]);

  return !neededRoles.includes(userData.value?.role?.id as RoleId);
}
