<template>
  <section class="header" :style="background">
    <div class="buttons">
      <div class="button">
        <a type="primary" @click="handleClick">Iniciar Sesión </a>
      </div>
      <div class="button">
        <a type="primary" @click="routerRegister"> Registrarse </a>
      </div>
      <welcome-template v-if="showTemplate" @onHide="handleClose" />
    </div>
  </section>
</template>

<script>
import WelcomeTemplate from "@/app/auth/components/templates/WelcomeTemplate.vue";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import EventBus from "@/app/shared/utils/eventBus";
import httpManager from "@/app/shared/utils/httpManager";

import templateImage from "@/app/auth/assets/template.png";

export default {
  components: {
    WelcomeTemplate,
  },
  data() {
    return {
      showTemplate: this.$route.query?.searchModal || false,
      background: {
        backgroundImage: `url(${templateImage})`,
      },
    };
  },
  mounted() {
    this.$store.commit("SET_TEMPORAL_FORM", null);
    const hostname = window.location.hostname;
    const inLocalHost = hostname == "localhost";
    const workspace = localStorage.getItem("workspace");
    const inTenant = !(hostname.split(".").length <= 2 || hostname.split(".")[0] === "www");

    httpManager.updateBaseURL(`https://backend.${import.meta.env.VITE_HOSTNAME}`);

    if (inTenant || inLocalHost) {
      if (!workspace && !inLocalHost) localStorage.setItem("workspace", hostname.split(".")[0]);

      if (!inLocalHost && window.location.href.includes("/home")) {
        this.$router.push("/login");
      }
    } else {
      localStorage.clear();
    }
  },
  created() {
    EventBus.$on(WorkspaceEvent.OPEN_MODAL_WORKSPACE, () => {
      this.showTemplate = true;
    });
  },
  methods: {
    handleClick() {
      this.showTemplate = true;
    },
    routerRegister() {
      this.$router.push("/register");
    },
    handleClose() {
      this.showTemplate = false;
    },
  },
};
</script>
<style lang="sass" scoped>
.header
  display: flex
  justify-content: flex-end
  margin-right: 30px
  height: 680px
  background-repeat: no-repeat

.buttons
  display: flex

.button
  margin-left: 30px
  margin-top: 90px
  & a
    font-size: 16px
</style>
