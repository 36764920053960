import httpManager from "@/app/shared/utils/httpManager";
import { ResponseFetchDownloads } from "@/@types/settingsMenu/downloads";

/**
 * Obtener descargas realizas por el usuario
 * @return {Promise<Array>} Lista de descargas.
 */
export async function fetchDownloads({
  name = "",
  start_date = "",
  end_date = "",
  next_url = "",
}): Promise<ResponseFetchDownloads> {
  try {
    let response;
    if (next_url) {
      response = await httpManager.get(next_url);
    } else
      response = await httpManager.get(
        `/processes?name=${name}&start_date=${start_date}&end_date=${end_date}`
      );

    return { success: true, data: response?.data };
  } catch (error) {
    return { success: false, message: "Ocurrió un error" };
  }
}
