import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza nuevo Correo electronico.
 * @param {number} contactId Identificador del contacto
 * @param {Object} body Datos del email.
 *
 * @return {Promise<Object>} Datos del correo creado
 */
export async function updateInformationCustom(contactId, body) {
  const url = `/contacts/clients/${contactId}/custom-values/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
