<template>
  <div :class="providerWhatsapp.type == 'securitec' ? 'card-channel--selected' : 'card-channel'">
    <div v-if="providerWhatsapp.type == 'securitec'" class="tag-recommended">
      <a-tag color="#108ee9">Recomendado</a-tag>
    </div>
    <div class="container">
      <!-- Logo Proveedor -->
      <div class="logo">
        <ProviderIcon :type="providerWhatsapp.type" />
      </div>
      <div class="title">
        {{ providerWhatsapp.title }}
      </div>
      <!-- Lista de Beneficios -->
      <div class="benefit">
        <p
          v-for="(item, index) in providerWhatsapp.description"
          :key="index"
          class="descriptions-provider"
        >
          <a-row :gutter="8">
            <a-col span="2">
              <CheckOutlined class="icon-check" theme="filled" />
            </a-col>
            <a-col span="22" class="item">
              <span> {{ item }} </span>
            </a-col>
          </a-row>
        </p>
      </div>
      <div v-if="providerWhatsapp.type == 'securitec'" class="item__extra">
        ¿Quieres saber más? <a href="#">Mira los detalles aqui</a>
      </div>
      <div class="button">
        <a-button
          v-if="providerWhatsapp.id === providerType.twilio"
          :type="hover == providerType.twilio ? 'primary' : 'secondary'"
          disabled
          class="btn"
        >
          Proximamente
        </a-button>
        <a-button
          v-if="providerWhatsapp.id === providerType.securitec"
          :type="hover == providerType.securitec ? 'primary' : 'secondary'"
          class="btn"
          @click="handleNextStep(providerType.securitec)"
        >
          Solicitar WhatsApp Business API
        </a-button>
        <!-- <TwilioWhatsapp
          v-if="providerWhatsapp.id === providerType.twilio"
          @onIntegrationFinished="handleIntegrationFinished"
        />
        <GupshupWhatsapp
          v-if="providerWhatsapp.id === providerType.gupshup"
          @onIntegrationFinished="handleIntegrationFinished"
        />
        <SecuritecWhatsapp
          v-if="providerWhatsapp.id === providerType.securitec"
          typemodal="crear"
          :record="providerType"
          @onIntegrationFinished="handleIntegrationFinished"
        /> -->
      </div>
    </div>
  </div>
</template>

<script>
import ProviderIcon from "@/app/channels/components/cards/ProviderIcon.vue";
import { CheckOutlined } from "@ant-design/icons-vue";
export default {
  name: "CardWhatsappProvider",
  components: {
    ProviderIcon,
    CheckOutlined,
  },
  props: {
    providerWhatsapp: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      providerType: {
        twilio: 1,
        gupshup: 2,
        securitec: 4,
      },
      hover: 4,
    };
  },
  methods: {
    handleIntegrationFinished() {
      this.$emit("onIntegrationFinished");
    },
    handleNextStep(provider) {
      this.$emit("onNextStep", provider);
    },
  },
};
</script>
<style lang="sass" scoped>
.card-channel
  background-color: $white
  padding: 20px 30px
  border-radius: 8px
  height: 360px
  border: 1px solid $gray-5
  box-sizing: border-box
  position: relative

  &--selected
    background-color: $white
    padding: 20px 30px
    border-radius: 8px
    height: 360px
    box-sizing: border-box
    border: 1px solid $blue-6
    position: relative

.container
  height: 100%

.logo
  display: flex
  justify-content: center
.button
  text-align: center

.logo
  margin-bottom: 20px
.icon-check
  color: $blue-6
.item
  color: $gray-7
  &__extra
    text-align: center
    color: $gray-7
    margin-bottom: 15px
.title
  text-align: center
  font-size: 16px
  font-weight: 600
  margin-bottom: $margin-large
.tag-recommended
  position: absolute
  right: 0
  left: 0
  top: -12px
  text-align: center

.descriptions-provider
  margin-bottom: 4px
</style>
