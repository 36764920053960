<template>
  <div class="parent-email" :style="`margin-left: calc(${props.element?.nivel} * 10px)`">
    El {{ formatDate(props.element?.created_at) }}, {{ props.element?.alias }} (&lt;{{
      props.element?.from_email
    }}&gt;) escribió:
    <div style="margin-bottom: 10px; border-left: 1px solid; margin-left: 10px; padding-left: 10px">
      <p v-html="props.element?.message" />
      <EmailParentDetail v-if="props.element?.parent" :element="props.element?.parent" />
    </div>
  </div>
</template>

<script lang="ts" setup>
import moment from "moment";
import EmailParentDetail from "@/app/emails/components/cards/EmailParentDetail.vue";

const props = defineProps<{
  element: any;
}>();

moment.locale("es");

const formatDate = (date: string) => {
  const fechaParseada = moment(date, "YYYY-MM-DD HH:mm:ss");
  const fechaFormateada = fechaParseada.format("ddd, DD MMM YYYY [a las] HH:mm");

  return fechaFormateada;
};
</script>
