import httpManager from "../../../../shared/utils/httpManager";
/**
 * Cambiar contraseña del usuario.
 *
 * @return {Promise<object>} Data del correo del usuario.
 *
 * @param {Object} data Correo del usuario.
 */
export async function recoverPassword(data) {
  const body = data;
  const url = "/users/password/";
  const options = { isPublic: true };
  const response = await httpManager.post(url, body, options);
  return response.data;
}
