<template>
  <section class="email-card-section" v-if="tableData">
    <div v-if="tableData?.email_messages_associated?.length && isWorkspace" class="flex-center">
      <span> Correo asociado a este ticket: </span>
      <a-button
        v-for="(item, idx) in tableData?.email_messages_associated"
        :key="idx"
        type="link"
        class="link-button"
      >
        <LinkOutlined />
        Correo # {{ item }}
      </a-button>
    </div>

    <div>
      <a-table
        row-key="id"
        size="middle"
        :columns="columns"
        :data-source="tableData?.results"
        :scroll="{ y: 400 }"
        @change="handleTableChange"
        :pagination="pagination"
      >
        <template #created_at="{ text }">
          <template v-if="text">
            {{ text }}
          </template>
        </template>

        <template #id="{ text }"> #{{ text }} </template>

        <template #user_fullname="{ text }">
          {{ text }}
        </template>

        <template #identifier="{ text }">
          {{ text }}
        </template>

        <template #actionScoped="{ record }">
          <a-tooltip>
            <template #title>Ver Historial</template>
            <a-button type="link" class="table-link-button" @click="onOpen(record)">
              <InfoCircleOutlined />
            </a-button>
          </a-tooltip>
        </template>
      </a-table>
    </div>
    <!-- <a-button type="link" class="table-link-button" @click="onOpen({ id: '17' })">
      <InfoCircleOutlined />
    </a-button> -->
    <DetailDrawer v-if="openDrawer" :emailId="emailSelected" @onCloseDrawer="onClose" />
  </section>
</template>

<script setup lang="ts">
import { toRefs, reactive, ref, onMounted, computed, watch } from "vue";
import { columns } from "@/app/contacts/components/tables/emailTab/EmailTabTableColumns";
import { InfoCircleOutlined, LinkOutlined } from "@ant-design/icons-vue";

import DetailDrawer from "@/app/workspace/components/drawers/email/DetailDrawer.vue";
import { useStore } from "vuex";

const props = defineProps({
  contactId: { type: String, required: true },
  isWorkspace: { type: Boolean, required: false },
});

const { contactId } = toRefs(props);

interface Pagination {
  current: number;
  pageSize: number;
  total: number;
  showSizeChanger: boolean;
}

const pagination = reactive<Pagination>({
  current: 1,
  pageSize: 30,
  total: 0,
  showSizeChanger: false,
});

// const tableData = ref();
const store = useStore();

const tableData = computed(() => store.getters["getEmailsTab"]);

watch(tableData, (value) => {
  pagination.total = value.count;
});

const getTableData = async (contactId: string, pagination: Pagination) => {
  try {
    await store.dispatch("setEmailsTab", { contactId: contactId, pagination: pagination });
    // tableData.value = await getInfoTabEmails(ticketId, pagination);
    // pagination.total = tableData.value.count;
  } catch (error) {
    console.log(error);
  }
};

const handleTableChange = async (aPagination: Pagination) => {
  pagination.current = aPagination.current;
  await getTableData(contactId.value, pagination);
};

// DRAWER
const openDrawer = ref<boolean>(false);

const emailSelected = ref();

const onOpen = ({ id }: { id: string }) => {
  emailSelected.value = id;
  openDrawer.value = true;
};

const onClose = () => {
  openDrawer.value = false;
};

onMounted(async () => {
  await getTableData(contactId.value, pagination);
});
</script>

<style lang="sass" scoped>
.email-card-section
  padding: 24px 16px
  border-top: 1px solid #d9d9d9

.flex-center
  margin-bottom: 12px
  display: flex
  align-items: center
  flex-wrap: wrap
  gap: 3px

.link-button
  padding: 4px 6px
  &:not(:last-child)
    border-top-right-radius: 0
    border-bottom-right-radius: 0
    border-right: 1px solid #00000040
</style>
@/app/contacts/components/tables/emailTab/EmailTabTableColumns
