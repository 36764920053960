import { State } from "@/@types/emailsManager";

const initialState = (): State => ({
  emailsData: {},
  transferEmailsData: {},
  tableEmailsPage: 1,
  emailsBasicFilters: null,
  emailsAdvancedFilters: null,
  emailsAdvancedFiltersData: null,
  usersData: null,
});

const state: State = initialState();

export default state;
