//import httpManager from '@/app/shared/utils/httpManager'
import httpManager from "../../utils/httpManager";

export async function fetchAllHeaders() {
  const clients = (await httpManager.get(`/workspace/fields/clients/`)).data;
  const products = (await httpManager.get(`/workspace/fields/clients/`)).data;

  return {
    clients,
    products,
  };
}
