<template>
  <a-modal
    :visible="true"
    width="450px"
    :body-style="bodyStyle"
    centered
    :footer="null"
    @cancel="handleCloseModalHeader"
  >
    <div class="container__inactive">
      <img class="image__delete" src="@/app/users/assets/profiles/delete.png" alt="perfil" />
      <p class="text__alert">¿Estás seguro de inactivar el contacto</p>
      <p class="text__alert">“{{ record.fullName }}”?</p>
      <p>Actualmente se encuentra en una llamada activa.</p>
    </div>
    <div class="container__info">
      <p class="text">Recuerda que se tomarán las siguientes acciones:</p>
      <ul>
        <li>La llamada activa, se cortará.</li>
        <li>Todos sus tickets activos se finalizarán.</li>
        <li>
          Se actualizará el módulo de Workspace del usuario que tiene el ticket asociado a este
          contacto.
        </li>
        <li>Se eliminarán los recordatorios pendientes asociados.</li>
        <li>
          Se eliminará de las estrategias en las que se encuentre y aún no haya sido contactado.
        </li>
        <li>
          Se eliminará de las secuencias en donde se encuentre asociado.
        </li>
      </ul>
    </div>
    <div class="button__disabled">
      <a-button
        style="width: 100%"
        class="footer__delete"
        type="primary"
        @click="handleDeleteContact"
      >
        Inactivar contacto
      </a-button>
    </div>
  </a-modal>
</template>

<script>
import { defineComponent, h } from "vue";
import { UserStatus } from "@/app/shared/utils/enums";
// import { deleteUserSocket } from "@/app/shared/services";
import { mapIcon } from "@/app/notifications/utils/icons";

export default defineComponent({
  name: "InactiveUserModal",
  components: {},
  props: {
    record: {
      type: Object,
      default: null,
    },
    itemId: {
      required: false,
      type: Number || String,
      default: "",
    },
    contact: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const bodyStyle = {
      loading: false,
    };
    return {
      bodyStyle,
    };
  },
  data() {
    return {
      UserStatus,
    };
  },
  computed: {
    usersInfo() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    handleCloseModalHeader() {
      this.$emit("onCancel");
    },
    handleDeleteContact() {
      try {
        this.$store.dispatch("fetchChangeStatusContact", { contactId: this.itemId, status: 2 });
        this.$emit("onCancel");
        this.$emit("onCloseDrawer");
        this.$message.success(`Se ha inactivado el contacto ${this.record.fullName} correctamente`);
        this.handleModalNotification();
      } catch(error) {
        this.$message.error("Hubo un error al eliminar el contacto");
      }
    },
    handleModalNotification() {
      this.$notification.open({
        message: `Un administrador ha inactivado al contacto "${this.contact.fullName}`,
        description: `${this.usersInfo?.fullname} a inactivado al contacto ${this.record.fullName}. Su ticket asociado desaparecerá de tu módulo de Workspace y se eliminarán los recordatorios pendientes asociados.`,
        icon: h(mapIcon("contactBlue"), { notification: true }),
        duration: 5,
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.image__delete
  margin-bottom: 10px
  margin-top: 10px

.text__alert
  font-size: 16px
  font-weight: 600
  margin: 0

.button__disabled
  margin-top: 10px

.footer__delete
  margin-bottom: 20px

.container__inactive
  text-align: center
  color: $gray-13

.container__info
  margin-bottom: 18px
  color: $gray-8

.text
  margin-top: 20px
  margin-bottom: 12px
</style>
