import {
  fetchStrategies,
  updateStrategyData,
  generateStrategyByTemplate,
  deleteStrategy,
  assignDataToStrategy,
  assignAgentsToStrategy,
  assignTimeToStrategy,
  fetchTemplatesWhatsapp,
  launchStrategy,
  fetchAgentsInStrategy,
  downloadStrategy,
  fetchAgentsByCampaign,
} from "@/app/strategies/services";
import { TABLE_PAGE_SIZE_REDUCE } from "@/app/shared/utils/constants";

import {
  SELECT_ALL_ACTIVE_USERS,
  SELECT_ACTIVE_USERS_BY_PROFILE,
  SET_ALL_USERS,
  SELECT_SINGLE_ACTIVE_USER,
  SET_SELECTED_ACTIVE_USERS_STRATEGY,
  SET_STRATEGIES,
  SET_STRATEGY,
  REMOVE_STRATEGY,
} from "./mutation-types";
const actions = {
  async fetchAllStrategies({ commit }, params = {}) {
    const { filters, pagination } = params;
    const data = await fetchStrategies(filters, pagination);

    console.log(data.results);

    await commit(SET_STRATEGIES, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE_REDUCE,
        showSizeChanger: false,
      },
    });

    console.log(data.results);
  },
  async generateStrategyByTemplate(_, formData) {
    return await generateStrategyByTemplate(formData);
  },
  // async assignAgentsToStrategy({ commit }, { strategyId, users }) {
  //   const updatedStrategy = await assignAgentsToStrategy(strategyId, users);
  //   commit("SET_STRATEGY", updatedStrategy);
  // },
  async launchStrategyById({ commit }, strategyId) {
    const strategy = await updateStrategyData(strategyId, { turnedOn: true });
    // commit(SET_STRATEGY, strategy);
    return strategy;
  },
  async finishStrategyById({ commit }, strategyId) {
    await updateStrategyData(strategyId, { turnedOn: false });
    // commit(SET_STRATEGY, strategy);
  },
  async moveStrategyFromIndexes({ commit }, { from, to }) {
    commit("MOVE_STRATEGY", { from, to });
  },

  async fetchTemplatesWhatsapp({ commit }, idPortafolio) {
    const templates = await fetchTemplatesWhatsapp(idPortafolio);
    commit("SET_TEMPLATES_WHATSAPP", templates);
  },
  async launchStrategy(_, data) {
    await launchStrategy(data);
  },
  async fetchAllAgentsByStrategy({ commit }) {
    const response = await fetchAgentsInStrategy();
    commit("SET_AGENTS_STRATEGY", response.results);
  },
  async downloadStrategy(_, idStrategy) {
    const response = await downloadStrategy(idStrategy);
    return response;
  },
  async fetchAgentsByCampaignStrategy({ commit }, strategyId) {
    const data = await fetchAgentsByCampaign(strategyId);
    commit(SET_ALL_USERS, data.results);
  },
  selectAllActiveUsersStrategy({ commit }, status) {
    commit(SELECT_ALL_ACTIVE_USERS, status);
  },
  selectActiveUsersByProfileStrategy({ commit }, { status, profileName }) {
    commit(SELECT_ACTIVE_USERS_BY_PROFILE, { status, profileName });
  },
  selectSingleActiveUser({ commit }, { status, userId }) {
    commit(SELECT_SINGLE_ACTIVE_USER, { status, userId });
  },
  async fetchSelectedActiveUsersStrategy({ commit }, items) {
    await commit(SET_SELECTED_ACTIVE_USERS_STRATEGY, items);
  },
  async addUsersProfileStrategy({ commit, state }, { strategyId }) {
    const usersAdded = state.filteredActiveUsers
      .filter((user) => user.added)
      .map((user) => user.id);
    console.log(usersAdded, "usersAdded");
    const addAgentsStrategy = await assignDataToStrategy(strategyId, "assignAgents", usersAdded);
    commit("SET_AGENTS_STRATEGY", addAgentsStrategy);
  },
  async editExecuteTimeStrategy({ commit }, { strategyId, data }) {
    const updateStrategyTime = await assignDataToStrategy(strategyId, "assignTime", data);
    console.log(updateStrategyTime, "updateStrategyTime");
    commit("SET_TIME_STRATEGY", updateStrategyTime);
  },
  deleteStrategyById({ commit }, strategyId) {
    deleteStrategy(strategyId);
    commit(REMOVE_STRATEGY, strategyId);
  },
};

export default actions;
