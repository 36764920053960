<template>
  <section class="viewer__list">
    <div v-for="campaign of list" :key="campaign.id">
      <viewer-item-header :campaign="campaign" />

      <a-tabs default-active-key="1" :tab-bar-style="{ border: 'none' }">
        <a-tab-pane key="1" tab="Voz">
          <viewer-voice-table :campaign="campaign" />
        </a-tab-pane>
        <a-tab-pane key="2" tab="Chats">
          <viewer-chats-table :campaign="campaign" />
        </a-tab-pane>
      </a-tabs>
    </div>
  </section>
</template>

<script>
import ViewerItemHeader from "./ViewerItemHeader.vue";
import ViewerChatsTable from "../tables/ViewerChatsTable.vue";
import ViewerVoiceTable from "../tables/ViewerVoiceTable.vue";
import { defineComponent } from "vue";

export default defineComponent({
  components: {
    ViewerItemHeader,
    ViewerVoiceTable,
    ViewerChatsTable,
  },
  props: {
    list: { required: true, type: Array },
  },
});
</script>

<style lang="sass" scoped>
.viewer
  &__list
    display: flex
    flex-direction: column
    gap: 28px
</style>
