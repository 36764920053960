import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los troncales registrados.
 *
 * @return {Promise<Array>} Lista de troncales.
* @param {Object} pagination Paginación

 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export default async function (search, pagination = {}) {
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  const url = search
    ? `/pbx/dids/?search=${search}&offset=${offset}&limit=${pageSize}`
    : `/pbx/dids/`;
  const response = await httpManager.get(url);
  return response?.data;
}
