import httpManager from "@/app/shared/utils/httpManager";

/**
 * Guardar tipificacion por defecto para un ticket.
 * @param {number} ticket Identificador de ticket.
 */
export async function saveDefaultManagement(params) {
  const { strategy_phone, strategy, channel_customer, asterisk_id, ...restParams } = params;

  const body = restParams;

  if (strategy_phone) body.strategy_phone = strategy_phone;
  if (strategy) body.strategy = strategy;
  if (channel_customer) body.channel_customer = channel_customer;
  if (asterisk_id) body.asterisk_id = asterisk_id;

  const url = `/workspace/default-management/`;
  const response = await httpManager.post(url, body);
  return response.data;
}
