import {
  SET_CLIENT,
  SET_TICKETS_FILTERS,
  SET_TICKETS,
  SET_FILTERED_TICKETS,
  SET_TICKETS_LIST_PAGE,
  SET_HAS_NEXT_TICKET_PAGE,
  SET_MESSAGE_HISTORY,
  SET_CLIENT_CHANNELS,
  UPDATE_PERSONAL_INFORMATION,
  ADD_EMAIL_CLIENT,
  ADD_SELECTED_TICKET,
  REMOVE_SELECTED_TICKET,
  SELECT_ALL_TICKETS,
  ADD_PHONES_CLIENT,
  REMOVE_PHONE_CLIENT,
  ADD_ADDRESSES_CLIENT,
  REMOVE_ADDRESSES_CLIENT,
  REMOVE_EMAIL_CLIENT,
  UPDATE_ADDRESSES_CLIENT,
  UPDATE_PHONES_CLIENT,
  UPDATE_EMAIL_CLIENT,
  UPDATE_DATA_CUSTOM,
  SET_CLIENTS_WITH_SAME_NUMBER_FOUND,
  SET_CLIENTS_FOUND,
  SET_CLEAN_RESULTS,
  SET_CONTACTS_CLIENT,
  UPDATE_CONTACTS_CLIENT,
  DELETE_CONTACTS_CLIENT,
  SET_CALL_FINISH,
  SET_TICKET_DETAIL,
  SET_DIALING_TYPE,
  SET_CHANNEL_PERSON_TICKET,
  SET_SPEECH_CONFIG,
  SET_PORTFOLIO_DETAIL,
  SET_TYPIFICATIONS_TABLE_DATA,
  SET_CAPTURE,
  SET_IS_SCHEDULE,
  SET_LAST_OPENED_TICKET,
  SET_INTERACTIONS_CHANNEL,
  SET_EMAIL_HISTORY,
  SET_EVENT_MESSAGING,
} from "./mutation-types";
import _ from "lodash";

const mutations = {
  [SET_CLIENT](state, client) {
    state.client = client;
  },
  [SET_TICKETS_FILTERS](state, filters) {
    state.ticketsFilters = filters;
  },
  [SET_TICKETS_LIST_PAGE](state, page) {
    state.ticketListPage = page;
  },
  [SET_HAS_NEXT_TICKET_PAGE](state, hasNext) {
    state.hasNextTicketPage = hasNext;
  },
  [SET_TICKETS](state, tickets) {
    state.tickets = tickets;
    const updatedTickets = state.tickets.filter((item) => {
      const ticketFound = state.selectedTickets.find((ticket) => ticket.id === item.id);
      if (ticketFound) {
        return item;
      }
    });
    state.selectedTickets = updatedTickets;
  },
  [SET_FILTERED_TICKETS](state, filteredTickets) {
    state.filteredTickets = filteredTickets;
  },
  [SET_MESSAGE_HISTORY](state, history) {
    state.history = history;
  },
  [SET_CLIENT_CHANNELS](state, channels) {
    state.channels = channels;
  },
  [UPDATE_PERSONAL_INFORMATION](state, personaInformation) {
    state.client.personal = personaInformation;
  },
  [ADD_SELECTED_TICKET](state, ticket) {
    state.selectedTickets.push(ticket);
  },
  [REMOVE_SELECTED_TICKET](state, ticket) {
    const result = state.selectedTickets.filter((selectedTicket) => {
      return selectedTicket.id !== ticket.id;
    });
    state.selectedTickets = result;
  },
  [SELECT_ALL_TICKETS](state, checked) {
    if (checked) {
      state.selectedTickets = state.tickets.filter((ticket) => ticket.channel.id !== 1);
    } else {
      state.selectedTickets = [];
    }
  },
  [ADD_PHONES_CLIENT](state, dataPhone) {
    state.client.phones.push(dataPhone);
  },
  [UPDATE_PHONES_CLIENT](state, dataPhone) {
    const index = state.client.phones.findIndex((item) => dataPhone.id === item.id);
    state.client.phones.splice(index, 1, dataPhone);
  },
  [REMOVE_PHONE_CLIENT](state, phoneId) {
    const index = state.client.phones.findIndex((item) => phoneId === item.id);
    state.client.phones.splice(index, 1);
  },
  [ADD_ADDRESSES_CLIENT](state, dataAddresse) {
    state.client.addresses.push(dataAddresse);
  },
  [REMOVE_ADDRESSES_CLIENT](state, addresseId) {
    const index = state.client.addresses.findIndex((item) => addresseId === item.id);
    state.client.addresses.splice(index, 1);
  },
  [UPDATE_ADDRESSES_CLIENT](state, dataAddresse) {
    const array = state.client.addresses.map((item) =>
      dataAddresse.id == item.id ? dataAddresse : item
    );
    state.client.addresses = array;
  },
  [ADD_EMAIL_CLIENT](state, dataEmail) {
    state.client.emails.push(dataEmail);
  },
  [UPDATE_EMAIL_CLIENT](state, dataEmail) {
    const array = state.client.emails.map((item) => (dataEmail.id == item.id ? dataEmail : item));
    state.client.emails = array;
  },
  [REMOVE_EMAIL_CLIENT](state, emailId) {
    const index = state.client.emails.findIndex((item) => emailId === item.id);
    state.client.emails.splice(index, 1);
  },
  [UPDATE_DATA_CUSTOM](state, dataCustom) {
    state.client.customs = dataCustom;
  },
  [SET_CLIENTS_WITH_SAME_NUMBER_FOUND](state, items) {
    state.clientsWithSameNumberFound = items;
  },
  [SET_CLIENTS_FOUND](state, items) {
    state.clientsFound = items;
  },
  [SET_CLEAN_RESULTS](state) {
    state.clientsFound = [];
  },
  [SET_CONTACTS_CLIENT](state, contacts) {
    const array = state.client.contacts;
    state.client.contacts = [...array, contacts];
  },
  [UPDATE_CONTACTS_CLIENT](state, contact) {
    console.log("actualizando", contact);
    const array = state.client.contacts.map((item) => (contact.id == item.id ? contact : item));
    state.client.contacts = array;
  },
  [DELETE_CONTACTS_CLIENT](state, contactId) {
    const array = _.remove(state.client.contacts, function (contact) {
      return contact.id != contactId;
    });
    state.client.contacts = array;
  },
  [SET_CALL_FINISH](state, call) {
    state.callEnded = call;
  },
  [SET_TICKET_DETAIL](state, data) {
    state.ticketDetail = data;
  },
  [SET_DIALING_TYPE](state, data) {
    state.dialingType = data;
  },
  [SET_CHANNEL_PERSON_TICKET](state, data) {
    state.channel_person = data;
  },
  [SET_SPEECH_CONFIG](state, config) {
    state.speechConfig = config;
  },
  [SET_PORTFOLIO_DETAIL](state, portfolioDetail) {
    state.portfolioDetail = portfolioDetail;
  },
  [SET_TYPIFICATIONS_TABLE_DATA](state, typificationsTableData) {
    state.typificationsTableData = typificationsTableData;
  },
  [SET_CAPTURE](state, data) {
    state.isCaptureded = data;
  },
  [SET_IS_SCHEDULE](state, schedule) {
    state.isSchedule = schedule;
  },
  [SET_LAST_OPENED_TICKET](state, openedTicket) {
    state.lastTicketOpened = openedTicket;
  },
  [SET_INTERACTIONS_CHANNEL](state, interactions) {
    state.interactionsChannel = interactions;
  },
  [SET_EMAIL_HISTORY](state, data) {
    state.emailHistory = data;
  },
  [SET_EVENT_MESSAGING](state, data) {
    state.eventMessaging = data;
  },
};
export default mutations;
