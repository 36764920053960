import httpManager from "@/app/shared/utils/httpManager";
/**
 * Actualizar la invitación del usuario
 *
 * @param {Object} emailUser Datos de la invitación.
 * @param {number} userId Identificador del usuario.
 * @return {Promise<Object>} Actualizar información
 */
export default async function (userId, data) {
  const response = await httpManager.put(`/users/${userId}/update-invitation/`, data);
  return response?.data;
}
