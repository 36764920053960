<template>
  <div class="information">
    <div class="information__owner">
      <span>
        Actualmente, esta cuenta posee permisos de Owner (dueño del ambiente de trabajo), por lo que
        cuenta con privilegios únicos como el control total de la plataforma. Este rol puede ser
        transferido tanto a usuarios con rol administrador como rol agente. Una vez transferido este
        permiso no se podrá volver a recuperar a menos que el nuevo Owner lo autorice.
      </span>
    </div>

    <a-button class="button" type="link" @click="handleOpenModalTransfer">
      Tranferir rol de owner
    </a-button>
  </div>

  <ModalTransferOwner :visible="visibleModalTransfer" @onCloseModal="handleCloseModal" />
</template>

<script>
import { defineComponent } from "vue";

import ModalTransferOwner from "@/app/settingsMenu/components/modal/ModalTransferOwner.vue";

export default defineComponent({
  components: {
    ModalTransferOwner,
  },
  data() {
    return {
      visibleModalTransfer: false,
    };
  },
  methods: {
    handleOpenModalTransfer() {
      this.visibleModalTransfer = true;
    },
    handleCloseModal() {
      this.visibleModalTransfer = false;
    },
  },
});
</script>

<style lang="sass" scoped>
.information__owner
  margin-bottom: 16px

.text__strong
  font-weight: 600
  font-size: 16px

.flex
  display: flex

.info
  margin-top: -3px
  margin-bottom: 2px

.circleInfo
  color: $gray-1
  margin-left: 2px

.button
  padding: 0
</style>

<style>
.information .ant-checkbox-checked::after {
  border: none;
}
</style>
