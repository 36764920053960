<template>
  <!-- Acciones que aparecen arriba del hilo de correos seleccionado -->
  <div class="icons" v-if="showOnlyIcons">
    <a-tooltip placement="top" color="white" :class="`${(open || visible) && 'hide-tool'}`">
      <template #title>
        <div :class="`btn ${(open || visible) && 'hide-tool'}`">
          <a-button type="link" @click="open = true">Transferir a un usuario</a-button>
          <a-button type="link" @click="visible = true">Transferir a otra bandeja</a-button>
        </div>
      </template>
      <a-tooltip placement="top">
        <template #title> Transferir correo </template>
        <ActionButton v-if="role && !resolvedSelected" type="link" :icon="'SwapOutlined'" />
      </a-tooltip>
    </a-tooltip>
    <a-tooltip>
      <template #title> Mover correo </template>
      <ActionButton
        v-if="role && !resolvedSelected"
        type="link"
        icon="IconFolderArrow"
        :hasText="false"
        @click="showMoveModal = true"
      />
      <!-- <a-button
        v-if="role && !resolvedSelected"
        type="text"
        style="padding: 0; height: 24px"
        @click="showMoveModal = true"
      >
        <IconFolderArrow />
      </a-button> -->
    </a-tooltip>
    <TagsList
      v-if="!resolvedSelected"
      :tagsDefault="tags"
      simple-tag
      :currentTags="props.selectedRows?.map((r) => r?.tags?.map((t) => t.id))?.[0] || []"
      @selectTags="handleSelectTags($event)"
    />
    <ContactAssociated :thread="threadActual" :currentMessage="currentMessage" />
    <!-- <Reminder :email="true" /> -->
    <template
      v-if="(currentMessage?.resolved ? false : !isResolved) && !resolvedSelected && isAssigned"
    >
      <a-divider type="vertical" style="height: 25px; background-color: #00000026; width: 2px" />
      <a-button class="btn" @click="handleShowResolveModal('single')"> Resolver </a-button>
    </template>
  </div>
  <!-- Acciones que aparecen arriba de la tabla segun items seleccionados o que aparecen por fila en la tabla-->
  <div
    :class="showOnlyIconsTable ? 'icons icons_row' : 'actions'"
    v-if="(showFullActions || showOnlyIconsTable) && !resolvedSelected"
  >
    <div v-if="!isAssigned && showFullActions">
      <ActionButton
        type="link"
        :icon="'UserSwitchOutlined'"
        :hasText="showFullActions"
        :tooltipMessage="showOnlyIconsTable ? 'Asignarme' : null"
        title="Asignarme"
        titlePosition="left"
        @click.stop="handleAssignEmail"
      />
      <a-divider v-if="showFullActions" type="vertical" class="divider" />
    </div>
    <a-tooltip placement="bottom" color="white" :class="`${(open || visible) && 'hide-tool'}`">
      <template #title>
        <div :class="`btn ${(open || visible) && 'hide-tool'}`">
          <a-button type="link" @click.stop="open = true">Transferir a un usuario</a-button>
          <a-button type="link" @click.stop="visible = true">Transferir a otra bandeja</a-button>
        </div>
      </template>
      <ActionButton
        v-if="role"
        type="link"
        :icon="'SwapOutlined'"
        :hasText="showFullActions"
        :tooltipMessage="showOnlyIconsTable ? 'Transferir' : null"
        title="Transferir"
        titlePosition="left"
        @click.stop
      />
    </a-tooltip>
    <template v-if="isAssigned">
      <a-divider v-if="showFullActions" type="vertical" class="divider" />
      <ActionButton
        v-if="showMoveButton"
        type="link"
        :tooltipMessage="showOnlyIconsTable ? 'Mover' : null"
        :icon="showFullActions ? 'FolderOutlined' : 'IconFolderArrow'"
        :hasText="showFullActions"
        title="Mover"
        titlePosition="left"
        @click.stop="showMoveModal = true"
      />
      <a-divider v-if="showMoveButton && showFullActions" type="vertical" class="divider" />
      <div v-if="!showOnlyIconsTable" style="padding: 4.5px 15px">
        <TagsList
          tagPosition="left"
          :tagsDefault="tags"
          :multipleElementTags="props.selectedRows?.map((r) => r.tags.map((t) => t.id)) || []"
          @selectTags="handleSelectTags($event, 'massive')"
        />
      </div>
      <TagsList
        v-else
        simple-tag
        :tagsDefault="tags"
        :currentTags="props.selectedRows?.map((r) => r?.tags?.map((t) => t.id))?.[0] || []"
        @selectTags="handleSelectTags($event, 'massive')"
      />
    </template>

    <ActionButton
      v-if="showOnlyIconsTable && firstSelected"
      type="link"
      :icon="firstSelected.read ? 'MailOutlined' : 'IconEmailOpen'"
      :hasText="false"
      :tooltipMessage="
        showOnlyIconsTable
          ? firstSelected.read
            ? 'Marcar como no leído'
            : 'Marcar como leído'
          : null
      "
      titlePosition="left"
      @click.stop="handleChangeStatusEmail(firstSelected.read)"
    />
    <a-divider v-if="showFullActions && isAssigned" type="vertical" class="divider" />

    <ActionButton
      v-if="isAssigned"
      type="link"
      :icon="'CheckOutlined'"
      :hasText="showFullActions"
      :tooltipMessage="showOnlyIconsTable ? 'Resolver' : null"
      title="Resolver"
      titlePosition="left"
      @click.stop="handleShowResolveModal('massive')"
    />
  </div>
  <!-- Seccion que va junto al asunto del correo -->
  <div v-if="isSubject" class="subject">
    <div v-if="!isAssigned">
      <ActionButton
        type="link"
        :icon="'UserSwitchOutlined'"
        :hasText="true"
        title="Asignarme"
        titlePosition="right"
        @click="handleAssignEmail"
      />
      <!-- | -->
    </div>
    <a-tooltip placement="bottom" color="white" :class="`${(open || visible) && 'hide-tool'}`">
      <template #title>
        <div :class="`btn ${(open || visible) && 'hide-tool'}`">
          <a-button type="link" @click="open = true">Transferir a un usuario</a-button>
          <a-button type="link" @click="visible = true">Transferir a otra bandeja</a-button>
        </div>
      </template>
      <!-- <ActionButton
        v-if="role"
        type="link"
        :icon="'SwapOutlined'"
        :hasText="true"
        title="Transferir"
        titlePosition="right"
      /> -->
    </a-tooltip>
    <ActionButton
      v-if="isAssigned && !threadActual?.client && !resolvedSelected"
      type="link"
      :icon="'UserSwitchOutlined'"
      :hasText="true"
      title="Asignar contacto"
      titlePosition="right"
      @click="assignContact"
    />
    <div v-if="threadActual?.ticket" class="ticket-associated">
      <a-button type="link" @click="openTicketHistoryDrawer">
        <LinkOutlined /> Ticket # {{ threadActual?.ticket }}
      </a-button>
    </div>
    <!-- <ActionButton
      v-if="isAssigned"
      type="link"
      :icon="'LinkOutlined'"
      :hasText="true"
      title="Ticket #023"
      titlePosition="right"
    /> -->
  </div>
  <div class="modals">
    <TransferUser
      v-if="open"
      v-model:open="open"
      :selectedRows="props.selectedRows"
      :channelCustomerId="channelCustomerId"
      @update="update"
    />

    <TransferCampaign
      v-if="visible"
      v-model:visible="visible"
      :selectedThreads="props.selectedRows"
      :channelCustomerId="channelCustomerId"
      @update="update"
      type="campaign"
      title="Transferir a una campaña"
    />
    <TransferCampaign
      v-if="showMoveModal"
      :campaignDefault="selectedCampaign.channelCustomerId"
      :channelCustomerId="channelCustomerId"
      v-model:visible="showMoveModal"
      :selectedThreads="props.selectedRows"
      @update="update"
      type="move"
      title="Mover a otro identificador"
    />
    <ResolveEmail
      v-if="showResolveModal"
      v-model:open="showResolveModal"
      :multiple="resolveModalType === 'massive'"
      :emailsIds="props.selectedRows?.map((r) => r.key)"
      @update="toggleResolveArea"
    />
    <AssignEmailToContact
      v-if="showAssignModal"
      v-model:open="showAssignModal"
      :currentMessage="currentMessage"
    />
    <ContactInfo
      :visible="contactInfoVisible"
      :email="emailContactInfo"
      :currentMessage="currentMessage"
      @close="contactInfoVisible = false"
    />
    <TicketDrawerContact
      v-if="showTicketHistoryDrawer"
      :item="{ id: threadActual?.ticket }"
      @onCloseDrawerTicket="showTicketHistoryDrawer = false"
      :isModuleTicket="false"
    />
  </div>
</template>
<script lang="ts" setup>
import { ref, computed, watch, inject, Ref } from "vue";
import TransferUser from "../modals/transferEmail/TransferUser.vue";
import TransferCampaign from "../modals/transferEmail/TransferCampaign.vue";
import ResolveEmail from "../modals/ResolveEmail.vue";
import ActionButton from "../buttons/ActionButton.vue";
// import Reminder from "@/app/workspace/components/modal/Reminder.vue";
import AssignEmailToContact from "../modals/AssignEmailToContact.vue";
import ContactAssociated from "@/app/emails/components/drawers/ContactAssociated.vue";
import TagsList from "@/app/shared/components/lists/TagsList.vue";
import { DataType, EmailDetails, IMenuSubItem, IMenutem, Thread } from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";
import { useRoute } from "vue-router";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { LinkOutlined } from "@ant-design/icons-vue";
import TicketDrawerContact from "@/app/contacts/components/drawers/recordTickets/TicketDrawerContact.vue";
import ContactInfo from "@/app/emails/components/drawers/ContactInfo.vue";
import { Tag, UserData } from "@/@types/global";

// defineProps<{
//   currentMessage?: EmailDetails;
//   role: boolean;
//   isAssigned: boolean;
//   showOnlyIcons: boolean;
//   showFullActions: boolean;
//   isSubject: boolean;
//   props.selectedRows?: DataType[];
//   showMoveButton?: boolean | true;
// }>();

const props = defineProps({
  currentMessage: {
    type: Object as () => EmailDetails | null,
  },
  role: {
    required: false,
    type: Boolean,
  },
  isAssigned: {
    required: false,
    type: Boolean,
  },
  showOnlyIcons: {
    required: false,
    type: Boolean,
  },
  showFullActions: {
    required: false,
    type: Boolean,
  },
  showOnlyIconsTable: {
    required: false,
    type: Boolean,
  },
  isSubject: {
    required: false,
    type: Boolean,
  },
  selectedRows: {
    type: Array as () => Thread[] | DataType[],
  },
  showMoveButton: {
    type: [Boolean, Boolean] as () => boolean | true,
    default: true,
  },
  isInbox: {
    type: Boolean,
    default: true, // acciones de inbox o de gestor de correos
  },
  tags: {
    type: Array as () => Tag[],
    default: undefined,
  },
  channelCustomerId: {
    type: Number,
    default: undefined,
  },
  // channelCustomerId: {
  //   type: Number,
  // },
  // campaignActual: {
  //   type: Number,
  // },
});

const store = useStore();

const route = useRoute();

const threadActual = computed(() => {
  if (props.selectedRows?.length == 1) {
    return props.selectedRows?.[0];
  }

  return null;
});

const showTicketHistoryDrawer = ref(false);

const open = ref<boolean>(false);
const showAssignModal = ref<boolean>(false);
const contactInfoVisible = ref<boolean>(false);
const emailContactInfo = ref<boolean>(false);
const visible = ref<boolean>(false);
const showResolveModal = ref<boolean>(false);
const resolveModalType = ref<"single" | "massive">();
const showMoveModal = ref<boolean>(false);

const emit = defineEmits([
  "emitAssignEvent",
  "massiveTag",
  "resolved",
  "update", //actualiza tabla y menus en inbox
  "assign:contact",
  "resetSelected",
  "refreshTable",
]);

const handleAssignEmail = () => {
  emit("emitAssignEvent");
};

const firstSelected = computed(() => {
  return props.selectedRows.length > 0 ? props.selectedRows[0] : null;
});

const resolvedSelected = computed(() => route.query.resolved);
const isResolved = ref(false);

const selectedCampaign = computed(() => {
  return store.getters.getSelectedCampaign;
});

const channelCustomerId = computed(() => {
  return props.channelCustomerId || route.query.channelCustomer;
});

const toggleResolveArea = async (value: boolean) => {
  console.log("toggle resolve area");
  isResolved.value = value;
  await emit("resolved");
  await fetchMenuList();
};

const handleSelectTags = async (
  tags: { tagsToAdd: number[]; tagsToRemove: number[] },
  type?: string
) => {
  const emailId = Number(route.query.emailId);

  if (type === "massive" && props.selectedRows) {
    await emailService.massiveTag(
      props.selectedRows.map((el) => el.key),
      tags
    );
    emit("refreshTable");
    // emit("massiveTag", tags);
  } else {
    const response = await emailService.editThreadEmail(emailId, {
      tag_list: tags.tagsToAdd,
      delete_tags_id: tags.tagsToRemove,
    });

    if (response.status == 200) message.success("Se ha asignado las etiquetas correctamente");

    await store.dispatch("updateThreadsInbox", {
      assignedUser: user.value?.id,
      resetPagination: false,
    });
  }
};

const assignContact = () => {
  const receivers = props.currentMessage.receivers.map((r) => ({
    value: r?.email,
    label: r?.email,
  }));
  if (receivers.length == 1) {
    emailContactInfo.value = receivers[0].label;
    contactInfoVisible.value = true;
  } else {
    showAssignModal.value = true;
  }
  console.log(receivers, "currentMessage");
};

const handleShowResolveModal = (type: "single" | "massive") => {
  showResolveModal.value = true;
  resolveModalType.value = type;
};

const openTicketHistoryDrawer = () => {
  showTicketHistoryDrawer.value = true;
};

const user = computed<UserData | undefined>(() => store.getters["getDataUser"]);
// const menuItems = inject("menuItems") as Ref<IMenutem[]>;
const menuItems = computed(() => {
  return store.getters.getMenuItems;
});

const fetchMenuList = async () => {
  await store.dispatch("updateMenuItems", user.value?.id);
  // if (menuItems) {
  //   const assignedUser = route.query.assigned == "true" ? user.value?.id : undefined;
  //   const itemsAux = await emailService.getEmailsMenuList(assignedUser);
  //   for (let i = 0; i < itemsAux.length; i++) {
  //     for (let j = 0; j < itemsAux[i].subItems?.length; j++) {
  //       menuItems.value[i].subItems[j].unread = itemsAux[i]?.subItems[j]?.unread;
  //       menuItems.value[i].subItems[j].buttonLabel = itemsAux[i]?.subItems[j]?.buttonLabel;
  //       // menuItems.value[i].subItems[j].unread = false;
  //     }
  //   }
  // }
};

const handleButtonMenuList = inject("handleButtonMenuList");

const update = async (identifier: number) => {
  if (props.isInbox) {
    await emit("resetSelected");
    await fetchMenuList();

    if (handleButtonMenuList) {
      let item: IMenutem = null,
        subItem: IMenuSubItem = null;
      menuItems.value.forEach((el: IMenutem) => {
        el.subItems.forEach((el2: IMenuSubItem) => {
          if (el2.id == identifier) {
            item = el;
            subItem = el2;
          }
        });
      });

      if (props.showOnlyIcons && item && subItem) {
        store.dispatch("updateSelectedCampaign", {
          channelCustomerId: item?.id,
          ...subItem,
          incomingEmail: subItem?.emailIncoming,
          outgoingEmail: subItem?.email,
          totalEmail: subItem?.buttonLabel,
        });
        store.dispatch("updateSelectedKeysMenuItems", [subItem?.id]);
      } else {
        handleButtonMenuList(item, subItem, true);
      }
    }
  } else {
    emit("update");
  }

  // if (handleButtonMenuList) {
  //   const identifier = 277;
  //   let item, subItem;
  //   menuItems.value.forEach((el) => {
  //     el.subItems.forEach((el2) => {
  //       if (el2.id == identifier) {
  //         item = el;
  //         subItem = el2;
  //       }
  //     });
  //   });

  //   handleButtonMenuList(item, subItem, true);
  // }
  // console.log(item, "item");
  // console.log(subItem, "subItem");
  // if (item && subItem) {
  //   store.dispatch("updateSelectedCampaign", {
  //     channelCustomerId: item.id,
  //     ...subItem,
  //     incomingEmail: subItem?.emailIncoming,
  //     outgoingEmail: subItem?.email,
  //     totalEmail: subItem?.buttonLabel,
  //   });
  // }
};

const handleChangeStatusEmail = async (read: boolean) => {
  await emailService.changeStatus(
    props.selectedRows.map((el) => el.key),
    read ? 1 : 2 // 1: No leido | 2: Leido
  );
  emit("refreshTable");
};
</script>
<style lang="sass" scoped>
.modals
  display: none
.subject
  display: flex
.actions
  display: flex
  justify-content: center
  background: #F0F5FF
  padding: 3px 0
  border-bottom: 1px solid #dadada
.icons
  display: flex
  gap: 10px
  align-items: center
  &_row
    gap: 20px
  button
    padding: 0
    span
      font-size: 18px
      color: #262626
    svg
      height: 18px
      cursor: pointer
  .btn
    padding: 0 15px
.button
  &--tag
    span
      padding-right: 5px

.divider
  margin: 0
  background-color: $gray-7
  height: 1.5em
  margin-top: 5px
.ticket-associated
  display: flex
</style>
<style>
.ant-tooltip-inner .btn button {
  width: 100%;
  color: #595959;
  text-align: left;
  padding: 20px 10px;
  display: flex;
  align-items: center;
}
.ant-tooltip-inner .btn button:hover {
  color: #595959;
  background-color: #e6f7ff;
}
.hide-tool {
  display: none;
}
.icons svg {
  font-size: 18px;
  color: #262626;
}
</style>
