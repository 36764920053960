import {
  SET_CHANNELS_CUSTOMERS,
  SET_WEBHOOK_PROVIDER_WHATSAPP,
  ADD_CHANNEL,
  REMOVE_CHANNEL,
  SET_CHANNEL,
  SET_DATA_PARAMETERS,
} from "./mutation-types";

const mutations = {
  [SET_CHANNELS_CUSTOMERS](state, channels) {
    state.channelsCustomers = channels;
  },
  [SET_WEBHOOK_PROVIDER_WHATSAPP](state, webhook) {
    state.webhookProviderWhatsapp = webhook;
  },
  [ADD_CHANNEL](state, channel) {
    state.channelsCustomers = [...state.channelsCustomers, channel];
  },
  [REMOVE_CHANNEL](state, channelId) {
    const index = state.channelsCustomers.findIndex((item) => channelId === item.id);
    state.channelsCustomers.splice(index, 1);
  },
  [SET_CHANNEL](state, channel) {
    const index = state.channelsCustomers.findIndex((item) => channel.id === item.id);
    state.channelsCustomers.splice(index, 1, channel);
  },
  [SET_DATA_PARAMETERS](state, params) {
    state.dataParameters = params;
  },
};

export default mutations;
