export interface Form {
  id: number;
  name: string;
  description: string;
  form: FormElement[];
  conditionals: Condition[] | null;
  updated_at?: string;
}

export enum ElementType {
  Header = "header",
  File = "file",
  ShortText = "short-text",
  LongText = "long-text",
  SingleOption = "single-option",
  MultipleOptions = "multiple-options",
  Dropdown = "dropdown",
  Reminder = "reminder",
  Date = "date",
  Hour = "hour",
  Pdp = "pdp",
}

export enum ElementStatus {
  Active = 1,
  Deleted = 2,
}

export enum ValidationType {
  Text = "text",
  Number = "number",
  TextAndNumber = "text_number",
  Email = "email",
}

export interface FormElementOption {
  name: string;
  description: string;
  type: ElementType;
}

export interface FormElement {
  name: string;
  type: ElementType;
  label: string;
  status: ElementStatus;
}

export interface FormInputElement extends FormElement {
  required: boolean;
  instruction: string;
}

export interface FormInputOption {
  id: number;
  value: string;
}

// interface FileElement extends FormInputElement {
//   file?: File;
// }

interface TextElement extends FormInputElement {
  validation: ValidationType;
}
interface DropdownElement extends FormInputElement {
  options: FormInputOption[];
}
interface SingleOptionElement extends DropdownElement {
  has_other_option: boolean;
}
interface MultipleOptionsElement extends SingleOptionElement {
  selection_limit?: number | null;
}
interface ReminderElement extends FormInputElement {
  element_inputs: { title: string; date: string; hour: string; description: string };
}
interface DateElement extends FormInputElement {
  is_range: boolean;
}
interface PpdElement extends FormInputElement {
  element_inputs: { date: string; currency: string; amount: string };
}

export interface FormElementMap {
  [ElementType.Header]: FormElement;
  [ElementType.File]: FormInputElement;
  [ElementType.ShortText]: TextElement;
  [ElementType.LongText]: TextElement;
  [ElementType.SingleOption]: SingleOptionElement;
  [ElementType.MultipleOptions]: MultipleOptionsElement;
  [ElementType.Dropdown]: DropdownElement;
  [ElementType.Reminder]: ReminderElement;
  [ElementType.Date]: DateElement;
  [ElementType.Hour]: DateElement;
  [ElementType.Pdp]: PpdElement;
}

type OmittedTypes = "type" | "name" | "status";

export interface FormStateElementMap {
  [ElementType.Header]: Omit<FormElementMap[ElementType.Header], OmittedTypes>;
  [ElementType.File]: Omit<FormElementMap[ElementType.File], OmittedTypes>;
  [ElementType.ShortText]: Omit<FormElementMap[ElementType.ShortText], OmittedTypes>;
  [ElementType.LongText]: Omit<FormElementMap[ElementType.LongText], OmittedTypes>;
  [ElementType.SingleOption]: Omit<FormElementMap[ElementType.SingleOption], OmittedTypes>;
  [ElementType.MultipleOptions]: Omit<FormElementMap[ElementType.MultipleOptions], OmittedTypes>;
  [ElementType.Dropdown]: Omit<FormElementMap[ElementType.Dropdown], OmittedTypes>;
  [ElementType.Reminder]: Omit<FormElementMap[ElementType.Reminder], OmittedTypes>;
  [ElementType.Date]: Omit<FormElementMap[ElementType.Date], OmittedTypes>;
  [ElementType.Hour]: Omit<FormElementMap[ElementType.Hour], OmittedTypes>;
  [ElementType.Pdp]: Omit<FormElementMap[ElementType.Pdp], OmittedTypes>;
}

export type DrawerAction = "add" | "edit";

export function isText(element: unknown): element is TextElement {
  if (element !== null && typeof element === "object") {
    return "validation" in element;
  }

  return false;
}

export function isDate(element: unknown): element is FormElementMap["date"] {
  if (element !== null && typeof element === "object") {
    return "is_range" in element;
  }

  return false;
}

export function isOptionElement(element: unknown): element is FormElementMap["dropdown"] {
  if (element !== null && typeof element === "object") {
    return "options" in element;
  }

  return false;
}

// CONDITIONS
export enum LogicalType {
  Everyone = "everyone",
  Anyone = "anyone",
}

export enum ConditionType {
  Contains = "contains",
  NoContains = "no_contains",
  BeginsWith = "begins_with",
  NoBeginsWith = "no_begins_with",
  EndsWith = "ends_with",
  NoEndsWith = "no_ends_with",
  IsEmpty = "is_empty",
  NoEmpty = "no_empty",
  IsEqual = "is_equal",
  NoEqual = "no_equal",
  IsBefore = "is_before",
  IsAfter = "is_after",
}

export enum ComparisonValueType {
  File = "file",
  Text = "text",
  Option = "option",
  Date = "date",
  Time = "time",
}

export enum ActionType {
  Hide = "hide",
  Show = "show",
  Require = "require",
  NoRequire = "no_require",
  Enable = "enable",
  Disable = "disable",
}

export interface FormIf {
  id: string;
  input_name?: string;
  input_label?: string;
  input_type?: ElementType;
  input_validation?: ValidationType | null;
  condition_type?: ConditionType;
  comparison_value?: string;
}

export interface FormElse {
  id: string;
  input_name?: string;
  input_label?: string;
  action_type?: ActionType;
}

export interface Condition {
  id: string;
  logical_type: LogicalType;
  form_if: FormIf[];
  form_else: FormElse[];
}

export interface ConditionedAttribute {
  show?: boolean;
  required?: boolean;
  disabled?: boolean;
}

export type ConditionElementType = "condition" | "if" | "else";
