import { SET_CARDS } from "./mutation-types";
import { SET_BUSINESS } from "./mutation-types";
import { SET_SLOTS } from "./mutation-types";

const mutations = {
  [SET_CARDS](state, cards) {
    state.cards = cards;
  },
  [SET_BUSINESS](state, enable_bases) {
    state.enable_bases = enable_bases;
  },
  [SET_SLOTS](state, business) {
    state.business = business;
  },
};

export default mutations;
