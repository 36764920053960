import { PhoneType } from "@/app/shared/utils/enums";

const initialState = () => ({
  base: undefined,
  portfolio: undefined,
  coverage: 1,
  type_phone: PhoneType.all,

  filters: null,
});

const state = initialState();

export default state;
