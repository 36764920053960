import httpManager from "../../../shared/utils/httpManager";
import { TABLE_PAGE_SIZE } from "../../../shared/utils/constants";

/**
 * Aplicar filtros avanzados.
 *
 * @param {Object} filter
 * @return {Promise<Object>} Listado de contactos filtrados.
 */
export async function applyAdvancedFilters(filters, pagination = {}) {
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  const response = await httpManager.post(
    `/contacts/clients/?offset=${offset}&limit=${pageSize}`,
    filters
  );

  return response?.data;
}

export async function fetchContactsIdsWithAdvancedFilters(filters) {
  const response = await httpManager.post(
    `/contacts/clients/for-massive-actions/`,
    filters
  );

  return response?.data;
}

