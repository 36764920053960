export const unSuscriberTableColumns = [
  {
    title: "N°",
    customRender: ({ index }: any) => index + 1,
    width: 60,
  },
  {
    title: "Destinatario (correo)",
    dataIndex: "email",
    width: 300,
  },
  // {
  //   title: "Contacto asociado",
  //   key: "associated_persons",
  //   dataIndex: "associated_persons",
  //   width: 300,
  // },
  {
    title: "Motivo de la desuscripción",
    dataIndex: "description",
    width: 300,
  },
  {
    title: "N° de resuscripciones",
    key: "cancellation_count",
    dataIndex: "cancellation_count",
    width: 300,
  },
  {
    title: "Fecha de la desuscripción",
    dataIndex: "created_at",
    width: 250,
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    width: 80,
    fixed: "right",
  },
];
