import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener colas.
 *
 * @return {Promise<Array>} Lista de colas.
  * @param {Object} pagination Paginación

 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export async function fetchAllQueues(search = "", portfolio = "", pagination = {}) {
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  let url = `/queues/?search=${search}&offset=${offset}&limit=${pageSize}`;
  if (portfolio > 0) url = url + `&portfolios=${portfolio}`;
  const response = await httpManager.get(url);
  return response?.data;
}
