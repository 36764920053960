import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener la data del usuario logueado.
 *
 * @return {Promise<object>} Data del usuario logueado.
 */
export async function fetchDataUser() {
  const url = `/users/data`;
  const response = await httpManager.get(url);
  return response?.data;
}
