<template>
  <div class="name-container">
    <a-popover placement="bottom" trigger="hover" class="supervision-agent-popover">
      <a-button type="link" class="button button__name" @click="handleClick">
        {{ fullname }}

        <a-tooltip title="Ver detalle de actividad">
          <img :src="externalLinkImage" alt="open" />
        </a-tooltip>
      </a-button>

      <template #content>
        <supervision-agent-popover
          :identifier="supervision.identifier"
          :name="fullname"
          :profile="profile"
          :phone="phone"
          :email="email"
          :state="state"
          :profile-picture="photo"
          @logout="handleLogout"
          @monitoring="handleMonitoring"
          :information="information"
          :eventManagement="eventManagement"
        />
      </template>
    </a-popover>

    <a-divider type="vertical" class="divider" />

    <a-tooltip title="Monitorear pantalla">
      <a-button type="link" class="button" @click="handleMonitoring">
        <DesktopOutlined style="font-size: 16px; margin-top: 2px" />
      </a-button>
    </a-tooltip>
  </div>

  <span v-if="!detailedView" class="profile">{{ supervision?.user?.profile_name }}</span>
</template>

<script>
import { defineComponent } from "vue";
import { DesktopOutlined } from "@ant-design/icons-vue";

import SupervisionAgentPopover from "../popover/SupervisionAgentPopover.vue";
import { StateOptions } from "@/app/shared/utils/enums";

import externalLinkImage from "@/app/supervision/assets/external-link.svg?url";

const classes = {
  [StateOptions.Connected]: "element--connected",
  [StateOptions.Refreshment]: "element--refreshment",
  [StateOptions.Occupied]: "element--occupied",
  [StateOptions.Services]: "element--services",
  [StateOptions.Training]: "element--training",
  [StateOptions.Disconnected]: "element--disconnected",
};

export default defineComponent({
  components: {
    SupervisionAgentPopover,
    DesktopOutlined,
  },
  props: {
    supervision: { required: true, type: Object },
    detailedView: { required: true, type: Boolean },
  },
  setup() {
    return { externalLinkImage };
  },
  emits: ["click", "logout"],
  computed: {
    fullname() {
      return this.supervision.user.fullname;
    },
    email() {
      return this.supervision.user.email;
    },
    profile() {
      return this.supervision.user?.profile_name;
    },
    phone() {
      return this.supervision.user.phone;
    },
    photo() {
      return this.supervision.user?.picture;
    },
    connected() {
      return true;
    },
    state() {
      return this.supervision.state;
    },
    getClasses() {
      return { [classes[this.state]]: true };
    },
    information() {
      return this.supervision.user;
    },
    eventManagement() {
      return this.supervision.event_management;
    },
  },
  methods: {
    handleClick() {
      this.$emit("click");
    },
    async handleLogout() {
      this.$emit("logout");
      // await disconnectAgentById(this.supervision.user.id);
    },
    handleMonitoring() {
      this.$emit("screen-monitoring");
    },
  },
});
</script>

<style lang="sass" scoped>
.name-container
  display: flex
  align-items: center
  gap: 8px

.divider
  background-color: $gray-5
  margin: 0
  height: 22px

.button
  padding: 0
  height: 22px
  display: flex
  align-items: center

  &__name
    max-width: calc(100% - 32px)
    flex: 0 1 auto
    gap: 5px

.profile
  color: $gray-7
  font-size: 12px
</style>

<style>
.supervision-agent-popover span {
  overflow: hidden !important;
  text-overflow: ellipsis !important;
}
</style>
