export const dateFormat = "YYYY-MM-DD HH:mm:ss";
export const TABLE_PAGE_SIZE = 50;
export const TABLE_PAGE_SIZE_REDUCE = 10;
export const TABLE_SLOTS_SIZE = 20;
export const TABLE_PAGE_SIZE_XL = 50;
export const humanDateFormat = "DD/MM/YYYY";
export const humanDateWithSlashFormat = "DD/MM/YYYY";
export const humanDateTimeWithSlashFormat = "DD/MM/YYYY hh:mm:ss";
export const regexpEmailFormat =
  /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const TABLE_MAX_SIZE = 2;
export const TABLE_PAGE_SIZE_DETAIL_CONTACT = 2;
export const regexpUrl =
  /^(http(s)?:\/\/)?(([a-zA-Z0-9-]+\.)+[a-zA-Z]{2,7}|(\d{1,3}\.){3}\d{1,3})(:\d{1,5})?$/;
export const DATE_CALENDAR = {
  sameDay: "[Hoy, ] HH:mm", // The same day ( Today at 2:30 AM )
  nextDay: "[Mañana, ] HH:mm", // The next day ( Tomorrow at 2:30 AM )
  nextWeek: "dddd [a las] h:mm A", // The next week ( Sunday at 2:30 AM )
  lastDay: "[Ayer, ] HH:mm", // The day before ( Yesterday at 2:30 AM )
  lastWeek: "[Pasado] dddd [a las] h:mm A", // Last week ( Last Monday at 2:30 AM )
  sameElse: "DD/MM/YYYY", // Everything else ( 17/10/2011 )
};

export const CompanyName = import.meta.env.VITE_COMPANY_NAME;
export const ProductName = import.meta.env.VITE_PRODUCT_NAME;

