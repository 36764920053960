import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener canales de cliente dado un ticket.
 * @param {number} ticketId Identificador de ticket.
 * @return {Promise<Array>} Listado de canales
 */
export async function fetchChannelsByTicketWithoutChannelPerson(ticketId) {
  const url = `/tickets/${ticketId}/`;
  const response = await httpManager.get(url);
  return response?.data;
}
