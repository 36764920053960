<template>
  <section class="keyboard">
    <div v-if="call && !insideOverlay" class="keyboard-title">
      <web-phone-elapsed-time v-if="mode == 'fixed'" />
      <div class="header-keyboard">{{ name }} | {{ status }}</div>
    </div>

    <div class="keyboard__input-wrapper">
      <SelectorCountry
        :code="country"
        @selected="
          country = $event;
          $emit('country', $event);
        "
        @codePhone="$emit('codePhone', $event)"
        webphone
      />

      <input
        ref="inputRef"
        v-model="number"
        class="keyboard__input"
        type="text"
        @keypress="validateInput"
        @keydown.enter="$emit('enter-press', $event)"
        @paste="handlePaste"
      />
    </div>

    <a-divider class="divider" :class="{ 'divider-overlay': insideOverlay }" />

    <CampaignPicker
      class="portfolio-picker"
      :value="portfolio"
      @update:value="$emit('update:portfolio', $event)"
      with-out-entity
      webphone
      :show-all="false"
    />

    <div class="keyboard__keys" :class="{ 'keyboard__keys-overlay': insideOverlay }">
      <div
        v-for="key in keys"
        :key="key"
        class="keyboard__key"
        :class="{ 'keyboard__key-overlay': insideOverlay }"
        @click="handleClickKey(key)"
      >
        {{ key }}
      </div>

      <div />

      <div v-if="!call">
        <slot />
      </div>

      <div
        class="keyboard__key keyboard__key--flat"
        :class="{ 'keyboard__key-overlay': insideOverlay }"
        @click="handleClickDelete"
      >
        <img :src="deleteImage" alt="delete" />
      </div>
    </div>
  </section>
</template>

<script>
import { defineComponent, ref } from "vue";

import { statuses } from "../../common/statuses";
import { usePhoneNumberValidation } from "@/app/shared/composables/usePhoneNumberValidation";

import WebPhoneElapsedTime from "./WebPhoneElapsedTime.vue";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
// import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";

import deleteImage from "../../assets/icons/delete.svg?url";

const keys = ["1", "2", "3", "4", "5", "6", "7", "8", "9", "*", "0", "#"];

export default defineComponent({
  components: {
    WebPhoneElapsedTime,
    CampaignPicker,
    SelectorCountry,
  },
  props: {
    mode: {
      type: String,
      required: true,
      default: "",
    },
    receiver: { type: Object, default: () => ({}) },
    value: {
      required: true,
      type: String,
    },
    call: {
      type: Boolean,
      required: true,
    },
    insideOverlay: {
      type: Boolean,
      default: false,
    },
    codeCountry: String,
    portfolio: Number,
  },
  mounted() {
    this.$nextTick(() => {
      if (!this.$refs?.inputRef) return;
      this.$refs.inputRef.focus();
    });
  },
  setup() {
    const { handlePaste, validateInput } = usePhoneNumberValidation();
    const country = ref(1);

    return { handlePaste, validateInput, country, deleteImage };
  },
  data() {
    return {
      keys,
    };
  },
  computed: {
    isCallEnded() {
      return this.$jssip.phone.status !== statuses["in-call"];
    },
    number: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("update:value", value);
      },
    },
    name() {
      return this.receiver?.name || "No identificado";
    },
    status() {
      return statuses[this.$jssip.phone.status];
    },
  },
  methods: {
    handleClickKey(key) {
      if (this.call) {
        this.$jssip.sendDTMF(key);
      }
      this.number = `${this.number}${key}`;
    },
    handleClickDelete() {
      this.number = this.number.slice(0, -1);
    },
  },
  watch: {
    isCallEnded(value) {
      if (value) {
        this.number = "";
      }
    },
  },
});
</script>

<style lang="sass" scoped>
@import '../../styles/main'

$key-size: 48px
$key-size-small: 40px

.keyboard
  color: $black
  display: flex
  flex-direction: column
  align-items: center
  user-select: none
  position: relative
  margin-bottom: 15px
  width: 100%

  &__input
    height: 40px
    border: none
    font-size: 22px
    font-weight: 500
    text-align: center
    background-color: unset
    width: 100%

    &:focus
      border: none
      outline: none

    &-wrapper
      display: flex
      align-items: center
      width: 100%
      padding: 0 10px

  &__keys
    display: grid
    grid-template-columns: repeat(3, $key-size)
    // column-gap: 28px
    // row-gap: 16px
    gap: 16px 28px

    &-overlay
      grid-template-columns: repeat(3, $key-size-small)
      gap: 10px 22px

  &__key
    width: $key-size
    height: $key-size
    font-size: 28px
    font-weight: 600
    border-radius: 50%
    background-color: rgba(214, 228, 255, 0.4)
    display: flex
    align-items: center
    justify-content: center
    cursor: pointer
    text-align: center

    &-overlay
      width: $key-size-small
      height: $key-size-small
      font-size: 24px
      background-color: rgba(214, 228, 255, 0.1)

    &--flat
      background-color: transparent

.header-keyboard
  font-size: 12px
  font-style: normal
  font-weight: 400
  letter-spacing: 0em
  text-align: center
  color: #8C8C8C

.keyboard-title
  text-align: center
  margin-top: 30px

.portfolio-picker
  width: 100%
  margin-bottom: 12px
  padding: 0 16px

.divider
  margin-top: 6px
  margin-bottom: 8px
  background-color: rgba(0, 21, 41, 0.2)
  width: calc(100% + 20px)

  &-overlay
    margin-bottom: 10px
</style>
