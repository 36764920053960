<template>
  <!--Boton que muestra el modal-->
  <a-button
    type="text"
    class="filter-button"
    :class="{ 'filter-button-active': isActive }"
    shape="circle"
    @click="visible = true"
  >
    <FilterFilled v-if="isActive" />
    <FilterOutlined v-else />
  </a-button>
  <!--Modal-->
  <a-modal :visible="visible" title="Aplicar filtros" @cancel="handleClose">
    <a-form layout="vertical" :model="modalFormState">
      <!--Selector de colas-->
      <QueuePicker
        showAll
        defaultBehavior
        withOutPortfolio
        v-model:value="modalFormState.queue"
        label="Cola"
      />
      <!--Selector de canales-->
      <channels-picker
        class="input-channel"
        defaultBehavior
        :value="modalFormState.channel"
        @update:value="
          modalFormState.channel = $event;
          modalFormState.identifier = '';
        "
        label="Canales"
      />
      <!-- :channel-type="ChannelType.Email" :hiddenIdentifier="modalFormState.channel" -->
      <!--Selector de identificadores-->
      <IdentifierPicker
        v-model:value="modalFormState.identifier"
        label="Identificadores"
        :disabled="false"
        v-model:channel-type="modalFormState.channel"
        withOutPortfolio
        showAll
        defaultBehavior
        class="input-identifier"
      />
      <!--Selector de mensajes leidos o no-->
      <a-form-item label="Tickets leidos">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          v-model:value="modalFormState.read"
        >
          <a-select-option value="">Todos</a-select-option>
          <a-select-option value="True">Tickets leídos</a-select-option>
          <a-select-option value="False">Tickets no leídos</a-select-option>
        </a-select>
      </a-form-item>
      <!--Selector de etiquetas-->
      <a-form-item label="Etiquetas">
        <a-select
          :getPopupContainer="(triggerNode) => triggerNode.parentNode"
          type="multiple"
          v-model:value="modalFormState.tag"
        >
          <a-select-option value="">Todas las etiquetas</a-select-option>
          <a-select-option v-for="tag in tags" :key="tag.id">{{ tag.name }}</a-select-option>
        </a-select>
      </a-form-item>
    </a-form>

    <template #footer>
      <a-button class="button" :loading="ticketsListLoading" @click="handleCleanFilters">
        Limpiar
      </a-button>
      <a-button type="primary" class="button" :loading="ticketsListLoading" @click="handleApply">
        Aplicar
      </a-button>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { inject, reactive, ref, watch, computed } from "vue";
import type { Ref } from "vue";
import { FilterOutlined, FilterFilled } from "@ant-design/icons-vue";
import { Tag } from "@/@types/global";
import { WorkspaceVars } from "@/@types/workspace";
import QueuePicker from "@/app/shared/components/pickers/QueuePicker.vue";
import ChannelsPicker from "@/app/shared/components/pickers/ChannelsPicker.vue";
import IdentifierPicker from "@/app/shared/components/pickers/IdentifierPicker.vue";

interface TicketFilters extends TicketModalFilters {
  search?: string;
  channel: number | string;
}

interface TicketModalFilters {
  queue: number | string;
  read: string;
  tag: string;
  identifier: number | string;
}

/**
 * filterState has a watcher in TicketsFilters.vue, so every change
 * updates de global filter state and fetch the data
 */
const filterState = inject("filterState") as TicketFilters;
const ticketsListLoading = inject("ticketsListLoading") as Ref<boolean>;
const workspaceDataSource = inject("workspaceDataSource") as Ref<WorkspaceVars | undefined>;
const visible = ref(false);
const isActive = ref(false);

const modalFormState = reactive<TicketFilters>({
  queue: filterState.queue,
  channel: filterState.channel,
  identifier: filterState.identifier,
  read: "",
  tag: "",
});

// WATCHERS
watch(ticketsListLoading, (newValue: boolean) => {
  if (!newValue) handleClose();
});

watch(visible, (newValue: boolean) => {
  if (newValue) {
    modalFormState.queue = filterState.queue;
    modalFormState.channel = filterState.channel;
    modalFormState.identifier = filterState.identifier;
  }
});

// COMPUTED
const hasFilters = computed(() => {
  let key: keyof typeof filterState;

  for (key in filterState) {
    if (filterState[key]) return true;
  }

  return false;
});

const tags = computed<Tag[]>(() => {
  return workspaceDataSource.value?.tags || [];
});

// FUNCIONES

const handleApply = () => {
  filterState.queue = modalFormState.queue;
  filterState.channel = modalFormState.channel;
  filterState.identifier = modalFormState.identifier;
  filterState.read = modalFormState.read;
  filterState.tag = modalFormState.tag;
};

const handleCleanFilters = () => {
  filterState.search = "";
  filterState.channel = "";
  filterState.queue = "";
  filterState.identifier = "";
  filterState.read = "";
  filterState.tag = "";
  modalFormState.queue = "";
  modalFormState.channel = "";
  modalFormState.identifier = "";
  modalFormState.read = "";
  modalFormState.tag = "";
};

const handleClose = () => {
  visible.value = false;
  isActive.value = hasFilters.value;
};
</script>

<style lang="sass" scoped>
.filter-button
  position: relative
  font-size: 20px
  display: flex
  align-items: center
  justify-content: center
  color: $gray-8

  &-active::after
    content: ""
    width: 13px
    height: 13px
    border-radius: 50%
    background-color: red
    position: absolute
    top: -2px
    right: -4px
    border: 1px solid white

.input-identifier
  margin-bottom: 24px
</style>
