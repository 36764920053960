<template>
  <span class="text" :class="{ floating: floating }">
    {{ isCurrentTicket || floating ? hour(elapsedTime) : "00:00" }}
  </span>
</template>

<script>
import { secondsToHHMMSS } from "../../utils/time";
import { defineComponent } from "vue";
import EventBus from "@/app/shared/utils/eventBus";

export default defineComponent({
  props: {
    floating: { type: Boolean, default: false },
  },
  methods: {
    hour(seconds) {
      return secondsToHHMMSS(seconds);
    },
  },
  data() {
    return {
      start: null,
      end: null,
      interval: null,
      coso: 0,
    };
  },
  mounted() {
    EventBus.on("SET_START_TIME", () => {
      this.runClock();
    });
    EventBus.on("SET_END_TIME", () => {
      clearInterval(this.interval);
      this.end = new Date();
    });
    EventBus.on("SET_CLEAR_TIME", () => {
      this.start = null;
      this.end = null;
    });
  },
  computed: {
    /**
     * Elapsed time in seconds.
     * @return {number} Elapsed seconds.
     */
    now() {
      return this.dateToSeconds(new Date());
    },
    elapsedTime() {
      return this.$jssip.phone.time.elapsedTime;
    },
    isCurrentTicket() {
      const ticketId = this.$route.query?.ticket;
      const interactionId = this.$route.query?.interaction;
      // const { ticket, channel_person } = this.$jssip.phone?.meta;
      // return ticketId == ticket && channelId == channel_person;
      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
  },
});
</script>

<style lang="sass" scoped>
.text
  font-size: 30px
  font-weight: 600
</style>
