import httpManager from "@/app/shared/utils/httpManager";
/**
 * Eliminar una entidad.
 *
 * @param {number} entityId Identificador de la entidad.
 * @param {number} tokenId Identificador del token
 */
export async function deleteEntity(entityId, tokenId) {
  await httpManager.delete(`/entities/${entityId}/?token=${tokenId}`);
}
