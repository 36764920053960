import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear un nuevo troncal.
 *
 * @param {Object} data Datos del plan de marcación a crear.
 *
 * @return {Promise<Object>} Nuevo plan de marcación
 */
export default async function (data) {
  const body = {
    ...data,
  };
  const response = await httpManager.post("/pbx/dialing-plans/", body);
  return response?.data;
}
