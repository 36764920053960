export const StrategyType = Object.freeze({
  predictive: 1,
  progressive: 2,
  sms: 3,
  ivr: 4,
  whatsapp: 5,
  email: 6,
});

export const ChannelType = Object.freeze({
  Voice: 1, // Voice Outbound
  Sms: 2,
  Whatsapp: 3,
  Ivr: 4,
  Inbound: 5,
  Messenger: 6,
  VoiceInbound: 7,
  Instagram: 8,
  Email: 9,
  Multichannel: 99,
});

export const InteractionType = Object.freeze({
  Inbound: 1,
  Outbound: 2,
});

// export const ProviderType = {

// };

export const MetaAppType = {
  Messenger: 1,
  Instagram: 2,
};

export const PhoneType = {
  all: 3,
  mobile: 2,
  landline: 1,
};

export const StatusAgent = Object.freeze({
  success: 1, //Disponible
  processing: 2, //En llamada
  acw: 3, //ACW
  capacitacion: 4, //En capaciotacion
  servicios: 5, //En servicios
  disconnect: 6,
});

export const DocumentType = {
  dni: 1, //DNI
  ce: 2, // Carnet de extrangeria
  pasaporte: 3, //Pasaporte
  ruc: 4,
};

export const OriginType = {
  personal: 1, //Personal
  job: 2, // Trabajo
};

export const MessageType = {
  Text: 1,
  File: 2,
  Image: 3,
  Audio: 4,
  Notification: 5,
  Sticker: 6,
  Video: 7,
  Buttons: 8,
  List: 9,
  Card: 10,
  MessageResponse: 11,
  TextWithImage: 13,
  TextWithFile: 14,
  TextWithVideo: 15,
  Location: 17,
  Contacts: 18,
};

export const SupportType = {
  Agent: 1,
  Chatbot: 2,
};

export const StateOptions = {
  Connected: 1,
  Occupied: 2,
  Refreshment: 3,
  Services: 4,
  Training: 5,
  Disconnected: 6,
  NotAvailable: 7,
  Dinner: 8,
};

export const StateTexts = {
  [StateOptions.Connected]: "Disponible",
  [StateOptions.Refreshment]: "Almuerzo",
  [StateOptions.Occupied]: "Ocupado",
  [StateOptions.Services]: "En servicios",
  [StateOptions.Training]: "En capacitación",
  [StateOptions.Disconnected]: "Desconectado",
  [StateOptions.NotAvailable]: "No disponible",
  [StateOptions.Dinner]: "Cena",
};

export const StateImageName = {
  [StateOptions.Occupied]: "occupied.png",
  [StateOptions.Refreshment]: "refreshment.png",
  [StateOptions.Training]: "training.png",
  [StateOptions.Services]: "services.png",
  [StateOptions.Dinner]: "refreshment.png",
};

export const UserStatus = Object.freeze({
  active: 1,
  inactive: 2,
  delete: 3,
  pending: 4,
});

export const EventManagement = {
  Login: 1, // Inicio de sesion
  Online: 2, // Conectado
  Ringing: 3, // Timbrando
  Talking: 4, // Hablando
  Acw: 5, // ACW
  Typifying: 6, // Tipificando
  Refreshment: 7, // Refrigerio
  Services: 8, // Servicios
  Training: 9, // Capacitación
  Disconnected: 10, // Desconectado
  Occupied: 11, // Ocupado
  Opened: 12, //Abriendo
  Writing: 13, //Escribiendo
  Resolved: 14, //Resolviendo
  Waiting: 15, //Esperando
  Inactive: 16, //Inactivo
  Dinner: 17, // Cena
};

export const EventTexts = {
  [EventManagement.Online]: StateTexts[StateOptions.Connected],
  [EventManagement.Ringing]: "Timbrando",
  [EventManagement.Talking]: "Hablando",
  [EventManagement.Acw]: "ACW",
  [EventManagement.Refreshment]: StateTexts[StateOptions.Refreshment],
  [EventManagement.Services]: StateTexts[StateOptions.Services],
  [EventManagement.Training]: StateTexts[StateOptions.Training],
  [EventManagement.Disconnected]: StateTexts[StateOptions.Disconnected],
  [EventManagement.Occupied]: StateTexts[StateOptions.Occupied],
  [EventManagement.Opened]: "Abriendo",
  [EventManagement.Writing]: "Escribiendo",
  [EventManagement.Waiting]: "Esperando",
  [EventManagement.Inactive]: "Inactivo",
  [EventManagement.Dinner]: StateTexts[StateOptions.Dinner],
};

export const DialingType = Object.freeze({
  Manual: 1,
  Progressive: 2,
  Predictive: 3,
  Ivr: 4,
  Inbound: 5,
  Sequence: 6,
  SequenceMultiple: 8,
});

export const TypeBusiness = {
  contactCenter: 1,
  inHouse: 2,
};

export const RolTypes = Object.freeze({
  owner: 1,
  administrator: 2,
  agent: 3,
  support: 4,
});

export const ProfileTypes = Object.freeze({
  Owner: 1,
  Administrator: 2,
  Supervisor: 3,
  Agent: 4,
  Support: 5,
});

export const LanguageTypes = Object.freeze({
  spanish: "Español",
  english: "English",
});

export const TicketState = Object.freeze({
  wait: 1,
  delivered: 2,
  answered: 3,
  finished: 4,
  // noLabels: "no_labels",
});

export const FormTypes = Object.freeze({
  Reminder: 1,
  PayPromiseReminder: 2,
  PayPromise: 3,
  Pay: 4,
  Sell: 5,
  ATC: 6,
});

export const PdpType = Object.freeze({
  perClient: 1,
  perProduct: 2,
});

export const WebphoneStatus = Object.freeze({
  Connected: 1,
  Connecting: 2,
  Disconnected: 3,
});

export const WebphoneColor = {
  [WebphoneStatus.Connected]: { tag: "green", hexadecimal: "#389e0d" },
  [WebphoneStatus.Connecting]: { tag: "default", hexadecimal: "#000000d9" },
  [WebphoneStatus.Disconnected]: { tag: "red", hexadecimal: "#cf1322" },
};

export const WebphoneText = {
  [WebphoneStatus.Connected]: "Conectado",
  [WebphoneStatus.Connecting]: "Conectando...",
  [WebphoneStatus.Disconnected]: "Desconectado",
};

export const TypificationNameByType = {
  group: "Grupo",
  result: "Resultado",
  motive: "Motivo",
  submotive: "Submotivo",
};

export const EmailState = Object.freeze({
  try: 1,
  assigned: 2,
  resolved: 3,
});

export const EmailInboxState = Object.freeze({
  unread: 1,
  read: 2,
  unanswered: 3,
  answered: 4,
});

export const StrategyStatus = Object.freeze({
  Finalized: 1,
  InProgress: 2,
  PendingToStart: 3,
  Paused: 4,
  NoFunds: 5,
});

export const StrategyText = {
  [StrategyStatus.Finalized]: "Finalizado",
  [StrategyStatus.InProgress]: "En progreso",
  [StrategyStatus.PendingToStart]: "Pendiente por iniciar",
  [StrategyStatus.Paused]: "Pausada",
  [StrategyStatus.NoFunds]: "Sin fondos",
};

export const StrategyColor = {
  [StrategyStatus.Finalized]: { tag: "green", hexadecimal: "#389e0d" },
  [StrategyStatus.InProgress]: { tag: "processing", hexadecimal: "#000000d9" },
  [StrategyStatus.PendingToStart]: { tag: "default", hexadecimal: "#a0a0a0" },
  [StrategyStatus.Paused]: { tag: "warning", hexadecimal: "#ffa500" },
  [StrategyStatus.NoFunds]: { tag: "red", hexadecimal: "#cf1322" },
};

import {
  CheckCircleOutlined,
  SyncOutlined,
  ClockCircleOutlined,
  PauseCircleOutlined,
  DollarCircleOutlined,
} from "@ant-design/icons-vue";
export const StrategyImageName = {
  [StrategyStatus.Finalized]: CheckCircleOutlined,
  [StrategyStatus.InProgress]: SyncOutlined,
  [StrategyStatus.PendingToStart]: ClockCircleOutlined,
  [StrategyStatus.Paused]: PauseCircleOutlined,
  [StrategyStatus.NoFunds]: DollarCircleOutlined,
};
