<template>
  <a-carousel v-if="payload" class="carousel_card" :dots="false" arrows>
    <template #prevArrow>
      <div class="custom-slick-arrow" style="margin-left: 6px">
        <LeftOutlined />
      </div>
    </template>

    <template #nextArrow>
      <div class="custom-slick-arrow" style="margin-right: 6px">
        <RightOutlined />
      </div>
    </template>

    <div class="card" v-for="card in mappedCards" :key="card.title">
      <img :src="card.img_url" :alt="card.title" />

      <div class="details">
        <h2>{{ card.title }}</h2>

        <p>{{ card.description }}</p>
      </div>

      <div
        v-for="button in card.buttons"
        :key="button.title"
        class="button"
        :class="{ disabled: button.payload === responseText }"
      >
        {{ button.title }}
      </div>
    </div>
  </a-carousel>
</template>

<script setup lang="ts">
import { LeftOutlined, RightOutlined } from "@ant-design/icons-vue";

import { CardMessage, MetaCardMessagePayload } from "@/@types/workspace/chats/message";
import { computed } from "vue";
import { ChannelType } from "@/app/shared/utils/enums";
import { WebchatCardMessagePayload } from "@/@types/workspace/chats/message";

const props = defineProps<{ payload: unknown; channelId: number; responseText?: string }>();

const formatToCardMessage = (typedPayload: MetaCardMessagePayload) =>
  typedPayload.attachment.payload.elements.map<CardMessage>((e) => ({
    title: e.title,
    description: e.subtitle,
    img_url: e.image_url,
    buttons: e.buttons.map((b) => ({ title: b.title, type: b.content_type, payload: b.payload })),
  }));

const mappedCards = computed<CardMessage[]>(() => {
  if (props.channelId === ChannelType.Messenger) {
    const typedPayload = props.payload as MetaCardMessagePayload;

    return formatToCardMessage(typedPayload);
  }

  if (props.channelId === ChannelType.Inbound) {
    const typedPayload = props.payload as WebchatCardMessagePayload;

    return formatToCardMessage(typedPayload[0].value);
  }

  return [];
});
</script>

<style scoped lang="sass">
.card
  background-color: white
  border-radius: 20px
  overflow: hidden
  border-bottom: 1px solid $gray-5

img
  height: 120px
  width: 100%
  object-fit: cover

.details
  padding: 8px 12px
  font-size: 12px
  font-weight: 400
  line-height: 20px

h2
  color: $gray-9
  margin: 0
  font-weight: 600

p
  color: $text-secondary
  margin: 0

.button
  width: 100%
  line-height: 22px
  text-align: center
  color: $text-tertiary
  border-top: 1px solid $gray-5

  &.disabled
    color: $text-disabled
    cursor: not-allowed

.custom-slick-arrow
  height: 30px
  width: 30px
  display: flex !important
  justify-content: center
  align-items: center
  border-radius: 50%
  background-color: white
  filter: drop-shadow(0px 2px 8px rgba(0, 0, 0, 0.15))
  z-index: 4

  > span
    color: $gray-9
    font-size: 14px

:deep(.slick-arrow.custom-slick-arrow:hover)
  background-color: white
</style>
