import httpManager from "@/app/shared/utils/httpManager";

/**
 * Agregar nuevo telefono.
 * @param {number} ticketId Identificador del ticket.
 * @param {Object} phone Datos de telefono.
 *
 * @return {Promise<Object>} Datos del telefono creado
 */
export async function addPhonesClient(ticketId, phone) {
  const url = `/workspace/tickets/${ticketId}/phones/`;
  const response = await httpManager.post(url, phone);
  return response?.data;
}
