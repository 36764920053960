const initialState = () => ({
  queuesInfo: {
    pagination: {},
    items: [],
  },
});

const state = initialState();

export default state;
