<template>
  <a-modal
    :open="open"
    title="Enviar mensaje de SMS"
    width="700px"
    centered
    :bodyStyle="{ minHeight: '540px' }"
    :mask-closable="false"
    @cancel="handleClose"
  >
    <section class="container">
      <div style="flex: 1 1 auto">
        <!-- Tipo de mensaje -->
        <template v-if="currentStep === 1">
          <h2>Tipo de mensaje</h2>

          <a-divider class="divider" />

          <h3>Datos del contacto</h3>

          <p style="margin-bottom: 8px">Nombre: {{ contact }}</p>

          <p style="margin-bottom: 8px">
            Teléfono: +{{ phone?.country?.code_phone }} {{ phone?.number }}
          </p>
          <p>Campaña: {{ portfolio.name }}</p>

          <a-divider class="divider" />

          <a-form layout="vertical" :model="configFormState">
            <a-form-item name="message_type" label="Tipo de mensaje" required>
              <a-select
                :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                v-model:value="configFormState.message_type"
                placeholder="Seleccionar tipo de mensaje"
              >
                <a-select-option key="template">Usar plantilla</a-select-option>
                <a-select-option key="write">Redactar mensaje</a-select-option>
              </a-select>
            </a-form-item>

            <QueuePicker
              v-model:value="configFormState.queue"
              :portfolio="portfolio.id"
              label="Cola"
              required
              defaultBehavior
              :smsTemplatePicker="configFormState.message_type === 'template'"
              :showAll="false"
              :tooltip="
                configFormState.message_type === 'template'
                  ? 'Se listarán todas las colas asociadas a la campaña y cuenten con una plantilla de SMS asociada.'
                  : 'Se listarán todas las colas asociadas a la campaña del contacto. '
              "
            />

            <SmsTemplatePicker
              v-if="configFormState.message_type === 'template'"
              type="template"
              :queue="configFormState.queue"
              selectLabel="Plantilla"
              @update:templateId="configFormState.template = $event"
              @update:template="message = $event || ''"
            />
          </a-form>
        </template>

        <!-- Completar parametros -->
        <template v-if="currentStep === 2 && configFormState.message_type === 'template'">
          <h2 style="margin-bottom: 16px">Completa los parámetros</h2>

          <CustomScrollbar v-if="parametersNumber" height="430px" auto>
            <a-form layout="vertical" class="form" ref="formRef" :model="parametersFormState">
              <a-form-item
                v-for="idx in parametersNumber"
                :key="idx"
                :label="`Nombre del parámetro {{${idx}}}`"
                :name="idx"
                required
              >
                <a-input
                  v-model:value="parametersFormState[idx]"
                  placeholder="Escribir nombre del parámetro"
                  show-count
                  :maxlength="50"
                />
              </a-form-item>
            </a-form>
          </CustomScrollbar>

          <p v-else>Esta plantilla no cuenta con parámetros</p>
        </template>

        <!-- Redactar sms -->
        <template v-if="currentStep === 2 && configFormState.message_type === 'write'">
          <h2 style="margin-bottom: 16px">Redacta el mensaje de SMS</h2>

          <SmsTemplatePicker type="write" @update:message="message = $event || ''" />
        </template>
      </div>

      <SmsTemplatePhone
        :content="message"
        :state="parametersFormState"
        preview="Visualiza el contenido de tu mensaje SMS aquí."
      />
    </section>

    <template #footer>
      <footer>
        <a-button @click="handleSecondaryClick">
          {{ currentStep === 1 ? "Cancelar" : "Volver" }}
        </a-button>
        <a-button
          type="primary"
          :disabled="isPrimaryDisabled"
          :loading="loading"
          @click="handlePrimaryClick"
        >
          {{ currentStep === 1 ? "Continuar" : "Enviar" }}
        </a-button>
      </footer>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import { computed, reactive, ref } from "vue";
import { message as antMessage } from "ant-design-vue";

import { Phone } from "@/@types/global";
import { ConfigForm, ParametersForm, SendSmsTemplateDTO } from "@/@types/workspace/smsModal";
import { templateParamRegex } from "@/app/channels/utils/regex";
import { smsTemplatesService } from "@/app/channels/services/SmsTemplates.service";
import { ResponseError } from "@/app/shared/utils/httpManager";

import QueuePicker from "@/app/shared/components/pickers/QueuePicker.vue";
import SmsTemplatePicker from "@/app/strategies/components/pickers/SmsTemplatePicker.vue";
import SmsTemplatePhone from "@/app/channels/components/modals/sms/templates/SmsTemplatePhone.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

const props = defineProps<{
  open: boolean;
  contact: string;
  phone: Phone;
  portfolio: { id: number; name: string };
}>();

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const currentStep = ref(1);

const configFormState: ConfigForm = reactive({
  message_type: undefined,
  queue: undefined,
  template: undefined,
});
const message = ref("");
const parametersFormState: ParametersForm = reactive({});

const parametersNumber = computed(() => message.value.match(templateParamRegex)?.length);

const isPrimaryDisabled = computed(() => {
  const { message_type, queue, template } = configFormState;

  if (currentStep.value === 1)
    return message_type === "write"
      ? !(message_type && queue)
      : !(message_type && queue && template);

  if (currentStep.value === 2) {
    if (message_type === "write") return !message.value;

    if (message_type === "template") {
      const parameters = Object.values(parametersFormState);

      return parameters.length
        ? parameters.filter(Boolean).length !== parametersNumber.value
        : false;
    }
  }

  return true;
});

const handleSecondaryClick = () => {
  if (currentStep.value === 2) {
    currentStep.value--;
    return;
  }

  handleClose();
};

const loading = ref(false);

const handlePrimaryClick = async () => {
  if (currentStep.value === 1) {
    currentStep.value++;
    return;
  }

  loading.value = true;

  try {
    if (!configFormState.queue) throw new Error("La cola es requeida");

    const body: SendSmsTemplateDTO = {
      country: props.phone.country.id,
      message: message.value || undefined,
      phone_number: props.phone.number,
      queue: configFormState.queue,
      template: configFormState.template,
      template_values: Object.values(parametersFormState),
    };

    await smsTemplatesService.sendTemplate(body);

    handleClose();
  } catch (error) {
    let errorMessage = "Ha ocurrido un error";

    let fullErrorMessage;
    if (error instanceof ResponseError) {
      fullErrorMessage = error.getErrorMessage();

      if (error?.status === 400) {
        const regex = /code:(\w+) type:(\w+) description:phone_number:(.+)/;
        const match = fullErrorMessage.match(regex);

        if (match) {
          const code = match[1];
          const type = match[2];
          const phoneNumberDescription = match[3];

          console.log("Code:", code);
          console.log("Type:", type);
          console.log("Description (phone_number):", phoneNumberDescription);
          errorMessage = phoneNumberDescription;
        } else {
          errorMessage = "Error no match found";
        }
      } else {
        errorMessage = fullErrorMessage;
      }
    }

    antMessage.error(errorMessage);
    console.error(error);
  }

  loading.value = false;
};

const handleClose = () => emit("update:open", false);
</script>

<style scoped lang="sass">
.container
  display: flex
  gap: 32px
  align-items: flex-start
  padding: 20px 40px

h2
  color: $gray-9
  font-size: 24px
  margin: 0

h3
  color: $text-primary
  font-size: 16px
  font-weight: 600

p
  color: $text-secondary
  margin: 0

.divider
  background-color: $gray-4
  margin: 20px 0
</style>
