import httpManager from "@/app/shared/utils/httpManager";
/**
 * Crear una nueva entidad.
 *
 * @param {FormData} entity Datos de la entidad.
 *
 * @return {Promise<Object>} Entidad creada.
 */
export default async function (entity) {
  const response = await httpManager.upload("/entities/", entity);
  return response?.data;
}
