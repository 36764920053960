<template>
  <section class="section">
    <a-typography-text class="section__description">
      Visualiza y gestiona tus descargas. Podrás descargar tus archivos hasta 24 horas después que
      se completo su procesamiento. <anchor :href="webDoc">Click aquí</anchor> para conocer más.
    </a-typography-text>
    <a-space :size="8" class="filters">
      <a-input-search
        class="filters__search"
        v-model:value="filters.name"
        placeholder="Buscar por nombre"
        @search="handleChange"
      />
      <a-range-picker
        class="filters__range"
        v-model:value="filters.date"
        :disabled-date="disabledDateFuture"
        format="DD/MM/YYYY"
        valueFormat="YYYY-MM-DD"
        @change="handleChange"
      />
    </a-space>
    <list-cards-downloads :data-source="dataSource?.results" :loading="loading" />
  </section>
</template>

<script setup lang="ts">
import { reactive, onMounted, ref, provide, computed } from "vue";
import ListCardsDownloads from "@/app/settingsMenu/components/lists/ListCardsDownloads.vue";
import { disabledDateFuture, dateToString } from "@/app/shared/utils/dates";
import { fetchDownloads } from "@/app/settingsMenu/services";
import { ResponseDownload } from "@/@types/settingsMenu/downloads";
import { useSocket } from "@/app/shared/composables/useSocket";
import Anchor from "@/app/shared/components/molecules/Anchor.vue";

const currentDay = dateToString(new Date())?.substring(0, 10);
const filters = reactive({
  name: "",
  date: currentDay ? ref([currentDay, currentDay]) : [],
});

const webDoc = import.meta.env.VITE_WEB_DOC;

const loading = ref(false);
const dataSource = ref<ResponseDownload>();

onMounted(() => {
  loadDataInitial();
});

const nextUrl = computed(() => {
  return dataSource.value?.next || "";
});

/**
 * Carga la data inicial
 */
const loadDataInitial = async () => {
  handleChange();
};
/**
 * Escucha el cambio de los filtros
 */
const handleChange = async () => {
  loading.value = true;
  const response = await fetchDownloads({
    name: filters.name,
    start_date:
      filters.date && filters.date[0]
        ? `${dateToString(filters.date[0])?.substring(0, 10)} 00:00:00`
        : "",
    end_date:
      filters.date && filters.date[1]
        ? `${dateToString(filters.date[1])?.substring(0, 10)} 23:59:59`
        : "",
  });
  if (response.success) {
    dataSource.value = response.data;
  }
  loading.value = false;
};
/**
 * Setea loading como verdadero por un periodo de 500 ms
 */
const setLoading = () => {
  loading.value = true;
  setTimeout(() => (loading.value = false), 500);
};
/**
 * Actualiza una descarga
 */
const updateDownload = ({
  proccessId,
  state,
  is_file_available,
}: {
  proccessId: number;
  state: number;
  is_file_available: boolean;
}) => {
  setLoading();
  const proccess = dataSource.value?.results.find((proccess) => proccess.id === proccessId);
  if (proccess) {
    proccess.state = state;
    proccess.is_file_available = is_file_available;
  }
};
/**
 * Elimina una descarga
 */
const deleteDownload = ({ proccessId }: { proccessId: number }) => {
  setLoading();
  if (dataSource.value?.results)
    dataSource.value.results = dataSource.value?.results.filter(
      (proccess) => proccess.id !== proccessId
    );
};
/**
 * Trae data de la siguiente pagina
 */
const nextPage = async () => {
  if (!nextUrl.value) return;
  const response = await fetchDownloads({
    next_url: nextUrl.value || "",
  });
  if (response.data && dataSource.value) {
    const results = [...dataSource.value.results, ...response.data.results];
    dataSource.value = {
      count: response.data.count,
      results,
      next: response.data.next,
    };
  }
};

provide("functionsDownload", {
  onUpdate: updateDownload,
  onDelete: deleteDownload,
  onNext: nextPage,
});

// SOCKETS
const { vueSocket } = useSocket();

vueSocket.subscribe("refreshProcesses", (data) => {
  updateDownload({
    proccessId: data.process_id,
    state: data.process_state,
    is_file_available: data.is_file_available,
  });
});
</script>

<style lang="sass" scoped>
.section
  max-width: 71em
  overflow: hidden
  &__description
    display: block
.filters
  margin: 8px 0px 12px 0px
  &__range
    width: 225px
  &__search
    width: 265px
</style>
