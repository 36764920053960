<template>
  <a-modal
    :open="open"
    centered
    :title="!fromTypification ? 'Transferir a un usuario' : 'Tipificar y transferir a un usuario'"
    @cancel="handleClose"
  >
    <div class="content">
      <h4 v-if="fromTypification">Paso 2: Transferir a un usuario</h4>
      <p>
        Elige el usuario al que deseas transferir el ticket. Se listarán todos aquellos que
        pertenezcan a la misma {{ isOutbound ? "campaña" : "cola" }} del ticket.
      </p>
      <a-input-search v-model:value="search" placeholder="Buscar por nombre" style="width: 100%" />

      <div class="list">
        <span class="info" v-if="!filteredUsers.length && !isLoading">No se encontraron usuarios</span>

        <a-radio-group v-model:value="userId">
          <a-radio v-for="user in filteredUsers" :key="user.id" :value="user.id">
            <div class="user-content">
              <UserAvatar :record="{ user }" />
              <strong class="name">{{ user.fullname }}</strong>

              <EventLabel :event-management="user.event_management" />
            </div>
          </a-radio>
        </a-radio-group>

        <span class="info"><LoadingOutlined v-if="isLoading" /></span>
      </div>
    </div>

    <template #footer>
      <footer class="footer">
        <a-button v-if="fromTypification" key="back" style="width: 40%" @click="handleBack">
          Volver
        </a-button>
        <a-button
          type="primary"
          :disabled="!userId"
          :style="!fromTypification ? 'width: 80%' : 'width: 40%'"
          @click="handleTransfer"
        >
          Transferir
        </a-button>
      </footer>
    </template>
  </a-modal>
</template>

<script setup>
import { computed, h, ref, watch, createVNode } from "vue";
import { useStore } from "vuex";
import { message } from "ant-design-vue";
import { useRoute } from "vue-router";
import { Modal } from "ant-design-vue";

import { QuestionCircleOutlined } from "@ant-design/icons-vue";

import UserAvatar from "@/app/shared/components/avatar/UserAvatar.vue";
import EventLabel from "@/app/shared/components/labels/EventLabel.vue";

import { EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";

import { LoadingOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  open: { type: Boolean, default: false },
  queue: { type: String, default: "" },
  users: { type: Array },
  ticketId: { type: Number },
  ticketIdList: { type: Array, default: () => [] },
  fromTypification: { type: Boolean, default: false },
  defaultSelected: { type: Number, default: null },
  origin: { type: Number, default: 1 }, // 1: llamadas o workspace | 2: gestor de tickets
  isAsync: { type: Boolean, default: false },
  isLoading: { type: Boolean, default: false },
});

const emit = defineEmits(["update:open", "update", "handleBack", "transferCall"]);

const store = useStore();
const route = useRoute();

const search = ref("");
const userId = ref();

const filteredUsers = computed(() =>
  props.users ? props.users?.filter((user) => user.fullname?.toLowerCase().includes(search.value.toLowerCase())) : []
);

const handleClose = () => emit("update:open", false);

const isOutbound = computed(() => props.queue.includes("Outbound"));

const globalState = computed(() => store.getters["getStateUser"]);
const dataUserState = computed(() => store.getters["getDataUserState"]);

const registerEvent = (event) => {
  const ticket = route.query.ticket;
  registerEventManagement(event, {
    open_ticket: ticket,
    menu: 23,
    state: dataUserState.value,
    interaction_id: event == EventManagement.Writing ? route.query.interaction : undefined,
  });
  store.dispatch("setEventMessaging", event);
};

const handleBack = () => {
  emit("update:open", false);
  emit("handleBack", { key: "users", idSelected: userId.value });
};

const handleTransfer = async () => {
  Modal.confirm({
    title: props.fromTypification
      ? `¿Estás seguro(a) de transferir el ticket y guardar la tipificación de este cliente?`
      : `¿Estás seguro(a) de transferir el ticket?`,
    content: props.fromTypification
      ? "Recuerda que luego podras hacer click en resolver el ticket para poder cerrarlo."
      : "",
    centered: true,
    width: 416,
    icon: createVNode(QuestionCircleOutlined),
    onOk: async () => {
      try {
        const { total_tickets, tickets_transfered, messageFetch } = await store.dispatch(
          "transferTicketToAgent",
          {
            ticketIdList: props.ticketId ? [props.ticketId] : props.ticketIdList,
            agentId: userId.value,
            origin: props.origin,
            isAsync: props.isAsync,
          }
        );

        emit("update", "users");

        if (!messageFetch && total_tickets && tickets_transfered) {
          const successMessage =
            total_tickets === 1
              ? "Se realizó la transferencia correctamente"
              : h("span", [
                  "Se han transferido ",
                  h("strong", `${tickets_transfered} de ${total_tickets}`),
                  ` tickets con éxito. ${
                    total_tickets - tickets_transfered
                  } tickets cuentan con llamada activa. `,
                ]);

          if (tickets_transfered >= 1) {
            message.success(successMessage);
            emit("transferCall", { itemId: userId.value, type: "agent" });
          } else
            message.error(
              h("span", [
                "No se ha transferido ",
                h("strong", "ningún"),
                " ticket con éxito. Todos los tickets cuentan con llamada activa. ",
              ])
            );
        }

        if (route.path == "/workspace" && route.query.ticket) registerEvent(globalState.value);

        handleClose();
      } catch (error) {
        if (error?.status === 400)
          if (error?.data?.user)
            message.error(error?.data?.user);
          else
            message.error(
              h("span", [
                "No se ha transferido este ticket ",
                h("strong", "porque cuenta con una llamada activa."),
              ])
            );
      }
    },
    okText: "Transferir",
    cancelText: "Cancelar",
  });
};

watch(
  () => props.open,
  () => {
    console.log(props.defaultSelected, "default selected");
    // userId.value = value;
  }
);
</script>

<style scoped lang="sass">
.footer
  width: 100%
  text-align: center

.list
  margin-top: 16px
  overflow-y: auto
  max-height: 500px

  .info
    display: block
    width: 100%
    text-align: center
    color: $gray-7

.content
  margin: 10px 15px

.user-content
  display: grid
  grid-template-columns: 40px 1fr 120px
  align-items: center
  gap: 8px

  .name
    color: $text-primary
    text-overflow: ellipsis
    white-space: nowrap
    overflow: hidden

:deep(.ant-radio-group)
  width: 100%
  margin-bottom: 12px

:deep(.ant-radio-wrapper)
  border-radius: 10px
  border: 1px solid rgba(0, 0, 0, 0.15)
  padding: 12px
  display: flex
  align-items: center
  margin: 0
  margin-bottom: 12px
  flex-direction: row-reverse
  justify-content: space-between

:deep(.ant-radio-wrapper::after)
  display: none

:deep(.ant-radio-wrapper span:nth-child(2))
  margin-right: 20px
  flex: auto
</style>
