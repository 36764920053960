import {
  SET_REMINDER,
  ADD_REMINDER,
  REMOVE_REMINDER,
  UPDATE_REMINDER,
  SET_CHECK_NOTIFICATION_INTERVAL,
} from "./mutation-types";

const mutations = {
  [SET_REMINDER](state, reminders) {
    state.reminders = reminders;
  },
  [ADD_REMINDER](state, reminder) {
    state.reminders.unshift(reminder);
  },
  [REMOVE_REMINDER](state, reminderId) {
    const index = state.reminders.findIndex((item) => reminderId == item.id);
    state.reminders.splice(index, 1);
  },
  [UPDATE_REMINDER](state, newReminder) {
    const index = state.reminders.findIndex((item) => newReminder.id == item.id);
    state.reminders.splice(index, 1, newReminder);
  },
  [SET_CHECK_NOTIFICATION_INTERVAL](state, intervalId) {
    state.checkNotificationInterval = intervalId;
  },
};

export default mutations;
