import { createQueue, updateQueue, fetchAgentsQueue } from "@/app/queues/services";
import { RESET_STATE, SET_PROPERTIES, SET_AGENTS_QUEUE } from "./mutation-types";
import { uploadAudioFileQueue } from "@/app/queues/services/requests/uploadAudioFileQueue";

const actions = {
  async createNewQueue({ state }) {
    return await createQueue(state);
  },
  async updateQueue({ state }, queueId) {
    await updateQueue(queueId, state);
  },
  async uploadAudioFileQueue({ state }, { id: queueId, create = false }) {
    return await uploadAudioFileQueue(queueId, state, create);
  },
  setQueueProperties({ commit }, properties) {
    commit(SET_PROPERTIES, properties);
  },
  resetQueueState({ commit }) {
    commit(RESET_STATE);
  },
  async fetchAgentsQueue({ commit }, idPortfolio) {
    const agentsQueue = await fetchAgentsQueue(idPortfolio);
    commit(SET_AGENTS_QUEUE, agentsQueue);
  },
};

export default actions;
