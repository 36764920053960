export interface CardDownloadItem {
  state: DownloadStatusNumber;
  created_at: string;
  id: string | number; // id (numero)
  extension: string;
  name: string;
  number_registers?: number;
  is_file_available?: boolean;
  updated_at?: string;
  menu: {
    name: string;
    scope: string;
  };
}

export enum DownloadStatus {
  Pending = "pending",
  Processing = "processing",
  Finished = "finished",
  Failed = "failed",
  Canceled = "canceled",
}

// texto o numero
export enum DownloadStatusNumber {
  Pending = 1,
  Processing = 2,
  Finished = 3,
  Failed = 4,
  Canceled = 5,
}

export type ActionType = "download" | "delete" | "cancel";

export interface Status {
  actions: ActionType[];
  tag: {
    title: string;
    color: string;
    icon?: string;
  };
  bg_color?: string;
  bg_color_new?: string;
  id: number;
  status: string;
  tooltip: string;
  tooltip_disabled?: string;
  is_canceled?: boolean;
}

export interface ResponseFetchDownloads {
  success: boolean;
  data?: ResponseDownload;
  message?: string;
}

export interface ResponseDownload {
  count: number;
  next: string;
  results: CardDownloadItem[];
}

export interface FunctionsDownload {
  onUpdate: ({ proccessId, state }: { proccessId: string | number; state: number }) => void;
  onDelete: ({ proccessId }: { proccessId: string | number }) => void;
  onNext: () => void;
}
