export interface DialRule {
  name: string;
  prefix_provider?: string;
  prefix_dial?: string;
  rule: string;
  destination_price?: number;
  price?: string | number;
}

export interface FormStateRule {
  rules: DialRule[];
}

export interface FormStateDialPlan extends FormStateRule {
  name: string;
  trunk: string;
  country: string;
  ringing_time: number;
  status?: number;
}

export interface Dialplan {
  id: number;
  name: string;
}

export enum TabKeyDialPlan {
  General = "form_general",
  Rules = "form_rules",
}

export enum Trunk {
  Securitec = 1,
}
