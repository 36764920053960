<template>
  <div>
    <h3>Buscar otro contacto</h3>

    <p class="information">Buscar por nombres, apellidos, teléfono o Nº de documento.</p>
    <div class="search">
      <a-input v-model:value="search" placeholder="Escribe aquí..." @change="handleSearchClient" />
      <a-button class="button--search" shape="circle" @click="handleSearchClient">
        <template #icon><SearchOutlined /></template>
      </a-button>
    </div>
    <!-- <p v-if="hasClients">Resultados de búsqueda para "{{ searchTextRequested }}"</p> -->
    <p v-if="hasNotResults && searchTextRequested.length > 0">
      No se encontraron resultados de búsqueda para "{{ searchTextRequested }}" puedes
      <a @click.prevent="handleCreateNewUser">Crear cliente nuevo</a>
    </p>
    <a-table
      v-if="clients.length > 0"
      size="middle"
      :columns="columnsTable"
      :data-source="clients"
      :scroll="{ x: 1400, y: 300 }"
      :pagination="false"
    >
      <template #manage="{ record }">
        <a v-if="record.client_status == 1" @click="handleSelect(record, false)">Seleccionar</a>
        <a v-else @click="handleSelect(record, true)">Activar</a>
      </template>
      <template #phones="{ text }">
        <a>
          <a-popover v-if="text.length > 0">
            <template #title>
              <span
                >{{ text.length }}
                {{ getPhoneText(text) }}
              </span>
            </template>
            <template #content>
              <ul class="list">
                <li v-for="phone in text" :key="phone">{{ phone }}</li>
              </ul>
            </template>
            {{ text.length }}
            {{ getPhoneText(text) }}
          </a-popover>
          <span v-if="text.length === 0">0 teléfonos</span>
        </a>
      </template>
    </a-table>
  </div>
</template>

<script>
import { columns } from "@/app/workspace/components/tables/Client/ClientTableColumn";
import { SearchOutlined } from "@ant-design/icons-vue";
import { defineComponent, createVNode, computed, inject } from "vue";
import _ from "lodash";

import { CallStatus } from "@/packages/vue-jssip/jssip/phone";

export default defineComponent({
  components: {
    SearchOutlined,
  },
  props: {
    ticket: {
      type: Object,
      required: false,
      default: null,
    },
  },
  setup(props, { emit }) {
    const handleSelect = (record, activate) => {
      emit("client-select", { record, activate });
    };

    return {
      handleSelect,
    };
  },
  data() {
    return {
      columns,
      search: "",
      isFirstTime: true,
      searchTextRequested: "",
      key: 0,
    };
  },
  computed: {
    clientName() {
      return this.ticket.client.name;
    },
    clients() {
      return this.$store.getters["getClientsFound"];
    },
    hasClients() {
      return this.clients.length > 0;
    },
    hasNotResults() {
      return this.clients.length === 0 && !this.isFirstTime;
    },
    columnsTable() {
      // const column = columnsFixedTableLeft(this.columns);
      // return column;
      return this.columns;
    },
    ticketId() {
      return this?.ticket?.id;
    },
    isInACall() {
      return this.$jssip.phone.status == CallStatus.InCall;
    },
  },
  watch: {
    ticketId() {
      this.$store.dispatch("cleanResultsClient");
      this.search = "";
      this.searchTextRequested = "";
    },
  },
  methods: {
    getPhoneText(text) {
      return text.length > 1 || text.length === 0 ? "teléfonos" : "teléfono";
    },
    handleSearchClient: _.debounce(async function () {
      if (this.search.trim().length === 0) {
        this.$store.dispatch("cleanResultsClient");
        this.searchTextRequested = "";
      } else {
        const initialSearchText = this.search;
        await this.$store.dispatch("searchClients", this.search);
        this.isFirstTime = false;
        this.searchTextRequested = initialSearchText;
      }
    }, 600),
    handleCreateNewUser() {
      this.$emit("onClick");
    },
  },
});
</script>

<style lang="sass" scoped>
h3
  margin: 0
  font-size: 16px

.paragraph
  margin: 0 0 8px 0
  color: $gray-7
  font-weight: 400

.information
  margin-top: 15px
  margin-bottom: 0
  font-weight: 400
  color: $gray-9

.search
  width: 85%
  display: flex
  align-items: center
  cursor: pointer
  margin-bottom: 10px

.button--search
  font-size: 20px
  margin: 8px
  height: 38px
  width: 40px
  margin-left: 10px
  line-height: 1

:deep(.ant-btn:active)
  color: $gray-8
  border-color: $gray-5

:deep(.ant-btn:hover)
  color: $gray-8
  border-color: $gray-5

.list
  padding-left: 28px
</style>
