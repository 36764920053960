import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener mensajes de un ticket.
 * @param {number} ticketId Identificador de ticket.
 * @return {Promise<Object>} Detalle del ticket con sus mensajes
 */
export async function fetchMessagesByTicket(ticketId) {
  const url = `/tickets/${ticketId}/messages/`;
  const { data } = await httpManager.get(url);
  return {
    ticket: data.ticket,
    history: [data],
  };
}
