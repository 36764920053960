import httpManager from "@/app/shared/utils/httpManager";
/**
 * Confirmación de la contraseña.
 *
 * @return {Promise<object>} Data del usuario logueado.
 *
 * @param {Object} data Información del token y la contraseña.
 */
export async function confirmPassword(data) {
  const body = data;
  const response = await httpManager.post("/users/password/confirm/", body);
  return response.data;
}
