import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una entidad.
 *
 * @param {number} trunkId Iedntificador de la entidad.
 * @param {FormData} entity Datos modificados de la entidad.
 *
 * @return {Promise<Object>} Entidad editada.
 */
export default async function (trunkId, trunk) {
  const response = await httpManager.patch(`/pbx/trunks/${trunkId}/`, trunk);
  return response?.data;
}
