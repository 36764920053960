import {
  createNewProfile,
  eliminateProfile,
  fetchAllProfiles,
  fetchProfileById,
  updateProfile,
} from "@/app/users/services";

import { SET_PROFILES, SET_PROFILE } from "./mutation-types";

const actions = {
  async fetchAllProfiles({ commit }, params = {}) {
    const { search } = params;
    const profiles = await fetchAllProfiles(search);
    commit(SET_PROFILES, profiles);
  },
  async fetchProfileById({ commit }, id) {
    const profile = await fetchProfileById(id);
    commit(SET_PROFILE, profile);
  },
  async createNewProfile(_, newProfile) {
    await createNewProfile(newProfile);
  },
  async updateProfile(_, { id, params }) {
    await updateProfile(id, params);
  },
  async deleteProfile(_, { id, data }) {
    await eliminateProfile(id, data);
  },
};

export default actions;
