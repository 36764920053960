<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M10.4788 4.86461C10.2206 4.60358 9.80002 4.60028 9.53782 4.85728L4.61958 9.71815L2.48158 7.50479C2.22658 7.23901 1.80442 7.23029 1.53868 7.48529C1.2729 7.74029 1.26418 8.16245 1.51918 8.42819C1.52034 8.42938 1.52146 8.43056 1.52262 8.43175L4.12946 11.1333C4.25421 11.2605 4.42472 11.3326 4.60295 11.3333H4.61028C4.78512 11.3328 4.95278 11.2636 5.0771 11.1406L10.4728 5.80558C10.7335 5.54706 10.7361 5.12646 10.4788 4.86461V4.86461Z"
      fill="#00000040"
    />
    <path
      d="M8.13066 11.1333C8.2551 11.2602 8.42506 11.3322 8.60282 11.3333H8.61016C8.785 11.3328 8.95265 11.2636 9.07697 11.1406L14.4727 5.80559C14.7173 5.5302 14.6923 5.1087 14.4169 4.86413C14.1673 4.64241 13.7921 4.63949 13.5391 4.85729L8.62281 9.71816L8.44742 9.5361C8.19245 9.27032 7.77029 9.26157 7.50451 9.51657C7.23874 9.77154 7.22999 10.1937 7.48499 10.4595C7.48614 10.4607 7.4873 10.4619 7.48845 10.4631L8.13066 11.1333Z"
      fill="#00000040"
    />
  </svg>
</template>
