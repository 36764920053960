export const SET_STRATEGIES = "SET_STRATEGIES";
export const ADD_STRATEGY = "ADD_STRATEGY";
export const SET_STRATEGY = "SET_STRATEGY";
export const REMOVE_STRATEGY = "REMOVE_STRATEGY";
export const MOVE_STRATEGY = "MOVE_STRATEGY";
export const SET_TEMPLATES_WHATSAPP = "SET_TEMPLATES_WHATSAPP";
export const SET_AGENTS_STRATEGY = "SET_AGENTS_STRATEGY";
export const SET_TIME_STRATEGY = "SET_TIME_STRATEGY";
export const SELECT_ALL_ACTIVE_USERS = "SELECT_ALL_ACTIVE_USERS";
export const SET_ALL_USERS = "SET_ALL_USERS";
export const SELECT_ACTIVE_USERS_BY_PROFILE = "SELECT_ACTIVE_USERS_BY_PROFILE";
export const SET_SELECTED_ACTIVE_USERS_STRATEGY = "SET_SELECTED_ACTIVE_USERS_STRATEGY";
export const SELECT_SINGLE_ACTIVE_USER = "SELECT_SINGLE_ACTIVE_USER";
export const RESET_NEW_SELECTED_USERS = "RESET_NEW_SELECTED_USERS";
