export enum SmsTemplateStatus {
  Active = 1,
  Inactive = 2,
}

export interface SmsTemplate {
  id: number;
  name: string;
  status: SmsTemplateStatus;
  queues: { id: number; name: string; portfolios: { id: number; name: string }[] }[];
  parameters: { name: string }[];
  template: string;
}
