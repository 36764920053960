<template>
  <div class="button">
    <a-button type="primary" ghost block @click="handleShowModal"> Agregar más agentes </a-button>

    <a-modal
      v-model:visible="visible"
      :body-style="{ padding: '24px 0px' }"
      title="Agregar más agentes a la cola"
      width="660px"
      centered
      @cancel="handleClose"
    >
      <section class="container">
        <div class="section--agents">
          <p class="title-agents">
            Se listarán solo aquellos agentes activos de la campaña que no están actualmente en esta
            cola.
          </p>
          <div class="box">
            <div class="container__search">
              <!-- <a-checkbox
                :checked="selectAllCheckbox"
                @change="handleSelectAllAgents"
                class="checkbox__select"
              >
                Seleccionar todos
              </a-checkbox> -->
              <a-input
                v-model:value="search"
                placeholder="Busca los usuarios aquí"
                class="search__agents"
              >
                <template #suffix>
                  <SearchOutlined />
                </template>
              </a-input>
            </div>

            <div id="style-scroll-agente" class="scrollbar-agent">
              <div class="container-items-agent">
                <div v-for="agent in listAgent" :key="agent.profile">
                  <span v-if="agent.agents.length > 0">{{ agent.profile }}</span>
                  <!-- <div v-if="agent.agents.length > 0" class="line-gray" /> -->

                  <div
                    v-for="subAgent in orderedAgentsByProfile(agent.profile)"
                    :key="subAgent.id"
                    class="item__agent"
                  >
                    <div class="subitem__agent">
                      <div v-if="subAgent?.picture">
                        <img class="user__icon" :src="subAgent?.picture" alt="perfil" />
                      </div>
                      <div
                        v-else
                        :class="subAgent.role.name === 'Agente' ? 'background__agent' : ''"
                        class="background__profile"
                      >
                        <img
                          v-if="subAgent.icon"
                          class="user__profile"
                          :class="subAgent.role.name === 'Agente' ? 'user__agente' : ''"
                          :src="getProfileImage(subAgent?.icon || iconDefault)"
                          alt="perfil"
                        />
                      </div>
                      <div>
                        <a-tooltip placement="top">
                          <template #title v-if="subAgent.fullname?.length > 21">
                            {{ subAgent.fullname }}
                          </template>
                          <p class="text__name">
                            {{ subAgent.fullname.toLowerCase() }}
                          </p>
                        </a-tooltip>
                      </div>
                    </div>
                    <div class="agent__event">
                      <StateCell :record="subAgent" :detailedView="false" :time="false" />
                    </div>
                    <div class="agent__event">
                      <a-badge :color="eventColor(subAgent.event_management)" />
                      <span> {{ eventText(subAgent.event_management) }}</span>
                    </div>
                    <a-checkbox
                      class="flex"
                      :checked="isAgent(subAgent)"
                      :disabled="disabledChecked(subAgent.event_management)"
                      @change="handleSelectedAgentItems($event, subAgent)"
                    >
                    </a-checkbox>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <!--Fotter Modal -->
      <template v-slot:footer>
        <a-divider class="divider" type="horizontal" />
        <div class="footer__container">
          <div class="footer__left">
            <div>
              <a-button type="primary" ghost @click="handleUpdateUsers">Actualizar datos</a-button>

              <p class="footer__button">Actualizando datos en {{ countToUpdate }} segundos</p>
            </div>
          </div>
          <div class="footer__right">
            <a-button key="submit" type="primary" :disabled="disabled" @click="handleOk">
              Agregar a cola
            </a-button>
          </div>
        </div>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, createVNode, onMounted, ref } from "vue";
import { Modal } from "ant-design-vue";
import { SearchOutlined, QuestionCircleOutlined } from "@ant-design/icons-vue";
import { addAgentsToQueue } from "@/app/supervision/services";
// import UserAvatar from "@/app/shared/components/avatar/UserAvatar.vue";

import { EventData } from "@/app/shared/utils/globalVariables";
import { EventManagement, StateOptions } from "@/app/shared/utils/enums";
import StateCell from "@/app/supervision/components/tables/cells/StateCell.vue";

const TIME_TO_UPDATE = 5;
export default defineComponent({
  components: {
    SearchOutlined,
    StateCell,
  },
  props: {
    queue: { required: true, type: Number },
    portfolio: { required: true, type: Number },
    currentAgents: { required: true, type: Array },
  },
  setup() {
    let updateIntervalId = null;
    const countToUpdate = ref(TIME_TO_UPDATE);

    onMounted(() => {
      updateIntervalId = setInterval(() => {
        countToUpdate.value = countToUpdate.value - 1;

        if (countToUpdate.value === 0) countToUpdate.value = TIME_TO_UPDATE;
      }, 1000);
    });

    return {
      countToUpdate,
      updateIntervalId,
    };
  },
  data() {
    return {
      selectedAgents: [],
      visible: false,
      search: "",
      orderedAgents: [],
    };
  },
  computed: {
    disabled() {
      return !this.selectedAgents || this.selectedAgents.length == 0;
    },
    agents() {
      // let agents = this.$store.getters["getAgentPortfolio"];
      let agents = this.orderedAgents;
      const currentAgentsIds = this.currentAgents.map((item) => item.id);
      if (this.search != "") {
        agents = agents.filter((agent) => {
          return (
            agent.fullname.toLowerCase().includes(this.search.toLowerCase()) &&
            !currentAgentsIds.includes(agent.id)
          );
        });
      } else {
        agents = agents.filter((agent) => {
          return !currentAgentsIds.includes(agent.id);
        });
      }
      return agents;
    },
    listAgent() {
      const arr = [];
      this.profiles.forEach((profile) => {
        const agents = this.agents.filter((agent) => agent.profile_name === profile.name);
        arr.push({ profile: profile.name, agents });
      });

      return arr;
    },
    selectAllCheckbox() {
      return this.selectedAgents.length === this.agents.length;
    },
    profiles() {
      const listProfiles = this.$store.getters["getProfileList"];
      if (!listProfiles.some((profile) => profile.name === "Owner")) {
        listProfiles.push({ name: "Owner", total_users: 1 });
      }
      return listProfiles.filter((item) => item.total_users > 0);
    },
    iconDefault() {
      return "smile.svg";
    },
    portfolioUpload() {
      return this.$store.getters["getQueueState"].portfolios;
    },
  },
  methods: {
    orderedSubAgents(agents) {
      const onlySelectedAgents = agents
        .filter((subAgent) => this.selectedAgents.includes(subAgent.id))
        .sort((a, b) => a.fullname.localeCompare(b.fullname));

      const subAgentsWithState1 = agents
        .filter((subAgent) => subAgent.state === 1 && !this.selectedAgents.includes(subAgent.id))
        .sort((a, b) => a.fullname.localeCompare(b.fullname));

      const subAgentsWithoutState2 = agents
        .filter((subAgent) => subAgent.state !== 1 && !this.selectedAgents.includes(subAgent.id))
        .sort((a, b) => a.fullname.localeCompare(b.fullname));
      return [...onlySelectedAgents, ...subAgentsWithState1, ...subAgentsWithoutState2];
    },
    orderedAgentsByProfile(profileName) {
      return this.agents.filter((el) => el.profile_name == profileName);
    },
    getProfileImage(profile) {
      return new URL(`../../../users/assets/profiles/${profile}`, import.meta.url).href;
    },
    async fetchAgentsByCampaign(id) {
      await this.$store.dispatch("fetchAgentPortfolio", id);
    },
    async handleShowModal() {
      this.selectedAgents = [];
      await this.fetchAgentsByCampaign(this.portfolio);
      this.orderedAgents = this.orderedSubAgents(this.$store.getters["getAgentPortfolio"]);
      this.visible = true;
    },
    handleClose() {
      this.visible = false;
      clearInterval(this.interval);
    },
    isProfile(agent) {
      let checkProfile = false;
      agent.agents.forEach((agnt) => {
        if (this.selectedAgents.includes(agnt.id)) checkProfile = true;
      });
      return checkProfile;
    },
    isAgent(agent) {
      return this.selectedAgents.includes(agent?.id);
    },
    handleSelectedAgentItems(e, agent) {
      const checked = e.target.checked;
      if (checked) {
        this.selectedAgents.push(agent.id);
      } else {
        const index = this.selectedAgents.findIndex((item) => {
          return item == agent.id;
        });
        this.selectedAgents.splice(index, 1);
      }
    },
    handleSelectAllAgents(event) {
      if (event.target.checked) {
        this.listAgent.forEach((item) => {
          item.agents.map((agent) => this.selectedAgents.push(agent.id));
        });
      } else {
        this.selectedAgents = [];
      }
    },
    handleOk() {
      Modal.confirm({
        title:
          this.selectedAgents.length == 1
            ? "¿Estás seguro de agregar el usuario a esta cola?"
            : "¿Estás seguro de agregar usuarios a esta cola?",
        content: `Recuerda que estos nuevos usuarios agregados comenzarán a recibir los tickets que lleguen a esta cola.`,
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.addAgents(),
        centered: true,
      });
    },
    async addAgents() {
      await addAgentsToQueue(this.queue, this.selectedAgents);
      this.$emit("refresh");
      this.visible = false;
    },
    handleProfileSelected(e, agent) {
      const checked = e.target.checked;
      if (checked) {
        agent.agents.map((agnt) => {
          this.selectedAgents.push(agnt.id);
        });
      } else {
        agent.agents.map((agnt) => {
          const index = this.selectedAgents.findIndex((item) => {
            return item == agnt.id;
          });
          this.selectedAgents.splice(index, 1);
        });
      }
    },
    statusColor(id) {
      if (id === StateOptions.NotAvailable) return "#13C2C2";

      return EventData[id].color;
    },
    statusText(id) {
      if (id === StateOptions.NotAvailable) return "No disponible";

      return id === EventManagement.Online ? "Disponible" : EventData[id].name;
    },
    eventColor(id) {
      return id ? EventData?.[id]?.color : "#00000026";
    },
    eventText(id) {
      return id ? EventData?.[id]?.name : "Sin evento activo";
    },
    disabledChecked(eventId) {
      return (
        eventId === EventManagement.Talking ||
        eventId === EventManagement.Acw ||
        eventId === EventManagement.Ringing
      );
    },
    async handleUpdateUsers() {
      await this.fetchAgentsByCampaign(this.portfolio);
      this.countToUpdate = TIME_TO_UPDATE;
    },
  },
  watch: {
    async countToUpdate(newCounterValue) {
      if (newCounterValue === 1) await this.fetchAgentsByCampaign(this.portfolio);
    },
  },
});
</script>

<style lang="sass" scoped>
.container
  display: flex
  box-sizing: border-box
  border-radius: 0
  justify-content: space-evenly
  .box
    background: $white
    box-sizing: border-box
    border-radius: 2px
    display: flex
    flex-direction: column
    height: 420px
  .container__information
    margin: 0 auto
    text-align: center
    margin-top: 80px
  .infoUser
    margin-top: 40px
    color: $gray-6

.container__search
  display: flex
  flex-direction: column
  margin-bottom: 10px

.item__agent
  display: flex
  justify-content: space-between
  border-radius: 10px
  align-items: center
  /*margin-left: 20px */
  margin-bottom: 10px
  padding: 5px 10px
  border: 1px solid $gray-4

.subitem__agent
  display: flex
  align-items: center

.title-agents
  color: $gray-8
  text-align: justify

.selection-agents
  background: $blue-1
  border: 1px solid $blue-3
  box-sizing: border-box
  border-radius: 3px
  height: 35px
  display: flex
  padding: 1px 8px 1px
  margin: 2px 0px
  justify-content: space-between
  margin-left: 20px
  margin-top: 10px
  align-items: center

input[type="checkbox"]
  width: 16px
  height: 16px
  margin-right: 8px
input:checked
  background-color: $blue-6
  &:hover
    background-color: $blue-6

.scrollbar-agent
  float: left
  overflow-y: scroll
  padding-right: 0px
  width: 101%
.label-agent
  padding-left: 5px

#style-scroll-agente::-webkit-scrollbar-track
  border-radius: 10px
  background-color: $white
#style-scroll-agente::-webkit-scrollbar
  width: 5px
  background-color: $white
#style-scroll-agente::-webkit-scrollbar-thumb
  border-radius: 10px
  background-color: #C4C4C4

.section--agents
  margin-right: 12px
  width: 80%

.icon--close
  color: $gray-8
  padding-left: 10px

.section__selected
  width: 49%

.icon--close
  color: $gray-8
  padding-left: 10px

.checkbox__select
  border-bottom: 1px solid #E8E8E8
  margin-bottom: 10px
  padding: 8px 12px

.search__agents
  // margin-left: 7px
  width: 100%
.container-items-agent
  padding: 0 0px
  span:first-child
    font-size: 14px
    font-weight: 600
    margin-bottom: 10px
.line-gray
  border-bottom: 1px solid #E8E8E8

.image-without-modules
  display: block
  margin: 15% 33%
.label
  font-weight: 600
  font-size: $font-normal
  line-height: 25px
  color: $gray-9
  padding-bottom: 10px
.style-container
  padding: 10px 8px
.container__image
  margin-right: 10px

.user__icon
  width: 22px
  height: 22px
  margin: 6px
  border-radius: 50%

.user__profile
  width: 17px
  height: 17px
  margin: 6px
  filter: invert(62%) sepia(84%) saturate(1521%) hue-rotate(178deg) brightness(103%) contrast(99%)

.user__agente
  width: 17px
  height: 17px
  margin: 6px
  filter: invert(55%) sepia(46%) saturate(1606%) hue-rotate(351deg) brightness(105%) contrast(96%)

.background__profile
  background-color: rgb(230, 247, 255)
  border-radius: 50%
  display: flex
  align-items: center
  margin-right: 5px

.background__agent
  background: #FFF6E2
  border-radius: 50%
  display: flex
  align-items: center
  margin: 0 auto

.image-record
  width: 30px
  height: 30px
  border-radius: 50%

.text__left
  margin-top: 86px
  text-align: center

.hideText
  display: none

.flex
  display: flex
  align-items: center

.text__name
  overflow: hidden
  white-space: nowrap
  text-overflow: ellipsis
  margin-right: 4px
  width: 150px
  margin-bottom: 0
  font-size: 14px
  font-weight: 600

.checkbox-content
  margin-left: 8px

.agent__event
  width: 120px
  display: flex
  align-items: center
  span
    margin-bottom: 0 !important

.footer__container
  display: flex
  justify-content: space-between
  align-items: center

.footer__left
  gap: 10px
  div
    display: flex
    align-items: center
    p
      margin-bottom: 0
      margin-left: 1em

.footer__right
  display: flex
  align-items: center
  gap: 10px

.divider
  background-color: $gray-7
</style>
