const initialState = () => {
  const data_user = null;
  const stateUser = 6;
  const minutes_expire = 0;
  const dataUserState = 1;
  return { data_user, stateUser, minutes_expire, dataUserState };
};

const state = initialState();

export default state;
