export default [
  {
    path: "/dashboard",
    name: "dashboard",
    redirect: "/reports/dashboard",
    // component: DashboardPage,
    // meta: {
    //   requiresAuth: true,
    // },
  },
];
