import { timeStringToSeconds, secondsToTimeString } from "@/app/shared/utils/dates";

function fixNumberToPercent(number, { decimalNumbers = 1 } = {}) {
  const percent = number * 100;

  return Number(percent.toFixed(decimalNumbers));
}

export function formatVoiceQueueTableData({ rawData = [] }) {
  const formattedData = [];

  const totalRow = {
    queue_name: "Total",
    sum_service_level: 0,
    total_answered_calls: 0,
    total_dropped_calls: 0,
    total_interactions: 0,
    total_calls_waiting: 0,
    mean_time_acw: 0,
    mean_time_speaking: 0,
    mean_time_waiting: 0,
  };

  rawData.forEach((row) => {
    const {
      sum_service_level,
      total_answered_calls,
      total_dropped_calls,
      total_calls_waiting,
      total_interactions,
    } = row;

    const rowWithExtraData = { ...row };

    rowWithExtraData.mean_answered_level = fixNumberToPercent(
      total_answered_calls / total_interactions
    );
    rowWithExtraData.mean_dropped_level = fixNumberToPercent(
      total_dropped_calls / total_interactions
    );

    formattedData.push(rowWithExtraData);

    totalRow.sum_service_level += sum_service_level;
    totalRow.total_answered_calls += total_answered_calls;
    totalRow.total_dropped_calls += total_dropped_calls;
    totalRow.total_calls_waiting += total_calls_waiting;
    totalRow.total_interactions += total_interactions;
    totalRow.mean_time_acw += timeStringToSeconds(row.mean_time_acw);
    totalRow.mean_time_speaking += timeStringToSeconds(row.mean_time_speaking);
    totalRow.mean_time_waiting += timeStringToSeconds(row.mean_time_waiting);
  });

  totalRow.mean_service_level = fixNumberToPercent(
    totalRow.sum_service_level / totalRow.total_interactions
  );
  totalRow.mean_answered_level = fixNumberToPercent(
    totalRow.total_answered_calls / totalRow.total_interactions
  );
  totalRow.mean_dropped_level = fixNumberToPercent(
    totalRow.total_dropped_calls / totalRow.total_interactions
  );

  totalRow.mean_time_acw = secondsToTimeString(totalRow.mean_time_acw);
  totalRow.mean_time_speaking = secondsToTimeString(totalRow.mean_time_speaking);
  totalRow.mean_time_waiting = secondsToTimeString(totalRow.mean_time_waiting);

  formattedData.push(totalRow);

  return formattedData;
}

export function formatChatQueueTableData({ rawData = [] }) {
  const formattedData = [];

  const totalRow = {
    queue_name: "Total",
    sum_service_level: 0,
    total_interactions: 0,
    total_assigned: 0,
    mean_time_resolution: 0,
    mean_time_first_answer: 0,
    mean_time_waiting: 0,
  };

  rawData.forEach((row) => {
    formattedData.push(row);

    totalRow.sum_service_level += row.sum_service_level;
    totalRow.total_interactions += row.total_interactions;
    totalRow.total_assigned += row.total_assigned;
    totalRow.mean_time_resolution += timeStringToSeconds(row.mean_time_resolution);
    totalRow.mean_time_first_answer += timeStringToSeconds(row.mean_time_first_answer);
    totalRow.mean_time_waiting += timeStringToSeconds(row.mean_time_waiting);
  });

  totalRow.mean_service_level = fixNumberToPercent(
    totalRow.sum_service_level / totalRow.total_interactions
  );

  totalRow.mean_time_resolution = secondsToTimeString(totalRow.mean_time_resolution);
  totalRow.mean_time_first_answer = secondsToTimeString(totalRow.mean_time_first_answer);
  totalRow.mean_time_waiting = secondsToTimeString(totalRow.mean_time_waiting);

  formattedData.push(totalRow);

  return formattedData;
}
