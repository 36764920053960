<template>
  <a-tag v-if="state === EmailInboxState.unread" class="unread">Nuevo sin leer</a-tag>
  <a-tag v-if="state === EmailInboxState.read" color="blue">Leído</a-tag>
  <a-tag v-if="state === EmailInboxState.unanswered" color="orange">Sin Respuestas</a-tag>
  <a-tag v-if="state === EmailInboxState.answered" color="warning">Respondido</a-tag>
</template>

<script>
import { EmailInboxState } from "@/app/shared/utils/enums";

export default {
  props: {
    state: {
      type: Number,
      required: true,
    },
  },
  setup() {
    return {
      EmailInboxState,
    };
  },
};
</script>

<style lang="sass" scoped>
.try
  background-color: $gray-2
  color: $gray-9
  border-color: $gray-5
  width: fit-content

.unread
  background-color: $green-1
  color: $green-6
  border-color: $green-3
  width: fit-content

.unanswered
  background-color: $cyan-1
  color: $cyan-6
  border-color: $cyan-3
  width: fit-content
</style>
