import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todas las Empresas.
 *
 * @param {string} search Texto para buscar una entidad
 * @param {Object} pagination Paginación

 * @return {Promise<Array>} Lista de Empresas.
 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export default async function (filters = {}, pagination = {}) {
  const search = filters.search || "";
  const toUsersAssigned = filters.toUsersAssigned == true ? "&to_users_assigned=true" : "";
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;
  const url = `/entities/?search=${search}&offset=${offset}&limit=${pageSize}${toUsersAssigned}`;
  const response = await httpManager.get(url);
  return response?.data;
}
