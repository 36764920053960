<template>
  <a-table
    size="middle"
    :columns="quickActionsColumns"
    :data-source="answersData?.results"
    :pagination="pagination"
    :loading="loadingTable"
    @change="handleChange"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.key === 'file'">
        <span :class="{ empty: !text }">
          {{ getFileTypeName(record.type_file) }}
        </span>
      </template>

      <template v-if="column.key === 'queues'">
        <div class="queue">
          <span>
            {{ text.length === 1 ? text.at(0)?.name : `${text.length} colas asignadas` }}
          </span>

          <a-popover v-if="text.length > 1" :title="`${text.length} colas asignadas`">
            <template #content>
              <ul>
                <li v-for="queue in text" :key="queue.id">{{ queue.name }}</li>
              </ul>
            </template>

            <InfoCircleOutlined style="margin-left: 5px" />
          </a-popover>
        </div>
      </template>

      <template v-if="column.key === 'actions'">
        <div class="actions">
          <a-button type="link" class="button" @click="handleOpen(record)">
            <EditOutlined />
          </a-button>

          <a-button type="link" class="button" @click="handleDelete(record)">
            <DeleteOutlined />
          </a-button>
        </div>
      </template>
    </template>
  </a-table>
</template>

<script setup lang="ts">
import { computed, createVNode, onMounted, ref, watch } from "vue";
import { Modal, type TableProps } from "ant-design-vue";
import {
  InfoCircleOutlined,
  EditOutlined,
  DeleteOutlined,
  QuestionCircleOutlined,
} from "@ant-design/icons-vue";

import { quickActionsColumns } from "@/app/platformSettings/utils/columns/quickActions";
import type { Pagination } from "@/@types/utils";
import type { AnswerMediaType, QuickAnswer } from "@/@types/platformSettings/quickAnswers";
import { quickAnswersService } from "../../services/QuickActionsService";
import { debounce } from "lodash";

const props = defineProps<{ search: string }>();

const emit = defineEmits<{ edit: [record: QuickAnswer] }>();

const answersData = ref<Pagination<QuickAnswer>>();
const currentPage = ref(1);

const loadingTable = ref(false);

const fetchData = async () => {
  loadingTable.value = true;

  answersData.value = await quickAnswersService.getList({
    page: currentPage.value,
    title: props.search,
  });

  loadingTable.value = false;
};

onMounted(() => fetchData());

const pagination = computed(() => ({
  total: answersData.value?.count,
  pageSize: 6,
  showSizeChanger: false,
}));

const handleChange: TableProps["onChange"] = async (page) => {
  currentPage.value = page.current || 1;
  fetchData();
};

watch(
  () => props.search,
  debounce(() => {
    currentPage.value = 1;
    fetchData();
  }, 200)
);

defineExpose({ fetchData });

const getFileTypeName = (typeName: AnswerMediaType) => {
  if (typeName === "image") return "Imagen";
  if (typeName === "video") return "Video";
  if (typeName === "file") return "Documento";

  return "Sin multimedia";
};

const handleOpen = (record: QuickAnswer) => {
  emit("edit", record);
};

const handleDelete = (record: QuickAnswer) => {
  Modal.confirm({
    centered: true,
    icon: createVNode(QuestionCircleOutlined),
    title: `¿Estás seguro de eliminar la respuesta rápida “${record.title}”?`,
    content: "Recuerda que ya no podrá ser utilizada por tus usuarios en el módulo de Workspace.",
    okText: "Eliminar",
    onOk: async () => {
      await quickAnswersService.delete(record.id);
      await fetchData();
    },
  });
};
</script>

<style scoped lang="sass">
.empty
  color: $gray-6
  font-style: italic

.button
  padding: 0
  font-size: inherit

.queues
  display: flex
  justify-content: space-between

.actions
  display: flex
  gap: 16px
  font-size: 18px
</style>
