import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener los datos para realizar la transferencia de un ticket.
 *
 * @param {number} ticketId Identificador de un ticket
 *
 * @returns {Promise} Objeto con los datos de los agentes y colas
 *
 */
export async function fetchTransferData(ticketId, origin, onlyUsers = false) {
  const body = {
    tickets: [ticketId],
    origin: origin,
  };

  if (!onlyUsers) {
    const [agentsResponse, queuesResponse] = await Promise.all([
      httpManager.post(`/tickets/agents/`, body),
      httpManager.post(`/tickets/queues/`, body),
    ]);
    return {
      agents: agentsResponse?.data,
      queues: queuesResponse?.data,
    };
  } else {
    const agentsResponse  = await httpManager.post(`/tickets/agents/`, body)
    return {
      agents: agentsResponse?.data,
      queues: [],
    };
  }  
}
