import httpManager from "@/app/shared/utils/httpManager";

/**
 * @param {Number} chatbotId
 *
 * @return {Promise<Object>}
 */

export async function fetchNodes(chatbotId) {
  const response = await httpManager.get(`/chatbots/${chatbotId}/nodes/`);

  return response?.data;
}
