<template>
  <!-- Floating button in workspace -->
  <div v-if="floating" class="button-whats" @click="openWhatsModal">
    <WhatsAppOutlined :style="{ fontSize: '21px', marginTop: '10px' }" />
  </div>

  <!-- Button in popover options -->
  <div v-else-if="popoverButton" class="popover-content">
    <a-tooltip placement="left">
      <template #title v-if="validateBlacklist">
        Este Número se encuentra bloqueado para mandar mensajes de Whatsapp
      </template>
      <a-button type="text" @click="openWhatsModal" :disabled="disabled">
        <WhatsAppOutlined />
        Enviar Whatsapp
      </a-button>
    </a-tooltip>
  </div>

  <!-- Button on contact detail table -->
  <a-tooltip v-else-if="!chatButton">
    <template #title v-if="hasTooltip"> Enviar plantilla de WhatsApp </template>
    <!-- class="button" -->
    <a-button
      :block="isBlock"
      :type="typeButton"
      :disabled="record?.type_phone == 1 || disabled"
      @click="openWhatsModal"
    >
      <WhatsAppOutlined :class="record?.type_phone == 1 ? 'icon__disabled' : 'icon'" />
      <span v-if="hasLabelButton">Enviar WhatsApp</span>
    </a-button>
  </a-tooltip>

  <!-- Send template modal -->
  <a-modal
    title="Enviar plantilla de WhatsApp"
    v-if="showModal"
    :visible="showModal"
    width="650px"
    :bodyStyle="{
      height: balance <= 0 && type_billing == TypeBilling.PrePayment ? '600px' : '540px',
    }"
    @cancel="handleBack"
    centered
  >
    <!-- <section>
      <a-steps :current="current" labelPlacement="vertical">
        <a-step v-for="item in steps" :key="item.id" :title="item.title" />
      </a-steps>
    </section> -->
    <div class="container-step">
      <!-- Step 1 -->
      <div v-if="current === 0" class="container-step__one">
        <a-form class="form" layout="vertical" ref="formRef" :model="formState2">
          <!-- For floating button -->
          <div v-if="floating">
            <h3 class="step-title">
              Ingresa el número de teléfono y elige la plantilla para enviar un mensaje.
            </h3>

            <a-alert
              v-if="balance <= 0 && type_billing == TypeBilling.PrePayment"
              message="Actualmente, no cuentas con saldo suficiente en WhatsApp para realizar envíos de plantillas"
              type="warning"
              show-icon
              class="balance-warning"
            />

            <a-form-item label="Télefono de contacto" name="phone_number">
              <a-row :gutter="24" type="flex">
                <a-col :span="7">
                  <selector-country
                    :code="code_phone"
                    @selected="code_phone = $event"
                    @change="handleChangeCountry"
                  />
                </a-col>
                <a-col :span="17">
                  <a-input
                    v-model:value="formState2.phone_number"
                    placeholder="Escribir número"
                    :maxlength="code_phone === 1 ? 9 : 100"
                    @keypress="handleNumberPhone($event)"
                    @input="handleChangeNumber"
                    :class="{ 'background-red': phoneMax }"
                  />
                  <span v-if="phoneMax" class="form__error">Ingrese un número de 9 dígitos</span>
                </a-col>
              </a-row>
            </a-form-item>
          </div>

          <!-- For contact detail table -->
          <div v-else>
            <h2 class="step-subtitle">Selecciona la cola de distribución</h2>

            <h3>Datos del contacto</h3>
            <div class="user-phone">
              <div class="user-info">
                <profile-image :photo="client?.picture" :name="client?.full_name" size="small" />
                <p style="margin-left: 12px">
                  {{ contact?.fullName || client?.full_name }}
                </p>
              </div>

              <span> <WhatsAppOutlined class="icon__whatsapp" /> {{ record?.phone }} </span>
            </div>

            <a-divider />

            <a-alert
              v-if="balance <= 0 && type_billing == TypeBilling.PrePayment"
              message="Actualmente, no cuentas con saldo suficiente en WhatsApp para realizar envíos de plantillas"
              type="warning"
              show-icon
              class="balance-warning"
            />
          </div>

          <a-form-item label="Cola" name="queue">
            <a-select
              :getPopupContainer="(triggerNode) => triggerNode.parentNode"
              v-model:value="formState2.queue"
              placeholder="Seleccionar cola"
              :disabled="ticketQueue"
            >
              <a-select-option v-for="item in queues" :key="item.id">
                {{ item.name + " " + whatsappIdentifier(item) }}
              </a-select-option>
            </a-select>
          </a-form-item>
          <div class="campaign">{{ textCampaign }}</div>
        </a-form>
      </div>

      <!-- Step 2 -->
      <div v-if="current === 1" class="container-step__two">
        <div class="template-picker__wrapper">
          <div>
            <a-form ref="formRef2" :model="formState2" :rules="rules2" layout="vertical">
              <template v-if="record?.phone">
                <h3>Datos del contacto</h3>

                <p class="client__info" style="margin-bottom: 12px" v-if="client?.full_name">
                  Nombre: {{ client?.full_name }}
                </p>
                <p class="client__info">
                  Teléfono:
                  {{
                    record?.country?.code_phone || record?.code_country
                      ? `+${record?.country?.code_phone || record.code_country}`
                      : ""
                  }}
                  {{ record?.phone }}
                </p>

                <a-divider type="horizontal" class="divider" />

                <a-alert
                  v-if="balance <= 0 && type_billing == TypeBilling.PrePayment"
                  message="Actualmente, no cuentas con saldo suficiente en WhatsApp para realizar envíos de plantillas"
                  type="warning"
                  show-icon
                  class="balance-warning"
                />

                <h3>Selecciona la plantilla que se utilizará</h3>
              </template>

              <template v-else>
                <h3>
                  Ingresa el número telefónico al que deseas enviar un mensaje y la cola de
                  distribución
                </h3>

                <a-alert
                  v-if="balance <= 0 && type_billing == TypeBilling.PrePayment"
                  message="Actualmente, no cuentas con saldo suficiente en WhatsApp para realizar envíos de plantillas"
                  type="warning"
                  show-icon
                  class="balance-warning"
                />

                <a-form-item label="Télefono de contacto" name="phone_number">
                  <a-row :gutter="24" type="flex">
                    <a-col :span="9">
                      <selector-country
                        :code="code_phone"
                        @selected="code_phone = $event"
                        @change="handleChangeCountry"
                      />
                    </a-col>
                    <a-col :span="15" style="padding-left: 0">
                      <a-input
                        v-model:value="formState2.phone_number"
                        placeholder="Escribir número"
                        :maxlength="code_phone === 1 ? 9 : 100"
                        @keypress="handleNumberPhone($event)"
                        @input="handleChangeNumber"
                        :class="{ 'background-red': phoneMax }"
                      />
                      <span v-if="phoneMax" class="form__error"
                        >Ingrese un número de 9 dígitos</span
                      >
                    </a-col>
                  </a-row>
                </a-form-item>
              </template>

              <template v-if="!showNoTemplateMessage || !chatButton">
                <CampaignPicker
                  v-model:value="formState2.portfolio"
                  withName
                  withOutEntity
                  templatePicker
                  :disabled="portfolio"
                  :showAll="false"
                  label="Campaña"
                />

                <QueuePicker
                  v-model:value="formState2.queue"
                  label="Cola"
                  ref="queuePickerRef"
                  templatePicker
                  :showAll="false"
                  :disabled="ticketQueue"
                  :withOutPortfolio="false"
                  :portfolio="formState2.portfolio"
                />

                <a-form-item label="Plantilla" name="template">
                  <a-select
                    :getPopupContainer="(triggerNode) => triggerNode.parentNode"
                    v-model:value="formState2.template"
                    :disabled="!formState2.queue"
                    placeholder="Seleccionar plantilla"
                  >
                    <a-select-option v-for="item in templates" :key="item.id">
                      {{ item.name }}
                    </a-select-option>
                  </a-select>
                </a-form-item>
              </template>

              <p v-else>No existen plantillas asignadas a la cola: {{ ticketQueue?.name }}</p>
            </a-form>
          </div>

          <div class="footer-step-two" v-if="!record?.phone">
            <!-- ¿Deseas solicitar más plantillas? Escríbenos a
            <p><strong>feedback@scorecloud.com</strong></p> -->
            <p>
              <strong>Nota: </strong> Solo se listarán las plantillas asignadas a la cola y campaña
              seleccionada.
            </p>
          </div>
        </div>
        <WhatsappTemplatePhone
          :content="!formState2.template ? 'Aquí se visualizará tu plantilla de WhatsApp' : text"
          :preview="!formState2.template"
          :state="!formState3"
          :file="multimediaFile"
          :templateType="templateType"
          :buttons="buttons"
        />
      </div>

      <!-- Step 3 -->
      <div v-if="current === 2" class="container-step__two">
        <CustomScrollbar height="100%" style="margin: 0; padding: 0 15px">
          <h3>Finalmente completa los parametros</h3>
          <p v-if="!totalParameters && templateType === WhatsappTemplateTypes.Text">
            Esta plantilla no tiene parámetros
          </p>
          <a-form v-else layout="vertical" :model="formState3">
            <a-form-item
              v-for="index in totalParameters"
              :key="index"
              :name="$data[`param${index}`]"
              :label="`Parámetro {{${index}}}`"
              required
            >
              <a-input v-model:value="formState3[index]" placeholder="Escribe aquí" />
            </a-form-item>

            <a-form-item
              label="Archivo adjunto"
              v-if="templateType !== WhatsappTemplateTypes.Text"
              :required="templateType !== WhatsappTemplateTypes.Text"
            >
              <ArchivePicker
                v-model:value="multimediaFile"
                :supported-types="supportedTypes"
                :maxMBSize="maxMBSize"
              />

              <span v-if="multimediaFile" class="file-info">
                <InfoCircleOutlined />
                La previsualización es referencial
              </span>
            </a-form-item>
          </a-form>
        </CustomScrollbar>

        <WhatsappTemplatePhone
          :content="text"
          :state="formState3"
          :file="multimediaFile"
          :templateType="templateType"
          :buttons="buttons"
        />
      </div>
    </div>
    <template #footer>
      <div class="footer">
        <a-button class="secondary" v-if="current !== 1" @click="() => current--">
          Volver
        </a-button>
        <a-button class="secondary" v-if="current === 1" @click="handleBack"> Cancelar </a-button>
        <a-tooltip v-if="current === 2" :title="isDisabled ? 'Complete campos requeridos' : null">
          <a-button type="primary" :disabled="isDisabled" @click="handleOk" :loading="isLoading">
            Enviar plantilla
          </a-button>
        </a-tooltip>
        <a-button
          type="primary"
          v-if="current !== 2"
          :disabled="isDisabled || (balance <= 0 && type_billing == TypeBilling.PrePayment)"
          @click="() => current++"
        >
          Continuar
        </a-button>
      </div>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, createVNode, watch } from "vue";
import {
  WhatsAppOutlined,
  QuestionCircleOutlined,
  InfoCircleOutlined,
} from "@ant-design/icons-vue";
import { message, Modal } from "ant-design-vue";

import { fetchQueues, fetchTemplates, sendTemplate } from "@/app/workspace/services";
import { ChannelType } from "@/app/shared/utils/enums";
import { WhatsappTemplateTypes } from "@/app/channels/utils/enums";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import EventBus from "@/app/shared/utils/eventBus";
import { templateParamRegex } from "@/app/channels/utils/regex";

import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import ProfileImage from "@/app/shared/components/organisms/ProfileImage.vue";
import WhatsappTemplatePhone from "@/app/shared/components/organisms/WhatsappTemplatePhone.vue";
import ArchivePicker from "@/app/shared/components/molecules/ArchivePicker.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import QueuePicker from "@/app/shared/components/pickers/QueuePicker.vue";
import { fetchWhatsappBalance } from "@/app/settings/services";
import { TypeBilling } from "@/app/workspace/utils/enums";

const steps = [
  {
    id: 1,
    title: "Ingresa datos",
  },
  {
    id: 2,
    title: "Selecciona plantilla",
  },
  {
    id: 3,
    title: "Completa parámetros",
  },
];

export default defineComponent({
  components: {
    WhatsAppOutlined,
    SelectorCountry,
    ProfileImage,
    WhatsappTemplatePhone,
    ArchivePicker,
    CustomScrollbar,
    InfoCircleOutlined,
    CampaignPicker,
    QueuePicker,
  },
  props: {
    record: {
      required: false,
    },
    floating: {
      type: Boolean,
      default: false,
    },
    chatButton: {
      type: Boolean,
      default: false,
    },
    hasTooltip: {
      type: Boolean,
      default: true,
    },
    hasLabelButton: {
      type: Boolean,
      default: false,
    },
    typeButton: {
      type: String,
      default: "link",
    },
    isBlock: {
      type: Boolean,
      default: false,
    },
    portfolio: {
      type: Object,
      default: undefined,
    },
    ticketQueue: {
      type: Object,
      default: undefined,
    },
    selectedInteraction: { type: Object, default: undefined },
    disabled: { type: Boolean, default: false },
    popoverButton: {
      type: Boolean,
      default: false,
    },
    ticketId: {
      required: false,
      type: Number,
    },
    validateBlacklist: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const showModal = ref(false);
    const templates = ref([]);

    // const selectedInteraction = inject("selectedChannel");

    const current = ref(1);
    const queuePickerRef = ref();
    // const formRef = ref();
    const code_phone = ref(1);
    // const formState = reactive({
    //   phone_number: "",
    //   queue: undefined,
    // });
    // const rules = {
    //   phone_number: [{ required: true, message: "Campo requerido" }],
    //   queue: [{ required: true, message: "Campo requerido" }],
    // };
    const formRef2 = ref();
    const formState2 = reactive({
      phone_number: "",
      portfolio: undefined,
      queue: undefined,
      template: undefined,
    });
    const rules2 = {
      phone_number: [{ required: true, message: "Campo requerido" }],
      portfolio: [{ required: true, message: "Campo requerido" }],
      queue: [{ required: true, message: "Campo requerido" }],
      template: [{ required: true, message: "Campo requerido" }],
    };

    const formState3 = reactive({});

    watch([formState2, showModal], () => {
      if (props.record?.phone) formState2.phone_number = props.record.phone;
      if (props.ticketQueue) formState2.queue = props.ticketQueue.id;
      if (props.portfolio) formState2.portfolio = props.portfolio.id;
      if (props.record?.country) code_phone.value = props.record.country?.id;
    });

    watch(
      () => formState2.portfolio,
      () => {
        if (!props.ticketQueue) formState2.queue = undefined;
        formState2.template = undefined;
      }
    );

    const showNoTemplateMessage = ref(false);

    watch(
      () => formState2.queue,
      async () => {
        formState2.template = undefined;
        if (formState2.queue) {
          templates.value = await fetchTemplates(
            formState2.queue,
            props.selectedInteraction?.channel_customer?.id
          );
          showNoTemplateMessage.value = templates.value.length === 0 && props.chatButton;
        }
      }
    );

    watch(current, (newCurrent) => {
      if (newCurrent === 1) {
        for (const idx in formState3) {
          formState3[idx] = "";
        }
      }
    });

    return {
      showModal,
      templates,
      current,
      code_phone,
      queuePickerRef,
      // formRef,
      // formState,
      // rules,
      formRef2,
      formState2,
      rules2,
      formState3,
      steps,
      WhatsappTemplateTypes,
      showNoTemplateMessage,
      TypeBilling,
    };
  },
  data() {
    return {
      textCopy: "",
      isLoading: false,
      queues: [],
      param1: null,
      param2: null,
      param3: null,
      param4: null,
      param5: null,
      clientId: undefined,
      phoneMax: false,
      multimediaFile: null,
      balance: 0,
      type_billing: null,
    };
  },
  created() {
    this.fetchQueues();
    EventBus.on("WORKSPACE_CLIENT_ID", (data) => {
      this.clientId = data;
    });
    this.fetchBalance();
  },
  computed: {
    client() {
      return this.$store.getters["getWorkspaceClient"]?.data_client?.[0] || this.record;
    },
    currentQueue() {
      return this.queues.find((item) => item.id == this.formState2.queue);
    },
    textCampaign() {
      if (this.currentQueue) return `Campaña asociada: ${this.currentQueue.portfolios[0].name}`;
      return "Campaña a la que se encuentra vinculada la cola";
    },
    currentTemplate() {
      return this.templates.find((item) => item?.id == this.formState2.template);
    },
    text() {
      return this.currentTemplate?.template;
    },
    templateType() {
      return this.currentTemplate?.template_type || WhatsappTemplateTypes.Text;
    },
    buttons() {
      return this.currentTemplate?.buttons ?? [];
    },
    totalParameters() {
      return this.text?.match(templateParamRegex)?.length || 0;
    },
    isDisabled() {
      if (this.current == 0) {
        return !(this.formState2.queue !== undefined && this.formState2.phone_number?.length > 6);
      }
      if (this.current == 1) {
        const isSomeFieldEmpty = this.record?.phone
          ? false
          : this.formState2.phone_number?.length <= 6 ||
            !this.formState2.portfolio ||
            !this.formState2.queue;

        return isSomeFieldEmpty || !this.formState2.template;
      }

      if (this.current == 2) {
        let allFilled = true;
        for (let index = 1; index <= this.totalParameters; index++) {
          if (this.formState3[index] == null || this.formState3[index] == "") allFilled = false;
        }
        return this.templateType !== WhatsappTemplateTypes.Text
          ? !(allFilled && this.multimediaFile)
          : !allFilled;
      }
      return true;
    },
    supportedTypes() {
      let types = [];

      if (this.templateType === WhatsappTemplateTypes.TextAndImage)
        types = [".png", ".jpg", ".jpeg"];
      if (this.templateType === WhatsappTemplateTypes.TextAndVideo) types = [".mp4"];
      if (this.templateType === WhatsappTemplateTypes.TextAndDocument) types = [".pdf"];

      return types;
    },
    maxMBSize() {
      let size = null;

      if (this.templateType === WhatsappTemplateTypes.TextAndImage) size = 2;
      if (this.templateType === WhatsappTemplateTypes.TextAndVideo) size = 5;
      if (this.templateType === WhatsappTemplateTypes.TextAndDocument) size = 5;

      return size;
    },
  },
  methods: {
    async fetchBalance() {
      const response = await fetchWhatsappBalance("whatsapp");

      this.balance = response.balance;
      this.type_billing = response.type_billing;
    },
    handleChangeCountry() {
      this.formState2.phone_number = undefined;
      this.phoneMax = false;
    },
    handleChangeNumber() {
      if (this.formState2.phone_number.length < 9 && this.code_phone === 1) {
        this.phoneMax = true;
      } else {
        this.phoneMax = false;
      }
    },
    handleNumberPhone(e) {
      const char = String.fromCharCode(e.keyCode);
      if (/^[0-9]+$/.test(char)) return true;
      else e.preventDefault();
    },
    showConfirmationModal() {
      Modal.confirm({
        title: "¿Deseas descartar los cambios realizados?",
        content: "Recuerda que si no guardas los cambios registrados, estos se perderán.",
        icon: createVNode(QuestionCircleOutlined),
        onOk: () => this.handleClose(),
        centered: true,
        okText: "Sí, descartar cambios",
        cancelText: "No, seguir editando",
      });
    },
    formatted() {
      this.textCopy = this.text;
      for (let index = 1; index <= this.totalParameters; index++) {
        if (this.$data[`param${index}`] != null) {
          this.textCopy = this.textCopy.replace(
            new RegExp(`{{[${index}]}}`, "g"),
            this.$data[`param${index}`]
          );
        }
      }

      return this.textCopy;
    },
    whatsappIdentifier(queue) {
      const whatsapp = queue.channels_customer.find(
        (channel) => channel.channel.id === ChannelType.Whatsapp
      );

      return whatsapp ? "(+" + whatsapp.data_parameters.phone_number + ")" : "";
    },
    async fetchQueues() {
      const response = await fetchQueues();
      // const whatsappQueues = response.filter((item) => {
      //   let queue = item.channels_customer.filter(
      //     (channel) => channel.channel.id == ChannelType.Whatsapp
      //   );
      //   return queue.length > 0;
      // });

      this.queues = response;
    },
    openWhatsModal() {
      this.showModal = true;
    },
    clearFields() {
      this.multimediaFile = null;
      this.formState2.phone_number = "";
      this.formState2.portfolio = undefined;
      this.formState2.queue = undefined;
      this.formState2.template = undefined;
      this.phoneMax = false;
      this.code_phone = 1;
    },
    handleClose() {
      for (let index = 1; index <= this.totalParameters; index++) {
        this.$data[`param${index}`] = null;
      }
      this.showModal = false;
      this.current = 1;
      this.clearFields();
      this.$emit("handleClose");
    },
    handleOk() {
      switch (this.current) {
        case 0:
          // this.formRef.validateFields().then(() => {
          //   this.current++;
          // });
          break;
        case 1:
          this.formRef2.validateFields().then(() => {
            this.current++;
          });
          break;
        case 2:
          this.sendTemplate();
          break;
        default:
          break;
      }
    },
    async sendTemplate() {
      const template_values = [];
      for (let index = 1; index <= this.totalParameters; index++) {
        template_values.push(this.formState3[index]);
      }
      const phone_number = this.formState2.phone_number;
      // const channel_customer = this.currentQueue.channels_customer.find(
      //   (channel) => channel.channel.id === ChannelType.Whatsapp
      // ).id;
      const queue = this.formState2.queue;
      const country = this.code_phone;
      const code_country = this.record?.code_country;
      const template = this.formState2.template;

      const data = {
        country,
        phone_number,
        queue,
        template,
        template_values: JSON.stringify(template_values),
      };

      if (this.record?.id) data.client = this.record.id;
      if (this.multimediaFile) data.file = this.multimediaFile;
      if (code_country) data.code_country = code_country;
      const channelPerson = this.$route.params.channel;
      if (this.chatButton && channelPerson) data.channel_person = channelPerson;
      if (this.ticketId) data.ticket_id = this.ticketId;

      this.isLoading = true;
      try {
        await sendTemplate(data);
        this.handleDisabledWhatsapp();
        message.success("Se ha enviado la plantilla de WhatsApp correctamente");
        this.$store.dispatch("fetchActiveWorkspaceTickets");
        this.handleClose();
      } catch (error) {
        if (error.status === 409)
          EventBus.emit(WorkspaceEvent.ANOTHER_USER_HAS_THIS_TICKET_ACTIVE, { error });
        else if (error.status === 400) {
          const fullErrorMessage = error.getErrorMessage();
          const errorMessage = fullErrorMessage.replace(/^[^:]+:\s*/, "");
          message.error(errorMessage);
        } else message.warning("Algo salió mal, por favor, vuelve a intentarlo");
      }
      this.isLoading = false;
    },
    handleBack() {
      if (this.current === 1 && this.formState2.phone_number === "") {
        this.showModal = false;
        this.clearFields();
        this.$emit("handleClose");
      } else {
        this.showConfirmationModal();
      }
    },
    handleDisabledWhatsapp() {
      EventBus.$emit(WorkspaceEvent.DISABLED_INPUT_WHATSAPP);
    },
  },
  watch: {
    text(value) {
      this.textCopy = value;
    },
  },
});
</script>

<style lang="sass" scoped>
h3
  font-size: 16px
  color: $gray-9
  font-weight: 600
  margin-bottom: 10px

p
  margin-bottom: 0

.step-subtitle
  text-align: center
  font-size: 16px
  font-weight: 600
  margin-bottom: 20px
  color: $gray-9

.user
  &-phone
    display: flex
    justify-content: space-between
    font-size: 14px
    color: $gray-7
    font-style: italic
    align-items: center

  &-info
    display: flex
    align-items: center

.client
  &__info
    color: $gray-7
    font-style: italic

.divider
  background-color: $gray-4
  margin: 20px 0

.footer
  display: flex
  justify-content: flex-end
  gap: 8px

.file
  &-info
    color: $gray-7

.template-picker__wrapper
  display: flex
  flex-direction: column
  justify-content: space-between

.button
  padding: 0
  height: 22px

  &-whats
    position: absolute
    width: 44px
    height: 44px
    right: 10px
    bottom: 35px
    background: #25D366
    color: #fff
    border-radius: 100px
    box-shadow: 5px 5px 10px 0 #BABABA
    text-align: center
    margin: auto
    cursor: pointer
.container-step
  height: 85%
  &__one
    padding: 50px 40px
    & .step-title
      text-align: center
  &__two
    padding: 25px 20px 8px
    display: grid
    grid-template-columns: minmax(0, 1.1fr) minmax(0, 0.9fr)
    gap: 25px

.footer-step-two
  color: $gray-8
.campaign
  margin-top: -15px
  color: $gray-6
.cellphone
  height: 100%

.icon
  &-required
    margin-right: 4px
    color: #ff4d4f
    font-size: 14px
    font-family: SimSun, sans-serif
    line-height: 1

  &__whatsapp
    margin-right: 5px

.form__error
  color: $red-6
  margin: 0

.background-red
  border: 1px solid red !important

:deep(.ant-steps-item-title)
  font-size: 14px
  line-height: normal

:deep(.ant-steps-item-content)
  width: 150px !important
  position: relative !important
  left: -14px !important

:deep(.ps__rail-y)
  opacity: 0.6

.balance-warning
  margin-bottom: 12px

.popover-content
  button
    width: 100%
    text-align: left
    padding: 0 5px
    display: flex
    align-items: center
    gap: 5px

    &:hover
      background: #0000000A
    .icon
      height: 13px
</style>
