<template>
  <a-dropdown :trigger="['click']">
    <a-tooltip :title="companyIdentifier">
      <a-button
        class="channel__button"
        :class="{ 'channel__button-active': isActive }"
        @click="handleClick"
      >
        <img :src="groupedChannel.icon" :alt="groupedChannel.name" />

        <CaretDownOutlined v-if="showDropdown" class="channel__icon" />
      </a-button>
    </a-tooltip>

    <template #overlay v-if="showDropdown">
      <a-menu :selectedKeys="selectedKeys" @click="handleMenuClick" class="popover-item">
        <a-menu-item v-for="channel in groupedChannel.items" :key="channel.id">
          <span class="flex-center">
            <span class="flex-center">
              <UserIcon />
              {{ getInteractionLabel(channel).identifier }}
            </span>

            <span class="flex-center">
              <component :is="getChannelIcon(channel?.channel.id)" />
              <span v-if="getInteractionLabel(channel).did">
                <span v-if="getInteractionLabel(channel).alias">
                  {{ getInteractionLabel(channel).alias }}
                </span>
                <span v-else>
                  {{ getInteractionLabel(channel).did }}
                  <small class="no-alias">Sin alias</small>
                </span>
              </span>

              <span v-else>Número aleatorio</span>
            </span>
          </span>
        </a-menu-item>
      </a-menu>
    </template>
  </a-dropdown>
</template>

<script setup>
import { computed, ref, toRefs } from "vue";
import { useRoute, useRouter } from "vue-router";
import { CaretDownOutlined } from "@ant-design/icons-vue";
import { ChannelType, InteractionType } from "@/app/shared/utils/enums";

import UserIcon from "@/app/shared/components/icons/UserIcon.vue";
import VoiceIcon from "@/app/shared/components/icons/channelIcons/VoiceIcon.vue";
import WhatsappIcon from "@/app/shared/components/icons/channelIcons/WhatsappIcon.vue";
import FacebookMsnIcon from "@/app/shared/components/icons/channelIcons/FacebookMsnIcon.vue";
import InstagramIcon from "@/app/shared/components/icons/channelIcons/InstagramIcon.vue";
import WebchatIcon from "@/app/shared/components/icons/channelIcons/WebchatIcon.vue";
import SmsIcon from "@/app/shared/components/icons/channelIcons/SmsIcon.vue";

const props = defineProps({
  groupedChannel: { type: Array, default: () => [] },
});

// const { groupedChannel } = toRefs(props);

const route = useRoute();
const router = useRouter();

const isActive = computed(() => {
  const currentChannelPersonIsInsideDropdown = props.groupedChannel?.items?.some(
    (interaction) => interaction.id === Number(route.query.interaction)
  );

  return currentChannelPersonIsInsideDropdown;
});

const companyIdentifier = computed(() => {
  const channelId = props.groupedChannel.items[0].channel?.id;
  if (![ChannelType.Voice, ChannelType.Whatsapp].includes(channelId)) {
    return null;
  }

  const interactionsCount = props.groupedChannel?.items?.length;
  if (interactionsCount > 1) {
    return `${interactionsCount} interacciones`;
  }

  const dataParameters = props.groupedChannel.items[0].channel_customer?.data_parameters;

  const identifier = dataParameters
    ? dataParameters.phone_number
    : props.groupedChannel.items[0].channel_customer?.identifier;

  const alias = props.groupedChannel.items[0].channel_customer?.alias;

  const interactionType =
    props.groupedChannel.items[0].type_interaction === InteractionType.Inbound
      ? "Inbound"
      : "Outbound";

  return identifier && alias
    ? `Identificador de tu empresa: ${alias} ${identifier}`
    : `Numero aleatorio - ${interactionType}`;
});

const getInteractionLabel = (interaction) => {
  const identifier = interaction?.identifier;
  const alias = interaction?.channel_customer?.alias;
  const did = interaction?.channel_customer?.data_parameters?.phone_number;

  return { identifier, alias, did };
};

const showDropdown = computed(() => props.groupedChannel?.items?.length > 1);

const goToInteraction = (interaction) => {
  if (!interaction) return;

  const ticketId = route.query.ticket;
  const clientId = route.query.client;

  router.push({ path: "/workspace", query: { ticket: ticketId, interaction, client: clientId } });
};

const handleClick = () => {
  if (!showDropdown.value) goToInteraction(props.groupedChannel?.items?.[0]?.id);
};

const selectedKeys = computed(() => [Number(route.query.interaction)]);

const handleMenuClick = (menuItem) => {
  goToInteraction(menuItem.key);
};

const getChannelIcon = (type) => {
  switch (type) {
    case ChannelType.Voice:
    case ChannelType.Ivr:
    case ChannelType.VoiceInbound:
      return VoiceIcon;
    case ChannelType.Whatsapp:
      return WhatsappIcon;
    case ChannelType.Messenger:
      return FacebookMsnIcon;
    case ChannelType.Instagram:
      return InstagramIcon;
    case ChannelType.Inbound:
      return WebchatIcon;
    case ChannelType.Sms:
      return SmsIcon;
    default:
      return null;
  }
};
</script>

<style lang="sass" scoped>
.channel
  &__button
    padding: 0
    display: flex
    align-items: center
    justify-content: center
    padding: 6px
    border-radius: 6px
    gap: 4px
    width: 47px

    &-active
      color: $blue-7
      background-color: $geekblue-1
      border-color: $geekblue-5

      &:hover
        background-color: $geekblue-1
        border-color: $geekblue-5
        color: $blue-7

      &:focus
        background-color: $geekblue-1
        border-color: $geekblue-5
        color: $blue-7

    img
      width: 18px

  &__icon
    font-size: 11px
    color: $blue-7

.popover-item
  :deep(.ant-dropdown-menu-title-content)
    display: flex
    align-items: center
    gap: 8px

.flex-center
  display: flex
  align-items: center
  gap: 8px

.no-alias
  font-style: italic
  color: #00000040
</style>
