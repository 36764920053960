import httpManager from "@/app/shared/utils/httpManager";
/**
 * Etiquetar uno o varios tickets.
 *
 * @param {Array} ticketsId Objeto con ticketsId y tagId
 *
 */
export async function resolveTickets(ticketsId) {
  const body = {
    tickets: ticketsId,
  };

  await httpManager.post(`/tickets/manager/resolve/`, body);
}
