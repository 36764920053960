import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los usuarios registrados.
 *
 * @return {Promise<Array>} Lista de usuarios filtrados.
 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export default async function (search = "", portfolio = "", entity = "", pagination = {}) {
  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE;

  const response = await httpManager.get(
    `/users/?search=${search}&portfolio=${portfolio}&entity=${entity}&offset=${offset}&limit=${pageSize}`
  );
  return response?.data;
}
