const getters = {
  getAllIndustries(state) {
    const Others = 'Otros';
    const industries = state.industries.map((industry, index) => {
      return {
        id: index + 1,
        name: industry,
      };
    });
    return [...industries].sort((a, b) => {
      if (a.name === Others) return 1;
      if (b.name === Others) return -1;
      return a.name.localeCompare(b.name);
    }); 
  },
};

export default getters;
