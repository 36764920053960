import { GetterTree } from "vuex";
import { State } from "@/@types/emailsManager";

const getters: GetterTree<State, any> = {
  getTablePage(state) {
    return state.tableEmailsPage;
  },
  getEmailsBasicFilters(state) {
    return state.emailsBasicFilters;
  },
  getEmailsAdvancedFilters(state) {
    return state.emailsAdvancedFilters;
  },
  getEmailsAdvancedFiltersData(state) {
    return state.emailsAdvancedFiltersData;
  },
  getEmailsManager(state) {
    console.log(state);
    return state.emailsData;
  },
  getTransferEmailsData(state) {
    return state.transferEmailsData;
  },
  getUsersData(state) {
    return state.usersData;
  },
};

export default getters;
