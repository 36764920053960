<template>
  <a-modal
    v-model:visible="visible"
    width="1120px"
    :footer="showFooter ? undefined : null"
    title="Sms Integration"
    @cancel="handleCancel"
  >
    <div v-if="isIntegrationCompleted">
      <CompleteIntegration :text-complete="textComplete" type="sms" />
    </div>
    <div v-else class="template">
      <div class="container">
        <p class="title">SMS</p>
        <p class="description">¡Activa tu canal SMS doble vía para conversar con tus clientes!</p>
        <div class="card-details">
          <p class="description">
            • La numeración mostrada al enviar es aleatoria. <br />
            • La efectividad de entrega es del 99%. <br />
            • Los resultados, en tiempos y estados, dependen del operador móvil.
            <br />
            • Comunicación bidireccional.
          </p>
        </div>

        <a-row :gutter="4" type="flex">
          <a-col span="12"> <InfoCircleOutlined /> Válido solo para Perú </a-col>
        </a-row>
      </div>
    </div>
    <template #footer>
      <a-button @click="handleCancel">Atras</a-button>

      <a-button type="primary" :disabled="isDisabled" @click="handleOk"> Activar </a-button>
    </template>
  </a-modal>
</template>

<script>
import CompleteIntegration from "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue";
import { defineComponent, ref, reactive } from "vue";
import { InfoCircleOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  name: "SmsChannels",
  components: {
    CompleteIntegration,
    InfoCircleOutlined,
  },
  props: {
    open: {
      required: true,
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const formRef = ref();
    const formState = reactive({
      workspace: "",
    });
    const rules = {
      workspace: [],
    };
    return {
      rules,
      formState,
      formRef,
    };
  },

  data() {
    return {
      isIntegrationCompleted: false,
      textComplete: {
        channel: "sms",
        title: "SMS",
        description: "Tu canal de SMS esta listo para su implementación",
      },
    };
  },
  computed: {
    visible() {
      return this.open;
    },
    isDisabled() {
      return this.infoBussiness?.country !== 1;
    },
    showFooter() {
      return !this.isIntegrationCompleted;
    },
    infoBussiness() {
      return this.$store.getters["getBussiness"];
    },
  },
  methods: {
    handleOk() {
      this.isIntegrationCompleted = true;
    },
    async handleCreateNewChannel(data) {
      try {
        await this.$store.dispatch("createNewChannel", data);
        this.isIntegrationCompleted = true;
        this.formRef.resetFields();
      } catch (error) {
        const errorMessage = error?.getErrorMessage() || "Ha courrido un error";

        this.$message.error(errorMessage);
      }
    },
    handleCancel() {
      this.isIntegrationCompleted = false;
      this.$emit("close");
    },
  },
});
</script>

<style lang="sass" scoped>
.template
  padding: 32px 20px 8px
.container
  margin: auto
  width: 540px
.link
  color: $blue-6
  margin-bottom: 0px
  margin-left: $margin-tiny
  font-weight: normal
.title
  font-weight: 600
  font-size: $font-large
  line-height: 28px
  text-align: center
  color: $gray-9
  margin-bottom: $margin-tiny
  margin-top: 30px
.description
  font-size: $font-normal
  line-height: 22px
  color: $gray-9
  text-align: center
.card-details
  background: $geekblue-2
  border: 1px solid $blue-1
  box-sizing: border-box
  border-radius: 2px
  padding: $padding-medium
  width: 100%
  margin-top: 28px
  margin-bottom: 28px
  p
    text-align: left
    margin-bottom: 0px
.card-active
  background: $white
  border: 1px solid $gray-4
  box-sizing: border-box
  border-radius: 8px
  margin-top: $margin-small
  padding: 8px 0px 8px 12px
  margin-bottom: $margin-large
  span
    font-size: $font-small
.btns
  display: flex
  justify-content: flex-end
  button
    border-radius: 2px
  .btn-return
    margin-right: 12px
</style>
