import {
  fetchAllDialplans,
  createNewDialplan,
  updateDialplan,
  fetchPriceTrunkDialplan,
  deleteDialPlan,
} from "@/app/pbx/services";
import {
  SET_DIALPLANS,
  ADD_DIALPLANS_TO_LIST,
  UPDATE_DIALPLAN,
  SET_DIALPLANRULES,
  ADD_DIALPLANRULES,
  UPDATE_DIALPLANRULES,
  REMOVE_DIALPLANRULES,
  SET_PRICES_TRUNKS_DIALPLAN,
  REMOVE_DIALPLAN,
} from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchAllDialplans({ commit }, params = {}) {
    const { search, pagination, country } = params;
    const data = await fetchAllDialplans(search, pagination, country);
    commit(SET_DIALPLANS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async createNewDialplan({ commit }, params) {
    const createdTrunks = await createNewDialplan(params);
    commit(ADD_DIALPLANS_TO_LIST, createdTrunks);
  },
  async updateDialplan({ commit }, { id, params }) {
    const updatedDialplan = await updateDialplan(id, params);
    commit(UPDATE_DIALPLAN, updatedDialplan);
  },
  setDialplanRules({ commit }, params) {
    commit(SET_DIALPLANRULES, params);
  },
  addDialplanRules({ commit }, params) {
    commit(ADD_DIALPLANRULES, params);
  },
  updateDialplanRules({ commit }, params) {
    commit(UPDATE_DIALPLANRULES, params);
  },
  deleteDialplanRules({ commit }, ruleId) {
    commit(REMOVE_DIALPLANRULES, ruleId);
  },
  async addPricesTrunk({ commit }, idTrunk) {
    const pricesTrunk = await fetchPriceTrunkDialplan(idTrunk);
    commit(SET_PRICES_TRUNKS_DIALPLAN, pricesTrunk);
  },
  async deleteDialPlan({ commit }, dialPlanId) {
    await deleteDialPlan(dialPlanId);
    commit(REMOVE_DIALPLAN, dialPlanId);
  },
};
export default actions;
