import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar un recordatorio.
 *
 * @param {number} reminderId Identificador del recordatorio.
 * @param {FormData} reminder Datos modificados del recordatorio.
 *
 * @return {Promise<Object>} Base editada.
 */
export async function updateReminder(reminderId, reminder) {
  const response = await httpManager.put(`/schedule/reminders/${reminderId}/`, reminder);
  return response?.data;
}
