<template>
  <div class="controls">
    <div class="pickers">
      <entity-picker
        v-if="user?.customer?.type_business == 1"
        class="picker"
        :value="entity"
        @update:value="entity = $event"
      />

      <campaign-picker
        class="picker"
        :value="campaign"
        @update:value="campaign = $event"
        :entity="entity"
      />
    </div>

    <UpdateCounterButton
      v-if="entity !== undefined"
      :secondsToUpdate="secondsToUpdate"
      @click="$emit('click:update')"
    />
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch, inject } from "vue";
import { useStore } from "vuex";

import EntityPicker from "@/app/shared/components/pickers/EntityPicker.vue";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import UpdateCounterButton from "@/app/supervision/components/buttons/UpdateCounterButton.vue";

export default defineComponent({
  components: {
    CampaignPicker,
    EntityPicker,
    UpdateCounterButton,
  },
  setup() {
    const store = useStore();
    const user = computed(() => store.getters["getDataUser"]);
    const entity = ref(undefined);
    if (user.value) {
      if (user.value.customer.type_business == 2) {
        entity.value = 1;
      }
    }
    watch(user, (newValue) => {
      if (newValue.customer.type_business == 2) {
        entity.value = 1;
      }
    });

    const secondsToUpdate = inject("secondsToUpdate");

    return {
      entity,
      user,
      secondsToUpdate,
    };
  },
  data() {
    return {
      // entity: undefined,
      campaign: 0,
      intervalId: null,
    };
  },
  watch: {
    entity() {
      this.$emit("change:entity", this.entity);
      // this.handleSearch();
      // this.startCounter();
    },
    campaign() {
      this.$emit("change:campaign", this.campaign);
      // this.handleSearch();
      // this.startCounter();
    },
  },
});
</script>

<style lang="sass" scoped>
.ant-form-item
  margin: 0

.controls
  display: flex
  justify-content: space-between
  margin-bottom: 1rem

.picker
  min-width: 200px

.pickers
  display: flex
  align-items: center
  gap: 10px
</style>
