import { SET_CHANNELS, SET_CHANNEL, ADD_NEW_CHANNEL, CLEAR_CHANNEL } from "./mutation-types";

const mutations = {
  [SET_CHANNELS](state, channels) {
    state.channels = channels;
  },
  [SET_CHANNEL](state, channel) {
    state.channel = channel;
  },
  [ADD_NEW_CHANNEL](state, payload) {
    const index = state.channels.findIndex((channel) => {
      return channel.id == payload.id;
    });
    state.channels.splice(index, 1, payload.datachannel);
  },
  [CLEAR_CHANNEL](state) {
    state.channel = {};
  },
};

export default mutations;
