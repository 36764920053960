const initialState = () => ({
  types: ["Contact Center", "In-House"],
  //types: ["Atención al Cliente", "Telemarketing", "Televentas", "Cobranzas"],
  hourPlatform: "",
  platformDate: "",
  inAcw: false,
  waitingChangeStateMessage: null,
});

const state = initialState();

export default state;
