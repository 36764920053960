import {
  fetchNotifications,
  markAsReadNotification,
  readAllNotifications,
} from "@/app/notifications/services";

import {
  SET_LIMITED_NOTIFICATIONS,
  SET_NOTIFICATIONS,
  SET_MARK_AS_READ_NOTIFICATION,
} from "./mutation-types";

const actions = {
  async fetchLimitedNotifications({ commit }) {
    const data = await fetchNotifications(1, 10);

    commit(SET_LIMITED_NOTIFICATIONS, data);
  },
  async fetchNotifications({ commit }, page = 1) {
    const data = await fetchNotifications(page);

    commit(SET_NOTIFICATIONS, data);
  },
  async markAsReadNotification({ commit }, notificationId) {
    await markAsReadNotification(notificationId);

    commit(SET_MARK_AS_READ_NOTIFICATION, notificationId);
  },
  async readAllNotifications() {
    await readAllNotifications();
  },
};

export default actions;
