<template>
  <!-- <navigation-tabs :tabs="tabs"> -->
  <MainContainer>
    <router-view />
  </MainContainer>
  <!-- </navigation-tabs> -->
</template>

<script>
import { defineComponent } from "vue";
// import NavigationTabs from "@/app/shared/components/organisms/NavigationTabs";
import MainContainer from "@/app/shared/components/organisms/MainContainer.vue";

export default defineComponent({
  components: {
    // NavigationTabs,
    MainContainer,
  },
});
</script>
