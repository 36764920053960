<template>
  <div class="transfer__campaign">
    <a-modal
      :visible="visible"
      :title="title"
      wrapClassName="transfer"
      @ok="handleOk"
      @cancel="handleClose"
      destroyOnClose
      :maskClosable="false"
      width="612px"
      :footer="null"
    >
      <div class="content">
        <p v-if="type === 'user'">
          Elige la campaña e identificador al cual transferirás el correo. El nuevo usuario asignado
          podrá responder desde este identificador al contacto.
        </p>
        <p v-if="type === 'campaign'">
          Selecciona la campaña y el identificador al que deseas transferir el correo.
        </p>
        <p v-if="type === 'move'">
          Selecciona uno de tus propios identificadores al que deseas mover el correo. Este será tu
          nuevo remitente.
        </p>
        <a-divider class="divider" type="horizontal" />
        <a-form class="form" ref="formRef" layout="vertical" :model="formState">
          <CampaignPicker
            v-model:value="formState.selectedCampaign"
            :defaultItems="props.selectedUserObject?.portfolios ?? null"
            onlyWithIdentifiers
            :hiddenIdentifier="channelCustomerId"
            v-show="type != 'move'"
            defaultBehavior
            :showAll="false"
            withOutEntity
            label="Selecciona la campaña:"
            showEntityLabel
            @onSetPortfolios="setPortfolios"
            @update:value="selectCampaign"
          />
          <div v-if="type == 'move'">
            <span>Campaña: {{ campaignSelected?.name }}</span>
            <p class="entity-name">Empresa: {{ campaignSelected?.entity?.name ?? "--" }}</p>
          </div>
          <IdentifierPicker
            v-model:value="formState.selectedIdentifier"
            :defaultItems="props.selectedUserObject?.campaign"
            :channel-type="ChannelType.Email"
            :portfolio="formState.selectedCampaign"
            :hiddenIdentifier="channelCustomerId"
            label="Selecciona el identificador:"
          />

          <!-- <a-form-item>
            <a-tooltip placement="right">
              <template #title> Identificador </template>
              Selecciona el identificador: <InfoCircleOutlined />
            </a-tooltip>
            <a-select
:getPopupContainer="triggerNode => triggerNode.parentNode"              v-model:value="formState.selectedIdentifier"
              :options="identifiers"
              placeholder="Seleccionar identificador"
              :disabled="!formState.selectedCampaign"
            ></a-select>
          </a-form-item> -->
          <div class="footer">
            <a-button
              size="medium"
              :loading="loading"
              :disabled="enableTransferButton()"
              @click="handleSuccess"
            >
              {{ type != "move" ? "Transferir" : "Mover correo" }}
            </a-button>
          </div>
        </a-form>
      </div>
    </a-modal>
  </div>
</template>
<script lang="ts" setup>
import { ref, reactive, watch, computed } from "vue";
import { message } from "ant-design-vue";

import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import IdentifierPicker from "@/app/shared/components/pickers/IdentifierPicker.vue";
import { ChannelType } from "@/app/shared/utils/enums";
import { emailService } from "@/app/emails/services/Email.service";

const props = defineProps({
  visible: { type: Boolean, default: false },
  title: { type: String, required: true },
  type: { type: String, required: true },
  campaignDefault: { type: Number, required: false, default: null },
  selectedThreads: { type: Array, required: false, default: () => [] },
  selectedUser: { type: Number, default: null },
  selectedUserObject: { type: Object, default: null },
  channelCustomerId: { type: Number, default: undefined },
});

const emit = defineEmits<{
  "update:visible": [visible: boolean];
  update: [identifier: number | undefined];
  handleOk: [identifier: number | undefined];
}>();

const formState = reactive({
  selectedCampaign: ref(props.campaignDefault),
  selectedIdentifier: ref<number | undefined>(undefined),
});
const loading = ref(false);

const campaigns = ref([]);

const campaignSelected = computed(() => {
  return campaigns.value?.find((el) => el.id == formState.selectedCampaign);
});

const setPortfolios = (value) => {
  campaigns.value = value;
  // console.log(value, "set portfolios");
};

const selectCampaign = () => {
  formState.selectedIdentifier = undefined;
};

const handleOk = () => {
  emit("update:visible", false);
};
const handleClose = () => {
  emit("update:visible", false);
};

watch(
  () => props.campaignDefault,
  (newValue: any) => {
    formState.selectedCampaign = newValue;
  }
);

const handleSuccess = async () => {
  loading.value = true;
  if (props.type === "move") {
    await emailService.transferThreadCampaign({
      email_threads: props.selectedThreads.map((r: any) => r.key),
      channel_customer: formState.selectedIdentifier,
    });
    emit("update", formState.selectedIdentifier);
    message.success("Se ha movido el correo con éxito");

    handleClose();
  } else if (props.type === "user" || props.type === "campaign") {
    if (props.type === "user") {
      await emailService.transferThreadUser(props.selectedUser, {
        email_threads: props.selectedThreads.map((r: any) => r.key),
        channel_customer: formState.selectedIdentifier,
      });
      emit("handleOk", formState.selectedIdentifier);
    } else {
      await emailService.transferThreadCampaign({
        email_threads: props.selectedThreads.map((r: any) => r.key),
        channel_customer: formState.selectedIdentifier,
      });
      emit("update", formState.selectedIdentifier);
    }
    message.success("Se ha transferido el correo con éxito");
    handleClose();
  }
};
const enableTransferButton = () => {
  if (formState.selectedCampaign && formState.selectedIdentifier) return false;
  return true;
};
</script>
<style lang="sass" scoped>
.ant-form-item
  margin-bottom: 16px

.content
  padding: 15px 0
  .form
    p
      margin: 5px 0
    .footer
      padding: 17px 0 0
      display: flex
      justify-content: center
      button
        width: 200px

.entity-name
  color: $text-tertiary
  margin-top: 4px
  margin-bottom: 20px !important

.divider
  margin: 10px 0
</style>
