<template>
  <section>
    <p class="paragraph">
      Puedes agregar o editar información acorde a los permisos configurados.
      <a @click="handleOpenEditInformation()"><span>Editar informacion</span></a>
    </p>
    <edit-information-contact-drawer
      :visible="isEditInformationVisible"
      @onCloseDrawerEdit="handleCloseDrawer"
      :itemId="itemId"
    />
    <!-- <a-table
      row-key="id"
      size="middle"
      :columns="columnsTable"
      :data-source="[personalData]"
      :scroll="{ x: 700, x: 1300 }"
      :pagination="false"
    >
    </a-table> -->

    <!-- {{  personalData }} -->
    <CustomScrollbar height="calc(100vh - 390px)">
      <div class="table">
        <div class="row" v-for="row in columnsTable" :key="row.key">
          <div class="cell title">{{ row.title }}</div>
          <div class="cell value">{{ personalData[row.key] }}</div>
        </div>
      </div>
    </CustomScrollbar>
  </section>
</template>

<script>
import EditInformationContactDrawer from "@/app/contacts/components/drawers/EditInformationContactDrawer.vue";
import { DocumentType } from "@/app/shared/utils/enums";
import { humanDateWithSlashFormat } from "@/app/shared/utils/constants";
import { dateToString } from "@/app/shared/utils/dates";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

export default {
  components: {
    EditInformationContactDrawer,
    CustomScrollbar,
  },
  props: {
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  data() {
    return {
      isEditInformationVisible: false,
    };
  },
  watch: {
    itemId(value) {
      if (value) {
        this.$store.dispatch("fetchCustomerPersonalField", value);
        this.$store.dispatch("fetchCustomerPersonalData", value);
      }
    },
  },
  computed: {
    informationClient() {
      return this.$store.getters["getPersonalInformation"];
    },
    personalData() {
      const data = this.$store.getters["getCustomerPersonalData"];

      const formattedTypeDocument = this.typeDocument(Number(data.type_document));

      return { ...data, type_document: formattedTypeDocument };
    },
    columnsTable() {
      const columns = this.informationClient.map((col) => ({
        title: col.title == "code" ? "Código" : col.title,
        dataIndex: col.index,
        key: col.index,
      }));
      const index = columns.findIndex((item) => {
        return item.key === "id";
      });
      columns.splice(index, 1);
      return columns;
    },
  },
  created() {
    this.$store.dispatch("fetchCustomerPersonalField", this.itemId);
    this.$store.dispatch("fetchCustomerPersonalData", this.itemId);
  },
  methods: {
    handleCloseDrawer() {
      this.isEditInformationVisible = false;
    },
    handleOpenEditInformation() {
      this.isEditInformationVisible = true;
    },
    typeDocument(text) {
      if (text == DocumentType.dni) return "DNI";
      if (text == DocumentType.ce) return "Carnet de Extranjeria";
      if (text == DocumentType.pasaporte) return "Pasaporte";
      if (text == DocumentType.ruc) return "RUC";

      return "";
    },
    typeFormat(date) {
      return dateToString(date, humanDateWithSlashFormat);
    },
  },
};
</script>

<style lang="sass" scoped>
.paragraph
  display: flex
  justify-content: space-between
  margin: 0 0 8px 0
  color: $gray-7

:deep(.ant-table-thead > tr > th, .ant-table-tbody > tr > td)
  overflow-wrap: initial
  width: 11em

.table
  width: 100%
  border-bottom: 1px solid $gray-4

  .row
    width: 100%
    display: flex

    .cell
      width: 50%
      border-top: 1px solid $gray-4
      padding: 8px
      display: flex
      align-items: center

  .title
    background-color: $gray-2
    color: $gray-9
    font-weight: 600

  .value
    color: $gray-8
</style>
