import { Commit } from "vuex";
import { emailManagerService, FilterEmailManager } from "../../services";

import {
  SET_TABLE_PAGE,
  SET_BASIC_FILTERS,
  SET_EMAILS_ADVANCED_FILTERS,
  SET_EMAILS,
  SET_TAG_TO_EMAILS,
  SET_ADVANCED_FILTERS_DATA,
} from "./mutation-types";

const actions = {
  async getAllEmailsManager({ commit }: { commit: Commit }, params: FilterEmailManager) {
    const data = await emailManagerService.getAllEmailsManager(params);

    commit(SET_EMAILS, data);
  },
  setTablePage({ commit }: { commit: Commit }, tableEmailsPage: any) {
    commit(SET_TABLE_PAGE, tableEmailsPage);
  },
  setBasicFilters({ commit }: { commit: Commit }, basicFilters: any) {
    commit(SET_BASIC_FILTERS, basicFilters);
  },
  setAdvancedFilters({ commit }: { commit: Commit }, emailsAdvancedFilters: any) {
    commit(SET_EMAILS_ADVANCED_FILTERS, emailsAdvancedFilters);
  },
  async fetchEmailsAdvancedFiltersData({ commit }: { commit: Commit }, params: any) {
    console.log(params.basicFilters, "basic filters");
    const data = await emailManagerService.fetchEmailsAdvancedFiltersData(params.basicFilters);
    const data2 = await emailManagerService.getIdentifiers();
    console.log(data, "advanced filters 1");
    console.log(data2, "advanced filters 2");
    commit(SET_ADVANCED_FILTERS_DATA, { ...data, identifiers: data2 });
  },
  async tagEmails({ commit }: { commit: Commit }, payload: any) {
    const { ticketsId, tagId } = payload;

    const [tagDetails] = await Promise.all([
      emailManagerService.getTagEmailsDetails(tagId),
      emailManagerService.tagEmails(payload),
    ]);

    commit(SET_TAG_TO_EMAILS, { ticketsId, tagDetails });
  },
};

export default actions;
