export default [
  {
    path: "/operations",
    name: "operations",
    component: () =>
      import(/* webpackChunkName: "operations-page" */ "@/app/entities/pages/EntitiesPage.vue"),
    redirect: "/operations/board",
    children: [
      {
        path: "board",
        component: () =>
          import(
            /* webpackChunkName: "entities" */ "@/app/entities/components/templates/EntitiesTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "campaigns",
        component: () =>
          import(
            /* webpackChunkName: "campaigns" */ "@/app/entities/components/templates/CampaignsTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "campaigns/:id",
        component: () =>
          import(
            /* webpackChunkName: "typifications" */ "@/app/entities/components/templates/TypificationsTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "bases",
        component: () =>
          import(
            /* webpackChunkName: "bases" */ "@/app/entities/components/templates/BasesTemplate.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "strategies",
        component: () =>
          import(/* webpackChunkName: "strategies" */ "@/app/strategies/pages/StrategiesPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "strategies/filter",
        component: () =>
          import(
            /* webpackChunkName: "strategies-filter" */ "@/app/strategies/pages/StrategiesFilterPage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "strategies/template",
        component: () =>
          import(
            /* webpackChunkName: "strategies-template" */ "@/app/strategies/pages/StrategiesTemplatePage.vue"
          ),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "queues",
        component: () =>
          import(/* webpackChunkName: "queue-page" */ "@/app/queues/pages/QueuesPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "forms",
        redirect: "/flows/forms",
        // component: FormsTemplate,
        // meta: {
        //   requiresAuth: true,
        // },
      },
      {
        path: "forms/:id",
        redirect: (to) => ({ path: `/flows/forms/${to.params.id}` }),
        // component: CreateFormTemplate,
        // meta: {
        //   requiresAuth: true,
        // },
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];
