<template>
  <a-modal
    :open="open"
    :title="`Bloquear número para el canal de ${BlackListTabName[blackListTabSelection]}`"
    @cancel="handleCancel"
    width="550px"
    class="settings__block-modal"
  >
    <div class="information">
      <p>
        Completa los campos requeridos. No se notificará del bloqueo al propietario del teléfono.
      </p>
      <a-form
        class="form form__appeal"
        layout="vertical"
        ref="formRef"
        :model="formState"
        :rules="rules"
      >
        <a-row :gutter="24">
          <a-col :span="24">
            <a-form-item
              name="phone_number"
              :class="getClass(BlackListTab.Sms === blackListTabSelection)"
            >
              <span class="required">*</span>
              <span>Número telefónico</span>
              <a-row :gutter="24">
                <a-col span="7">
                  <SelectorCountry
                    v-model:value="formState.country"
                    @codePhone="setCodePhone"
                    :disabled="props.blockNumber"
                  />
                </a-col>
                <a-col span="17">
                  <a-input
                    v-model:value="formState.phone_number"
                    placeholder="Ingresar número de teléfono"
                    @blur="refreshPortfolios"
                    :maxlength="formState.country === 1 ? 9 : 100"
                    @keypress="handleNumberPhone($event)"
                    @input="handleChangeNumber"
                    :class="{ 'background-red': phoneMax }"
                    :disabled="props.blockNumber"
                  />
                </a-col>
              </a-row>
            </a-form-item>
            <p v-if="BlackListTab.Sms === blackListTabSelection">
              El tipo de bloqueo para el canal de SMS será únicamente de tipo saliente. Los usuarios
              no se podrán comunicar con estos números.
            </p>
            <a-form-item name="blocked_type" v-if="BlackListTab.Sms !== blackListTabSelection">
              <span class="required">*</span>
              <span>Tipo de bloqueo</span>
              <a-select
                v-model:value="formState.blocked_type"
                :options="[...TypeBlockOptions]"
                placeholder="Seleccionar tipo de bloqueo"
                @change="handleChangeBlockedType(formState.blocked_type)"
                :disabled="BlackListTab.Voice === blackListTabSelection"
              />
            </a-form-item>
            <!-- <a-form-item
              v-if="blackListTabSelection != BlackListTab.Whatsapp"
              has-feedback
              name="portfolios"
              style="margin-bottom: 0"
            > -->
            <CampaignPicker
              v-model:value="formState.portfolios"
              ref="campaignRef"
              v-if="blackListTabSelection != BlackListTab.Whatsapp"
              :form-name="'portfolios'"
              :required="!handleRequiredBlockedType(formState.blocked_type)"
              multiple
              with-out-entity
              default-behavior
              :show-all="false"
              with-name
              :disabled="
                handleRequiredBlockedType(formState.blocked_type) ||
                !formState.country ||
                !formState.phone_number
              "
              :blacklist-campaing="{
                blacklist_phone: formState.phone_number,
                blacklist_code_phone: formState.code_phone,
                blacklist_channel: BlackListTabChannelName[blackListTabSelection],
                blacklist_blocked_type: formState.blocked_type,
              }"
              :blacklist-exceptions="blockNumber?.portfolios?.map((el: any) => el.id) || []"
            >
              <template #label>
                <div>
                  <span class="required">*</span>
                  <span>Campañas restringidas</span>
                  <div v-if="blackListTabSelection == BlackListTab.Voice">
                    <p>
                      Selecciona las campañas por las que no deseas recibir ni generar llamadas del
                      número a bloquear.
                      <span class="BoldWord"> Solo aplica para llamadas Saliente.</span>
                    </p>
                  </div>
                  <div v-else>
                    <p>
                      Selecciona las campañas por las que no deseas que se envíen mensajes de SMS.
                    </p>
                  </div>
                </div>
              </template>
            </CampaignPicker>
            <!-- </a-form-item> -->

            <IdentifierPicker
              v-model:value="formState.channels_customers"
              v-else
              multiple
              name="channels_customers"
              :show-all="false"
              :hiddenLabel="true"
              :channel-type="ChannelType.Whatsapp"
              :alias-with-associate="true"
              :disabled="!formState.country || !formState.phone_number || !formState.blocked_type"
              :blacklist-identifiers="{
                blacklist_phone: formState.phone_number ?? '',
                blacklist_code_phone: formState.code_phone ?? '',
                blacklist_blocked_type: formState.blocked_type ?? '',
              }"
              :blacklist-exceptions="blockNumber?.channels_customers?.map((el: any) => el.id) || []"
            >
              <template #label>
                <div>
                  <span class="required">*</span>
                  <span>Identificadores restringidos</span>
                  <div>
                    <p>
                      Selecciona los identificadores de WhatsApp por los que no deseas recibir ni
                      enviar mensajes del número a bloquear.
                    </p>
                  </div>
                </div>
              </template>
            </IdentifierPicker>
            <!-- <a-form-item v-else has-feedback name="channels_customers">
              
            </a-form-item> -->
            <a-form-item label="Motivo del bloqueo" name="observations">
              <a-textarea
                v-model:value="formState.observations"
                placeholder="Escribir aquí..."
                :maxLength="maxTextCountObservation"
              ></a-textarea>
              <span class="counter">
                {{ observationTextCount }}/ {{ maxTextCountObservation }}
              </span>
            </a-form-item>
          </a-col>
        </a-row>
      </a-form>
    </div>
    <template #footer>
      <div class="footer">
        <div>
          <a-button @click="handleConfirmationClose">Cancelar</a-button>
        </div>
        <div>
          <a-popover placement="topRight" v-if="showPopoverValidate">
            <template #content>
              <span>
                Completa todos los campos obligatorios <br />
                para habilitar esta acción.
              </span>
            </template>
            <a-button type="primary" @click="handleSave" :disabled="disabledButton">
              {{ blockNumber ? "Guardar cambios" : "Bloquear número" }}
            </a-button>
          </a-popover>
          <a-button type="primary" @click="handleSave" :disabled="disabledButton" v-else>
            {{ blockNumber ? "Guardar cambios" : "Bloquear número" }}
          </a-button>
        </div>
      </div>
    </template>
  </a-modal>
</template>
<script lang="ts" setup>
import { QuestionCircleOutlined } from "@ant-design/icons-vue";
import { computed, createVNode, ref, watch } from "vue";
import SelectorCountry from "@/app/shared/components/selectors/SelectorCountry.vue";
import { Modal, message } from "ant-design-vue";
import {
  BlackListTabName,
  BlackListTab,
  BlackListTabChannelId,
  BlockedType,
  BlockedTypeVoiceName,
  BlockedTypeWhatsappName,
  BlackListTabChannelName,
} from "@/app/platformSettings/utils/enums";
import CampaignPicker from "@/app/shared/components/pickers/CampaignPicker.vue";
import IdentifierPicker from "@/app/shared/components/pickers/IdentifierPicker.vue";
import { ChannelType } from "@/app/shared/utils/enums";
import { blackListService } from "@/app/platformSettings/services/BlackListService";
import { IBlockNumber } from "@/@types/platformSettings/blacklist";
import { isEqual } from "lodash";

const props = defineProps<{ open: boolean; blackListTabSelection: number; blockNumber: any }>();

const emit = defineEmits<{ "update:open": [open: boolean]; fetchData: [] }>();
const maxTextCountObservation = 500;

const formRef = ref();
const discard = ref(false);
const disabledButton = ref(true);
const showPopoverValidate = ref(true);

const formState = ref<IBlockNumber>({
  country: props.blockNumber?.country?.id || null,
  code_phone: props.blockNumber?.country?.code_phone || null,
  phone_number: props.blockNumber?.phone_number || null,
  observations: props.blockNumber?.observations || "",
  portfolios: props.blockNumber?.portfolios?.map((el: { id: any }) => el.id) || [],
  channels_customers: props.blockNumber?.channels_customers?.map((el: { id: any }) => el.id) || [],
  blocked_type:
    props.blockNumber?.blocked_type || BlackListTab.Voice === props.blackListTabSelection
      ? BlockedType.Outgoing
      : null,
});

const TypeBlockOptions = [
  {
    value: BlockedType.Incoming,
    label:
      props.blackListTabSelection == BlackListTab.Voice
        ? BlockedTypeVoiceName[BlockedType.Incoming]
        : BlockedTypeWhatsappName[BlockedType.Incoming],
  },
  {
    value: BlockedType.Outgoing,
    label:
      props.blackListTabSelection == BlackListTab.Voice
        ? BlockedTypeVoiceName[BlockedType.Outgoing]
        : BlockedTypeWhatsappName[BlockedType.Outgoing],
  },
  {
    value: BlockedType.Both,
    label:
      props.blackListTabSelection == BlackListTab.Voice
        ? BlockedTypeVoiceName[BlockedType.Both]
        : BlockedTypeWhatsappName[BlockedType.Both],
  },
];

const initialFormState = { ...formState.value };

// const rules = {
//   // observations: [{ required: true, message: "Campo requerido" }],
// };
const rules_phone_number = [
  {
    validator: (rule, value) => {
      if (!formState.value.country || !value) {
        return Promise.reject("");
      }
      return Promise.resolve();
    },
  },
];
const rules = computed(() => {
  if (props.blackListTabSelection == BlackListTab.Voice) {
    return {
      phone_number: rules_phone_number,
      portfolios: [{ required: true, message: "" }],
      blocked_type: [{ required: true, message: "" }],
    };
  } else if (props.blackListTabSelection == BlackListTab.Whatsapp) {
    return {
      phone_number: rules_phone_number,
      channels_customers: [{ required: true, message: "" }],
      blocked_type: [{ required: true, message: "" }],
    };
  } else {
    return {
      phone_number: rules_phone_number,
      portfolios: [{ required: true, message: "" }],
    };
  }
});

const resetFormState = () => {
  formState.value = {
    country: props.blockNumber?.country?.id || null,
    code_phone: props.blockNumber?.country?.code_phone || null,
    phone_number: props.blockNumber?.phone_number || null,
    observations: props.blockNumber?.observations || "",
    portfolios: props.blockNumber?.portfolios?.map((el: { id: any }) => el.id) || [],
    channels_customers:
      props.blockNumber?.channels_customers?.map((el: { id: any }) => el.id) || [],
    // channels_customers: props.blockNumber?.channels_customers || [],
    blocked_type:
      props.blockNumber?.blocked_type || BlackListTab.Voice === props.blackListTabSelection
        ? BlockedType.Outgoing
        : null,
  };
};

const handleCancel = () => {
  if (!props.blockNumber) {
    if (discard.value) {
      resetFormState();
      discard.value = false;
    }
  } else {
    resetFormState();
  }
  emit("update:open", false);
};
const handleConfirmationClose = () => {
  if (props.blackListTabSelection) {
    Modal.confirm({
      title: `¿Quieres descartar los cambios realizados?`,
      content: "Recuerda que los campos que completaste se perderán.",
      okText: "Sí, descartar",
      cancelText: "No, conservar",
      centered: true,
      onOk: () => {
        discard.value = true;
        handleCancel();
      },
    });
  }
};

const handleNumberPhone = (e: { keyCode: number; preventDefault: () => void }) => {
  const char = String.fromCharCode(e.keyCode);
  if (/^[0-9]+$/.test(char)) return true;
  else e.preventDefault();
};

const handleChangeBlockedType = (blocked_type: any) => {
  if (blocked_type === BlockedType.Incoming && props.blackListTabSelection == BlackListTab.Voice) {
    formState.value.portfolios = [];
  }
};

const handleRequiredBlockedType = (blocked_type: any) => {
  return (
    (blocked_type === BlockedType.Incoming || blocked_type === null) &&
    props.blackListTabSelection == BlackListTab.Voice
  );
};

const handleChangeNumber = () => {
  // if (this.formState2.phone_number.length < 9 && this.code_phone === 1) {
  //   this.phoneMax = true;
  // } else {
  //   this.phoneMax = false;
  // }
};

const handleSave = async () => {
  await formRef.value?.validate();
  if (props.blackListTabSelection) {
    Modal.confirm({
      icon: createVNode(QuestionCircleOutlined),
      title: `¿Estás seguro de bloquear el número ${formState.value.phone_number}?`,
      content:
        "Recuerda que los números bloqueados no podrán comunicarse o ser comunicados por tu empresa.",
      okText: "Bloquear",
      cancelText: "Cancelar",
      centered: true,
      onOk: async () => {
        try {
          if (formState.value.observations.trim() === "") {
            delete formState.value.observations;
          }
          if (
            Array.isArray(formState.value.portfolios) &&
            formState.value.portfolios.length === 0
          ) {
            delete formState.value.portfolios;
          }
          if (
            Array.isArray(formState.value.channels_customers) &&
            formState.value.channels_customers.length === 0
          ) {
            delete formState.value.channels_customers;
          }
          if (!props.blockNumber)
            await blackListService.saveBlockNumber(
              formState.value,
              BlackListTabChannelId[props.blackListTabSelection]
            );
          else
            await blackListService.editBlockNumber(
              props.blockNumber.id,
              formState.value,
              BlackListTabChannelId[props.blackListTabSelection]
            );
          emit("update:open", false);
          message.success("Número bloqueado correctamente");

          emit("fetchData");

          resetForm();
        } catch (error) {
          message.error("Ha ocurrido un error");
          console.error(error);
        }
      },
    });
  }
};

const campaignRef = ref();

const setCodePhone = async (value: string) => {
  console.log("setCodePhone");
  formState.value.code_phone = value;
  // await nextTick();
  // await campaignRef.value.fetchPortfolios();
};

const refreshPortfolios = async () => {
  formState.value.portfolios = [];
  // await campaignRef.value.fetchPortfolios();
};

const getClass = (IsSms: Boolean) => {
  const initial = IsSms ? "first-item" : "";
  return initial;
};

const resetForm = () => {
  Object.assign(formState.value, initialFormState);
};

const observationTextCount = computed(() => formState.value.observations?.length);

watch(
  () => props.blockNumber,
  (value) => {
    formState.value = {
      country: value?.country?.id || null,
      code_phone: value?.country?.code_phone || null,
      phone_number: value?.phone_number || null,
      observations: value?.observations || "",
      portfolios: value?.portfolios?.map((el: { id: any }) => el.id) || [],
      channels_customers: value?.channels_customers?.map((el: { id: any }) => el.id) || [],
      blocked_type: value?.blocked_type || null,
    };
  }
);

watch(
  () => formState.value,
  async () => {
    await formRef.value
      ?.validate()
      .then(() => {
        if (!isEqual(initialFormState, formState.value)) disabledButton.value = false;
        showPopoverValidate.value = false;
      })
      .catch(() => {
        if (!isEqual(initialFormState, formState.value)) disabledButton.value = true;
        showPopoverValidate.value = true;
      });
  },
  { immediate: true, deep: true }
);
</script>
<style lang="sass" scoped>
.settings__block-modal
  .information
    padding: 20px 20px 0px 20px
  .footer
    display: flex
    justify-content: flex-end
    gap: 10px
    margin-top: 12px !important
.BoldWord
  font-weight: bold
.required
  display: inline-block
  margin-inline-end: 4px
  color: #ff4d4f
  font-size: 14px
  font-family: SimSun, sans-serif
  line-height: 1
.first-item
  margin-bottom: 0px !important
.counter
  position: absolute !important
  right: 12px
  bottom: -20px
  font-size: $font-small
  color: $gray-6
// :deep(.ant-form-item .ant-form-item-label > label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before)
//   content: "" !important
</style>
