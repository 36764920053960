const getters = {
  getTotalTypificationsPerFormId(state) {
    const resultsFormIds = state.results.map((result) => {
      const resultFormId = result.form_id;

      const motiveAndSubmotiveFormId = result.motives.map((motive) => {
        const motiveFormId = motive.form_id;
        const submotiveFormIds = motive.submotives.map((submotive) => submotive.form_id);

        return [motiveFormId, ...submotiveFormIds];
      });

      return [resultFormId, ...motiveAndSubmotiveFormId];
    });

    const flattedFormIds = resultsFormIds.flat(2).filter((id) => id);

    let typificationPerFormId = {};

    flattedFormIds.forEach((id) => {
      typificationPerFormId[id]
        ? (typificationPerFormId[id] += 1)
        : (typificationPerFormId[id] = 1);
    });

    return typificationPerFormId;
  },
};

export default getters;
