function mapArrayToIds(array) {
  const mapped = array.map((item) => item.id);
  return clearArray(mapped);
}

function mapUsers(supervisors, agents) {
  supervisors = mapArrayToIds(supervisors);
  agents = mapArrayToIds(agents);
  return [...supervisors, ...agents];
}

export function mapQueue(queue) {
  return {
    name: queue.name,
    channels_customer: mapArrayToIds(queue.channels_customer),
    portfolios: mapArrayToIds(queue.portfolios),
    users: mapUsers(queue.supervisors, queue.agents),
    level_service: queue.level_service,
    waiting_threshold: queue.waiting_threshold,
    allowed_queues: queue.allowed_queues,
    distribution_type: queue.distribution_type,
    max_tickets_agents: queue.max_tickets_agents,
    waiting_file_audio: queue.waiting_file_audio,
    waiting_file_audio_url: queue.waiting_file_audio_url,
    whatsapp_template: queue.whatsapp_template,
    distribution_time: queue.distribution_time,
    enable_custom_ticket_distribution: queue.enable_custom_ticket_distribution,
    tickets_to_distribute: queue.tickets_to_distribute,
  };
}

export function clearArray(array) {
  array = array.filter((item) => item !== null);
  const set = new Set(array);
  return [...set];
}

export function groupChannels(data) {
  const newData = [];
  data.forEach((record) => {
    const recordModified = record;
    const grouped = groupBy(record.channels_customer, (channel) => channel.channel.name);
    recordModified.channels = [];
    if (grouped.get("Messenger")) {
      recordModified.channels.push(grouped.get("Messenger"));
    }
    if (grouped.get("WhatsApp")) {
      recordModified.channels.push(grouped.get("WhatsApp"));
    }
    if (grouped.get("Chat Inbound")) {
      recordModified.channels.push(grouped.get("Chat Inbound"));
    }
    if (grouped.get("Telefonica")) {
      recordModified.channels.push(grouped.get("Telefonica"));
    }
    if (grouped.get("SMS")) {
      recordModified.channels.push(grouped.get("SMS"));
    }
    newData.push(recordModified);
  });
  return newData;
}

export function groupBy(list, keyGetter) {
  const map = new Map();
  list.forEach((item) => {
    const key = keyGetter(item);
    const collection = map.get(key);
    if (!collection) {
      map.set(key, [item]);
    } else {
      collection.push(item);
    }
  });
  return map;
}
