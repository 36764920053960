export const ADD_TOKEN = "ADD_TOKEN";
export const SET_TEMPORAL_USER = "SET_TEMPORAL_USER";
export const REMOVE_TEMPORAL_USER = "REMOVE_TEMPORAL_USER";
export const ADD_PROPERTIES_TO_CUSTOMER = "ADD_PROPERTIES_TO_CUSTOMER";
export const SET_PROGRESS = "SET_PROGRESS";
export const SET_NEW_USER_INVITE = "SET_NEW_USER_INVITE";
export const ADD_PROPERTIES_TO_NEW_USER = "ADD_PROPERTIES_TO_NEW_USER";
export const ADD_TOKEN_NEW_USER = "ADD_TOKEN_NEW_USER";
export const SET_ZONE_TIME = "SET_ZONE_TIME";
export const SET_TEMPORAL_FORM = "SET_TEMPORAL_FORM";
export const SET_REGISTER_STEP = "SET_REGISTER_STEP";
export const SET_REGISTER_WORKSPACE_STEP = "SET_REGISTER_WORKSPACE_STEP";
