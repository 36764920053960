import LoginPage from "@/app/auth/pages/LoginPage.vue";
import RecoveryPage from "@/app/auth/pages/RecoveryPage.vue";
import PasswordReset from "@/app/auth/components/organisms/PasswordReset.vue";
import ScoreCloud from "@/app/auth/pages/ScoreCloud.vue";

export default [
  {
    path: "/home",
    name: "home",
    component: ScoreCloud,
    meta: {
      requiresAuth: false,
      layout: "empty",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginPage,
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  // {
  //   path: '/login/:workspace',
  //   name: 'old login',
  //   component: LoginPage,
  //   meta: {
  //     requiresAuth: false,
  //     layout: 'footer',
  //   },
  // },
  {
    path: "/recovery",
    redirect: "/login",
  },
  {
    path: "/recovery/:workspace",
    name: "recovery",
    component: RecoveryPage,
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  {
    path: "/reset",
    name: "reset",
    component: PasswordReset,
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  {
    path: "/dummy",
    name: "dummy",
    component: () => import(/* webpackChunkName: "dummy" */ "@/app/auth/pages/Dummy.vue"),
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
  {
    path: "/desuscripcion",
    name: "desuscripcion",
    component: () =>
      import(
        /* webpackChunkName: "dummy" */ "@/app/common/components/organisms/UnsubscriberEmail.vue"
      ),
    meta: {
      requiresAuth: false,
      layout: "footer",
    },
  },
];
