import httpManager from "@/app/shared/utils/httpManager";

/**
 * Muestra la data de la tabla bases.
 *
 * @return {Promise<Object>} Datos de la tabla bases
 */
export async function clientNewTable({ isBasesEnable = false } = {}) {
  let url = "/portfolios/";
  if (isBasesEnable) url = "/entities/portfolios/bases/";

  const response = await httpManager.get(url);
  return response?.data;
}
