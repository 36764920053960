import { initialState } from "./state";
import {
  RESET_STATE,
  ADD_ITEM_TO_TYPIFICATION,
  SET_FLAG_TREE,
  SET_PORTFOLIO_NAME,
  SET_TYPIFICATION,
  SET_BUSINESS_TYPE,
  SET_TYPIFICATION_TYPE,
  SET_SAVED,
  DELETE_TYPIFICATION_ITEM,
  UPDATE_ITEM_TO_TYPIFICATION,
  UPDATE_TYPIFICATION_ITEM_BY_STATUS,
  SET_TYPIFICATION_TREE_MUTATION_STATUS,
  SET_LAST_MODIFIED,
  SET_FORMS,
  SET_TYPIFICATION_DISABLED,
} from "./mutation-types";

const mutations = {
  [RESET_STATE](state) {
    const newState = initialState();
    Object.keys(newState).forEach((key) => {
      state[key] = newState[key];
    });
  },
  [SET_SAVED](state, data) {
    state.typificationSaved = data;
  },
  [SET_FLAG_TREE](state, typification) {
    state.isExistingTree = typification?.length > 0;
  },
  [SET_PORTFOLIO_NAME](state, name) {
    state.portfolioName = name;
  },
  [SET_BUSINESS_TYPE](state, type) {
    state.businessType = type;
  },
  [SET_TYPIFICATION_TYPE](state, typification) {
    state.typeDefaultManagement = typification;
  },

  [SET_TYPIFICATION_TREE_MUTATION_STATUS](state, status) {
    state.isTypificationTreeMutating = status;
  },

  [ADD_ITEM_TO_TYPIFICATION](state, item) {
    const currentTypificationTree = state.typification;

    if (item.type === "group") {
      state.typification = [...state.typification, item].sort(
        (itemA, itemB) => itemA.group_type - itemB.group_type
      );
    } else if (item.type === "result") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      currentTypificationTree[currentGroupIndex].children.push(item);
    } else if (item.type === "motive") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (resultItem) => resultItem.id === item.result
      );
      currentTypificationTree[currentGroupIndex].children[currentResultIndex].children.push(item);
    } else if (item.type === "submotive") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (resultItem) => resultItem.id === item.result
      );
      const currentMotiveIndex = currentTypificationTree[currentGroupIndex].children[
        currentResultIndex
      ].children.findIndex((motiveItem) => motiveItem.id === item.motive);
      currentTypificationTree[currentGroupIndex].children[currentResultIndex].children[
        currentMotiveIndex
      ].children.push(item);
    }
  },
  [UPDATE_ITEM_TO_TYPIFICATION](state, item) {
    const currentTypificationTree = state.typification;

    if (item.type === "group") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.id
      );
      currentTypificationTree[currentGroupIndex] = item;
    } else if (item.type === "result") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (resultItem) => resultItem.id === item.id
      );
      currentTypificationTree[currentGroupIndex].children[currentResultIndex] = item;
    } else if (item.type === "motive") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (resultItem) => resultItem.id === item.result
      );
      const currentMotiveIndex = currentTypificationTree[currentGroupIndex].children[
        currentResultIndex
      ].children.findIndex((motiveItem) => motiveItem.id === item.id);
      currentTypificationTree[currentGroupIndex].children[currentResultIndex].children[
        currentMotiveIndex
      ] = item;
    } else if (item.type === "submotive") {
      const currentGroupIndex = currentTypificationTree.findIndex(
        (groupItem) => groupItem.id === item.group
      );
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (resultItem) => resultItem.id === item.result
      );
      const currentMotiveIndex = currentTypificationTree[currentGroupIndex].children[
        currentResultIndex
      ].children.findIndex((motiveItem) => motiveItem.id === item.motive);
      const currentSubMotiveIndex = currentTypificationTree[currentGroupIndex].children[
        currentResultIndex
      ].children[currentMotiveIndex].children.findIndex(
        (submotiveItem) => submotiveItem.id === item.id
      );
      currentTypificationTree[currentGroupIndex].children[currentResultIndex].children[
        currentMotiveIndex
      ].children[currentSubMotiveIndex] = item;
    }
    console.log("tree update", currentTypificationTree);
  },
  [SET_TYPIFICATION](state, typification = []) {
    state.typification = typification.sort((itemA, itemB) => itemA.group_type - itemB.group_type);
  },
  [DELETE_TYPIFICATION_ITEM](state, { groupId, resultId, motiveId, submotiveId }) {
    const currentTypificationTree = state.typification;
    const currentGroupIndex = currentTypificationTree.findIndex((item) => item.id === groupId);

    if (resultId) {
      const currentResultIndex = currentTypificationTree[currentGroupIndex].children.findIndex(
        (item) => item.id === resultId
      );

      if (motiveId) {
        const currentMotiveIndex = currentTypificationTree[currentGroupIndex].children[
          currentResultIndex
        ].children.findIndex((item) => item.id === motiveId);

        if (submotiveId) {
          const currentSubmotiveIndex = currentTypificationTree[currentGroupIndex].children[
            currentResultIndex
          ].children[currentMotiveIndex].children.findIndex((item) => item.id === submotiveId);

          currentTypificationTree[currentGroupIndex].children[currentResultIndex].children[
            currentMotiveIndex
          ].children.splice(currentSubmotiveIndex, 1);
        } else {
          currentTypificationTree[currentGroupIndex].children[currentResultIndex].children.splice(
            currentMotiveIndex,
            1
          );
        }
      } else {
        currentTypificationTree[currentGroupIndex].children.splice(currentResultIndex, 1);
      }
    } else {
      currentTypificationTree.splice(currentGroupIndex, 1);
    }
  },
  [UPDATE_TYPIFICATION_ITEM_BY_STATUS](state, data) {
    state.typificationSaved = data;
  },
  [SET_LAST_MODIFIED](state, data) {
    state.lastModification = data;
  },
  [SET_FORMS](state, data) {
    state.forms = data;
  },
  [SET_TYPIFICATION_DISABLED](state, data) {
    state.typificationDisabled = data;
  },
};

export default mutations;
