import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener colas.
 *
 *  @param {Object} tickets Los tickets seleccionados.
 *
 * @return {Promise<Array>} Lista de colas.
 */
export async function fetchListQueues(tickets) {
  const url = `/tickets/queues/`;
  const response = await httpManager.post(url, tickets);
  return response?.data;
}
