import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los codecs registrados.
 *
 * @return {Promise<Array>} Lista de codecs.
 */
export default async function () {
  const response = await httpManager.get("/pbx/codecs/");
  return response?.data?.results;
}
