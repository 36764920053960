import httpManager from "../../../../shared/utils/httpManager";

/**
 * Obtener contacto.
 *
 * @param {Number} id
 * @return {Promise<Object>} Contacto
 */

export async function fetchPhoneContact(id) {
  const url = `/contacts/clients/${id}/phones/`;
  const response = await httpManager.get(url);
  return response?.data;
}
