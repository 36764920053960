<template>
  <div class="email__message">
    <SubjectCard
      v-if="data && isMailbox"
      :isAssigned="isAssigned"
      :message="messages?.length > 0 ? messages[0].elementEmail : null"
      :selectedRows="[data]"
      :currentEmailId="currentEmailId"
      @update="handleRefreshThread"
      @close="close"
    />
    <div :class="isMailbox ? 'scroll scroll_mailbox' : 'scroll'" id="container-email-thread">
      <div
        v-if="!data?.details"
        class="container--loading display-flex direction-column align--center"
      >
        <a-spin />
        <p class="mrg-top-12">Cargando datos</p>
      </div>
      <div v-else>
        <div v-for="(item, pos) of messages" :key="pos">
          <EmailMessageCard
            v-if="item.type == ThreadDetailType.Email"
            :isAssigned="isAssigned"
            @handleNoteEvent="handleNoteEvent"
            @emitNoteEvent="handleNoteEvent"
            :data="item.elementEmail"
            :pos="pos"
            :multiple="false"
            :showContent="item.elementEmail?.show ?? false"
            @emitShowEmailEvent="handleShowEmail"
            @refreshThread="handleRefreshThread"
            :isMailbox="isMailbox"
          />
          <UserActionMessageCard v-else :data="item.elementLog" />
          <!-- :showContent="item.show"
          @emitShowEmailEvent="handleShowEmail" -->
          <!-- {{ :showContent="item.show" }} -->
          <!-- <UserActionMessageCard class="thread" v-if="item.hasActionMessage" /> -->
        </div>
      </div>
      <!-- <div class="single" v-else>
        <EmailMessageCard
          :isAssigned="isAssigned"
          @handleNoteEvent="handleNoteEvent"
          @emitNoteEvent="handleNoteEvent"
          :data="data?.[0]"
          :showContent="true"
          :multiple="false"
        />
      </div> -->
      <!-- <NoteCard :show="show" @emitNoteEvent="handleNoteEvent" :isAssigned="isAssigned" /> -->
    </div>
  </div>
</template>
<script lang="ts" setup>
import { ref, watch, onMounted, computed, inject, provide, Ref } from "vue";
import SubjectCard from "../cards/SubjectCard.vue";
import EmailMessageCard from "../cards/EmailMessageCard.vue";
import UserActionMessageCard from "@/app/emails/components/cards/UserActionMessageCard.vue";
import { IMenutem } from "@/@types/emails";
import { ThreadDetailType, ThreadDetails } from "@/@types/emails";
import { emailService } from "@/app/emails/services/Email.service";
import { useStore } from "vuex";
import { UserData } from "@/@types/global";
import { useRoute } from "vue-router";

const props = withDefaults(
  defineProps<{
    currentEmailId: number | undefined;
    isMailbox?: boolean;
    resolveSelected?: boolean;
    readBefore: boolean;
  }>(),
  {
    isMailbox: true,
    resolveSelected: false,
    readBefore: null,
  }
);

const emit = defineEmits<{ update: []; close }>();

const store = useStore();

const messages = ref<ThreadDetails[]>([]);
const show = ref(false);
const isGrouped = ref(false);

// const isAssigned = ref(false);
const data = ref(null);
const route = useRoute();
const user = computed<UserData | undefined>(() => store.getters["getDataUser"]);
const isAssigned = inject("isAssigned") as Ref<boolean>;

// const items = ref<IMenutem[]>([]);
// const menuItems = inject("menuItems") as Ref<IMenutem[]>;
// provide("menuItems", items);

// const items = ref<IMenutem[]>([]);
// provide("menuItems", items);

watch(
  () => store.getters.getThreadActual,
  async (newData) => {
    console.log(newData, "new data");
    // messages.value = (newData as EmailDetails[]).map((item) => ({ ...item, show: false }));
    data.value = newData;
    if (newData.details) {
      messages.value = (newData.details as ThreadDetails[]).map((item) => ({ ...item }));
      console.log(newData.assignedUser, "assigned user");
      console.log(user.value?.id, "user.value?.id");
      // isAssigned.value = newData.assignedUser == user?.value?.id ? true : false;
      if (isAssigned) {
        isAssigned.value = newData.assignedUser == user?.value?.id ? true : false;
        show.value = isAssigned.value ? false : messages.value.length > 1 ? false : true;
      }
      isGrouped.value = messages.value.length > 1;
      // console.log(newData.details, "new data details");

      // console.log(newData.details?.findIndex((el: any) => el?.show == true));
      // await nextTick();
      // handleShowEmail(newData.details?.findIndex((el: any) => el?.show == true));
    }

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    // messages.value.at(-1)!.show = true;
    // messages.value.at(0)!.show = true;
  }
);

watch(
  () => props.currentEmailId,
  async (newData) => {
    if (newData) await handleRefreshThread();
  }
);

// const messages = reactive(props.data.map((item: IEmailMessage) => ({ ...item, show: false })));

const handleNoteEvent = (message: boolean) => {
  show.value = message;
};
// const handleShowEmail = (index: number) => {
//   messages.value[index - 1].show = !messages.value[index - 1].show;
//   messages.value[messages.value.length - 1].show = true;
// };

const handleShowEmail = (pos: number) => {
  // const index = messages.value.findIndex((el) => el.elementEmail?.id == id);
  // if (messages.value[index]?.elementEmail)
  //   messages.value[index].elementEmail.show = !messages.value[index].elementEmail?.show;
  messages.value[pos].elementEmail.show = !messages.value[pos].elementEmail?.show;
  // messages.value[messages.value.length - 1].show = true;
};

// const handleRefreshThread = () => {
//   emit("refreshThread");
// };

const handleRefreshThread = async () => {
  if (props.currentEmailId) {
    messages.value = [];
    store.dispatch("updateThreadActual", []);
    const messageDetail = await emailService.getThreadDetails(props.currentEmailId);
    store.dispatch("updateThreadActual", messageDetail);
    // threadSelected.value = messageDetail;
  }
};

const close = () => {
  console.log("close 2");
  emit("close");
};

watch(show, () => {
  console.log(messages, "IS INBOX TABLE ASSIGNED");
});

// #TODO: reemplazar por action
const fetchMenuList = async () => {
  await store.dispatch("updateMenuItems", user.value?.id);
  // if (menuItems.value) {
  //   const assignedUser = route.query.assigned == "true" ? user.value.id : undefined;
  //   const itemsAux = await emailService.getEmailsMenuList(assignedUser);
  //   for (let i = 0; i < itemsAux.length; i++) {
  //     for (let j = 0; j < itemsAux[i].subItems?.length; j++) {
  //       menuItems.value[i].subItems[j].unread = itemsAux[i]?.subItems[j]?.unread;
  //       // menuItems.value[i].subItems[j].unread = false;
  //     }
  //   }
  // }
};

/*
  #TODO: Cambiar estructura de filtros de inbox para que use estados. 
  Usar vuex para estos valores ya que el listado del inbox depende de estos
*/
// const read = inject("filterRead") as Ref<boolean | "">;
// const selectedTags = inject("filterSelectedTags") as Ref<Array<number | "">>;
// const search = inject("filterSearch") as Ref<string | "">;

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
// const getUrlParams = (): {
//   channelCustomerId: number;
//   assigned: boolean;
//   resolved?: boolean;
// } => {
//   const { channelCustomer, assigned, resolved } = route.query;

//   return {
//     channelCustomerId: Number(channelCustomer),
//     assigned: assigned === "true",
//     resolved: resolved === " true",
//   };
// };
// const fetchList = inject("fetchList");

const refreshInbox = async () => {
  // const params = getUrlParams();
  // if (fetchList) {
  //   fetchList({ ...params, read: read.value, tagList: selectedTags.value, search: search.value });
  // }
  await store.dispatch("updateThreadsInbox", {
    assignedUser: store.getters["getDataUser"]?.id,
    resetPagination: false,
  });
};

provide("refreshInbox", refreshInbox);

onMounted(async () => {
  if (props.currentEmailId) await handleRefreshThread();
  // await nextTick();
  if (!props.readBefore) {
    await fetchMenuList();
  }
  refreshInbox();
});
</script>
<style lang="sass" scoped>
.scroll
  height: calc(100vh - 160px)
  overflow-y: scroll
  &_mailbox
    background-color: $gray-2
    margin-top: 1px
.email__message
  padding: 0
  .head
    display: flex
    justify-content: space-between
    background-color: $base-ninja-100
    padding: 10px
    .actions
      color: #1677FF
  .thread
    border: 1px solid $gray-5
    margin: 8px 10px 10px 0px
    border-radius: 4px
    padding: 10px
  .group
    max-height: 500px
    overflow-y: scroll

.container
  width: 100%
  &--loading
    margin: 40px
    color: $gray_6
    text-align: center
</style>
