<template>
  <div class="reply">
    <!-- <RedactEmailForm
      :replyEmail="replyEmail"
      :emailTransmitter="emailTransmitter"
      :defaultEmail="emailFromResponse"
      @handleChangeEmailFrom="handleChangeEmailFrom"
      ref="redactReplyFormRef"
    /> -->
    <RedactEmailForm
      :replyEmail="replyEmail"
      :emailTransmitter="emailTransmitter"
      @handleChangeEmailFrom="handleChangeEmailFrom"
      ref="redactReplyFormRef"
    />
    <div class="footer">
      <div class="modal" @click="openEmailModal">
        <ArrowsAltOutlined />
        <a-button type="link">Responder en pantalla completa</a-button>
      </div>
      <div class="buttons">
        <a-button @click="$emit('emitCloseReplyEvent', false)">Cancelar</a-button>
        <a-button :loading="sending" type="primary" @click="sendMessage">Enviar</a-button>
      </div>
    </div>
  </div>
  <!-- <RedactNewEmail
    ref="redactReplyFormMaximRef"
    v-model:open="open"
    @close="updateOpenRedact"
    :defaultEmail="emailFromResponse"
    :isReply="true"
    :emailTransmitter="emailTransmitter"
    @handleChangeEmailFrom="handleChangeEmailFrom"
  /> -->
  <RedactNewEmail
    ref="redactReplyFormMaximRef"
    v-model:open="open"
    :isReply="true"
    :emailTransmitter="emailTransmitter"
    @handleChangeEmailFrom="handleChangeEmailFrom"
    @updateList="refreshInbox"
  />
</template>
<script lang="ts" setup>
import { ref, inject, watch, onMounted, provide, computed } from "vue";
import RedactEmailForm from "@/app/emails/components/forms/RedactEmailForm.vue";
import { ArrowsAltOutlined } from "@ant-design/icons-vue";
import RedactNewEmail from "@/app/emails/components/modals/RedactNewEmail.vue";
import { message } from "ant-design-vue";
import { SendEmailDto } from "@/@types/emails/dtos";
import { emailService } from "../../services/Email.service";
import { ActionsEmail, EmailDetails, EmailForm } from "@/@types/emails";
import { useStore } from "vuex";
import { ResponseError } from "@/app/shared/utils/httpManager";

import HslToHex from "@/app/shared/utils/hslToHex";

const props = defineProps<{
  idEmail: number;
  emailFrom: EmailDetails;
  action: string;
}>();

const open = ref<boolean>(false);
const replyEmail = inject("replyEmail");
const redactReplyFormRef = ref<InstanceType<typeof RedactEmailForm>>();
const redactReplyFormMaximRef = ref<InstanceType<typeof RedactNewEmail>>();
const sending = ref<boolean>(false);
// const defaultEmail = ref<EmailDetails>(null);
const emailFromResponse = ref<EmailForm>({
  fromEmail: null,
  selectedItems: [],
  selectedItemsCC: [],
  selectedItemsCCO: [],
  subject: "",
  text: "",
});

const hslToHex = new HslToHex();

provide("emailFromResponse", emailFromResponse);

const emailTransmitter = ref(true);

const emit = defineEmits(["emitSaveReplyEvent", "emitCloseReplyEvent"]);

const store = useStore();

const selectedCampaign = computed(() => {
  return store.getters.getSelectedCampaign;
});

// const emailFromResponse = computed(() => {
//   let itemsCC: any[] = [];
//   if (props.emailFrom.receivers.length > 0)
//     itemsCC = props.emailFrom.receivers.map((el) => (el.email));

//   itemsCC = [...itemsCC, ...props.emailFrom.ccEmails.filter((el) => {
//     if (el.email != "")
//       return el.email
//   })];

//   const itemsCCO = props.emailFrom.bccEmails.filter((el) => {
//     if (el.email != "")
//       return el.email
//   });

//   return {
//     fromEmail: undefined,
//     selectedItems: props.emailFrom.sender,
//     selectedItemsCC: itemsCC,
//     selectedItemsCCO: itemsCCO,
//     subject: null,
//     text: null
//   }

// });

const openEmailModal = () => {
  open.value = true;
  replyEmail.value = true;
  if (redactReplyFormRef?.value?.formState)
    emailFromResponse.value = redactReplyFormRef?.value?.formState;
  // console.log(redactReplyFormRef?.value?.formState, "formState");
};

const refreshInbox = inject("refreshInbox");

const sendMessage = async () => {
  sending.value = true;

  try {
    const form = redactReplyFormRef.value?.formState;
    const files = redactReplyFormRef.value?.files;

    if (!form) throw new Error("No email data");
    if (!form.fromEmail) throw new Error(" No selected email");

    if (form.selectedItems.join(",") && form.text && selectedCampaign.value.id) {
      const convertedHtml = hslToHex.convertHslToHex(form.text);

      if (props.action == ActionsEmail.Reenviar) {
        const body: SendEmailDto = {
          channel_customer: selectedCampaign.value.id,
          to_emails: form.selectedItems.join(","),
          // message: form.text,
          message: convertedHtml,
          subject: form.subject,
          cc_emails: form.selectedItemsCC.join(","),
          bcc_emails: form.selectedItemsCCO.join(","),
          attachments: files,
        };

        await emailService.sendEmail(body);
      } else {
        const body: SendEmailDto = {
          channel_customer: selectedCampaign.value.id,
          to_emails: form.selectedItems.join(","),
          // message: form.text,
          message: convertedHtml,
          subject: form.subject,
          cc_emails: form.selectedItemsCC.join(","),
          bcc_emails: form.selectedItemsCCO.join(","),
          attachments: files,
          reference_id: props.emailFrom.referenceId,
          parent: props.idEmail,
        };

        await emailService.replyEmail(body);
      }

      // emit("update:open", false);
      // status.value = "normal";
      refreshInbox();
      message.success("Se ha enviado el correo con éxito");
      emit("emitSaveReplyEvent", false);
    } else {
      message.error("Complete los campos requeridos");
    }
  } catch (error: any) {
    console.error(error);
    let errorMessage = "Ha ocurrido un error";
    if (error instanceof ResponseError) errorMessage = error.getErrorMessage();
    message.error(errorMessage);
  }

  sending.value = false;
};

// const closeCard = () => {
//   emit("emitCloseEvent", false);
// };

const formatEmailDefault = (data: any) => {
  let itemsCC: any[] = [];
  let itemsCCO: any[] = [];

  if (props.action == ActionsEmail.Reenviar) {
    console.log(selectedCampaign.value, "email default");
    emailFromResponse.value = {
      fromEmail: selectedCampaign.value.email, //consultar por que valor cambiarlo
      selectedItems: [],
      selectedItemsCC: itemsCC,
      selectedItemsCCO: itemsCCO,
      subject: "",
      text: data.content,
    };
    console.log(emailFromResponse.value, "email from tesponse");
  } else {
    if (props.action == ActionsEmail.ResponderTodos) {
      if (data.receivers.length > 0) itemsCC = data.receivers.map((el: any) => el.email);

      if (data.ccEmails) {
        itemsCC = [
          ...itemsCC,
          ...data.ccEmails.filter((el: any) => {
            if (el.email != "") return el.email;
          }),
        ];
      }

      if (data.bccEmails) {
        itemsCCO = data.bccEmails.filter((el: any) => {
          if (el.email != "") return el.email;
        });
      }
    }

    if (props.action == ActionsEmail.ResponderTodos || props.action == ActionsEmail.Responder) {
      emailFromResponse.value = {
        fromEmail: selectedCampaign.value.email, //consultar por que valor cambiarlo
        selectedItems: [data.sender],
        selectedItemsCC: itemsCC,
        selectedItemsCCO: itemsCCO,
        subject:
          (props.emailFrom.subject?.substring(0, 3) == "RE:" ? "" : "RE: ") +
          props.emailFrom.subject,
        text: emailFromResponse.value.text,
      };
    }
  }
};

const updateOpenRedact = (objeto: any) => {
  if (objeto) emailFromResponse.value = { ...objeto };
  // console.log(redactReplyFormRefMax, "redactReplyFormRefMax");
  // if (!open)
  //   if (redactReplyFormMaximRef?.value)
  //     console.log(redactReplyFormMaximRef?.value, "redactReplyFormRefMax");
  // emailFromResponse.value = redactReplyFormRefMax?.value?.formState;
};

const handleChangeEmailFrom = () => {
  emailTransmitter.value = false;
};

watch(
  () => props.action,
  () => {
    formatEmailDefault(props.emailFrom);
  }
);

watch(
  () => selectedCampaign.value,
  () => {
    formatEmailDefault(props.emailFrom);
  }
);

onMounted(async () => {
  formatEmailDefault(props.emailFrom);
});
</script>
<style lang="sass" scoped>
.reply
  padding: 10px 20px
  border: 1px solid #1677FF
  margin: 5px 8px 0px 8px
  border-radius: 4px
  background-color: $white
  .footer
    padding: 20px 10px 10px 10px
    display: flex
    justify-content: space-between
    .buttons
      display: flex
      gap: 20px
    .modal
      color: #1677FF
      font-weight: 400
</style>
