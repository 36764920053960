import httpManager from "@/app/shared/utils/httpManager";
import { dateToString } from "@/app/shared/utils/dates";

export async function fetchDefaultTickets() {
  const currentDay = dateToString(new Date()).substring(0, 10);

  const response = await httpManager.get(
    `/tickets/manager/?entity=&channel=&start_date=${currentDay} 00:00:00&end_date=${currentDay} 23:59:59`
  );

  return response?.data;
}

/**
 * Obtener todos los tickets.
 *
 * @return {Promise<Array>} Lista de tickets.
 */
export async function fetchTicketsWithBasicFilters(params) {
  const { page, entity, portfolio, channel, search, startDate, endDate } = params;
  const currentDay = dateToString(new Date()).substring(0, 10);

  const isDefaultFilters =
    search &&
    entity === "" &&
    channel === "messaging" &&
    startDate === `${currentDay} 00:00:00` &&
    endDate === `${currentDay} 23:59:59`;

  if (isDefaultFilters) {
    const response = await httpManager.get(`/tickets/manager/?search=${search}`);
    return response?.data;
  }

  const response = await httpManager.get(
    `/tickets/manager/?offset=${50 * (page - 1)}&limit=50&entity=${entity ?? ""}&portfolio=${
      portfolio || ""
    }&channel=${channel}&search=${search}&start_date=${startDate}&end_date=${endDate}`
  );
  return response?.data;
}

export async function fetchTicketsWithAdvancedFilters(params) {
  const {
    page,
    search,
    entity,
    portfolio,
    channel,
    identifier,
    queues,
    ticketStates,
    tags,
    users,
    contact_types,
    withoutUser,
    switchStrategies,
    checkedStrategies,
    startDate,
    endDate,
  } = params;
  console.log(params, "parametros");
  const base = {
    queues_id: queues,
    states_id: ticketStates,
    tags_id: tags,
    users_id: users,
    groups: contact_types,
    start_date: startDate,
    end_date: endDate,
    without_user: withoutUser,
    only_strategy: checkedStrategies,
  };

  let filters = base;

  if (search)
    filters.search = search

  if (entity || entity === 0)
    filters.entity_id = entity == 0 ? '0' : entity

  if (portfolio)
    filters.portfolio_id = portfolio

  if (channel) {
    filters = { ...filters, channels_id: channel.filter(el => el != '') };
  }

  if (identifier) {
    filters = { ...filters, channel_customer: identifier };
  }

  const response = await httpManager.post(
    `/tickets/manager/?offset=${
      50 * (page - 1)
    }&limit=50&in_strategy=${switchStrategies}`,
    filters
  );

  return response?.data;
}

/**
 * Obtener todos los ids de los tickets.
 *
 * @return {Promise<Array>} Lista de ids tickets.
 */
export async function fetchTicketsIdsWithBasicFilters(params) {
  let url;
  const currentDay = dateToString(new Date()).substring(0, 10);
  if (!params) {
    url = `/tickets/manager/tickets_to_transfer/?entity=&channel=&start_date=${currentDay} 00:00:00&end_date=${currentDay} 23:59:59`;
  } else {
    const { page, entity, portfolio, channel, search, startDate, endDate } = params;

    const isDefaultFilters =
      search &&
      entity === "" &&
      channel === "messaging" &&
      startDate === `${currentDay} 00:00:00` &&
      endDate === `${currentDay} 23:59:59`;

    if (isDefaultFilters) {
      url = `/tickets/manager/tickets_to_transfer/?search=${search}`;
    } else {
      url = `/tickets/manager/tickets_to_transfer/?entity=${entity ?? ""}&portfolio=${
        portfolio || ""
      }&channel=${channel}&search=${search}&start_date=${startDate}&end_date=${endDate}`;
    }
  }
  const response = await httpManager.get(url);
  return response?.data;
}

export async function fetchTicketsIdsWithAdvancedFilters(params) {
  const {
    search,
    entity,
    portfolio,
    channel,
    identifier,
    queues,
    ticketStates,
    tags,
    users,
    contact_types,
    withoutUser,
    switchStrategies,
    checkedStrategies,
    startDate,
    endDate,
  } = params;

  const base = {
    queues_id: queues,
    states_id: ticketStates,
    tags_id: tags,
    users_id: users,
    groups: contact_types,
    start_date: startDate,
    end_date: endDate,
    without_user: withoutUser,
    only_strategy: checkedStrategies,
  };

  let filters = base;

  if (search)
    filters.search = search
  
  if (entity || entity === 0)
    filters.entity_id = entity == 0 ? '0' : entity

  if (portfolio)
    filters.portfolio_id = portfolio

  if (channel) {
    filters = { ...filters, channels_id: channel.filter(el => el != '') };
  }

  if (identifier) {
    filters = { ...filters, channel_customer: identifier };
  }

  const response = await httpManager.post(`/tickets/manager/tickets_to_transfer/?in_strategy=${switchStrategies}`, filters);

  return response?.data;
}
