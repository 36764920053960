<template>
  <a-list
    class="list"
    :data-source="props.dataSource"
    :bordered="false"
    item-layout="vertical"
    :loading="loading"
    :locale="{ emptyText: 'No se han encontrado descargas de acuerdo a los filtros' }"
    v-infinite-scroll="[loadData, { distance: 50 }]"
  >
    <template #renderItem="{ item }">
      <a-list-item class="list__item">
        <card-download :key="item.id" class="list__card" :download="item" />
      </a-list-item>
    </template>
  </a-list>
</template>

<script setup lang="ts">
import { inject } from "vue";
import { vInfiniteScroll } from "@vueuse/components";
import CardDownload from "@/app/settingsMenu/components/lists/CardDownload.vue";
import { CardDownloadItem } from "@/@types/settingsMenu/downloads";
import { FunctionsDownload } from "@/@types/settingsMenu/downloads";

const props = defineProps<{
  dataSource: CardDownloadItem[] | undefined;
  loading: boolean;
}>();

const provideFunctions = inject("functionsDownload") as FunctionsDownload;

async function loadData() {
  provideFunctions.onNext();
}
</script>

<style lang="sass" scoped>
$height-filters: 80px
$height-header: 80px
$height-margins: 20px
.list
  width: 100%
  height: calc(100vh - #{$height-filters + $height-header + $height-margins})
  overflow-y: auto
  &__item
    border-bottom: none
    padding: 6px 0px
</style>
