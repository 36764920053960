import httpManager from "@/app/shared/utils/httpManager";

export async function downloadHistoricalReport(campaign, date_start, date_end) {
  const url = `/queues/monitoring/download-history/`;
  const body = {
    portfolio: campaign,
    date_start: date_start,
    date_end: date_end,
  };
  const response = await httpManager.post(url, body, { blob: true });
  return response;
}
