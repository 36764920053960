import ChannelsPage from "@/app/channels/pages/ChannelsPage.vue";
import ChannelsSetupTemplate from "@/app/channels/components/templates/ChannelsSetupTemplate.vue";

export default [
  {
    path: "/channels",
    name: "channels",
    component: ChannelsPage,
    children: [
      {
        path: "setup",
        component: ChannelsSetupTemplate,
      },
      {
        path: "setup/sms",
        component: () =>
          import(
            /* webpackChunkName: "sms" */ "@/app/channels/components/templates/SocialChannels/SmsChannels.vue"
          ),
      },
      {
        path: "editor/sms",
        component: () =>
          import(
            /* webpackChunkName: "edit-sms" */ "@/app/channels/components/templates/SocialChannels/EditSmsChannels.vue"
          ),
      },
      {
        path: "setup/voice",
        component: () =>
          import(
            /* webpackChunkName: "voice" */ "@/app/channels/components/templates/SocialChannels/VoiceChannels.vue"
          ),
      },
      // {
      //   path: "setup/confirmIntegration",
      //   component: () =>
      //     import(
      //       /* webpackChunkName: "complete" */ "@/app/channels/components/templates/SocialChannels/CompleteIntegration.vue"
      //     ),
      // },
      {
        path: "board",
        component: () =>
          import(
            /* webpackChunkName: "channel-board" */ "@/app/channels/components/templates/ChannelsBoardTemplate.vue"
          ),
      },
      {
        path: "integrations",
        component: () =>
          import(
            /* webpackChunkName: "integrations-list" */ "@/app/integrations/components/templates/IntegrationsListTemplate.vue"
          ),
      },
      {
        path: "board/whatsapp-templates/:channel_customer",
        component: () =>
          import(
            /* webpackChunkName: "whatsapp-templates" */ "@/app/channels/components/templates/WhatsappTemplatesTemplate.vue"
          ),
      },
      {
        path: "board/sms-templates/:channel_customer",
        component: () =>
          import(
            /* webpackChunkName: "sms-templates" */ "@/app/channels/components/templates/SmsTemplatesTemplate.vue"
          ),
      },
      {
        path: "template",
        component: () =>
          import(
            /* webpackChunkName: "sms-templates" */ "@/app/channels/components/templates/Template.vue"
          ),
      },
    ],
    meta: {
      requiresAuth: true,
    },
  },
];
