<template>
  <div
    class="card"
    :class="{
      centered: templateType !== WhatsappTemplateTypes.TextAndDocument,
      'card-file': templateType === WhatsappTemplateTypes.TextAndDocument,
    }"
  >
    <div class="content-file" v-if="templateType === WhatsappTemplateTypes.TextAndDocument">
      <div class="content-file-name">
        <FilePdfOutlined class="icon" />
        <a-typography-text class="name" :ellipsis="{ tooltip: fileName }" :content="fileName" />
      </div>

      <DownloadOutlined class="icon" />
    </div>

    <component
      v-if="templateType === WhatsappTemplateTypes.TextAndVideo"
      :is="showIcon ? PlayCircleOutlined : 'video'"
      :class="{ 'icon-xl': showIcon, 'preview preview-video': !showIcon }"
      controls
    >
      <source :src="contentSrc" type="video/mp4" />
    </component>

    <component
      v-if="templateType === WhatsappTemplateTypes.TextAndImage"
      :is="showIcon ? PictureOutlined : 'img'"
      :class="{ 'icon-xl': showIcon, 'preview preview-image': !showIcon }"
      :src="contentSrc"
    />
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";
import {
  FilePdfOutlined,
  DownloadOutlined,
  PlayCircleOutlined,
  PictureOutlined,
} from "@ant-design/icons-vue";

import { WhatsappTemplateTypes } from "@/app/channels/utils/enums";

const props = defineProps<{
  preview?: boolean;
  templateType: number | string;
  file?: File;
  mediaLink: string;
}>();

const showIcon = computed(() => (!props.file && !props.mediaLink) || props.preview);
const fileName = computed(() => props.file?.name || "Nombre del archivo");

const contentSrc = computed(() => {
  if (props.mediaLink) return props.mediaLink;
  return props.file ? window.URL.createObjectURL(props.file) : "";
});
</script>

<style scoped lang="sass">
.card
  width: 100%
  background-color: #F0F0F0
  color: $gray-7
  margin: 5px 0 10px
  border-radius: 10px

  &-file
    padding: 8px

.centered
  aspect-ratio: 16 / 9
  display: flex
  justify-content: center
  align-items: center

.content
  &-file
    display: flex
    align-items: center
    justify-content: space-between
    width: 100%

    &-name
      display: flex
      align-items: center
      gap: 5px
      width: 100%

.name
  color: $gray-7

.icon
  font-size: 18px

  &-xl
    font-size: 30px

.preview
  width: 100%
  aspect-ratio: 16 / 9
  object-fit: cover
  border-radius: 10px

  &-image
    object-position: top
</style>
