import httpManager from "@/app/shared/utils/httpManager";
/**
 * Limpia el numero del anexo.
 *
 * @return {Promise<string>} Numero de anexo.
 */
export async function disposeAnnex() {
  const response = await httpManager.post(`/users/annex/disable/`, {});
  return response?.data?.access;
}
