export const SET_SELECTED_MENU = "SET_SELECTED_MENU";
export const SET_MENU_ITEMS = "SET_MENU_ITEMS";
export const SET_OPEN_KEYS_MENU_ITEMS = "SET_OPEN_KEYS_MENU_ITEMS";
export const SET_SELECTED_KEYS_MENU_ITEMS = "SET_SELECTED_KEYS_MENU_ITEMS";
export const SET_TOTAL_EMAILS = "SET_TOTAL_EMAILS";
export const SET_TABLE_PAGE = "SET_TABLE_PAGE";
export const SET_SELECTED_CAMPAIGN = "SET_SELECTED_CAMPAIGN";
export const SET_SELECTED_EMAIL = "SET_SELECTED_EMAIL";
export const SET_THREAD_ACTUAL = "SET_THREAD_ACTUAL";
export const REMOVE_THREAD_ACTUAL = "REMOVE_THREAD_ACTUAL";
export const SET_THREADS_INBOX = "SET_THREADS_INBOX";
export const SET_FETCHING_THREADS_INBOX = "SET_FETCHING_THREADS_INBOX";
export const SET_SELECTED_THREADS_INBOX = "SET_SELECTED_THREADS_INBOX";
