<template>
  <div class="contact">
    <div class="info">
      <img
        :src="
          contact?.imageSrc ||
          'https://atg-prod-scalar.s3.amazonaws.com/studentpower/media/user%20avatar.png'
        "
        width="40"
        height="40"
        alt="Avatar de contacto"
      />
      <div class="name">
        <h3>{{ contact?.name ?? "No identificado" }}</h3>
        <span v-if="contact?.email">&lt;{{ contact?.email }}&gt;</span>
      </div>
    </div>
    <div>
      <a-tooltip>
        <template #title> Ver contacto </template>
        <ArrowRightOutlined
          v-if="contact?.id"
          type="text"
          class="icon-primary"
          @click="handleOpenContactDrawer()"
        />
      </a-tooltip>
      <a-tooltip>
        <template #title>
          <span v-if="!hasClient">Crear / asignar a contacto</span>
          <span v-else>Asociar a un contacto</span>
        </template>
        <UserAddOutlined
          v-if="!contact?.id"
          type="text"
          class="icon-primary"
          @click="handleAsignContactDrawer()"
        />
      </a-tooltip>
    </div>
  </div>
  <ContactDetailDrawer
    fromSchedule
    v-if="contactDrawerConfig.visible"
    :itemId="contactDrawerConfig.contactId"
    :visible="contactDrawerConfig.visible"
    :campaignName="contactDrawerConfig.campaign?.name"
    @on-close-drawer="handleCloseDrawer"
  />
  <ContactInfo
    :isDisabled="!contact?.email"
    :visible="contactInfoVisible"
    :email="contact?.email"
    :currentMessage="currentMessage"
    :hasClient="hasClient"
    @close="contactInfoVisible = false"
  />
</template>
<script lang="ts" setup>
import { ref } from "vue";
import { ArrowRightOutlined, UserAddOutlined } from "@ant-design/icons-vue";
import ContactDetailDrawer from "@/app/contacts/components/drawers/ContactDetailDrawer.vue";
import ContactInfo from "@/app/emails/components/drawers/ContactInfo.vue";
import { useStore } from "vuex";
import { EmailDetails } from "@/@types/emails";

const props = defineProps({
  contact: { type: Object },
  currentMessage: { type: Object },
  hasClient: { type: Boolean, default: false },
});

const store = useStore();

const contactInfoVisible = ref(false);

const contactDrawerConfig = ref({
  visible: false,
  contactId: props.contact?.id,
  campaign: store.getters.getSelectedCampaign,
});

const handleOpenContactDrawer = () => {
  contactDrawerConfig.value.visible = true;
};

const handleCloseDrawer = () => {
  contactDrawerConfig.value.visible = false;
};

const handleAsignContactDrawer = () => {
  contactInfoVisible.value = true;
};
</script>
<style lang="sass" scoped>
.contact
  display: flex
  gap: 20px
  margin-bottom: 10px
  justify-content: space-between
  align-items: center
  .info
    display: flex
    .name
      margin-left: 10px
      h3
        color: #262626E0
        margin: 0
      span
        color: $gray-7
    span
      color: #1677FF

  .icon-primary
    font-size: 18px
    margin-left: auto
    color: $primary
</style>
