export const PdpTypeLabel = {
  1: "Por Cliente",
  2: "Por Producto",
};

export const ReportTypes = {
  PayPromise: 7,
  InboundVoice: 8,
  Sells: 11,
  Atc: 12,
  CustomerSupport: 13,
  Forms: 14,
  Calls: 15,
  IvrAutomatic: 18,
  IvrSurvey: 17,
  MessagingTickets: 19,
  Strategies: 20,
  Phones: 21,
  LeadsResume: 22,
  CapturedLeads: 23,
  MessageTicketsTransfer: 24,
  ContactProducts: 25,
};

export const CustomerSupportReportTypes = {
  Resume: 1,
  TimeSection: 2,
  DailyManagement: 3,
  Abandoned: 4,
  UsersManagement: 5,
};

export const PayPromiseReportTypes = Object.freeze({
  Ranking: 1,
  Progress: 2,
  List: 3,
});

export const CustomerSupportReportTypesNames = {
  [CustomerSupportReportTypes.Resume]: "RESUMEN",
  [CustomerSupportReportTypes.TimeSection]: "TIME_SLOT",
  [CustomerSupportReportTypes.DailyManagement]: "DAILY_MANAGEMENT",
  [CustomerSupportReportTypes.Abandoned]: "DROPPED_CALLS",
  [CustomerSupportReportTypes.UsersManagement]: "USERS_MANAGEMENT",
};

export const PayPromiseReportTypesNames = Object.freeze({
  [PayPromiseReportTypes.Ranking]: "RANKING_PDP",
  [PayPromiseReportTypes.Progress]: "CONTROL_PDP",
  [PayPromiseReportTypes.List]: "LIST_PDP",
});

export const SubtypesByReport = {
  [ReportTypes.CustomerSupport]: {
    label: "atención al cliente",
    options: [
      { value: CustomerSupportReportTypes.Resume, label: "Resumen" },
      { value: CustomerSupportReportTypes.TimeSection, label: "Tramo horario" },
      { value: CustomerSupportReportTypes.DailyManagement, label: "Gestión diaria" },
      { value: CustomerSupportReportTypes.Abandoned, label: "Abandonadas" },
      { value: CustomerSupportReportTypes.UsersManagement, label: "Gestión de usuarios" },
    ],
  },
  [ReportTypes.PayPromise]: {
    label: "promesa de pago",
    options: [
      { value: PayPromiseReportTypes.Ranking, label: "Ranking de PDP por usuario" },
      { value: PayPromiseReportTypes.Progress, label: "Control de avance de PDP" },
      { value: PayPromiseReportTypes.List, label: "Listado de PDP" },
    ],
  },
};

export const Months = {
  ene: "Enero",
  feb: "Febrero",
  mar: "Marzo",
  abr: "Abril",
  may: "Mayo",
  jun: "Junio",
  jul: "Julio",
  ago: "Agosto",
  sep: "Setiembre",
  oct: "Octubre",
  nov: "Noviembre",
  dic: "Diciembre",
};

export const MonthName = {
  "01": "Ene",
  "02": "Feb",
  "03": "Mar",
  "04": "Abr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Ago",
  "09": "Set",
  10: "Oct",
  11: "Nov",
  12: "Dic",
};
