<template>
  <section class="card__container">
    <div class="card" :style="cardStyle">
      <header v-if="hasHeader">
        <ImagePicker
          v-if="!hideImg"
          :src="imageSrc || briefCaseBlue"
          isBusiness
          isPreview
          isSmall
        />

        <div class="caption">
          <h2 v-if="title">{{ title }}</h2>

          <p v-if="description">{{ description }}</p>
        </div>
      </header>

      <section :class="{ 'full-content': !hasHeader }">
        <slot />
      </section>
    </div>
  </section>
</template>

<script setup lang="ts">
import { computed } from "vue";
import ImagePicker from "@/app/shared/components/molecules/ImagePicker.vue";

import briefCaseBlue from "@/app/entities/assets/briefcase-blue.svg?url";

const props = defineProps<{
  imageSrc?: string;
  title: string;
  description: string;
  hideImg?: boolean;
  cardStyle?: Record<string, string>;
}>();

const hasHeader = computed(() => props.title || props.description || !props.hideImg);
</script>

<style scoped lang="sass">
.card
  height: 100%
  max-width: 640px
  min-width: min(640px, 90%)
  width: 60%
  background-color: white
  box-shadow: 0px 2px 20px rgba(0, 0, 0, 0.08)
  border-radius: 16px
  margin: 20px auto
  padding: 50px 100px
  overflow: auto

  &__container
    height: calc(100vh - 68px - 47px)
    width: 100%
    padding: 20px 0
    display: flex
    justify-content: center
    align-items: center

.caption
  text-align: center
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  gap: 8px

h2
  font-size: 24px
  font-weight: 600
  color: $gray-9
  margin: 0

p
  font-size: 16px
  color: $gray-8
  margin: 0

header
  margin-bottom: 16px

.full-content
  height: 100%
</style>
