<template>
  <div>
    <a-button v-if="!isResolved" class="modal-button" :type="buttonType" @click="handleShowModal">
      Resolver ticket
    </a-button>

    <a-modal
      v-model:visible="visible"
      title="Resolver ticket"
      width="600px"
      :body-style="{ padding: '12px 20px' }"
      centered
      :keyboard="false"
      :mask-closable="false"
      @cancel="handleCloseModal"
    >
      <div>
        <div class="container">
          <p>
            Escribe un breve comentario sobre la gestión de este ticket para poder resolver.
            Recuerda que se finalizaran todas las interacciones activas y se cerrará el ticket.
          </p>
          <a-form
            ref="formRef"
            :model="formState"
            :rules="rules"
            layout="vertical"
            hide-required-mark
          >
            <a-form-item label="Comentario" name="observations">
              <a-textarea
                v-model:value="formState.observations"
                class="textarea"
                rows="5"
                placeholder="Escribe aquí, tienes caracteres limitados..."
                :maxlength="maxTextCountObservation"
              />
              <span class="counter">
                {{ observationTextCount }}/{{ maxTextCountObservation }}
              </span>
            </a-form-item>
          </a-form>

          <AIConclusionTextArea type="management" />
        </div>
      </div>

      <!-- Fotter Modal -->
      <template #footer>
        <footer class="footer">
          <!-- <AIConclusionButton type="management" /> -->

          <a-button key="back" @click="handleCloseModal"> Cancelar </a-button>
          <a-button key="submit" type="primary" @click="handleSubmit"> Resolver </a-button>
        </footer>
      </template>
    </a-modal>
  </div>
</template>

<script>
import { defineComponent, ref, reactive, computed, inject } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import { message } from "ant-design-vue";

import { resolveTicketById } from "@/app/workspace/services";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import EventBus from "@/app/shared/utils/eventBus";
import ticketsStorage from "@/app/workspace/storage/tickets";
import sipStorage from "@/packages/vue-jssip/jssip/storage";
import { useJssip } from "@/app/shared/composables/useJssip";

import AIConclusionButton from "@/app/integrations/components/buttons/AIConclusionButton.vue";
import AIConclusionTextArea from "@/app/integrations/components/inputs/AIConclusionTextArea.vue";

import { EventManagement } from "@/app/shared/utils/enums";
import { registerEventManagement } from "@/app/shared/services";

export default defineComponent({
  name: "ResolveTicket",
  components: {
    // AIConclusionButton,
    AIConclusionTextArea,
  },
  setup() {
    const $jssip = useJssip();

    const visible = ref(false);
    const formRef = ref();
    const formState = reactive({
      observations: "",
    });
    const rules = {
      observations: [{ required: true, message: "Campo requerido" }],
    };
    const maxTextCountObservation = 150; // Máxima cantidad de caracteres en el campo de "Observación del cliente"

    const observationTextCount = computed(() => formState.observations.length);
    const dataUserState = computed(() => store.getters["getDataUserState"]);
    const globalState = computed(() => store.getters["getStateUser"]);

    const store = useStore();
    const route = useRoute();

    const isCurrentTicket = computed(() => {
      const openedTicketId = route.query.ticket;
      // const { ticket: webphoneTicketId } = $jssip.phone?.meta;

      // return openedTicketId == webphoneTicketId;
      return $jssip.phone.isCurrentCallTicket(openedTicketId);
    });

    // const ticket = computed(() => {
    //   const tickets = store.getters["getWorkspaceTickets"];
    //   const ticketId = route.params.ticket;

    //   return tickets.find((item) => item.id == ticketId);
    // });

    // const channel = computed(() => {
    //   const channels = store.getters["getWorkspaceClientChannels"];
    //   const channelId = route.params.channel;

    //   return channels.find((item) => item.id == channelId);
    // });
    const ticket = inject("selectedTicket");
    const channel = inject("selectedChannel");

    const isIdentifierTypified = computed(() => {
      const identifier = ticket.value?.typified?.find(
        (_identifier) => _identifier?.identifier === channel.value?.identifier
      );

      return identifier?.is_typified;
    });

    const buttonType = computed(() =>
      isIdentifierTypified.value || !ticket.value?.client ? "primary" : "default"
    );

    const isResolved = computed(() => {
      if (!route.query.client) return false;
      if (channel.value == undefined) return false;
      if (!channel.value) return true;
      if (channel.value.channel) return false;

      return channel.value?.status == 2;
    });

    const registerEvent = (event) => {
      const ticket = route.query.ticket;
      registerEventManagement(event, {
        open_ticket: ticket,
        menu: 23,
        state: dataUserState.value,
      });
      store.dispatch("setEventMessaging", event);
    };

    const handleShowModal = () => {
      visible.value = true;
      registerEvent(EventManagement.Resolved);
      // const ticket = route.params.ticket;

      // if (channel.value?.channel?.id != ChannelType.Voice) {
      //   registerEventManagement(EventManagement.Resolved, {
      //     call_ticket: $jssip.phone?.meta?.ticket,
      //     open_ticket: ticket,
      //     type_dialing: dialingType.value,
      //     menu: 30,
      //     state: dataUserState.value,
      //   });
      // }
    };
    const handleCloseModal = (handleRegisterEvent = true) => {
      visible.value = false;
      if (handleRegisterEvent)  registerEvent(globalState.value);
    };
    const handleSubmit = () => {
      formRef.value.validate().then(async () => {
        const body = { observations: formState.observations };
        const ticketId = Number(route.query.ticket);
        const interactionId = Number(route.query.interaction);
        const clientId = Number(route.query.client);

        if (isCurrentTicket.value) {
          // EventBus.emit(WorkspaceEvent.ENDED_BY_TYPIFICATION, true);
          $jssip.end({ fromTypification: true });

          ticketsStorage.clearTypification(ticketId);
          sipStorage.clearAcwTime();
          EventBus.$emit(WorkspaceEvent.DIAL_CLOSE);
        }

        if (!isIdentifierTypified.value && clientId) {
          console.log("Default typification saved before resolve");

          const defaultTypificationBody = {
            ticket: ticketId,
            interaction_id: interactionId,
            phone_number: channel.value?.identifier,
            identifier: channel.value?.identifier,
            channel_customer:
              channel.value?.channel_customer?.id || channel.value?.channel_customer,
            asterisk_id: channel.value?.asterisk_id ?? undefined, //$jssip.phone.meta?.asteriskId,
            strategy_phone: $jssip.phone.meta.strategyPhone,
          };

          const type_default_management = $jssip.phone.meta.type_default_management;

          const messageText = type_default_management === 1 ? "el log" : "la tipificación";

          try {
            await store.dispatch("saveDefaultManagement", defaultTypificationBody);
            message.success(`Se ha guardado ${messageText} por defecto correctamente`);
          } catch (error) {
            message.error(`No se pudo guardar ${messageText} por defecto`);
          }
        }

        await resolveTicketById(ticketId, body);
        store.dispatch("fetchActiveWorkspaceTickets");

        EventBus.$emit(WorkspaceEvent.TICKET_CLOSED, { resolveWithoutTypification: true });
        EventBus.$emit(WorkspaceEvent.RESET_LIST);
        handleCloseModal(false);
      });
    };

    return {
      maxTextCountObservation,
      observationTextCount,
      rules,
      formState,
      formRef,
      handleSubmit,
      visible,
      handleShowModal,
      handleCloseModal,
      isIdentifierTypified,
      isResolved,
      buttonType,
      ticket,
      channel,
    };
  },
});
</script>

<style lang="sass" scoped>
.container
  margin: auto
  p
    font-size: 14px
    line-height: 22px
    color: $gray-10
    margin-bottom: 4px

.description
  font-size: $font-normal
  line-height: 22px
  text-align: center
  color: $gray-9
.textarea
  position: relative
  border: 1px solid $gray-5
  border-radius: 4px
  width: 100%
  height: 100px
  margin: auto

  &::placeholder
    color: $gray-6
  &:hover
    border: 1px solid $blue-5
  &:focus
    border: 1px solid $blue-5
.counter
  position: absolute !important
  right: 12px
  bottom: -20px
  font-size: $font-small
  color: $gray-6
:deep(.ant-modal-footer)
  text-align: center

.modal-button
  min-height: 32px
  height: 100%
  width: 100%
  padding: 0

.footer
  display: flex
  align-items: center
  justify-content: space-between
</style>
