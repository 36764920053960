<template>
  <div class="phone__footer" :class="{ 'acw-floating': floating }">
    <div class="timeACW" v-if="show && isCallEnded">
      <p class="timeACW__title">ACW</p>
      <p class="timeACW__description">Recuerda tipificar antes que se termine el tiempo</p>
      <p class="timeACW__time">
        {{ seconds }}
      </p>
    </div>
  </div>
</template>

<script>
/**
 * Componente que muestra el ACW
 */
import EventBus from "@/app/shared/utils/eventBus";
import { WorkspaceEvent } from "@/app/workspace/domain/events";
import { EventManagement } from "@/app/shared/utils/enums";
import { defineComponent, ref, computed, inject } from "vue";
import moment from "moment-timezone";

export default defineComponent({
  setup() {
    const availableTime = ref(0);
    const floating = inject("floating");
    const seconds = computed(() => {
      return moment(availableTime.value * 1000).format("mm:ss");
    });
    return {
      availableTime,
      seconds,
      floating,
    };
  },
  data() {
    return {
      interval: null,
      key: 0,
    };
  },
  mounted() {
    /**
     * Recibe el evento de show acw el cual contiene el tiempo de acw enviado desde el worker del layout principal
     */
    EventBus.on(WorkspaceEvent.SHOW_ACW, (data) => {
      if (data.ticket == this.$jssip.phone?.meta?.ticket) this.availableTime = data.time;
    });
    EventBus.on(WorkspaceEvent.RECALL, () => {
      this.availableTime = 0;
    });
  },
  computed: {
    // currentTicket() {
    //   const tickets = this.$store.getters["getWorkspaceTickets"];
    //   const { ticket } = this.$jssip.phone?.meta;
    //   return tickets.find((item) => item.id == ticket);
    // },
    isCallEnded() {
      const statuses = ["finished", "failed"];
      return statuses.includes(this.$jssip.phone.status);
    },
    isCurrentTicket() {
      const ticketId = this.$route.query?.ticket;
      const interactionId = this.$route.query?.interaction;
      // const { ticket, channel_person } = this.$jssip.phone?.meta;
      // return ticketId == ticket && channelId == channel_person;

      return this.$jssip.phone.isCurrentCallTicketInteraction(ticketId, interactionId);
    },
    globalState() {
      return this.$store.getters["getStateUser"];
    },
    /**
     * Muestra el ACW si la llamada actual esta terminada y si el ticket actual corresponde al de la llamada terminada
     */
    show() {
      // const hasAcwState = this.currentTicket?.time_acw || !this.currentTicket?.channel_person;
      const isInAcwState = this.globalState === EventManagement.Acw;

      return this.availableTime > 0 && isInAcwState && (this.isCurrentTicket || this.floating);
    },
  },
});
</script>

<style lang="sass" scoped>
.phone
  &__footer
    text-align: center
    display: grid
    place-content: center
    margin: 5px 0px
    min-height: 35px

.acw-floating
  min-height: 85px

.timeACW
  text-align: center

  &__title
    font-weight: 600
    font-size: $font-medium
    line-height: 24px
    color: $orange-7
    margin-bottom: 2px

  &__description
    font-size: $font-small
    line-height: 20px
    color: $red-4
    margin-bottom: 2px

  &__time
    font-weight: 600
    font-size: $font-normal
    color: $orange-7
</style>
