<template>
  <div style="width: 100%; font-size: 12px" :class="{ fixed: fixed }">
    <template v-if="showIvrSurveyMessage">
      <component
        :is="inModal ? Alert : 'div'"
        class="ivr-message"
        :class="{ floating: floating && !inModal, 'change-state-message': !inModal }"
        type="info"
      >
        <template #[templateName]>
          <p>
            <span v-if="portfolioSurveyType === SentType.ByUser">
              Selecciona para enviar encuesta
            </span>
            <span v-else>Se enviará una encuesta al finalizar la llamada</span>
            <a-tooltip :title="`Encuesta: “${jssip.phone.meta.ivr?.ivr?.name}”`">
              <InfoCircleOutlined style="margin-left: 5px" />
            </a-tooltip>
          </p>

          <a-checkbox
            v-if="portfolioSurveyType === SentType.ByUser"
            :checked="jssip.phone.meta.ivr?.shouldSent"
            @update:checked="handleChange"
          >
            Enviar encuesta al finalizar llamada
          </a-checkbox>
        </template>
      </component>
    </template>

    <a-divider v-if="showIvrSurveyMessage && waitingChangeStateMessage" class="divider" />

    <template v-if="waitingChangeStateMessage">
      <div class="change-state-message" :class="{ floating: floating }">
        Al terminar esta gestión, tu estado cambiará a “{{ waitingChangeStateMessage.text }}”
      </div>
    </template>
  </div>
</template>

<script setup lang="ts">
import { computed, watchEffect } from "vue";
import { useStore } from "vuex";
import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { Alert } from "ant-design-vue";

import { useJssip } from "@/app/shared/composables/useJssip";
import { CallStatus } from "@/packages/vue-jssip/jssip/phone";
import { SentType } from "@/@types/ivr/sent";

const props = defineProps<{ fixed?: boolean; floating?: boolean; inModal?: boolean }>();

const emit = defineEmits<{ changeVisibility: [visibility: { state: boolean; survey: boolean }] }>();

const store = useStore();
const jssip = useJssip();

const handleChange = (isChecked: boolean) => {
  if (jssip.phone.meta.ivr?.shouldSent === null) return;
  if (jssip.phone.meta.ivr?.shouldSent === undefined) return;

  jssip.phone.meta.ivr.shouldSent = isChecked;
};

const templateName = computed<string>(() => (props.inModal ? "description" : "default"));

const waitingChangeStateMessage = computed<{ text: string } | null>(
  () => store.getters["getWaitingChangeStateMessage"]
);

const portfolioSurveyType = computed<SentType | undefined | null>(
  () => jssip.phone.meta.ivr?.survey_submission_type
);

const showIvrSurveyMessage = computed<boolean>(() => {
  const isInCall = jssip.phone.status === CallStatus.InCall;
  const hasIvrSentType = portfolioSurveyType.value
    ? [SentType.Automatic, SentType.ByUser].includes(portfolioSurveyType.value)
    : false;

  return isInCall && hasIvrSentType;
});

watchEffect(() => {
  emit("changeVisibility", {
    state: Boolean(waitingChangeStateMessage.value),
    survey: showIvrSurveyMessage.value,
  });
});
</script>

<style lang="sass" scoped>
.change-state-message
  min-height: 28px
  color: $gray-9
  font-size: 12px
  background-color: #FFE58F
  right: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  flex-wrap: wrap
  text-align: center
  padding: 5px 10px
  animation: message-blink 1.5s infinite
  width: 100%

  &.floating
    position: relative
    min-height: 50px
    // bottom: 0

.fixed
  top: 0
  position: absolute

.divider
  margin: 0
  background-color: $gray-4

p
  margin: 0

.ivr-message.ant-alert
  padding: 9px 16px !important
</style>
