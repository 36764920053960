import httpManager from "@/app/shared/utils/httpManager";
/**
 * Editar una capaña
 *
 * @param {Object} portfolio Datos de la campaña a editar
 *
 * @return {Promise<Object>} Datos de la campaña editada
 */
export async function updatePortfolio(portfolioId, portfolio) {
  const body = portfolio;
  if (!body.is_enable_time_acw) delete body.time_acw;
  if (!body.is_enable_call_time) delete body.call_time;
  if (!body.description) delete body.description;
  body.text_negotiation = body.text_negotiation || "Sin speech";
  const url = `/portfolios/${portfolioId}/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
