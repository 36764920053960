const getters = {
  getTemporalUserEmail(state) {
    return state.temporalUser?.email;
  },
  getTemporalUserName(state) {
    return state.temporalUser?.name;
  },
  getRegistrationProgress(state) {
    return state.step;
  },
  getTemporalForm(state) {
    return state.form;
  },
  isThereTemporalUser(state) {
    return !!state.temporalUser;
  },
  hasCustomerRegisterPassword(state) {
    return !!state.customer?.password;
  },
  hasCustomerRegisterBusinessName(state) {
    return !!state.customer?.name;
  },
  getNewUserInviteEmail(state) {
    return state.newUserInvite?.email;
  },
  getNewUserInviteBusiness(state) {
    return state.newUserInvite?.business_name;
  },
  getNewUser(state) {
    return state.newUser;
  },
  getCompanyTimeZone(state) {
    return state.zoneTime;
  },
  getRegisterStep(state) {
    return state.registerStep;
  },
  getRegisterWorkspaceStep(state) {
    return state.registerWorkspaceStep;
  },
  getInformationWorkspace(state) {
    return state.customer;
  },
};

export default getters;
