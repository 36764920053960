<template>
  <!-- <div style="padding-left: 10px; background-color: aliceblue">
    Filtros || {{ filters }} <br />
    -----------------------------------------------------------------<br />
    Identificador Sellecionado || {{ selectedCampaign }} <br />
    -----------------------------------------------------------------<br />
    Campañas abiertas || {{ openKeys }} ------------------------------- Identificador seleccionado
    || {{ selectedKeys }}<br />
    -----------------------------------------------------------------<br />
    Items Menus || {{ menuItems }} <br />
    -----------------------------------------------------------------<br />
    Menú seleccionado || {{ selectedMenu }} <br />
    -----------------------------------------------------------------<br />
    Inbox seleccionado || {{ selectedThreadsInbox }} <br />
  </div> -->
  <div class="head">
    <div class="information">
      <h1>{{ selectedMenu }}</h1>
      <div v-if="!(selectedMenu === 'Resueltos')" class="campaign">
        <span v-if="selectedCampaign" class="title">{{ selectedCampaign.title }}</span>
        <div class="email-info" v-if="selectedCampaign">
          <span>Saliente: &lt;{{ selectedCampaign.outgoingEmail }}&gt;</span>
          <span>Entrante: &lt;{{ selectedCampaign.incomingEmail }}&gt;</span>
        </div>
      </div>
    </div>
    <div class="action-icons">
      <FilterOutlined @click="open = true" v-if="isAssigned && !isVisibleEmail" />
      <a-input
        v-model:value="filters.search"
        class="search"
        size="small"
        placeholder="buscar por correo, ID o asunto"
        v-if="!isVisibleEmail"
      >
        <template #prefix>
          <search-outlined />
        </template>
      </a-input>

      <ActionsCard
        :showOnlyIcons="isVisibleEmail && isAdmin"
        :isAssigned="isAssigned"
        :currentMessage="currentMessage"
        :role="isAdmin"
        :selectedRows="[selectedRow]"
        @resolved="emit('toResolved')"
      />
    </div>
  </div>
  <FilterEmail v-if="open" v-model:open="open" />
</template>
<script lang="ts" setup>
import { ref, computed, watch, inject, Ref } from "vue";
import { FilterOutlined, SearchOutlined } from "@ant-design/icons-vue";
import FilterEmail from "../modals/FilterEmail.vue";
import ActionsCard from "./ActionsCard.vue";
import { useStore } from "vuex";
import { EmailDetails, Thread } from "@/@types/emails";
import _ from "lodash";
import { useRoute } from "vue-router";
import { UserData } from "@/@types/global";

defineProps<{
  isVisibleEmail: boolean;
  isAssigned: boolean;
  currentMessage: EmailDetails;
  isAdmin: boolean;
  selectedRow: Thread;
}>();

const open = ref<boolean>(false);

const emit = defineEmits(["toResolved"]);
const store = useStore();
const selectedMenu = computed(() => {
  return store.getters.getSelectedMenu;
});
const selectedCampaign = computed(() => {
  return store.getters.getSelectedCampaign;
});
const menuItems = computed(() => {
  return store.getters.getMenuItems;
});
const selectedThreadsInbox = computed(() => {
  return store.getters.getSelectedThreadsInbox;
});

// const search = ref(null);

/*
  #TODO: Cambiar estructura de filtros de inbox para que use estados. 
  Usar vuex para estos valores ya que el listado del inbox depende de estos
*/
// const search = inject("filterSearch") as Ref<string | "">;
// const read = inject("filterRead") as Ref<boolean | "">;
// const selectedTags = inject("filterSelectedTags") as Ref<Array<number | "">>;
const filters = computed(() => {
  return store.getters.getFilters;
});
const openKeys = computed(() => store.getters.getOpenKeysMenuItems);
const selectedKeys = computed(() => store.getters.getSelectedKeysMenuItems);

/*
  #TODO: Mejorar acción SET_THREADS_INBOX, deberia tomar todos los valores necesarios de state y query
  Para poder actualizar el listado de inbox de una manera más rápida desde acciones como resolver, transferir,
  mover, asignar, etc, ya que actualmente el código es muy engorroso y dificulta agregar parametros a la petición.
*/
// const fetchList = inject("fetchList") as (query: {
//   channelCustomerId?: number;
//   assigned: boolean;
//   resolved?: boolean;
//   read?: boolean | "";
//   tagList?: Array<number | "">;
// }) => Promise<void>;

// const route = useRoute();

// const getUrlParams = (): { channelCustomerId: number; assigned: boolean; resolved?: boolean } => {
//   const { channelCustomerId, assigned, resolved } = route.query;

//   return {
//     channelCustomerId: Number(channelCustomerId),
//     assigned: assigned === "true",
//     resolved: resolved === " true",
//   };
// };

// const user = computed<UserData>(() => store.getters["getDataUser"]);

watch(
  filters.value.search,
  _.debounce(async () => {
    await store.dispatch("updateThreadsInbox", {
      assignedUser: store.getters["getDataUser"]?.id,
      resetPagination: true,
    });

    // const params = getUrlParams();

    // store.commit("SET_THREADS_INBOX", []);
    // fetchList({
    //   ...params,
    //   read: filters.value.read,
    //   tagList: filters.value.selectedTags,
    //   search: filters.value.search,
    // });

    // store.commit("SET_THREADS_INBOX", []);
    // const assignedUser = route.query.assigned ? user.value.id : undefined;

    // await store.dispatch("updateThreadsInbox", {
    //   ...{ ...params, read: read.value, tagList: selectedTags.value, search: search.value },
    //   assignedUser: assignedUser,
    // });
  }, 600),
  { deep: false }
);
</script>
<style lang="sass" scoped>
.head
  display: flex
  padding: 5px 12px
  align-items: center
  justify-content: space-between
  box-shadow: 0 4px 2px -2px #C5C5C5
  z-index: 2
  .information
    display: flex
    gap: 15px
    align-items: center
    h1
      margin: 0
      font-size: 20px
    span
      font-size: $font-medium
      color: $gray-7

    .campaign
      display: flex
      align-items: center
      margin-left: 10px
      .title
        margin-right: auto
        color: $gray-8
        font-weight: 500
        padding-right: 25px
        font-size: 18px
      .email-info
        text-align: left
        margin-left: auto
        span
          display: block
          font-size: 14px
  span
    font-size: 24px
    color: $gray-7

  .action-icons
    display: flex
    > *
      margin-left: 10px
    .search
      font-size: 16px
      .anticon
        font-size: 14px
</style>
