import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los usuarios registrados.
 *
 * @return {Promise<Array>} Lista de usuarios.
 */
export default async function () {
  const response = await httpManager.get("/users/active/?limit=120&offset=0");
  return response?.data?.results;
}
