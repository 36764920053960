import httpManager from "@/app/shared/utils/httpManager";

export default async function ({ payload, type }) {
  let url;
  switch (type) {
    case "group":
      url = "/entities/group/";
      break;
    case "result":
      url = "/entities/results/";
      break;
    case "motive":
      url = "/entities/motives/";
      break;
    case "submotive":
      url = "/entities/submotives/";
      break;
    default:
      break;
  }
  const response = await httpManager.post(url, payload);
  return response.data;
}
