<template>
  <div class="background">
    <main-logo-register class="logo__main" />
    <div>
      <section class="container">
        <div class="container__info">
          <p class="name">Lo sentimos, tu cuenta ha sido eliminada</p>
          <p class="name">por completo 😔</p>
        </div>
        <div class="information">
          <p class="info">Te invitamos a que vuelvas a crear tu equipo de trabajo y volver a</p>
          <p class="info">laborar con nosotros.</p>
          <p class="info__welcome">¡Bienvenidos los nuevos comienzos!</p>
        </div>
        <div class="separator"></div>
      </section>
      <section class="drawing">
        <a-button type="primary" class="buttom__register" @click="routerRegister"
          >Volver a registrarse</a-button
        >
      </section>
    </div>
  </div>
</template>

<script>
import MainLogoRegister from "@/app/shared/components/molecules/MainLogoRegister.vue";

import { defineComponent } from "vue";
export default defineComponent({
  components: {
    MainLogoRegister,
  },
  methods: {
    routerRegister() {
      this.$router.push("/register");
    },
  },
});
</script>

<style lang="sass" scoped>


.container
  text-align: center
  margin-bottom: 20px

.drawing
  margin-top: 50px
  text-align: center

.container__info
  margin: 0 auto
  margin-top: 100px
  text-align: center
  margin-bottom: 20px

.name
  font-weight: 600
  font-size: 30px
  margin-bottom: 0

.separator
  margin-bottom: 30px

.button
  width: 100%

.information
  font-size: 20px
  color: $gray-9

.logo__main
  background-color: #ffffff
  padding: 12px 0 0
  margin-top: -20px

.background
  background-color: $background-main

.info
  margin: 0

.info__welcome
  margin-top: 30px

.buttom__register
  width: 24%
</style>
