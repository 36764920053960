import httpManager from "@/app/shared/utils/httpManager";

/**
 * Eliminar un correo.
 *
 * @param {number} referralsId Identificador del referido.
 */
export async function deleteReferralsContact(referralsId) {
  await httpManager.delete(`/contacts/clients/contacts/${referralsId}/`);
}
