<template>
  <div class="phone">
    <!-- <div class="change-state-message fixed" v-if="waitingChangeStateMessage"> -->
    <!--   Al terminar esta gestión, tu estado cambiará a “{{ waitingChangeStateMessage.text }}” -->
    <!-- </div> -->
    <InformationCard fixed />

    <!-- This webphone component is: @/packages/webphone/components/WebPhone -->
    <web-phone in-workspace />
  </div>
</template>

<script>
import InformationCard from "@/packages/webphone/components/cards/InformationCard.vue";

export default {
  components: {
    InformationCard,
  },
  computed: {
    waitingChangeStateMessage() {
      return this.$store.getters["getWaitingChangeStateMessage"];
    },
  },
};
</script>

<style lang="sass" scoped>
.phone
  position: relative
</style>
