import httpManager from "@/app/shared/utils/httpManager";
import { tokenManager } from "@/app/shared/utils/tokenManager";

const initialState = () => {
  const access_token = tokenManager.getToken();

  // Asegurarse que httpManager esté inicializado antes de usarlo
  if (!httpManager) return { access_token: null, workspaces: [] };
  if (access_token) httpManager?.setHeader();
  return { access_token, workspaces: [] };
};

const state = initialState();

export default state;
