const getters = {
  getUserList(state) {
    return state.usersInfo.items;
  },
  getUsersInfo(state) {
    return {
      items: state.usersInfo.items,
      pagination: state.usersInfo.pagination,
    };
  },
  getUser(state) {
    return state.user;
  },
};

export default getters;
