<template>
  <AccountTemplate />
</template>
<script>
import AccountTemplate from "@/app/settingsMenu/components/template/AccountTemplate.vue";

export default {
  components: {
    AccountTemplate,
  },
};
</script>
<style lang="sass" scoped>
.container__info
  position: absolute
  left: 60px
  right: 0
  bottom: 0
  top: 52px
</style>
