<template>
  <a-form-item
    :name="input.name"
    :label="index ? `${index}. ${input.label}` : input.label"
    :rules="rules"
  >
    <a-input
      :value="workspaceFormState?.[input.name]"
      :placeholder="placeholderText"
      :disabled="conditionedAttributes?.disabled"
      show-count
      :maxlength="70"
      @update:value="handleChange"
    />

    <p class="instruction">{{ input.instruction }}</p>
  </a-form-item>
</template>

<script setup lang="ts">
import { inject, computed } from "vue";

import {
  ConditionedAttribute,
  ElementType,
  FormElementMap,
  ValidationType,
} from "@/@types/entities/forms";
import { ValidatorByType } from "@/app/entities/utils/forms/textValidators";

const props = defineProps<{
  input: FormElementMap[ElementType.ShortText];
  index: number;
  isWorkspace?: boolean;
  conditionedAttributes?: ConditionedAttribute;
  isPerProduct?: boolean;
  hasInputsFilled?: boolean;
}>();

const workspaceFormState = inject("workspaceFormState") as Record<string, string | null>;

const placeholderText = computed(() => {
  const getText = (text?: string) => `Escribe aquí${text ? ` ${text}` : "..."}`;

  if (props.input.validation === ValidationType.TextAndNumber) return getText("texto o números");
  if (props.input.validation === ValidationType.Text) return getText("solo texto");
  if (props.input.validation === ValidationType.Number) return getText("solo números");
  if (props.input.validation === ValidationType.Email) return getText("solo correos electrónicos");

  return getText();
});

const isRequired = computed(() => {
  if (props.isPerProduct && !props.hasInputsFilled) return false;

  if (typeof props.conditionedAttributes?.required === "undefined") return props.input.required;

  return props.conditionedAttributes.required;
});

const rules = computed(() => [
  {
    required: isRequired.value,
    validator: ValidatorByType[props.input.validation],
    trigger: props.isPerProduct ? ["blur", "change"] : "change",
  },
]);

const handleChange = (value: string | null) => {
  if (!props.isWorkspace) return;

  workspaceFormState[props.input.name] = value;
};

const initialValue = null;

defineExpose({ initialValue });
</script>

<style scoped lang="sass">
.ant-form-item, p
  margin: 0

.instruction
  color: $gray-7
  line-height: 22px

:deep(.ant-input-suffix)
  position: absolute
  margin: 0 !important
  font-size: 12px
  bottom: 0
  right: 5px
</style>
