export const coinSelectOptions = [
  {
    value: "SOLES",
    label: "Soles",
  },
  {
    value: "DOLARES",
    label: "Dólares",
  },
];
