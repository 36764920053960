import httpManager from "@/app/shared/utils/httpManager";
/**
 * Transferir ticket a cola.
 *
 * @param {number} ticketId Identificador del ticket
 * @param {number} queueId Identificador del agente
 *
 */
export async function transferTicketToQueue(ticketIdList, queueId, origin, isCall = false, isAsync = false) {
  const body = {
    tickets: ticketIdList,
    queue: queueId,
    origin: origin, // Origin 2 is for tickets manager | 1 calls or workspace
    is_call: isCall,
    is_async: isAsync,
  };

  const response = await httpManager.post(`/tickets/transfer-to-queue/`, body);

  return response.data;
}
