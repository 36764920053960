<template>
  <a-table
    :columns="COLUMNS_MANAGE_VARS"
    :data-source="dataSorted"
    size="middle"
    :loading="props.loading"
    :pagination="pagination"
    :locale="{ emptyText: 'No se encontraron variables' }"
    @change="handleChangePagination"
    :scroll="{ y: 'calc(100vh - 20em)' }"
    class="table-scroll"
  >
    <template #bodyCell="{ text, column, record }">
      <template v-if="column.key === 'chatbots'">
        <span v-if="text && !!text.length">{{ quantityChatbotsAssigned(text) }}</span>
        <em v-else>Sin chatbots asignados</em>
        <!--POPOVER-->
        <a-popover v-if="text && !!text.length" :title="quantityChatbotsAssigned(text)">
          <template #content>
            <ul class="popover__list">
              <li v-for="chatbot in text" :key="chatbot.key">{{ chatbot.name }}</li>
            </ul>
          </template>
          <info-circle-outlined class="mrg-left-8" />
        </a-popover>
      </template>
      <!--ACCIONES-->
      <template v-if="column.key === 'action'">
        <a-tooltip title="Editar">
          <a @click="handleShowEdit(record)">
            <EditOutlined />
          </a>
        </a-tooltip>
        <a-divider type="vertical" />
        <a-tooltip title="Eliminar">
          <a @click="handleShowDelete(record)">
            <DeleteOutlined />
          </a>
        </a-tooltip>
      </template>
    </template>
  </a-table>
</template>
<script setup lang="ts">
import { computed, ref } from "vue";
import { COLUMNS_MANAGE_VARS } from "@/app/manageVars/utils/manageVars";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";
import { InfoCircleOutlined, EditOutlined, DeleteOutlined } from "@ant-design/icons-vue";
import { VarItem, ChatbotAssigned } from "@/@types/manageVars";
import { Pagination } from "@/@types/global";

const props = defineProps<{
  dataSource: VarItem[] | undefined;
  loading: boolean;
  total: number;
}>();

const emits = defineEmits(["onUpdate", "onDelete", "onChangePagination"]);

const dataSorted = computed(() => {
  return props.dataSource;
});

const currentPage = ref(1);

const pagination = computed(() => {
  return {
    hideOnSinglePage: true,
    defaultPageSize: TABLE_PAGE_SIZE,
    size: "default",
    total: props.total,
    current: currentPage.value,
  };
});

const quantityChatbotsAssigned = (chatbotsAssigned: ChatbotAssigned[]) => {
  const length = chatbotsAssigned.length;
  return length > 1 ? `${length} chatbots vinculados` : `${length} chatbot vinculado`;
};

const handleChangePagination = (pagination: Pagination) => {
  console.log("cambiar paginacion", pagination);
  currentPage.value = pagination.current;
  emits("onChangePagination", pagination);
};

const handleShowEdit = (record: VarItem) => {
  console.log("editar variable", record);
  emits("onUpdate", record);
};

const handleShowDelete = (record: VarItem) => {
  console.log("eliminar variable", record);
  emits("onDelete", record);
};
</script>
<style lang="sass">
.popover__list
  padding-left: 16px
</style>
