import httpManager from "../../../../shared/utils/httpManager";
/**
 * Buscar Espacio de trabajo.
 *
 * @return {Promise<Array>} Lista de trabajos.
 *
 * @param {Object} workspace Los espacios de  trabajos.
 */

export async function fetchAllWorkspace(workspace = "") {
  const url = `/customers/search-workspace?workspace=${workspace}`;
  const response = await httpManager.get(url, { isPublic: true });
  return response?.data;
}
