export const initialState = () => ({
  currentStrategyTag: {
    text: "",
    status: false,
    strategyType: null,
  },
  currentSequenceTag: {
    text: "",
    status: false,
  },
});

const state = initialState();

export default state;
