import httpManager from "@/app/shared/utils/httpManager";
import fetchChannelsCustomers from "@/app/channels/services/requests/fetchAllChannelsCustomers";
import { ChannelType } from "@/app/shared/utils/enums";
import {
  DataType,
  EmailDetails,
  IMenutem,
  IUsers,
  Thread,
  ThreadDetailType,
  ThreadDetails,
} from "@/@types/emails";
import { ChannelCustomer, UserData } from "@/@types/global";
import { SendEmailDto } from "@/@types/emails/dtos";
import { dateCalendar } from "@/app/shared/utils/dates";
import { portfolioService } from "@/app/entities/services/Portfolio.service";
import { toFormData } from "axios";
import { FormAppealEmail, UnsubscribeEmail } from "@/@types/emails/forms";
import { getPaginationQuery } from "@/app/shared/utils/helpers";
import dayjs from "dayjs";

interface UpdateEmailDto {
  assigned_user: number;
  attachments_url: string[];
  bcc_emails: string[];
  cc_emails: string[];
  channel_customer: number;
  client: null | number;
  from_email: string;
  id: number;
  message: string;
  person: null | number;
  portfolio: null | number;
  status_sent: number;
  subject: string;
  tag: number[];
  to_emails: string[];
  tag_list: number[];
  resolved: boolean;
  comment_resolved: string;
  to_email: string;
  delete_tags_id?: number[];
}

interface TransferEmailDto {
  email_threads: number[];
  channel_customer: number;
}

class EmailService {
  private baseUrl = "/mail";

  async getAllEmails(): Promise<{ id: number; name: string; email: string }[]> {
    const emailChannels = (await fetchChannelsCustomers(
      ChannelType.Email,
      ""
    )) as ChannelCustomer[];

    const emailLinkedWithPortfolio = emailChannels.filter(
      (channel) => channel.is_associate_portfolio
    );

    return emailLinkedWithPortfolio.map((email) => ({
      id: email.id,
      email: email.associate || "",
      name: email.alias || email.associate || "",
    }));
  }

  async getEmailsMenuList(userId?: number): Promise<IMenutem[]> {
    const mailPortfolios = await portfolioService.getAssignedChannelCustomers(
      ChannelType.Email,
      userId
    );

    return mailPortfolios.map((p) => ({
      id: p.id,
      title: p.name,
      subMenu: `sub${p.id}`,
      subItems: p.channel_customer.map((c) => ({
        id: c.id,
        title: c.alias || c.identifier || "",
        email: c.identifier || "",
        emailIncoming: c.imap_user || "",
        buttonLabel: String(c.total_email ?? 0),
        isActive: false,
        unread: c.unread,
      })),
    }));
  }

  async getInboxList(
    available: boolean,
    channelCustomerId: number | undefined,
    assigned: number | undefined,
    resolved?: boolean,
    read?: boolean,
    tagList?: Array<number | string>,
    emailThreadStatus?: number,
    search?: string | undefined,
    pagination = 1
  ): Promise<{ count: number; results: DataType[] }> {
    let url = `${this.baseUrl}/threads/?offset=${50 * (pagination - 1)}&available=${available}`;

    if (channelCustomerId && !resolved) url += `&channel_customer=${channelCustomerId}`;
    // if (assigned && !resolved) url += `&assigned_user=${assigned}`;
    if (assigned) url += `&assigned_user=${assigned}`;
    if (resolved) url += `&email_thread_status=3`;
    else if (emailThreadStatus) url += `&email_thread_status=${emailThreadStatus}`;
    if (read != undefined) url += `&read=${read}`;
    if (tagList && !tagList?.includes("")) url += `&tag_ids=${tagList.join(",")}`;
    if (search) url += `&search=${search}`;

    const response = await httpManager.get(url);
    return {
      ...response.data,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      results: response.data.results.map((r: any) => ({
        key: r.id,
        number: r.id,
        id: r.id,
        email: r.last_email_message.from_email,
        subject: r.last_email_message.subject,
        messagePreview: r.last_email_message.message_preview,
        time: dateCalendar(r.last_email_message.email_created_at),
        tags: r.tag,
        referenceId: r.last_email_message.reference_id,
        read: r.read,
        statusInbox: r.status_inbox,
      })),
    };
  }

  async getEmailDetails(emailId: number): Promise<EmailDetails> {
    const url = `${this.baseUrl}/${emailId}/`;

    const response = await httpManager.get(url);
    const data = response.data;

    return {
      id: data.id,
      avatar: "",
      code: 0,
      content: data.message,
      date: dayjs(data.created_at).format("DD/MM/YYYY"),
      time: dayjs(data.created_at).format("HH:mm"),
      hasActionMessage: false,
      notes: data.notes,
      hasNote: data.notes?.length > 0,
      receivers: data.to_emails,
      sender: data.from_email,
      ccEmails: data.cc_emails,
      bccEmails: data.bcc_emails,
      subject: data.subject,
      show: false,
      tags: data.tag,
      client: data.client,
      resolved: data.resolved,
      parent: data.parent,
      referenceId: data.reference_id,
      channelCustomer: data.channel_customer,
      createdAt: data.created_at,
    };
  }

  async getThreadDetails(referenceId: number): Promise<Thread> {
    const url = `${this.baseUrl}/threads/${referenceId}/`;

    const response = await httpManager.get(url);
    const data = response.data;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataMessages = data?.email_messages?.map((el: any, pos: number) => ({
      key: el.key,
      id: el.id,
      createdAt: el.created_at,
      type: ThreadDetailType.Email,
      show: pos == data?.email_messages.length - 1,
      elementEmail: {
        id: el.id,
        avatar: "",
        code: 0,
        content: el.message,
        date: dayjs(el.created_at).format("DD/MM/YYYY"),
        time: dayjs(el.created_at).format("HH:mm"),
        hasActionMessage: false,
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        notes: el.notes.map((elNote: any) => ({
          ...elNote,
          userData: elNote.user_data,
          date: dayjs(elNote.created_at).format("DD/MM/YYYY"),
          time: dayjs(elNote.created_at).format("HH:mm"),
        })),
        hasNote: el.notes?.length > 0,
        receivers: el.to_emails,
        sender: el.from_email,
        ccEmails: el.cc_emails,
        bccEmails: el.bcc_emails,
        subject: el.subject,
        show: true,
        tags: el.tag,
        client: el.clients,
        resolved: el.resolved,
        referenceId: el.reference_id,
        channelCustomer: el.channel_customer,
        createdAt: el.created_at,
        attachmentsPublicUrl: el.attachments_public_url,
        attachmentsUrl: el.attachments_url,
      },
    }));

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const dataLogs = data?.email_thread_logs?.map((el: any) => ({
      createdAt: el.created_at,
      type: ThreadDetailType.Log,
      show: false,
      elementLog: {
        userId: el.user_id,
        message: el.message,
        createdAt: el.created_at,
      },
    }));

    const dataSort = [...dataMessages, ...dataLogs].sort(
      (a, b) => new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
    );

    return {
      id: data.id,
      key: data.id,
      tags: data.tag,
      client: data.client,
      associatedClients: data.associated_clients,
      details: dataSort,
      ticket: data.ticket,
      assignedUser: data.assigned_user,
    };
  }

  async assignEmailsToUser(emails: number[], userId: number) {
    const url = `${this.baseUrl}/threads/assign_user/${userId}/`;

    await httpManager.put(url, { email_threads: emails });
  }

  async sendEmail(sendEmailDto: SendEmailDto) {
    const url = `${this.baseUrl}/`;

    const formData = toFormData(sendEmailDto);
    console.log(sendEmailDto?.attachments, 'sendEmailDto?.attachments');
    if (sendEmailDto?.attachments)
      for (let i = 0; i < sendEmailDto?.attachments?.length; i++) {
        const encodedFilename = encodeURIComponent(sendEmailDto.attachments[i]?.name);
        formData.append('attachments', sendEmailDto.attachments[i], encodedFilename);
      }
    await httpManager.post(url, formData);
  }

  async editThreadEmail(id: number, updateEmailDto: Partial<UpdateEmailDto>) {
    const url = `${this.baseUrl}/threads/${id}/`;

    return await httpManager.put(url, updateEmailDto);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  async createClientToEmail(id: number, client: any) {
    const url = `${this.baseUrl}/threads/${id}/assign-clients/`;

    return await httpManager.post(url, client);
  }

  async massiveTag(emailIds: number[], tags: { tagsToAdd: number[]; tagsToRemove: number[] }) {
    const url = `${this.baseUrl}/threads/assign-tags/`;

    return await httpManager.post(url, {
      email_thread_ids: emailIds,
      tag_ids: tags.tagsToAdd,
      delete_tag_ids: tags.tagsToRemove,
    });
  }

  async massiveResolve(emailId: number[], comment?: string) {
    const url = `${this.baseUrl}/threads/resolved/`;

    await httpManager.post(url, {
      email_thread_ids: emailId,
      comment_resolved: comment,
    });
  }

  async appealEmailSuspension(body: FormAppealEmail) {
    const url = `${this.baseUrl}/suspended/appeal/`;

    await httpManager.post(url, body);
  }

  async unsubscribeEmail(body: UnsubscribeEmail) {
    const url = `${this.baseUrl}/unsubscribe/`;
    await httpManager.post(url, body);
  }
  async getUnsubscribeEmails({
    filters,
    page = 1,
  }: {
    filters?: any;
    page?: number;
  } = {}): Promise<any> {
    const paginationQuery = getPaginationQuery(page, 20);
    const email = filters?.email || "";
    const identifier = filters?.identifier || "";
    const query = `?${paginationQuery}&email=${email}&identifier=${identifier}`;
    const url = `${this.baseUrl}/unsubscribe/`;
    const response = await httpManager.get(`${url}${query}`);
    return response.data;
  }

  async subscribeEmail(id: number) {
    const url = `${this.baseUrl}/unsubscribe/${id}`;
    await httpManager.delete(url);
  }

  async downloadUnsubscribeReport(filters: { email: string }) {
    const url = `${this.baseUrl}/unsubscribe/download/?email=${filters.email || ""}`;

    return await httpManager.getBlob(url);
  }

  async getUsers(): Promise<IUsers[]> {
    const url = `/users/active/?limit=120&offset=0&linked_to_email=true`;

    const response = await httpManager.get(url);

    // return response.data.;
    return response.data.results.map((r: UserData) => ({
      id: r.id,
      name: r.fullname,
      avatar: r.picture,
      campaign: r.portfolios.map((p) => ({
        id: p.id,
        name: p.portfolio,
        entity: p.entity,
        containsEmailChannel: p.contains_email_channel,
      })),
      entities: r.entities,
      state: r.state,
      rawUser: r,
    }));
  }

  async unsubscribeEmailByRoute(email: string, id: number, comment: string) {
    const url = `${this.baseUrl}/strategies/unsubscribe/`;

    await httpManager.post(url, { email, strategy_message_id: id, description: comment });
  }

  async replyEmail(sendEmailDto: SendEmailDto) {
    const url = `${this.baseUrl}/replys/`;

    const formData = toFormData(sendEmailDto);
    if (sendEmailDto?.attachments)
      for (let i = 0; i < sendEmailDto?.attachments?.length; i++) {
        const encodedFilename = encodeURIComponent(sendEmailDto.attachments[i]?.name);
        formData.append('attachments', sendEmailDto.attachments[i], encodedFilename);
      }
    await httpManager.post(url, formData);
  }

  async transferThreadUser(idUser: number, transferEmailDto: Partial<TransferEmailDto>) {
    const url = `${this.baseUrl}/threads/assign_user/${idUser}/`;

    await httpManager.put(url, transferEmailDto);
  }

  async transferThreadCampaign(transferEmailDto: Partial<TransferEmailDto>) {
    const url = `${this.baseUrl}/threads/transfer/channel_customer/`;

    await httpManager.put(url, transferEmailDto);
  }

  async saveNote(note: { email: number; user: number; note: string }) {
    const url = `${this.baseUrl}/notes/`;

    return await httpManager.post(url, note);
  }

  async editNote(id: number, note: { email: number; user: number; note: string }) {
    const url = `${this.baseUrl}/notes/${id}/`;

    return await httpManager.put(url, note);
  }

  async deleteNote(id: number) {
    const url = `${this.baseUrl}/notes/${id}/`;

    return await httpManager.delete(url);
  }

  async changeStatus(emailIds: number[], status: boolean) {
    const url = `${this.baseUrl}/threads/assign-status-inbox/`;

    return await httpManager.post(url, {
      email_thread_ids: emailIds,
      status_inbox: status,
    });
  }
}

export const emailService = new EmailService();
