<template>
  <div :class="type == 'popover' ? 'cellphone__popover' : 'cellphone__wrapper'">
    <img :src="phoneImage" alt="cellphone" class="cellphone" />

    <div class="overlap">
      <div class="text" :class="{ preview: preview }">
        <CustomScrollbar :height="type == 'popover' ? '180px' : '290px'" auto>
          <FilePreviewCard
            v-if="templateType !== WhatsappTemplateTypes.Text"
            :preview="preview"
            :templateType="templateType"
            :file="file"
            :mediaLink="mediaLink"
          />

          <div v-html="state ? reactiveContent : content"></div>
          <!-- {{ state ? reactiveContent : content }} -->
        </CustomScrollbar>
        <span class="time">14:15</span>
      </div>
      <div class="group" v-if="buttons">
        <div v-if="buttons.length > 0">
          <div
            class="button"
            v-for="(button, index) of buttons"
            :key="button.text"
            :class="button.text && 'active'"
          >
            <IconPhoneCall
              v-if="button.type === WhatsappTemplateTypeSpecificButtons.PhoneNumber"
              :active="button.text"
            />
            <IconExternalLink
              v-if="button.type === WhatsappTemplateTypeSpecificButtons.Url"
              :active="button.text"
            />
            <IconReply
              :active="button.text"
              v-if="button.type === WhatsappTemplateTypeButtons.QuickReply"
            />
            <CopyOutlined
              style="font-size: 16px"
              v-if="button.type === WhatsappTemplateTypeSpecificButtons.OTP"
            />
            {{ button.text === null ? `Botón ${index + 1}` : button.text }}
          </div>
        </div>
      </div>
      <div v-else>
        <div v-if="templateButton === WhatsappTemplateButtons.WithButton" class="button">
          <template v-if="templateCategory === WhatsappTemplateCategories.OTP">
            <CopyOutlined />

            Copiar código
          </template>

          <template v-else>Botón 1</template>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, watch, inject } from "vue";
import { CopyOutlined } from "@ant-design/icons-vue";
import { cloneDeep } from "lodash";

import {
  WhatsappTemplateTypes,
  WhatsappTemplateButtons,
  WhatsappTemplateTypeSpecificButtons,
  WhatsappTemplateTypeButtons,
  WhatsappTemplateCategories,
} from "@/app/channels/utils/enums";

import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import FilePreviewCard from "../molecules/FilePreviewCard.vue";
import IconExternalLink from "@/app/common/components/atoms/IconExternalLink.vue";
import IconPhoneCall from "@/app/common/components/atoms/IconPhoneCall.vue";
import IconReply from "@/app/common/components/atoms/IconReply.vue";

import phoneImage from "@/app/workspace/assets/whatsapp-phone.svg?url";

export default defineComponent({
  components: {
    CustomScrollbar,
    IconExternalLink,
    IconPhoneCall,
    FilePreviewCard,
    IconReply,
    CopyOutlined,
  },
  props: {
    /**
     * State is the formState with the parameters, this parameters must be in the next format:
     *
     * {
     *   1: param1Value,
     *   2: param2Value,
     *   ...
     * }
     */
    state: {
      required: false,
      default: null,
    },
    content: {
      required: false,
      type: String,
    },
    buttons: {
      required: false,
      type: Array,
    },
    buttonType: {
      required: false,
      type: String,
    },
    preview: {
      default: false,
      type: Boolean,
    },
    templateType: [Number, String],
    templateCategory: String,
    file: {
      type: File,
      default: null,
    },
    mediaLink: {
      type: String,
      required: false,
    },
    type: {
      type: String,
      default: "normal", // popover
    },
  },
  setup(props) {
    const reactiveContent = ref(props.content);

    const templateButton = inject("templateButton");

    const type = ref(props.type);

    watch(props.state, (newState) => {
      for (const num in newState) {
        const singleParamRegex = new RegExp(`{{[${num}]}}`);

        const initialContentArray = props.content.split(" ");
        const paramIdx = initialContentArray.findIndex((word) => singleParamRegex.test(word));

        // const contentArray = reactiveContent.value.split(" ");
        const contentArray = cloneDeep(initialContentArray);
        const newParamText = initialContentArray[paramIdx].replace(
          singleParamRegex,
          newState[num] || `{{${num}}}`
        );

        contentArray[paramIdx] = newParamText;

        reactiveContent.value = contentArray.join(" ");
      }
    });
    watch(
      () => props.buttons,
      (newButtons) => {
        console.log("Buttons changed:", newButtons);
      }
    );

    return {
      WhatsappTemplateCategories,
      WhatsappTemplateTypes,
      reactiveContent,
      WhatsappTemplateButtons,
      WhatsappTemplateTypeSpecificButtons,
      WhatsappTemplateTypeButtons,
      templateButton,
      phoneImage,
    };
  },
});
</script>

<style lang="sass" scoped>
.cellphone
  width: 100%
  box-shadow: 0 0 10px 0 rgba(0,0,0,0.35)
  border-radius: 22px

  &__wrapper
    position: relative
    width: 102%

  &__popover.template-preview
    width: auto !important

.overlap
  color: $gray-8
  line-height: 22px
  position: absolute
  top: 18.5%
  right: 5%
  left: 5%
  .text
    max-height: 325px
    padding: 5px 10px
    background: white
    border-radius: 20px
    box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.25)
    margin-bottom: 10px
    &.preview
      color: $gray-6
  .button
    color: $gray-6
    padding: 5px 10px
    background: white
    border-radius: 20px
    box-shadow: 0px 0.5px 2px rgba(0, 0, 0, 0.25)
    text-align: center
    margin-bottom: 10px
    display: flex
    align-items: center
    justify-content: center
    gap: 10px
  .active
    color: #1677FF


  .time
    color: $gray-7
    width: 100%
    display: block
    text-align: right
    font-size: 12px

.cellphone__popover .overlap .text
  font-size: 12px
  color: $gray-8
  line-height: 20px

:deep(.ps__rail-y)
  opacity: 0.6
</style>
