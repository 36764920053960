import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener las plantillas de una campaña
 *
 * @param {number} portfolioId Identificador de la campaña.
 *
 * @return {Promise<Object>} Datos de la campaña con sus plantillas
 */
export async function fetchTemplateByPortfolio(portfolioId) {
  const response = await httpManager.get(`/portfolios/${portfolioId}/template/`);
  return response.data;
}
