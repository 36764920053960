import httpManager from "@/app/shared/utils/httpManager";

/**
 * Muestra la data de la tabla de tipificaciones.
* @param {number} ticketId Identificador del ticket.

* @return {Promise<Object>} Datos de la tabla historial de tipificaciones
 */
export async function typificationsTable(ticketId) {
  const response = await httpManager.get(`/workspace/tickets/${ticketId}/managements/`);
  return response?.data.results;
}
