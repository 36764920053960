import { ChannelCustomer, Entity } from "@/@types/global";
import { ChannelType as ChannelTypeConst } from "@/app/shared/utils/enums";
import httpManager from "@/app/shared/utils/httpManager";

type ChannelType = (typeof ChannelTypeConst)[keyof typeof ChannelTypeConst];

class PortfolioService {
  private baseUrl = "/portfolios";

  async getAssignedChannelCustomers(
    channelType: ChannelType,
    userId?: number
  ): Promise<
    {
      name: string;
      id: number;
      entity: Entity;
      channel_customer: ChannelCustomer[];
    }[]
  > {
    let url = `${this.baseUrl}/channel/${channelType}/`;

    if (userId) url += `?assigned_user=${userId}`;

    const response = await httpManager.get(url);

    return response.data;
  }
}

export const portfolioService = new PortfolioService();
