import { UPDATE_PASSWORD_WORK, SET_TIME_INACTIVE } from "./mutation-types";

const mutations = {
  [UPDATE_PASSWORD_WORK](state, password) {
    state.password = password;
  },
  [SET_TIME_INACTIVE](state, timeInactive) {
    state.timeInactive = timeInactive;
  },
};

export default mutations;
