const getters = {
  getProfileList(state) {
    return state.profiles;
  },
  getProfile(state) {
    return state.profile;
  },
};

export default getters;
