import { MetaAppType } from "@/app/shared/utils/enums";
import { MetaSdkManager } from "@/app/channels/utils/MetaSdkManager";

// /*global FB*/
/* Install SDK de Facebook */

/**
 * These variables will be initialized as a MetaSdkManager instance once the Facebook SDK has been loaded
 * @type {MetaSdkManager}
 */
let MessengerManager = null;
/**
 * These variables will be initialized as a MetaSdkManager instance once the Facebook SDK has been loaded
 * @type {MetaSdkManager}
 */
let InstagramManager = null;

// class MetaAppSDK {
//   constructor(fb) {
//     this.FB = fb;
//   }
// }

function initFacebookSdk() {
  window.fbAsyncInit = function () {
    // Documentation: https://developers.facebook.com/docs/javascript/reference/FB.init/v14.0

    // window.Messenger = new MetaAppSDK({ ...FB });
    // window.Instagram = new MetaAppSDK({ ...FB });

    MessengerManager = new MetaSdkManager(MetaAppType.Messenger);
    InstagramManager = new MetaSdkManager(MetaAppType.Instagram);

    // MessengerManager.init();
    // InstagramManager.init();

    // FB.init({
    //   appId: facebookAppId,
    //   cookie: true,
    //   xfbml: false,
    //   version: facebookSdkVersion,
    // });
  };

  (function (d) {
    const id = "facebook-jssdk";
    if (d.getElementById(id)) {
      return;
    }
    const js = d.createElement("script");
    js.id = id;
    js.async = true;
    js.defer = true;
    js.src = "https://connect.facebook.net/en_US/sdk.js";
    d.getElementsByTagName("head")[0].appendChild(js);
  })(document);
}

async function loadFacebook() {
  try {
    // await fetch(fb_sdk)
    initFacebookSdk();
    console.log("Facebook sdk is allowed");
  } catch (error) {
    console.log("Facebook sdk is NOT allowed");
    throw error;
  }
}

export { loadFacebook, MessengerManager, InstagramManager };
