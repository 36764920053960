import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener el listado de ciudades.
 *  @param {number} countryId Identificador del pais

 * @return {Promise<Object>}  Lista de ciudades
 */
export async function cityClient(countryId) {
  const response = await httpManager.get(`/parameters/countries/${countryId}/cities/`);
  return response.data.cities;
}
