<template>
  <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect
      x="0.5"
      y="0.5"
      width="47"
      height="47"
      rx="23.5"
      stroke="black"
      :stroke-opacity="border ? '0.06' : '0'"
    />
    <g clip-path="url(#clip0_18630_50847)">
      <path
        d="M24 38C16.2808 38 10 31.7192 10 24C10 16.2808 16.2808 10 24 10C31.7192 10 38 16.2808 38 24C38 31.7192 31.7192 38 24 38ZM24 11.75C17.245 11.75 11.75 17.245 11.75 24C11.75 30.755 17.245 36.25 24 36.25C30.755 36.25 36.25 30.755 36.25 24C36.25 17.245 30.755 11.75 24 11.75ZM28.3759 31C28.0609 31 27.7581 30.8302 27.6015 30.5345C27.5534 30.4522 26.7965 29.25 24 29.25C21.1659 29.25 20.4274 30.4838 20.3967 30.5371C20.1605 30.9475 19.6355 31.1076 19.2181 30.8854C18.7999 30.6614 18.6301 30.1574 18.8419 29.7339C18.9565 29.5046 20.073 27.5 24 27.5C27.927 27.5 29.0435 29.5046 29.1581 29.7339C29.3743 30.1661 29.1992 30.692 28.767 30.9081C28.641 30.9703 28.5071 31 28.3759 31ZM28.375 25.75C27.8911 25.75 27.5 25.3589 27.5 24.875V20.5C27.5 20.017 27.8911 19.625 28.375 19.625C28.8589 19.625 29.25 20.017 29.25 20.5V24.875C29.25 25.3589 28.8589 25.75 28.375 25.75ZM19.625 25.75C19.142 25.75 18.75 25.3589 18.75 24.875V20.5C18.75 20.017 19.142 19.625 19.625 19.625C20.108 19.625 20.5 20.017 20.5 20.5V24.875C20.5 25.3589 20.108 25.75 19.625 25.75Z"
        fill-opacity="0.45"
        :fill="active ? 'red' : '#8C8C8C'"
      />
    </g>
    <defs>
      <clipPath id="clip0_18630_50847">
        <rect width="28" height="28" fill="white" transform="translate(10 10)" />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  props: {
    active: {
      required: false,
      type: Boolean,
      default: false,
    },
    border: {
      required: false,
      type: Boolean,
      default: true,
    },
  },
};
</script>
