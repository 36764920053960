<template>
  <div class="minimize">
    <h3>Redactar nuevo correo</h3>
    <slot name="actions"></slot>
    <div class="actions">
      <LineOutlined v-if="!status === 'minimized'" />
      <ExpandAltOutlined @click="emitExpandEvent" />
      <CloseOutlined @click="emitCloseEvent" />
    </div>
  </div>
</template>
<script lang="ts" setup>
import { CloseOutlined, ExpandAltOutlined, LineOutlined } from "@ant-design/icons-vue";

const props = defineProps({
  status: { type: String },
  open: { type: Boolean },
});

const emit = defineEmits(["emitExpandEvent", "emitCloseEvent"]);

const emitExpandEvent = () => {
  emit("emitExpandEvent", "normal");
};
const emitCloseEvent = () => {
  emit("emitCloseEvent", !props.open);
};
</script>
<style lang="sass" scoped>
.minimize
  display: flex
  justify-content: space-between
  h3
    margin: 0
  .actions
    display: flex
    gap: 10px
</style>
