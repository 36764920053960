<template>
  <section>
    <!-- <section class="ticket" :class="{ 'ticket-open': showMessages }"> -->
    <!-- <article class="ticket__messages" v-if="showMessages"> -->

    <div
      v-for="(date, i) in Object.keys(groupedMessages)"
      :key="`${i}-${date}`"
      class="padding-x"
      :class="{ 'ticket-open': showMessages }"
    >
      <p v-if="date" class="date">
        {{ formatDate(date) }}
      </p>

      <workspace-ticket-information
        v-if="i === 0"
        :ticket="ticket"
        type="start"
        class="interaction-info-margin"
      />

      <WorkspaceMessageItem
        v-for="(msg, idx) in groupedMessages[date]"
        :key="msg.id"
        :message="msg"
        :nextMessage="msg?.[idx + 1]"
      />
    </div>
    <workspace-ticket-information v-if="!!ticket.closed_at" :ticket="ticket" type="end" />
    <!-- </article> -->
    <!-- </section> -->
  </section>
</template>

<script setup lang="ts">
import { inject, ref, computed, toRefs, watch } from "vue";
import dayjs from "dayjs";

import WorkspaceMessageItem from "./WorkspaceMessageItem.vue";
import WorkspaceTicketInformation from "./WorkspaceTicketInformation.vue";
import { ChannelType } from "@/app/shared/utils/enums";

const props = defineProps({
  item: { required: true, type: Object },
  readonly: { required: false, type: Boolean, default: false },
});

const { item, readonly } = toRefs(props);

const ticket = inject("selectedTicket");
const interaction = inject("selectedChannel");

const open = ref(false);

const showMessages = computed(() => {
  if (readonly.value) return true;
  return open.value;
});

const groupedMessages = ref([]);

const groupMessagesByDate = (item) => {
  const messagesByDate = item.reduce((acc, message) => {
    const messageDate = dayjs(message.created_at).format("YYYY-MM-DD");

    if (!acc[messageDate]) {
      acc[messageDate] = [];
    }

    acc[messageDate].push(message);
    return acc;
  }, {});

  groupedMessages.value = messagesByDate;
};

const formatDate = (dateString) => {
  const date = dayjs(dateString);
  const today = dayjs().startOf("day");
  const yesterday = dayjs().subtract(1, "day").startOf("day");

  if (date.isSame(today, "day")) {
    return "HOY";
  } else if (date.isSame(yesterday, "day")) {
    return "AYER";
  } else {
    return date.format("DD MMMM YYYY");
  }
};
watch(item, (newVal) => {
  groupMessagesByDate(newVal);
});
</script>

<style lang="sass" scoped>
.interaction-info-margin
  margin: 0 12px 16px

.padding-x
  padding-left: 12px
  padding-right: 8px
// .ticket
//   overflow: hidden
//   transition: max-height 0.8s ease-in-out

//   &-open

//   &__messages
//     padding: 8px 16px

//   &__about
//     font-size: 10px
//     padding: 8px 16px
//     text-align: center
//     border-radius: 8px
//     background-color: $gray-2
//     color: $gray-7

.header
  color: $gray-7
  font-size: 12px
  padding: 6px 8px
  // border-bottom: $border-medium

  &__content
    display: flex
    justify-content: space-between
    align-items: center

  &__action
    display: flex

  &__arrow
    width: 24px
    cursor: pointer
    display: flex
    align-items: center

  &__icon
    color: $blue-6
    margin-left: 8px

.date
  width: fit-content
  padding: 2px 8px 0px
  margin-left: auto
  margin-right: auto
  background-color: white
  border-radius: 4px
  box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.20)
  text-align: center
  font-size: 12px
  color: $gray-8

  position: sticky
  top: 10px
  z-index: 1

  // &-wrapper
  //   width: 100%
  //   display: flex
  //   justify-content: center
  //   margin: 12px 0 7px

  // &-pill
  //   padding: 2px 8px
  //   background-color: white
  //   border-radius: 4px
  //   box-shadow: 0px 2px 1px 0px rgba(0, 0, 0, 0.20)
  //   text-align: center
  //   font-size: 12px
  //   color: $gray-8

// .open-enter-active,
// .open-leave-active
//   transition: max-height 0.5s ease

// .open-enter-from,
// .open-leave-to
//   max-height: 32px
</style>
