import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener campañas por nombre, entidad y estado.
 *
 * @param {string} search Identificador de la entidad.
 * @param {number} entity Identificador de la entidad.
 * @param {string} status Estado de la entidad.
 *
 * @return {Promise<Array>} Listado de campañas
 * @param {Object} pagination Paginación

 */
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

export async function fetchPortfolios(
  search = "",
  status = "",
  entity = "",
  page = 1,
  { chatbotPicker = false, templatePicker = false } = {},
  blacklist = {}
) {
  const { blacklist_phone, blacklist_code_phone, blacklist_channel, blacklist_blocked_type } =
    blacklist || {};
  entity = entity == 0 ? "" : entity;
  status = status == 0 ? "" : status;

  const offset = (page - 1 || 0) * TABLE_PAGE_SIZE;
  const pageSize = TABLE_PAGE_SIZE;

  let url = `/portfolios/?search=${search}&entity=${entity}&status=${status}&offset=${offset}&limit=${pageSize}`;

  let urlblacklist = `/blacklist/portfolios/?phone_number=${blacklist_phone}&code_phone=${blacklist_code_phone}&channel=${blacklist_channel}&blocked_type=${blacklist_blocked_type}`;

  const chatbotPortfoliosEndpoint = "/chatbots/portfolios/";

  if (templatePicker) url += `&linked_to_whatsapp_template=${templatePicker}`;

  if (blacklist_phone && blacklist_code_phone && blacklist_channel) url = urlblacklist;

  const response = await httpManager.get(chatbotPicker ? chatbotPortfoliosEndpoint : url);
  return response?.data;
}
