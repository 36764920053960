import { IBlackList, IBlackListFilters, IBlockNumber } from "@/@types/platformSettings/blacklist";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";
import httpManager from "@/app/shared/utils/httpManager";

class BlackListService {
  private baseUrl = "/blacklist";

  async getBlackList(
    filters: IBlackListFilters,
    current: number
  ): Promise<{ results: IBlackList[]; count: number }> {
    let url = `${this.baseUrl}/?offset=${TABLE_PAGE_SIZE * (current - 1)}`;

    if (filters.search) url += `&phone_number=${filters.search}`;
    if (filters.portfolio) url += `&portfolio=${filters.portfolio}`;
    if (filters.identifier) url += `&channel_customer=${filters.identifier}`;
    if (filters.channel) url += `&channel=${filters.channel}`;

    const response = await httpManager.get(url);
    response.data.results = response.data?.results.map((el: any) => {
      return { ...el, country_code: el.country?.code_phone };
    });
    return response.data;
  }

  async saveBlockNumber(formState: IBlockNumber, channel: number) {
    const url = `${this.baseUrl}/`;

    await httpManager.post(url, { ...formState, channel });
  }

  async editBlockNumber(id: number, formState: IBlockNumber, channel: number) {
    const url = `${this.baseUrl}/${id}/`;

    await httpManager.put(url, { ...formState, channel });
  }

  async delete(id: number): Promise<string> {
    const url = `${this.baseUrl}/${id}/`;

    const response = await httpManager.delete(url);

    return response.data;
  }
}

export const blackListService = new BlackListService();
