<template>
  <!-- <a-tooltip :title="$route.params.channel ? 'Agregar recordatorio' : 'Ticket no identificado'"> -->
  <a-button
    class="button-reminder"
    :disabled="!$route.query.client"
    type="text"
    @click="handleShowModal"
  >
    <ReminderIcon />

    Agregar recordatorio
  </a-button>
  <!-- </a-tooltip> -->

  <a-modal
    :visible="visible"
    width="432px"
    centered
    :keyboard="false"
    :body-style="{ padding: 20 }"
    :mask-closable="false"
    title="Crear recordatorio"
    @cancel="handleConfirmationClose"
  >
    <contact-data-section :contactData="contactData">
      <div class="msj-reminder">
        <InfoCircleOutlined style="padding-top: 3px" />
        <span class="msj-text">
          Se vinculará el contacto al recordatorio. Así, podrás volver a generar una interacción en
          la fecha elegida.
        </span>
      </div>
    </contact-data-section>

    <a-form class="form" layout="vertical" :model="formState" :rules="rules" ref="formRef">
      <a-form-item label="Título" name="title" required>
        <a-input
          v-model:value="formState.title"
          placeholder="Escribir titulo del recordatorio"
          :maxlength="maxObservationName"
          @input="handleFormChange"
        />

        <span class="countername"> {{ formState.title.length }}/{{ maxObservationName }} </span>
      </a-form-item>

      <a-row :gutter="16">
        <a-col :span="12">
          <a-form-item label="Seleccione fecha" name="date_schedule" required>
            <a-date-picker
              v-model:value="formState.date_schedule"
              style="width: 100%"
              :disabled-date="disabledDate"
              placeholder="-- / -- / --"
              format="DD/MM/YYYY"
              valueFormat="YYYY-MM-DD"
              :show-today="false"
              @change="handleFormChange"
            />
          </a-form-item>
        </a-col>

        <a-col :span="12">
          <a-form-item label="Seleccione hora" name="time_schedule" required>
            <a-time-picker
              v-model:value="formState.time_schedule"
              v-model:open="open"
              placeholder="00:00"
              format="HH:mm"
              style="width: 100%"
              @change="handleFormChange"
            />
          </a-form-item>
        </a-col>
      </a-row>

      <a-form-item name="description">
        <p>Descripción</p>
        <a-textarea
          v-model:value="formState.description"
          class="textarea"
          rows="5"
          placeholder="Escribir el mensaje inicial"
          :maxlength="maxDescriptionLength"
          @input="handleFormChange"
        />

        <span class="countername">
          {{ formState.description.length }}/{{ maxDescriptionLength }}
        </span>
      </a-form-item>
    </a-form>
    <!-- Fotter Modal -->
    <template #footer>
      <a-button key="back" @click="handleCancel">Cancelar</a-button>
      <a-button
        key="submit"
        type="primary"
        @click="handleOk"
        @loading="creating"
        :disabled="buttonDisabled"
      >
        Crear Recordatorio
      </a-button>
    </template>
  </a-modal>
</template>

<script>
import { defineComponent, ref, reactive, toRaw, computed, createVNode, h, inject } from "vue";
import { useStore } from "vuex";
import { InfoCircleOutlined, QuestionCircleOutlined, CloseOutlined } from "@ant-design/icons-vue";
import { Modal } from "ant-design-vue";

import { disabledDate, hourToLocalTime } from "@/app/shared/utils/dates";

import ContactDataSection from "@/app/schedule/components/section/ContactDataSection.vue";
import ReminderIcon from "@/app/shared/components/icons/ReminderIcon.vue";

export default defineComponent({
  components: {
    ContactDataSection,
    InfoCircleOutlined,
    ReminderIcon,
  },

  props: {
    client: {
      type: String,
      required: true,
    },
    clientid: {
      type: Number,
      required: true,
    },
    photo: {
      type: String,
      required: false,
      default: "",
    },
    email: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  setup(props) {
    const store = useStore();

    const ticketHistory = computed(() => store.getters["getWorkspaceMessageHistory"]);
    const contactData = computed(() => ({
      clientName: props.client,
      clientId: props.clientid,
      clientPhoto: props.photo,
      campaign: ticketHistory.value?.ticket?.portfolio?.name,
      queue: ticketHistory.value?.ticket?.queue?.name,
    }));

    const currentInteraction = inject("selectedChannel");

    const formRef = ref();
    const formState = reactive({
      title: "",
      date_schedule: "",
      time_schedule: "",
      description: "",
    });
    const rules = {
      title: [{ required: true, message: "Campo requerido" }],
      date_schedule: [{ required: true, message: "Campo requerido" }],
      time_schedule: [{ required: true, message: "Campo requerido" }],
      // description: [{ required: true, message: "Campo requerido" }],
    };

    return {
      contactData,
      rules,
      formState,
      formRef,
      currentInteraction,
    };
  },
  data() {
    return {
      creating: false,
      visible: false,
      size: {
        width: 40,
        height: 40,
      },
      open: false,
      buttonDisabled: true,
      name: "",
      maxObservationName: 50, // Máxima cantidad de caracteres en el campo de "Titulo Recordatorio"
      maxDescriptionLength: 100,
    };
  },
  methods: {
    handleShowModal() {
      this.visible = true;
    },
    async handleOk() {
      this.formRef.validateFields().then(async () => {
        const description = toRaw(this.formState).description || "-";

        const data = {
          title: toRaw(this.formState).title,
          date_schedule: toRaw(this.formState).date_schedule,
          time_schedule:
            toRaw(this.formState).date_schedule +
            " " +
            hourToLocalTime(toRaw(this.formState).time_schedule),
          description,
          client: this.clientid,
          ticket: Number(this.$route.query.ticket),
          interaction_id: Number(this.$route.query.interaction),
          channel: this.currentInteraction?.channel?.id,
        };

        this.creating = true;

        try {
          await this.$store.dispatch("addReminder", data);
          // this.handleCloseModal();
          this.$message.success(
            h("span", [
              "Se ha creado un nuevo recordatorio correctamente",
              h(
                "span",
                {
                  style: { cursor: "pointer", marginLeft: "5px" },
                  onClick: () => this.$message.destroy(),
                },
                createVNode(CloseOutlined, { style: { color: "#000000d9" } })
              ),
            ])
          );
          this.visible = false;
        } catch (error) {
          this.$message.error("Hubo un error al crear un evento");
        }

        this.creating = false;

        this.formRef.resetFields();
      });
    },
    handleFormChange() {
      this.buttonDisabled = false;
    },
    handleCancel() {
      this.visible = false;
      this.formRef.resetFields();
    },

    disabledDate(current) {
      return disabledDate(current);
    },
    handleConfirmationClose() {
      Modal.confirm({
        title: "¿Estás seguro de cerrar el proceso?",
        icon: createVNode(QuestionCircleOutlined),
        content: "Al cerrar no se guardarán los datos previamente ingresados",
        onOk: () => this.handleCancel(),
        centered: true,
        okText: "Cerrar",
        cancelText: "Cancelar",
      });
    },
    handleChangeName(event) {
      this.name = event.target.value;
    },
  },
});
</script>

<style lang="sass" scoped>
.button-reminder
  display: flex
  align-items: center
  justify-content: flex-start

  .icon
    font-size: 18px

.form p
  margin-bottom: 6px
  color: $gray-9
.space
  margin-bottom: 8px
.textarea
  height: 56px
  margin: auto
.date
  display: flex
  justify-content: space-between
  .picker
    width: 184px
.ant-tag
  white-space: nowrap
  overflow: hidden
  text-overflow: ellipsis
  max-width: 330px
.msj-reminder
  color: $gray-8
  display: inline-flex
.msj-text
  margin-left: 7px
.countername
  bottom: 0px
  position: absolute
  right: 12px
  font-size: $font-small
  color: $gray-6
</style>
