import { fetchRecordsList } from "@/app/supervision/services";
import { SET_RECORDS } from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchRecordList({ commit }, params = {}) {
    const { filters, pagination } = params;
    const data = await fetchRecordsList(filters, pagination);
    commit(SET_RECORDS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
};

export default actions;
