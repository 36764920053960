import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener arbol de tipificaciones por campaña.
 *
 * @param {number} portfolioId Identificador de la campaña.
 *
 * @return {Promise<Object>} Datos de la campaña y el arbol. `level_palette` `groups`
 */

export default async function (portfolioId) {
  const response = await httpManager.get(`/portfolios/${portfolioId}/agents/`);
  return response.data;
}
