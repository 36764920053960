import httpManager from "@/app/shared/utils/httpManager";

/**
 * Actualiza nuevos datos personalizados.
 * @param {number} clientId Identificador del cliente
 * @param {Object} body Datos del cliente.
 *
 * @return {Promise<Object>} Datos editados del cliente
 */
export async function updateDataCustom(clientId, body) {
  const url = `/workspace/tickets/clients-customized/${clientId}/`;
  const response = await httpManager.put(url, body);
  return response.data;
}
