<template>
  <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6.45746 3.79112C6.71016 3.53843 6.71016 3.12873 6.45746 2.87604C6.20477 2.62335 5.79508 2.62335 5.54238 2.87604L2.20905 6.20938C1.95636 6.46207 1.95636 6.87176 2.20905 7.12446L5.54238 10.4578C5.79508 10.7105 6.20477 10.7105 6.45746 10.4578C6.71016 10.2051 6.71016 9.7954 6.45746 9.54271L3.58167 6.66692L6.45746 3.79112ZM9.12405 3.79112C9.37675 3.53843 9.37675 3.12873 9.12405 2.87604C8.87136 2.62335 8.46167 2.62335 8.20897 2.87604L4.87564 6.20938C4.62295 6.46207 4.62295 6.87176 4.87564 7.12446L8.20897 10.4578C8.46167 10.7105 8.87136 10.7105 9.12405 10.4578C9.37675 10.2051 9.37675 9.7954 9.12405 9.54271L6.89532 7.31397H9.99985C11.1747 7.31397 11.6278 7.32018 11.9814 7.42061C12.9165 7.68612 13.6473 8.41695 13.9128 9.35198C14.0133 9.70567 14.0195 10.1587 14.0195 11.3336C14.0195 11.6909 14.3092 11.9806 14.6665 11.9806C15.0239 11.9806 15.3136 11.6909 15.3136 11.3336L15.3136 11.2177V11.2177C15.3138 10.1982 15.3139 9.54862 15.1577 8.99848C14.7697 7.63189 13.7015 6.56376 12.3349 6.17571C11.7848 6.01949 11.1352 6.01963 10.1157 6.01984L9.99985 6.01986H6.89532L9.12405 3.79112Z"
      fill="#262626"
      fill-opacity="0.88"
    />
  </svg>
</template>
