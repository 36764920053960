import httpManager from "@/app/shared/utils/httpManager";
import { FormVar } from "@/@types/manageVars";
/**
 * Crea una variable
 * @return {Promise<Object>} variable.
 */
export async function createVar({ name, exclude_words = [] }: FormVar) {
  try {
    const response = await httpManager.post(`variables/`, {
      name,
      exclude_words,
    });
    return { success: true, data: response.data };
  } catch (error) {
    console.error(error);
    return { success: false };
  }
}
