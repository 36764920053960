import {
  fetchAllTrunks,
  fetchTrunkById,
  createNewTrunk,
  updateTrunk,
  deleteTrunk,
  fetchSearchHost,
} from "@/app/pbx/services";

import { SET_TRUNKS, SET_TRUNK, CLEAR_TRUNK } from "./mutation-types";
import { TABLE_PAGE_SIZE } from "@/app/shared/utils/constants";

const actions = {
  async fetchAllTrunks({ commit }, params = {}) {
    const { search, pagination, useIncomingCalls } = params;
    const data = await fetchAllTrunks(search, pagination, useIncomingCalls);
    commit(SET_TRUNKS, {
      items: data.results,
      pagination: {
        total: data.count,
        pageSize: TABLE_PAGE_SIZE,
        showSizeChanger: false,
      },
    });
  },
  async fetchTrunkById({ commit }, id) {
    commit(SET_TRUNK, undefined);
    const trunk = await fetchTrunkById(id);
    console.log(trunk, "trunk by id");
    commit(SET_TRUNK, trunk);
  },
  async createNewTrunk(_, params) {
    await createNewTrunk(params);
  },
  async updateTrunk(_, { params, id }) {
    await updateTrunk(id, params);
  },
  clearTrunk({ commit }) {
    commit(CLEAR_TRUNK);
  },
  assignedTrunk(context, user) {
    let stateTrunk = context.state.trunk;
    stateTrunk = { ...stateTrunk, ...user };
    context.commit(SET_TRUNK, stateTrunk);
  },
  async deleteTrunk(_, trunkId) {
    await deleteTrunk(trunkId);
  },
  async searchHost({ commit, state }, host) {
    const trunk = await fetchSearchHost(host);
    if (trunk)
      commit(SET_TRUNK, {
        ...state.trunk,
        type_authentication: trunk.trunk_type,
        host_server: trunk.host,
      });
  },
};
export default actions;
