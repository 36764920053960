import ManageVars from "@/app/manageVars/pages/ManageVars.vue";

export default [
  {
    path: "/flows/manage-vars",
    name: "manage-vars",
    component: ManageVars,
    meta: {
      requiresAuth: true,
    },
  },
];
