<template>
  <section>
    <EditOutlined class="icon" @click="handleShowModal" />
    <a-drawer
      v-model:visible="visible"
      title="Editar información"
      destroy-on-close
      :mask-closable="false"
      placement="right"
      width="480"
      keyboard
      :body-style="{ padding: 0 }"
      @close="handleCloseDrawer"
    >
      <custom-scrollbar class="modal__body" height="calc(100vh - 108px)">
        <a-form
          v-if="customData"
          class="form"
          layout="vertical"
          ref="formRef"
          :model="formState"
          @submit.prevent="handleSubmit"
        >
          <div v-for="item in columns" :key="item.index">
            <a-form-item :label="item.title" :name="item.title">
              <a-input
                v-model:value="formState[item.dataIndex]"
                :defaultValue="customData[item.dataIndex]"
              />
            </a-form-item>
          </div>
        </a-form>
      </custom-scrollbar>

      <div class="footer">
        <a-button class="footer__button" @click="handleCloseDrawer"> Cancelar </a-button>
        <a-button type="primary" @click="handleSubmit"> Guardar cambios </a-button>
      </div>
    </a-drawer>
  </section>
</template>

<script>
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";
import { defineComponent, ref, reactive, toRaw } from "vue";
import { EditOutlined } from "@ant-design/icons-vue";

export default defineComponent({
  components: {
    CustomScrollbar,
    EditOutlined,
  },
  setup() {
    const formRef = ref();
    const formState = reactive({});

    return {
      formState,
      formRef,
    };
  },
  props: {
    record: {
      type: Object,
      required: true,
    },
    itemId: {
      type: Number || String,
      required: true,
    },
  },
  data() {
    return {
      visible: false,
      customFields: {},
    };
  },
  computed: {
    getColumns() {
      return this.$store.getters["getContactCustomFields"];
    },
    customData() {
      return this.$store.getters["getContactCustomData"];
    },
    columns() {
      let cols = this.getColumns;
      cols = cols.filter((col) => col.title != "id" && col.title != "Acciones");
      return cols;
    },
  },

  methods: {
    handleDataCustomChange($event, key) {
      this.customFields[key] = $event;
    },
    handleShowModal() {
      this.visible = true;
    },
    handleCloseDrawer() {
      this.visible = false;
    },
    handleSubmit() {
      this.formRef.validateFields().then(async () => {
        try {
          await this.$store.dispatch("updateInformationCustom", {
            contactId: this.itemId,
            body: toRaw(this.formState),
          });
          this.$store.dispatch("fetchContactCustomData", this.itemId);
          this.handleCloseDrawer();
          this.$message.success("Se ha editado información correctamente");
        } catch (error) {
          this.$message.error("Se ha producido un error al editar la información");
        }
      });
    },
  },
});
</script>

<style lang="sass" scoped>
.modal
  &__body
    padding: 12px 24px

.footer
  display: flex
  align-items: center
  justify-content: flex-end
  padding: 10px 16px
  border-top: $border-normal
  position: absolute
  width: 100%
  bottom: 0
  left: 0

  &__button
    margin-right: 12px

:deep(.ant-input-number)
  width: 100%

:deep(.ant-calendar-picker)
  width: 100%

.icon
  color: $blue-6
  margin-left: $margin-tiny
  margin-top: 3px
</style>
