export function getAcwTimeFrom(time, times, now) {
  if (!times || times.length == 0 || time == null) return 0;

  let count = 0;
  for (const index in times) {
    if (index % 2 != 0) {
      count += times[index] - times[index - 1];
    }
  }

  if (times.length % 2 == 0) {
    return time - count;
  }

  return time - (now - times[times.length - 1]) - count;
}
