import httpManager from "@/app/shared/utils/httpManager";
/**
 * Envía las instrucciones a un email de cómo agregar el código del inboundChat
 *
 * @param {Object} data Datos de la persona que recibirá el email
 *
 */

export default function (data) {
  return httpManager.post("/channels/webchat/send-instructions/", data);
}
