import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener todos los usuarios registrados.
 *
 * @return {Promise<Array>} Lista de usuarios.
 */
export default async function (roleId) {
  const response = await httpManager.get(`/profiles/role/${roleId}/menus/`);
  return response?.data;
}
