import { BlackListTab } from "@/app/platformSettings/utils/enums";
export const blackListTableColumns = (typeBlock: number) => [
  {
    title: "N°",
    customRender: ({ index }: any) => index + 1,
    width: 60,
  },
  {
    title: "Código del País",
    dataIndex: "country_code",
    width: 150,
  },
  {
    title: "Número telefónico",
    dataIndex: "phone_number",
    width: 150,
  },
  ...(typeBlock != BlackListTab.Whatsapp
    ? [
        {
          title: "Campañas restringidas",
          key: "portfolios",
          dataIndex: "portfolios",
          width: 200,
        },
      ]
    : [
        {
          title: "Identificadores restringidos",
          key: "channels_customers",
          dataIndex: "channels_customers",
          width: 200,
        },
      ]),
  {
    title: "Bloqueado por",
    dataIndex: "blocked_by",
    width: 200,
  },
  {
    title: "Fecha y hora",
    dataIndex: "created_at",
    width: 200,
  },
  {
    title: "Tipo de bloqueo",
    dataIndex: "blocked_type",
    width: 200,
  },
  {
    title: "Origen de bloqueo",
    dataIndex: "origin",
    width: 200,
  },
  {
    title: "Acciones",
    dataIndex: "actions",
    width: 80,
  },
];
