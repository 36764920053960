import httpManager from "@/app/shared/utils/httpManager";
/**
 * Obtener agentes.
 *
 * @param {Object} tickets Los tickets seleccionados.
 *
 * @return {Promise<Array>} Lista de agentes.
 */
export async function fetchAgentsTransfer() {
  const url = `/workspace/phone-call/transfer/agents-available/`;
  const response = await httpManager.get(url);
  return response?.data;
}
