import { EventManagement } from "./enums";

export const EventData = Object.freeze({
  [EventManagement.Login]: {
    name: "Login",
    color: "#F9F9F9",
  },
  [EventManagement.Online]: {
    name: "En linea",
    color: "#52C41A",
  },
  [EventManagement.Ringing]: {
    name: "Timbrando",
    color: "#69C0FF",
  },
  [EventManagement.Talking]: {
    name: "Hablando",
    color: "#A0D911",
  },
  [EventManagement.Acw]: {
    name: "ACW",
    color: "#FA8C16",
  },
  [EventManagement.Typifying]: {
    name: "Tipificando",
    color: "#F9F9F9",
  },
  [EventManagement.Refreshment]: {
    name: "Almuerzo",
    color: "#FAAD14",
  },
  [EventManagement.Services]: {
    name: "En servicios",
    color: "#1890FF",
  },
  [EventManagement.Training]: {
    name: "En capacitación",
    color: "#eb2f96",
  },
  [EventManagement.Disconnected]: {
    name: "Desconectado",
    color: "gray",
  },
  [EventManagement.Occupied]: {
    name: "Ocupado",
    color: "#F5222D",
  },
  [EventManagement.Opened]: {
    name: "Abriendo",
    color: "#69C0FF",
  },
  [EventManagement.Writing]: {
    name: "Escribiendo",
    color: "#A0D911",
  },
  [EventManagement.Resolved]: {
    name: "Resolviendo",
    color: "#FAAD14",
  },
  [EventManagement.Waiting]: {
    name: "Esperando",
    color: "#5CDBD3",
  },
  [EventManagement.Inactive]: {
    name: "Inactivo",
    color: "gray",
  },
  [EventManagement.Dinner]: {
    name: "Cena",
    color: "#FA8C16",
  },
});
