import httpManager from "@/app/shared/utils/httpManager";

/**
 * Vitiate an incoming call by the phone number
 * @param {number} phoneNumber
 *
 */

export async function vitiateIncomingCall(body) {
  await httpManager.post("/workspace/inbound-call/invalid/", body);
}
