<template>
  <section>
    <div class="modal-top-section">
      <h3 class="modal-top-section__title margin-0">Perfil de webchat</h3>
      <p class="modal-top-section__subtitle">
        Configura el nombre, estiliza y crea la mejor experiencia para tu webchat.
      </p>
    </div>

    <a-form ref="formRef" :model="formState" class="step-web-chat-form">
      <div class="web-chat-avatar">
        <AvatarPicker
          v-model:value="formState.avatarUrl"
          :value="formState.avatarUrl"
          :avatarSrc="formState.avatarUrl"
          @update:value="formState.avatarUrl = $event"
        />

        <div class="web-chat-avatar__instructions">
          <h4>Sube aquí el avatar</h4>
          <p>El tipo de imagen ideal es jpg o png</p>
          <p>Las dimensiones ideales son 500px x 500px</p>
          <p>El tamaño máximo es de 5MB</p>
        </div>
      </div>

      <a-form-item name="title" :rules="rules">
        <div class="flex-center gap-4 mb-5">
          <p class="step-web-chat-form__label step-web-chat-form__label--required">
            Nombre de tu empresa (Alias)
          </p>
          <a-tooltip placement="top" :title="aliasTooltip">
            <InfoCircleOutlined />
          </a-tooltip>
        </div>
        <a-input
          v-model:value="formState.title"
          placeholder="Escribir nombre de tu empresa"
          :disabled="isEditing"
          maxlength="60"
        >
        </a-input>
      </a-form-item>

      <a-form-item class="color-picker-input" required>
        <p class="step-web-chat-form__label step-web-chat-form__label--required">Color</p>
        <span>Elige un color que contraste con la marca de tu empresa</span>

        <a-radio-group class="color-radio-group" v-model:value="formState.color">
          <div class="color-picker-input__default-colors-section">
            <a-radio
              v-for="color in colorList"
              :key="color.name"
              :value="color.code"
              :style="{ backgroundColor: `${color.code}` }"
              class="color-radio"
            />
          </div>

          <div class="custom-color__selector">
            <label class="block mb-5">Personaliza el color:</label>
            <div class="radio-color__container">
              <BrushIcon size="23px" />

              <a-radio
                class="color-radio"
                :style="{ backgroundColor: `#${customColor}` }"
                :value="customColor"
                :class="{ 'custom-color-checked': isCustomColorSelected }"
                @click="openColorPicker"
              />

              <div class="relative">
                <a-input
                  maxlength="6"
                  style="width: 118px"
                  v-model:value="customColor"
                  placeholder="109FEE"
                >
                  <template #prefix>
                    <span>#</span>
                  </template>
                </a-input>

                <ColorInput
                  ref="colorInputRef"
                  @input="onColorInput"
                  style="transform: translate(0px, -10px)"
                />
              </div>
            </div>
          </div>
        </a-radio-group>
      </a-form-item>
    </a-form>
  </section>
</template>

<script setup>
import { ref, toRefs, reactive, watch, onMounted } from "vue";

import { InfoCircleOutlined } from "@ant-design/icons-vue";
import { colorSelectorList } from "@/app/channels/utils/constants";
import BrushIcon from "@/app/shared/components/icons/BrushIcon.vue";
import ColorInput from "@/app/platformSettings/components/input/ColorInput.vue";
import AvatarPicker from "../complements/AvatarPicker.vue";
import { ProductName } from "@/app/shared/utils/constants";

const emit = defineEmits(["webChatOptionsUpdated"]);

const props = defineProps({
  avatar: {
    type: String,
    default: "",
  },
  alias: {
    type: String,
    default: "",
  },
  color: {
    type: String,
    default: "#262A31",
  },
});

const { avatar, alias, color } = toRefs(props);

const formRef = ref();

const formState = reactive({
  avatarUrl: "",
  title: "",
  color: "",
});

const aliasTooltip = `Nombre público visible en tu Webchat. Además, en ${ProductName} se listará este nombre cuando desees vincular tu identificador.`;

onMounted(() => {
  formState.avatarUrl = avatar.value;
  formState.title = alias.value;
  formState.color = color.value;
});

const submitForm = async () => {
  try {
    await formRef.value.validate();
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: true,
    });
  } catch (error) {
    console.log("error", error);
    emit("webChatOptionsUpdated", {
      ...formState,
      isValid: false,
    });
  }
};

defineExpose({
  submitForm,
});

// COLOR PICKER
const colorList = colorSelectorList;
const colorInputRef = ref(null);
const customColor = ref("262A31");
const isCustomColorSelected = ref(false);

const openColorPicker = () => {
  colorInputRef.value.$el.click();
};

const onColorInput = (inputColor) => {
  if (typeof inputColor === "string") {
    const hexCode = inputColor.substring(1);
    formState.color = inputColor;
    customColor.value = hexCode;
  }
};

watch(
  () => formState.color,
  () => {
    if (customColor.value !== formState.color) {
      isCustomColorSelected.value = false;
    }
  }
);

watch(customColor, () => {
  isCustomColorSelected.value = true;
  formState.color = "#" + customColor.value;
});

watch(
  formState,
  (newVal) => {
    emit("webChatOptionsUpdated", newVal);
  },
  { deep: true }
);

const rules = [{ required: true, message: "Campo requerido", trigger: "change" }];
</script>

<style lang="sass" scoped>
@import '../sharedStyles'
.web-chat-avatar
  display: flex
  align-items: center
  gap: 20px

  &__instructions
    h4
      margin-bottom: 4px
      font-weight: 600
      font-size: $font-normal
      color: $gray-9

    p
      font-size: $font-small
      margin-bottom: 0
      color: $gray-8

// COLOR PICKER
.color-picker-input

  &__default-colors-section
    height:32px
    display: grid
    grid-template-columns: repeat(9, 20px)
    align-items: center
    gap: 24px

.color-radio-group
  margin-top: 12px

.custom-color
  &__selector
    margin-top: 20px

.color-radio
  width: 24px
  height: 24px
  margin: 0
  border-radius: 50%
  transition: 0.2s all

  &:deep(.ant-radio)
    display: none

.ant-radio-wrapper-checked,
.custom-color-checked
  width: 32px
  height: 32px
  transition: 0.2s all

.ant-radio-wrapper-checked::after,
.custom-color-checked::after
  content: url('../../../../assets/check.svg')
  margin-top: 3px
  width: 32px
  height: 32px
  display: flex
  align-items: center
  justify-content: center

.radio-color__container
  display: grid
  grid-template-columns: 24px 42px 118px
  align-items: center
</style>
