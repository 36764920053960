<template>
  <!-- <navigation-tabs :tabs="tabs"> -->
  <MainContainer v-if="currentRoute != '/channels/template'">
    <router-view />
  </MainContainer>
  <router-view v-else />
  <!-- </navigation-tabs> -->
</template>

<script>
// import NavigationTabs from "@/app/shared/components/organisms/NavigationTabs";
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";
import MainContainer from "@/app/shared/components/organisms/MainContainer.vue";

export default defineComponent({
  components: {
    // NavigationTabs,
    MainContainer,
  },
  setup() {
    const route = useRoute();
    const currentRoute = computed(() => route.path);

    return {
      currentRoute,
    };
  },
});
</script>
