<template>
  <a-config-provider :locale="locale">
    <Suspense>
      <component :is="layout" class="score-app">
        <router-view />
      </component>

      <template #fallback> Loading... </template>
    </Suspense>
  </a-config-provider>
</template>

<script setup lang="ts">
import { onBeforeMount, defineAsyncComponent, computed } from "vue";
import { useStore } from "vuex";
import { useRoute } from "vue-router";
import esES from "ant-design-vue/es/locale/es_ES";
import "dayjs/locale/es";

// import MainLayout from "@/layouts/MainLayout.vue";
// import EmptyLayout from "@/layouts/EmptyLayout.vue";
// import FooterLayout from "@/layouts/FooterLayout.vue";

const locale = {
  ...esES,
  Tour: {
    Next: "Continuar",
    Previous: "Volver",
    Finish: "Finalizar",
  },
};

const store = useStore();
const route = useRoute();

onBeforeMount(() => {
  store.dispatch("fetchAllCountries");
});

const MainLayout = defineAsyncComponent(() => import("@/layouts/MainLayout.vue"));
const EmptyLayout = defineAsyncComponent(() => import("@/layouts/EmptyLayout.vue"));
const FooterLayout = defineAsyncComponent(() => import("@/layouts/FooterLayout.vue"));

const layout = computed(() => {
  const routeLayout = route.meta.layout;

  if (routeLayout === "empty") return EmptyLayout;
  if (routeLayout === "footer") return FooterLayout;
  return MainLayout;
});
</script>

<style lang="sass">
body
  overflow: hidden
  line-height: 1.5715 !important
  color: $gray-9

.score-app
  overflow: hidden
  height: 100vh !important

  .ant-table-content > .ant-table-body > table > .ant-table-thead
    background: $gray-2

  .ant-table-content > .ant-table-body > table > .ant-table-thead tr
    border: 0

  .ant-table-content > .ant-table-body > table > .ant-table-thead > tr > th:hover
    background-color: $gray-4

  .ant-table-small
    border-top: 1px solid $gray-4
    border-bottom: 1px solid $gray-4
    border-right: 0

  .ant-table-small > .ant-table-content > .ant-table-body
    margin: 0px

  .ant-table-tbody > tr:hover:not(.ant-table-expanded-row):not(.ant-table-row-selected) > td
    background-color: #feffe6

  .ant-picker
    width: 100%

.vueditor
  position: relative

  .ve-toolbar
    position: absolute
    bottom: -40px
    border-bottom: none

    & div
      padding: 8px

    & i[class^="icon"]
      width: 12px
      height: 12px

//Sombra Scroll Tabla Contacto
.ant-table-small.ant-table-bordered .ant-table-fixed-right
  box-shadow: -3px 0px 6px #9d9d9d
.ant-table-small.ant-table-bordered .ant-table-fixed-left
  box-shadow: 3px 0px 6px #9d9d9d
.ant-spin-container .ant-table-small.ant-table-bordered .ant-table-fixed-right
  border-right: none
.ant-table-small.ant-table-bordered .ant-table-fixed-left
  margin-left: -1px

//Sombra scroll tablas
.ant-table .ant-table-content .ant-table-fixed-left
  box-shadow: 3px 0 7px 0px #9d9d9d

.ant-table-scroll-position-left .ant-table-content .ant-table-fixed-left
  box-shadow: none !important
// Ant popover

// .ant-menu-submenu ant-menu-submenu-inline menu--small


.ant-menu-submenu ant-menu-submenu-inline menu--small ant-menu-submenu-open ant-menu-submenu-active
  display: flex !important
  flex-direction: row-reverse !important
  margin-left: -174px !important

.ant-message-error
  background: #fff1f0
  margin-top: -28px
  margin-left: -17px
  margin-bottom: -10px
  margin-right: -17px
  padding: 7px
  padding-right: 20px
  padding-left: 20px
  border: 1px solid #FF9694
  border-radius: 4px

.ant-message-warning
  background: #FFFBE6
  margin-top: -28px
  margin-left: -17px
  margin-bottom: -10px
  margin-right: -17px
  padding: 7px
  padding-right: 20px
  padding-left: 20px
  border: 1px solid #FFE28B
  border-radius: 4px

.ant-message-success
  background: #F6FFED
  margin-top: -28px
  margin-left: -17px
  margin-bottom: -10px
  margin-right: -17px
  padding: 7px
  padding-right: 20px
  padding-left: 20px
  border: 1px solid #ABEA85
  border-radius: 4px


.ant-message-notice-content
  margin-top: 24px

.ant-tabs-nav-operations
  display: none !important

.ant-table-filter-dropdown-link.confirm
  font-size: 14px
  margin-right: 5px

.ant-table-filter-dropdown-link.clear
  font-size: 14px
  margin-left: 8px

.ps__rail-y
  z-index: 99

// .ant-row
//   display: block

.ant-checkbox-group
  top: 0

.ant-steps-item-title
  font-size: 16px
  font-weight: 400

.ant-form-item-label
  padding: 0 0 2px !important

.ant-tour-placement-rightTop .ant-tour-arrow, .ant-tour-placement-leftTop .ant-tour-arrow
  top: 6px

.ant-tour-placement-rightBottom .ant-tour-arrow, .ant-tour-placement-leftBottom .ant-tour-arrow
  bottom: 6px

.ant-tour-inner .ant-tour-description
  word-break: break-word
  white-space: pre-wrap

.grecaptcha-badge
  visibility: hidden
</style>
