import httpManager from "@/app/shared/utils/httpManager";
import { TABLE_PAGE_SIZE_XL } from "../../../shared/utils/constants";
export default async function (params, pagination = {}) {
  let query = "";
  query += params.entity ? `entity=${params.entity}&` : "";
  query += params.campaign ? `portfolio=${params.campaign}&` : "";
  query += params.base ? `base=${params.base}&` : "";
  query += params.startValue ? `start_date=${params.startValue + " " + "00:00:00"}&` : "";
  query += params.endValue ? `end_date=${params.endValue + " " + "23:59:00"}` : "";
  query += params.user ? `&user=${params.user}` : "";

  const offset = (pagination.current - 1 || 0) * TABLE_PAGE_SIZE_XL;
  const pageSize = pagination.pageSize || TABLE_PAGE_SIZE_XL;

  const url = `/reports/${params.type}/?offset=${offset}&limit=${pageSize}&${query}`;
  const response = await httpManager.get(url);
  return response?.data;
}
