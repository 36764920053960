<template>
  <div class="information">
    <p class="text__info">
      Configura el tiempo máximo a respetar para el cierre de sesión de los usuarios por
      inactividad.
    </p>

    <div class="input__item">
      <p>Tiempo configurado actual:</p>

      <a-input-number
        class="inputNumber"
        v-model:value="inactivity_time"
        :min="1"
        :max="120"
        @change="handleChangeNumber"
      />
      <span>minutos</span>
    </div>
    <div class="button__inactivity">
      <a-button :disabled="!disabledInactivity" @click="handleCancelInactivity">
        Cancelar
      </a-button>

      <a-button type="primary" @click="updateCounterInactive" :disabled="!disabledInactivity">
        Guardar cambios
      </a-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, computed, watch } from "vue";
import { useStore } from "vuex";

import { updateCompanyDataAdmin, refreshMinutesToExpire } from "@/app/settingsMenu/services";

export default defineComponent({
  setup() {
    const store = useStore();

    const userData = computed(() => store.getters["getDataUser"]);
    const serverInactivityTime = computed(() => store.getters["getMinutesExpire"]);
    const inactivity_time = ref(serverInactivityTime.value);

    watch(
      serverInactivityTime,
      () => {
        inactivity_time.value = serverInactivityTime.value || 30;
      },
      { flush: "post" }
    );

    return {
      serverInactivityTime,
      inactivity_time,
      userData,
    };
  },
  data() {
    return {
      disabledInactivity: false,
    };
  },
  computed: {
    user() {
      return this.$store.getters["getDataUser"];
    },
  },
  methods: {
    async updateCounterInactive() {
      try {
        if (this.inactivity_time) {
          const formData = new FormData();
          formData.append("inactivity_time", this.inactivity_time);
          await updateCompanyDataAdmin(formData);
          this.$store.dispatch("setMinutesExpire", this.inactivity_time);
          this.$message.success("Se guardaron los cambios correctamente");
          this.disabledInactivity = false;
        } else {
          this.inactivity_time = this.serverInactivityTime || 30;
        }
      } catch (error) {
        this.$message.error("Hubo un error al actualizar los datos");
      }

      try {
        const body = {
          customer_id: this.userData?.customer?.id,
          minutes_to_expire: this.inactivity_time,
        };

        refreshMinutesToExpire(body);
      } catch (error) {
        this.$message.error("Ocurrió un error al actualizar para los demás usuario");
        console.log(error);
      }
    },
    handleCancelInactivity() {
      this.disabledInactivity = false;
      this.inactivity_time = this.dataCompany?.inactivity_time || 30;
    },
    handleChangeNumber() {
      this.disabledInactivity = true;
      if (!this.inactivity_time) this.inactivity_time = this.serverInactivityTime || 30;
    },
  },
});
</script>

<style scoped lang="sass">
.text__strong
  font-weight: 600
  font-size: 16px

.text__info
  // margin-top: 30px

.input__item
  display: flex
  align-items: center
  gap: 8px

  p
    margin: 0

.inputNumber
  width: 80px
  margin-right: 8px

.disabled
  cursor: not-allowed
  color: rgba(0, 0, 0, 0.25)

.information__password
  width: 70%
  line-height: 25px

.button__delete
  background: $red-6
  color: white
  margin-top: 20px

.button__inactivity
  margin-top: 20px
  button
    margin-right: 10px
</style>
