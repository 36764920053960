<template>
  <div class="template" :class="getClasses" :style="getStyles">
    <div class="template__body">
      <slot />

      <!-- State change message -->
      <InformationCard
        v-if="showInformationMessage"
        floating
        @changeVisibility="handleChangeInformation"
      />
      <!-- <div class="change-state-message floating" v-if="showWaitingMessage"> -->
      <!--   Al terminar esta gestión, tu estado cambiará a “{{ waitingChangeStateMessage.text }}” -->
      <!-- </div> -->
    </div>

    <div
      v-if="$slots.footer"
      class="template__footer"
      :class="{
        fixed__footer: currentWebPhoneState !== WebPhoneState.expanded,
      }"
    >
      <slot name="footer" />
    </div>
  </div>
</template>

<script>
/**
 * Componente que contiene el diseño del webphone
 */
import { themes } from "../../styles/themes";
import { defineComponent, inject, ref } from "vue";

import { WebPhoneState } from "@/packages/webphone/utils/enums";

import InformationCard from "@/packages/webphone/components/cards/InformationCard.vue";

export default defineComponent({
  components: {
    InformationCard,
  },
  props: {
    centered: { type: Boolean, default: false },
  },
  inject: {
    height: { from: "height" },
    theme: { from: "theme" },
  },
  setup() {
    const currentWebPhoneState = inject("currentWebPhoneState");
    const extraHeight = ref(0);

    const handleChangeInformation = (visibility) => {
      extraHeight.value = 0;

      if (visibility.state) extraHeight.value += 50;
      if (visibility.survey) extraHeight.value += 50;
    };

    return {
      WebPhoneState,
      currentWebPhoneState,
      handleChangeInformation,
      extraHeight,
    };
  },
  computed: {
    getClasses() {
      return {
        "template--centered": this.centered,
      };
    },
    showInformationMessage() {
      return this.currentWebPhoneState === WebPhoneState.expanded;
    },
    getStyles() {
      // const extraHeight = this.showWaitingMessage ? 50 : 0;
      const newHeight = this.height + this.extraHeight;

      return {
        minHeight: `${newHeight}px`,
        backgroundColor: themes[this.theme].backgroundColor,
      };
    },
    waitingChangeStateMessage() {
      return this.$store.getters["getWaitingChangeStateMessage"];
    },
  },
});
</script>

<style lang="sass" scoped>
@import '../../styles/main'

.template
  display: flex
  align-items: center
  justify-content: center
  flex-direction: column

  &__body
    flex: auto
    display: flex
    align-items: center
    justify-content: flex-end
    flex-direction: column
    width: 100%

  &__footer
    width: 100%

.fixed__footer
  // max-width: $webphone-width - 24px
  // height: $footer-height
  // padding: 12px 0
  // display: flex
  // justify-content: center
</style>
