import httpManager from "@/app/shared/utils/httpManager";

/**
 * Obtener todos los canales.
 *
 * @param {number} channelId Identificador del canal.
 *
 * @return {Promise<ChannelCustomer[]>} Lista de canales.
 */
export default async function (channelId, portfolio, status = undefined) {
  let url = `/channels/channel-customer/?channel=${channelId}&portfolio=${portfolio ?? ""}`;
  if (status) url += `&status=${status}`;
  const response = await httpManager.get(url);
  return response?.data;
}
