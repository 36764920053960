<template>
  <a-modal
    title="Previsualización"
    :open="open"
    centered
    :maskClosable="false"
    @cancel="handleClose"
  >
    <section class="wrapper">
      <CustomScrollbar height="460px">
        <h4>Título:</h4>
        <p>
          {{ answer?.title }}
        </p>

        <h4>Mensaje:</h4>
        <div>
          <FilePreview
            v-if="answer?.type_file"
            :file="{ file: answer?.file_url, inputType: answer.type_file }"
          />
          <div v-html="formatText(answer?.message)"></div>
        </div>
      </CustomScrollbar>
    </section>

    <template #footer>
      <footer class="footer">
        <a-button @click="handleClose">Cerrar previsualización</a-button>
      </footer>
    </template>
  </a-modal>
</template>

<script setup lang="ts">
import type { QuickAnswer } from "@/@types/platformSettings/quickAnswers";
import FilePreview from "../chat/input/FilePreview.vue";
import CustomScrollbar from "@/app/shared/components/organisms/CustomScrollbar.vue";

defineProps<{
  open: boolean;
  answer: QuickAnswer | undefined;
}>();

const emit = defineEmits<{ "update:open": [open: boolean] }>();

const handleClose = () => emit("update:open", false);

const formatText = (text: string | undefined): string => {
  if (!text) return "";

  const formattedText = text.replace(/\\r\\n\s\\r\\n/g, "\r\n\r\n");

  const paragraphs = formattedText
    .split(/\r\n/)
    .map((p) => (p.trim() ? `<p style="margin-bottom: 0">${p}</p>` : `<br/>`))
    .join("");

  return paragraphs;
};
</script>

<style scoped lang="sass">
.footer
  width: 100%
  display: flex
  justify-content: center
  gap: 8px

.wrapper
  padding: 20px

p
  color: $text-secondary
  margin-bottom: 16px

h4
  font-size: 14px
  font-weight: 600
  color: $text-primary
</style>
