<template>
  <!-- <div class="flex"> -->
  <tags-card :data="tagsData" />
  <!-- <EnableBases /> -->
  <!-- </div> -->
</template>

<script>
import { computed, onMounted } from "vue";
import { useStore } from "vuex";

import TagsCard from "../cards/TagsCard.vue";
// import EnableBases from "../bases/EnableBases.vue";

export default {
  components: {
    TagsCard,
    // EnableBases,
  },
  setup() {
    const store = useStore();

    onMounted(() => {
      store.dispatch("fetchTags");
    });

    const tagsData = computed(() => store.getters["getTags"]);

    return {
      tagsData,
    };
  },
};
</script>

<style lang="sass" scoped>
p
  color: $gray-8
  margin-bottom: 12px

.flex
  display: flex
</style>
