export enum Event {
  // Registration events
  Connecting = "connecting",
  Connected = "connected",
  Registered = "registered",
  Unregistered = "unregistered",
  Disconnected = "disconnected",
  NewSession = "newsession",
  Predictive = "predictive",

  // Call events
  Transfer = "refer",
  Incomming = "incoming",
  Progress = "progress",
  Confirmed = "confirmed",
  Accepted = "accepted",
  Failed = "failed",
  Ended = "ended",
  Answer = "answer",
}

export const events = [
  Event.Connecting,
  Event.Connected,
  Event.Registered,
  Event.Disconnected,
  Event.NewSession,
  Event.Progress,
  Event.Confirmed,
  Event.Accepted,
  Event.Failed,
  Event.Ended,
  Event.Predictive,
  Event.Incomming,
];
