export default [
  {
    path: "/billing",
    name: "billing",
    component: () =>
      import(/* webpackChunkName: "billing" */ "@/app/settings/pages/BillingPage.vue"),
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/billing/expired",
    name: "expired",
    component: () =>
      import(
        /* webpackChunkName: "billing-expired" */ "@/app/settings/pages/ExpiredBillingPage.vue"
      ),
    meta: { requiresAuth: false, layout: "footer" },
  },
];
