import httpManager from "@/app/shared/utils/httpManager";

export async function fetchTypificationsFilters(base, portfolio, filters) {
  let filters2 = {};
  if (!filters) {
    filters2 = {
      clients: [
        { name: "groups", type: "text", values: [] },
        { name: "results", type: "text", values: [] },
        { name: "motives", type: "text", values: [] },
        { name: "submotives", type: "text", values: [] },
      ],
    };
  }
  filters2.custom_clients = filters?.custom_clients;
  filters2.custom_products = filters.custom_products;
  const url = `/strategies/filters/show-available/`;
  const response = await httpManager.post(url, { base, portfolio, filters: filters2 });
  return response.data;
}
