export default [
  {
    path: "/reports",
    component: () =>
      import(
        /* webpackChunkName: "report-menu-page" */ "@/app/reportsMenu/pages/ReportsMenuPage.vue"
      ),
    redirect: "/reports/dashboard",
    meta: {
      requiresAuth: true,
    },
    children: [
      {
        path: "dashboard",
        component: () =>
          import(/* webpackChunkName: "Dashboard" */ "@/app/dashboard/pages/DashboardPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "documents",
        component: () =>
          import(/* webpackChunkName: "Reports" */ "@/app/reports/pages/ReportsPage.vue"),
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
];
